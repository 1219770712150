import React, { useState, useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import img1 from '../../assets/img/images/COmpressionAir-img1.png'
import img2 from '../../assets/img/images/compressionAirImg2.png'
import { Helmet } from "react-helmet";

function CompressedAir() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [color, setColor] = useState('#095CF6');
    const handleBtn = () => {
        setColor('#fff')
    }
    const handleBtnOut = () => {
        setColor('#095CF6')
    }
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Compressed Air Flow Meters </title>
            <meta name="description" content="In most industrial facilities, Compressed Dry Air (CDA) is a primary component of overall energy used. Let’s discover how flow meters can help. " />
            <link rel="canonical" href="https://epiflow.com/whitepapers/compressedair" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner WPCompressedAirSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/whitepapers" tag={Link}>White Papers</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/whitepapers/compressedair" tag={Link}>Compressed Air</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Compressed Air Flow Measurement Meters</h2>
                        <p className='cover-title-p'>Everything you need to know about EPI.</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <Container className='py-5'>
                <div className='DownloadPDfbtn-div'>
                    <div className='position-relative d-inline'>
                        <button
                            className='button2'
                            onMouseOver={handleBtn}
                            onMouseOut={handleBtnOut}
                        >
                            Download as PDF
                        </button>
                        <FontAwesomeIcon
                            className='DownloadPDfbtn-icon'
                            style={{ color: color }}
                        />
                    </div>
                </div>
                <h4 className='commontitleTwo text-center mb-4'>Thermal Mass Flow Compressed Air Metering</h4>
                <p className='commonParaThree'>
                    In most industrial facilities Compressed Dry Air (CDA) is a primary component of overall energy use. In fact, compressed air is
                    considered to be the “fourth utility” after electricity, natural gas and water, and its cost is very often higher than the other
                    three when compared by cost per delivered unit of energy. An efficient compressed dry air system effectively balances six basic
                    aspects — total demand, output air quality, operational supply, system storage, on demand distribution, and overall management.
                    All six elements must work together for the system to reach its maximum efficiency. As the costs of energy continues to rise,
                    accurately tracking the use of compressed air with Master-Touch™ flow meters can provide direct and immediate benefits by giving
                    you the information you need to establish a program that:
                    <br /><br />
                    <ul>
                        <li>monitors general usage to encourage energy and plant cost conservation;</li>
                        <li>tracks peak usage to correctly determine optimum compressor capacity;</li>
                        <li>simplifies your overall gas measurement instrumentation needs through the use of thermal gas mass flow technology.</li>
                    </ul>
                    Determining the true demand in a compressed air system, whether for a new installation or for an upgrade, can be a difficult
                    task but it is critical first step in any design. Once operational, the demands upon a system will typically fluctuate significantly.
                    In either case, the most accurate method of monitoring the system is through the use of one or more flow meters installed at strategic
                    locations in the facility served by the system.
                </p>
                <Row>
                    <Col md="6">
                        <div>
                            <img src={img1} className="w-100" alt="Graphic showing layout of air compression" />
                        </div>
                    </Col>
                    <Col md="6">
                        <p className='commonParaThree'>
                            Potential points of measurement in a compressed air flow meter are: <br /><br />
                            A Intake air;<br />
                            B Total usage;<br />
                            C Distribution pipes; and<br />
                            D Work areas.<br />
                        </p>
                    </Col>
                </Row>
                <h4 className='commontitleTwo text-center my-4'>Thermal Technology for Compressed Air Flow Meters</h4>
                <p className='commonParaThree'>
                    For many years compressed air was most commonly measured by differential pressure orifice plate flow meters. Unfortunately, their very
                    method of measurement — the plate pressure differential — creates a significant pressure drop in the system. Due to the square function
                    characteristics of the pressure drop, the meters typically have a very limited turndown of approximately 5:1, making them unsuitable for
                    monitoring system leaks or even off-peak usage. And if mass flow rate outputs are required, manual or computer calculations incorporating
                    physical process measurements such as absolute pressure, differential pressure, temperature, and viscosity readings must be applied to the
                    output signal to obtain the correct flow rate. Pressure drop, limited turndown and complexity are the necessary evils of using orifice
                    plates to measure the flow rate of compressed air. The introduction of thermal mass flow meters addresses these inherent problems.
                </p>
                <h4 className='commontitleTwo text-center my-4'>Compressed Air Flow Meters: Specifying the Requirements</h4>
                <p className='commonParaThree'>
                    A number of factors must be considered when selecting and specifying any instrumentation and this is true for thermal mass flow meters to be used in compressed air systems. To specify the best configuration, you must determine:
                    <br /><br />

                    What are the flow measurement conditions, such as the minimum and maximum flow rates* to be measured, the process pipe size, the air temperature and line pressure?
                    <br />
                    All flow meters have minimum and maximum flow limits for any given pipe size, and temperature and pressure ranges for the physical construction. Assuring that the
                    flow meter meets these basic requirements is the first step in specifying the proper mass flow meter. These parameters will determine the calibration scale and the
                    expected accuracy, as well as help to identify potential issues with the overall installation.
                    <br />
                    *These values are often expressed as CFM (cubic feet/minute) so it is important to understand the differences between SCFM (Standard cubic feet/minute),
                    ICFM (Inlet cubic feet/minute) and ACFM (Actual cubic feet/minute) for compressed air systems:
                    <br /><br />

                    <ul>
                        <li>
                            Standard units of measurement are referenced to a well-defined temperature and pressure which establishes the mass of air per volume. 70°F and 14.7 PSIA are commonly used
                            for compressed air, though other specific values are often used, too.
                        </li>
                        <li>
                            Inlet units of measurement are determined by the air temperature and pressure at the intake of the compressor system. These units are typically very close to the Standard units.
                        </li>
                        <li>
                            Actual units of measurements are the most variable because air is compressed or expanded by its pressure and temperature. For example, if we assume a constant temperature, then
                            one ACFM of air at 29 PSIA has twice the mass of one SCFM at 14.7 PSIA.
                        </li>
                    </ul>
                    Where will the flow meter be installed and what is the piping configuration upstream and downstream of that location? The flow readings will be their most accurate where the air
                    flow profile in the pipe is uniform and consistent so that the sensor output at the point of measurement is truly representative of the overall flow through the pipe. All instrument
                    manufacturers have recommended straight run requirements for the installation of their meters. These recommendations are offered to help end-users determine suitable locations for
                    the flow meters, but it is important to recognize that these are only guidelines and not guarantees of optimal positioning.
                    <br /><br />
                    Is there moisture present at the point of measurement?<br />
                    Most compressors will produce water droplets entrained in the air flow as a result of the compression process. Simply stated, thermal mass flow meters will not read accurately*
                    if water droplets come into contact with the sensor RTDs, so installing the flow meter at a location where the air is dry is strongly advised. This location may be downstream of
                    air dryers or after the system’s receiver tanks, etc., but it is important to eliminate, or at least minimize as much as possible, the potential for this problem.
                    <br /><br />
                    * The heat loss to a liquid such as water droplets is so much greater than the heat loss to a dry gas that the meter’s flow signal will typically rise to a higher value producing
                    an error that will remain until the heated RTD is dry again. Although Eldridge Products’ flow meters will be affected by these droplets, the droplets will not cause damage to the
                    flow sensor. Non-condensing water vapor in the air is acceptable.
                    <br /><br />
                    Master-Touch™ thermal flow meters provide real-time mass flow measurement and totalization for continuous CDA flow monitoring. A variety of sizes and configuration are available
                    to meet virtually any installation requirement. One very common strategy is to use insertion style flow meters with ball valve assemblies that hold the flow meter firmly in place
                    and yet allow the flow meter to be removed and reinstalled as necessary while preserving the air line’s integrity. If the survey of a compressed dry air system rotates from line
                    to line throughout a large system, multiple ball valve assemblies can be installed at all points of measurement.
                    <br /><br />
                </p>
                <Row>
                    <Col lg="4" md="6">
                        <img src={img2} className="w-100" alt="Model 8840MPNH with ball valve assembly" />
                    </Col>
                    <Col lg="8" md="6">
                        <p className='commonParaThree'>
                            Then, flow meters can be installed in the desired locations for the duration of the survey and moved on to new
                            locations for the next series of measurements. In this way, a very large system can be monitored efficiently and economically.
                            <br /><br />

                            A Common Configuration<br />
                            for CDA Measurement —<br />
                            Model 8840MPNH with<br />
                            ball valve assembly<br />
                        </p>
                    </Col>
                </Row>
            </Container>
            {/* section-2 */}
        </>
    );
}

export default CompressedAir;
