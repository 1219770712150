import React from "react";
import { HashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";

// reactstrap components
import {
  Row,
  Col,
  Button,
  NavLink
} from "reactstrap";

import ImageViewer from "components/Customizer/ImageViewer";

function ProductView({productViewData, images}){
  return(
    <>
      <Col className="ml-auto mr-auto" md="10">
        <Row className="collections">
          <Col md="8" style={{'margin-bottom': '20px'}}>
            <h3><strong>{ productViewData.title }</strong></h3>
            <p dangerouslySetInnerHTML={ {__html: productViewData.description_small} } />
            <Row style={{ 'margin-top': '40px', 'margin-bottom': '10px'}}>
              <Col className="col-1" style={{'font-size': '2.3em'}}>
                <i className="productIcon"><img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Icons%2FProducts%2FDiscover%2Finline-style.svg?alt=media&token=732356fc-eea6-4fdc-aa88-1d972b44ecad" alt="" /></i>
              </Col>
              <Col className="col-4">
                METER TYPE <br />
                <strong>{ productViewData.meter_type }</strong>
              </Col>

              <Col className="col-1" style={{'font-size': '2.3em'}}>
                <i className="productIcon"><img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Icons%2FProducts%2FDiscover%2Fintegrated-electronics.svg?alt=media&token=8d142f0c-48f2-431a-9aab-761525d6bd7c" alt="" /></i>
              </Col>
              <Col className="col-4">
                ENCLOSURE <br />
                <strong>{ productViewData.enclosure }</strong>
              </Col>
            </Row>

            <Row>
              <Col className="col-1" style={{'font-size': '2.3em'}}>
                <i className="productIcon"><img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Icons%2FProducts%2FDiscover%2Fwarranty.svg?alt=media&token=af4e71ef-d274-44a4-ab63-78f092aa0e52" alt="" /></i>
              </Col>
              <Col className="col-4">
                WARRANTY <br />
                <strong>{ productViewData.warranty } Years</strong>
              </Col>

              <Col className="col-1" style={{'font-size': '2.3em'}}>
                <i className="productIcon"><img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Icons%2FProducts%2FDiscover%2Fcertificate.svg?alt=media&token=2a9555b4-69b2-4a0a-9dfd-48d9a500209a" alt="" /></i>
              </Col>
              <Col className="col-6">
                CERTIFICATIONS <br />
                <strong>{ productViewData.certifications }</strong>
              </Col>
            </Row>

            <Row style={{ 'margin-top': '15px'}}>
              <Col className="inline-block">
                <HashLink to="#meterOrientation_">
                  <Button color="info" href="#customizer">Customize Your Meter</Button>
                </HashLink>
                <NavLink tag={Link} to="/findrep">Find a Representative Near You</NavLink>
              </Col>
            </Row>


          </Col>
          <Col md="4">
            <ImageViewer items={images}/>
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default ProductView;
