import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';

// reactstrap components
import {
  UncontrolledCollapse,
  Button,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardHeader,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Collapse,
  UncontrolledTooltip
} from "reactstrap";

function MountingRequirements({handleChange, pipeInfoPipeSize, state, checkState }){
  const [selectFlange, setSelectFlange] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  // collapse states and functions
  const [collapses, setCollapses] = React.useState([0]);
  const changeCollapse = collapse => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter(prop => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  useEffect(()=>{
    if(pipeInfoPipeSize > 3){
      setSelectFlange(false);
    }
  }, [pipeInfoPipeSize]);

  const MakeChangeDropdown = (data) => {
    data.option_value = data.option.target.value;
    const values = data.values;
    const first_value = values[0]

    if(first_value !== data.option_value){
      values.removeWithValue(data.option_value)
      values[0] = data.option_value;
      values.splice(1,0, first_value);
      values.join();
      data.values = values;
    }

    handleChange(data);
  }

  const MakeChange = (data) => {
    handleChange(data);
  }

  var title = 'Mounting Requirements';
  if (isMobile) {
    if(title.length > 18) title = title.substring(0,18) + '...';
  }

  var flangeSelected = false;
  if(pipeInfoPipeSize > 3){
    flangeSelected = true;
  }

  return (
    <>
      <Card style={{
        'border-top-left-radius': 'calc(.45rem - 1px)',
        'border-top-right-radius': 'calc(.45rem - 1px)',
        'border-bottom-left-radius': 'calc(.45rem - 1px)',
        'border-bottom-right-radius': 'calc(.45rem - 1px)'
        }}>
        <CardHeader>
          <a
            aria-expanded={collapses.includes(2)}
            data-toggle="collapse"
            href="#pablo"
            onClick={e => {
              e.preventDefault();
              changeCollapse(2);
            }}
          >
            <Navbar className="bg-epi-blue" style={{
              'height': '10px', 'margin-bottom': '5px',
              'border-top-left-radius': 'calc(.45rem - 1px)',
              'border-top-right-radius': 'calc(.45rem - 1px)'
              }} expand="lg">
              <Container>
                <div className="navbar-translate">
                  <NavbarBrand href="#pablo" onClick={e => e.preventDefault()} style={{'font-size': '22px', 'color': 'white'}}>
                    <p>{ title }</p>
                  </NavbarBrand>
                  <button
                    className="navbar-toggler"
                    id="example-navbar-danger"
                    type="button"
                    aria-expanded={collapses.includes(2)}
                    onClick={e => {
                      e.preventDefault();
                      changeCollapse(2);
                    }}
                  >
                    <i className="now-ui-icons arrows-1_minimal-down" style={{'font-size': '22px', 'color': 'white'}}></i>
                  </button>
                </div>
                <UncontrolledCollapse navbar toggler="#example-navbar-danger">
                  <Nav className="ml-auto" navbar style={{'font-size': '22px', 'color': 'white'}}>
                    <i className="now-ui-icons arrows-1_minimal-down"></i>
                  </Nav>
                </UncontrolledCollapse>
              </Container>
            </Navbar>
          </a>
        </CardHeader>

        {state.meter_type.type === 'inline' &&
          <Collapse isOpen={collapses.includes(2)}>
            <CardBody>
            <Row>
                <Col>
                  <span className="customizerInputTitle">Mounting Type</span>
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">MNPT</span>
                      <Input
                        defaultChecked={!flangeSelected &&
                          checkState(
                            state.mounting_requirements.mounting_types,
                            'mnpt'
                          )
                        }
                        disabled={flangeSelected}
                        onClick={ (e) => MakeChange({
                            section: 'mounting_requirements',
                            type: 'mounting_types',
                            values: ['mnpt', 'butt'],
                            price_effect: false,
                            meter_model_effect: true,
                            meter_model_type: 'mounting_requirements',
                            meter_model_value: 'MT'
                          },
                          e,
                          setSelectFlange(false)
                        )}
                        id="mounting_types"
                        name="mounting_types"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">Butt End</span>
                      <Input
                        defaultChecked={
                          checkState(
                            state.mounting_requirements.mounting_types,
                            'butt'
                          )
                        }
                        disabled={flangeSelected}
                        onClick={ (e) => MakeChange({
                            section: 'mounting_requirements',
                            type: 'mounting_types',
                            values: ['butt', 'mnpt'],
                            price_effect: false,
                            meter_model_effect: true,
                            meter_model_type: 'mounting_requirements',
                            meter_model_value: 'BE'
                          },
                          e,
                          setSelectFlange(false)
                        )}
                        id="mounting_types"
                        name="mounting_types"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                </Col>
                {/*
                <Col>
                  <FormGroup>
                    <label htmlFor="exampleFormControlSelect1">
                      Image here
                    </label>
                  </FormGroup>
                </Col>
                */}
              </Row>
            </CardBody>
          </Collapse>
        }

        {state.meter_type.type === 'insertion' &&
          <Collapse isOpen={collapses.includes(2)}>
            <CardBody>
            <Row>
                <Col>
                  <span className="customizerInputTitle">Mounting Type</span>
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">None. (Add to state). This goes for both insertion Val's</span>
                      <Input
                        defaultChecked={!flangeSelected &&
                          checkState(
                            state.mounting_requirements.mounting_types,
                            'mnpt'
                          )
                        }
                        disabled={flangeSelected}
                        onClick={ (e) => MakeChange({
                            section: 'mounting_requirements',
                            type: 'mounting_types',
                            values: ['mnpt', 'butt'],
                            price_effect: false
                          },
                          e,
                          setSelectFlange(false)
                        )}
                        id="mounting_types"
                        name="mounting_types"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">Compression Fitting</span>
                      <Input
                        defaultChecked={!flangeSelected &&
                          checkState(
                            state.mounting_requirements.mounting_types,
                            'mnpt'
                          )
                        }
                        disabled={flangeSelected}
                        onClick={ (e) => MakeChange({
                            section: 'mounting_requirements',
                            type: 'mounting_types',
                            values: ['mnpt', 'butt'],
                            price_effect: false
                          },
                          e,
                          setSelectFlange(false)
                        )}
                        id="mounting_types"
                        name="mounting_types"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">Stainless Steel Ferrules</span>
                      <Input
                        defaultChecked={
                          checkState(
                            state.mounting_requirements.mounting_types,
                            'butt'
                          )
                        }
                        disabled={flangeSelected}
                        onClick={ (e) => MakeChange({
                            section: 'mounting_requirements',
                            type: 'mounting_types',
                            values: ['butt', 'mnpt'],
                            price_effect: false
                          },
                          e,
                          setSelectFlange(false)
                        )}
                        id="mounting_types"
                        name="mounting_types"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                </Col>
                {/*
                <Col>
                  <FormGroup>
                    <label htmlFor="exampleFormControlSelect1">
                      Image here
                    </label>
                  </FormGroup>
                </Col>
                */}
              </Row>
            </CardBody>
          </Collapse>
        }
      </Card>
    </>
  );
}

export default MountingRequirements;
