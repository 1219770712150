import React,{ useState, useEffect } from 'react';
import useSwr from 'swr';
import { useHistory } from 'react-router';
// import '../assets/css/Quotes.css';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ViewPricesModal from './ViewPricesModal';
import fetcher from 'utils/fetcher';
import { Row, Col} from "reactstrap";

function ViewPrices() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();
    const [modalLarge, setModalLarge] = React.useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const [requestData, setRequestData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const page = { viewPrices: true }

    var { data } = useSwr(`/api/me`, fetcher);
    var { data: Requests, mutate } = useSwr(`/api/users/request`, fetcher);

    useEffect(() => {
        if(data){
            setUserData(data);
            if(data.account_type != 'admin'){
                history.push('/portal/quotes');
            }
        }

        if(Requests){
            setRequestData(Requests)
        }
    }, [data, Requests]);

    const headerData = {
        firstname: userData.firstname,
        page: 'VIEW PRICES APPROVALS'
    }

    console.log('Show me view prices requests 1.0 =>', Requests);
    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page} /></div>) : undefined}
                    <div className='common-main'>
                        <div className='shadow'>
                            <h2 className='p-3'>Pending View Pricing Approvals</h2>
                            <div className='quotes body p-3'>
                                <p>If you would like to see pricing for the meters on the meter customizer, please ensure you have filled in all of your profile information and then request access to our pricing. Once approved, you should see pricing enabled for your account.</p>
                                <hr />
                                <div>
                                    {requestData && requestData.map((value) => {
                                        const date = new Date(value.createdAt);
                                        const day = date.getDate();
                                        const month = ("0" + (date.getMonth() + 1)).slice(-2)
                                        const year = date.getFullYear();

                                        return(
                                            <>
                                                <Row type="button" onClick={ () => {
                                                    setModalData(value);
                                                    setModalLarge(!modalLarge);
                                                }}>
                                                    <Col xs="10" className='quotesLink'>Waiting approval: {value.firstname} {value.lastname},  ({ value.companyName })</Col>
                                                    <Col className="quotesButtons"> { `${month}/${day}/${year}` } </Col>
                                                </Row>
                                                <hr />
                                            </>
                                        )
                                    })}

                                </div>
                                <br />


                                <div className="row filters">
                                  <div className="col-10">
                                    <h6>Toggle Columns</h6>

                                    <div class="form-check form-check-inline">
                                      <label class="form-check-label">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="" /> ID
                                        <span class="form-check-sign">
                                            <span class="check"></span>
                                        </span>
                                      </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <label class="form-check-label">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="" /> Request Type
                                        <span class="form-check-sign">
                                            <span class="check"></span>
                                        </span>
                                      </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <label class="form-check-label">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="" /> User Name
                                        <span class="form-check-sign">
                                            <span class="check"></span>
                                        </span>
                                      </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <label class="form-check-label">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="" /> Date Requested
                                        <span class="form-check-sign">
                                            <span class="check"></span>
                                        </span>
                                      </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <label class="form-check-label">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="" /> Actions
                                        <span class="form-check-sign">
                                            <span class="check"></span>
                                        </span>
                                      </label>
                                    </div>

                                  </div>

                                  <div class="col-2">
                                    <label class="form-check-label"></label>
                                    <p className="text-right p-2">
                                      Page: <b>
                                        1 of 1
                                      </b>
                                    </p>
                                  </div>

                                  <div className="h-4" />
                                  <table class="table table-hover">
                                    <thead class="thead-light">
                                        <tr scope="col">
                                          <th className="tHeadText" scope="col">
                                            ID
                                            <br /><input type="text" placeholder="Search..." class="form-control form-control-sm" value="" />
                                          </th>
                                          <th className="tHeadText" scope="col">
                                            Request Type <br />
                                            <select class="form-control form-control-sm">
                                              <option>Both</option>
                                              <option>Distributor / Rep</option>
                                              <option>Pricing</option>
                                            </select>
                                          </th>
                                          <th className="tHeadText" scope="col">
                                            User Name
                                            <br /><input type="text" placeholder="Search..." class="form-control form-control-sm" value="" />
                                          </th>
                                          <th className="tHeadText" scope="col">
                                            Date Requested
                                            <input type="text" placeholder="Search..." class="form-control form-control-sm" value="" />
                                          </th>
                                          <th className="tHeadText" scope="col">
                                            Actions
                                          </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                          <th className="tHeadText" scope="row">
                                            1
                                          </th>
                                          <td>
                                            Pricing
                                          </td>
                                          <td>
                                            Jay Allen
                                          </td>
                                          <td>
                                            11/03/2022
                                          </td>
                                          <td>
                                            <a href="">Approve</a> / <a href="">Deny</a>
                                          </td>
                                        </tr>
                                    </tbody>
                                  </table>

                                  <div>
                                    <nav>
                                      <ul className="pagination justify-content-center">
                                        <li className="page-item">
                                          <a class="page-link">
                                            Previous
                                          </a>
                                        </li>
                                        <li className="page-item">
                                          <a class="page-link">
                                            &laquo;
                                          </a>
                                        </li>
                                        <li className="page-item">
                                          <a class="page-link">
                                            &lsaquo;
                                          </a>
                                        </li>
                                        <li className="page-item">
                                          <a class="page-link">
                                            &rsaquo;
                                          </a>
                                        </li>
                                        <li className="page-item">
                                          <a class="page-link">
                                            &raquo;
                                          </a>
                                        </li>
                                        <li className="page-item">
                                          <a class="page-link">
                                            Next
                                          </a>
                                        </li>
                                      </ul>
                                    </nav>
                                    <div className="paginationHelper justify-content-center row">
                                      <div className="col-2">
                                        <p className="">
                                          <label>Go To:</label>
                                          <input
                                            type="number"
                                            value="1"
                                            className="form-control"
                                          />
                                        </p>
                                      </div>
                                      <div className="col-3">
                                        <div className="form-group">
                                          <p className="">
                                            <label>Display Per Page:</label>
                                            <select
                                              className="form-select form-control form-control-lg"
                                              value="1"
                                            >
                                              <option value="1">
                                                Show
                                              </option>
                                            </select>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-12">
                                        <p className="text-center">
                                          Page: <b>
                                            1 of 1
                                          </b>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ViewPricesModal
                modalLarge={modalLarge}
                setModalLarge={setModalLarge}
                userData={modalData}
                mutate={mutate}
            />
        </div>
    )
}

export default ViewPrices
