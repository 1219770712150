import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import logo1 from '../../assets/img/images/AACLogo1.png'
import logo2 from '../../assets/img/images/AACLogo2.png'
import logo3 from '../../assets/img/images/AACLogo3.png'
import logo4 from '../../assets/img/images/AACLogo4.png'
import logo5 from '../../assets/img/images/AACLogo5.png'
import logo6 from '../../assets/img/images/AACLogo6.png'
import logo7 from '../../assets/img/images/AACLogo7.png'
import { Helmet } from "react-helmet";


import TechnicalInfoFooter from 'components/Footers/TechnicalInfoFooter';


function AgencyApprovalsCertificates() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/* section-1 */}
            <Helmet>
                <meta charSet="utf-8" />
                <title>EPI – Agency Approvals & Certificates</title>
                <meta name="description" content="Thermal mass flow meter approvals may be required for specific applications. Have a look at EPI’s meter approvals for our series." />
                <link rel="canonical" href="https://epiflow.com/technicalinfo/approvalscertificates" />
            </Helmet>
            <div className='commonbanner AACSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/approvalscertificates" tag={Link}>Agency Approvals & Certificates</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Agency Approvals & Certificates</h2>
                        <p className='cover-title-p'>
                            EPI flow meters have passed multiple certifications and approvals, especially for the HAZ line (explosion-proof enclosures).
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo text-center'>Approvals & Certificates</h4>
                    <p className='text-center commonParaThree mb-4'>
                        Thermal mass flow meter approvals may be required for specific applications. <br />
                        See below for EPI’s meter approvals for our various series.
                    </p>
                    <br />
                    <div className="shadow rounded AACSection2-Table-div">
                        <table class="table ts-s6-table AACSection2">
                            <thead>
                                <tr>
                                    <th scope="col">Organization/Agency</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div style={{'display': 'grid', 'grid-template-columns': 'repeat(6, auto)', 'align-items': 'center'}}>
                                            <img src={logo1} alt="CE Mark Logo" />
                                            <img src={logo3} alt="CSA CUS Logo" />
                                            <img src={logo3} alt="CSA CUS Logo" />
                                            <img src={logo4} alt="ATEX Logo" />
                                            <h1 style={{'color': '#003a82', 'font-weight': '600', 'margin': '0 10px', 'display': 'inline'}}>IECEx</h1>
                                            <img src={logo7} alt="KOSHA Logo" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Container>
            </div>

            {/* section-2 */}
            {/* section-3 */}
                <TechnicalInfoFooter />
            {/* section-3 */}
        </>
    )
}

export default AgencyApprovalsCertificates
