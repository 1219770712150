import React,{ useState, useEffect } from 'react';
import useSwr from 'swr';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import fetcher from 'utils/fetcher';
import './DeliveryTime.css'

import { UncontrolledTooltip } from "reactstrap";

function DeliveryTime() {
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const page = { deliveryTime: true }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    var { data } = useSwr(`${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/me`, fetcher);
    useEffect(() => {
        if(data){
            setUserData(data);
        }
    }, [data]);

    const headerData = {
        firstname: userData.firstname,
        page: 'DELIVERY TIMES'
    }

    return (
        <div className='deliverytime'>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar> </Sidebar></div>) : undefined}
                    <div className='common-main'>
                        <h2 className='p-3'>Shipping Info</h2>
                        <div className='bdr body p-3'>
                            <p>
                                We want to get you your new flow meter as soon as possible. That’s why
                                we make every effort to process your order quickly so you can put your meter to good use sooner.
                            </p>
                            <hr />

                            <h4>Our Shipping Policy</h4>
                            <p>
                                All EPI shipments are <strong><a href="#" className="" id="knw12" size="sm">Ex Works</a></strong> from our facility in Marina, CA. Your finished goods are packaged securely
                                and delivered to the EPI shipping dock for pickup and transportation using a courier as
                                approved by EPI. Approved carriers include UPS, FedEx, and DHL. For <strong><a href="#" id="osp12">oversized shipments</a></strong> a freight forwarder is accepted.
                                <UncontrolledTooltip placement="right" target="knw12" delay={0}>
                                    EPI uses the Incoterm Ex Works. With Ex Works, the
                                    seller is not obligated to load the goods on the buyer's designated method of transport. Instead,
                                    the seller must make the product available at a selected location, and the buyer must incur
                                    transportation costs.
                                </UncontrolledTooltip>
                                <UncontrolledTooltip placement="right" target="osp12" delay={0}>
                                    Subject to courier’s requirement.
                                </UncontrolledTooltip>
                            </p>
                            <p>
                                All goods are insured unless specified otherwise. If no shipping method and account information
                                is shared, EPI will add shipping charges to your final invoice. This will be shown as Prepay and
                                Add on your final invoice.
                            </p>
                            <br />
                            <h4 style={{'font-size': '1.2em'}}>Domestic Shipping - Prepay and Add</h4>
                            <p>
                                Standard Shipping Services:<br />
                                Domestic UPS Ground, 5-7 days (contiguous United States).<br />
                                Optional Shipping Services:<br />
                                Domestic: UPS 1, 2, or 3 days (depending on service selected)
                            </p>
                            <h4 style={{'font-size': '1.2em'}}>International Shipping - Prepay and Add</h4>
                            <p>
                                Standard Shipping Services:<br />
                                International UPS Worldwide Expedited<br />
                                Optional Shipping Services: <br />
                                Worldwide Express, Worldwide Express Plus, Worldwide Express Saver
                            </p>

                            <h4 style={{'font-size': '1.2em'}}>Shipping Holidays</h4>
                            <p>
                                New Year’s Day<br />
                                Easter Sunday<br />
                                Memorial Day<br />
                                Independence Day<br />
                                Labor Day<br />
                                Thanksgiving Day<br />
                                Christmas Day<br />
                                New Year’s Day
                            </p>
                            <h4 style={{'font-size': '1.2em'}}>Shipping to a PO Box</h4>
                            <p>
                                EPI does not ship to PO Boxes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeliveryTime
