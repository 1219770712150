import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function CartModal({modal, toggle, cartData, submitQuote}) {
  const [buttonCheck, setButtonCheck] = useState(false);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}><b class="text-center">Request for Review</b></ModalHeader>
        <ModalBody>
          <p>Please check the box below to confirm that the information you have provided is accurate and complete. Your quote will be reviewed by an EPI representative and you will be contacted to confirm final details.</p>
          <div class="form-check form-check-inline mt-2">
            <label class="form-check-label">
              <span class="customizerInputTitleSmallX1">I have verified that all of my information is correct and I agree to EPI terms of payment.</span>
              <input
                id="termsAgree"
                name="termsAgree"
                type="checkbox"
                class="form-check-input"
                checked={buttonCheck}
                onClick={() => setButtonCheck(!buttonCheck)}
              />
              <span class="form-check-sign"></span>
            </label>
          </div>
        </ModalBody>
        <ModalFooter>
          <div class="row w-100">
            <div class="d-flex justify-content-end">
              <Button className="btn btn-outline mr-2" color="secondary" onClick={toggle}>
                Cancel
              </Button>
                <Button
                  disabled={!buttonCheck}
                  className="btn btn-gradient"
                  color="primary"
                  onClick={
                    toggle,
                    () => submitQuote('realQuote')
                  }
                >
                  Confirm
                </Button>{' '}
              </div>
            </div>
          </ModalFooter>
      </Modal>
    </div>
  );
}

export default CartModal;
