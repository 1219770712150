import React from "react";

// product views
import MultigenProductView from "components/ProductViews/Multigen/MultigenInsertion/MultigenProductView";
import _525CrProductView from "components/ProductViews/Xt/XtInsertion/525CrProductView";


function MultigenInsertion({product, productType, tab}) {
  switch (product) {
      case 'gen':
        return (
          <>
            <MultigenProductView productType={productType} tab={tab} />
          </>
        );
        break;
  
    default:
      return (
        <>
            <MultigenProductView productType='insertion'  tab={tab} />
        </>
      );
      break;
  }
}

export default MultigenInsertion;
