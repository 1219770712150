import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import '../../assets/css/style.css'
import FaqAccordation from './FaqAccordation'
import FaqAccordationTwo from './FaqAccordationTwo'
import { Helmet } from "react-helmet";


function Faq() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – FAQ's</title>
            <meta name="description" content="View a list of answers to the most-asked questions our support staff receive daily from EPI’s customers. " />
            <link rel="canonical" href="https://epiflow.com/support/faq" />
        </Helmet>
            {/* section-1 */}
            <div className='faq-section-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/support" tag={Link}>Support</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/support/faq" tag={Link}>FAQs</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>FAQ's</h2>
                        <p className='cover-title-p'>View a list of answers to the top most-asked questions our support staff receive from customers.</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <div className='pb-5'>
                <Container>
                    <h1 className='commontitleTwo text-center mt-5 mb-4'>Get answers to the most common questions.</h1>
                    <FaqAccordation />
                </Container>
            </div>
            {/* section-2 */}
        </>
    )
}

export default Faq
