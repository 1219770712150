import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledTooltip
} from "reactstrap";

function Gas({ MakeChangeText, state, checkState }){
  const [maxTempInput, setMaxTempInput] = useState(false);
  const [selectedInput, setSelectedInput] = useState('C');
  const [maxGasTempError, setMaxGasTempError] = useState(false);

  const getValue = (object, value) => {
    if(object){
      return object.valuesObj[value]
    }
  }

  const checkUnits = (unit) => {
    if(checkState(state.process_parameters.gas_units, 'units') === unit){
      return true;
    }
    return false;
  }

  const checkMaxGasTempDirect = (value) => {
    // setMaxTempInput(value);
    setMaxGasTempError(false);
    if(selectedInput === 'C'){
      if(value <= 50){ setMaxTempInput(value); return "MW050" }
      if(value <= 75){ setMaxTempInput(value);return "MW075" }
      if(value <= 100){ setMaxTempInput(value);return "MW100" }
      if(value <= 125){ setMaxTempInput(value);return "MW125" }
      if(value <= 150){ setMaxTempInput(value); return "MW150" }
      if(value <= 175){ setMaxTempInput(value); return "MW175" }
      if(value <= 200){ setMaxTempInput(value); return "MW200" }
      if(value <= 250){ setMaxTempInput(value); return "MW250" }
      if(value <= 300){ setMaxTempInput(value); return "MW300" }
      setMaxGasTempError(true);
    }

    if(selectedInput === 'F'){
      if(value <= 122){ setMaxTempInput(value); return "MW050" }
      if(value <= 167){ setMaxTempInput(value); return "MW075" }
      if(value <= 212){ setMaxTempInput(value); return "MW100" }
      if(value <= 257){ setMaxTempInput(value); return "MW125" }
      if(value <= 302){ setMaxTempInput(value); return "MW150" }
      if(value <= 347){ setMaxTempInput(value); return "MW175" }
      if(value <= 392){ setMaxTempInput(value); return "MW200" }
      if(value <= 482){ setMaxTempInput(value); return "MW250" }
      if(value <= 572){ setMaxTempInput(value); return "MW300" }
      setMaxGasTempError(true);
    }
    return "NA";
  }

  const checkMaxGasTempSwitch = () => {
    setMaxGasTempError(false);
    console.log('Show me max temp value 1.0 =>', selectedInput);
    if(selectedInput === 'C'){
      if(maxTempInput <= 50){ return "MW050" }
      if(maxTempInput <= 75){ return "MW075" }
      if(maxTempInput <= 100){ return "MW100" }
      if(maxTempInput <= 125){ return "MW125" }
      if(maxTempInput <= 150){ return "MW150" }
      if(maxTempInput <= 175){ return "MW175" }
      if(maxTempInput <= 200){ return "MW200" }
      if(maxTempInput <= 250){ return "MW250" }
      if(maxTempInput <= 300){ return "MW300" }
      setMaxGasTempError(true);
    }

    if(selectedInput === 'F'){
      if(maxTempInput <= 122){ return "MW050" }
      if(maxTempInput <= 167){ return "MW075" }
      if(maxTempInput <= 212){ return "MW100" }
      if(maxTempInput <= 257){ return "MW125" }
      if(maxTempInput <= 302){ return "MW150" }
      if(maxTempInput <= 347){ return "MW175" }
      if(maxTempInput <= 392){ return "MW200" }
      if(maxTempInput <= 482){ return "MW250" }
      if(maxTempInput <= 572){ return "MW300" }
      setMaxGasTempError(true);
    }
    return "NA";
  }

  const setSelectedInput_ = () => {
    if(selectedInput === 'C'){
      setSelectedInput('F');
    } else {
      setSelectedInput('C');
    }
  }


  return (
    <>
      <Row>
        <Col className="processParametterCols">
        <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitleProcessParameters">MINIMUM GAS TEMP.</span>
              <Button className="questionToolTip" id="MINIMUMGASTEMP" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="MINIMUMGASTEMP" delay={0}>
                Input the minimum gas temperature you expect in the process line.
                This will help EPI determine the appropriate temperature compensation range for your process.
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlSelect1"
              type="number"
              placeholder="Minimum Temp"
              defaultValue={
                getValue(state.process_parameters.gas_minimum, 'minimum') &&
                getValue(state.process_parameters.gas_minimum, 'minimum')
              }
              onChange={ (e) => MakeChangeText({
                section: 'process_parameters',
                type: 'gas_minimum',
                values: ['minimum'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            >
            </Input>
          </FormGroup>
        </Col>

        <Col className="processParametterCols">
        <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitleProcessParameters">MAXIMUM GAS TEMP.</span>

              <Button className="questionToolTip" id="MAXIMUMGASTEMP" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="MAXIMUMGASTEMP" delay={0}>
                Input the maximum gas temperature you expect in the process line.
                This will help EPI determine the appropriate temperature compensation range for your process.
              </UncontrolledTooltip>
            </label>
            {maxGasTempError &&
              <span style={{ 'color': 'rgb(246 65 81)'}}>
                <br />
                "Sorry this temp is out of our range"
              </span>
            }
            <Input
              className="epiInputSize"
              id="exampleFormControlSelect1"
              type="number"
              placeholder="Maximum Temp"
              defaultValue={
                checkState(state.process_parameters.gas_maximum, 'maximum') &&
                checkState(state.process_parameters.gas_maximum, 'maximum')
              }
              onChange={ (e) => MakeChangeText({
                  section: 'process_parameters',
                  type: 'gas_maximum',
                  values: ['maximum'],
                  price_effect: false,
                  text_input: true,
                  input_name: 'max_gas_temp',
                  meter_model_effect: true,
                  meter_model_type: 'gas_temp',
                  meter_model_value: checkMaxGasTempDirect(e.target.value),
                  option: e
                },
              e)}
            >
            </Input>
          </FormGroup>
        </Col>

        <Col className="processParametterCols">
          <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitleProcessParameters">NORMAL GAS TEMP.</span>
              <Button className="questionToolTip" id="OPTIMALGASTEMP" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="OPTIMALGASTEMP" delay={0}>
                Input your optimal gas temperature.
                This will serve as a reference point when your meter is calibrated to your gas type.
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlSelect1"
              type="number"
              placeholder="Optimal Temp"
              defaultValue={
                checkState(state.process_parameters.gas_optimal, 'optimal') &&
                checkState(state.process_parameters.gas_optimal, 'optimal')
              }
              onChange={ (e) => MakeChangeText({
                section: 'process_parameters',
                type: 'gas_optimal',
                values: ['optimal'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            >
            </Input>
          </FormGroup>
        </Col>

        <Col className="processParametterCols">
          <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitleProcessParameters">Units</span>
              <Button className="questionToolTip" id="UnitsGAS" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="UnitsGAS" delay={0}>
                Units Gas
              </UncontrolledTooltip>
              </label>
            <Input
              className="epiInputSize form-select"
              id="exampleFormControlSelect1"
              type="select"
              onChange={ (e) => MakeChangeText({
                section: 'process_parameters',
                type: 'gas_units',
                values: ['units'],
                price_effect: false,
                text_input: true,
                option: e
              },
              setSelectedInput_(),
              // checkMaxGasTempSwitch(e.target.value),
              MakeChangeText({
                section: 'none',
                type: 'none',
                values: ['none'],
                price_effect: false,
                text_input: true,
                input_name: 'max_gas_temp',
                meter_model_effect: true,
                meter_model_type: 'gas_temp',
                meter_model_value: checkMaxGasTempSwitch(),
                option: e
              }),
              e)}
            >
              <option value="" selected disabled hidden>Units</option>
              <option selected={checkUnits('F')} value="F">°F</option>
              <option selected={checkUnits('C')} value="C">°C</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default Gas;
