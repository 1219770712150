import React,{ useState, useEffect } from 'react';
import useSwr from 'swr';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import fetcher from 'utils/fetcher';
import img1 from '../../assets/img/images/packing-materials-box-matter-img.png'
import img2 from '../../assets/img/images/nopeanuts-box-img.png'
import img3 from '../../assets/img/images/camera-icon.png'
import './PackingMaterials.css'

function PackingMaterials() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const page = { packingMaterials: true }

    var { data } = useSwr(`http://localhost:1337/api/me`, fetcher);
    useEffect(() => {
        if(data){
            setUserData(data);
        }
    }, [data]);

    const headerData = {
        firstname: userData.firstname,
        page: 'PACKING MATERIALS'
    }
    
    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar> </Sidebar></div>) : undefined}
                    <div className='common-main'>
                        <h2 className='p-3'>Packing Materials</h2>
                        <div className='bdr body p-3'>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p>
                            <hr />
                            <h4>Boxes That Matter</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            <div className='position-relative'>
                                <img src={img1} className='pm-img' alt="" />
                                <img src={img3} className='test-img' alt="" />
                            </div>
                            <h4>No Peanuts</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            <div className='row mt-3 mb-3'>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <img src={img2} className='pw-card-img' alt="" /><br />
                                    <img src={img3} className='test-img' alt="" />
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <img src={img2} className='pw-card-img' alt="" />
                                    <img src={img3} className='test-img' alt="" />
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <img src={img2} className='pw-card-img' alt="" />
                                    <img src={img3} className='test-img' alt="" />
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <img src={img2} className='pw-card-img' alt="" />
                                    <img src={img3} className='test-img' alt="" />
                                </div>
                            </div>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            <h4>It's What's on the Inside that Counts</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            <div className='row mt-3 mb-3'>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <img src={img2} className='pw-card-img' alt="" /><br />
                                    <img src={img3} className='test-img' alt="" />
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <img src={img2} className='pw-card-img' alt="" />
                                    <img src={img3} className='test-img' alt="" />
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <img src={img2} className='pw-card-img' alt="" />
                                    <img src={img3} className='test-img' alt="" />
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <img src={img2} className='pw-card-img' alt="" />
                                    <img src={img3} className='test-img' alt="" />
                                </div>
                            </div>
                            <h4>Availability</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PackingMaterials
