import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';

import TechnicalInfoFooter from 'components/Footers/TechnicalInfoFooter';

import { Helmet } from "react-helmet";


function FullGuideFlowMeters() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Guide to Thermal Mass Flow Meters</title>
            <meta name="description" content="What is a thermal mass flow meter? How does it work? EPI’s guide aims to give a broad understanding of flow meters. Check it out!" />
            <link rel="canonical" href="https://epiflow.com/technicalinfo/fullguideflowmeters" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner FullGuide-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/fullguideflowmeters" tag={Link}>Full Guide to Flow Meters</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>A Full Guide to Thermal Mass Flow Meters</h2>
                        <p className='cover-title-p'>
                            This guide aims to give a broad understanding of thermal mass flow meters, from the way they work to the industries they work best in.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo text-center mb-2'>
                        Full Guide to Thermal Mass Flow Meters
                    </h4>
                    <br /><br />
                    {/* <p className='commonParaThree text-center mb-4'>
                        Thermal mass flow technology comparison has some explana,on below for helping to understand correct terms while doing this analysis.
                    </p> */}
                    <h4 className='commontitlefive mb-2'>
                        What Is a Thermal Mass Flow Meter and How Do They Work?
                    </h4>
                    <p className='commonParaThree'>
                        A thermal mass flow meter is an instrument used to precisely and constantly measure the rate of flow of a
                        fluid – normally a gas – through a pipe or conduit in some sort of industrial application.
                        <br /><br />
                        Thermal mass flow meters are inserted directly into the center of flow of the fluid they’re measuring, either as a system mounted to the outside of the conduit with a probe entering through a port, or as a completely contained inline system that acts as part of the conduit itself. They provide a way to accurately monitor gas flow in an essentially passive manner that has a near-zero impact on the wider processes the gasses are involved in. They also offer high reliability and extremely low maintenance requirements compared to other types of flow meters, thanks to the elegant simplicity of their design.
                    </p>
                    <br /><br />
                    <h4 className='commontitlefive mb-2'>
                        Where are Thermal Mass Flow Meters Used?
                    </h4>
                    <p className='commonParaThree'>
                        Thermal mass flow meters are used everywhere it’s important to carefully monitor the flow rate of a fluid. To the uninitiated, that might seem like a narrow field of applications, but it’s actually shockingly broad, as carefully metered gas flow is crucial in everything from manufacturing to municipal operations to environmental protection and beyond.
                        <br /><br />

                        While the full list of <a href="/technicalinfo/whitepapers">thermal flow meter applications</a> is far too long to include in a single article, the following represent just a few common applications for the technology.
                        <br /><br />

                        <strong>Compressed Air:</strong> Compressed air is widely used in industrial settings to run everything from large pneumatic machines right down to hand tools. Thermal mass flow meters allow plants to control how compressed air is distributed to stations from central sources and measure total use in order to identify costly and potentially dangerous leaks.
                        <br /><br />

                        <strong>Greenhouse Gasses:</strong> Combustion in manufacturing produces greenhouse gasses that have to be
                        carefully tracked and reported for companies to maintain compliance with environmental regulations like
                        the ones set out by the EPA in the United States. Thermal mass flow meters are commonly used to
                        accurately measure the flow of greenhouse gasses exiting plants, allowing companies to both stay on the
                        good side of the law and find opportunities to reduce their emissions.
                        <br /><br />

                        <strong>Natural Gas:</strong> Natural gas is a common fuel used for industrial combustion, and while it’s cheaper than
                        many other fuels, waste is still a big problem. Thermal mass flow meters are used to measure the rate of
                        flow of natural gas into boilers, furnaces, and heaters in order to closely track and optimize fuel flow and
                        to identify inefficient systems that are sucking up more fuel than they should.
                        <br /><br />

                        <strong>Wastewater Aeration:</strong> Aeration is a key step in municipal water treatment that uses oxygen injection to
                        feed the bacteria that break down waste. Too much or too little oxygen renders the process less efficient
                        and drives up costs. Municipal water treatment plants use thermal mass flow meters to ensure the ideal
                        amount of air is being bubbled into the water to keep costs down and happy bacteria working efficiently.
                    </p>
                    <br /><br />

                    <h4 className='commontitlefive mb-2'>
                        Why Thermal Mass Flow Metering is So Important.
                    </h4>
                    <p className='commonParaThree'>
                        The applications listed above make up only a tiny fraction of the full scope of flow metering’s usefulness,
                        but it’s clear from each that the process is exceptionally important to the bottom line, the effectiveness of
                        production, and the environmental impact of industrial processes.
                        <br /><br />

                        Thermal mass flow meters offer users a way to meter their gas flows with a number of significant benefits
                        including, but not limited to:
                        <br /><br />

                        <ul>
                            <li>An extremely high degree of accuracy, generally within 1% of the total flow or better.</li>
                            <li>High reliability and low downtime due to a design that involves no moving parts.</li>
                            <li>An effectively negligible impact on overall pressure within the flow being measured.</li>
                            <li>The ability to accurately measure widely varying flow rates thanks to a low turndown ratio.</li>
                            <li>Easy installation and low maintenance requirements.</li>
                        </ul>

                        In essence, thermal mass flow meters provide a way to tackle the important process of metering gas flow
                        in a way that is highly accurate, highly reliable, highly repeatable, and highly economical, leading to better
                        metering, lower waste, and improved safety.
                    </p>
                    <br /><br />

                    <h4 className='commontitlefive mb-2'>
                        How Do Thermal Mass Flow Meters Operate?
                    </h4>
                    <p className='commonParaThree'>
                        One of the beauties of thermal mass flow meters is their simplicity, both in their design and the concepts
                        that drive their function. To understand how thermal mass flow meters work, it’s first important to
                        understand what a thermal mass is. After all, it’s in the name. Thermal mass refers to a material’s ability
                        to absorb and release heat. In your house, the wood, brick, and concrete act as thermal masses that help
                        regulate the interior temperature. In thermal mass flow metering, the thermal mass is the gas being
                        flowed, which also has the capability to absorb and move heat.
                        <br /><br />

                        All thermal mass flow meters work by inserting two probes into the center of the gas stream being
                        measured. One is a passive probe that measures the temperature of the surrounding gas, and one is an
                        actively heated probe that is kept at a constant temperature in relation to the passive temperature sensor.
                        As gas flows past the probes, it carries some of the heat away from the active sensor. Higher flow
                        velocities equal more heat carried away. As that cooling effect occurs, the control system is forced to use
                        more electrical current to keep the temperature spread between the two sensors consistent. That current
                        change is monitored constantly, and using some fancy mathematics known as King’s law, the system can
                        calculate the exact flow rate of the fluid to within 1% accuracy or better.
                    </p>
                    <br /><br />

                    <h4 className='commontitlefive mb-2'>
                        Types of Thermal Mass Flow Meters.
                    </h4>
                    <p className='commonParaThree'>
                        Thermal mass flow meters all work on the same core principles, but they can mount and operate
                        differently depending on the type chosen. Each type has advantages and disadvantages that will be more
                        or less suited to any given application, but all deliver an accurate, repeatable metering process.
                        <br /><br />

                        <strong>Inline vs. Insertion.</strong><br />
                        Inline and insertion flow meters differ in how they mount to the pipe or conduit carrying the target gas. &nbsp;
                        Inline thermal mass flow meters are permanent installations that include their own section of pipe and are
                        fitted inline with the flow pipe, attaching at either end with threading or flanges. They come in a variety of
                        sizes, allowing them to be used with many common pipe diameters, and while they tend to cost more
                        than insertion meters, they offer set-and-forget installation ideal for long-term use.
                        <br /><br />

                        Insertion thermal mass flow meters insert into a conduit from the outside through a port. They provide
                        identical accuracy and repeatability, but because they aren’t integrated into the system body itself, they
                        represent a lower cost, semi-permanent solution more ideally suited for intermittent or temporary
                        metering.
                        <br /><br />

                        <strong>Constant Temperature vs. Constant Current.</strong><br />
                        Constant temperature and constant current thermal mass flow meters both measure the same thing – the
                        heat change across two probes – but they differ in how the actively heated probe is utilized within the
                        system. In a constant temperature system, the heated probe is kept at a constant temperature relative to
                        the opposing probe, and the change in the current needed to maintain that constant spread is the variable
                        measured. In a constant current system, the amount of current to the heated probe is kept steady, and
                        the fluctuating temperature of the probe in relation to the passive surrounding temperature is the variable
                        measured. Both systems are effective, but constant temperature probes offer a faster response and are
                        generally a better option.
                    </p>
                    <br /><br />

                    <h4 className='commontitlefive mb-2'>
                        Find Out More About EPI Thermal Mass Flow Meters.
                    </h4>
                    <p className='commonParaThree'>
                        Thermal mass flow meters offer an ideal solution to the problem of accurately measuring gas flows in
                        industrial, municipal, and environmental applications. Their simplicity, ease of installation, and low
                        maintenance requirements make them extremely affordable, and their high level of accuracy and
                        repeatability make them arguably the most effective option available.
                        <br /><br />
                        Eldridge Products, Inc. designs and manufactures inline and insertion thermal mass flow meters and control software for use in a wide variety of industries. To find out more about how our flagship Master
                        Touch or budget-conscious ValuMass lines of thermal mass flow meters can meet your needs, <a href="/contactus">reach out
                        to a member of the EPI team today. </a>
                    </p>
                </Container>
            </div>

            {/* section-2 */}

            {/* section-3 */}

            {/* section-3 */}

            {/* section-4 */}
                <TechnicalInfoFooter />
            {/* section-4 */}
        </>
    )
}

export default FullGuideFlowMeters
