import React, { useState, useEffect } from "react";

// core components
import ProductHeader from "components/Headers/ProductHeader";
import ProductViewNavbar from "components/Navbars/ProductViewNavbar";
import ProductView from "components/ProductViews/Components/ProductView";
import ProductDescription from "components/ProductViews/Components/ProductDescription";
import ValgenLMCustomizer from "components/Customizer/Views/Val/ValInline/ValgenLMCustomizer";
import TechSpecs from "views/TechSpecs/TechSpecs";
import CADFiles from "views/CADFiles/CADFiles";
import { Helmet } from "react-helmet";


function ValgenLMProductView({productType}, tab){
  const [page, setPage] = useState('discover');

  useEffect(() => {
    if(tab){
      if(tab === 'disc'){
        setPage('discover');
      }
  
      if(tab === 'specs'){
        setPage('specs');
      }
  
      if(tab === 'cad'){
        setPage('cad');
      }  
    }
  }, [tab]);
  
  const productImage = "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInsertion%2FLM%2FValuMass-540-Insertion-Hero.png?alt=media&token=ca7231b3-4362-48a6-9bb0-914d0ca0a9d6";

  const navbarData = {
    product_title: 'SERIES 540 VAL',
    product_title_old: 'SERIES 540',
    product_type: 'INLINE THERMAL MASS FLOW METER'
  }

  const productInfo = {
    model: 'SERIES 540',
    series: navbarData.product_title
  }

  const images = [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInsertion%2FLM%2FProductImages%2FValuMass-540-Insertion.jpg?alt=media&token=8e48af64-4022-4838-84de-2d1ad5cd29c2",
      altText: "ValuMass™ Series 540 VAL Insertion Flow Meter",
      productImageLink: '/productimages/val540'
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInsertion%2FLM%2FProductImages%2FValuMass-540-Insertion-Enclosure-Closeup.jpg?alt=media&token=8d992ebd-1439-43ce-bf3e-67edc6220526",
      altText: "ValuMass™ Series 540 VAL Insertion Enclosure",
      productImageLink: '/productimages/val540_2'
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInsertion%2FLM%2FProductImages%2FValuMass-540-Insertion-in-Production-Plant.jpg?alt=media&token=542f65e6-9ea0-462c-88cf-863451ad3504",
      altText: "ValuMass™ Series 540 VAL Insertion in Production Plant",
      productImageLink: '/productimages/val540_3'
    }
  ];

  const productViewData = {
    title: 'ValuMass™ Series 540 VAL Insertion Flow Meters',
    description_small: `
    ValuMass™ Series 540 is a thermal mass flow meter with insertion installation type which is part of our lower-cost ValuMass™ Series. Designed with an explosion-proof rated enclosure, it supports many flow applications with the high value associated with all of EPI’s flow measurement instrumentation.
    <br /><br />
    Like <a href="/products/val/inline/val-lm">ValuMass™ Series 500</a>, the ValuMass™ Series 540 insertion thermal flow meter uses two ratiometrically-matched Resistance Temperature Detectors (RTDs). This assures that many different gases and gas mixtures are accurately measured.
    <br /><br />
    Besides being temperature compensated, these thermal mass flow sensors are constructed of reference-grade platinum, ceramic, glass, and stainless steel. They are also large and insensitive to dirt buildup and slipped into a stainless-steel sheath for corrosion and abrasion resistance.
    <br /><br />
    If you are in search of an economical solution for mass measurement in many air and gas flow applications with an insertion installation type, the ValuMass™ Series 540 insertion thermal flow meter is surely one of your top choices.
    `,
    description: `
    ValuMass™ products include a rugged, cleanable, thermal mass flow sensor. These units consist of a sensor assembly which utilizes two RTD (Resistance Temperature Detector) sensing elements. The sensors are constructed of reference grade platinum, ceramic, glass, and stainless steel. Two platinum resistance sensors are built up upon the ceramic substrate and then given a thin glass coating. The assembly is then slipped into a stainless-steel sheath for corrosion and abrasion resistance. The sensor assembly is large, rugged, and relatively insensitive to dirt buildup. During operation, the temperature sensor constantly measures the ambient temperature of the gas and maintains a reference resistance on one side of a bridge circuit. The second sensor is forced through self-heating to a constant temperature above that of the gas stream and is controlled by the temperature sensor and bridge circuitry. Our bridge maintains the overheat temperature and counterbalances the temperature effects through our proprietary temperature compensation techniques.
    <br /><br />
    540 Series flow meters are approved for use in non-hazardous locations (see specifications).
    <br /><br />
    Insertion style thermal mass flow meters include a sensor & probe assembly that is inserted into the process gas flow conduit to allow the process gas to flow across the flow sensing elements. Our insertion style flow meters are available with ½” OD probes in 6” to 36” lengths, ¾” probes to 60” lengths. Tube fittings and ball valve retractor assemblies, with or without a mounting flange, are also available from the factory as options. The tube length must be specified upon ordering.
    <br /><br />
    Integral style thermal mass flow meters have all the electrical components and connections located within one enclosure. This enclosure is rated for non-hazardous environments. The enclosure is mounted directly to the insertion probe assembly at the point of measurement. The enclosure includes all the electrical connections as well as the linearizing electronics and the display/keypad assembly.
    <br /><br />
    Thermal mass flow meters use the principle of convective heat transfer to directly measure mass flow. EPI’s proprietary thermal mass flow sensors use two ratiometrically-matched, reference-grade platinum Resistance Temperature Detectors (RTDs). The platinum sensing element wire is encapsulated in a 316 Stainless Steel sheath or, if specified, a Hastelloy C sheath. Our microcontroller operated smart sensor technology preferentially heats one RTD; the other RTD acts as the temperature reference. The process gas flow dissipates heat from the first RTD, causing an increase in the power required to maintain a balance between the RTDs. This increase is directly related to the gas molecular rate of flow. Our sensors are temperature compensated for a wide process gas temperature range and insensitive to pressure changes, so the output signal is a true mass flow rate signal.

    `,
    meter_type: 'Insertion',
    enclosure: 'Integrated Electronics',
    warranty: '1-4',
    certifications: 'CE'
  }

  const techSpecsData = {
    features:{
      titleOne: 'VAL Series 540 Enclosure',
      highlightOneImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FProductFeatures%2Ffeatures-val-500.png?alt=media&token=07fe4b10-8695-47ec-9fc5-3ecf2436f47a',
      highlightOneInfo: `EPI’s VAL Series 540 enclosure is a diecast aluminum, NEMA Type 4X watertight enclosure.
      <br /><br />
      <ul>
        <li>Material of Body & Cover: Aluminium Pressure Die-Casting</li>
        <li>Finish: Safety Blue Spray Epoxy</li>
        <li>Coating: Chromating and Chemically Resistant Paint (outside only)</li>
        <li>Protection Degree: Max. possible IP68</li>
        <li>Glass Thickness: 15mm</li>
      </ul>
      `,
      titleTwo: 'Superior Flow Measurement and Calibration',
      highlightTwoImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ffeatures-image-2.png?alt=media&token=86df9cb6-22d4-4fbc-bc7f-f6b4e267b8ec',
      highlightTwoPdf: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FProductFeatures%2FFlow%20Calibration%20Check.png?alt=media&token=d98121d8-44ae-465d-b603-aa56f7c9bdd6',
      highlightTwoInfo: `EPI flow meters provide accurate, consistent, and rapid real-time flow measurement with a sensor that
      is both temperature and pressure corrective. Each flow meter is calibrated using standards traceable to
      the National Institute of Standards and Technology (NIST).
      <br /><br />

      -Accuracy: +/- (1% of reading + 0.5% of full scale + GTC) <br />
      -Repeatability: +/- 0.2% of full scale <br />
      -Sensor response time: 1 second to 63% of final value <br />
      -Gas temperature coefficient (GTC): 0.02% full scale/°C <br />
      -Gas pressure effect: negligible over +/-50% of factory calibration pressure
      `
    },
    our_process: {
      benefits:[
        {
          title: 'Broad Velocity Range',
          info: `Up to 30,000 SFPM`
        },
        {
          title: 'Low flow sensitivity',
          info: `100:1 Turn down ratio; 10 SFPM (0.05 NMPS) minimum reading.`
        },
        {
          title: 'Wide temperature range',
          info: `VAL Sensor suitable for process gas temperatures up to 257°F (125°C).`
        },
        {
          title: 'Multiple analog outputs',
          info: `4-20mA and 0-5VDC outputs for both flow and temperature`
        },
        {
          title: 'Various communication protocols supported',
          info: `RS485 Modbus RTU (standard) & BACnet`
        },
        {
          title: 'NIST traceable calibration facility',
          info: `Over 30 years of flow calibration expertise`
        },
        {
          title: 'Ease of installation',
          info: `Various configurations to meet your installation requirements`
        },
        {
          title: 'Live Zero',
          info: `Live Zero (EPIVal) to validate your flow meter calibration in both process line and ambient air
          conditions`
        }
      ]
    },
    specifications: {
      specs: [
        {
          title: 'Linear signal output',
          info: `0–5 VDC & 4–20 mA (Flow and Temperature),
          0 – 1 kHz (proportional to flow for total/counter)
           `
        },
        {
          title: 'Event Relay Rating',
          info: `One with Frequency or two, 1 Amp @ 30Vdc (33W)`
        },
        {
          title: 'Signal Interface',
          info: `RS485 Modbus RTU (standard) & BACnet`
        },
        {
          title: 'Display',
          info: `Optional 2-line, 16-character backlit LCD and 4-button keypad (flow rate, flow total, gas temperature, milliwatts)`
        },
        {
          title: 'Accuracy, including linearity (Ref.: 21°C)',
          info: `±(1% of Reading + 0.5% of Full Scale + GTC)`
        },
        {
          title: 'Repeatability',
          info: `±0.2% of Full Scale`
        },
        {
          title: 'Gas temperature coefficient (GTC)',
          info: `0.02% Full Scale/°C`
        },
        {
          title: 'Dry Gas – Sensor Type CTA',
          info: `SSS, CTA (Constant Temperature Anemometer)`
        },
        {
          title: 'Sensor response time (TC to 63% final value)',
          info: `1 second (sensor skin temperature varies)`
        },
        {
          title: '*Wet Gas – Optional Sensor Type CCA',
          info: `SSS, CCA (Constant Current Anemometer)`
        },
        {
          title: 'Sensor Response Time (TC to 63% final value)',
          info: `10 second (sensor thermal mass temperature varies)`
        },
        {
          title: 'Turn down ratio',
          info: `100:1 @ 10 SFPM (.051 NMPS) Minimum Reading`
        },
        {
          title: 'Sensor temperature limits',
          info: `-40° to 400°F (-40° to 204°C), temperature compensation range determined at time of order`
        },
        {
          title: 'Environmental temperature limits',
          info: `-40° to 140°F (-40° to 60°C)`
        },
        {
          title: 'Electronics PCB temperature limits',
          info: `-40° to 158°F (-40° to 70°C)`
        },
        {
          title: 'Gas pressure effect',
          info: `Negligible over ± 20% of absolute calibration pressure`
        },
        {
          title: 'Insertion Flowmeters',
          info: `500 PSIG (Stainless Steel ferrule),
          25 PSIG (Teflon™ ferrule)`
        },
        {
          title: 'Input power requirement',
          info: `24VDC @ 250mA, 115 VAC 50/60 Hz optional, 230 VAC 50/60 Hz 50mA optional`
        },
        {
          title: 'Transmitter Power Requirements',
          info: `5 Watts or less`
        },
        {
          title: 'Wetted Materials',
          info: `316L Stainless Steel (optional Hastelloy C276)`
        },
        {
          title: 'Standard Temperature & Pressure (STP)',
          info: `70°F & 29.92″ Hg (Air = 0.075 Lbs./FT^3)`
        },
        {
          title: 'NIST Traceable Calibration',
          info: `Standard`
        },
        {
          title: 'Enclosure Marking Only',
          info: `Ex; IECEx; CSA, FM – Ex II2G Ex d IIC Gb, Ex IID Ex t IIIC Db; Classes: 1, Gr. A, B, C, D; II, Gr. E, F, G; III Type 4X; IP68 (sealed per Mfg. Ins.); Zone 1, Zone 2, Zone 21, Zone 22, Division 1, Division 2`
        },
        {
          title: 'NOTE',
          info: `Specifications subject to change without notice`
        },
        {
          title: '',
          info: `*Wet-Gas CCA, consult factory for review of application to determine if required.`
        }
      ]
    },
    communication_config: {
      type: 'val500',
      comm_config: [
        {
          output: '0-5 OR 0-10 VDC TEMPERATURE',
          standard: true,
          frequency: true,
          bacnet: true,
          bacnet_frequency: true
        },
        {
          output: 'FREQUENCY 0-1KHZ',
          standard: true,
          frequency: true,
          bacnet: false,
          bacnet_frequency: true
        },
        {
          output: 'MODE SWITCH (RANGES)',
          standard: true,
          frequency: true,
          bacnet: true,
          bacnet_frequency: true
        },
        {
          output: '1 MECHANICAL RELAY',
          standard: true,
          frequency: false,
          bacnet: true,
          bacnet_frequency: false
        },
        {
          output: '2 MECHANICAL RELAY	',
          standard: true,
          frequency: true,
          bacnet: true,
          bacnet_frequency: true
        },

        {
          output: 'RS 485/MODBUS (R/W)',
          standard: true,
          frequency: true,
          bacnet: false,
          bacnet_frequency: false
        },
        {
          output: 'RS 232 (R/W)',
          standard: true,
          frequency: true,
          bacnet: true,
          bacnet_frequency: true
        },
        {
          output: 'HART (READ ONLY)',
          standard: false,
          frequency: false,
          bacnet: false,
          bacnet_frequency: false
        },
        {
          output: 'PROFIBUS (READ ONLY)',
          standard: false,
          frequency: false,
          bacnet: false,
          bacnet_frequency: false
        },
        {
          output: 'RS 232/MODBUS (READ ONLY)',
          standard: false,
          frequency: false,
          bacnet: true,
          bacnet_frequency: true
        },
      ]
    },
    geometry_sizing: {
      cadImg: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FVAL-540-Insertion.jpg?alt=media&token=01c7d2ef-45ef-4f7d-a6b1-97aa38306698',
      cadImg2: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FVAL-540-Insertion-2.jpg?alt=media&token=40208fcf-584b-467e-90d3-077443f1a87f',
      sizing: [
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        }
      ]
    },
    tech_support:{
      faq: [
        {
          title: 'Wiring',
          info: `The wiring of your EPI flow meter will be based on the configuration selected when your order was placed. EPI flow meters can be
          configured for various AC or DC input power options. Both analog and digital output signals are also available depending on your model and
          options.`
        },
        {
          title: 'Orientation',
          info: `EPI flow meters can be mounted in a number of different orientations based on your requirements with some limited adjustability in the
          field.`
        },
        {
          title: 'Installation',
          info: `EPI flow meters do require minumum up and downstream straight run to ensure the flow profile can develop properly, providing you with
          the highest accuracy readings.`
        },
        {
          title: 'Communication/ Outputs',
          info: `Your EPI flow meter will come standard with RS485 Modbus and RS232 digital communication. We also offer optional BACnet, Profibus DP and
          HART communications protocols. EPI flow meters provide a 4-20mA and 0-5 VDC analog output signal for flow and
          temperature (based on specific models) as well as up to 2 relays that can be independently configured based on your requirements.
          `
        }
      ],
      maintenance_info: {
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
      }
    },
    installation: {
      video: '',
      image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FWiring%2F500.png?alt=media&token=feb2fb39-631a-4b62-82a6-c27e624c9e8b'
    },
    documents_downloads: {
      datasheets: [
        {
          title: 'Downloads',
          files: [
            {
              file_title: 'Product Brochure',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%20500%20VALUMASS.pdf?alt=media&token=677df829-f29c-474e-8a27-6e129dc0ae66',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Wiring Diagram',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FVAL%20Series%20500-540%20Wiring.pdf?alt=media&token=176d2c0e-0d4b-4688-b873-eb758e40dc27',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Product Manual',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000002_RevA%20Valumass%20Manual.pdf?alt=media&token=03025f6a-9bf0-44ac-8c69-2d0cb37a06b4',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Meter Installation Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'EPITerm Software Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000003_RevA%20EPITerm%20Interface%20User%20Manual.pdf?alt=media&token=0e082343-3f69-476a-aaa0-fd344ece1885',
              version: '1.0.0',
              release_data: '2022'
            }
          ],
        }
      ],
    }
  }

  const cadData = {
    files: [
      {
        productName: '8716',
        modelLink: 'https://proengineerportal.autodesk360.com/shares/public/SH9285eQTcf875d3c539cc144c88493ca402?mode=embed',
        pdfLink: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/files%2F8716MP.pdf?alt=media&token=9ad25d55-b7e2-47f3-95f7-535318cab484',
        downloadLink: 'https://proengineerportal.autodesk360.com/g/shares/SH9285eQTcf875d3c539cc144c88493ca402',
        description: `All content on this site is the intellectual property of Eldridge Products,
        Inc. You have the right to copy and share all materials herein in any medium or format.
        You may not use materials for commercial purposes and you may not modify or redistribute modified materials.
        We reserve the right to receive credit for all uses of the content.`
      },
      {
        productName: '8720',
        modelLink: 'https://proengineerportal.autodesk360.com/shares/public/SH9285eQTcf875d3c539cc144c88493ca402?mode=embed',
        pdfLink: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/files%2F8716MP.pdf?alt=media&token=9ad25d55-b7e2-47f3-95f7-535318cab484',
        downloadLink: 'https://proengineerportal.autodesk360.com/g/shares/SH9285eQTcf875d3c539cc144c88493ca402',
        description: `All content on this site is the intellectual property of Eldridge Products,
        Inc. You have the right to copy and share all materials herein in any medium or format.
        You may not use materials for commercial purposes and you may not modify or redistribute modified materials.
        We reserve the right to receive credit for all uses of the content.`
      },
      {
        productName: '8724',
        modelLink: 'https://proengineerportal.autodesk360.com/shares/public/SH9285eQTcf875d3c539cc144c88493ca402?mode=embed',
        pdfLink: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/files%2F8716MP.pdf?alt=media&token=9ad25d55-b7e2-47f3-95f7-535318cab484',
        downloadLink: 'https://proengineerportal.autodesk360.com/g/shares/SH9285eQTcf875d3c539cc144c88493ca402',
        description: `All content on this site is the intellectual property of Eldridge Products,
        Inc. You have the right to copy and share all materials herein in any medium or format.
        You may not use materials for commercial purposes and you may not modify or redistribute modified materials.
        We reserve the right to receive credit for all uses of the content.`
      },
    ]
  }

  const ViewPages = () => {
    if(page === 'discover'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <ProductView productViewData={productViewData} images={images} />
          </div>
          <ProductDescription productViewData={productViewData} />
          <ValgenLMCustomizer
            productType={productType}
            productInfo={productInfo}
          />
        </>
      );
    }

    if(page === 'specs'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <TechSpecs data={techSpecsData} />
          </div>
        </>
      );
    }

    if(page === 'cad'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <CADFiles data={cadData} />
          </div>
        </>
      );
    }

    if(page === 'support'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <h1>Support</h1>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <ProductHeader productImage={productImage} />
      <div
        className="section section-about-us"
        style={{
          'background': 'whitesmoke',
          'padding-bottom': '50px',
          'border-bottom': 'whitesmoke'
        }}
      >
        <ProductViewNavbar
          navbarData={navbarData}
          page={page}
          setPage={setPage}
        />
      </div>
      <ViewPages />
    </>
  );
}

export default ValgenLMProductView;
