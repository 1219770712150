import React from "react";

// product views
import SwitchProductView from "components/ProductViews/Switch/SwitchInline/SwitchProductView";

function SwitchInline({product, productType, tab}) {
  switch (product) {
    case 'gen':
      return (
        <>
          <SwitchProductView productType={productType} tab={tab} />
        </>
      );
      break;
  
    default:
      return (
        <>
            <SwitchProductView productType={productType}  tab={tab} />
        </>
      );
      break;
  }
}

export default SwitchInline;
