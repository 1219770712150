import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import '../../assets/css/style.css'
import img1 from '../../assets/img/images/meter-modals-img.png'
import icon1 from '../../assets/img/images/section-5-1sticon.png'
import icon2 from '../../assets/img/images/section-5-2ndicon.png'
import icon3 from '../../assets/img/images/section-5-3rdicon.png'
import icon4 from '../../assets/img/images/section-5-4thicon.png'

function MeterModels() {
    return (
        <>
            {/* section-1 */}
            <div className='mm-section-1'>
                <div className='image-container'>
                    <div className='about-title container pt-2 pb-2'>
                        <span className='me-2'>PRODUCTS</span>
                        <FontAwesomeIcon icon={faChevronRight} className='icon' />
                        <span className='ml-2'>MODELS</span>
                    </div>
                    <div>
                        <h2 className='cover-title-h2'>Meter Models</h2>
                        <p className='cover-title-p'>Page Description</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <div className='mm-section-2 text-center pb-5'>
                <h3 className='commontitleOne pt-5 mb-4'>A flow meter for any requirement.</h3>
                <div className='mm-row container'>
                    <div className='shadow rounded'>
                        <img src={img1} alt="" />
                        <h3 className='mm-card-title mt-3'>EPI-HAZ</h3>
                        <p className='mm-card-des'>HAZARDOUS LOCATIONS</p>
                        <p className='mm-card-body'>Thermal Mass Flow Meters
                            CerAfied for Hazardous LocaAons
                            (Explosion-Proof)
                        </p>
                        <div className='pb-5'>
                            <a href="/" className='button2 mm-card-btn'>See All</a>
                        </div>
                    </div>
                    <div className='shadow rounded'>
                        <img src={img1} alt="" />
                        <h3 className='mm-card-title mt-3'>EPI-HAZ</h3>
                        <p className='mm-card-des'>HAZARDOUS LOCATIONS</p>
                        <p className='mm-card-body'>Thermal Mass Flow Meters
                            CerAfied for Hazardous LocaAons
                            (Explosion-Proof)
                        </p>
                        <div className='pb-5'>
                            <a href="/" className='button2 mm-card-btn'>See All</a>
                        </div>
                    </div>
                    <div className='shadow rounded'>
                        <img src={img1} alt="" />
                        <h3 className='mm-card-title mt-3'>EPI-HAZ</h3>
                        <p className='mm-card-des'>HAZARDOUS LOCATIONS</p>
                        <p className='mm-card-body'>Thermal Mass Flow Meters
                            CerAfied for Hazardous LocaAons
                            (Explosion-Proof)
                        </p>
                        <div className='pb-5'>
                            <a href="/" className='button2 mm-card-btn'>See All</a>
                        </div>
                    </div>
                    <div className='shadow rounded'>
                        <img src={img1} alt="" />
                        <h3 className='mm-card-title mt-3'>EPI-HAZ</h3>
                        <p className='mm-card-des'>HAZARDOUS LOCATIONS</p>
                        <p className='mm-card-body'>Thermal Mass Flow Meters
                            CerAfied for Hazardous LocaAons
                            (Explosion-Proof)
                        </p>
                        <div className='pb-5'>
                            <a href="/" className='button2 mm-card-btn'>See All</a>
                        </div>
                    </div>
                    <div className='shadow rounded'>
                        <img src={img1} alt="" />
                        <h3 className='mm-card-title mt-3'>EPI-HAZ</h3>
                        <p className='mm-card-des'>HAZARDOUS LOCATIONS</p>
                        <p className='mm-card-body'>Thermal Mass Flow Meters
                            CerAfied for Hazardous LocaAons
                            (Explosion-Proof)
                        </p>
                        <div className='pb-5'>
                            <a href="/" className='button2 mm-card-btn'>See All</a>
                        </div>
                    </div>
                    <div className='shadow rounded'>
                        <img src={img1} alt="" />
                        <h3 className='mm-card-title mt-3'>EPI-HAZ</h3>
                        <p className='mm-card-des'>HAZARDOUS LOCATIONS</p>
                        <p className='mm-card-body'>Thermal Mass Flow Meters
                            CerAfied for Hazardous LocaAons
                            (Explosion-Proof)
                        </p>
                        <div className='pb-5'>
                            <a href="/" className='button2 mm-card-btn'>See All</a>
                        </div>
                    </div>
                    <div className='shadow rounded'>
                        <img src={img1} alt="" />
                        <h3 className='mm-card-title mt-3'>EPI-HAZ</h3>
                        <p className='mm-card-des'>HAZARDOUS LOCATIONS</p>
                        <p className='mm-card-body'>Thermal Mass Flow Meters
                            CerAfied for Hazardous LocaAons
                            (Explosion-Proof)
                        </p>
                        <div className='pb-5'>
                            <a href="/" className='button2 mm-card-btn'>See All</a>
                        </div>
                    </div>
                    <div className='shadow rounded'>
                        <img src={img1} alt="" />
                        <h3 className='mm-card-title mt-3'>EPI-HAZ</h3>
                        <p className='mm-card-des'>HAZARDOUS LOCATIONS</p>
                        <p className='mm-card-body'>Thermal Mass Flow Meters
                            CerAfied for Hazardous LocaAons
                            (Explosion-Proof)
                        </p>
                        <div className='pb-5'>
                            <a href="/" className='button2 mm-card-btn'>See All</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* section-2 */}
            {/* section 3 */}
            <section className='section-3'>
                <div className='container'>
                    <h1 className='text-center pt-5 pb-3'>EPI Meter Features</h1>
                    {/* row 1 */}
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='img-div'>

                            </div>
                        </div>
                        <div className='col-md-6 d-flex align-items-center'>
                            <div>
                                <h2 className='s3-h2'>Feature Highlight #1</h2>
                                <p className='s3-p'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                    nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                                    aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                    nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                                    officia deserunt mollit anim id est laborum.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* row 1 */}
                    {/* row 2 start */}
                    <div className='row s3-row2'>
                        <div className='col-md-6 d-flex align-items-center'>
                            <div>
                                <h2 className='s3-h2'>Feature Highlight #2</h2>
                                <p className='s3-p'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='img-div'>

                            </div>
                        </div>
                    </div>
                    {/* row 2 end */}
                    {/* row 3 start */}
                    <div className='row pb-5'>
                        <div className='col-md-6'>
                            <div className='img-div'>

                            </div>
                        </div>
                        <div className='col-md-6 d-flex align-items-center'>
                            <div>
                                <h2 className='s3-h2'>Feature Highlight #3</h2>
                                <p className='s3-p'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                    nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                                    aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                    nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                                    officia deserunt mollit anim id est laborum.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* row 3 end */}
                </div>
            </section>
            {/* section 3 */}
            {/* section-4 */}
            <div className='mm-section-4'>
                <div className='container'>
                    <h2 className='mm-s4-title pt-5'>Need help deciding?</h2>
                    <p className='mm-s4-des'>Fill out the form below and an EPI representaAve will contact you to help you choose
                        the best meter for your applicaAon.
                    </p>
                    <form action="" method="post">

                        <div className='row'>
                            <div className='col-md-4'>
                                <div class="form-group">
                                    <label for="">FIRST NAME <span className='text-danger'>*</span></label>
                                    <input type="text" class="form-control" id="" placeholder="First Name" />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div class="form-group">
                                    <label for="">LAST NAME <span className='text-danger'>*</span></label>
                                    <input type="text" class="form-control" id="" placeholder="Last Name" />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div class="form-group">
                                    <label for="">EMAIL <span className='text-danger'>*</span></label>
                                    <input type="email" class="form-control" id="" placeholder="Email" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">ADDITIONAL INFORMATION </label>
                            <textarea class="form-control mm-textarea" id="exampleFormControlTextarea1" rows="3" placeholder='Please enter any helpful informaAon about your applicaAon or use case'></textarea>
                        </div>
                        <div className='mt-5 pb-5'>
                            <a href="/" className='button1'>Get Help</a>
                        </div>
                    </form>

                </div>
            </div>
            {/* section-4 */}
            {/* section-5 */}
            <div className='mm-section-5'>
                <div className='container'>
                    <h3 className='commontitleOne pt-5 pb-4 text-center'>Why Choose EPI?</h3>
                    <div className='row mm-row-2'>
                        <div className='col-md-6'>
                            <div className="row">
                                <div className='col-md-3 d-flex justify-content-center align-items-center'>
                                    <div className='shadow rounded-circle mm-img-main-div d-flex justify-content-center align-items-center'>
                                        <img src={icon1} className='mm-card2-img' alt="" />
                                    </div>
                                </div>
                                <div className='col-md-9'>
                                    <h4 className='mm-card2-title'>Built Custom for You</h4>
                                    <p className='mm-card2-des'>EPI offers completely customizable products for thermal flow
                                        metering. Start customizing your own today!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="row">
                                <div className='col-md-3 d-flex justify-content-center align-items-center'>
                                    <div className='shadow rounded-circle mm-img-main-div d-flex justify-content-center align-items-center'>
                                        <img src={icon2} className='mm-card2-img' alt="" />
                                    </div>
                                </div>
                                <div className='col-md-9'>
                                    <h4 className='mm-card2-title'>Fast Turnaround Times</h4>
                                    <p className='mm-card2-des'>The Ame between your order and our shipment is streamlined
                                        to ensure your new meter arrives quickly.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="row">
                                <div className='col-md-3 d-flex justify-content-center align-items-center'>
                                    <div className='shadow rounded-circle mm-img-main-div d-flex justify-content-center align-items-center'>
                                        <img src={icon3} className='mm-card2-img' alt="" />
                                    </div>
                                </div>
                                <div className='col-md-9'>
                                    <h4 className='mm-card2-title'>World Class Support</h4>
                                    <p className='mm-card2-des'>We have the best customer support in the industry. Whatever
                                        you need, we are here to help in every way possible.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="row">
                                <div className='col-md-3 d-flex justify-content-center align-items-center'>
                                    <div className='shadow rounded-circle mm-img-main-div d-flex justify-content-center align-items-center'>
                                        <img src={icon4} className='mm-card2-img' alt="" />
                                    </div>
                                </div>
                                <div className='col-md-9'>
                                    <h4 className='mm-card2-title'>Extended Warranty</h4>
                                    <p className='mm-card2-des'>EPI's warranty is one of the best in the industry. We want to
                                        make sure that our products are always outperforming
                                        expectaAons.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section-5 */}
            {/* section-6 */}
            <div className='mm-section-6'>
                <div className='container'>
                    <h3 className='commontitleOne pt-5 pb-4 text-center'>Our Process</h3>
                    <div className="row text-center">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <h4 className='mm-card2-title'>Step 1</h4>
                            <p className='mm-card2-des'>Configure your new meter with our Meter
                                Customizer, and see how your new meter
                                will look.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <h4 className='mm-card2-title'>Step 2</h4>
                            <p className='mm-card2-des'>An EPI team member will perform a
                                checkup to make sure that the meter
                                setup will perfectly match your specific
                                applicaAon.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <h4 className='mm-card2-title'>Step 3</h4>
                            <p className='mm-card2-des'>Once your meter setup is ready, we
                                finalize shipping and payment details, and
                                then start building.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <h4 className='mm-card2-title'>Step 4</h4>
                            <p className='mm-card2-des'>After your new meter has been
                                completed in the shop and tested for
                                quality, we will ship the meter and
                                provide you with a tracking number.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* section-6 */}
        </>
    )
}

export default MeterModels
