import React from "react";
import ProfibusBottom from "./ProfibusBottom";

function Profibus(){
  return (
    <>
      <hr />
      <ProfibusBottom />
    </>
  );
}

export default Profibus;
