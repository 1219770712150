import React from "react";

import DuctRectangular from "./DuctRectangular";
import DuctRound from "./DuctRound";
function Duct({ handleChange, state, checkState, ductShape }){
  return (
    <>
      { ductShape === 'rectangular'? (
        <DuctRectangular
          handleChange={ handleChange }
          state={ state }
          checkState={ checkState }
        />
      ) : (
        <DuctRound
          handleChange={ handleChange }
          state={ state }
          checkState={ checkState }
        />
      )}
    </>
  );
}

export default Duct;
