import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';

import vertical_top_to_bottom_left from 'assets/img/MeterCustomizerAssets/Orientations/Inline/v-tb-l.png';
import vertical_top_to_bottom_right from 'assets/img/MeterCustomizerAssets/Orientations/Inline/v-tb-r.png';
import vertical_bottom_to_top_left from 'assets/img/MeterCustomizerAssets/Orientations/Inline/v-bt-l.png';
import vertical_bottom_to_top_right from 'assets/img/MeterCustomizerAssets/Orientations/Inline/v-bt-r.png';

import horizontal_left_to_right_above from 'assets/img/MeterCustomizerAssets/Orientations/Inline/h-lr-a.png';
import horizontal_left_to_right_below from 'assets/img/MeterCustomizerAssets/Orientations/Inline/h-lr-b.png';
import horizontal_right_to_left_above from 'assets/img/MeterCustomizerAssets/Orientations/Inline/h-rl-a.png';
import horizontal_right_to_left_below from 'assets/img/MeterCustomizerAssets/Orientations/Inline/h-rl-b.png';

// reactstrap components
import {
  UncontrolledCollapse,
  Card,
  CardBody,
  CardImg,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardHeader,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Collapse,
} from "reactstrap";

function MeterOrientation({ handleChange, state, checkState, getKeyByValue }){
  const [orientationImg, setOrientationImg] = useState();

  useEffect(() => {
    const orientation = getKeyByValue(state.meter_orientation.pipe_orientation, true);

    if(checkState(state.meter_orientation.pipe_orientation, 'vertical')){
      const flowDirectionVertical = getKeyByValue(state.meter_orientation.flow_direction_vertical, true);
      const enclosureVertical = getKeyByValue(state.meter_orientation.enclosure_mounting_preference_vertical, true);

      switch (orientation +'_'+ flowDirectionVertical +'_'+ enclosureVertical) {
        case 'vertical_top_to_bottom_left_side':
          setOrientationImg(vertical_top_to_bottom_left);
          break;

        case 'vertical_top_to_bottom_right_side':
          setOrientationImg(vertical_top_to_bottom_right);
          break;

        case 'vertical_bottom_to_top_right_side':
          setOrientationImg(vertical_bottom_to_top_right);
          break;

        case 'vertical_bottom_to_top_left_side':
          setOrientationImg(vertical_bottom_to_top_left);
          break;

        default:
          break;
      }
    }

    if(checkState(state.meter_orientation.pipe_orientation, 'horizion')){
      const flowDirectionHori = getKeyByValue(state.meter_orientation.flow_direction_horizontal, true);
      const enclosureHori = getKeyByValue(state.meter_orientation.enclosure_mounting_preference_horizontal, true);

      const image = orientation +'_'+ flowDirectionHori +'_'+ enclosureHori;
      console.log('Show me meter ori state 1.0.2 =>', image);

      switch (orientation +'_'+ flowDirectionHori +'_'+ enclosureHori) {
        case 'horizion_left_to_right_above_pipe':
          setOrientationImg(horizontal_left_to_right_above);
          break;

        case 'horizion_left_to_right_below_pipe':
          setOrientationImg(horizontal_left_to_right_below);
          break;

        case 'horizion_right_to_left_above_pipe':
          setOrientationImg(horizontal_right_to_left_above);
          break;

        case 'horizion_right_to_left_below_pipe':
          setOrientationImg(horizontal_right_to_left_below);
          break;

        default:
          break;
      }
    }
  }, [state]);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  // collapse states and functions
  const [collapses, setCollapses] = useState([1]);
  const changeCollapse = collapse => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter(prop => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  const MakeChange = (data) => {
    handleChange(data);
  }

  var title = 'Meter Orientation';
  if (isMobile) {
    if(title.length > 18) title = title.substring(0,18) + '...';
  }


  return (
    <>
      <Card style={{
        'border-top-left-radius': 'calc(.45rem - 1px)',
        'border-top-right-radius': 'calc(.45rem - 1px)',
        'border-bottom-left-radius': 'calc(.45rem - 1px)',
        'border-bottom-right-radius': 'calc(.45rem - 1px)'
        }}
        id="meterOrientation"
      >
        <CardHeader>
          <a
            aria-expanded={collapses.includes(2)}
            data-toggle="collapse"
            href="#pablo"
            onClick={e => {
              e.preventDefault();
              changeCollapse(2);
            }}
          >
            <Navbar className="bg-epi-blue" style={{
              'height': '10px', 'margin-bottom': '5px',
              'border-top-left-radius': 'calc(.45rem - 1px)',
              'border-top-right-radius': 'calc(.45rem - 1px)'
              }} expand="lg">
              <Container>
                <div className="navbar-translate">
                  <NavbarBrand href="#pablo" onClick={e => e.preventDefault()} style={{'font-size': '22px', 'color': 'white'}}>
                    <p>{ title }</p>
                  </NavbarBrand>
                  <button
                    className="navbar-toggler"
                    id="example-navbar-danger"
                    type="button"
                    aria-expanded={collapses.includes(2)}
                    onClick={e => {
                      e.preventDefault();
                      changeCollapse(2);
                    }}
                  >
                    <i className="now-ui-icons arrows-1_minimal-down" style={{'font-size': '22px', 'color': 'white'}}></i>
                  </button>
                </div>
                <UncontrolledCollapse navbar toggler="#example-navbar-danger">
                  <Nav className="ml-auto" navbar style={{'font-size': '22px', 'color': 'white'}}>
                    <i className="now-ui-icons arrows-1_minimal-down"></i>
                  </Nav>
                </UncontrolledCollapse>
              </Container>
            </Navbar>
          </a>
        </CardHeader>

        <Collapse isOpen={collapses.includes(2)}>
          <CardBody>
            <Row>
              <Col>
                <span className="customizerInputTitle">Pipe Orientation test</span>
                <FormGroup check className="form-check-radio">
                  <Label check>
                    <span className="customizerInputTitleSmallX1">Horizontal</span>
                    <Input
                      defaultChecked = {
                        checkState(
                          state.meter_orientation.pipe_orientation,
                          'horizion'
                        )
                      }
                      onClick={ (e) => MakeChange({
                          section: 'meter_orientation',
                          type: 'pipe_orientation',
                          values: ['horizion', 'vertical'],
                          price_effect: false
                        },
                        setOrientationImg(horizontal_right_to_left_above),
                        e
                      )}
                      id="pipe_orientation1"
                      name="pipe_orientation1"
                      type="radio"
                    ></Input>
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
                <FormGroup check className="form-check-radio">
                  <Label check>
                    <span className="customizerInputTitleSmallX1">Vertical</span>
                    <Input
                      defaultChecked = {
                        checkState(
                          state.meter_orientation.pipe_orientation,
                          'vertical'
                        )
                      }
                      onClick={ (e) => MakeChange({
                        section: 'meter_orientation',
                        type: 'pipe_orientation',
                        values: ['vertical', 'horizion'],
                        price_effect: false
                      },
                      setOrientationImg(vertical_top_to_bottom_left),
                      e)}
                      id="pipe_orientation1"
                      name="pipe_orientation1"
                      type="radio"
                    ></Input>
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup><br />

                {checkState(state.meter_orientation.pipe_orientation, 'horizion') && (
                  <span className="customizerInputTitle">Flow Direction Horizontal</span>
                )}

                {checkState(state.meter_orientation.pipe_orientation, 'horizion') && (
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">Left to Right</span>
                      <Input
                        defaultChecked = {
                          checkState(
                            state.meter_orientation.flow_direction_horizontal,
                            'left_to_right'
                          )
                        }
                        onClick={ (e) => MakeChange({
                          section: 'meter_orientation',
                          type: 'flow_direction_horizontal',
                          values: ['left_to_right', 'right_to_left'],
                          price_effect: false
                        },
                        e)}
                        id="flowdirectionhorizontal"
                        name="flowdirectionhorizontal"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                )}

                {checkState(state.meter_orientation.pipe_orientation, 'horizion') && (
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">Right to Left</span>
                      <Input
                        defaultChecked = {
                          checkState(
                            state.meter_orientation.flow_direction_horizontal,
                            'right_to_left'
                          )
                        }
                        onClick={ (e) => MakeChange({
                          section: 'meter_orientation',
                          type: 'flow_direction_horizontal',
                          values: ['right_to_left', 'left_to_right'],
                          price_effect: false
                        },
                        e)}
                        id="flowdirectionhorizontal"
                        name="flowdirectionhorizontal"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                )}

                {checkState(state.meter_orientation.pipe_orientation, 'horizion') && (
                  <br />
                )}

                {checkState(state.meter_orientation.pipe_orientation, 'horizion') && (
                  <span className="customizerInputTitle">Enclosure Mounting Preference Horizontal</span>
                )}

                {checkState(state.meter_orientation.pipe_orientation, 'horizion') && (
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">Above Pipe</span>
                      <Input
                        defaultChecked = {
                          checkState(
                            state.meter_orientation.enclosure_mounting_preference_horizontal,
                            'above_pipe'
                          )
                        }
                        onClick={ (e) => MakeChange({
                          section: 'meter_orientation',
                          type: 'enclosure_mounting_preference_horizontal',
                          values: ['above_pipe', 'below_pipe', 'left_side', 'right_side'],
                          price_effect: false
                        },
                        e)}
                        id="enclosure_mounting_preference_horizontal"
                        name="enclosure_mounting_preference_horizontal"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                )}

                {checkState(state.meter_orientation.pipe_orientation, 'horizion') && (
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">Below Pipe</span>
                      <Input
                        defaultChecked = {
                          checkState(
                            state.meter_orientation.enclosure_mounting_preference_horizontal,
                            'below_pipe'
                          )
                        }
                        onClick={ (e) => MakeChange({
                          section: 'meter_orientation',
                          type: 'enclosure_mounting_preference_horizontal',
                          values: ['below_pipe', 'above_pipe'],
                          price_effect: false
                        },
                        e)}
                        id="enclosure_mounting_preference_horizontal"
                        name="enclosure_mounting_preference_horizontal"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                )}

                {checkState(state.meter_orientation.pipe_orientation, 'horizion') && (
                  <br />
                )}

                {checkState(state.meter_orientation.pipe_orientation, 'vertical') && (
                  <span className="customizerInputTitle">Flow Direction Vertical</span>
                )}

                {checkState(state.meter_orientation.pipe_orientation, 'vertical') && (
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">Top to Bottom</span>
                      <Input
                        defaultChecked = {
                          checkState(
                            state.meter_orientation.flow_direction_vertical,
                            'top_to_bottom'
                          )
                        }
                        onClick={ (e) => MakeChange({
                          section: 'meter_orientation',
                          type: 'flow_direction_vertical',
                          values: ['top_to_bottom', 'bottom_to_top'],
                          price_effect: false
                        },
                        e)}
                        id="flow_direction_vertical1"
                        name="flow_direction_vertical1"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                )}

                {checkState(state.meter_orientation.pipe_orientation, 'vertical') && (
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">Bottom to Top</span>
                      <Input
                        defaultChecked = {
                          checkState(
                            state.meter_orientation.flow_direction_vertical,
                            'bottom_to_top'
                          )
                        }
                        onClick={ (e) => MakeChange({
                          section: 'meter_orientation',
                          type: 'flow_direction_vertical',
                          values: ['bottom_to_top', 'top_to_bottom'],
                          price_effect: false
                        },
                        e)}
                        id="flow_direction_vertical1"
                        name="flow_direction_vertical1"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                )}

                {checkState(state.meter_orientation.pipe_orientation, 'vertical') && (
                  <br />
                )}

                {checkState(state.meter_orientation.pipe_orientation, 'vertical') && (
                  <span className="customizerInputTitle">Enclosure Mounting Preference Vertical</span>
                )}

                {checkState(state.meter_orientation.pipe_orientation, 'vertical') && (
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">Left Side</span>
                      <Input
                        defaultChecked = {
                          checkState(
                            state.meter_orientation.enclosure_mounting_preference_vertical,
                            'left_side'
                          )
                        }
                        onClick={ (e) => MakeChange({
                          section: 'meter_orientation',
                          type: 'enclosure_mounting_preference_vertical',
                          values: ['left_side', 'right_side'],
                          price_effect: false
                        },
                        e)}
                        id="enclosure_mounting_preference_vertical"
                        name="enclosure_mounting_preference_vertical"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                )}

                {checkState(state.meter_orientation.pipe_orientation, 'vertical') && (
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">Right Side</span>
                      <Input
                        defaultChecked = {
                          checkState(
                            state.meter_orientation.enclosure_mounting_preference_vertical,
                            'right_side'
                          )
                        }
                        onClick={ (e) => MakeChange({
                          section: 'meter_orientation',
                          type: 'enclosure_mounting_preference_vertical',
                          values: ['right_side', 'left_side'],
                          price_effect: false
                        },
                        e)}
                        id="enclosure_mounting_preference_vertical"
                        name="enclosure_mounting_preference_vertical"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                )}

                {checkState(state.meter_orientation.pipe_orientation, 'vertical') && (
                  <br />
                )}
              </Col>
              <Col className="text-center">
                <CardImg alt="..." src={orientationImg} top></CardImg>
              </Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
}

export default MeterOrientation;
