import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row, Button, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";
import img1 from '../../assets/img/images/WhitePapersImg1.png'
import img2 from '../../assets/img/images/WhitePapersImg2.png'
import img3 from '../../assets/img/images/WhitePapersImg3.png'
import img4 from '../../assets/img/images/WhitePapersImg4.png'
import img5 from '../../assets/img/images/WhitePapersImg5.png'
import img6 from '../../assets/img/images/WhitePapersImg6.png'
import img7 from '../../assets/img/images/WhitePapersImg7.png'

function WhitePapers() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – White Papers</title>
            <meta name="description" content="EPI’s thermal mass flow meters are being put to work in industrial plants and factories all over the world. Learn more about their applications!" />
            <link rel="canonical" href="https://epiflow.com/technicalinfo/whitepapers" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner WPection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/whitepapers" tag={Link}>White Papers</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>White Papers</h2>
                        <p className='cover-title-p'>
                            EPI thermal mass flow meters are being put to work in industrial plants, factories and more all over the world. Learn more about these applications below.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <div className='bg-white WPSection-2'>
                <Container className='py-5'>
                    <Row className='g-4'>
                        <Col lg="4" md="6" sm="12">
                            <div className='text-center p-4 shadow rounded-3'>
                                <img src={img1} className="w-100" alt="Technician loosening a compressed air tank." />
                                <h5 className='commontitlefive color-blue my-3'>
                                    Compressed Air Flow Measurement Meters
                                </h5>
                                <p className='commonParaThree WPS2-card-p'>
                                    In most industrial facilities Compressed Dry Air (CDA)
                                    is a primary component of overall energy use. In fact,
                                    compressed air is considered to be the “fourth utility”
                                    after electricity, natural gas and water, and its cost
                                    is very often higher than the other three when compared
                                    by cost per delivered unit of energy.
                                </p>
                                <NavLink to="/whitepapers/compressedair" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Read More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="4" md="6" sm="12">
                            <div className='text-center p-4 shadow rounded-3'>
                                <img src={img2} className="w-100" alt="Worker checking an HVAC system" />
                                <h5 className='commontitlefive color-blue my-3'>
                                    HVAC & Air Vent Meters
                                </h5>
                                <p className='commonParaThree WPS2-card-p'>
                                    Thermal Mass Flow HVAC meters are used in a wide variety of
                                    applications. Virtually every commercial structure — factories,
                                    office buildings, hotels, etc. — has an air distribution system
                                    for heating, ventilation and air-conditioning (HVAC).
                                </p>
                                <NavLink to="/whitepapers/hvac" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Read More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="4" md="6" sm="12">
                            <div className='text-center p-4 shadow rounded-3'>
                                <img src={img3} className="w-100" alt="Top view of landfill and bulldozer" />
                                <h5 className='commontitlefive color-blue my-3'>
                                    Landfill Gas & Municipal Flow Meters
                                </h5>
                                <p className='commonParaThree WPS2-card-p'>
                                    Thermal mass flow landfill meters are critical to monitoring gas
                                    process efficiencies. Landfill gas (LFG) is produced by the decomposition
                                    of organic material in landfills. It is a mixture of gases that are required
                                    to be monitored and reported.
                                </p>
                                <NavLink to="/whitepapers/landfill" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Read More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="4" md="6" sm="12">
                            <div className='text-center p-4 shadow rounded-3'>
                                <img src={img4} className="w-100" alt="Natural Gas processing facility" />
                                <h5 className='commontitlefive color-blue my-3'>
                                    Natural Gas Flow Measurement Meter
                                </h5>
                                <p className='commonParaThree WPS2-card-p'>
                                    Thermal mass flow natural gas metering is a key tool. Natural
                                    gas is a fossil fuel composed of a mixture of hydrocarbon gases.
                                    The primary components are methane, ethane and propane. It is the
                                    cleanest burning fossil fuel, producing byproducts of mostly carbon
                                    dioxide and water vapor.
                                </p>
                                <NavLink to="/whitepapers/naturalgas" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Read More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="4" md="6" sm="12">
                            <div className='text-center p-4 shadow rounded-3'>
                                <img src={img5} className="w-100" alt="Wastewater treatment facility" />
                                <h5 className='commontitlefive color-blue my-3'>
                                    Wastewater & Aeration Basin Meters
                                </h5>
                                <p className='commonParaThree WPS2-card-p'>
                                    Thermal mass flow wastewater & aeration basin meters area critical
                                    tool for municipal responsibility for these applications. The
                                    treatment of water and wastewater is a critical element of municipal
                                    responsibility. Increased public and private awareness of water quality,
                                    availability, and cost is a driving force behind the demands for
                                    better efficiency and economy in these processes.
                                </p>
                                <NavLink to="/whitepapers/wastewater" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Read More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="4" md="6" sm="12">
                            <div className='text-center p-4 shadow rounded-3'>
                                <img src={img6} className="w-100" alt="Flue gas stacks" />
                                <h5 className='commontitlefive color-blue my-3'>
                                    Flue Gas Applications
                                </h5>
                                <p className='commonParaThree WPS2-card-p'>
                                    Generally speaking, Flue or Stack gas is the exhaust gas resulting
                                    from any source of combustion. Typical commercial sources of these
                                    gases are ovens, furnaces, boilers or steam generators and power
                                    plants. The need to accurately monitor, measure and report on the
                                    exhaust from commercial combustion systems is increasingly required
                                    by environmental regulations and the resulting company policies.
                                </p>
                                <NavLink to="/whitepapers/fluegas" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Read More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="4" md="6" sm="12">
                            <div className='text-center p-4 shadow rounded-3'>
                                <img src={img7} className="w-100" alt="Flare gas stacks" />
                                <h5 className='commontitlefive color-blue my-3'>
                                    Flare Gas Applications
                                </h5>
                                <p className='commonParaThree WPS2-card-p'>
                                    Various industries use vent and flare gas, such as wastewater treatment,
                                    landfills, refining, oil & gas, etc.. The flare is burning off waste gases
                                    to protect the environment and equipment. Thermal Mass Flow Meters are a
                                    good way for measuring that the flare system is operating as expected.
                                </p>
                                <NavLink to="/whitepapers/flaregas" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Read More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* section-2 */}
        </>
    )
}

export default WhitePapers
