import React,{ useState, useEffect } from 'react';
import useSwr from 'swr';
import { useHistory } from 'react-router';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import AllUsersModal from './AllUsersModal';
import fetcher from 'utils/fetcher';
import { Row, Col} from "reactstrap";
import AllUsersTable from "./AllUsersTable";

function AllUsers() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();
    const [modalLarge, setModalLarge] = React.useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const [requestData, setRequestData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const page = { allUsers: true }

    var { data } = useSwr(`/api/me`, fetcher);
    var { data: Requests } = useSwr(`/api/users/all`, fetcher);

    useEffect(() => {
        if(data){
            setUserData(data);
            if(data.account_type != 'admin'){
                history.push('/portal/quotes');
            }
        }

        if(Requests){
            setRequestData(Requests)
        }
    }, [data, Requests]);

    const headerData = {
        firstname: userData.firstname,
        page: 'ALL REGISTERED USERS'
    }

    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page} /></div>) : undefined}
                    <div className='common-main'>
                        <div className='shadow'>
                            <h2 className='p-3'>ALL REGISTERED USERS</h2>
                            <div className='quotes body p-3'>
                                {/* <div>
                                    {requestData && requestData.map((value) => {
                                        const date = new Date(value.createdAt);
                                        const day = date.getDate();
                                        const month = ("0" + (date.getMonth() + 1)).slice(-2)
                                        const year = date.getFullYear();

                                        return(
                                            <>
                                                <Row type="button" onClick={ () => {
                                                    setModalData(value);
                                                    setModalLarge(!modalLarge);
                                                }}>
                                                    <Col xs="10" className='quotesLink'>
                                                        {value.firstname} {value.lastname},
                                                        ({value.email}),
                                                        ({ value.companyName? value.companyName : <>Account information not filled</> }),
                                                        ({value.account_type})
                                                    </Col>
                                                    <Col className="quotesButtons"> { `${month}/${day}/${year}` } </Col>
                                                </Row>
                                                <hr />
                                            </>
                                        )
                                    })}
                                </div> */}

                                <AllUsersTable 
                                    users={requestData}
                                    setModalData={setModalData}
                                    setModalLarge={setModalLarge}
                                    modalLarge={modalLarge}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AllUsersModal
                modalLarge={modalLarge}
                setModalLarge={setModalLarge}
                userData={modalData}
            />
        </div>
    )
}

export default AllUsers
