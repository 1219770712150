import React from "react";

// reactstrap components
import {
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledTooltip
} from "reactstrap";

function FlowRate({ MakeChangeText, state, checkState }){
  const getValue = (object, value) => {
    if(object){
      return object.valuesObj[value]
    }
  }

  const checkUnits = (unit) => {
    if(checkState(state.process_parameters.flow_rate_units, 'units') === unit){
      return true;
    }
    return false;
  }

  return (
    <>
      <Row>
        <Col className="processParametterCols">
        <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitleProcessParameters">Minimum Flow Rate</span>
              <Button className="questionToolTip" id="MinimumFlowRate" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="MinimumFlowRate" delay={0}>
                Input the minimum gas flow rate you expect in the process line. 
                This will serve as a reference point when your meter is calibrated to your gas type. 
                As a standard reference, all flow rates start at no flow (0).
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlSelect1"
              type="number"
              placeholder="Minimum Flow Rate"
              defaultValue={
                getValue(state.process_parameters.flow_rate_minimum, 'minimum') && 
                getValue(state.process_parameters.flow_rate_minimum, 'minimum')
              }
              onChange={ (e) => MakeChangeText({
                section: 'process_parameters',
                type: 'flow_rate_minimum',
                values: ['minimum'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            >
            </Input>
          </FormGroup>
        </Col>

        <Col className="processParametterCols">
        <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitleProcessParameters">Maximum Flow Rate</span>
              <Button className="questionToolTip" id="MaximumFlowRate" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="MaximumFlowRate" delay={0}>
                Input the maximum gas flow rate you expect in the process line. 
                This will serve as a reference point when your meter is calibrated to your gas type, 
                and will serve as the full scale.
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlSelect1"
              type="number"
              defaultValue={
                checkState(state.process_parameters.flow_rate_maximum, 'maximum') && 
                checkState(state.process_parameters.flow_rate_maximum, 'maximum')
              }
              placeholder="Maximum Flow Rate"
              onChange={ (e) => MakeChangeText({
                section: 'process_parameters',
                type: 'flow_rate_maximum',
                values: ['maximum'],
                price_effect: false,
                text_input: true,
                input_name: 'max_flow_rate',
                option: e
              }, e)}
            >
            </Input>
          </FormGroup>
        </Col>

        <Col className="processParametterCols">
          <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitleProcessParameters">NORMAL FLOW RATE</span>
              <Button className="questionToolTip" id="OPTIMALFLOWRATE" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="OPTIMALFLOWRATE" delay={0}>
                Input your optimal gas flow rate. 
                This will serve as a reference point when your meter is calibrated to your gas type. 
                This is usually between 50-80% of your specified flow range.
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlSelect1"
              type="text"
              placeholder="Optimal Flow Rate"
              defaultValue={
                checkState(state.process_parameters.flow_rate_optimal, 'optimal') && 
                checkState(state.process_parameters.flow_rate_optimal, 'optimal')
              }
              onChange={ (e) => MakeChangeText({
                section: 'process_parameters',
                type: 'flow_rate_optimal',
                values: ['optimal'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            >
            </Input>
          </FormGroup>
        </Col>

        <Col className="processParametterCols">
          <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitleProcessParameters">Units</span>
              <Button className="questionToolTip" id="UnitsFLOWRATE" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="UnitsFLOWRATE" delay={0}>
                Units
              </UncontrolledTooltip>
              </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlSelect1"
              type="select"
              onChange={ (e) => MakeChangeText({
                section: 'process_parameters',
                type: 'flow_rate_units',
                values: ['units'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            >
              <option value="" selected disabled hidden>Units</option>
              <option selected={checkUnits('SCFS')} value="SCFS">SCFS</option>
              <option selected={checkUnits('SCFM')} value="SCFM">SCFM</option>
              <option selected={checkUnits('SCFH')} value="SCFH">SCFH</option>
              <option selected={checkUnits('SCFD')} value="SCFD">SCFD</option>
              <option selected={checkUnits('LB/S')} value="LB/S">LB/S</option>
              <option selected={checkUnits('LB/M')} value="LB/M">LB/M</option>
              <option selected={checkUnits('LB/H')} value="LB/H">LB/H</option>
              <option selected={checkUnits('LB/D')} value="LB/D">LB/D</option>
              <option selected={checkUnits('STPM')} value="STPM">STPM</option>
              <option selected={checkUnits('SCIM')} value="SCIM">SCIM</option>
              <option selected={checkUnits('SCIH')} value="SCIH">SCIH</option>
              <option selected={checkUnits('SFPS')} value="SFPS">SFPS</option>
              <option selected={checkUnits('SFPM')} value="SFPM">SFPM</option>
              <option selected={checkUnits('BTUM')} value="BTUM">BTUM</option>
              <option selected={checkUnits('BTUH')} value="BTUH">BTUH</option>
              <option selected={checkUnits('MCFD')} value="MCFD">MCFD</option>
              <option selected={checkUnits('SLPS')} value="SLPS">SLPS</option>
              <option selected={checkUnits('SLMP')} value="SLMP">SLPM</option>
              <option selected={checkUnits('SLPH')} value="SLPH">SLPH</option>
              <option selected={checkUnits('SCCM')} value="SCCM">SCCM</option>
              <option selected={checkUnits('KG/S')} value="KG/S">KG/S</option>
              <option selected={checkUnits('KG/M')} value="KG/M">KG/M</option>
              <option selected={checkUnits('KG/H')} value="KG/H">KG/H</option>
              <option selected={checkUnits('KG/D')} value="KG/D">KG/D</option>
              <option selected={checkUnits('MTPM')} value="MTPM">MTPM</option>
              <option selected={checkUnits('NMPS')} value="NMPS">NMPS</option>
              <option selected={checkUnits('NMPM')} value="NMPM">NMPM</option>
              <option selected={checkUnits('NMPH')} value="NMPH">NMPH</option>
              <option selected={checkUnits('NCMS')} value="NCMS">NCMS</option>
              <option selected={checkUnits('NCMM')} value="NCMM">NCMM</option>
              <option selected={checkUnits('NCMH')} value="NCMH">NCMH</option>
              <option selected={checkUnits('SCMH')} value="SCMH">SCMH</option>
              <option selected={checkUnits('NLPM')} value="NLPM">NLPM</option>
              <option selected={checkUnits('NLPH')} value="NLPH">NLPH</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default FlowRate;
