import React, { useEffect, lazy, Suspense } from 'react';
import { NavLink, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import './Home.css';

import s5ione from '../../assets/img/images/section-5-1sticon.png';
import s5itwo from '../../assets/img/images/section-5-2ndicon.png';
import s5ithree from '../../assets/img/images/section-5-3rdicon.png';
import s5ifour from '../../assets/img/images/section-5-4thicon.png';

const Meters = lazy(() => import('./Meters'));
const DocumentsAndDownloads = lazy(() => import('./DocumentsAndDownloads'));
const YourMeter = lazy(() => import('./YourMeter'));
const ContactSection = lazy(() => import('./ContactSection/ContactSection'));

function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Suspense fallback={<div>Loading...</div>}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>EPI – Thermal Mass Flow Meters</title>
                    <meta name="description" content="Discover Eldridge Products Inc’s (EPI) production of high-quality thermal mass flow meters and switches for measuring almost any type of gas flow." />
                    <link rel="canonical" href="https://epiflow.com/" />
                </Helmet>
                <div className='home-main'>
                    {/* section 1 */}
                        <section className='section-1'>
                            <div className='container'>
                                <div className='row height'>
                                    <div className='col-lg-6 d-flex'>
                                        <div>
                                            <h1 className='heading-text'>Accuracy, Reliability, Integrity.</h1>
                                            <h2 className='heading-secondary'>Masters of Flow Since 1988.</h2>
                                            <h2 className='heading-secondary fw-bold'>ISO 9001:2015 <u onClick={() => window.open('https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/files%2FEldridge%20Products%20Inc.%20-%20ISO%209001-2015%20QMS%20Certificate%20241004.pdf?alt=media&token=1a2b2b94-0990-4d89-bd4d-84b56aa86e60', '_blank')} role="button" className='cursor'>Certified.</u></h2>
                                            {/* <h5>Over 30 Years of Thermal Mass Flow Meter Excellence</h5> */}
                                            <NavLink className="noPadding" tag={Link} to='/allproducts'>
                                                <Button className="btn btn-light mt-4">
                                                    Choose a Meter
                                                </Button>
                                            </NavLink>
                                            {/* <button className='btn btn-light mt-4'>Choose a Meter</button> */}
                                        </div>
                                    </div>
                                    <div className='col-lg-6 d-flex justify-content-center align-items-center'>
                                        <div className='responsiveVid'>
                                            <iframe className='rounded homePageVid' src="https://www.youtube.com/embed/lCVTHlj5CwM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                    {/* section 1 */}

                    {/* section 2 */}
                        <Meters />
                    {/* section 2 */}

                    {/* section 3 */}
                        <DocumentsAndDownloads />
                    {/* section 3 */}

                    {/* section 4- */}
                        <YourMeter />
                    {/* section 4- */}

                    {/* section 4 */}
                    <section className='section-4'>
                        <div className='container'>
                            <div className='section-4-header pt-5'>
                                <h3 className='section-header'>What Our Customers Are Saying</h3>
                            </div>
                            <div className='row mt-4 pb-5'>
                            <div className='col-md-6'>
                                <div class="card">
                                {
                                // <img src={circleimg} class="card-img-top" alt="..." />
                                }
                                    <div class="card-body">
                                        <div className='s4rating'>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                        </div>
                                        <p class="card-text">
                                        The Eldridge Products 9800 Series FAT flowmeters performed exactly as described. The MIT Reactor Laboratory installed two redundant probes in the 24-inch square duct that feeds 8000 CFM environmental air to our 150 ft. tall exhaust stack. One probe is centered in a 5 ft. horizontal run, while the other is centered in a 5 ft. vertical run. The vertical section is at the discharge of the blower and turns 90 degrees, which feeds the horizontal section and makes another 90 degree turn up into the stack. The velocity data must be accurate as it is used to calculate radioactive effluents at our reactor in the event of an accident. (which will never happen!) The data from both meters are in agreement despite the vertical meter located close to the blower discharge. I am very happy with our purchase and would recommend them to anyone needing accurate flow measurements in ductwork that may not be a linear run.
                                        </p>
                                        <h5 class="card-title">Anonymous</h5>
                                    </div>
                                </div>
                            </div>
                                <div className='col-md-6'>
                                    <div class="card">
                                    {
                                        // <img src={circleimg} class="card-img-top" alt="..." />
                                    }
                                        <div class="card-body">
                                            <div className='s4rating'>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                            </div>
                                            <p class="card-text">The FAT probe at our site is used for a pump and treat system which uses air stripping. We use the air flow meter to measure and achieve a specific air-to-water treatment ratio and we generally have air flows of up to 4,000 SCFM. The flow meter is placed between two treatment units with limited space and has functioned with minimal fluctuation in measurements (±100 SCFM) &#8212; We've had a good experience with the FAT probe at our treatment plant.</p>
                                            <h5 class="card-title">J. Raul Ramirez - Tetra Tech Inc.</h5>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>
                    {/* section 4 */}
                    {/* section 5 start */}
                    <section className='section-5'>
                        <div className='container'>
                            <div className='section-4-header pt-5'>
                                <h3 className='section-header'>What Sets EPI Apart</h3>
                            </div>
                            <div className='row mt-5 pb-3'>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div class="card">
                                        <img src={s5ione} class="card-img-top" alt="Unique Solutions Icon" />
                                        <div class="card-body">
                                            <h5 class="card-title">Unique Solutions</h5>
                                            <p class="card-text">
                                                Thermal flow metering devices from EPI are entirely customizable. Begin personalizing yours today!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div class="card">
                                        <img src={s5itwo} class="card-img-top" alt="Exceptional Service Icon" />
                                        <div class="card-body">
                                            <h5 class="card-title">Exceptional Service</h5>
                                            <p class="card-text">
                                                We provide the most excellent customer service in the business. Whatever you require, we are here to assist you in any way we can.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div class="card">
                                        <img src={s5ithree} class="card-img-top" alt="Quick Delivery Icon" />
                                        <div class="card-body">
                                            <h5 class="card-title">Quick Delivery</h5>
                                            <p class="card-text">
                                                To guarantee that your meter arrives soon, the period between your order and our shipment is streamlined.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div class="card">
                                        <img src={s5ifour} class="card-img-top" alt="Long-Term Warranty Icon" />
                                        <div class="card-body">
                                            <h5 class="card-title">Long-Term Warranty</h5>
                                            <p class="card-text">
                                                EPI offers a complimentary one-year warranty with every flow meter purchased. Extended warranties are available on our meter customizer.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section 5 end */}
                    {/* section 6 start */}
                    <section className='section-6'>
                        <div className='container'>
                            <div className='row pt-5 pb-5'>
                                <div className='col-md-4 d-flex justify-content-center text-center'>
                                    <div>
                                        <h3 className='statHeader'>30+</h3>
                                        <p>Years in Operation</p>
                                    </div>
                                </div>
                                <div className='col-md-4 d-flex justify-content-center text-center'>
                                    <div>
                                        <h3 className='statHeader'>USA</h3>
                                        <p>Made & Manufactured</p>
                                    </div>
                                </div>
                                <div className='col-md-4 d-flex justify-content-center align-items-center text-center '>
                                    <div>
                                        <h3 className='statHeader'>10,000+</h3>
                                        <p>Meters Sold</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section 6 end */}
                    {/* section 7 start */}
                    <section className='section-7'>
                        <div className='container'>
                            <div className='section-4-header pt-5'>
                                <h3 className='section-header'>Get In Touch</h3>
                            </div><br />
                            <ContactSection/>
                            <div className='d-block text-center'>
                                <NavLink tag={Link} to="/contactus">
                                    <Button className="button1 loginbtn" style={{ 'font-size': '1.0em' }}>
                                        Send Us a Message
                                    </Button>
                                </NavLink>
                            </div>
                        </div>
                    </section>
                    {/* section 7 end */}
                    {/* section 8 start */}
                    <section className='section-8'>
                        <div className='container'>
                            <div className='row pt-5 pb-5'>
                                <div className='col-md-6 d-flex align-items-center'>
                                    <div>
                                        <h3 className='s3-h2'>About Eldridge Products, Inc.</h3>
                                        <p className='s3-p'>
                                            EPI offers a comprehensive range of thermal equipment for measuring gas flow. Our flow meters and switches are recognized for their exceptional quality, dependability, ease of use, and longevity, thanks to over thirty years of expertise in thermal mass flow metering design and manufacture. You may learn more about what makes EPI unique on our About page.
                                        </p>
                                        <a href="/aboutus" className='btn btn-primary'>About Us</a>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='img-div'>
                                        <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FAbout%2Fabout-epi-home.png?alt=media&token=cbffd0ec-7cd4-42a2-8576-9188c065937b" alt="About EPI" className='w-100' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section 8 end */}
                    {/* section 9 start */}
                    <section className='section-9'>
                        <div className='container'>
                            <div className='section-4-header pt-5'>
                                <h3 className='section-header pb-3'>EPI’s Patented Flow Averaging Tube<sup>TM</sup> Technology</h3>
                                <div className='row pb-5'>
                                    <div className='col-md-6'>
                                        <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Fhomepage%2FFlow-Averaging-Tube-Closeup.png?alt=media&token=f820da14-02a8-4e72-abe3-b84e1ee9c9d5" className='imgResponsive' alt="EPI Flow Averaging Tube Closeup"/>
                                    </div>
                                    <div className='col-md-6 d-flex align-items-center '>
                                        <div className='text-start'>
                                            <p className='s3-p'>
                                                Our innovative Flow Averaging Tubes™ (FAT) directly calculates mass flow using convective heat transfer and is best suited to most applications with a limited straight run. The upstream straight run can be decreased to three diameters in many systems. Along the length of the upstream impact surface, the probe contains several large diameter entrance openings. The pressure at each entrance port is averaged inside the tube to produce the axial flow through the tube and over our flow sensor. The gas returns to the main flow stream through the ports near the detecting devices. Minor adjustments may be required to attain the best accuracy if there are anomalies in the real flow profile or installations in non-circular ducts.
                                            </p>
                                            <a href="/allproducts" className='btn first-button'>Customize a Meter</a>
                                            <a href="/technicalinfo" className='btn second-button'>More About EPI Technology</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section 9 end */}
                    {/* section 10 start */}
                    <section className='section-10'>
                        <div className='container'>
                            <div className='section-4-header pt-5'>
                                <h3 className='section-header pb-3'>What is a Thermal Mass Flow Meter, and How Does It Work?</h3>
                                <div className='row pb-5'>
                                    <div className='col-md-6 d-flex align-items-center '>
                                        <div className='text-start'>
                                            <p className='s3-p'>
                                                Thermal mass flow meters detect mass flow directly using the convective heat transfer theory. Two ratiometrically matched, reference-grade platinum Resistance Temperature Detectors (RTDs) are used in EPI's unique thermal mass flow sensors. The platinum sensing element wire is encased in a 316 Stainless Steel sheath or a Hastelloy C sheath if one is required. Our intelligent sensor technology, which is controlled by a microprocessor, preferentially warms one RTD. At the same time, the other serves as a temperature reference. The heat from the first RTD is dissipated by the process gas flow, increasing the power necessary to keep an equilibrium between the RTDs. This rise is proportional to the molecular rate of flow of the gas. Our sensors are temperature adjusted for a wide range of process gas temperatures. As a result, they are unaffected by pressure fluctuations, resulting in a real mass flow rate indication of the output signal.
                                            </p>
                                            <a href="/technicalinfo/fullguideflowmeters" className='btn first-button'>View Full Guide</a>
                                            <a href="/technicalinfo/industrialapplications" className='btn second-button'>See Industry Applications</a>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='img-div'>
                                            <img src='https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Fhomepage%2Fthermal-mass-flow-meter-example.png?alt=media&token=acc56fe3-3f07-46be-9219-539229da4e13' className='imgResponsive' alt="MPNH GEN Flow Meter in warehouse"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section 10 end */}
                </div>
            </Suspense>
        </div>
    );
};

export default Home;
