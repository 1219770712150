import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";


import TechnicalInfoFooter from 'components/Footers/TechnicalInfoFooter';

function Methane() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Methane Gas Measurement</title>
            <meta name="description" content="Get information about the use of flow meters to measure methane gas flow with EPI’s state-of-the-art thermal mass flow meters. Dive into our article!" />
            <link rel="canonical" href="https://epiflow.com/industrialapplications/methane" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner FullGuide-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/industrialapplications" tag={Link}>Industrial Applications</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/methane" tag={Link}>Methane Gas</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Methane</h2>
                        <p className='cover-title-p'>
                            Get information about the use of flow meters to measure methane gas flow.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <div className='DownloadPDfbtn-div'>
                        <div className='position-relative d-inline'>
                            <audio src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fapplications%2FMethane.mp3?alt=media&token=be9eac91-c50e-4aac-97ce-269274dbbcfb" controls />
                        </div>
                    </div>
                    <h4 className='commontitleTwo text-center mb-2'>
                        Methane Gas Measurement with Thermal Mass Flow Meters
                    </h4>
                    <p className='commonParaThree'>
                        Methane is a greenhouse gas that is emitted naturally from a number of sources, including wetlands, the
                        oceans, the breakdown of organic materials like human and animal waste, wildfires, and more. Methane
                        is also a key gas used in commercial and industrial applications. On average, it makes up <a href="https://www.enbridgegas.com/about-enbridge-gas/learn-about-natural-gas">94% of the
                        natural gas used to power industry and our homes</a>, and it’s also key in everything from chemical
                        production to advanced rocket fuels.
                        <br /><br />

                        Unfortunately, in addition to its usefulness as an industrial input, methane is also extremely good at
                        capturing heat, and it’s a direct contributor to the greenhouse effect driving climate change. So, while we
                        need methane, we also need to carefully control how we use it and vent it. The following is a quick primer
                        on how methane gas is used, how it makes its way back into the atmosphere, and how it can be
                        accurately measured and controlled to maximize profitability and minimize environmental impact.
                    </p>
                    <br /><br />
                    <h4 className='commontitlefive mb-2'>
                        Commercial Uses for Methane Gas
                    </h4>
                    <p className='commonParaThree'>
                        Methane, both in its pure form and as the primary component of natural gas, is an important industrial
                        input, with a variety of uses covering everything from running machinery to sending cargo into space.
                        Because of its widespread use and importance to industry, it’s extremely important for companies using
                        methane in their processes to closely monitor its movement to maximize safety, minimize profit-draining
                        waste, and ensure frictionless production.
                    </p>
                    <br /><br />

                    <h4 className='commontitlefive mb-2'>
                        Natural Gas
                    </h4>
                    <p className='commonParaThree'>
                        Natural gas – composed of 90% methane or more – is an extremely common fuel which is burned in a
                        variety of commercial and industrial applications, including industrial furnaces, driers, heaters, and
                        generators. Natural gas is also burned to run generator turbines, and is responsible for <a href="https://www.eia.gov/energyexplained/natural-gas/use-of-natural-gas.php">40% of utility-
                        scale electricity production across the United States.</a>
                    </p>
                    <br /><br />

                    <h4 className='commontitlefive mb-2'>
                        Chemical Manufacturing
                    </h4>
                    <p className='commonParaThree'>
                        Methane is used to produce a variety of chemicals that are important to both industry and everyday life.
                        Methane-rich natural gas is a primary input in the process of steam reforming, which uses the reaction
                        between methane’s hydrocarbons and water to create hydrogen. Hydrogen is in turn used as a fuel
                        source and as an input in chemicals like ammonia and hydrochloric acid. Methane is also used to
                        produce methanol, the non-drinkable alcohol used in a variety of solvents and everyday products like
                        antifreeze.
                    </p>
                    <br /><br />

                    <h4 className='commontitlefive mb-2'>
                        Rocket Fuel
                    </h4>
                    <p className='commonParaThree'>
                        Methane is now being used as a key component in rocket fuel, most notably in the <a href="https://www.wired.co.uk/article/spacex-raptor-engine-starship"></a> SpaceX Raptor engine
                        which powers Starship, the company’s reusable super-heavy launch system. Methane as a rocket fuel
                        offers two major benefits: its low cost allows for more economical launches, and its clean burning
                        prevents waste deposits from building up inside the engine – an important factor for reusable systems like
                        Raptor. Those qualities, combined with the strides being made in efficiency, could make methane one of
                        the most important inputs in future space travel.
                    </p>
                    <br /><br />

                    <h4 className='commontitleTwo text-center mb-2'>
                        Controlling Methane as a Byproduct
                    </h4>
                    <p className='commonParaThree'>
                        Methane is a greenhouse gas and, as such, it can have a big impact on the atmosphere and the warming
                        of our planet. So, while methane itself is not directly harmful to living things, its environmental effects are.
                        Unsurprisingly, many companies work hard to monitor and minimize their methane emissions, and
                        <a href="https://www.wired.co.uk/article/spacex-raptor-engine-starship">sweeping new regulations have been proposed</a> to significantly limit the amount of methane emitted from
                        oil and gas production – one of the largest sources of natural gas emissions. However, the natural gas
                        that is a byproduct of oil and gas production is just one of the many sources of methane being vented into
                        the atmosphere every day by commercial and industrial activities. Everything from our water, to our
                        garbage, to our food contributes, making the careful monitoring and, when possible, capture and reuse of
                        methane crucial to our planet’s long-term health.
                    </p>

                    <h4 className='commontitlefive mb-2'>
                        Agricultural Methane
                    </h4>
                    <p className='commonParaThree'>
                        Agriculture is the world’s <a href="https://www.iea.org/reports/methane-tracker-2020">second-largest producer of methane emissions</a>, topped only by the gas released
                        naturally by wetlands. Many people have heard the anecdote that “cow farts” are one of the major
                        contributors to global warming (it turns out <a href="https://www.iea.org/reports/methane-tracker-2020">their burps are worse</a>), but there is a great deal of truth behind
                        the humor, as livestock waste is a top source of agricultural methane emissions. Production of certain
                        foods like rice and the wastage common in food production are also major contributors. All that methane
                        being produced is referred to as biogas, and farmers have become wise to its value as a fuel source.
                        Today, capturing biogas is becoming big business, and the ability to measure its release is of key
                        importance.
                    </p>

                    <h4 className='commontitlefive mb-2'>
                        Landfill Gas
                    </h4>
                    <p className='commonParaThree'>
                        Municipal landfills contain an enormous amount of organic material, and as that material breaks down, it
                        releases a gas composed of 50% carbon dioxide and 50% methane. With the amount of garbage being
                        dumped in landfills across the country each day, it’s no surprise that <a href="https://www.epa.gov/lmop/basic-information-about-landfill-gas">municipal landfill gas accounts for
                        15% of total methane emissions</a> in the United States. Landfills are responsible for carefully tracking and
                        reporting their methane emissions under EPA rule <a href="https://www.ecfr.gov/current/title-40/chapter-I/subchapter-C/part-98?toc=1">40 CFR Part 98</a>, which can be a challenge due to the
                        extremely low flow of gas passively emitted from waste breakdown. Much like the methane resulting from
                        agricultural activity, landfill gas can also be redirected, captured, and burned, turning a problem into a
                        potential opportunity.
                    </p>

                    <h4 className='commontitlefive mb-2'>
                        Anaerobic Digester Gas
                    </h4>
                    <p className='commonParaThree'>
                        Anaerobic digesters use bacteria to break down organic matter in a low oxygen environment, creating
                        methane as a byproduct. They’re used in agricultural settings, but they’re also emerging as a way to
                        improve wastewater treatment systems and recapture energy from our water. From food scraps to grease
                        to fertilizer runoff and more, an enormous amount of organic waste ends up in our water supply.
                        Traditionally, that waste is filtered out and turned into sludge, which must then be disposed of. Today,
                        only a small fraction of the country’s 16,000 treatment plants use anaerobic digesters, but their ability to
                        redirect sludge from landfills and incinerators and recapture useful methane as a fuel source all but
                        guarantees anaerobic digestion will become common in the future, creating a greater need to carefully
                        monitor and redirect biogas emerging from the systems.
                    </p>
                    <br /><br />

                    <h4 className='commontitleTwo text-center mb-2'>
                        Monitoring Methane Flow with a Thermal Mass Flow Meter
                    </h4>
                    <p className='commonParaThree'>
                        Whether controlling venting and emissions, recapturing energy, or feeding industrial processes, the key to
                        profitable, environmentally conscious methane management is good flow measurement. Only by
                        maintaining an accurate picture of the flow within a system can users ensure that fuel mixtures are
                        correct, leaks are detected, inefficiencies are pinpointed, processes run optimally, and the amount of
                        methane vented into the atmosphere is kept to a minimum.
                        <br /><br />
                        Thermal mass flow meters are tools designed specifically to enable better measurement and
                        management of methane and other gasses. They measure gas flow by detecting heat changes across
                        the system, and offer a level of accuracy, simplicity, and affordability that other types of flow metering
                        can’t match.
                    </p>
                    <br /><br />

                    <h4 className='commontitleTwo mb-2'>
                        Extreme Accuracy
                    </h4>
                    <p className='commonParaThree'>
                        Thermal mass flow meters offer the accuracy, repeatability, and reliability necessary to measure methane
                        gas flow across all industrial, commercial, and municipal applications. With accuracy often within 1%
                        across their full operating range, thermal mass flow meters are perfect for environmental regulation
                        compliance that requires consistent, accurate reporting. They also make it easy to maintain ideal feed
                        rates and detect leaks to ensure maximum safety and profitability in industrial environments.
                    </p>
                    <br /><br />

                    <h4 className='commontitleTwo mb-2'>
                        Low-Flow Capabilities
                    </h4>
                    <p className='commonParaThree'>
                        Thermal mass flow meters enable methane flow measurement across a variety of flow rates. Their ability
                        to accurately and repeatably measure even very low flow rates makes them ideally suited for challenging
                        environments like landfills, where emissions are slow and diffuse.
                    </p>
                    <br /><br />

                    <h4 className='commontitleTwo mb-2'>
                        Negligent Impact on Overall Pressure
                    </h4>
                    <p className='commonParaThree'>
                        Both inline and insertion thermal mass flow meters can accurately measure methane gas flow while
                        having a near-zero impact on system pressure. As a result, thermal mass flow meters can be used even
                        in applications where very specific pressures and flow rates need to be maintained without interruption,
                        like industrial combustion.
                    </p>
                    <br /><br />

                    <h4 className='commontitleTwo mb-2'>
                        Ease of Installation and Minimal Maintenance
                    </h4>
                    <p className='commonParaThree'>
                        Thermal mass flow meters can be easily installed, either inline as permanent fixtures or as retrofitted
                        insertions in pipes and ducting. Because they contain no moving parts, thermal mass flow meters are also
                        extremely reliable, offering very low downtime and minimal maintenance requirements. Those two factors
                        make thermal mass flow meters the most economical way of accurately and repeatably measuring
                        methane gas flow.
                    </p>
                    <br /><br />

                    <h4 className='commontitleTwo text-center mb-2'>
                        Contact EPI Today About Our Methane Thermal Mass Flow Meters
                    </h4>
                    <p className='commonParaThree'>
                        Eldridge Products Inc. is a leading manufacturer of thermal mass flow meters designed to enable the
                        accurate measurement of gasses in industrial and environmental applications, including methane gas. To
                        find out more about our full line of inline and insertion thermal mass flow meters and how they can help
                        your plant improve your flow metering, <a href="/contactus">click here to reach out to a member of the EPI team today.</a>
                    </p>
                </Container>
            </div>
        </>
    )
}

export default Methane
