import React from "react";

import { Input } from "reactstrap";

function CommunicationsOptions({ MakeChangeDropdown, state, checkState }){

  const Options = () => {
    if(checkState(state.input_power_communication.input_power, 'vdc_18_24')){
      return(
        <>
          <option selected={checkState(state.input_power_communication.communications_options, 'standard')} value="standard">Standard (MODBUS RTU)</option>
          <option selected={checkState(state.input_power_communication.communications_options, 'multi_range')} value="multi_range">Multi-Range</option>
          <option selected={checkState(state.input_power_communication.communications_options, 'hart')} value="hart">HART</option>
          <option selected={checkState(state.input_power_communication.communications_options, 'bacnet')} value="bacnet">BACnet</option>
          <option selected={checkState(state.input_power_communication.communications_options, 'profibus')} value="profibus">Profibus</option>
        </>
      )
    } else {
      return(
        <>
        <option selected={checkState(state.input_power_communication.communications_options, 'standard')} value="standard">Standard (MODBUS RTU)</option>
        <option selected={checkState(state.input_power_communication.communications_options, 'multi_range')} value="multi_range">Multi-Range</option>
        <option selected={checkState(state.input_power_communication.communications_options, 'bacnet')} value="bacnet">BACnet</option>
        </>
      )
    }
  }

  const getMeterModelValue = (value) => {
    if(value === 'standard'){
      return 'MB';
    }

    if(value === 'multi_range'){
      return 'MR';
    }

    if(value === 'multi_range_no_temp'){
      return 'MR';
    }

    if(value === 'hart'){
      return 'HT';
    }

    if(value === 'bacnet'){
      return 'BN';
    }

    if(value === 'profibus'){
      return 'PB';
    }
  }

  return(
    <>
      <Input
        className="epiInputSize form-select"
        id="exampleFormControlSelect1"
        type="select"
        onChange={ (e) => MakeChangeDropdown({
          section: 'input_power_communication',
          type: 'communications_options',
          values: [
            'standard',
            'multi_range',
            'hart',
            'bacnet',
            'profibus'
          ],
          price_effect: false,
          change_effect: 'communications',
          meter_model_effect: true,
          meter_model_type: 'communications_options_2',
          meter_model_value: getMeterModelValue(e.target.value),
          option: e,
        }, e)}
      >
        <Options />
      </Input>
    </>
  )
}

export default CommunicationsOptions;
