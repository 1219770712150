import React, { useState, useEffect } from 'react';
import 'assets/css/EditAccount.css';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import CompanyAddress from './CompanyAddress';
import ShipToAddresses from './ShipToAddresses';
import InvoiceAddresses from './InvoiceAddresses';

function MyAddresses() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const page = { myAddresses: true }

    const headerData = {
        page: 'MY ADDRESSES'
    }
    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page}/></div>) : undefined}
                    <CompanyAddress />
                    <ShipToAddresses />
                    <InvoiceAddresses />
                </div>
            </div>
        </div>
    )
}

export default MyAddresses
