import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap'

function ProductRegistration() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            {/* section-1 */}
            <div className='pr-section-1'>
                <div className='image-container'>
                    <div className='about-title container pt-2 pb-2'>
                        <span className='me-2'>SUPPORT</span>
                        <FontAwesomeIcon icon={faChevronRight} className='icon' />
                        <span className='ml-2'>PRODUCT REGISTRATION</span>
                    </div>
                    <div>
                        <h2 className='cover-title-h2'>Product Registration</h2>
                        <p className='cover-title-p'>Page Description</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <div className='pt-5 pb-5'>
                <Container>
                    <h3 className='commontitleOne pt-5 mb-4 text-center'>Why You Should Register</h3>
                    <Row>
                        <Col lg="4" md="6" sm="12">
                            <Card className='card-shadow-none'>
                                <CardBody className='text-center p-4'>
                                    <CardTitle className='commontitlefour'>
                                        Benefit 1
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2'>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna
                                        aliqua.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" md="6" sm="12">
                            <Card className='card-shadow-none'>
                                <CardBody className='text-center p-4'>
                                    <CardTitle className='commontitlefour'>
                                        Benefit 2
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2'>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna
                                        aliqua.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" md="6" sm="12">
                            <Card className='card-shadow-none'>
                                <CardBody className='text-center p-4'>
                                    <CardTitle className='commontitlefour'>
                                        Benefit 3
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2'>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna
                                        aliqua.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section-2 */}
            {/* section-3 */}
            <div className='background-colorOne pt-5 pb-5'>
                <Container className='text-center'>
                    <h3 className='commontitleOne pt-5 mb-4'>Our Shipping Policy</h3>
                    <p className='commonParaThree'>Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore
                    </p>
                    <h4 className='commontitlefive mt-2 mb-4'>You must be logged in to register a meter.</h4>
                    <Row className='text-center'>
                        <Col md="6" sm="12" className='d-flex justify-content-md-end justify-content-sm-center'>
                            <div className='pt-4 pb-5'>
                                <a href="/" className='button1'>Request a Return</a>
                            </div>
                        </Col>
                        <Col md="6" sm="12" className='d-flex justify-content-md-start justify-content-sm-center'>
                            <div className='pt-4 pb-5'>
                                <a href="/" className='button3'>View Warranty Terms</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section-3 */}
        </>
    )
}

export default ProductRegistration
