import React from 'react'
import { Modal, Button, Input } from 'reactstrap';

function DeleteModal({quoteData, deleteQuote, deleteQuoteSet, setEnteredQuoteId, setModalLive, modalLive}) {
  return (
    <div>
        <Modal toggle={() => setModalLive(false)} isOpen={modalLive}>
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLiveLabel">
                    <b class="text-center">Delete Configuration</b>
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    type="button"
                    onClick={() => setModalLive(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <p>To delete the configuration, please type the Confirmation # (<strong>{quoteData.quoteId}</strong>) into the box below to confirm.</p>
                <Input
                    id="exampleFormControlInput1"
                    placeholder="Enter Configuration ID"
                    type="text"
                    onChange={(e) => setEnteredQuoteId(e.target.value)}
                ></Input>
            </div>
            <div className="modal-footer">
            <div class="row w-100">
              <div class="d-flex justify-content-end">
                <Button
                  className="btn btn-outline mr-2"
                  color="secondary"
                  type="button"
                  onClick={() => setModalLive(false)}
                >
                    Close
                </Button>
                <Button
                  className="btn btn-gradient"
                  disabled={!deleteQuoteSet && ('disable') }
                  color="primary"
                  type="button"
                  onClick={() => deleteQuote()}
                >
                  Confirm Delete
                </Button>
              </div>
            </div>
          </div>
        </Modal>
    </div>
  )
}

export default DeleteModal
