import React from "react";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router";

import HazInline from "./haz/HazInline";
import HazInsertion from "./haz/HazInsertion";
import GenInline from "./Gen/GenInline";
import GenInsertion from "./Gen/GenInsertion";
import ValInline from "./Val/ValInline";
import ValInsertion from "./Val/ValInsertion";
import PureInline from "./Pure/PureInline";
import XtInline from "./Xt/XtInline";
import XtInsertion from "./Xt/XtInsertion";
import SwitchInline from "./Switch/SwitchInline";
import SwitchInsertion from "./Switch/SwitchInsertion";
import AxgenInline from "./Axgen/AxgenInline";
import RagenInsertion from "./Ragen/RagenInsertion";
import MultigenInsertion from "./Multigen/MultigenInsertion";

function ViewMeterRouter() {
  const { meter, type, product, tab } = useParams();
  console.log('Show me product url 1.0', meter, type, product, tab);

  switch (meter) {
    case 'haz':
      if(type === "inline"){
        return(
          <>
            <HazInline
              product={product}
              productType={type}
              tab={tab}
            />
          </>
        );
      }
  
      if(type == "insertion"){
        return(
          <>
            <HazInsertion
              product={product}
              productType={type}
              tab={tab}
            />
          </>
        );
      }
      break;

    case 'gen':
      if(type === "inline"){
        return(
          <>
            <GenInline
              product={product}
              productType={type}
              tab={tab}
            />
          </>
        );
      }
  
      if(type == "insertion"){
        return(
          <>
            <GenInsertion
              product={product}
              productType={type}
              tab={tab}
            />
          </>
        );
      }
      break;

    case 'val':
      if(type === "inline"){
        return(
          <>
            <ValInline
              product={product}
              productType={type}
              tab={tab}
            />
          </>
        );
      }
  
      if(type == "insertion"){
        return(
          <>
            <ValInsertion
              product={product}
              productType={type}
              tab={tab}
            />
          </>
        );
      }
      break;

    case 'pure':
      if(type === "inline"){
        return(
          <>
            <PureInline
              product={product}
              productType={type}
              tab={tab}
            />
          </>
        );
      }
      break;

    case 'xt':
      if(type === "inline"){
        return(
          <>
            <XtInline
              product={product}
              productType={type}
              tab={tab}
            />
          </>
        );
      }
  
      if(type == "insertion"){
        return(
          <>
            <XtInsertion
              product={product}
              productType={type}
              tab={tab}
            />
          </>
        );
      }
      break;

    case 'multigen':
      if(type == "insertion"){
        return(
          <>
            <MultigenInsertion
              product={product}
              productType={type}
              tab={tab}
            />
          </>
        );
      }
      break;
      
    case 'switch':
      if(type === "inline"){
        return(
          <>
            <SwitchInline
              product={product}
              productType={type}
              tab={tab}
            />
          </>
        );
      }
  
      if(type == "insertion"){
        return(
          <>
            <SwitchInsertion
              product={product}
              productType={type}
              tab={tab}
            />
          </>
        );
      }
      break;

    case 'axgen':
      if(type === "inline"){
        return(
          <>
            <AxgenInline
              product={product}
              productType={type}
              tab={tab}
            />
          </>
        );
      }
      break;

    case 'ragen':
      if(type === "insertion"){
        return(
          <>
            <RagenInsertion
              product={product}
              productType={type}
              tab={tab}
            />
          </>
        );
      }
      break;

  
    default:
      return ( <Redirect to={'/allproducts'} /> );
      break;
  }
}

export default ViewMeterRouter;
