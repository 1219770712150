import React, { useState, useEffect } from "react";

// core components
import ProductHeader from "components/Headers/ProductHeader";
import ProductViewNavbar from "components/Navbars/ProductViewNavbar";
import ProductView from "components/ProductViews/Components/ProductView";
import ProductDescription from "components/ProductViews/Components/ProductDescription";
import ValgenRBCustomizer from "components/Customizer/Views/Val/ValInline/ValgenRBCustomizer";
import TechSpecs from "views/TechSpecs/TechSpecs";
import CADFiles from "views/CADFiles/CADFiles";
import { Helmet } from "react-helmet";

function ValgenRBProductView({productType, tab}){
  const [page, setPage] = useState('discover');

  useEffect(() => {
    if(tab){
      if(tab === 'disc'){
        setPage('discover');
      }
  
      if(tab === 'specs'){
        setPage('specs');
      }
  
      if(tab === 'cad'){
        setPage('cad');
      }  
    }
  }, [tab]);
  
  const productImage = "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInline%2FRB%2FValuMass-400-Inline-Hero.png?alt=media&token=49965a6e-b402-4b5f-9b91-f79791d44ecf";

  const navbarData = {
    product_title: 'SERIES 400 VAL',
    product_title_old: 'SERIES 400',
    product_type: 'INLINE THERMAL MASS FLOW METER'
  }

  const productInfo = {
    model: 'SERIES 400',
    series: navbarData.product_title
  }

  const images = [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInline%2FRB%2FProductImages%2FValuMass-400-Inline.jpg?alt=media&token=c5800f5a-26aa-49e5-8cec-27b6ade017a8",
      altText: "ValuMass™ Series 400 VAL Inline Flow Meter",
      productImageLink: '/productimages/val400'
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInline%2FRB%2FProductImages%2FValuMass-400-Inline-Side.jpg?alt=media&token=07e6ec9a-b2ae-438e-a841-47a35db52468",
      altText: "ValuMass™ Series 400 VAL Inline Side",
      productImageLink: '/productimages/val400_2'
    }
  ];

  const productViewData = {
    title: 'ValuMass™ Series 400 VAL Inline Flow Meters',
    description_small: `
    EPI's Series 400 is an inline thermal mass flow meter part of ValuMass™, a product family with lower-cost options for many flow applications with the high value associated with all of EPI’s flow measurement instrumentation. This inline thermal mass flowmeter is specifically designed with a standard NEMA 4X Rated (IP66) Enclosure, for applications that do not require the hazardous rating.
    <br /><br />
    Besides the different enclosure, all unique engineering of the ValuMass™ Series, such as the two RTD (Resistance Temperature Detector) sensing elements, is also present in the ValuMass™ Series 400 thermal flow meter. These sensors use our proprietary temperature compensation techniques and are made of reference-grade platinum, ceramic, glass, and stainless steel. For superior protection, abrasion, and corrosion resistance, they are slipped in a stainless steel cover.
    <br /><br />
    If you need a highly accurate gas thermal mass flow meter for different applications in ordinary locations, this non-hazardous thermal mass flowmeter with inline meter type is one of your top choices. On the other hand, if you require an insertion meter type of the same flow meter design, then read more about the EPI <a href="/products/val/insertion/val-rb">ValuMass™ Series 440</a>.
    `,
    description: `
    ValuMass™ products include a rugged, cleanable, thermal mass flow sensor. These units consist of a sensor assembly which utilizes two RTD (Resistance Temperature Detector) sensing elements. The sensors are constructed of reference grade platinum, ceramic, glass, and stainless steel. Two platinum resistance sensors are built up upon the ceramic substrate and then given a thin glass coating. The assembly is then slipped into a stainless-steel sheath for corrosion and abrasion resistance. The sensor assembly is large, rugged, and relatively insensitive to dirt buildup. During operation, the temperature sensor constantly measures the ambient temperature of the gas and maintains a reference resistance on one side of a bridge circuit. The second sensor is forced through self-heating to a constant temperature above that of the gas stream and is controlled by the temperature sensor and bridge circuitry. Our bridge maintains the overheat temperature and counterbalances the temperature effects through our proprietary temperature compensation techniques.
    <br /><br />
    400 Series flow meters are approved for use in non-hazardous locations (see specifications).
    <br /><br />
    Inline style thermal mass flow meters include a flow section that is usually specified to match the user’s flow conduit and is then plumbed directly into the process line. This design has the sensing elements mounted directly in the flow section for exposure to the process gas. Our inline style thermal mass flow meters are available in sizes from 1/4″ pipe through 2″ pipe. The 3 ½” long flow sections have MNPT ends as standard for easy mounting in the process line (Contact the factory for pipe sizes over 2”).
    <br /><br />
    Integral style thermal mass flow meters have all the electrical components and connections located within one enclosure. This enclosure is rated for hazardous environments. The enclosure is mounted directly to the inline flow section or to the insertion probe assembly at the point of measurement. The enclosure includes all the electrical connections as well as the linearizing electronics and the display assembly.
    <br /><br />
    Thermal mass flow meters use the principle of convective heat transfer to directly measure mass flow. EPI’s proprietary thermal mass flow sensors use two ratiometrically-matched, reference-grade platinum Resistance Temperature Detectors (RTDs). The platinum sensing element wire is encapsulated in a 316 Stainless Steel sheath or, if specified, a Hastelloy C sheath. Our microcontroller operated smart sensor technology preferentially heats one RTD; the other RTD acts as the temperature reference. The process gas flow dissipates heat from the first RTD, causing an increase in the power required to maintain a balance between the RTDs. This increase is directly related to the gas molecular rate of flow. Our sensors are temperature compensated for a wide process gas temperature range and insensitive to pressure changes, so the output signal is a true mass flow rate signal.
    `,
    meter_type: 'Inline',
    enclosure: 'Integrated Electronics',
    warranty: '1-4',
    certifications: 'CE'
  }

  const techSpecsData = {
    features:{
      titleOne: 'VAL Series 400 Enclosure',
      highlightOneImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FProductFeatures%2Ffeatures-val-400.png?alt=media&token=2cd9bddf-d25f-4bd8-bfe5-f5c66941ef39',
      highlightOneInfo: `Each VAL Series 400 aluminum enclosure includes a powder-coat-painted die-cast base and lid with a seal.
      <br /><br />
      <ul>
        <li>Ratings: IP66, NEMA 1,2,4,4X</li>
      </ul>
      `,
      titleTwo: 'Superior Flow Measurement and Calibration',
      highlightTwoImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ffeatures-image-2.png?alt=media&token=86df9cb6-22d4-4fbc-bc7f-f6b4e267b8ec',
      highlightTwoPdf: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FProductFeatures%2FFlow%20Calibration%20Check.png?alt=media&token=d98121d8-44ae-465d-b603-aa56f7c9bdd6',
      highlightTwoInfo: `EPI flow meters provide accurate, consistent, and rapid real-time flow measurement with a sensor that
      is both temperature and pressure corrective. Each flow meter is calibrated using standards traceable to
      the National Institute of Standards and Technology (NIST).
      <br /><br />

      -Accuracy: +/- (1% of reading + 0.5% of full scale + GTC) <br />
      -Repeatability: +/- 0.2% of full scale <br />
      -Sensor response time: 1 second to 63% of final value <br />
      -Gas temperature coefficient (GTC): 0.02% full scale/°C <br />
      -Gas pressure effect: negligible over +/-50% of factory calibration pressure
      `
    },
    our_process: {
      benefits:[
        {
          title: 'Broad Velocity Range',
          info: `Up to 30,000 SFPM`
        },
        {
          title: 'Low flow sensitivity',
          info: `100:1 Turn down ratio; 10 SFPM (0.05 NMPS) minimum reading.`
        },
        {
          title: 'Wide temperature range',
          info: `VAL Sensor suitable for process gas temperatures up to 257°F (125°C).`
        },
        {
          title: 'Multiple analog outputs',
          info: `4-20mA and 0-5VDC outputs for both flow and temperature`
        },
        {
          title: 'Various communication protocols supported',
          info: `RS485 Modbus RTU (standard) & BACnet`
        },
        {
          title: 'NIST traceable calibration facility',
          info: `Over 30 years of flow calibration expertise`
        },
        {
          title: 'Ease of installation',
          info: `Various configurations to meet your installation requirements`
        },
        {
          title: 'Live Zero',
          info: `Live Zero (EPIVal) to validate your flow meter calibration in both process line and ambient air
          conditions`
        }
      ]
    },
    specifications: {
      specs: [
        {
          title: 'Accuracy, including linearity (Ref.: 21°C)',
          info: `±(1% of Reading + 0.5% of Full Scale + GTC)`
        },
        {
          title: 'Repeatability',
          info: `±0.2% of Full Scale`
        },
        {
          title: 'Linear Signal Output',
          info: `Software Controlled 0 – 5 or 0 – 10VDC (Flow and Temperature),
          4 – 20 mA (Flow and Temperature),
          RS485 Modbus RTU (Flow Rate/Total, Temperature),
          Optional 0 – 1 kHz (proportional to flow for total/counter)`
        },
        {
          title: 'Sensor response time',
          info: `1 second to 63% of final value`
        },
        {
          title: 'Turn down ratio',
          info: `100:1 @ 10 SFPM (.051 NMPS) Minimum Reading`
        },
        {
          title: 'Electronics PCB temperature range',
          info: `-40° to 158°F (-40° to +70°C)`
        },
        {
          title: 'Environmental temperature range',
          info: `-40° to 140°F (-40° to +60°C)`
        },
        {
          title: 'Gas temperature range',
          info: `-40°–392°F (-40°–200°C)`
        },
        {
          title: 'Gas pressure effect',
          info: `Negligible over ± 20% of absolute calibration pressure`
        },
        {
          title: 'Inline Flowmeters',
          info: `500 PSIG`
        },
        {
          title: 'Input power requirement',
          info: `24VDC @ 250mA, 115 VAC 50/60 Hz optional, 230 VAC 50/60 Hz 50mA optional`
        },
        {
          title: 'Transmitter Power Requirements',
          info: `5 Watts or less`
        },
        {
          title: 'Relay Output Rating',
          info: `1 Amp @ 30VDC (33W) (2 Relays, 1 if Freq. Out needed)`
        },
        {
          title: 'Communications',
          info: `RS-232C, RS485 Modbus RTU`
        },
        {
          title: 'Display',
          info: `Optional 2-lines, 16 characters per line, backlit LCD (No Keypad)`
        },
        {
          title: 'Wetted Materials',
          info: `316 & 316L Stainless Steel`
        },
        {
          title: 'Standard Temperature & Pressure (STP)',
          info: `70°F & 29.92″ Hg (Air = 0.075 Lbs. / FT3)`
        },
        {
          title: 'NIST Traceable Calibration',
          info: `Standard`
        },
        {
          title: 'Enclosure Rating',
          info: `NEMA 4X (IP66)`
        },
        {
          title: 'NOTE',
          info: `Specifications subject to change without notice`
        }
      ]
    },
    communication_config: {
      type: 'val400',
      comm_config: [
        {
          output: '1 MECHANICAL RELAY',
          standard: true,
          frequency: false,
          bacnet: true,
          bacnet_frequency: false
        },
        {
          output: '2 MECHANICAL RELAY	',
          standard: true,
          frequency: true,
          bacnet: true,
          bacnet_frequency: true
        },
        {
          output: 'RS 485/MODBUS (R/W)',
          standard: true,
          frequency: true,
          bacnet: false,
          bacnet_frequency: false
        },
        {
          output: 'RS 232 (R/W)',
          standard: true,
          frequency: true,
          bacnet: true,
          bacnet_frequency: true
        },
        {
          output: 'HART (READ ONLY)',
          standard: false,
          frequency: false,
          bacnet: false,
          bacnet_frequency: false
        },
        {
          output: 'PROFIBUS (READ ONLY)',
          standard: false,
          frequency: false,
          bacnet: false,
          bacnet_frequency: false
        },
        {
          output: 'RS 485/BACnet (READ ONLY)',
          standard: false,
          frequency: false,
          bacnet: true,
          bacnet_frequency: true
        }
      ]
    },
    geometry_sizing: {
      cadImg: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FVAL-400-Inline.jpg?alt=media&token=933ebd98-775d-4030-8833-50a01e66c7aa',
      cadImg2: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FVAL-400-Inline-2.jpg?alt=media&token=ae818f48-b6eb-4307-a2ba-58e19dc74674',
      cadImg3: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FVAL-400-Inline-3.jpg?alt=media&token=99413d24-e06b-4e69-a74e-ecf84a6b21dd',
      cadImg4: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FVAL-400-Inline-4.jpg?alt=media&token=b711814b-8cf0-4f10-975f-2c65b7ba9dba',
      sizing: [
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        }
      ]
    },
    tech_support:{
      faq: [
        {
          title: 'Wiring',
          info: `The wiring of your EPI flow meter will be based on the configuration selected when your order was placed. EPI flow meters can be
          configured for various AC or DC input power options. Both analog and digital output signals are also available depending on your model and
          options.`
        },
        {
          title: 'Orientation',
          info: `EPI flow meters can be mounted in a number of different orientations based on your requirements with some limited adjustability in the
          field.`
        },
        {
          title: 'Installation',
          info: `EPI flow meters do require minumum up and downstream straight run to ensure the flow profile can develop properly, providing you with
          the highest accuracy readings.`
        },
        {
          title: 'Communication/ Outputs',
          info: `Your EPI flow meter will come standard with RS485 Modbus and RS232 digital communication. We also offer optional BACnet, Profibus DP and
          HART communications protocols. EPI flow meters provide a 4-20mA and 0-5 VDC analog output signal for flow and
          temperature (based on specific models) as well as up to 2 relays that can be independently configured based on your requirements.
          `
        }
      ],
      maintenance_info: {
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
      }
    },
    installation: {
      video: '',
      image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FWiring%2F400.png?alt=media&token=72a0b457-5975-483f-8766-b81b6428ce16'
    },
    documents_downloads: {
      datasheets: [
        {
          title: 'Downloads',
          files: [
            {
              file_title: 'Product Brochure',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%20400%20VALUMASS.pdf?alt=media&token=f0bd4ff9-f0dc-4a37-922b-5f0a59442fd5',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Wiring Diagram',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FVAL%20Series%20400-440%20Wiring.pdf?alt=media&token=37036fc8-339b-4b05-bdb7-f69f26505102',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Product Manual',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000002_RevA%20Valumass%20Manual.pdf?alt=media&token=03025f6a-9bf0-44ac-8c69-2d0cb37a06b4',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Meter Installation Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'EPITerm Software Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000003_RevA%20EPITerm%20Interface%20User%20Manual.pdf?alt=media&token=0e082343-3f69-476a-aaa0-fd344ece1885',
              version: '1.0.0',
              release_data: '2022'
            }
          ],
        }
      ],
    }
  }

  const cadData = {
    files: [
      {
        productName: '',
        modelLink: '',
        pdfLink: '',
        downloadLink: '',
        description: ``
      }
    ]
  }

  const ViewPages = () => {
    if(page === 'discover'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <ProductView productViewData={productViewData} images={images} />
          </div>
          <ProductDescription productViewData={productViewData} />
          <ValgenRBCustomizer
            productType={productType}
            productInfo={productInfo}
          />
        </>
      );
    }

    if(page === 'specs'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <TechSpecs data={techSpecsData} />
          </div>
        </>
      );
    }

    if(page === 'cad'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <CADFiles data={cadData} />
          </div>
        </>
      );
    }

    if(page === 'support'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <h1>Support</h1>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <ProductHeader productImage={productImage} />
      <div
        className="section section-about-us"
        style={{
          'background': 'whitesmoke',
          'padding-bottom': '50px',
          'border-bottom': 'whitesmoke'
        }}
      >
        <ProductViewNavbar
          navbarData={navbarData}
          page={page}
          setPage={setPage}
        />
      </div>
      <ViewPages />
    </>
  );
}

export default ValgenRBProductView;
