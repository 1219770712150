import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

function RepairRecalibration() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Repair & Recalibration Disclaimer</title>
            <meta name="description" content="Get a detailed description of the EPI Repair & Recalibration Disclaimer." />
            <link rel="canonical" href="https://epiflow.com/support/privacyinquiry/repairrecalibration" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner LegalCS-section-1'>
                <div className='image-container'>
                    <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/support" tag={Link}>Support</NavLink></span>
                        <FontAwesomeIcon icon={faChevronRight} className='icon' />
                        <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/support/privacyinquiry" tag={Link}>Privacy Inquiry</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/support/privacyinquiry/repairrecalibration" tag={Link}>Repair & Recalibration Disclaimer</NavLink></span>
                    </div>
                    <div>
                        <h2 className='cover-title-h2'>Repair & Recalibration Disclaimer</h2>
                        <p className='cover-title-p'>Read the details regarding EPI's disclaimer below.</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <div className="bg-white">
                <Container className='py-5'>
                    <Row>
                        <Col lg="8">
                          <p className='commonParaThree'>
                            Prior to returning any flow meter(s) to EPI for service, you must contact our Service Manager to receive an authorized RMA number and RMA form. The RMA number must be
                            wriaen on the outside of the package shipped to EPI, and on all documents and communications pertaining to the flow meter and the required service work. Failure to provide
                            this number on all documents and communications will delay the processing of your RMA. <br /><br />
                            Note: You are responsible for the cost of returning the flow meter(s) to EPI for service work, even if that work is determined to be covered by our Limited Warranty, unless
                            otherwise authorized by our Finance Manager. All flow meter probes and flow sections must be returned to EPI clean and free from contamination, including chemical or
                            radiation contamination. At our sole discretion, flow meters may be returned to you at your expense for the required cleaning and will delay the RMA process. <br /><br />
                            Note: As Found data service is not available for dirty or contaminated flow meters. The electronic components of our remote style flow meters are a complete assembly.
                            Therefore, all components must be returned for any service work, unless otherwise specifically authorized by the Service Manager. We also require that you return the flow
                            section of an inline style flow meter or the probe tube of a FAT™ flow meter so that the flow readings and output signals following the service work can be confirmed to be
                            within EPI’s published accuracy specification for your flow meter(s).<br /><br />
                            Note: If you do not return the inline flow section or the FAT probe tube with the flow meter(s) we cannot guarantee that the flow readings and output signals will be within the
                            corresponding published specification. Our standard turnaround time for service work is 4 to 5 weeks from the day we obtain your authorization to begin work. In most cases
                            we offer an optional Expedited Service if you require your flow meter(s) to be shipped back to you sooner. We offer the following, 1) 25% of list price for shipment deadline of
                            8-10 days, 2) 50% of list price for shipment deadline of 5-7 days, 3) 75% of list price for shipment deadline of 48 hours. If you require this type of service, we must be notified
                            prior to the return of the flow meter(s) to determine whether or not that option will be available for the required service work. These lead times are contingent with receiving
                            signed paperwork.<br /><br />
                            There is a minimum charge for all service work, referred to as the “Repair Service” the minimum is $250, and the amount varies depending on the EPI model type and options.
                            This amount is due and payable with or without any subsequent additional service work by EPI. If the required service work is determined by EPI to be covered by the Limited
                            Warranty for the flow meter(s) in question, you will not be charged the Repair Service fee. If the evaluation reveals additional issues outside of warranty, you will then be
                            informed of additional costs through a revised quote. We require payment for repair orders by accepted credit card or bank to bank wire transfer. You must contact our Finance
                            Manager for prior approval at finance@epiflow.com or 831.648.7777 for any other terms or payment arrangements.<br /><br />
                            If the meter is older than 10 years with obsolete technology, then you will be offered an upgrade/ refurbish path at a discount, and the evaluation charge will be applied
                            towards the upgrade. (Some restrictions apply, contact EPI) The meter warranty will then be extended to 1 year.<br /><br />
                          If the meter is less than 10 years old, the evaluation charge will be applied towards repair work. After evaluation, a discount upgrade offer may still be provided to you. (Contact
                            EPI to learn more about this)

                          </p>
                        </Col>
                        <Col lg="4">

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default RepairRecalibration;
