import React from "react";
import { useMediaQuery } from 'react-responsive';
import { useEffect } from "react/cjs/react.development";


// reactstrap components
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  UncontrolledTooltip
} from "reactstrap";

function Pipe({ 
  handleChange,
  setSelectedPipeSize,
  pipeDiameter,
  setPipeDiameter,
  pipeDiameterMM,
  setPipeDiameterMM,
  pipeLength,
  setPipeLength,
  otherPipeSize,
  setOtherPipeSize,
  state,
  checkState,
  getKeyByValue
}){
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  // useEffect(() => {
  //   const pipe_size = getKeyByValue(state.pipe_infornation.pipe_size, true)
  //   if(pipe_size == 0.25) {setPipeLength(3.5); setPipeDiameter(0.364); setPipeDiameterMM(6.35);}
  //   if(pipe_size == 0.375) {setPipeLength(3.5); setPipeDiameter(0.498); setPipeDiameterMM(9.525);}
  //   if(pipe_size == 0.5) {setPipeLength(3.5); setPipeDiameter(0.622); setPipeDiameterMM(12.70);}
  //   if(pipe_size == 0.75) {setPipeLength(3.5); setPipeDiameter(0.824); setPipeDiameterMM(19.05);}
  //   if(pipe_size == 1) {setPipeLength(3.5); setPipeDiameter(1.049); setPipeDiameterMM(25.4);}
  //   if(pipe_size == 1.25) {setPipeLength(3.5); setPipeDiameter(1.380); setPipeDiameterMM(31.75);}
  //   if(pipe_size == 1.5) {setPipeLength(3.5); setPipeDiameter(1.610); setPipeDiameterMM(38.09);}
  //   if(pipe_size == 2) {setPipeLength(3.5); setPipeDiameter(2.067); setPipeDiameterMM(50.8);}
  //   if(pipe_size == 2.5) {setPipeLength(3.5); setPipeDiameter(2.469); setPipeDiameterMM(63.5);}
  // }, [state]);

  const MakeChangeDropdown = (data) => {
    data.option_value = data.option.target.value;
    const values = data.values;
    const first_value = values[0]
    
    if(first_value !== data.option_value){
      values.removeWithValue(data.option_value)
      values[0] = data.option_value;
      values.splice(1,0, first_value);
      values.join();
      data.values = values;
      console.log('Show me pipe option data 1.0 =>', data);
    } else {
      console.log('Show me pipe option data 1.0 =>', data);
    }

    if(data.length_change){
      console.log('Show me pipe length data 1.0 =>', pipeLength);
      setSelectedPipeSize()
      if(data.option_value == 0.25) {setPipeLength(3.5); setPipeDiameter(0.364); setPipeDiameterMM(6.35);}
      if(data.option_value == 0.375) {setPipeLength(3.5); setPipeDiameter(0.498); setPipeDiameterMM(9.525);}
      if(data.option_value == 0.5) {setPipeLength(3.5); setPipeDiameter(0.622); setPipeDiameterMM(12.70);}
      if(data.option_value == 0.75) {setPipeLength(3.5); setPipeDiameter(0.824); setPipeDiameterMM(19.05);}
      if(data.option_value == 1) {setPipeLength(3.5); setPipeDiameter(1.049); setPipeDiameterMM(25.4);}
      if(data.option_value == 1.25) {setPipeLength(3.5); setPipeDiameter(1.380); setPipeDiameterMM(31.75);}
      if(data.option_value == 1.5) {setPipeLength(3.5); setPipeDiameter(1.610); setPipeDiameterMM(38.09);}
      if(data.option_value == 2) {setPipeLength(3.5); setPipeDiameter(2.067); setPipeDiameterMM(50.8);}
      if(data.option_value == 2.5) {setPipeLength(3.5); setPipeDiameter(2.469); setPipeDiameterMM(63.5);}
    }

    (data.option_value === 'other') ? setOtherPipeSize(true) : setOtherPipeSize(false);
    (data.option_value === 'other') && setPipeLength(0);

    handleChange(data);
  }

  const MakeChangeText = (data) => {
    data.option_value = data.option.target.value;
    handleChange(data);
  }

  Array.prototype.removeWithValue = function() {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
  };


  var title = 'Pipe Information';
  if (isMobile) {
    if(title.length > 18) title = title.substring(0,18) + '...';
  }

  return (
    <>
      {/**
        <Row>
          <Col>
            <FormGroup>
              <label htmlFor="exampleFormControlSelect1">
                <span className="customizerInputTitle">Pipe Size</span>
                <Button className="questionToolTip" id="PipeSize" size="sm">
                    ?
                </Button>{` `}
                <UncontrolledTooltip placement="right" target="PipeSize" delay={0}>
                  Based on ANSI standard
                </UncontrolledTooltip>
              </label>
              <Input
                className="epiInputSize"
                id="exampleFormControlSelect1"
                type="select"
                onChange={ (e) => MakeChangeDropdown({
                  section: 'pipe_infornation',
                  type: 'pipe_size',
                  values: [
                    0.25,
                    0.375,
                    0.5,
                    0.75,
                    1,
                    1.25,
                    1.5,
                    2,
                    2.5
                  ],
                  price_effect: true,
                  length_change: true,
                  option: e
                }, e)}
              >
                <option selected={checkState(state.pipe_infornation.pipe_size, 0.25)} value={0.25}>1/4"</option>
                <option selected={checkState(state.pipe_infornation.pipe_size, 0.375)} value={0.375}>3/8"</option>
                <option selected={checkState(state.pipe_infornation.pipe_size, 0.5)} value={0.5}>1/2"</option>
                <option selected={checkState(state.pipe_infornation.pipe_size, 0.75)} value={0.75}>3/4"</option>
                <option selected={checkState(state.pipe_infornation.pipe_size, 1)} value={1}>1"</option>
                <option selected={checkState(state.pipe_infornation.pipe_size, 1.25)} value={1.25}>1 1/4"</option>
                <option selected={checkState(state.pipe_infornation.pipe_size, 1.5)} value={1.5}>1 1/2"</option>
                <option selected={checkState(state.pipe_infornation.pipe_size, 2)} value={2}>2"</option>
                <option selected={checkState(state.pipe_infornation.pipe_size, 2.5)}value={2.5}>2 1/2"</option>
                <option selected={checkState(state.pipe_infornation.pipe_size, 'other')}value="other">Other Size</option>
              </Input>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <label htmlFor="exampleFormControlSelect1">
                <span className="customizerInputTitle">Length of Flow Section</span>
                <Button className="questionToolTip" id="Length" size="sm">
                  ?
                </Button>{` `}
                <UncontrolledTooltip placement="right" target="Length" delay={0}>
                  Consult factory for custom flow section length
                </UncontrolledTooltip>
              </label>
              <Input
                disabled
                id="exampleFormControlInput1"
                value={`${pipeLength}"`}
                type="text"
                style={{ 'border-radius': '6px', 'background-color': '#EBF2FF'}}
              ></Input>
            </FormGroup>
          </Col>
        </Row>

        <Row className={ otherPipeSize && 'text-center' } style={{ 'margin-top': '20px'}}>
          <Col>
            { otherPipeSize ? (
              <span className="customizerPipeSizeError">Consult factory for custom pipe size.</span>
            ) : (
              <FormGroup>
                <label htmlFor="exampleFormControlInput1">
                  <span className="customizerInputTitle">Inner Diameter (ID)</span>
                  <Button className="questionToolTip" id="InnerDiameter" size="sm">
                      ?
                  </Button>{` `}
                  <UncontrolledTooltip placement="right" target="InnerDiameter" delay={0}>
                    Based on ANSI standard
                  </UncontrolledTooltip>
                </label>
                <Input
                  disabled
                  id="exampleFormControlInput1"
                  value={`${pipeDiameter}" / ${pipeDiameterMM}mm`}
                  type="text"
                  style={{ 'border-radius': '6px', 'background-color': '#EBF2FF'}}
                ></Input>
              </FormGroup>
            )}
          </Col>
        </Row>
      */}

      {/* <Row className="text-center" style={{ 'margin-top': '20px'}}> */}
      <img src="https://epiflow.com/wp-content/uploads/2018/09/800hpxsizes1-1024x545-1.jpg" />
      <img src="https://epiflow.com/wp-content/uploads/2018/09/800hpxsizes2-1.jpg" />
      <Row className="text-center" style={{ 'margin-top': '20px'}}>
        <span className="customizerPipeSizeError">Factory will contact you for Pure meter pipe size.</span>
      </Row>
    </>
  );
}

export default Pipe;
