import { Redirect } from "react-router-dom";

export let RedirectRoutes =  [
  {
    path: "/sales",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/contactus'} />
    }
  },
  {
    path: "/about",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/aboutus'} />
    }
  },
  {
    path: "/products/epi-communicator/",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/support/downloads'} />
    }
  },
  {
    path: "/product/series-8600-8700mp-thermal-mass-flow-meter",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/haz/inline/haz'} />
    }
  },
  {
    path: "/product/series-8800mp-insertion-thermal-mass-flow-meter/",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/haz/insertion/haz'} />
    }
  },
  {
    path: "/product/series-8000-8100mp-thermal-mass-flow-meter-2",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/haz/inline/haz-r'} />
    }
  },
  {
    path: "/product/8200-mp-insertion-remote",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/haz/insertion/haz-r'} />
    }
  },
  {
    path: "/product/series-9700mp-flow-averaging-tube-fat",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/haz/inline/haz-fat'} />
    }
  },
  {
    path: "/product/series-9800mp-flow-averaging-tube-fat",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/haz/insertion/haz-fat'} />
    }
  },

  {
    path: "/product/series-9100mp-flow-averaging-tube-fat-2",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/haz/inline/haz-fat-r'} />
    }
  },
  {
    path: "/product/series-9200mp-flow-averaging-tube-fat-2",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/haz/insertion/haz-fat-r'} />
    }
  },
  {
    path: "/product/series-8600-8700mpnh-thermal-mass-flow-meter",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/gen/inline/gen'} />
    }
  },
  {
    path: "/product/series-8800mpnh-insertion-thermal-mass-flow-meter",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/gen/insertion/gen'} />
    }
  },
  {
    path: "/product/series-8000-8100mp-thermal-mass-flow-meter",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/gen/inline/gen-r'} />
    }
  },
  {
    path: "/product/8200-mpnh-insertion-remote",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/gen/insertion/gen-r'} />
    }
  },
  {
    path: "/product/series-9700mpnh-flow-averaging-tube-fat",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/gen/inline/gen-fat'} />
    }
  },
  {
    path: "/product/series-9800mpnh-flow-averaging-tube-fat",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/gen/insertion/gen-fat'} />
    }
  },
  {
    path: "/product/series-9100mp-flow-averaging-tube-fat",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/gen/inline/gen-fat-r'} />
    }
  },
  {
    path: "/product/series-9200mp-flow-averaging-tube-fat",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/gen/insertion/gen-fat-r'} />
    }
  },
  {
    path: "/product/valumass-500-inline",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/val/inline/val-lm'} />
    }
  },
  {
    path: "/product/valumass",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/val/insertion/val-lm'} />
    }
  },
  {
    path: "/product/valumass-400-insertion",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/val/inline/val-rb'} />
    }
  },
  {
    path: "/product/valumass-440-insertion",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/val/insertion/val-rb'} />
    }
  },
  {
    path: "/product/800hpx",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/pure/inline/pure-lm'} />
    }
  },
  {
    path: "/product/800hpx",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/pure/inline/pure-lm'} />
    }
  },
  {
    path: "/product/800hpn-high-purity-thermal-mass-flow-meter",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/pure/inline/pure-rb'} />
    }
  },
  {
    path: "/product/series-8200mpht-insertion-remote-thermal-mass-flow-meter-high-temperature",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/xt/insertion/525cr'} />
    }
  },
  {
    path: "/product/series-8800mpht-insertion-thermal-mass-flow-meter-high-temperature",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/xt/insertion/200c'} />
    }
  },
  {
    path: "/product/series-9000mp-multipoint-thermal-mass-flow-meter",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/multigen/insertion/multigen'} />
    }
  },
  {
    path: "/product/series-7000-inline-switch-thermal-mass-flow",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/switch/inline/gen'} />
    }
  },
  {
    path: "/product/series-7000-insertion-switch-thermal-mass-flow",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/products/switch/insertion/gen'} />
    }
  },
  {
    path: "/sales/domestic",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/findrep'} />
    }
  },
  {
    path: "/information/technical-information",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/technicalinfo'} />
    }
  },
  {
    path: "/information/technical-information/agency-approvals-certificates",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/technicalinfo/approvalscertificates'} />
    }
  },
  {
    path: "/information/technical-information/installation",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/technicalinfo/installation'} />
    }
  },
  {
    path: "/products/mounting-options",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/technicalinfo/installation/inline'} />
    }
  },
  {
    path: "/products/mounting-options-insertion",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/technicalinfo/installation/insertion'} />
    }
  },
  {
    path: "/information/technical-information/specifying-flow-conditions/",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/technicalinfo/meterconsiderations'} />
    }
  },
  {
    path: "/information/technical-information/flow-meter-configurations",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/technicalinfo/meterconfigurations'} />
    }
  },
  {
    path: "/information/technical-information/technology-comparison",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/technicalinfo/technologycomparison'} />
    }
  },
  {
    path: "/information/technical-information/operational-theory",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/technicalinfo/operationaltheory'} />
    }
  },
  {
    path: "/information/technical-information/industrial-applications-for-meters",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/technicalinfo/industrialapplications'} />
    }
  },
  {
    path: "/support/technicalinfo/industrialapplications",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/technicalinfo/industrialapplications'} />
    }
  },
  {
    path: "/information/thermal-mass-flow-white-papers",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/technicalinfo/whitepapers'} />
    }
  },
  {
    path: "/information/thermal-mass-flow-white-papers/compressed-air-flow-measurement",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/whitepapers/compressedair'} />
    }
  },
  {
    path: "/information/thermal-mass-flow-white-papers/hvac-air-vent",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/whitepapers/hvac'} />
    }
  },
  {
    path: "/information/thermal-mass-flow-white-papers/thermal-mass-flow-landfill-gas-municipal-flow-meters",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/whitepapers/landfill'} />
    }
  },
  {
    path: "/information/thermal-mass-flow-white-papers/thermal-mass-flow-natural-gas-measurement-meter",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/whitepapers/naturalgas'} />
    }
  },
  {
    path: "/information/thermal-mass-flow-white-papers/thermal-mass-flow-wastewater-aeration-basin",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/whitepapers/wastewater'} />
    }
  },
  {
    path: "/flue-gas",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/whitepapers/fluegas'} />
    }
  },
  {
    path: "/thermal-mass-flow-meters-for-flare-stack",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/whitepapers/flaregas'} />
    }
  },
  {
    path: "/information/gases-we-flow",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/technicalinfo/gasesweflow'} />
    }
  },
  {
    path: "/contact-us",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/contactus'} />
    }
  },
  {
    path: "/products/manuals",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/support/downloads'} />
    }
  },
  {
    path: "/sales/request-for-quote",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/support/getquote'} />
    }
  },
  {
    path: "/information/about-epi",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/aboutus'} />
    }
  },
  {
    path: "/user-login",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/login'} />
    }
  },
  {
    path: "/register-user",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/signup'} />
    }
  },
  {
    path: "/wp-content/uploads/2018/10/EPI_Terms_and_Conditions.pdf",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/support/privacyinquiry/terms'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-7000-INLINE-MAIN-1-1.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/switch_inline'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-7000-INSERTION-ENCLOSURE-CLOSE-UP-1.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/switch_inline'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-7000-INLINE-PROBE--600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/switch_inline_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-7000-INSERTION-.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/switch_insertion'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-7000-INSERTION-ENCLOSURE-CLOSE-UP.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/switch_insertion_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-7000-INLINE-PROBE-CLOSE-UP-.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/switch_insertion_3'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-7000-INSERTION-PROBE-CLOSE-UP-.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/switch_insertion_3'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8000-8100MP-INLINE.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_r_inline'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8000-8100MP-INLINE-ENCLOSURE-CLOSE-UP--600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_r_inline'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8000-8100MP-INLINE-BODU-CLOSE-UP--600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_r_inline_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8000-8100MP-INLINE-REMOTE-CLOSE-UP-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_r_inline_3'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8000-8100MP-REMOTE-XP-WITH-MOUNTING-BRACKET.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_r_inline_3'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/05/series-8000-8100-main.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_r_inline'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-800-8100MPNH-REMOTE-CLOSE-UP-.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_r_inline_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8000-8100MPNH-BLANK-ENCLOSURE-CLOSE-UP-.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_r_inline_3'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8000-8100MPNH-INLINE-CLOSE-UP.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_r_inline_4'} />
    }
  },
  {
    path: "/wp-content/uploads/2018/09/high-purity-medium.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/hpn'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-800HPN-ENCLOSURE-CLOSE-UP-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/hpn_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-800HPN-INLNE-PROBE-CLOSE-UP-1-1-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/hpn_3'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-800HPX-BUTT-ENDS-MAIN.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/hpx'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-800HPX-ENCLOSURE-CLOSE-UP-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/hpx_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-800HPX-INLINE-WITH-BUTT-END-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/hpx_3'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-800HPX-VCR-ENDS-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/hpx_4'} />
    }
  },
  {
    path: "/wp-content/uploads/2018/09/800hpxsizes1-1024x545-1.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/hpx'} />
    }
  },
  {
    path: "/wp-content/uploads/2018/09/800hpxsizes2-1.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/hpx'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8200-MPNH-INSERTION-REMOTE-MAIN-.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_r_insertion'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8200MPNH-REMOTE-CLOSE-UP--600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_r_insertion_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8200MPNH-REMOTE-CLOSE-UP-1-1-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_r_insertion_3'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8200MPNH-SENSOR-CLOSE-UP--600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_r_insertion_4'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8200MP-INSERTION-REMOTE.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_r_insertion'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8200MP-INLINE-REMOTE-ENCLOSURE-CLOSE-UP-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_r_insertion_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8200MP-INLINE-PROBE-CLOSE-UP-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_r_insertion_3'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8200MP-INSERTION-SENSOR-CLOSE-UP-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_r_insertion_4'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/REMOTE-ELECTRONICS-FOR-8200MP-REMOTE.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_r_insertion_5'} />
    }
  },
  {
    path: "/wp-content/uploads/2018/09/8240MP.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/xt525cr'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/05/SERIES8600-8700MP-MAIN-1.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_inline'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8600-8700MP-ENCLOSURE-CLOSE-UP.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_inline_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8600-8700MP-FLANGE-CLOSE-UP.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_inline_3'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8600-8700MP-FLANGE-LARGE.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_inline_4'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/05/SERIES8600-8700MPNH.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_inline'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8600-8700MPNH-LARGE-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_inline_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8600-8700MPNH-BODY-CLOSE-UP--600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_inline_3'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8800MP-INSERTION-.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_insertion'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIERS8800MP-CLOSE-UP-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_insertion_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2018/09/8840MP.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/xt200c'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8800MPNH-MAIN.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_insertion'} />
    }
  },
  {
    path: "/wp-content/uploads/2018/09/series9600mp.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/multi_insertion'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-9100MP-INLINE-REMOTE.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_fat_r_inline'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8200MP-INLINE-REMOTE-ENCLOSURE-CLOSE-UP.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_fat_r_inline_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-9700MP-INLINE-FAT-FLANGE-CLOSE-UP-.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_fat_r_inline'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8000-8100MP-INLINE-REMOTE-CLOSE-UP-1-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_fat_r_insertion'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-9100MPNH-REMOTE-1-1.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_fat_r_insertion'} />
    }
  },
  {
    path: "/wp-content/uploads/2018/09/series9240MP_1.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_fat_r_insertion'} />
    }
  },
  {
    path: "/wp-content/uploads/2018/09/series9240mpnh_1.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_fat_r_insertion'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-9700MP-Inline-.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_fat_inline'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-9700MP-INLINE-FAT-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_fat_inline_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-9700MP-INLINE-FAT-PROBE-CLOSE--600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_fat_inline_3'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-9700MP-INLINE-FAT-FLANGE-CLOSE-UP-.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_fat_inline_4'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-8600-8700MP-FLANGE-LARGE.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/gen_fat_inline'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-9700MPNH.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_fat_inline'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES-9800MP-INSERTION-.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_fat_inline_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2018/09/series9840mpnh_1.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/haz_fat_inline_3'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES400INLINEMAIN.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/val400'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/SERIES400INLINEFLAT-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/val400_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2018/09/valuemass440.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/val440'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/VALUMASS-500-INLINE-MAIN-.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/val500'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/VALUMASS-500-ENCLOSURE-CLOSE-UP.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/val500_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/VALUMASS-INLINE-BODY-CLOSE-UP.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/val500_3'} />
    }
  },
  {
    path: "/wp-content/uploads/2018/09/series540main.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/val540'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/VALUMASS-540-ENCLOSURE-CLOSE-UP-600x600.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/val540_2'} />
    }
  },
  {
    path: "/wp-content/uploads/2019/06/VALUMASS-540-IN-PRODUCTION-PLANT-600x750.jpg",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/productimages/val540_3'} />
    }
  },
  {
    path: "https://epiflow.com/support/findrep",
    exact: true,
    tracking: true,
    component: () => {
      return <Redirect to={'/findrep'} />
    }
  },
];
