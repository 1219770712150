import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faChevronRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row, NavLink, Button } from 'reactstrap';
import img1 from './imgs/FindRepImg1.png'
import FRsearch from './search/FRsearch';
import { Helmet } from "react-helmet";


function FindRep() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const reps = [
        {
            name: 'first',
            country: 'us',
            state: 'ca'
        },
        {
            name: 'second',
            country: 'us',
            state: 'ny'
        },
        {
            name: 'third',
            country: 'uk',
            state: 'ca'
        },
        {
            name: 'fourth',
            country: 'fa',
            state: 'ca'
        }
    ]

    const refFilter = () => {
        const q = 'us ca'
        // reps.map(value => {
        //     const returnCountry = q.match(value.country);
        //     const returnState = q.match(value.state);
        //     if(!returnCountry){
        //         return null;
        //     }

        //     if(!returnState){
        //         return null;
        //     }

        //     // console.log('Show search info 1.0 =>', returnQ)
        //     console.log('Show search info 2.0 =>', value)
        // })
    }
    // useEffect(() => {
        refFilter()
    // }, []);

    return (

        <div>
          <Helmet>
              <meta charSet="utf-8" />
              <title>EPI – Find Your Nearest Representative</title>
              <meta name="description" content="Are you looking for an EPI Representative near you? Enter your address to find out and talk with our staff!" />
              <link rel="canonical" href="https://epiflow.com/findrep" />
          </Helmet>
            {/* section-1 */}
            <div className='commonbanner FRepSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/" tag={Link}>Home</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/findrep" tag={Link}>Find a Representative</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Find a Representative</h2>
                        <p className='cover-title-p'>
                            Are you looking for one of EPI’s representatives? Use the location lookup tool below to find the one nearest to you.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <FRsearch/>
            {/* section-2 */}



            {/* section-5 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <div className='text-center'>
                        <h4 className='commontitleOne'>Would you like to become a distributor <br />
                            for EPI?
                        </h4>
                        <p className='commonParaThree font-weight-bold color-blue'>We’d love to have you on the team!</p>
                        <NavLink to="/portal/becomeAdistributor" tag={Link}>
                            <Button className="button1 px-5" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                                Apply Now
                            </Button>
                        </NavLink>
                    </div>
                </Container>
            </div>
            {/* section-5 */}
        </div>
    )
}

export default FindRep
