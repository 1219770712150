import React, {useEffect, useState} from "react";
import useSwr from 'swr';
import './viewQuote2.css';
import MappingData_ from "./ModelNumMappingData/Inline";
import ProcessParameters from "./ProcessParameters";
import fetcher from 'utils/fetcher';

function ViewQuoteTempSimple({ state, quoteData, quoteCreatorData }){
  const [mappingData, setMappingData] = useState(false);
  const [userData, setUserData] = useState([]);

  const { data } = useSwr(`/api/me`, fetcher);

  useEffect(() => {
    if(MappingData_){
      setMappingData(MappingData_[0]);
    }
  }, [MappingData_]);

  useEffect(() => {
    if(data){
      setUserData(data);
    }
  }, [data]);

  if(state){
    console.log('Show me mapping data 1.0 =>', mappingData.model_number.pipe_length[state.model_number.pipe_length].desc)
    var ca = state.model_number.certifications + state.model_number.approvals;
    if(state.model_number.certifications === 'NA'){
      var ca = state.model_number.certifications
    }
  }

  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  const date = new Date(quoteData.createdAt);
  const day = date.getDate();
  const month = ("0" + (date.getMonth() + 1)).slice(-2)
  const year = date.getFullYear();

  const futureDate = date.addDays(30);
  const dayF = futureDate.getDate();
  const monthF = ("0" + (futureDate.getMonth() + 1)).slice(-2)
  const yearF = futureDate.getFullYear();

  const total = quoteData + 400;

  const checkState = (checkData, position) => {
    if(checkData.valuesObj){
      if(checkData.valuesObj && checkData.valuesObj[position]){
        return checkData.valuesObj[position]
      }
    }

    if(checkData && checkData[position]){
      return checkData[position]
    }
  }

  const getValue = (object, value) => {
    if(object){
      return object.valuesObj[value]
    }
  }

  return(
    <>
      {state &&
        <div class="container-fluid">
          <div class="qMain">
            <div class="row">
              <div class="col-8">
                <div class="qMeterInfo qPaddingTop qPaddingBottom">
                  <p class="qLabel">Meter Series:</p>
                  <p class="qLarge">{quoteData.series }</p>
                  <p class="qLabel">Model Number:</p>
                  <p class="qMedium" style={{'text-transform': 'uppercase'}}>
                    {/* 8640MP-DCEX-SSS-133-DC24-MW100-4018NA-1RC-FLARE-AT2-MB/HT */}
                    {`
                      ${state.model_number.electronics}${state.model_number.pipe_length}${state.model_number.series}-
                      ${state.model_number.transmitter}-
                      ${state.model_number.sensor_material}${state.model_number.sensor_shield}-
                      ${state.model_number.display}-
                      ${state.model_number.input_power}-
                      ${state.model_number.gas_temp}-
                      ${state.model_number.pipe_standard}${state.model_number.pipe_length}${state.model_number.mounting_requirements}-
                      ${state.model_number.range}-
                      ${state.model_number.gas}-
                      ${ca}-
                      ${state.model_number.communications_options_2}
                    `}
                  </p>
                  <br />
                  <div class="qCustomerNotes">
                    <p class="qLabel">Notes:</p>
                    <p class="qNote">Customer notes here.</p>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <img class="img qMeterImg" src={state.meter_images[state.meter_type.type]} alt="" />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <table class="table qTable">
                  <thead>
                    <tr>
                      <th scope="col">Category</th>
                      <th scope="col">Code</th>
                      <th scope="col">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Meter Model</td>
                      <td className="qUppercase">{state.model_number.electronics}{state.model_number.pipe_length}{state.model_number.series}</td>
                      <td>
                        {`
                          ${mappingData.model_number.electronics[state.model_number.electronics]?.desc}.
                          ${mappingData.model_number.pipe_length[state.model_number.pipe_length]?.desc}.
                        `}
                      </td>
                    </tr>
                    <tr>
                      <td>Transmitter</td>
                      <td className="qUppercase">{state.model_number.transmitter}</td>
                      <td>{mappingData.transmitter[state.model_number.transmitter]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Sensor</td>
                      <td className="qUppercase">{state.model_number.sensor_material}</td>
                      <td>{mappingData.sensor_material[state.model_number.sensor_material]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Display</td>
                      <td className="qUppercase">{state.model_number.display}</td>
                      <td>{mappingData.display[state.model_number.display]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Input Power</td>
                      <td className="qUppercase">{state.model_number.input_power}</td>
                      <td>{mappingData.input_power[state.model_number.input_power]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Temperature Range</td>
                      <td className="qUppercase">{state.model_number.gas_temp}</td>
                      <td>{mappingData.gas_temp[state.model_number.gas_temp]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Configuration</td>
                      <td className="qUppercase">
                        {state.model_number.pipe_standard}
                        {state.model_number.pipe_length}
                        {state.model_number.mounting_requirements}
                      </td>
                      <td>
                        {`
                          ${mappingData.pipe_standard[state.model_number.pipe_standard]?.desc}
                          ${mappingData.pipe_standard.pipe_length[state.model_number.pipe_length]?.desc}
                          ${mappingData.pipe_standard[state.model_number.mounting_requirements]?.desc}
                        `}
                      </td>
                    </tr>
                    <tr>
                      <td>Range</td>
                      <td className="qUppercase">{state.model_number.range}</td>
                      <td>{mappingData.ranges[state.model_number.range]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Gas Type</td>
                      <td className="qUppercase">{state.model_number.gas}</td>
                      <td>{mappingData.gas[state.model_number.gas.toUpperCase()]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Certifications/Approvals</td>
                      <td className="qUppercase">{ca}</td>
                      <td>{mappingData.certifications_approvals[ca]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Communication Protocols</td>
                      <td className="qUppercase">{state.model_number.communications_options_2}</td>
                      <td>{mappingData.communications_options_2[state.model_number.communications_options_2]?.desc}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <ProcessParameters
              state={state}
              getValue={getValue}
              checkState={checkState}
            />
            <div class="row qCompany p-0">
              <div class="col-12 text-center">
                <p class="qBrand">Eldridge Products, Inc.</p>
                <p class="qBrandAddress">465 Reservation Rd. Marina, CA 93933</p>
                <p class="qContact"><a href="tel:1-831-648-7777">831-648-7777</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="mailto:sales@epiflow.com">sales@epiflow.com</a></p>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default ViewQuoteTempSimple;
