import React from 'react'
import { DropzoneArea } from 'material-ui-dropzone'

const DragDropFile = ({setFiles}) => {
    return(
        <>
            <div className='drag-drop-main text-center'>
                <div className='drag-drop-field'>
                    <a href="/" className='button2'>Click to Browse</a>
                    <p className='commonParaThree mt-3'>or <br /> drag files here</p>
                </div>
                <DropzoneArea
                    onChange={(e) => setFiles({ files: e})}
                />
            </div>
        </>
    );
}

export default DragDropFile;