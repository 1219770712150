import React, { useState, useEffect } from "react";

// core components
import ProductHeader from "components/Headers/ProductHeader";
import ProductViewNavbar from "components/Navbars/ProductViewNavbar";
import ProductView from "components/ProductViews/Components/ProductView";
import ProductDescription from "components/ProductViews/Components/ProductDescription";
import _300CrCustomizer from "components/Customizer/Views/Xt/XtInsertion/300CrCustomizer";
import TechSpecs from "views/TechSpecs/TechSpecs";
import CADFiles from "views/CADFiles/CADFiles";

function _300CrProductView({productType, tab}){
  const [page, setPage] = useState('discover');

  useEffect(() => {
    if(tab){
      if(tab === 'disc'){
        setPage('discover');
      }
  
      if(tab === 'specs'){
        setPage('specs');
      }
  
      if(tab === 'cad'){
        setPage('cad');
      }  
    }
  }, [tab]);

  const productImage = require('assets/img/epi-bg.png').default;
  
  const navbarData = {
    product_title: '300CR',
    product_type: 'INSERTION FLOW METER'
  }
  
  const productViewData = {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.    ',
    meter_type: 'Inline',
    enclosure: 'Integrated Electronics',
    warranty: '5',
    certifications: 'CE, CSA/CUS, IECEx, KOSHA, ATEX',
    product_description: ''
  }

  const techSpecsData = {
    features:{
      highlightOneImage: 'https://epiflow.com/wp-content/uploads/2019/06/SERIES-7000-INSERTION-ENCLOSURE-CLOSE-UP-1.jpg',
      highlightOneInfo: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
      incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
      nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
      aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
      nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
      officia deserunt mollit anim id est laborum.`,
      highlightTwoImage: 'https://epiflow.com/wp-content/uploads/2019/06/SERIES-7000-INSERTION-ENCLOSURE-CLOSE-UP-1.jpg',
      highlightTwoInfo: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
      incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
      nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
      aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
      nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
      officia deserunt mollit anim id est laborum.`
    },
    our_process: {
      benefits:[
        {
          title: 'Benefit',
          info: `CAt half-past eight the door opened, the policeman appeared.`
        },
        {
          title: 'Benefit',
          info: `CAt half-past eight the door opened, the policeman appeared.`
        },
        {
          title: 'Benefit',
          info: `CAt half-past eight the door opened, the policeman appeared.`
        },
        {
          title: 'Benefit',
          info: `CAt half-past eight the door opened, the policeman appeared.`
        },
        {
          title: 'Benefit',
          info: `CAt half-past eight the door opened, the policeman appeared.`
        },
        {
          title: 'Benefit',
          info: `CAt half-past eight the door opened, the policeman appeared.`
        },
        {
          title: 'Benefit',
          info: `CAt half-past eight the door opened, the policeman appeared.`
        },
        {
          title: 'Benefit',
          info: `CAt half-past eight the door opened, the policeman appeared.`
        }
      ]
    },
    specifications: {
      specs: [
        {
          title: 'APPROVALS',
          info: `No Agency Certifications`
        },
        {
          title: 'Signal output',
          info: `Relay contact, high & low,
          6 Amp @ 24 Volts DC or 250 Volts AC (Class “A” Insulation)
          Event Relay Rating one with Frequency or two, 1 Amp @ 30Vdc (33W)`
        },
        {
          title: 'Trip point accuracy',
          info: `+/‑ 1.0% of Set Point`
        },
        {
          title: 'Repeatability',
          info: `+/‑ 0.5% of Range`
        },
        {
          title: 'Sensor response time (Metal)',
          info: `1 Second`
        },
        {
          title: 'Sensor response time',
          info: `Set to 1 second`
        },
        {
          title: 'Turn down ratio',
          info: `100:1 minimum`
        },
        {
          title: 'Electronics temperature range',
          info: `0‑50°C (32‑122°F)`
        },
        {
          title: 'Gas temperature range',
          info: `0‑200°C (32‑392°F)`
        },
        {
          title: 'Gas temperature effect',
          info: `.01% /°C for gas`
        },
        {
          title: 'Pressure rating maximum:',
          info: `500 PSI`
        },
        {
          title: 'Power requirements',
          info: `User supplied 24 VDC, @ 250mA Max.
          120 VAC 50/60 Hz (optional)`
        },
        {
          title: 'Flow switch enclosure',
          info: `NEMA 4, Class 1, Div 1 Groups B, C and D`
        },
        {
          title: 'Wetted materials',
          info: `316SS, ceramic, glass, epoxy`
        },
        {
          title: 'NIST traceable calibration',
          info: `Optional for Gas`
        },
        {
          title: 'Calibration Standard (Gas)',
          info: `70 °F & 29.92″ Hg
          (Air = .075 lb./cubic foot)`
        },
        {
          title: 'Note',
          info: `Specifications subject to change without notice.`
        }
      ]
    },
    communication_config: {
      comm_config: [
        {
          output: '4-20MA FLOW (SELF OR ISOLATEDLOOP POWERED)',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: true,
          bacnet: true,
          profibus: true
        },
        {
          output: '0-5 OR 0-10 VDC FLOW',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: false,
          bacnet: true,
          profibus: false
        },
        {
          output: '4-20MA TEMPERATURE',
          standard: true,
          temp_out: true,
          no_temp_out: false,
          hart: true,
          bacnet: true,
          profibus: true
        },
        {
          output: '0-5 OR 0-10 VDC TEMPERATURE',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: false,
          bacnet: true,
          profibus: true
        },
        {
          output: 'FREQUENCY 0-1KHZ',
          standard: false,
          temp_out: false,
          no_temp_out: false,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: 'MODE SWITCH (RANGES)',
          standard: false,
          temp_out: false,
          no_temp_out: true,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: '1 MECHANICAL RELAY',
          standard: false,
          temp_out: false,
          no_temp_out: false,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: '2 MECHANICAL RELAY	',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: true,
          bacnet: true,
          profibus: true
        },

        {
          output: 'RS 485/MODBUS (R/W)',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: 'RS 232/MODBUS (R/W)',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: true,
          bacnet: true,
          profibus: true
        },
        {
          output: 'HART (READ ONLY)',
          standard: false,
          temp_out: false,
          no_temp_out: false,
          hart: true,
          bacnet: false,
          profibus: false
        },
        {
          output: 'PROFIBUS (READ ONLY)',
          standard: false,
          temp_out: false,
          no_temp_out: false,
          hart: false,
          bacnet: false,
          profibus: true
        },
        {
          output: 'BACNET (READ ONLY)',
          standard: false,
          temp_out: false,
          no_temp_out: false,
          hart: false,
          bacnet: true,
          profibus: false
        },
      ]
    },
    geometry_sizing: {
      cadImg: 'https://epiflow.com/wp-content/uploads/2018/09/7000switch_inline-e1558668933737.png',
      sizing: [
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        }
      ]
    },
    tech_support:{
      faq: [
        {
          title: 'FAQ Question Goes Here 1',
          info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
          aliqua. Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
        },
        {
          title: 'FAQ Question Goes Here 2',
          info: 'info 2'
        },
        {
          title: 'FAQ Question Goes Here 3',
          info: 'info 3'
        },
        {
          title: 'FAQ Question Goes Here 4',
          info: 'info 4'
        }
      ],
      maintenance_info: {
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
      }
    },
    installation: {
      video: 'https://www.youtube.com/embed/f9LFJOno96c',
      image: 'https://epiflow.com/wp-content/uploads/2018/09/7000switch_inline-e1558668933737.png'
    },
    documents_downloads: {
      datasheets: [
        {
          title: 'Datasheets',
          files: [
            {
              file_title: 'DocumentTitleGoesHere.pdf',
              version: '3.0.0',
              release_data: '07/30/2020'
            },
            {
              file_title: 'DocumentTitleGoesHere.pdf',
              version: '2.0.0',
              release_data: '07/30/2020'
            },
            {
              file_title: 'DocumentTitleGoesHere.pdf',
              version: '1.0.0',
              release_data: '07/30/2020'
            }
          ],
        },
        {
          title: 'Datasheets2',
          files: [
            {
              file_title: 'DocumentTitleGoesHere.pdf',
              version: '3.0.0',
              release_data: '07/30/2020'
            },
            {
              file_title: 'DocumentTitleGoesHere.pdf',
              version: '2.0.0',
              release_data: '07/30/2020'
            },
            {
              file_title: 'DocumentTitleGoesHere.pdf',
              version: '1.0.0',
              release_data: '07/30/2020'
            }
          ],
        },
      ],
    }
  }

  const cadData = {
    files: [
      {
        filename: 'HAZ Inline Thermal Flow Meter CAD',
        dwgLink: '/support/downloads',
        pdfLink: '/support/downloads',
        revitLink: '/support/downloads'
      },
      {
        filename: 'HAZ Inline Thermal Flow Meter Installation Details',
        dwgLink: '/support/downloads',
        pdfLink: '/support/downloads',
        revitLink: '/support/downloads'
      },
      {
        filename: 'HAZ Inline Thermal Flow Meter Revit Package',
        dwgLink: '/support/downloads',
        pdfLink: '/support/downloads',
        revitLink: '/support/downloads'
      },
    ]
  }

  const ViewPages = () => {
    if(page === 'discover'){
      return(
        <>
          <div 
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <ProductView productViewData={productViewData} />
          </div>
          <ProductDescription />
          <_300CrCustomizer productType={productType} />
        </>
      );
    }

    if(page === 'specs'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <TechSpecs data={techSpecsData} />
          </div>
        </>
      );
    }

    if(page === 'cad'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <CADFiles data={cadData} />
          </div>
        </>
      );
    }

    if(page === 'support'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <h1>Support</h1>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <ProductHeader productImage={productImage} />
      <div
        className="section section-about-us"
        style={{
          'background': 'whitesmoke',
          'padding-bottom': '50px',
          'border-bottom': 'whitesmoke'
        }}
      >
        <ProductViewNavbar
          navbarData={navbarData}
          page={page}
          setPage={setPage}
        />
      </div>
      <ViewPages />
    </>
  );
}

export default _300CrProductView;