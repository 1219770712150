import React from 'react'
import 'assets/css/style.css'
import 'assets/css/Sidebar.css'

function SidebarDownloads({meterOptions, setMeterOptions, secondOptions, setSecondOptions, setThirdOptions}) {
    return (
        <div className='sidebar-main SidebarAllproducts'>

            <form action="">
                {/* <div className='mt-5 mb-4'>
                    <h4><b>Filter Products</b></h4>
                    <h5>PRODUCT LINE</h5>
                    <label className="alp-radio-container">FAT
                        <input type="radio" name="radio" />
                        <span className="checkmark"></span>
                    </label>
                    <label className="alp-radio-container">Master Touch
                        <input type="radio" name="radio" />
                        <span className="checkmark"></span>
                    </label>
                    <label className="alp-radio-container">ValuMass
                        <input type="radio" name="radio" />
                        <span className="checkmark"></span>
                    </label>
                </div> */}
                <div className='mt-5 mb-4'>
                    <h5>Filter By</h5>
                    <hr />
                    <h6>METER STYLE</h6>
                    <label className="alp-radio-container">Inline
                        <input
                            type="radio"
                            name="radio"
                            id="first"
                            name="first"
                            defaultChecked={meterOptions == 'inline'}
                            onClick={() => setMeterOptions('inline')}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <label className="alp-radio-container">Insertion
                        <input
                            type="radio"
                            name="radio"
                            id="first"
                            name="first"
                            defaultChecked={meterOptions == 'insertion'}
                            onClick={() => setMeterOptions('insertion')}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <hr />

                    <h6>Meter Options</h6>
                    <label className="alp-radio-container">All
                        <input
                            type="radio"
                            name="radio"
                            id="second"
                            name="second"
                            defaultChecked
                            onClick={() => setSecondOptions('')}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <label className="alp-radio-container">Integral Electronics
                        <input
                            type="radio"
                            name="radio"
                            id="second"
                            name="second"
                            defaultChecked={secondOptions == 'insertion'}
                            onClick={() => setSecondOptions('integral')}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <label className="alp-radio-container">Remote Electronics
                        <input
                            type="radio"
                            name="radio"
                            id="second"
                            name="second"
                            defaultChecked={secondOptions == 'insertion'}
                            onClick={() => setSecondOptions('remote')}
                        />
                        <span className="checkmark"></span>
                    </label>
                    {/* <label className="alp-radio-container">Switch
                        <input
                            type="radio"
                            name="radio"
                            id="second"
                            name="second"
                            defaultChecked={secondOptions == 'insertion'}
                            onClick={() => setSecondOptions('switch')}
                        />
                        <span className="checkmark"></span>
                    </label> */}

                    <hr />
                    <h6>Approvals & Certifications</h6>
                    <label className="alp-radio-container">CE Only
                        <input
                            type="radio"
                            name="radio"
                            id="third"
                            name="third"
                            defaultChecked
                            onClick={() => setThirdOptions('')}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <label className="alp-radio-container">Hazardous Locations
                        <input
                            type="radio"
                            name="radio"
                            id="third"
                            name="third"
                            onClick={() => setThirdOptions('hazardous')}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <label className="alp-radio-container">General Locations
                        <input
                            type="radio"
                            name="radio"
                            id="third"
                            name="third"
                            onClick={() => setThirdOptions('general')}
                        />
                        <span className="checkmark"></span>
                    </label>
                    {/* <label className="alp-radio-container">Pure
                        <input
                            type="radio"
                            name="radio"
                            id="third"
                            name="third"
                            onClick={() => setThirdOptions('pure')}
                        />
                        <span className="checkmark"></span>
                    </label> */}
                </div>
            </form>
        </div>
    )
}

export default SidebarDownloads
