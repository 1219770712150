import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row, Button, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";

import Cimg1 from '../../assets/img/images/IndustrialApplicationsCardImg1.png'
import Cimg2 from '../../assets/img/images/IndustrialApplicationsCardImg2.png'
import Cimg3 from '../../assets/img/images/IndustrialApplicationsCardImg3.png'
import Cimg4 from '../../assets/img/images/IndustrialApplicationsCardImg4.png'
import Cimg5 from '../../assets/img/images/IndustrialApplicationsCardImg5.png'
import Cimg6 from '../../assets/img/images/IndustrialApplicationsCardImg6.png'
import Cimg7 from '../../assets/img/images/IndustrialApplicationsCardImg7.png'
import Cimg8 from '../../assets/img/images/IndustrialApplicationsCardImg8.png'
import Cimg9 from '../../assets/img/images/IndustrialApplicationsCardImg9.png'
import Cimg10 from '../../assets/img/images/IndustrialApplicationsCardImg10.png'
import Cimg11 from '../../assets/img/images/IndustrialApplicationsCardImg11.png'
import Cimg12 from '../../assets/img/images/IndustrialApplicationsCardImg12.png'
import Cimg13 from '../../assets/img/images/IndustrialApplicationsCardImg13.png'
import Cimg14 from '../../assets/img/images/IndustrialApplicationsCardImg14.png'
import Cimg15 from '../../assets/img/images/IndustrialApplicationsCardImg15.png'
import Cimg16 from '../../assets/img/images/IndustrialApplicationsCardImg16.png'
import Cimg17 from '../../assets/img/images/IndustrialApplicationsCardImg17.png'
import Cimg18 from '../../assets/img/images/IndustrialApplicationsCardImg18.png'
import Cimg19 from '../../assets/img/images/IndustrialApplicationsCardImg19.png'
import Cimg20 from '../../assets/img/images/IndustrialApplicationsCardImg20.png'
import Cimg21 from '../../assets/img/images/IndustrialApplicationsCardImg21.png'

import TechnicalInfoFooter from 'components/Footers/TechnicalInfoFooter';

function IndustrialApplications() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Industrial Applications for Flow Meters</title>
            <meta name="description" content="EPI’s flow meters work in many different applications and industries. Some of the most common are described in this article: check it out!" />
            <link rel="canonical" href="https://epiflow.com/industrialapplications/" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner IASection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/industrialapplications" tag={Link}>Industrial Applications</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Industrial Applications for Meters</h2>
                        <p className='cover-title-p'>
                            EPI flow meters work in many different applications and industries. Some of the most common are described below.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo text-center'>Applications</h4>
                    <p className='commonParaThree text-center mb-4'>
                        In general, thermal flow meters can be used to measure virtually any gas flow. Restrictions apply, of course, but thermal flow meters have been used in the following
                        applications and industries — and continue to be used every day:
                    </p>
                    <Row className='g-4'>
                        <Col md="6">
                            <div className='text-center rounded shadow p-3'>
                                <img src={Cimg5} className="w-100" alt="Oxygen Gas EPI White Paper" />
                                <h4 className='commontitlefour'>
                                    Oxygen
                                </h4>
                                <p className='commonParaThree'>
                                    Uncompressed air and oxygen gas are used in a wide variety of commercial and industrial applications, covering everything from steel
                                    production to dehydrating fruit to renewing municipal water supplies. Uncompressed atmospheric air is chosen for many applications for
                                    obvious reasons – it’s plentiful and effectively free. Pure oxygen is more costly, but its chemical properties make it an important
                                    input in many industrial processes where…
                                </p>
                                <NavLink to="/industrialapplications/oxygen" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Read More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='text-center rounded shadow p-3'>
                                <img src={Cimg8} className="w-100" alt="Methane Gas EPI White Paper" />
                                <h4 className='commontitlefour'>
                                    Methane
                                </h4>
                                <p className='commonParaThree'>
                                    Methane is a greenhouse gas that is emitted naturally from a number of sources, including wetlands, the oceans, the breakdown of organic
                                    materials like human and animal waste, wildfires, and more. Methane is also a key gas used in commercial and industrial applications.
                                    On average, it makes up 94% of the natural gas used to power industry and our homes, and it’s also key in everything from chemical
                                    production to advanced rocket fuels…
                                </p>
                                <NavLink to="/industrialapplications/methane" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Read More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='text-center rounded shadow p-3'>
                                <img src={Cimg13} className="w-100" alt="Flue Gas EPI White Paper" />
                                <h4 className='commontitlefour'>
                                    Flue Gas
                                </h4>
                                <p className='commonParaThree'>
                                    Flue gas is produced anywhere a non-electric furnace or boiler is used to generate heat. That includes the furnaces and chimneys in many of our homes,
                                    but the source of the vast majority of flue gasses – and thus, the source of greatest concern – is industrial processes. The sheer volume of flue gas
                                    released into the atmosphere from factories and plants means that carefully monitoring it is crucial to maintaining the health of the environment and
                                    the people who…
                                </p>
                                <NavLink to="/industrialapplications/flue" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Read More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section-2 */}
            {/* section-3 */}
                <TechnicalInfoFooter />
            {/* section-3 */}
        </>
    )
}

export default IndustrialApplications
