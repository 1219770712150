import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';

// reactstrap components
import {
  UncontrolledCollapse,
  Button,
  Card,
  CardBody,
  CardImg,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardHeader,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Collapse,
  UncontrolledTooltip
} from "reactstrap";

import display from 'assets/img/MeterCustomizerAssets/Display/HAZ/display-with-keypad.png';
// import noDisplay from 'assets/img/MeterCustomizerAssets/Display/HAZ/display-no-keypad.png';

function MeterHousing({ handleChange, state, checkState }){
  const od = 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FMeterCustomizer%2FMeterHousing%2FRemote%2FRemote-8x10.png?alt=media&token=5601d2f8-7ecf-4afb-8be6-da60b3bc8bc2';
  const ex = display;
  const [remoteCable, setRemoteCable] = useState(10);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [meterHousing, setMeterHousing] = useState('dual');

  // collapse states and functions
  const [collapses, setCollapses] = React.useState([0]);
  const changeCollapse = collapse => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter(prop => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  const MakeChange = (data) => {
    handleChange(data);
  }

  const MakeChangeText = (data) => {
    const remote_cable_feet = data.option.target.value;
    data.option_value = +10 + +remote_cable_feet;

    setRemoteCable(data.option_value);
    handleChange(data);
  }

  var title = 'Meter Housing';
  if (isMobile) {
    if(title.length > 18) title = title.substring(0,18) + '...';
  }

  return (
    <>
      <Card style={{
        'border-top-left-radius': 'calc(.45rem - 1px)',
        'border-top-right-radius': 'calc(.45rem - 1px)',
        'border-bottom-left-radius': 'calc(.45rem - 1px)',
        'border-bottom-right-radius': 'calc(.45rem - 1px)'
        }}>
        <CardHeader>
          <a
            aria-expanded={collapses.includes(2)}
            data-toggle="collapse"
            href="#pablo"
            onClick={e => {
              e.preventDefault();
              changeCollapse(2);
            }}
          >
            <Navbar className="bg-epi-blue" style={{
              'height': '10px', 'margin-bottom': '5px',
              'border-top-left-radius': 'calc(.45rem - 1px)',
              'border-top-right-radius': 'calc(.45rem - 1px)'
              }} expand="lg">
              <Container>
                <div className="navbar-translate">
                  <NavbarBrand href="#pablo" onClick={e => e.preventDefault()} style={{'font-size': '22px', 'color': 'white'}}>
                    <p>{ title }</p>
                  </NavbarBrand>
                  <button
                    className="navbar-toggler"
                    id="example-navbar-danger"
                    type="button"
                    aria-expanded={collapses.includes(2)}
                    onClick={e => {
                      e.preventDefault();
                      changeCollapse(2);
                    }}
                  >
                    <i className="now-ui-icons arrows-1_minimal-down" style={{'font-size': '22px', 'color': 'white'}}></i>
                  </button>
                </div>
                <UncontrolledCollapse navbar toggler="#example-navbar-danger">
                  <Nav className="ml-auto" navbar style={{'font-size': '22px', 'color': 'white'}}>
                    <i className="now-ui-icons arrows-1_minimal-down"></i>
                  </Nav>
                </UncontrolledCollapse>
              </Container>
            </Navbar>
          </a>
        </CardHeader>

        <Collapse isOpen={collapses.includes(2)}>
          <CardBody>
            <Row>
              <Col>
                <Row>
                  <Col className="align-items-center">
                    <span className="customizerInputTitle">Transmitter Enclosure
                    {/*    <Button className="questionToolTip" id="DisplayHaz" size="sm">
                        ?
                      </Button>{` `}
                      <UncontrolledTooltip placement="right" target="DisplayHaz" delay={0}>
                      </UncontrolledTooltip> */}
                    </span><br />
                    <span>
                      Dual Sided Explosion-Proof Enclosure For Hazardous Locations (No Agency Approvals)
                    </span>
                    <br /><br />
                  </Col>
                </Row>
                <Row>
                  <Col className="align-items-center">
                    <span className="customizerInputTitle">Display
                      <Button className="questionToolTip" id="Display" size="sm">
                        ?
                      </Button>{` `}
                      <UncontrolledTooltip placement="right" target="Display" delay={0}>
                        A 2-Line Backlit Liquid Crystal Transflective display is included with EPI flow meters.
                        It is easy to read in low light or high light conditions,
                        and will come with the orientation specified in the above section.
                      </UncontrolledTooltip>
                    </span><br />
                    <FormGroup check className="form-check-radio">
                      <Label check>
                        <span className="customizerInputTitleSmallX1">Dual Sided Explosion-proof enclosure for hazardous locations</span>
                          <Input
                            defaultChecked = {
                              checkState(
                                state.meter_housing.remote_enclosure,
                                'dual'
                              )
                            }
                            onClick={ (e) => {
                              MakeChange({
                                section: 'meter_housing',
                                type: 'remote_enclosure',
                                values: ['dual', 'standard'],
                                price_effect: true
                              }, e);
                              setMeterHousing('ex')
                            }}
                            id="display"
                            name="display"
                            type="radio"
                          ></Input>
                        <span className="form-check-sign"></span>
                        <Button className="questionToolTip" id="StandardEnclosureDualSided" size="sm">
                          ?
                        </Button>{` `}
                        <UncontrolledTooltip placement="right" target="StandardEnclosureDualSided" delay={0}>
                          Dual Sided Remote Explosion-proof enclosure with flat glass window; Comes with 2"
                          pipe mounting bracket assembly; For use in hazardous area locations; Class I Division 1,
                          Group B, C, D; Class II Group E, F, G; Class III; Enclosure Type 4X; Class I Zone I; AEx
                          d IIB+H2 IP66; Ex d IIB+H2 IP66; T2; Ta = 0°C to 50°C
                        </UncontrolledTooltip>
                      </Label>
                    </FormGroup>

                    <FormGroup check className="form-check-radio">
                      <Label check>
                        <span className="customizerInputTitleSmallX1">8" x 10" Ordinary Locations (non-explosion proof)</span>
                          <Input
                            defaultChecked = {
                              checkState(
                                state.meter_housing.remote_enclosure,
                                'standard'
                              )
                            }
                            onClick={ (e) => {
                              MakeChange({
                                section: 'meter_housing',
                                type: 'remote_enclosure',
                                values: ['standard', 'dual'],
                                price_effect: true
                              }, e);
                              setMeterHousing('od')
                            }}
                            id="display"
                            name="display"
                            type="radio"
                          ></Input>
                        <span className="form-check-sign"></span>
                        <Button className="questionToolTip" id="StandardEnclosure" size="sm">
                          ?
                        </Button>{` `}
                        <UncontrolledTooltip placement="right" target="StandardEnclosure" delay={0}>
                          Fiberglass Reinforced Polyester (FRP) enclosure with window.
                          Certified to CSA/CUS Standards: Class 2252-03 & 2252-83 for Process
                          Control Equipment in Ordinary Locations Only; CSA Enclosures Type 4X, IP66
                        </UncontrolledTooltip>
                      </Label>
                    </FormGroup><br />
                    <hr />

                    <span className="customizerInputTitle">Interconnecting Remote Cable:
                      <Button className="questionToolTip" id="InterconnectingRemoteCable" size="sm">
                        ?
                      </Button>{` `}
                      <UncontrolledTooltip placement="right" target="InterconnectingRemoteCable" delay={0}>
                        Interconnecting Remote Cable Info.
                      </UncontrolledTooltip>
                    </span><br />
                    <Row>
                      <Col style={{ 'padding-right': '0'}}>
                        <FormGroup>
                          <span className="customizerInputTitleSmallX1">10’ standard/minimum ($5/ft.)</span>
                          <Input
                            className="epiInputSize"
                            id="exampleFormControlSelect1"
                            type="number"
                            placeholder="Enter Length"
                            maxLength={10}
                            min="1"
                            max="1000"
                            defaultValue={
                              checkState(state.meter_housing.remote_cable, 'feet') &&
                              (state.meter_housing.remote_cable.valuesObj.feet - 10)
                            }
                            onChange={ (e) => MakeChangeText({
                              section: 'meter_housing',
                              type: 'remote_cable',
                              values: ['feet'],
                              price_effect: false,
                              text_input: true,
                              option: e
                            }, e)}
                          >
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col
                        className="col-2 my-auto"
                        style={{
                          'padding-right': '0',
                          'padding-left': '5px',
                          'padding-top': '10px'
                        }}
                      >
                      <span className="customizerInputTitleSmallX1">{remoteCable}Ft</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className="text-center">
                {meterHousing === "od"?
                  (<CardImg alt="..." src={od} top></CardImg>):
                  (<CardImg alt="..." src={ex} top></CardImg>)
                }
              </Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
}

export default MeterHousing;
