import React from "react";
// reactstrap components
import{
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Button
} from "reactstrap";
// core components

function Success(){
  return (
    <>
      <Card className="text-center" style={{'margin-top': '10px'}}>
        <CardHeader className="mt-2">Application pending approval</CardHeader>
        <CardBody>
          <img src="https://portal.hipporello.net/default/static/media/icon-check-green.4251f949.svg" />
          <CardTitle tag="h4">Thank you for applying to become a distributor, we will get back to you as soon as possible.</CardTitle>
          {/* <CardText>
            With supporting text below as a natural lead-in to additional
            content.
          </CardText> */}
          {/* <Button
            color="primary"
            href="#pablo"
            onClick={e => e.preventDefault()}
          >
            Go somewhere
          </Button> */}
        </CardBody>
      </Card>
    </>
  );
}

export default Success