import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';

// reactstrap components
import {
  UncontrolledCollapse,
  Button,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardHeader,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Collapse,
  UncontrolledTooltip
} from "reactstrap";

function Approvals({ handleChange, state, checkState }){
  const [selectInputOptions, setSelectInputOptions] = useState(0);
  const [selectInputOptionsTwo, setSelectInputOptionsTwo] = useState(0);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  // collapse states and functions
  const [collapses, setCollapses] = React.useState([1]);
  const changeCollapse = collapse => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter(prop => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  useEffect(() => {
    if(checkState(state.application_information.application_options_other, 'application')){
      setSelectInputOptions(1);
    }

    if(checkState(state.application_information.gas_other, 'gas')){
      setSelectInputOptionsTwo(1);
    }
  }, [state]);

  const MakeChangeDropdown = (data) => {
    data.option_value = data.option.target.value;
    const values = data.values;
    const first_value = values[0]

    if(first_value !== data.option_value){
      values.removeWithValue(data.option_value)
      values[0] = data.option_value;
      values.splice(1,0, first_value);
      values.join();
      data.values = values;
      console.log('Show me pipe option data 1.0 =>', data);
    } else {
      console.log('Show me pipe option data 1.0 =>', data);
    }

    if(data.input_name === 'application'){
      (data.option_value === 'other') ? setSelectInputOptions(1) : setSelectInputOptions(0);
    }

    if(data.input_name === 'gas'){
      (data.option_value === 'other') ? setSelectInputOptionsTwo(1) : setSelectInputOptionsTwo(0);
    }

    handleChange(data);
  }

  const MakeChange = (data) => {
    handleChange(data);
  }

  const MakeChangeText = (data) => {
    data.option_value = data.option.target.value;
    handleChange(data);
  }

  var title = 'Agency Approvals & Certifications';
  if (isMobile) {
    if(title.length > 18) title = title.substring(0,18) + '...';
  }

  return (
    <>
      <Card style={{
        'border-top-left-radius': 'calc(.45rem - 1px)',
        'border-top-right-radius': 'calc(.45rem - 1px)',
        'border-bottom-left-radius': 'calc(.45rem - 1px)',
        'border-bottom-right-radius': 'calc(.45rem - 1px)'
        }}>
        <CardHeader>
          <a
            aria-expanded={collapses.includes(1)}
            data-toggle="collapse"
            href="#pablo"
            onClick={e => {
              e.preventDefault();
              changeCollapse(1);
            }}
          >
            <Navbar className="bg-epi-blue" style={{
              'height': '10px', 'margin-bottom': '5px',
              'border-top-left-radius': 'calc(.45rem - 1px)',
              'border-top-right-radius': 'calc(.45rem - 1px)'
              }} expand="lg">
              <Container>
                <div className="navbar-translate">
                  <NavbarBrand href="#pablo" onClick={e => e.preventDefault()} style={{'font-size': '22px', 'color': 'white'}}>
                    <p>{ title }</p>
                  </NavbarBrand>
                  <button
                    className="navbar-toggler"
                    id="example-navbar-danger"
                    type="button"
                    aria-expanded={collapses.includes(2)}
                    onClick={e => {
                      e.preventDefault();
                      changeCollapse(2);
                    }}
                  >
                    <i className="now-ui-icons arrows-1_minimal-down" style={{'font-size': '22px', 'color': 'white'}}></i>
                  </button>
                </div>
                <UncontrolledCollapse navbar toggler="#example-navbar-danger">
                  <Nav className="ml-auto" navbar style={{'font-size': '22px', 'color': 'white'}}>
                    <i className="now-ui-icons arrows-1_minimal-down"></i>
                  </Nav>
                </UncontrolledCollapse>
              </Container>
            </Navbar>
          </a>
        </CardHeader>

        <Collapse isOpen={collapses.includes(1)}>
          <CardBody>
            <Row>
              <Col>
                <span className="customizerInputTitle">Certifications</span>
                <Button className="questionToolTip" id="Certifications1" size="sm">
                  ?
                </Button>{` `}
                <UncontrolledTooltip placement="right" target="Certifications1" delay={0}>
                  Select which meter certification you would like your flow meter to come with. Note: Not all certifications are available for every meter.
                </UncontrolledTooltip>
                <FormGroup check className="form-check-radio">
                  <Label check>
                    <span className="customizerInputTitleSmallX1">None (CE Only)</span>
                    <Input
                      defaultChecked={
                        checkState(
                          state.approvals_certifications.certifications,
                          'none'
                        )
                      }
                      onClick={ (e) => MakeChange({
                        section: 'approvals_certifications',
                        type: 'certifications',
                        values: ['none', 'csa', 'atex', 'iecex', 'kosha'],
                        price_effect: false
                      }, e)}
                      id="agencywarranty"
                      name="agencywarranty"
                      type="radio"
                    ></Input>
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
                <FormGroup check className="form-check-radio" disabled>
                  <Label check disabled>
                    <span className="customizerInputTitleSmallX1">CSA/CUS</span>
                    <Input disabled
                      defaultChecked={
                        checkState(
                          state.approvals_certifications.certifications,
                          'csa'
                        )
                      }
                      onClick={ (e) => MakeChange({
                        section: 'approvals_certifications',
                        type: 'certifications',
                        values: ['csa', 'none', 'atex', 'iecex', 'kosha'],
                        price_effect: false
                      }, e)}
                      id="agencywarranty"
                      name="agencywarranty"
                      type="radio"
                    ></Input>
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
                <FormGroup check className="form-check-radio" disabled>
                  <Label check>
                    <span className="customizerInputTitleSmallX1">ATEX</span>
                    <Input disabled
                      defaultChecked={
                        checkState(
                          state.approvals_certifications.certifications,
                          'atex'
                        )
                      }
                      onClick={ (e) => MakeChange({
                        section: 'approvals_certifications',
                        type: 'certifications',
                        values: ['atex', 'csa', 'none', 'iecex', 'kosha'],
                        price_effect: false
                      }, e)}
                      id="agencywarranty"
                      name="agencywarranty"
                      type="radio"
                    ></Input>
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
                <FormGroup check className="form-check-radio" disabled>
                  <Label check disabled>
                    <span className="customizerInputTitleSmallX1">IECEx</span>
                    <Input disabled
                      defaultChecked={
                        checkState(
                          state.approvals_certifications.certifications,
                          'iecex'
                        )
                      }
                      onClick={ (e) => MakeChange({
                        section: 'approvals_certifications',
                        type: 'certifications',
                        values: ['iecex', 'atex', 'csa', 'none', 'kosha'],
                        price_effect: false
                      }, e)}
                      id="agencywarranty"
                      name="agencywarranty"
                      type="radio"
                    ></Input>
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
                <FormGroup check className="form-check-radio" disabled>
                  <Label check disabled>
                    <span className="customizerInputTitleSmallX1">KOSHA</span>
                    <Input disabled
                      defaultChecked={
                        checkState(
                          state.approvals_certifications.certifications,
                          'kosha'
                        )
                      }
                      onClick={ (e) => MakeChange({
                        section: 'approvals_certifications',
                        type: 'certifications',
                        values: ['kosha', 'iecex', 'atex', 'csa', 'none'],
                        price_effect: false
                      }, e)}
                      id="agencywarranty"
                      name="agencywarranty"
                      type="radio"
                    ></Input>
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
                <br />
              </Col>

              <Col>
                <span className="customizerInputTitle">Approvals</span>
                <Button className="questionToolTip" id="Approvals1" size="sm">
                  ?
                </Button>{` `}
                <UncontrolledTooltip placement="right" target="Approvals1" delay={0}>
                  If you have selected a certification for your meter, please select which approval rating you will need.
                </UncontrolledTooltip>
                <FormGroup check className="form-check-radio">
                  <Label check>
                    <span className="customizerInputTitleSmallX1">None</span>
                    <Input
                      defaultChecked={
                        checkState(
                          state.approvals_certifications.rating,
                          'none'
                        )
                      }
                      onClick={ (e) => MakeChange({
                        section: 'approvals_certifications',
                        type: 'rating',
                        values: ['none', 't2', 't3', 't4'],
                        price_effect: true
                      }, e)}
                      id="agencyapprovals"
                      name="agencyapprovals"
                      type="radio"
                    ></Input>
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
                <FormGroup check className="form-check-radio" disabled>
                  <Label check disabled>
                    <span className="customizerInputTitleSmallX1">T2</span>
                    <Input disabled
                      defaultChecked={
                        checkState(
                          state.approvals_certifications.rating,
                          't2'
                        )
                      }
                      onClick={ (e) => MakeChange({
                        section: 'approvals_certifications',
                        type: 'rating',
                        values: ['t2', 'none', 't3', 't4'],
                        price_effect: true
                      }, e)}
                      id="agencyapprovals"
                      name="agencyapprovals"
                      type="radio"
                    ></Input>
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
                <FormGroup check className="form-check-radio" disabled>
                  <Label check disabled>
                    <span className="customizerInputTitleSmallX1">T3</span>
                    <Input disabled
                      defaultChecked={
                        checkState(
                          state.approvals_certifications.rating,
                          't3'
                        )
                      }
                      onClick={ (e) => MakeChange({
                        section: 'approvals_certifications',
                        type: 'rating',
                        values: ['t3', 't2', 'none', 't4'],
                        price_effect: true
                      }, e)}
                      id="agencyapprovals"
                      name="agencyapprovals"
                      type="radio"
                    ></Input>
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
                <FormGroup check className="form-check-radio" disabled>
                  <Label check disabled>
                    <span className="customizerInputTitleSmallX1">T4</span>
                    <Input disabled
                      defaultChecked={
                        checkState(
                          state.approvals_certifications.rating,
                          't4'
                        )
                      }
                      onClick={ (e) => MakeChange({
                        section: 'approvals_certifications',
                        type: 'rating',
                        values: ['t4', 't3', 't2', 'none'],
                        price_effect: true
                      }, e)}
                      id="agencyapprovals"
                      name="agencyapprovals"
                      type="radio"
                    ></Input>
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
                <br />
              </Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
}

export default Approvals;
