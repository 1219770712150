
import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";

function PageNotFound() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
      <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>EPI – 404 Page Not Found</title>
          <meta name="description" content="This page has either been moved or no longer exists. Please return to the homepage." />
          // <link rel="canonical" href="https://epiflow.com/404" />
      </Helmet>
        <div className='background-colorOne pb-5'>
            {/* section-1 */}
            <div className='htv-section-1'>
                <div className='image-container'>
                    <div>
                        <h2 className='cover-title-h2'>404 Page Not Found</h2>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <div className='container'>
                <div className='text-center'>
                    <h3 className='commontitleTwo mt-5'>Page Not Found</h3>
                </div>
            </div>
        </div>
        </>
    )
}

export default PageNotFound
