import React from "react";

import Profibus from "../Profibus";
import Bacnet from "../Bacnet";
import Hart from "../Hart";
import MultiRange from "../MultiRange";
import Standard from "../Standard";

function CommunicationBody({handleChange, state, checkState, getKeyByValue}){
  
  switch (getKeyByValue(state.input_power_communication.communications_options, true)) {
    case 'standard':
        return(
          <Standard
            handleChange={handleChange}
            state={ state }
            checkState={ checkState }
          />
        );
      break;

    case 'multi_range':
        return(
          <MultiRange
            handleChange={handleChange}
            state={ state }
            checkState={ checkState }
          />
        );
      break;

    case 'hart':
        return(
          <Hart />
        );
      break;

    case 'bacnet':
        return(
          <Bacnet
            handleChange={handleChange}
            state={ state }
            checkState={ checkState }
          />
        );
      break;

    case 'profibus':
        return(
          <Profibus
            handleChange={handleChange}
            state={ state }
            checkState={ checkState }
          />
        );
      break;

    default:
        return(
          <Standard
            handleChange={handleChange}
            state={ state }
            checkState={ checkState }
          />
        );
      break;
  }

  return(<> </>);
}

export default CommunicationBody;