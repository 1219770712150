import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router";
import 'assets/css/Login.css'
import logo from 'assets/img/logo1.png'

function UnSubscribe() {
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function unSubscribe () {
      let unsubscribe_data = {
        _id: id,
        subscriptions: 0
      }
  
      console.log('Show me update user info 1.0 =>', unsubscribe_data);
  
      try{
        await axios.put(
          `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/subscription`,
          unsubscribe_data,
          { withCredentials: true }
        );
      } catch (e) {
        console.log('Show me update user error 1.0 =>', e);
      }
    }
    unSubscribe();
  }, [])

  return (
    <>
      <div className='login-container'>
        <div className='login-main shadow'>
          <div className='text-center mb-4'>
            <img src={logo} alt="EPI Logo" />
          </div>

          <h5>Subscriptions</h5>
          <p className='pleaselog-text mb-4' style={{ 'color': '#16C172' }}>
            You have been successfully unsubscribed from EPI marketing emails.
          </p>
          
          <a href="#" onClick={() => history.push('/')}>
            <p className="account-text">Back to Home</p>
          </a>

          <a href="#" onClick={() => history.push(`/resubscribe/${id}`)}>
            <p className="account-text">Resubscribe</p>
          </a>
        </div>
      </div>
    </>
  );
}

export default UnSubscribe;
