
import React from 'react';
import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function SavedCartModal({modal, toggle, cartData}) {
  const submitQuote = async () => {
    const cart  = {...cartData.cart_items}
    cartData['total'] = '1000';
    cartData['cart_items'] = cart;
    delete cartData.total_price;
    delete cartData.ot;

    try {
      await axios.post(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/realquotes`,
        cartData,
        { withCredentials: true }
      );
    } catch(e) {
      console.log('Show me db insert error cart', e.message);
    }
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Modal title</ModalHeader>
        <ModalBody>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Do Something
          </Button>{' '}
          <Button color="secondary" onClick={toggle, submitQuote}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default SavedCartModal;