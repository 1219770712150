import React, { useEffect, useState } from "react";

// reactstrap components
import {
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

import { UncontrolledCollapse } from "reactstrap";


function MeterCustomizerNavbar({meterType}) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [pills, setPills] = React.useState(false);
  const [navPosition, setNavPosition] = useState('');

  useEffect(() => {
    const updatePosition = () => {
      if (
        document.documentElement.scrollTop > 2050 ||
        document.body.scrollTop > 2050
      ) {
        setNavPosition('fixed-top');
      } else {
        setNavPosition('');
      }
    };

    window.addEventListener("scroll", updatePosition);
    return function cleanup() {
      window.removeEventListener("scroll", updatePosition);
    };
  });

  const setPagePosition = (pill) => {
    // window.location.href = "#Orientation_";
    setPills(pill)
  }

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
        <Navbar className={ `${navPosition} epi-customerizer-nav-gray` } color="off-white" expand="lg" id="customizer">
          <Container>
            <div className="navbar-translate">
              <NavbarBrand href="#pablo" onClick={e => e.preventDefault()} style={{ 'color': 'white'}}>

              </NavbarBrand>
              <button
                className="navbar-toggler"
                id="example-navbar-danger"
                type="button"
              >
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </button>
            </div>
            <UncontrolledCollapse navbar toggler="#example-navbar-danger">
              <Nav navbar>
                <NavItem className={pills === "1" ? "active" : ""}>
                  <NavLink
                    href="#Approvals"
                    color="default"
                    onClick={() => {
                      setPills("1");
                    }}
                  >
                    <p>Approvals</p>
                  </NavLink>
                </NavItem>

                <NavItem className={pills === 2 ? "active" : ""}>
                  <NavLink
                    href="#orientation"
                    color="default"
                    onClick={() => {
                      setPills(2);
                    }}
                  >
                    <p>Orientation</p>
                  </NavLink>
                </NavItem>

                <NavItem className={pills === "3" ? "active" : ""}>
                  <NavLink
                    href="#meterHousing"
                    color="default"
                    onClick={() => {
                      setPills("3");
                    }}
                  >
                    <p>Housing</p>
                  </NavLink>
                </NavItem>

                {meterType === "inline" &&
                  <NavItem className={pills === "4" ? "active" : ""}>
                    <NavLink
                      href="#pipeInformation"
                      color="default"
                      onClick={() => {
                        setPills("4");
                      }}
                    >
                      <p>Pipe</p>
                    </NavLink>
                  </NavItem>
                }

                {meterType === "insertion" &&
                  <NavItem className={pills === "4" ? "active" : ""}>
                    <NavLink
                      href="#probeSelection"
                      color="default"
                      onClick={() => {
                        setPills("4");
                      }}
                    >
                      <p>Probe</p>
                    </NavLink>
                  </NavItem>
                }

                <NavItem className={pills === "5" ? "active" : ""}>
                  <NavLink
                    href="#applicationInformation"
                    color="default"
                    onClick={(e) => {
                      setPills("5");
                    }}
                  >
                    <p>Application</p>
                  </NavLink>
                </NavItem>

                <NavItem className={pills === "6" ? "active" : ""}>
                  <NavLink
                    href="#inputPower"
                    color="default"
                    onClick={(e) => {
                      setPills("6");
                    }}
                  >
                    <p>Input Power</p>
                  </NavLink>
                </NavItem>

                <NavItem className={pills === "7" ? "active" : ""}>
                  <NavLink
                    href="#mountingRequirements"
                    color="default"
                    onClick={(e) => {
                      setPills("7");
                    }}
                  >
                    <p>Mounting</p>
                  </NavLink>
                </NavItem>

                <NavItem className={pills === "8" ? "active" : ""}>
                  <NavLink
                    href="#processParameters"
                    color="default"
                    onClick={(e) => {
                      setPills("8");
                    }}
                  >
                    <p>Parameters</p>
                  </NavLink>
                </NavItem>

                {meterType === "inline" &&
                  <NavItem className={pills === "9" ? "active" : ""}>
                    <NavLink
                      href="#flowBody"
                      color="default"
                      onClick={(e) => {
                        setPills("9");
                      }}
                    >
                      <p>Flowbody</p>
                    </NavLink>
                  </NavItem>
                }

                <NavItem className={pills === "10" ? "active" : ""}>
                  <NavLink
                    href="#enhancements"
                    color="default"
                    onClick={(e) => {
                      setPills("10");
                    }}
                  >
                    <p>Enhancements</p>
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
    </>
  );
}

export default MeterCustomizerNavbar;
