import React from "react";
import { useMediaQuery } from 'react-responsive';

// reactstrap components
import {
  UncontrolledCollapse,
  Button,
  Card,
  CardBody,
  CardImg,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardHeader,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Collapse,
  UncontrolledTooltip
} from "reactstrap";

function FlowBody({handleChange, state, checkState }){
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  // collapse states and functions
  const [collapses, setCollapses] = React.useState([0]);
  const changeCollapse = collapse => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter(prop => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  const MakeChangeDropdown = (data) => {
    data.option_value = data.option.target.value;
    console.log('Show me Question Or Comments data 1.0 =>', data);
    const values = data.values;
    const first_value = values[0]

    if(first_value !== data.option_value){
      values.removeWithValue(data.option_value)
      values[0] = data.option_value;
      values.splice(1,0, first_value);
      values.join();
      data.values = values;
      console.log('Show me pipe option data 1.0 =>', data);
    } else {
      console.log('Show me pipe option data 1.0 =>', data);
    }

    handleChange(data);
  }

  const MakeChange = (data) => {
    handleChange(data);
  }

  var title = 'Wetted Materials';
  if (isMobile) {
    if(title.length > 18) title = title.substring(0,18) + '...';
  }

  return (
    <>
      <Card style={{
        'border-top-left-radius': 'calc(.45rem - 1px)',
        'border-top-right-radius': 'calc(.45rem - 1px)',
        'border-bottom-left-radius': 'calc(.45rem - 1px)',
        'border-bottom-right-radius': 'calc(.45rem - 1px)'
        }}>
        <CardHeader>
          <a
            aria-expanded={collapses.includes(2)}
            data-toggle="collapse"
            href="#pablo"
            onClick={e => {
              e.preventDefault();
              changeCollapse(2);
            }}
          >
            <Navbar className="bg-epi-blue" style={{
              'height': '10px', 'margin-bottom': '5px',
              'border-top-left-radius': 'calc(.45rem - 1px)',
              'border-top-right-radius': 'calc(.45rem - 1px)'
              }} expand="lg">
              <Container>
                <div className="navbar-translate">
                  <NavbarBrand href="#pablo" onClick={e => e.preventDefault()} style={{'font-size': '22px', 'color': 'white'}}>
                    <p>{ title }</p>
                  </NavbarBrand>
                  <button
                    className="navbar-toggler"
                    id="example-navbar-danger"
                    type="button"
                    aria-expanded={collapses.includes(2)}
                    onClick={e => {
                      e.preventDefault();
                      changeCollapse(2);
                    }}
                  >
                    <i className="now-ui-icons arrows-1_minimal-down" style={{'font-size': '22px', 'color': 'white'}}></i>
                  </button>
                </div>
                <UncontrolledCollapse navbar toggler="#example-navbar-danger">
                  <Nav className="ml-auto" navbar style={{'font-size': '22px', 'color': 'white'}}>
                    <i className="now-ui-icons arrows-1_minimal-down"></i>
                  </Nav>
                </UncontrolledCollapse>
              </Container>
            </Navbar>
          </a>
        </CardHeader>

        <Collapse isOpen={collapses.includes(2)}>
          <CardBody>
          <Row>
              <Col>
                <FormGroup>
                  <label htmlFor="exampleFormControlSelect1">
                    <span className="customizerInputTitle">Sensor Material</span>
                    <Button className="questionToolTip" id="SensorMaterial" size="sm">
                      ?
                    </Button>{` `}
                    <UncontrolledTooltip placement="right" target="SensorMaterial" delay={0}>
                      RTD Sheath Material
                    </UncontrolledTooltip>
                  </label>
                  <Input
                    className="epiInputSize form-select"
                    id="exampleFormControlSelect1"
                    type="select"
                    onChange={ (e) => MakeChangeDropdown({
                      section: 'flow_body',
                      type: 'sensors',
                      values: [
                        'cer',
                        'sss',
                        'has'
                      ],
                      price_effect: true,
                      option: e
                    }, e)}
                  >
                    <option value="" disabled>Select Sensor Material</option>
                    <option selected={checkState(state.flow_body.sensors, 'sss')} value="sss">Stainless Steel 316L</option>
                    <option disabled selected={checkState(state.flow_body.sensors, 'has')} value="has">Hastelloy C-276</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <br />
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitle">Sensor shroud</span>
            </label>
            <Row className="text-center">
              {state.meter_type.type === "inline" &&
                <Col>
                  <CardImg style={{'width': '152.65px', 'height': '150px'}} alt="..." src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FMeterCustomizer%2FSensors%2FFAT%2FFAT.png?alt=media&token=09329640-0b00-4b47-9906-6185f5dcb397" top></CardImg>
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">Standard Sensor</span>
                      <Input
                        defaultChecked={
                          checkState(
                            state.flow_body.sensors_shield,
                            'standard'
                          )
                        }
                        onClick={ (e) => MakeChange({
                          section: 'flow_body',
                          type: 'sensors_shield',
                          values: ['standard', 'rod', 'sheild'],
                          price_effect: true,
                        }, e)}
                        id="type"
                        name="type"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                  <p>
                    The standard sensor shroud will work best for most flow conditions.
                  </p>
                </Col>
              }

              {state.meter_type.type === "insertion" &&
                <Col>
                  <CardImg style={{'width': '152.65px', 'height': '150px'}} alt="..." src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FMeterCustomizer%2FSensors%2FFAT%2FFAT.png?alt=media&token=09329640-0b00-4b47-9906-6185f5dcb397" top></CardImg>
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">Standard Sensor</span>
                      <Input
                        defaultChecked={
                          checkState(
                            state.flow_body.sensors_shield,
                            'standard'
                          )
                        }
                        onClick={ (e) => MakeChange({
                          section: 'flow_body',
                          type: 'sensors_shield',
                          values: ['standard', 'rod', 'sheild'],
                          price_effect: true,
                        }, e)}
                        id="type"
                        name="type"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                  <p>
                    The standard sensor shroud will work best for most flow conditions.
                  </p>
                </Col>
              }
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
}

export default FlowBody;
