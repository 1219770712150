import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { Alert } from 'reactstrap';

// reactstrap components
import {
  Button,
  Row,
  Col,
  Modal,
  ModalBody,
  NavLink
} from "reactstrap";

import manuals from 'assets/img/documents_downloads/manuals.png';
import brochures from 'assets/img/documents_downloads/brochures.png';
import wiring from 'assets/img/documents_downloads/wiring.png';
import installation from 'assets/img/documents_downloads/installation.png';
import cadDrawing from 'assets/img/documents_downloads/cad-drawings.png';

function DownloadModal({setDownloadModal, downloadModal, downloadData}){
  const isAuthenticated_ = JSON.parse(sessionStorage.getItem('ciuli'));
    // const isAuthenticated_ = true;

  const routerAuth = {
    isAuthenticated: false,
    authenticate() {
      if (isAuthenticated_) {
        routerAuth.isAuthenticated = true;
      };
    }
  };
  routerAuth.authenticate();

  const [viewAlert, setViewAlert] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const requestCadDownload = async () => {
    const request_data = {
      meter: downloadData.name
    }
    
    try{
      await axios.post(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/downloadrequest`,
        request_data,
        { withCredentials: true }
      )
    } catch (e) {
      console.log('Show me update user error 1.0 =>', e);
    }
  }

  console.log('Show me download info 1.0 =>', downloadData)

  return (
    <>
      <Modal
        isOpen={downloadModal}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setDownloadModal(false)}
      >
        <div className="modal-headers text-center">
          <h4 className="modal-title" id="myLargeModalLabel">
            {downloadData.name}
          </h4>
        </div>
        <ModalBody>
          <Row className="text-center">
            {routerAuth.isAuthenticated &&
              <Alert color="info" isOpen={viewAlert}>
                <div className="container">
                  <div className="alert-icon">
                    <i className="now-ui-icons ui-2_like"></i>
                  </div>
                  <strong>Request Sent!</strong> The CAD drawings will be emailed to you within the next 24 hours.
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => {setViewAlert(!viewAlert)}}
                  >
                    <span aria-hidden="true">
                      <i className="now-ui-icons ui-1_simple-remove"></i>
                    </span>
                  </button>
                </div>
              </Alert>
            }

            {!routerAuth.isAuthenticated &&
              <Alert color="danger" isOpen={viewAlert}>
                <div className="container">
                  <div className="alert-icon">
                    <i className="now-ui-icons objects_key-25"></i>
                  </div>
                  <strong>Must be loged in to download CAD drawings</strong>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => {setViewAlert(!viewAlert)}}
                  >
                    <span aria-hidden="true">
                      <i className="now-ui-icons ui-1_simple-remove"></i>
                    </span>
                  </button>
                </div>
              </Alert>
            }
            <Col>
              <div>
                <img src={manuals} alt="..." />
                <div class="card-body" style={{ 'height': '100px'}}>
                  <h5 class="card-title">Manual</h5>
                </div>
                <NavLink className="downloadButton" tag={Link} target="_blank" to={{pathname: downloadData.manual}}>
                  Download
                </NavLink>
              </div>
            </Col>

            <Col>
              <div>
                <img src={brochures} alt="..." />
                <div class="card-body" style={{ 'height': '100px'}}>
                  <h5 class="card-title">Brochure</h5>
                </div>
                <NavLink className="downloadButton" tag={Link} target="_blank" to={{pathname: downloadData.brouchure}}>
                  Download
                </NavLink>
              </div>
            </Col>
            <Col>
              <div>
                <img src={wiring} alt="..." />
                <div class="card-body" style={{ 'height': '100px'}}>
                  <h5 class="card-title">Wiring Diagrams</h5>
                </div>
                <NavLink className="downloadButton" tag={Link} target="_blank" to={{pathname: downloadData.wiring}}>
                  Download
                </NavLink>
              </div>
            </Col>
            <Col>
              <div>
                <img src={installation} alt="..." />
                <div class="card-body" style={{ 'height': '100px'}}>
                  <h5 class="card-title">Installation</h5>
                </div>
                <NavLink className="downloadButton" tag={Link} target="_blank" to={{pathname: downloadData.installation}}>
                  Download
                </NavLink>
              </div>
            </Col>
            <Col>
              <div>
                <img src={cadDrawing} alt="..." />
                <div class="card-body" style={{ 'height': '100px'}}>
                  <h5 class="card-title">CAD Drawings</h5>
                </div>
                {downloadData.cad === ""?
                  (
                    <NavLink className="downloadButton" href="#" onClick={() => {
                      {routerAuth.isAuthenticated && requestCadDownload()}
                      setViewAlert(true);
                    }}>
                      Download
                    </NavLink>
                  ):
                  (
                    <NavLink className="downloadButton" tag={Link} target="_blank" to={{pathname: downloadData.cad}}>
                      Download
                    </NavLink>
                  )
                }
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}

export default DownloadModal;
