import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';

// reactstrap components
import {
  UncontrolledCollapse,
  Button,
  Card,
  CardBody,
  CardImg,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardHeader,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Collapse,
  UncontrolledTooltip
} from "reactstrap";

function MeterHousing({handleChange, state, checkState }){
  const display_insertion = 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FMeterCustomizer%2FMeterHousing%2FVal%2Fval-500-display.png?alt=media&token=2155b300-d680-43c8-b70d-f12aa45941b6';
  const noDisplay_insertion = 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FMeterCustomizer%2FMeterHousing%2FHaz%2Fdisplay-no-keypad.png?alt=media&token=1f0a628a-0f77-47b7-ab3d-4e2c8dd1735e';
  const [meterHousing, setMeterHousing] = useState('display');
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  // collapse states and functions
  const [collapses, setCollapses] = React.useState([0]);
  const changeCollapse = collapse => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter(prop => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  const MakeChange = (data) => {
    handleChange(data);
  }

  var title = 'Meter Housing';
  if (isMobile) {
    if(title.length > 18) title = title.substring(0,18) + '...';
  }

  console.log('Show me meter ori state 1.0.4 =>', state.meter_housing);

  return (
    <>
      <Card style={{
        'border-top-left-radius': 'calc(.45rem - 1px)',
        'border-top-right-radius': 'calc(.45rem - 1px)',
        'border-bottom-left-radius': 'calc(.45rem - 1px)',
        'border-bottom-right-radius': 'calc(.45rem - 1px)'
        }}>
        <CardHeader>
          <a
            aria-expanded={collapses.includes(2)}
            data-toggle="collapse"
            href="#pablo"
            onClick={e => {
              e.preventDefault();
              changeCollapse(2);
            }}
          >
            <Navbar className="bg-epi-blue" style={{
              'height': '10px', 'margin-bottom': '5px',
              'border-top-left-radius': 'calc(.45rem - 1px)',
              'border-top-right-radius': 'calc(.45rem - 1px)'
              }} expand="lg">
              <Container>
                <div className="navbar-translate">
                  <NavbarBrand href="#pablo" onClick={e => e.preventDefault()} style={{'font-size': '22px', 'color': 'white'}}>
                    <p>{ title }</p>
                  </NavbarBrand>
                  <button
                    className="navbar-toggler"
                    id="example-navbar-danger"
                    type="button"
                    aria-expanded={collapses.includes(2)}
                    onClick={e => {
                      e.preventDefault();
                      changeCollapse(2);
                    }}
                  >
                    <i className="now-ui-icons arrows-1_minimal-down" style={{'font-size': '22px', 'color': 'white'}}></i>
                  </button>
                </div>
                <UncontrolledCollapse navbar toggler="#example-navbar-danger">
                  <Nav className="ml-auto" navbar style={{'font-size': '22px', 'color': 'white'}}>
                    <i className="now-ui-icons arrows-1_minimal-down"></i>
                  </Nav>
                </UncontrolledCollapse>
              </Container>
            </Navbar>
          </a>
        </CardHeader>

        <Collapse isOpen={collapses.includes(2)}>
          <CardBody>
            <Row>
              <Col>
                <Row>
                  <Col className="align-items-center">
                    <span className="customizerInputTitle">Transmitter Enclosure
                    {/*    <Button className="questionToolTip" id="DisplayHaz" size="sm">
                        ?
                      </Button>{` `}
                      <UncontrolledTooltip placement="right" target="DisplayHaz" delay={0}>
                      </UncontrolledTooltip> */}
                    </span><br />
                    <span>
                      Dual Sided Explosion-Proof Enclosure For Hazardous Locations (No Agency Approvals)
                    </span>
                    <br /><br />
                  </Col>
                </Row>
                <Row>
                  <Col className="align-items-center">
                    <span className="customizerInputTitle">Display
                      <Button className="questionToolTip" id="Display" size="sm">
                        ?
                      </Button>{` `}
                      <UncontrolledTooltip placement="right" target="Display" delay={0}>
                        A 2-Line Backlit Liquid Crystal Transflective display is included with EPI flow meters.
                        It is easy to read in low light or high light conditions,
                        and will come with the orientation specified in the above section.
                      </UncontrolledTooltip>
                    </span><br />
                    <FormGroup check className="form-check-radio">
                      <Label check>
                        <span className="customizerInputTitleSmallX1">Display with keypad</span>
                        <Input
                          defaultChecked = {
                            checkState(
                              state.meter_housing.display,
                              'display'
                            )
                          }
                          onClick={(e) => { MakeChange({
                              section: 'meter_housing',
                              type: 'display',
                              values: ['display', 'no_display'],
                              price_effect: true,
                              meter_model_effect: true,
                              meter_model_type: 'display',
                              meter_model_value: 'D51',
                              option: e
                            }, e);
                            setMeterHousing('display')
                          }}
                          id="display"
                          name="display"
                          type="radio"
                        ></Input>
                        <span className="form-check-sign"></span>
                      </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio">
                      <Label check>
                        <span className="customizerInputTitleSmallX1">No Display</span>
                        <Input
                          defaultChecked = {
                            checkState(
                              state.meter_housing.display,
                              'no_display'
                            )
                          }
                          onClick={(e) => {
                            MakeChange({
                              section: 'meter_housing',
                              type: 'display',
                              values: ['no_display', 'display'],
                              price_effect: true,
                              meter_model_effect: true,
                              meter_model_type: 'display',
                              meter_model_value: 'D50'
                            });
                            setMeterHousing('noDisplay')
                          }}
                          id="display"
                          name="display"
                          type="radio"
                        ></Input>
                        <span className="form-check-sign"></span>
                      </Label>
                    </FormGroup><br />
                  </Col>
                </Row>
              </Col>
              <Col className="text-center">
                {meterHousing === "display"?
                  (<CardImg alt="..." src={display_insertion} top></CardImg>):
                  (<CardImg alt="..." src={noDisplay_insertion} top></CardImg>)
                }
              </Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
}

export default MeterHousing;
