import React,{ useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useSwr from 'swr';
import { useHistory } from 'react-router';
import '../../assets/css/Quotes.css';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import fetcher from 'utils/fetcher';
import { Row, Col, Button, NavLink} from "reactstrap";
import Pagination from './Pagination';
import AllRealQuotesTable from './AllRealQuotesTable';

function AllRealQuotes() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const [quoteData, setQuoteData] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(15);
    const [pageNumbers, setPageNumbers] = useState(1);
    const page = { allQuotesReal: true }

    var { data } = useSwr(`/api/me`, fetcher);
    var { data: Quotes } = useSwr(`/api/realquotes/all/all`, fetcher);

    useEffect(() => {
        setIsLoading(true);
        if(data){
            setUserData(data);
        }

        if(Quotes){
            setQuoteData(Quotes)
            setIsLoading(false);
        }
    }, [data, Quotes]);

    const headerData = {
        firstname: userData.firstname,
        page: 'ALL QUOTES'
    }

    const quoteClicked = (_id) => {
        history.push(`/portal/quotes/real/${_id}#`);
    }

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentQuotes = quoteData.slice(indexOfFirstPost, indexOfLastPost);

    console.log('Show me quote 1.0 =>', Quotes);

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page} /></div>) : undefined}
                    <div className='common-main'>
                        <div className='shadow'>
                            <h2 className='p-3'>All Submited Quotes</h2>
                            <div className='quotes body p-3'>
                                <p>View a list of all user submitted quotes. Select a quote to edit, delete, or export as a PDF.</p>
                                <AllRealQuotesTable configs={currentQuotes} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllRealQuotes
