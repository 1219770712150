import React, { useState, useEffect } from 'react';
import useSwr from 'swr';
import '../assets/css/ContactUs.css';
import HipporelloEmbed from "@hipporello/embed"
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import fetcher from 'utils/fetcher';

function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const page = { contactUs: true }

    const { data } = useSwr(`${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/me`, fetcher);
    useEffect(() => {
        if(data){
            setUserData(data);
        }
    }, [data]);

    console.log('Show me user data 1.0.1 =>', data);

    const headerData = {
        firstname: userData.firstname,
        page: 'CONTACT US'
    }

    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                    <FontAwesomeIcon icon={faArrowRight} />
                </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page}/></div>) : undefined}
                    <div className='common-main'>
                        <h2 className='p-3'>Contact Us</h2>
                        <div className='contact body p-3'>
                            <p>Do you have a specific question that you can't find the answer to? We are always happy to help you with whatever you may need! Fill out the form below and we will get back to you as soon as we can.</p>
                            <hr />
                            <HipporelloEmbed formId="e0521245b32f4ebba50afdb7ea64f5d0"/>
                            <hr/>
                          <section className='section-7 no-bg'>
                                <div className='container'>
                                    <div className='row pb-5'>
                                        <div className='col-lg-5 col-md-6 col-sm-12'>
                                            <div class="card">
                                                <div class="card-body">
                                                    <h5 class="card-title section-header">Email Us</h5>
                                                    <table>
                                                        <tr>
                                                            <td><p class="card-text">General Information</p></td>
                                                            <td>:</td>
                                                            <td><a href="mailto:info@epiflow.com">info@epiflow.com</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td><p class="card-text">Orders / Request Quote</p></td>
                                                            <td>:</td>
                                                            <td><a href="mailto:sales@epiflow.com">sales@epiflow.com</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td><p class="card-text">Representative Support</p></td>
                                                            <td>:</td>
                                                            <td><a href="mailto:reps@epiflow.com">reps@epiflow.com</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td><p class="card-text">Technical Support</p></td>
                                                            <td>:</td>
                                                            <td><a href="mailto:episupport@epiflow.com">episupport@epiflow.com</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td><p class="card-text">Media Request:</p></td>
                                                            <td>:</td>
                                                            <td><a href="mailto:media@epiflow.com">media@epiflow.com</a></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-6 col-sm-12'>
                                            <div class="card">
                                                <div class="card-body call-us-main">
                                                    <h5 class="card-title section-header">Call Us</h5>
                                                    <h5 class="card-title section-header phone">831-648-7777</h5>
                                                    <h5 className='toll-free'>TOLL FREE</h5>
                                                    <table className='m-auto mt-3 mb-3'>
                                                        <tr>
                                                            <td><p class="card-text">Sales</p></td>
                                                            <td>:</td>
                                                            <td><a href="tel:1-831-648-7777">831-648-7777</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td><p class="card-text">Fax</p></td>
                                                            <td>:</td>
                                                            <td><a href="tel:1-831-648-7780">831-648-7780</a></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-sm-12 m-auto'>
                                            <div class="card">
                                                <div class="card-body call-us-main">
                                                    <h5 class="card-title section-header">Visit Us</h5>
                                                    <table className='m-auto mt-3 mb-3'>
                                                        <tr>
                                                            <td className='align-top'><p class="card-text">Address:</p></td>
                                                            <td className='pb-2'>
                                                                <a target="_blank" href="https://www.google.com/maps/place/465+Reservation+Rd,+Marina,+CA+93933/@36.6793515,-121.7808481,17z/data=!4m2!3m1!1s0x808dfcec6abe5e8f:0x30f37f3f935ca7df" className='d-block text-left'>465 Reservation Road, Marina, CA 93933</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='align-top'><p class="card-text">Hours:</p></td>
                                                            <td><p className='card-text c-time'>Monday - Friday, <br /> 7:00am-5:00pm</p></td>
                                                        </tr>
                                                    </table>
                                                    <small>*Please call ahead to schedule an appointment to visit.</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <iframe src="https://maps.google.com/maps?q=465%20Reservation%20Rd,%20Marina,%20CA%2093933&t=k&z=15&ie=UTF8&iwloc=&output=embed" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" title='ss' className='rounded'></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactUs
