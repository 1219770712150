import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";

import ProductViewSidebar from "./ProductSidebar/ProductViewSidebar";

function ProductViewNavbar({navbarData, page, setPage}) {
  const [showMenu, setShowMenu] = useState(false);

  const MobileMenu = () => {
    return(
      <>
        <Nav
          className="nav-pills-info nav-pills-icons"
          pills
          role="tablist"
          style={{ 'padding-left': '5px', 'padding-right': '10px', 'padding-bottom': '35px' }}
        >
          <NavItem>
            <NavLink
              className={page === "discover" ? "active": ""}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setPage("discover");
              }}
            >
              <i className="productIcon"><img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Icons%2FProducts%2FTech%20Specs%2FCustomizer%2FWhite.svg?alt=media&token=67d383ca-5d04-4569-81a2-71617209edf4" alt="Discover Icon" /></i>
              Discover
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={page === "specs" ? "active": ""}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setPage("specs");
              }}
            >
              <i className="productIcon"><img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Icons%2FProducts%2FTech%20Specs%2FTech%20Specs%2FWhite.svg?alt=media&token=158ea821-bdb1-49a7-b859-aa8d8a1fb8f4" alt="Tech Specs Icon" /></i>
              Tech Specs
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={page === "cad" ? "active": ""}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setPage("cad");
              }}
            >
              <i className="productIcon"><img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Icons%2FProducts%2FTech%20Specs%2FCAD%2FWhite.svg?alt=media&token=931b2dac-b250-409f-a8e9-6e5bc3eebcc9" alt="CAD Files Icon" /></i>
              CAD Files
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={page === "support" ? "active": ""}
              tag={Link}
              to="/support"
            >
              <i className="productIcon"><img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Icons%2FProducts%2FTech%20Specs%2FSupport%2FWhite.svg?alt=media&token=c8623bef-a9ed-4b5e-a873-865b12a29167" alt="Support Icon" /></i>
              Support
            </NavLink>
          </NavItem>
        </Nav>
      </>
    )
  }

  const showSidebar = () => {
    document.getElementById("productSidebar").style.width = "100%";
  }

  return(
    <>
      <Navbar className="bg-epi-blue" style={{'min-height': '80px'}} expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand onClick={e => e.preventDefault()} style={{ 'color': 'white'}}>
              <p style={{'font-size': '1.8571em'}}>{ navbarData.product_title }</p><br />
              { navbarData.product_type }
            </NavbarBrand>
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => showSidebar()}
            >
              <span className="navbar-toggler-bar-2 bar1"></span>
              <span className="navbar-toggler-bar-2 bar2"></span>
              <span className="navbar-toggler-bar-2 bar3"></span>
            </button>
          </div>
          <UncontrolledCollapse navbar toggler="#example-navbar-danger">
            <Nav className="ml-auto" navbar style={{'font-size': '1.1em'}}>
              <NavItem className={page === "dicover" ? "active": ""}>
                <NavLink
                  href="#"
                  style={
                    page === "discover"?
                    ({ 'color': 'white', 'border-bottom': '2px solid whitesmoke' }):
                    ({ 'color': 'white'})
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setPage("discover");
                  }}
                >
                  <i className="productIcon"><img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Icons%2FProducts%2FTech%20Specs%2FCustomizer%2FWhite.svg?alt=media&token=67d383ca-5d04-4569-81a2-71617209edf4" alt="Discover Icon" /></i>
                  <p>Discover</p>
                </NavLink>
              </NavItem>

              <NavItem className={page === "specs" ? "active": ""}>
                <NavLink
                  href="#"
                  style={
                    page === "specs"?
                    ({ 'color': 'white', 'border-bottom': '2px solid whitesmoke' }):
                    ({ 'color': 'white'})
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setPage("specs");
                  }}
                >
                  <i className="productIcon"><img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Icons%2FProducts%2FTech%20Specs%2FTech%20Specs%2FWhite.svg?alt=media&token=158ea821-bdb1-49a7-b859-aa8d8a1fb8f4" alt="Tech Specs Icon" /></i>
                  <p>Tech Specs</p>
                </NavLink>
              </NavItem>

              <NavItem className={page === "cad" && "active"}>
                <NavLink
                  href="#"
                  style={
                    page === "cad"?
                    ({ 'color': 'white', 'border-bottom': '2px solid whitesmoke' }):
                    ({ 'color': 'white'})
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setPage("cad");
                  }}
                >
                  <i className="productIcon"><img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Icons%2FProducts%2FTech%20Specs%2FCAD%2FWhite.svg?alt=media&token=931b2dac-b250-409f-a8e9-6e5bc3eebcc9" alt="CAD Files Icon" /></i>
                  <p>CAD Files</p>
                </NavLink>
              </NavItem>

              <NavItem className={page === "support" && "active"}>
                <NavLink
                  style={{ 'color': 'white'}}
                  tag={Link}
                  to="/support"
                >
                  <i className="productIcon"><img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Icons%2FProducts%2FTech%20Specs%2FSupport%2FWhite.svg?alt=media&token=c8623bef-a9ed-4b5e-a873-865b12a29167" alt="Support Icon" /></i>
                  <p>Support</p>
                </NavLink>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
      <ProductViewSidebar setPage={setPage} />
      {showMenu && (<MobileMenu />)}
    </>
  );
}

export default ProductViewNavbar;
