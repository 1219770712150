import React, { FC, useEffect, useState, useCallback, useReducer } from 'react'
import axios from "axios";
import useSwr from 'swr';
import { validate } from 'email-validator'
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { Button } from 'reactstrap'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';


import fetcher from 'utils/fetcher';

import '../../assets/css/Login.css'
import logo from '../../assets/img/logo1.png'

const errorReducer = (state, action) => {
  switch (action.type) {
    case 'email':
      return {
        ...state,
        email: action.payload
      }
    case 'password':
      return {
        ...state,
        password: action.payload
      }

    case 'loginError':
      return {
        ...state,
        loginError: action.payload
      }

    case 'notActivated':
      return {
        ...state,
        notActivated: action.payload
      }
    default:
      throw new Error();
  }
}

function Login() {
  const history = useHistory();
  var { storedId } = useParams();
  var { activationId } = useParams();
  const { data } = useSwr(`/api/me`, fetcher);


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(400)
  const [dirty, setDirty] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [seePassword, setSeePassword] = useState(false)
  const [noPasswordLogin, setNoPasswordLogin] = useState(false)
  const [error, dispatch] = useReducer(errorReducer, {
    email: false,
    password: false
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    {data && history.push("/portal")}
  }, [data]);

  useEffect(() => {
    console.log('Show me form values 1.0 =>', email, password);
    setDirty(false);
    setDisabled(false);
    setLoading(false);
  }, [email, password])


  const handleValidation = useCallback(() => {    
    if(dirty){
      if(!validate(email)){
        dispatch({
          type: 'email',
          payload: 'Please enter a valid email address'
        })
      }
      

      if(password === ''){
        dispatch({
          type: 'password',
          payload: 'Please enter a valid password'
        })
      }

      if(message === 401){
        dispatch({
          type: 'loginError',
          payload: 'Incorrect Email or Password'
        })
      }

      if(message === 402){
        dispatch({
          type: 'notActivated',
          payload: `Your account need to be activated before you login. 
          Please click the activation link in your email to activate your account.`
        })
      }
    }
  }, [email, password, message, dirty])
  

  useEffect(() => {
    handleValidation()
  }, [handleValidation])

  const handleLogin = async (e) => {
    e.preventDefault()

    if (!dirty && !disabled){
      setDirty(true)
      handleValidation()
    }

    setDisabled(true);
    setLoading(true);

    if(!error.email || !email.password){
      let login_data = {
        email: email,
        password: password
      }
  
      if(activationId){
        const activation_data = {
          email: email,
          activation_id: activationId,
          activation: '1'
        }
        try {
          await axios.post(
            `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/activation`,
            activation_data,
            { withCredentials: true }
          )
  
          await axios.post(
            `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/sessions`,
            login_data,
            { withCredentials: true }
          )
          sessionStorage.setItem('ciuli', JSON.stringify(true));
        } catch (e) {
          return
        }
      }
  
      try {
        await axios.post(
          `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/sessions`,
          login_data,
          { withCredentials: true }
        )
        // console.log("Show me login return 1.0 =>", data, data.accessToken);
        sessionStorage.setItem('ciuli', JSON.stringify(true));
  
        if(storedId){
          const quoteData = JSON.parse(sessionStorage.getItem('_customized_meter'));
          if(storedId === quoteData.storedId){
            delete quoteData.storedId;
            await axios.post(
              `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/quotes`,
              quoteData,
              { withCredentials: true }
            )
            sessionStorage.removeItem('_customized_meter')
          }
        }
        history.go("/portal/quotes");
      } catch (e) {
        setMessage(e.response.status)
      }
    }
  };


  const handleLoginNoPassword = async () => {
    console.log('Show me Login without a password 1.0 =>')
    let login_data = {
      email: email
    }

    try {
      const loginToken = await axios.post(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/sessions/requestnopasswordlogin`,
        login_data,
        { withCredentials: true }
      );

      console.log('Show me login token 1.0 =>', loginToken);
      setNoPasswordLogin(true);
    } catch (e) {
      setMessage(e.response.status)
    }
  }

  return (
    <>
      <div className='login-container'>
        {!noPasswordLogin &&
          <div className='login-main shadow'>
              <div className='text-center mb-4'>
                  <img src={logo} alt="EPI Logo" />
              </div>
              <h5>Welcome</h5>

              {!storedId && !activationId && <p className='pleaselog-text mb-4'>Please log in to continue.</p> }
              {activationId && <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>Please log in to activate account.</p>}
              {storedId && <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>Please log in to save quote.</p>}

              <form onSubmit={handleLogin}>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  placeholder="Email Address"
                  style={!error.email? {'margin-bottom': '1rem'} : {'margin-bottom': '0px'}}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {error.email && 
                  <span 
                    style={{
                      'color': '#dc3545',
                      'margin-bottom': '10rem'
                    }}
                  >
                    {error.email}
                  </span>
                }

                <div className='inputDiv'>
                  <input
                    type={seePassword? 'text': 'password'}
                    className="form-control"
                    name="password"
                    placeholder="Password"
                    style={error.email? {'margin-top': '1rem'} : {'margin-top': '0px'}}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <i
                    className={`bi bi-eye-slash inputIcon`}
                    onClick={() => setSeePassword(!seePassword)}
                  >
                    {seePassword?
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                      </svg>
                      : 
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                      </svg>
                    }
                  </i>
                  {error.password && <span style={{ 'color': '#dc3545' }}>{error.password}</span>}
                </div>

                { !error.email && !error.password && error.loginError && <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>{error.loginError}</p>}
                {error.notActivated && <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>{error.notActivated}</p>}
                
                <a href="#" onClick={() => history.push('/forgotpassword')}>
                  <p className='forgot-text'>Forgot your password?</p>
                </a>
                <Button
                  className='btn'
                  style={{
                    'width' : '100%',
                    'margin-bottom' : '3px',
                    'background': 'linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%)'
                  }}
                  disabled={disabled}
                >
                  {loading? 'Loading...' : 'Log In'}
                </Button>

                <Button
                  className='btn btn-outline-primary_login'
                  style={{
                    'width' : '100%',
                    'margin' : '3px 1px'
                  }}
                  disabled={disabled}
                  onClick={handleLoginNoPassword}
                >
                  Log in without password
                </Button>
              </form>
              <a href="#" onClick={() => history.push('/signup')}>
                <p className="account-text">Don’t have an account? Sign Up</p>
              </a>
          </div>
        }

        {noPasswordLogin &&
          <div className='login-main shadow'>
            <div className='text-center mb-4'>
              <img src={logo} alt="EPI Logo" />
            </div>
            <h5>Log In Without Password</h5>
            <div>
              <p className='pleaselog-text mb-4' style={{ 'color': '#16C172' }}>A login link has been sent to your email. For security reasons, it will expire in 15 minutes.</p>
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default Login;
