import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react'
import { useParams } from "react-router";
import 'assets/css/style.css'
import SidebarAllproducts from './SidebarAllproducts/SidebarAllproducts';
import meterimg from '../../assets/img/images/meter-img.png'
import { Col, Row, NavLink, Button } from 'reactstrap';
import { Helmet } from "react-helmet";


function AllProducts() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();
    var { productType } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [meterOptions, setMeterOptions] = useState('all')
    const [secondOptions, setSecondOptions] = useState(false);
    const [thirdOptions, setThirdOptions] = useState(false);

    useEffect(() => {
        if(productType && productType === 'haz'){
            setThirdOptions('hazardous');
        }

        if(productType && productType === 'gen'){
            setThirdOptions('general');
        }

        if(productType && productType === 'val'){
            setThirdOptions('val');
        }

        if(productType && productType === 'pure'){
            setThirdOptions('pure');
        }

        if(productType && productType === 'xt'){
            setThirdOptions('xt');
        }
    }, []);

    const clearFilter = () => {
        history.push('/allproducts');
    }


    const meters = [
        {
            name: 'SERIES 8600-8700 MP',
            nameType: 'HAZ',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8600-8700-MP-Inline.png?alt=media&token=f6f34c3c-0aaf-45e6-a84c-54efcf46e38c',
            link: '/products/haz/inline/haz',
            options: 'inline',
            options_2: 'integral'
        },
        {
            name: 'SERIES 8800 MP',
            nameType: 'HAZ',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8800-MP-Insertion.png?alt=media&token=dc99f036-ef27-430a-941d-13c38dc1a713',
            link: '/products/haz/insertion/haz',
            options: 'insertion',
            options_2: 'integral'
        },
        {
            name: 'SERIES 8000–8100 MP',
            nameType: 'HAZ R',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8000-8100-MP-Inline-Remote-1.png?alt=media&token=757f08e1-7fe2-4a41-8dea-2319b4ea5b72',
            link: '/products/haz/inline/haz-r',
            options: 'inline',
            options_2: 'remote'
        },
        {
            name: 'SERIES 8200 MP',
            nameType: 'HAZ R',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8200-MP-Insertion-Remote.png?alt=media&token=b6b61fff-905e-4c74-8133-933247384502',
            link: '/products/haz/insertion/haz-r',
            options: 'insertion',
            options_2: 'remote'
        },
        {
            name: 'SERIES 9700 MP',
            nameType: 'HAZ FAT',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9700-MP-Inline-FAT.png?alt=media&token=03785a4c-4da7-48eb-9f86-80389bf69cca',
            link: '/products/haz/inline/haz-fat',
            options: 'inline',
            options_2: 'integral'
        },
        {
            name: 'SERIES 9800 MP',
            nameType: 'HAZ FAT',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9800-MP-Insertion-FAT.png?alt=media&token=27c195bf-2c91-41a0-89a1-24860f57eb9a',
            link: '/products/haz/insertion/haz-fat',
            options: 'insertion',
            options_2: 'integral'
        },
        {
            name: 'SERIES 9100 MP',
            nameType: 'HAZ FAT R',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9100-MP-Inline-Remote-FAT.png?alt=media&token=9a42a5d7-75d8-4d61-8dae-23ebc8e26146',
            link: '/products/haz/inline/haz-fat-r',
            options: 'inline',
            options_2: 'remote'
        },
        {
            name: 'SERIES 9200 MP',
            nameType: 'HAZ FAT R',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9200-MP-Insertion-Remote-FAT.png?alt=media&token=452e41f0-8ec5-4f84-8af0-1b806efbe873',
            link: '/products/haz/insertion/haz-fat-r',
            options: 'insertion',
            options_2: 'remote'
        },
        {
            name: 'SERIES 8600-8700 MPNH',
            nameType: 'GEN',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8600-8700-MPNH-Inline.png?alt=media&token=f26fdb4a-d1c0-40c0-a9cf-5a55125d4678',
            link: '/products/gen/inline/gen',
            options: 'inline',
            options_2: 'integral'
        },
        {
            name: 'SERIES 8800 MPNH',
            nameType: 'GEN',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8800-MPNH-Insertion.png?alt=media&token=06fdd9df-bd8b-4e29-9370-2b31c34a4f9b',
            link: '/products/gen/insertion/gen',
            options: 'insertion',
            options_2: 'integral'
        },
        {
            name: 'SERIES 8000-8100 MPNH',
            nameType: 'GEN R',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8000-8100-MPNH-Inline-Remote-1.png?alt=media&token=12391992-8ca5-4676-91d2-93f79aafd333',
            link: '/products/gen/inline/gen-r',
            options: 'inline',
            options_2: 'remote'
        },
        {
            name: 'SERIES 8200 MPNH',
            nameType: 'GEN R',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8200-MPNH-Insertion-Remote.png?alt=media&token=fff4e0f8-e9eb-4d31-9273-9f57cb3468ea',
            link: '/products/gen/insertion/gen-r',
            options: 'insertion',
            options_2: 'remote'
        },
        {
            name: 'SERIES 9700 MPNH',
            nameType: 'GEN FAT',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9700-MPNH-Inline-FAT.png?alt=media&token=3b6c9bf1-e340-4c85-939a-58940b96c68e',
            link: '/products/gen/inline/gen-fat',
            options: 'inline',
            options_2: 'integral'
        },
        {
            name: 'SERIES 9800 MPNH',
            nameType: 'GEN FAT',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9800-MPNH-Insertion-FAT.png?alt=media&token=24d206c1-28f0-43d7-8ab6-4b02cad839a7',
            link: '/products/gen/insertion/gen-fat',
            options: 'insertion',
            options_2: 'integral'
        },
        {
            name: 'SERIES 9100 MPNH',
            nameType: 'GEN FAT R',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9100-MPNH-Inline-Remote-FAT.png?alt=media&token=f34f7592-8ebb-4aa7-97e9-c0886833f844',
            link: '/products/gen/inline/gen-fat-r',
            options: 'inline',
            options_2: 'remote'
        },
        {
            name: 'SERIES 9200 MPNH',
            nameType: 'GEN FAT R',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9200-MPNH-Insertion-Remote-FAT.png?alt=media&token=dea45a00-2238-4bc9-85ac-f2cf5613c2a6',
            link: '/products/gen/insertion/gen-fat-r',
            options: 'insertion',
            options_2: 'remote'
        },
        {
            name: 'SERIES VM 500',
            nameType: 'VALGEN-LM',
            type: 'val',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FValuMass-500-Inline.png?alt=media&token=e3b95f33-ff88-473d-bbc8-ec5bd6d951e0',
            link: '/products/val/inline/val-lm',
            options: 'inline',
            options_2: 'integral'
        },
        // {
        //     name: 'SERIES VM 540',
        //     nameType: 'VALGEN-LMR',
        //     type: 'val',
        //     image: '',
        //     link: '/products/val/inline/val-lmr',
        //     options: 'insertion',
        //     options_2: 'remote'
        // },
        {
            name: 'SERIES VM 400',
            nameType: 'VALGEN-RB',
            type: 'val',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FValuMass-400-Inline.png?alt=media&token=8871295a-3050-4255-b638-62f22e4f3469',
            link: '/products/val/inline/val-rb',
            options: 'inline',
            options_2: 'integral'
        },
        {
            name: 'SERIES VM 440',
            nameType: 'VALGEN-RB',
            type: 'val',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FValuMass-440-Insertion.png?alt=media&token=1e742f5c-3f2e-4497-96f4-2154c833a5f4',
            link: '/products/val/insertion/val-rb',
            options: 'insertion',
            options_2: 'integral'
        },
        // {
        //     name: 'SERIES VM 440',
        //     nameType: 'VALGEN-RBR',
        //     type: 'val',
        //     image: '',
        //     link: '/products/val/inline/val-rbr',
        //     options: 'insertion',
        //     options_2: 'remote'
        // },
        {
            name: 'SERIES VM 540',
            nameType: 'VALGEN-LM',
            type: 'val',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FValuMass-540-Insertion.png?alt=media&token=d79139d4-2e3c-4891-8c98-9361f6d666a8',
            link: '/products/val/insertion/val-lm',
            options: 'insertion',
            options_2: 'integral'
        },
        // {
        //     name: 'SERIES VM 540',
        //     nameType: 'VALGEN-LMR',
        //     type: 'val',
        //     image: '',
        //     link: '/products/val/insertion/val-lmr',
        //     options: 'insertion',
        //     options_2: 'remote'
        // },
        // {
        //     name: 'SERIES VM 440',
        //     nameType: 'VALGEN-RBR',
        //     type: 'val',
        //     image: '',
        //     link: '/products/val/insertion/val-rbr',
        //     options: 'insertion',
        //     options_2: 'remote'
        // },
        {
            name: 'SERIES 800 HPX',
            nameType: 'PUREGEN-LM',
            type: 'pure',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-800-HPX-Inline.png?alt=media&token=7dbd1080-5f36-494c-a8bf-be25828886b9',
            link: '/products/pure/inline/pure-lm',
            options: 'inline',
            options_2: 'integral'
        },
        // {
        //     name: 'SERIES 800 HPX',
        //     nameType: 'PUREGEN-LMR',
        //     type: 'pure',
        //     image: '',
        //     link: '/products/pure/inline/pure-lmr',
        //     options: 'inline',
        //     options_2: 'remote'
        // },
        {
            name: 'SERIES 800 HPN',
            nameType: 'PUREGEN-RB',
            type: 'pure',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-800-HPN-Inline.png?alt=media&token=b9000367-1efc-483d-a94a-33924d9f6749',
            link: '/products/pure/inline/pure-rb',
            options: 'inline',
            options_2: 'integral'
        },
        // {
        //     name: 'SERIES 800 HPM',
        //     nameType: 'PUREGEN-RBR',
        //     type: 'pure',
        //     image: '',
        //     link: '/products/pure/inline/pure-rbr',
        //     options: 'inline',
        //     options_2: 'remote'
        // },
        {
            name: 'SERIES 8200 MPHT',
            nameType: '525CR',
            type: 'xt',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8200-MPHT-Insertion-Remote.png?alt=media&token=f4c16e41-9f8b-4975-8b5a-d36c9b4b4465',
            link: '/products/xt/insertion/525cr',
            options: 'insertion',
            options_2: 'remote'
        },
        // {
        //     name: 'SERIES 8200 MPHT',
        //     nameType: '300CR',
        //     type: 'xt',
        //     image: '',
        //     link: '/products/xt/inline/300cr',
        //     options: 'inline',
        //     options_2: ''
        // },
        // {
        //     name: 'SERIES 8200 MPHT',
        //     nameType: '300CR',
        //     type: 'xt',
        //     image: '',
        //     link: '/products/xt/insertion/525cr',
        //     options: 'insertion',
        //     options_2: 'remote'
        // },
        {
            name: 'SERIES 8800 MPHT',
            nameType: '200C',
            type: 'xt',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8800-MP-Insertion.png?alt=media&token=dc99f036-ef27-430a-941d-13c38dc1a713',
            link: '/products/xt/insertion/200c',
            options: 'insertion',
            options_2: 'integral'
        },
        // {
        //     name: 'SERIES 8200 MPHT',
        //     nameType: '200CR',
        //     type: 'xt',
        //     image: '',
        //     link: '/products/xt/insertion/200cr',
        //     options: 'insertion',
        //     options_2: 'remote'
        // },
        // {
        //     name: 'SERIES 9000 MP',
        //     nameType: 'MULTIGEN',
        //     type: 'multigen',
        //     image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9000-MP-Multipoint.png?alt=media&token=3433ecd1-956c-4028-aaaf-b0dc7dacaca4',
        //     link: '/products/multigen/insertion/200cr',
        //     options: 'insertion',
        //     options_2: 'remote'
        // },
        {
            name: 'SERIES 7000-7100',
            nameType: 'SWITCHGEN',
            type: 'switch',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-7000-Inline.png?alt=media&token=49801d08-51d1-4d02-ab1a-ae5d61b4ea54',
            link: '/products/switch/inline/gen',
            options: 'inline',
            options_2: 'integral'
        },
        {
            name: 'SERIES 7800',
            nameType: 'SWITCHGEN',
            type: 'switch',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-7200-Insertion.png?alt=media&token=dfcc21f8-cdb2-4cc1-af72-1ebba9577f09',
            link: '/products/switch/insertion/gen',
            options: 'insertion',
            options_2: 'integral'
        }
    ];

    return (

        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – All Products</title>
            <meta name="description" content="Check out all EPI’s products: thermal mass flow meters and switches of the highest quality to measure almost any gas." />
            <link rel="canonical" href="https://epiflow.com/allproducts" />
        </Helmet>
            {/* section-1 */}
            <div className='ap-section-1'>
                <div className='image-container'>
                    <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/" tag={Link}>Home</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/allproducts" tag={Link}>All Products</NavLink></span>
                    </div>
                    <div>
                        <h2 className='cover-title-h2'>All Products</h2>
                        <p className='cover-title-p'>
                            EPI has flow meters to accurately measure almost any gas. Find the right meter for your application below!
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='ap-section-2'>
                <div className="body-main ap-body-main">
                    <div className='bm-child-1'>
                        {/* <Sidebar></Sidebar> */}
                        <SidebarAllproducts
                            meterOptions={meterOptions}
                            setMeterOptions={setMeterOptions}
                            secondOptions={secondOptions}
                            setSecondOptions={setSecondOptions}
                            setThirdOptions={setThirdOptions}
                            productType={productType}
                        />
                    </div>
                    <div className='bm-child-2 ap-bm-child-2'>
                        <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                            <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                        </div>
                        {isOpen ? (
                            <div className='mobile-sidebar'>
                                <SidebarAllproducts
                                    meterOptions={meterOptions}
                                    setMeterOptions={setMeterOptions}
                                    secondOptions={secondOptions}
                                    setSecondOptions={setSecondOptions}
                                    setThirdOptions={setThirdOptions}
                                    productType={productType}
                                />
                            </div>
                        ) : undefined}

                        <div className='common-main'>
                            <div className='row ap-custom-row'>
                              <div className='selectedFilters'>
                                <div className="left">
                                  <span>Selected Filters</span>
                                  <h4>
                                      {meterOptions}
                                      { secondOptions && ' - ' }
                                      { secondOptions == ''? (' - All') : (secondOptions)}
                                  </h4>
                                </div>
                                <div className="right">
                                  <button className="btn btn-secondary" onClick={() => clearFilter()}>Clear Filters</button>
                                </div>
                              </div>
                                { meters.map( value => {
                                    if(meterOptions != 'all'){
                                        if (value.options !== meterOptions){
                                            return null;
                                        }
                                    }

                                    // console.log('Show me all products 1.0 =>', value);

                                    if (secondOptions && value.options_2 !== secondOptions){
                                        return null;
                                    }

                                    if (thirdOptions && thirdOptions && value.type !== thirdOptions){
                                        return null;
                                    }

                                    return(
                                        <div className='col-sm-4 col-md-3 text-center'>
                                            <img src={value.image? (value.image) : (meterimg)} alt="Flow Meter Product Image" />
                                            <p className='meter-name'>
                                                {value.name}<br />
                                                <span style={{ 'font-size': '14px', 'color': '#4B5976' }}>{value.nameType}</span>
                                            </p>
                                            <NavLink tag={Link} to={value.link}>
                                                <Button className="button1 loginbtn" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                                                    View Meter
                                                </Button>
                                            </NavLink>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section-2 */}

            {/* section-3 */}
            <div className='ap-section-3 d-flex justify-content-center pt-5 pb-5'>
                <div>
                    <h3 className='commontitleOne text-center'>Can't find the meter or information you <br /> are looking for?</h3>
                    <p className='commonParaOne text-center'>We're here to help!</p>
                    <Row className='text-center'>
                        <Col md="6" sm="12" className='d-flex justify-content-md-end justify-content-sm-center'>
                            <NavLink to="/support" tag={Link}>
                                <Button className="button1 loginbtn" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                                    View Support
                                </Button>
                            </NavLink>
                        </Col>
                        <Col md="6" sm="12" className='d-flex justify-content-md-start justify-content-sm-center'>
                            <NavLink to="/contactus" tag={Link}>
                                <Button className="button2 loginbtn" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                                    Contact Us
                                </Button>
                            </NavLink>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* section-3 */}
        </>
    )
}

export default AllProducts
