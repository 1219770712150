import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardText, CardTitle, Col, Row, NavLink } from 'reactstrap'
import downloadIcon from '../../assets/img/images/download-icon.png'
import videoIcon from '../../assets/img/images/video-icon-img.png'
import icon1 from '../../assets/img/support_icons/tech-support.png'
import icon2 from '../../assets/img/support_icons/service-request.png'
import icon3 from '../../assets/img/support_icons/find-a-representative.png'
import icon4 from '../../assets/img/support_icons/request-quote.png'
import icon5 from '../../assets/img/support_icons/faq.png'
import icon6 from '../../assets/img/support_icons/shipping-and-returns.png'
import icon8 from '../../assets/img/support_icons/warranty.png'
import icon9 from '../../assets/img/support_icons/product-registration.png'
import icon10 from '../../assets/img/support_icons/privacy-inquiry.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faAt } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from "react-helmet";

function Support() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Get Support</title>
            <meta name="description" content="Get the help you need, right when you need it! Use the resources in this link to find helpful information, or send us a specific request. " />
            <link rel="canonical" href="https://epiflow.com/support/" />
        </Helmet>
            {/* section-1 */}
            <div className='sprt-section-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                    <span className='me-2'><NavLink className='breadcrumbsLink' to="/" tag={Link}>Home</NavLink></span>
                    <FontAwesomeIcon icon={faChevronRight} className='icon' />
                    <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/support" tag={Link}>Support</NavLink></span>
                    <span className='me-2 newLinkOverlay'>
                      <NavLink className='contactLink' to="/contactus" tag={Link}>
                        <FontAwesomeIcon icon={faAt} className='icon' />&nbsp;
                        Contact Us
                      </NavLink>
                    </span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Support</h2>
                        <p className='cover-title-p'>
                            Get the help you need, right when you need it! Use the resources below to find helpful information or send us a specific request.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='bg-white sprt-section-2'>
                <div className='container'>
                    <h3 className='commontitleOne pt-5 mb-4 text-center'>Resources</h3>
                    <Row>
                        <Col sm="6">
                            <Card className='card-shadow-none'>
                                <CardBody className='text-center p-4'>
                                    <CardTitle tag="h5">
                                        <img src={downloadIcon} className='common-icon-img' alt="Download Icon" />
                                    </CardTitle>
                                    <CardTitle tag="h5" className='sprt-card-title mt-2'>
                                        Downloads
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2'>
                                        View all downloads for EPI meters, including manuals, CAD files, wiring diagrams, and technical specifications.
                                    </CardText>
                                    <NavLink to="/support/downloads" tag={Link}>
                                        <Button className="button2" style={{ 'font-size': '1.0em'}}>
                                            View Downloads
                                        </Button>
                                    </NavLink>
                                </CardBody>
                                <div class="d-flex horizontal-line" style={{ height: '200px' }}>
                                    <div class="vr"></div>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card className='card-shadow-none'>
                                <CardBody className='text-center p-4'>
                                    <CardTitle tag="h5">
                                        <img src={videoIcon} className='common-icon-img' alt="How-To Videos Icon" />
                                    </CardTitle>
                                    <CardTitle tag="h5" className='sprt-card-title mt-2'>
                                        How-To Videos
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2'>
                                        Find videos to help with your flow meter setup, interface and wiring instructions, software setup and packaging for shipment.
                                    </CardText>
                                    <NavLink to="/support/howtovideos" tag={Link}>
                                        <Button className="button2" style={{ 'font-size': '1.0em'}}>
                                            View How-To Videos
                                        </Button>
                                    </NavLink>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* section-2 */}

            {/* section-3 */}
            <div className='background-colorOne sprt-section-3'>
                <div className='container'>
                    <h3 className='commontitleOne pt-5 mb-4 text-center'>Resources</h3>
                    <Row>
                        <Col lg="3" md="6" sm="3">
                            <Card>
                                <CardBody className='text-center p-4'>
                                    <CardTitle tag="h5">
                                        <img src={icon1} className='common-icon-img' alt="Tech Support Icon" />
                                    </CardTitle>
                                    <CardTitle tag="h5" className='sprt-card-title mt-2'>
                                        Tech Support
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2 sprt-card-p-height'>
                                        Request help for any of your technical support needs, from software to wiring.
                                    </CardText>
                                    <div className='pt-4 pb-2'>
                                        <NavLink to="/support/techsupport" tag={Link}>
                                            <Button className="button2" style={{ 'font-size': '1.0em'}}>
                                                Tech Support
                                            </Button>
                                        </NavLink>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="3">
                            <Card>
                                <CardBody className='text-center p-4'>
                                    <CardTitle tag="h5">
                                        <img src={icon2} className='common-icon-img' alt="Service Request Icon" />
                                    </CardTitle>
                                    <CardTitle tag="h5" className='sprt-card-title mt-2'>
                                        Service Request
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2 sprt-card-p-height'>
                                        Open a service request ticket, whether you are requesting an RMA, reporting a bug, or requesting additional specific support.
                                    </CardText>
                                    <div className='pt-4 pb-2'>
                                        <NavLink to="/support/servicerequest" tag={Link}>
                                            <Button className="button2" style={{ 'font-size': '1.0em'}}>
                                                Service Request
                                            </Button>
                                        </NavLink>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="3">
                            <Card>
                                <CardBody className='text-center p-4'>
                                    <CardTitle tag="h5">
                                        <img src={icon3} className='common-icon-img' alt="Find a Rep Icon" />
                                    </CardTitle>
                                    <CardTitle tag="h5" className='sprt-card-title mt-2'>
                                        Find a Representative
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2 sprt-card-p-height'>
                                        Are you looking for an EPI representative to order from? Use our location based tool!
                                    </CardText>
                                    <div className='pt-4 pb-2'>
                                        <NavLink to="/findrep" tag={Link}>
                                            <Button className="button2" style={{ 'font-size': '1.0em'}}>
                                                Find a Rep
                                            </Button>
                                        </NavLink>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="3">
                            <Card>
                                <CardBody className='text-center p-4'>
                                    <CardTitle tag="h5">
                                        <img src={icon4} className='common-icon-img' alt="Request a Quote Icon" />
                                    </CardTitle>
                                    <CardTitle tag="h5" className='sprt-card-title mt-2'>
                                        Request Quote
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2 sprt-card-p-height'>
                                        Request a quote for a custom meter. A sales representative will contact you to finalize your order.
                                    </CardText>
                                    <div className='pt-4 pb-2'>
                                        <NavLink to="/support/getquote" tag={Link}>
                                            <Button className="button2" style={{ 'font-size': '1.0em'}}>
                                                Get a Quote
                                            </Button>
                                        </NavLink>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* section-3 */}

            {/* section-4 */}
            <div className='background-colorOne pb-5 sprt-section-4'>
                <div className='container'>
                    <h3 className='commontitleOne pt-5 mb-4 text-center'>Resources</h3>
                    <Row>
                        <Col lg="4" md="6" sm="3">
                            <Card>
                                <CardBody className='text-center p-4'>
                                    <CardTitle tag="h5">
                                        <img src={icon5} className='common-icon-img' alt="FAQ's Section Icon" />
                                    </CardTitle>
                                    <CardTitle tag="h5" className='sprt-card-title mt-2'>
                                        FAQ’s
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2 sprt-card-p-height'>
                                        View a list of all of the most commonly asked questions about the industry, our company, and everything in between.
                                    </CardText>
                                    <div className='pt-4 pb-2'>
                                        <NavLink to="/support/faq" tag={Link}>
                                            <Button className="button2" style={{ 'font-size': '1.0em'}}>
                                                View FAQ
                                            </Button>
                                        </NavLink>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" md="6" sm="3">
                            <Card>
                                <CardBody className='text-center p-4'>
                                    <CardTitle tag="h5">
                                        <img src={icon6} className='common-icon-img' alt="Shipping & Returns Icon" />
                                    </CardTitle>
                                    <CardTitle tag="h5" className='sprt-card-title mt-2'>
                                        Shipping & Returns
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2 sprt-card-p-height'>
                                        See our shipping policy, options, rates and holidays, as well as our shipping policy.
                                    </CardText>
                                    <div className='pt-4 pb-2'>
                                        <NavLink to="/support/shipping" tag={Link}>
                                            <Button className="button2" style={{ 'font-size': '1.0em'}}>
                                                Shipping & Returns
                                            </Button>
                                        </NavLink>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" md="6" sm="3">
                            <Card>
                                <CardBody className='text-center p-4'>
                                    <CardTitle tag="h5">
                                        <img src={icon8} className='common-icon-img' alt="Warranty Icon" />
                                    </CardTitle>
                                    <CardTitle tag="h5" className='sprt-card-title mt-2'>
                                        Warranty
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2 sprt-card-p-height'>
                                        Learn about the warranty that comes with every one of our flow meters.
                                    </CardText>
                                    <div className='pt-4 pb-2'>
                                        <NavLink to="/support/warranty" tag={Link}>
                                            <Button className="button2" style={{ 'font-size': '1.0em'}}>
                                                Warranty
                                            </Button>
                                        </NavLink>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col lg="4" md="6" sm="3">
                            <Card>
                                <CardBody className='text-center p-4'>
                                    <CardTitle tag="h5">
                                        <img src={icon9} className='common-icon-img' alt="" />
                                    </CardTitle>
                                    <CardTitle tag="h5" className='sprt-card-title mt-2'>
                                        Product Registration
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2 sprt-card-p-height'>
                                        Register your meter with EPI to extend your product warranty and gain easier access to product support.
                                    </CardText>
                                    <div className='pt-4 pb-2'>
                                        <NavLink to="/support/productregistration" tag={Link}>
                                            <Button className="button2" style={{ 'font-size': '1.0em'}}>
                                                Product Registration
                                            </Button>
                                        </NavLink>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col> */}
                        <Col lg="4" md="6" sm="3">
                            <Card>
                                <CardBody className='text-center p-4'>
                                    <CardTitle tag="h5">
                                        <img src={icon10} className='common-icon-img' alt="Privacy Inquiry Icon" />
                                    </CardTitle>
                                    <CardTitle tag="h5" className='sprt-card-title mt-2'>
                                        Privacy Inquiry
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2 sprt-card-p-height'>
                                        View our privacy policy, terms of service, and all other EPI legal documents.
                                    </CardText>
                                    <div className='pt-4 pb-2'>
                                        <NavLink to="/support/privacyinquiry" tag={Link}>
                                            <Button className="button2" style={{ 'font-size': '1.0em'}}>
                                                Privacy Inquiry
                                            </Button>
                                        </NavLink>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* section-4 */}
        </>
    )
}

export default Support
