import React, {useState, useEffect} from 'react';
import useSwr from 'swr';

import './HeaderDiv.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import fetcher from 'utils/fetcher';


function HeaderDiv({ data }) {
    const [ userData, setUserData] = useState([]);
    const { data:_data } = useSwr("/api/me", fetcher);
    
    useEffect(() => {
        {_data && setUserData(_data)}
    });

    const Subpage = () => {
        return(
            <>
                <FontAwesomeIcon icon={faChevronRight} className='icon' />
                <span>{ data.subpage }</span>
            </>
        );
    }

    return (
        <div>
            <div className='headerdiv-main'>
                <div className='container pt-2 pb-2'>
                    <span>HOME</span>
                    <FontAwesomeIcon icon={faChevronRight} className='icon' />
                    <span>PORTAL</span>
                    <FontAwesomeIcon icon={faChevronRight} className='icon' />
                    <span>{ data.page }</span>
                    {data.subpage && <Subpage />}
                    <div className='m-5'></div>
                    <h1>
                        Welcome back, { userData.firstname }
                        {userData.account_type === 'admin' && <> (Admin)</>}
                    </h1>
                </div>
            </div>
        </div>
    );
};

export default HeaderDiv;