import React, { useState, useEffect } from 'react'
import axios from "axios";
import storage from 'utils/firebase';
import { customAlphabet } from "nanoid";
import { useForm } from "react-hook-form"
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DragDropFile from './DragDropFile'
import { Col, Container, Row, Form, Button } from 'reactstrap'

function BugTracking() {
    const { register, handleSubmit } = useForm();
    const [ files, setFiles ] = useState({ files: [] });
    const [ uploading, setUploading ] = useState(false);
    const [ uploadedFiles, setUploadedFiles ] = useState({ files: [] });
    const { REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT: api_url } = process.env;
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const onSubmit = async (data, e) => {
        setUploading(true);
        const uploaded_ = await uploadFiles()

        if(!uploading){
            let bug_tracking_form_data = {
                firstname: data.firstname,
                lastname: data.lastname,
                email: data.email,
                company: data.company,
                epi_software: data.epiSoftware,
                operating_system: data.operatingSystem,
                communication_protocol: data.communication,
                signal_output: data.signalOutput,
                connection_type: data.connection,
                company_description: data.companyDescription,
                attachments: uploadedFiles
            }

            console.log('Show me bug form data 1.0 =>', bug_tracking_form_data, api_url, uploaded_);

            try {
                await axios.post(
                    `${api_url}/api/forms/bugtracking`,
                    bug_tracking_form_data,
                    { withCredentials: false }
                )
            } catch (e) {
                console.log('Show me bug form data error 1.0 =>', e.message);
            }
            e.target.reset();
        }
    };

    const uploadFiles = async () =>{
        setUploading(true);
        const nanoid = customAlphabet("0123456789", 10);
        const fileNanoid = nanoid();

        const files_0 = files;
        const files_ = files_0.files;
        // console.log('Show me bug tracking Uploaded Files 2.2 =>', files_.length, files_);

        const uploaded = [];
        const uploadedObj = {};
        for (let fs = 0; fs < files_.length; fs++) {
            try{
                const filename_ = files_[fs].name;
                const filename  = `${fileNanoid}${filename_}`;
                const ref = storage.ref(`/files/${filename}`);
                const uploadTask = ref.put(files_[fs]);

                uploadTask.on("state_changed", console.log, console.error, () => {
                    ref.getDownloadURL().then((url) => {
                        uploaded.push({
                            filename: filename,
                            url: url
                        })
                        uploadedObj[fs] = {
                            filename: filename,
                            url: url
                        }
                    });
                });
            } catch (e) {
                console.log('Show me bug tracking info 1.1 =>', e);
            }
            setUploadedFiles(uploadedObj);
            // setUploadedFiles(uploaded);
            // console.log('Show me bug tracking Uploaded Files 2.0 =>', uploadedFiles, fs, files_.length - 1);

            if(fs == files_.length - 1){
                setUploadedFiles(uploadedObj);
                setUploading(false);
                return(uploadedObj);
                // console.log('Show me bug tracking Uploaded Files 2.1 =>', uploadedFiles);
            }
        }
    }
    
    return (
        <div>
            {/* section-1 */}
            <div className='bt-section-1'>
                <div className='image-container'>
                    <div className='about-title container pt-2 pb-2'>
                        <span className='me-2'>SUPPORT</span>
                        <FontAwesomeIcon icon={faChevronRight} className='icon' />
                        <span className='ml-2'>Problem Report</span>
                    </div>
                    <div>
                        <h2 className='cover-title-h2'>Problem Report</h2>
                        <p className='cover-title-p'>Help us improve your flow meter experience.</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <div className='pb-5 bt-s2'>
                <Container>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <h3 className='commontitleOne pt-5 mb-4 text-center'>Submit Your Request Below</h3>
                        <h4 className='rsm-form-title'>Contact Information</h4>
                        <Row>
                            <Col lg="3" md="6" sm="12">
                                <div class="form-group">
                                    <label for="">FIRST NAME <span className='text-danger'>*</span></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id=""
                                        placeholder="First Name"
                                        {...register("firstname", { required: true })}
                                    />
                                </div>
                            </Col>
                            <Col lg="3" md="6" sm="12">
                                <div class="form-group">
                                    <label for="">LAST NAME <span className='text-danger'>*</span></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id=""
                                        placeholder="Last Name"
                                        {...register("lastname", { required: true })}
                                    />
                                </div>
                            </Col>
                            <Col lg="3" md="6" sm="12">
                                <div class="form-group">
                                    <label for="">EMAIL <span className='text-danger'>*</span></label>
                                    <input
                                        type="email"
                                        class="form-control"
                                        id=""
                                        placeholder="Email"
                                        {...register("email", { required: true })}
                                    />
                                </div>
                            </Col>
                            <Col lg="3" md="6" sm="12">
                                <div class="form-group">
                                    <label for="">COMPANY <span className='text-danger'>*</span></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id=""
                                        placeholder="Company"
                                        {...register("company", { required: true })}
                                    />
                                </div>
                            </Col>

                        </Row>
                        <h4 className='rsm-form-title'>Bug Information</h4>
                        <Row>
                            <Col md="6" sm="12">
                                <div class="form-group">
                                    <label for="">EPI PROPRIETY SOFTWARE</label>
                                    <select
                                        className="form-select rsm-select"
                                        aria-label="Default select example"
                                        {...register("epiSoftware", { required: false })}
                                    >
                                        <option selected value="">Select Software</option>
                                        <option value="1">One</option>
                                        <option value="2">two</option>
                                    </select>
                                </div>
                            </Col>
                            <Col md="6" sm="12">
                                <div class="form-group">
                                    <label for="">OPERATING SYSTEM</label>
                                    <select
                                        className="form-select rsm-select"
                                        aria-label="Default select example"
                                        {...register("operatingSystem", { required: false })}
                                    >
                                        <option selected value="">Select Operating System</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col md="4" sm="12">
                                <div class="form-group">
                                    <label for="">COMMUNICATION PROTOCOL</label>
                                    <select
                                        className="form-select rsm-select"
                                        aria-label="Default select example"
                                        {...register("communication", { required: false })}
                                    >
                                        <option selected value="">Select Communication Protocol</option>
                                        <option value="1">One</option>
                                        <option value="2">two</option>
                                    </select>
                                </div>
                            </Col>
                            <Col md="4" sm="12">
                                <div class="form-group">
                                    <label for="">SIGNAL OUTPUT</label>
                                    <select
                                        className="form-select rsm-select"
                                        aria-label="Default select example"
                                        {...register("signalOutput", { required: false })}
                                    >
                                        <option selected value="">Select Signal Output</option>
                                        <option value="1">One</option>
                                        <option value="2">two</option>
                                    </select>
                                </div>
                            </Col>
                            <Col md="4" sm="12">
                                <div class="form-group">
                                    <label for="">CONNECTION TYPE</label>
                                    <select
                                        className="form-select rsm-select"
                                        aria-label="Default select example"
                                        {...register("connection", { required: false })}
                                    >
                                        <option selected value="">Select Connection Type</option>
                                        <option value="1">One</option>
                                        <option value="2">two</option>
                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col md="6" sm="12">
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1">COMPANY DESCRIPTION <span className='text-danger'>*</span></label>
                                    <textarea
                                        class="form-control bt-text-area"
                                        id="exampleFormControlTextarea1"
                                        rows="9"
                                        placeholder='Select Communication Protocol'
                                        {...register("companyDescription", { required: true })}
                                    ></textarea>
                                </div>
                            </Col>
                            <Col md="6" sm="12">
                            <div class="form-group">
                                <label for="exampleFormControlTextarea1">SCREENSHOTS, VIDEOS & ATTACHMENTS</label>
                                <div>
                                    <DragDropFile
                                        files={files}
                                        setFiles={setFiles}
                                        uploadedFiles={uploadedFiles}
                                        setUploadedFiles={setUploadedFiles}
                                        setUploading={setUploading}
                                    />
                                </div>
                            </div>
                            </Col>
                        </Row>
                        <Button type="submit" className='button1'>
                            Submit a Bug
                        </Button>
                    </Form>
                </Container>
            </div>
            {/* section-2 */}
        </div>
    )
}

export default BugTracking
