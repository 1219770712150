import React from "react";

// reactstrap components
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip
} from "reactstrap";

import BacnetBottom from "./BacnetBottom";

function Bacnet({ handleChange, state, checkState }){
  return (
    <>
      <Row>
        <Col className="inputPowerCommunicationCols">
          <span>
            <span className="customizerInputTitleSmallX1">OUTPUT OPTIONS</span>
            <Button className="questionToolTip" id="MAFLOWOUTPUT" size="sm">
                ?
            </Button>{` `}
            <UncontrolledTooltip placement="top" target="MAFLOWOUTPUT" delay={0}>
              A 4-20mA current loop is a means to pass a “value” like flow using an established electrical current (the loop) 
              as the carrier of this information. Generally, the 4mA represents the minimum value (like 0 for flow); and the 20mA 
              represents the maximum value, or full scale, of the value you are sending.  
              EPI flow meters use this output to represent the flow rate in the process line. 
              We offer Self Powered or Isolated-Loop Powered 4-20mA flow signal; please specify your desired configuration. 
              If you choose the Isolated-Loop Powered option, this does not provide meter power, 
              and will require separate input power for the meter electronics.
            </UncontrolledTooltip>
          </span>
          <FormGroup check className="form-check-radio">
            <Label check>
              <span className="customizerInputTitleSmallX1">FLOW OUTPUT</span>
              <Input
                defaultChecked={true}
                id="bacnet_options"
                name="bacnet_options"
                type="radio"
              ></Input>
              <span className="form-check-sign"></span>
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">

          </FormGroup>
        </Col>

        {checkState(state.input_power_communication.bacnet_output_options, 'flow') && (
          <Col className="inputPowerCommunicationCols">
            <span>
              <span className="customizerInputTitleSmallX1">4-20MA FLOW OUTPUT</span>
              <Button className="questionToolTip" id="MAFLOWOUTPUT" size="sm">
                  ?
              </Button>{` `}
              <UncontrolledTooltip placement="top" target="MAFLOWOUTPUT" delay={0}>
                A 4-20mA current loop is a means to pass a “value” like flow using an established electrical current (the loop) 
                as the carrier of this information. Generally, the 4mA represents the minimum value (like 0 for flow); and the 20mA 
                represents the maximum value, or full scale, of the value you are sending.  
                EPI flow meters use this output to represent the flow rate in the process line. 
                We offer Self Powered or Isolated-Loop Powered 4-20mA flow signal; please specify your desired configuration. 
                If you choose the Isolated-Loop Powered option, this does not provide meter power, 
                and will require separate input power for the meter electronics.
              </UncontrolledTooltip>
            </span>
            <FormGroup check className="form-check-radio">
              <Label check>
                <span className="customizerInputTitleSmallX1">Self Powered</span>
                <Input
                  defaultChecked={
                    checkState(
                      state.input_power_communication.bacnet_ma_flow,
                      'self'
                    )
                  }
                  onClick={ (e) => handleChange({
                    section: 'input_power_communication',
                    type: 'bacnet_ma_flow',
                    values: ['self', 'isolated'],
                    price_effect: false
                  },e)
                }
                  id="bacnet_ma_flow"
                  name="bacnet_ma_flow"
                  type="radio"
                ></Input>
                <span className="form-check-sign"></span>
              </Label>
            </FormGroup>
            <FormGroup check className="form-check-radio">
              <Label check>
                <span className="customizerInputTitleSmallX1">Isolated-loop Powered</span>
                <Input
                  defaultChecked={
                    checkState(
                      state.input_power_communication.bacnet_ma_flow,
                      'isolated'
                    )
                  }
                  onClick={ (e) => handleChange({
                    section: 'input_power_communication',
                    type: 'bacnet_ma_flow',
                    values: ['isolated', 'self'],
                    price_effect: false
                  },e)}
                  id="bacnet_ma_flow"
                  name="bacnet_ma_flow"
                  type="radio"
                ></Input>
                <span className="form-check-sign"></span>
              </Label>
            </FormGroup>
          </Col>
        )}

        {checkState(state.input_power_communication.bacnet_output_options, 'flow') && (<Col className="inputPowerCommunicationCols"></Col>)}
        {checkState(state.input_power_communication.bacnet_output_options, 'flow') && (<Col className="inputPowerCommunicationCols"></Col>)}
      </Row>
      <hr />
      <BacnetBottom
        state={state}
        checkState={checkState}
      />
    </>
  );
}

export default Bacnet;
