import React, { useState } from 'react';
import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function ChangeConfigNameModal({modal, toggle, quoteData}) {
  const [configName, setConfigName] = useState(false);

  console.log('show me quote id 1.0 =>', quoteData);

  const updateConfigName = async () => {
    const meterCustomizedData = {
      ...quoteData
    }

    console.log('show me quote id 1.1 =>', meterCustomizedData);

    if(configName){
      meterCustomizedData.config_name = configName;
    }

    try {
      const meter_config = await axios.put(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/quotes/${quoteData._id}`,
        meterCustomizedData,
        { withCredentials: true }
      );

      console.log('Show me savedSingleConfig 1.1 =>', meter_config);
    } catch (e) {
      console.log('Show me db insert error', e.message);
    }
  }
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}><b class="text-center">Name Configuration</b></ModalHeader>
        <ModalBody>
          <p>Please enter a name for your saved configuration; being descriptive will help you find it later if you would like to edit the configuration or add it into your cart.</p>
          <input
            type="text"
            class="epiInputSize form-control"
            defaultValue={quoteData.config_name? quoteData.config_name : ''}
            onChange={(e) => setConfigName(e.target.value)}
          >
          </input>
        </ModalBody>
        <ModalFooter>
          <div class="row w-100 m-0">
            <div class="d-flex justify-content-end p-0">
              <Button className="btn btn-outline mr-2" color="secondary" onClick={toggle}>
                Cancel
              </Button>
                <Button
                  disabled={!configName}
                  className="btn btn-gradient"
                  color="primary"
                  onClick={updateConfigName}
                >
                  Save
                </Button>{' '}
              </div>
            </div>
          </ModalFooter>
      </Modal>
    </div>
  );
}