import React,{ useState, useEffect } from 'react';
import useSwr from 'swr';
import { faArrowRight, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import fetcher from 'utils/fetcher';
// import './DeliveryTime.css'

import { UncontrolledTooltip } from "reactstrap";

function Blank() {
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const page = { deliveryTime: true }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    var { data } = useSwr(`${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/me`, fetcher);
    useEffect(() => {
        if(data){
            setUserData(data);
        }
    }, [data]);

    const headerData = {
        firstname: userData.firstname,
        page: 'USER INFORMATION'
    }

    return (
        <div className='deliverytime'>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar> </Sidebar></div>) : undefined}
                    <span class="backBtn">
                      <a href="" class="">Back to All Users</a>
                    </span>
                    <div className='common-main'>
                      <div className='shadow'>
                        <div className='quit-details-main'>
                            <div className='row quit-details-main-row'>
                              <div className='col-md-4'>
                                  <h2 className='p-3 mt-2 quite-details-text'>User Information</h2>
                              </div>
                              <div className='col-md-8'>
                                  {/* new */}
                                  <div className='row text-right quote-nav-options'>
                                      <ul className='m-0'>
                                        <li className='quoteMenuButton'>
                                          <a>
                                              <FontAwesomeIcon icon={faEdit} className='quoute-2d-element-icon'></FontAwesomeIcon>
                                              Edit
                                          </a>
                                        </li>
                                        <li className='quoteMenuButton'>
                                          <a>
                                              <FontAwesomeIcon icon={faTrash} className='quoute-2d-element-icon'></FontAwesomeIcon>
                                              Delete
                                          </a>
                                        </li>
                                      </ul>
                                  </div>
                                  {/* new */}
                              </div>
                            </div>
                          </div>
                        <div className='bdr body p-3'>
                          <form>
                            <div class="form-group row">
                              <h4>Manage User</h4>
                              <div class="col-4">
                                <div class="form-check form-check-inline">
                                  <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" /> Distributor User
                                    <span class="form-check-sign">
                                        <span class="check"></span>
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div class="col-4">
                                <div class="form-check form-check-inline">
                                  <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" /> Admin User
                                    <span class="form-check-sign">
                                        <span class="check"></span>
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div class="col-4">
                                <div class="form-check form-check-inline">
                                  <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" /> Banned Account
                                    <span class="form-check-sign">
                                        <span class="check"></span>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row pt-2 pb-2">
                              <div class="col-4">
                                <label for="" class="">User Password</label><br />
                                <a class="btn btn-outline" href="">Send Reset Email</a>
                              </div>
                              <div class="col-4">
                                <label for="" class="">User Configurations</label><br />
                                <a class="btn btn-outline" href="">View All Configurations</a>
                              </div>
                            </div>
                            <hr class="qHr" />
                            <div class="form-group row">
                              <h4>User Information</h4>
                              <div class="col-sm-3">
                                <label for="name" class="">First Name</label>
                                <input type="text" readonly class="form-control-plaintext" id="fName" value="Jay" />
                              </div>
                              <div class="col-sm-3">
                                <label for="name" class="">Last Name</label>
                                <input type="text" readonly class="form-control-plaintext" id="lName" value="Allen" />
                              </div>
                              <div class="col-sm-6">
                                <label for="email" class="">Email</label>
                                <input type="text" readonly class="form-control-plaintext" id="email" value="jay@jayallen.com" />
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="form-group col-md-12">
                                <label for="country">Select Country</label>
                                <select id="inputState" class="form-control-plaintext">
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antartica">Antarctica</option>
                                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Bouvet Island">Bouvet Island</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">Central African Republic</option>
                                    <option value="Chad">Chad</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Congo">Congo, the Democratic Republic of the</option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                    <option value="Croatia">Croatia (Hrvatska)</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="East Timor">East Timor</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="France Metropolitan">France, Metropolitan</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Territories">French Southern Territories</option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                    <option value="Holy See">Holy See (Vatican City State)</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="India">India</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Iran">Iran (Islamic Republic of)</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                                    <option value="Korea">Korea, Republic of</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Lao">Lao People's Democratic Republic</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon" selected>Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macau">Macau</option>
                                    <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Micronesia">Micronesia, Federated States of</option>
                                    <option value="Moldova">Moldova, Republic of</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Namibia">Namibia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau">Palau</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Philippines">Philippines</option>
                                    <option value="Pitcairn">Pitcairn</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Reunion">Reunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russia">Russian Federation</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                    <option value="Saint LUCIA">Saint LUCIA</option>
                                    <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                                    <option value="Span">Spain</option>
                                    <option value="SriLanka">Sri Lanka</option>
                                    <option value="St. Helena">St. Helena</option>
                                    <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syria">Syrian Arab Republic</option>
                                    <option value="Taiwan">Taiwan, Province of China</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania">Tanzania, United Republic of</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="United States" selected>United States</option>
                                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                    <option value="Uruguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Vietnam">Viet Nam</option>
                                    <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                    <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                    <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                    <option value="Western Sahara">Western Sahara</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Serbia">Serbia</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                              </div>
                              <div class="form-group">
                                  <label for="inputAddress">Address</label>
                                  <input type="text" class="form-control-plaintext" id="inputAddress" placeholder="1234 Main St" value="Not Filled" />
                                </div>
                                <div class="form-group">
                                  <label for="inputAddress2">Address 2</label>
                                  <input type="text" class="form-control-plaintext" id="inputAddress2" placeholder="Apartment, Studio, or Floor" value="Not Filled" />
                                </div>
                                <div class="form-row">
                                  <div class="form-group col-md-4">
                                    <label for="inputCity">City</label>
                                    <input type="text" class="form-control" value="Not Filled" id="inputCity" />
                                  </div>
                                  <div class="form-group col-md-4">
                                    <label for="inputState">State</label>
                                    <select id="inputState" class="form-control-plaintext">
                                      <option selected>Select State</option>
                                        <option value="AL">Alabama</option>
                                      	<option value="AK">Alaska</option>
                                      	<option value="AZ">Arizona</option>
                                      	<option value="AR">Arkansas</option>
                                      	<option value="CA">California</option>
                                      	<option value="CO">Colorado</option>
                                      	<option value="CT">Connecticut</option>
                                      	<option value="DE">Delaware</option>
                                      	<option value="DC">District Of Columbia</option>
                                      	<option value="FL">Florida</option>
                                      	<option value="GA">Georgia</option>
                                      	<option value="HI">Hawaii</option>
                                      	<option value="ID">Idaho</option>
                                      	<option value="IL">Illinois</option>
                                      	<option value="IN">Indiana</option>
                                      	<option value="IA">Iowa</option>
                                      	<option value="KS">Kansas</option>
                                      	<option value="KY">Kentucky</option>
                                      	<option value="LA">Louisiana</option>
                                      	<option value="ME">Maine</option>
                                      	<option value="MD">Maryland</option>
                                      	<option value="MA">Massachusetts</option>
                                      	<option value="MI">Michigan</option>
                                      	<option value="MN">Minnesota</option>
                                      	<option value="MS">Mississippi</option>
                                      	<option value="MO">Missouri</option>
                                      	<option value="MT">Montana</option>
                                      	<option value="NE">Nebraska</option>
                                      	<option value="NV">Nevada</option>
                                      	<option value="NH">New Hampshire</option>
                                      	<option value="NJ">New Jersey</option>
                                      	<option value="NM">New Mexico</option>
                                      	<option value="NY">New York</option>
                                      	<option value="NC">North Carolina</option>
                                      	<option value="ND">North Dakota</option>
                                      	<option value="OH">Ohio</option>
                                      	<option value="OK">Oklahoma</option>
                                      	<option value="OR">Oregon</option>
                                      	<option value="PA">Pennsylvania</option>
                                      	<option value="RI">Rhode Island</option>
                                      	<option value="SC">South Carolina</option>
                                      	<option value="SD">South Dakota</option>
                                      	<option value="TN">Tennessee</option>
                                      	<option value="TX">Texas</option>
                                      	<option value="UT">Utah</option>
                                      	<option value="VT">Vermont</option>
                                      	<option value="VA">Virginia</option>
                                      	<option value="WA">Washington</option>
                                      	<option value="WV">West Virginia</option>
                                      	<option value="WI">Wisconsin</option>
                                      	<option value="WY">Wyoming</option>
                                        <option value="" disabled="disabled">&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;</option>
                                        <option value="AS">American Samoa</option>
                                        <option value="GU">Guam</option>
                                        <option value="MP">Northern Mariana Islands</option>
                                        <option value="PR">Puerto Rico</option>
                                        <option value="UM">United States Minor Outlying Islands</option>
                                        <option value="VI">Virgin Islands</option>
                                        <option value="" disabled="disabled">&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;</option>
                                        <option value="AA">Armed Forces Americas</option>
                                        <option value="AP">Armed Forces Pacific</option>
                                        <option value="AE">Armed Forces Others</option>
                                    </select>
                                  </div>
                                  <div class="form-group col-md-4">
                                    <label for="inputZip">Zip</label>
                                    <input type="text" class="form-control-plaintext" value="Not Filled" id="inputZip" />
                                  </div>
                                </div>
                            </div>
                            <hr class="qHr" />
                            <div class="form-group row">
                              <h4>Distributor - Company Information</h4>
                              <div class="col-sm-6">
                                <label for="dName" class="">Company Name</label>
                                <input type="text" readonly class="form-control-plaintext" id="dName" value="Jay Allen Technologies" />
                              </div>
                              <div class="col-sm-6">
                                <label for="dEmail" class="">Company Email</label>
                                <input type="text" readonly class="form-control-plaintext" id="dEmail" value="info@jayallen.com" />
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-sm-4">
                                <label for="dWebsite" class="">Website</label>
                                <input type="text" readonly class="form-control-plaintext" id="dWebsite" value="https://jayallen.com" />
                              </div>
                              <div class="col-sm-4">
                                <label for="dPhone" class="">Company Phone</label>
                                <input type="text" readonly class="form-control-plaintext" id="dPhone" value="Not Filled" />
                              </div>
                              <div class="col-sm-4">
                                <label for="dFax" class="">Company Fax</label>
                                <input type="text" readonly class="form-control-plaintext" id="dFax" value="Not Filled" />
                              </div>
                            </div>
                            <hr class="qHr" />
                            <div class="form-group row">
                              <h4>Marketing Preferences</h4>
                                <div class="col-sm-12">
                                  <div class="form-check form-check-inline disabled">
                                    <label class="form-check-label">
                                      <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="subscribed" disabled /> User is not subscribed to marketing emails.
                                      <span class="form-check-sign">
                                          <span class="check"></span>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                            </div>
                          </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
}

export default Blank
