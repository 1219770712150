import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useForm } from "react-hook-form"
import { Form, FormGroup, Button } from 'reactstrap'
import '../../assets/css/RegisterNewProduct.css';

function RegisterForm() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loginError, setLoginError] = useState(null);
  const { REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT: apiUrl } = process.env;
  
  const onSubmit = async (data, e) => {
    let meter_data = {
      serial_number: data.serialnumber,
      install_adress_one: data.addressone,
      install_adress_two: data.addresstwo,
      install_adress_three: data.addressthree
    }

    console.log('Register Meter data 1.0 =>', meter_data);

    try {
      await axios.post( `${apiUrl}/api/meters`, meter_data, { withCredentials: true } )
    } catch (e) {
      setLoginError(e.message);
    }
    e.target.reset();
  };

  return (
    <div>
      <div className='newproduct body p-3'>
          <p>Use the form below to register a new meter to your account.</p>
          <hr />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <label for="">METER SERIAL NUMBER <span className='text-danger'>*</span></label>
              <input 
                type="text" 
                className="form-control"
                name="serial_number" 
                placeholder="####-####-####-####"
                {...register("serialnumber", { required: true })}
              />
            </FormGroup>
            <FormGroup>
              <label for="">INSTALL LOCATION <span className='text-danger'>*</span></label>
              <input 
                type="text" 
                className="form-control"
                name="address_one" 
                placeholder="Install Address Line 1"
                {...register("addressone", { required: true })}
              />

            <input 
                type="text" 
                className="form-control new-input-field"
                name="address_one" 
                placeholder="Install Address Line 2"
                {...register("addresstwo", { required: true })}
              />

              <input 
                type="text" 
                className="form-control new-input-field"
                name="address_one" 
                placeholder="Install Address Line 3 (optional)"
                {...register("addressthree", { required: true })}
              />
            </FormGroup>
            <div>
              <label for="" class="form-label">AGREE TO TERMS <span className='text-danger'>*</span></label>
              <div className='check-main d-flex ml-4'>
                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                <label class="form-check-label" for="flexCheckChecked">
                  I have read and understand the terms laid out in the Product Registration Disclaimer.
                </label>
              </div>
            </div>
            <Button type="submit" className='inputButton'>
              Register Meter
            </Button>
            { loginError }
          </Form>
      </div>
    </div>
  )
}

export default RegisterForm
