import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TsFaqAccordation from './TsFaqAccordation/TsFaqAccordation'
import DdAccordation from './DdAccordation/DdAccordation'
import icon1 from '../../assets/img/images/section-5-1sticon.png'
import icon2 from '../../assets/img/images/section-5-2ndicon.png'
import icon3 from '../../assets/img/images/section-5-3rdicon.png'
import icon4 from '../../assets/img/images/section-5-4thicon.png'
import { Row, NavLink, Button } from 'reactstrap'

function TechSpecs({ data }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/* section 3 */}
            <section className='section-3 pb-5'>
                <div className='container'>
                    <h1 className='text-center pt-5 pb-3'>Features</h1>
                    {/* row 1 */}
                    <div className='row'>
                        <div className='col-md-6'>
                            <img className='img-div' src={ data.features.highlightOneImage } alt="Meter feature highlight #1"/>
                        </div>
                        <div className='col-md-6 d-flex align-items-center'>
                            <div>
                                <h2 className='s3-h2'>{ data.features.titleOne }</h2>
                                <p className='s3-p' dangerouslySetInnerHTML={ {__html: data.features.highlightOneInfo } } />
                            </div>
                        </div>
                    </div>
                    {/* row 1 */}
                    {/* row 2 start */}
                    <div className='row s3-row2'>
                        <div className='col-md-6 d-flex align-items-center'>
                            <div>
                                <h2 className='s3-h2'>{ data.features.titleTwo }</h2>
                                <p className='s3-p' dangerouslySetInnerHTML={ {__html: data.features.highlightTwoInfo } } />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <img className='img-div' src={ data.features.highlightTwoImage } alt="Meter feature highlight #2" />
                            {data.features.highlightTwoPdf &&
                                <p className='text-center'>
                                    <a target="_blank" href={data.features.highlightTwoPdf}>View Enlarged Image</a>
                                </p>
                            }
                        </div>
                    </div>
                    {/* row 2 end */}
                </div>
            </section>
            {/* section 3 */}
            {/* section-4 */}
            <div className='mm-section-6 ts-section-4'>
                <div className='container'>
                    <h3 className='commontitleOne pt-5 pb-4 text-center'>Benefits</h3>
                    <div className="row text-center">
                        { data.our_process.benefits.map((value) => {
                            return(
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <h4 className='mm-card2-title'>{ value.title }</h4>
                                    <p className='mm-card2-des'>{ value.info }</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            {/* section-4 */}
            {/* section-5 */}
            <div className='ts-section-5'>
                <div className='container'>
                    <h1 className='commontitleOne text-center pt-5 pb-3'>Specifications</h1>
                    <div className='row'>
                        { data.specifications.specs.map((value) => {
                            return(
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <p className='ts-s5-title capitalize'>{ value. title }</p>
                                    <p className='ts-s5-body'>{ value.info }</p>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>
            {/* section-5 */}
            {/* section-6 */}

            { data.communication_config.type === 'gen' &&
                <div className='background-colorOne pb-5'>
                    <div className='container'>
                        <h1 className='commontitleOne text-center pt-5 pb-3'>Communication Configurations</h1>
                        <p className='commonParaTwo ts-s6-des-p text-center'>The communication configuration options (outputs) available for this meter are shown below. These options are also available to select in the Meter Customizer.</p>
                        <div style={{ overflowX: 'scroll' }}>
                            <table class="table ts-s6-table">
                                <thead>
                                    <tr>
                                        <th width="40%" scope="col">Outputs</th>
                                        <th width="10%" scope="col">Standard (Modbus RTU)</th>
                                        <th width="10%" scope="col">Multi-Range & Temp Out</th>
                                        <th width="10%" scope="col">Multi-Range, No Temp Out</th>
                                        <th width="10%" vertical scope="col">HART</th>
                                        <th width="10%" scope="col">BACnet</th>
                                        <th width="10%" scope="col">Profibus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { data.communication_config.comm_config.map((value) => {
                                        return(
                                            <tr>
                                                <th scope="row">{ value.output }</th>
                                                <td><FontAwesomeIcon icon={ value.standard && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.temp_out && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.no_temp_out && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.hart && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.bacnet && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.profibus && faCheck} className='check-icon' /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

            { data.communication_config.type === 'haz' &&
                <div className='background-colorOne pb-5'>
                    <div className='container'>
                        <h1 className='commontitleOne text-center pt-5 pb-3'>Communication Configurations</h1>
                        <p className='commonParaTwo ts-s6-des-p text-center'>The communication configuration options (outputs) available for this meter are shown below. These options are also available to select in the Meter Customizer.</p>
                        <div style={{ overflowX: 'scroll' }}>
                            <table class="table ts-s6-table">
                                <thead>
                                    <tr>
                                        <th width="40%" scope="col">Outputs</th>
                                        <th width="10%" scope="col">Standard (Modbus RTU)</th>
                                        <th width="10%" scope="col">Multi-Range & Temp Out</th>
                                        <th width="10%" scope="col">Multi-Range, No Temp Out</th>
                                        <th width="10%" vertical scope="col">HART</th>
                                        <th width="10%" scope="col">BACnet</th>
                                        <th width="10%" scope="col">Profibus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { data.communication_config.comm_config.map((value) => {
                                        return(
                                            <tr>
                                                <th scope="row">{ value.output }</th>
                                                <td><FontAwesomeIcon icon={ value.standard && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.temp_out && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.no_temp_out && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.hart && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.bacnet && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.profibus && faCheck} className='check-icon' /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

            { data.communication_config.type === 'val500' &&
                <div className='background-colorOne pb-5'>
                    <div className='container'>
                        <h1 className='commontitleOne text-center pt-5 pb-3'>Communication Configurations</h1>
                        <p className='commonParaTwo ts-s6-des-p text-center'>The communication configuration options (outputs) available for this meter are shown below. These options are also available to select in the Meter Customizer.</p>
                        <div style={{ overflowX: 'scroll' }}>
                            <table class="table ts-s6-table">
                                <thead>
                                    <tr>
                                        <th width="40%" scope="col">Outputs</th>
                                        <th width="15%" scope="col">500 Standard (Modbus RTU)</th>
                                        <th width="15%" scope="col">500 w/Frequency</th>
                                        <th width="15%" scope="col">500 w/BACnet</th>
                                        <th width="15%" scope="col">500 w/BACnet + Frequency</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { data.communication_config.comm_config.map((value) => {
                                        return(
                                            <tr>
                                                <th scope="row">{ value.output }</th>
                                                <td><FontAwesomeIcon icon={ value.standard && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.frequency && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.bacnet && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.bacnet_frequency && faCheck} className='check-icon' /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

            { data.communication_config.type === 'val400' &&
                <div className='background-colorOne pb-5'>
                    <div className='container'>
                        <h1 className='commontitleOne text-center pt-5 pb-3'>Communication Configurations</h1>
                        <p className='commonParaTwo ts-s6-des-p text-center'>The communication configuration options (outputs) available for this meter are shown below. These options are also available to select in the Meter Customizer.</p>
                        <div style={{ overflowX: 'scroll' }}>
                            <table class="table ts-s6-table">
                                <thead>
                                    <tr>
                                        <th width="40%" scope="col">Outputs</th>
                                        <th width="15%" scope="col">400 Standard (Modbus RTU)</th>
                                        <th width="15%" scope="col">400 w/Frequency</th>
                                        <th width="15%" scope="col">400 w/BACnet</th>
                                        <th width="15%" scope="col">400 w/BACnet + Frequency</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { data.communication_config.comm_config.map((value) => {
                                        return(
                                            <tr>
                                                <th scope="row">{ value.output }</th>
                                                <td><FontAwesomeIcon icon={ value.standard && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.frequency && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.bacnet && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.bacnet_frequency && faCheck} className='check-icon' /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

            { data.communication_config.type === 'switch' &&
                <div className='background-colorOne pb-5'>
                    <div className='container'>
                        <h1 className='commontitleOne text-center pt-5 pb-3'>Communication Configurations</h1>
                        <p className='commonParaTwo ts-s6-des-p text-center'>The communication configuration options (outputs) available for this meter are shown below. These options are also available to select in the Meter Customizer.</p>
                        <div style={{ overflowX: 'scroll' }}>
                            <table class="table ts-s6-table">
                                <thead>
                                    <tr>
                                        <th width="40%" scope="col">Outputs</th>
                                        <th width="15%" scope="col">7000 Standard Flow Switch</th>
                                        <th width="15%" scope="col"></th>
                                        <th width="15%" scope="col"></th>
                                        <th width="15%" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { data.communication_config.comm_config.map((value) => {
                                        return(
                                            <tr>
                                                <th scope="row">{ value.output }</th>
                                                <td><FontAwesomeIcon icon={ value.standard && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={false} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={false} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={false} className='check-icon' /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

            { data.communication_config.type === 'pure' &&
                <div className='background-colorOne pb-5'>
                    <div className='container'>
                        <h1 className='commontitleOne text-center pt-5 pb-3'>Communication Configurations</h1>
                        <p className='commonParaTwo ts-s6-des-p text-center'>The communication configuration options (outputs) available for this meter are shown below. These options are also available to select in the Meter Customizer.</p>
                        <div style={{ overflowX: 'scroll' }}>
                            <table class="table ts-s6-table">
                                <thead>
                                    <tr>
                                        <th width="40%" scope="col">Outputs</th>
                                        <th width="15%" scope="col">800 Standard (Modbus RTU)</th>
                                        <th width="15%" scope="col">800 w/Frequency</th>
                                        <th width="15%" scope="col">800 w/BACnet</th>
                                        <th width="15%" scope="col">800 w/BACnet + Frequency</th>
                                        <th width="15%" scope="col">800 w/HART</th>
                                        <th width="15%" scope="col">800 w/Profibus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { data.communication_config.comm_config.map((value) => {
                                        return(
                                            <tr>
                                                <th scope="row">{ value.output }</th>
                                                <td><FontAwesomeIcon icon={ value.standard && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.frequency && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.bacnet && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.bacnet_frequency && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.hart && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.profibus && faCheck} className='check-icon' /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

            { data.communication_config.type === 'multigen' &&
                <div className='background-colorOne pb-5'>
                    <div className='container'>
                        <h1 className='commontitleOne text-center pt-5 pb-3'>Communication Configurations</h1>
                        <p className='commonParaTwo ts-s6-des-p text-center'>The communication configuration options (outputs) available for this meter are shown below. These options are also available to select in the Meter Customizer.</p>
                        <div style={{ overflowX: 'scroll' }}>
                            <table class="table ts-s6-table">
                                <thead>
                                    <tr>
                                        <th width="40%" scope="col">Outputs</th>
                                        <th width="15%" scope="col">9000 Standard (Modbus RTU)</th>
                                        <th width="15%" scope="col">HART</th>
                                        <th width="15%" scope="col">BACnet</th>
                                        <th width="15%" scope="col">Profibus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { data.communication_config.comm_config.map((value) => {
                                        return(
                                            <tr>
                                                <th scope="row">{ value.output }</th>
                                                <td><FontAwesomeIcon icon={ value.standard && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.hart && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.bacnet && faCheck} className='check-icon' /></td>
                                                <td><FontAwesomeIcon icon={ value.profibus && faCheck} className='check-icon' /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

            {/* section-6 */}
            {/* section-7 */}
            <div className='ts-section7 pb-5'>
                <div className='container'>
                    <h1 className='commontitleOne text-center pt-5 pb-3'>Geometry & Sizing</h1>
                    {/* <p className='commonParaTwo ts-s6-des-p text-center'>Description text</p> */}
                    <div className='text-center'>
                        {/* <div className='col-md-6'>
                            <div className='ts-s7-inner-row'>
                                {data.geometry_sizing.sizing.map((value) => {
                                    return(
                                        <div className='ts-s7-inner-col'>
                                            <p className='ts-s7-title'>{ value.title }</p>
                                            <p className='ts-s7-des'>{ value.demensions }</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div> */}
                        {/* <div className='col-md-6'> */}
                            { data.geometry_sizing.cadImg &&
                              <img src={data.geometry_sizing.cadImg} className='geometryImg' alt="Flow Meter geometry and sizing graphic #1" />
                            }
                            { data.geometry_sizing.cadImg2 &&
                              <img src={data.geometry_sizing.cadImg2} className='geometryImg' alt="Flow Meter geometry and sizing graphic #2" />
                            }
                            { data.geometry_sizing.cadImg3 &&
                              <img src={data.geometry_sizing.cadImg3} className='geometryImg' alt="Flow Meter geometry and sizing graphic #3" />
                            }
                            { data.geometry_sizing.cadImg4 &&
                              <img src={data.geometry_sizing.cadImg4} className='geometryImg' alt="Flow Meter geometry and sizing graphic #4" />
                            }
                        {/* </div> */}
                    </div>
                </div>
            </div>
            {/* section-7 */}
            {/* section-8 */}
            <div className='ts-section-8'>
                <div className='container'>
                    <h1 className='commontitleOne text-center pt-5 pb-3'>Tech Support</h1>
                    {/* <p className='commonParaTwo ts-s6-des-p text-center'>Description text</p> */}
                    <div className='row'>
                        <div className='col-md-12'>
                            <Row className='ts-tbn-row'>

                                    <NavLink className='inlineBtn' style={{ 'padding': '0px' }}>
                                        <span className='button1 ms-'>FAQ's</span>
                                    </NavLink>
                                    <NavLink className='inlineBtn' style={{ 'padding': '0px' }} from="*" tag={Link} to="/support">
                                        <span className='button2 ml-'>More Support</span>
                                    </NavLink>


                                {/* <Col lg="4" md="6" sm="12" className='mt-5'>
                                    <a href="/" className='button2 ml-3' style={{ paddingBottom: '15px' }}>Meter Setup</a>
                                </Col> */}
                            </Row>


                            <div className='mt-5 mb-5'>
                                <TsFaqAccordation data={data} />
                            </div>

                        </div>
                        {/*    <img src={meterimg} alt="" />
                        <div className='col-md-4'>
                            <MaintanenceAccordation data={data} />
                        </div> */}
                    </div>
                </div>
            </div>
            {/* section-8 */}
            {/* section-9 */}
            <div className='ts-section-9 pt-5 pb-5'>
                <div className='container'>
                    <div className='row ts-s9-row'>
                        <div className='col-md-6'>
                            <h1 className='commontitleOne'>Warranty</h1>
                            <br />
                            <p className='commonParaTwo ts-s9-p'>
                                Every EPI flow meter comes from the factory with an extendable one year warranty, free of charge. This warranty covers most repairs
                                and servicing for your new meter, assuming it is operating under the conditions that it was designed to. Please refer to our warranty page
                                to view the full terms and conditions.
                            </p>
                            <br />
                            <div className='row ts-s9-btn-row'>
                                <div className='col-md-6'>
                                    <NavLink style={{ 'padding': '0px' }} from="*" tag={Link} to="/support/warranty">
                                        <span className='button1'>View Warranty Terms</span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 d-flex align-items-center justify-content-center'>
                            <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Fepi-product-warranty.png?alt=media&token=00fc80c4-ca7f-484c-bf5a-980afec464a8" className='ts-s9-img' alt="1-4 Year Warrany" />
                        </div>
                    </div>
                </div>
            </div>
            {/* section-9 */}
            {/* section-10 */}
            {/* <div className='ts-section-9 pt-5 pb-5 background-colorOne'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 d-flex align-items-center justify-content-center'>
                            <img src={circlediv} className='ts-s9-img' alt="" />
                        </div>
                        <div className='col-md-6'>
                            <h1 className='commontitleOne'>Spare Parts</h1>
                            <p className='commonParaTwo ts-s9-p mt-4'>It is probably the dream of any amateur astronomer to be able to be the boss of
                                one of the great mulA million dollar telescopes even if it was just for one hour or
                                for a few shots. Sure, we can have a lot of fun with our binoculars.
                            </p>
                            <div className='row ts-s9-btn-row'>
                                <div className='col-md-5'><a href="/" className='button1'>Shop for Spare Parts</a></div>
                                <div className='col-md-7'><a href="/" className='button3' style={{ fontSize: '13px' }}>What comes with my new meter?</a></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> */}
            {/* section-9 */}
            {/* section-10 */}
            <div className='ts-section-9 pt-5 pb-5 background-colorOne'>
                <div className='container'>
                    <h1 className='commontitleOne text-center pt-5 pb-3'>Installation</h1>
                    {/* <p className='commonParaTwo ts-s6-des-p text-center'>Description text</p> */}
                    <div className='text-center'>
                        {/* <img src={installationv} className='ts-s10-img' alt="" /> */}
                        {/* <div>
                            <iframe
                                width="670"
                                height="372"
                                // style={{'max-width': '670px', 'max-height': '372px'}}
                                src={data.installation.video}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </div> */}
                        <img src={data.installation.image} className='ts-s10-img mt-5' alt="Thermal mass flow meter installation graphic" />
                    </div>
                    <div className='mt-5 mb-5 text-center'>
                          <Button className="button1" tag={Link} to="/technicalinfo/installation">
                              More Installation Help
                          </Button>
                    </div>
                    <div className='p-5'></div>
                </div>
            </div>
            {/* section-10 */}
            {/* section-11 */}
            <div className="ts-section11">
                <div className="container">
                    <h1 className='commontitleOne text-center pt-5 pb-3'>Documents & Downloads</h1>
                    {/* <p className='commonParaTwo ts-s6-des-p text-center'>DescripAon text</p> */}
                    <div className='mt-5 mb-5'>
                        <DdAccordation data={data} />
                    </div>
                </div>
                <div className='pt-5 pb-5 text-center'>
                    <Button className="button1" tag={Link} to="/support/downloads">
                        View All Downloads
                    </Button>
                </div>
            </div>
            {/* section-11 */}
            {/* section-12  From meterModals*/}
            <div className='mm-section-5 background-colorOne'>
                <div className='container'>
                    <h3 className='commontitleOne pt-5 pb-4 text-center'>Why Choose EPI?</h3>
                    <div className='row mm-row-2'>
                        <div className='col-md-6'>
                            <div className="row">
                                <div className='col-md-3 d-flex justify-content-center align-items-center'>
                                    <div className='shadow rounded-circle mm-img-main-div d-flex justify-content-center align-items-center'>
                                        <img src={icon1} className='mm-card2-img' alt="Custom Meters Icon" />
                                    </div>
                                </div>
                                <div className='col-md-9'>
                                    <h4 className='mm-card2-title'>Built Custom for You</h4>
                                    <p className='mm-card2-des'>EPI offers completely customizable products for thermal flow metering. Start customizing your own today!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="row">
                                <div className='col-md-3 d-flex justify-content-center align-items-center'>
                                    <div className='shadow rounded-circle mm-img-main-div d-flex justify-content-center align-items-center'>
                                        <img src={icon2} className='mm-card2-img' alt="Fast Turnaround Times Icon" />
                                    </div>
                                </div>
                                <div className='col-md-9'>
                                    <h4 className='mm-card2-title'>Fast Turnaround Times</h4>
                                    <p className='mm-card2-des'>The time between your order and our shipment is streamlined to ensure your new meter arrives quickly.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="row">
                                <div className='col-md-3 d-flex justify-content-center align-items-center'>
                                    <div className='shadow rounded-circle mm-img-main-div d-flex justify-content-center align-items-center'>
                                        <img src={icon3} className='mm-card2-img' alt="World Class Support Icon" />
                                    </div>
                                </div>
                                <div className='col-md-9'>
                                    <h4 className='mm-card2-title'>World Class Support</h4>
                                    <p className='mm-card2-des'>We have the best customer support in the industry. Whatever
                                        you need, we are here to help in every way possible.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="row">
                                <div className='col-md-3 d-flex justify-content-center align-items-center'>
                                    <div className='shadow rounded-circle mm-img-main-div d-flex justify-content-center align-items-center'>
                                        <img src={icon4} className='mm-card2-img' alt="Extended Warranty Icon" />
                                    </div>
                                </div>
                                <div className='col-md-9'>
                                    <h4 className='mm-card2-title'>Extended Warranty</h4>
                                    <p className='mm-card2-des'>EPI's warranty is one of the best in the industry. We want to
                                        make sure that our products are always out performing expectations.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section-12 */}
            {/* section-13 from MeterModals */}
            <div className='mm-section-6'>
                <div className='container'>
                    <h3 className='commontitleOne pt-5 pb-4 text-center'>Our Process</h3>
                    <div className="row text-center">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <h4 className='mm-card2-title'>Step 1</h4>
                            <p className='mm-card2-des'>Configure your new meter with our Meter
                                Customizer, and see how your new meter
                                will look.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <h4 className='mm-card2-title'>Step 2</h4>
                            <p className='mm-card2-des'>An EPI team member will perform a
                                checkup to make sure that the meter
                                setup will perfectly match your specific
                                application.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <h4 className='mm-card2-title'>Step 3</h4>
                            <p className='mm-card2-des'>Once your meter setup is ready, we
                                finalize shipping and payment details, and
                                then start building.
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <h4 className='mm-card2-title'>Step 4</h4>
                            <p className='mm-card2-des'>After your new meter has been
                                completed in the shop and tested for
                                quality, we will ship the meter and
                                provide you with a tracking number.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* section-13 */}
        </>
    )
}

export default TechSpecs
