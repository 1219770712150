import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';

// reactstrap components
import {
  UncontrolledCollapse,
  Button,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  CardHeader,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Collapse,
  UncontrolledTooltip
} from "reactstrap";

function ApplicationInformation({ handleChange, state, checkState }){
  const [selectInputOptions, setSelectInputOptions] = useState(0);
  const [selectInputOptionsTwo, setSelectInputOptionsTwo] = useState(0);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  // collapse states and functions
  const [collapses, setCollapses] = React.useState([0]);
  const changeCollapse = collapse => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter(prop => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  useEffect(() => {
    if(checkState(state.application_information.application_options_other, 'application')){
      setSelectInputOptions(1);
    }

    if(checkState(state.application_information.gas_other, 'gas')){
      setSelectInputOptionsTwo(1);
    }
  }, [state]);

  const MakeChangeDropdown = (data) => {
    data.option_value = data.option.target.value;
    const values = data.values;
    const first_value = values[0]

    if(first_value !== data.option_value){
      values.removeWithValue(data.option_value)
      values[0] = data.option_value;
      values.splice(1,0, first_value);
      values.join();
      data.values = values;
      console.log('Show me pipe option data 1.0 =>', data);
    } else {
      console.log('Show me pipe option data 1.0 =>', data);
    }

    if(data.input_name === 'application'){
      (data.option_value === 'other') ? setSelectInputOptions(1) : setSelectInputOptions(0);
    }

    if(data.input_name === 'gas'){
      (data.option_value === 'other') ? setSelectInputOptionsTwo(1) : setSelectInputOptionsTwo(0);
    }

    handleChange(data);
  }

  const MakeChangeText = (data) => {
    data.option_value = data.option.target.value;
    handleChange(data);
  }

  var title = 'Application Information';
  if (isMobile) {
    if(title.length > 18) title = title.substring(0,18) + '...';
  }

  return (
    <>
      <Card style={{
        'border-top-left-radius': 'calc(.45rem - 1px)',
        'border-top-right-radius': 'calc(.45rem - 1px)',
        'border-bottom-left-radius': 'calc(.45rem - 1px)',
        'border-bottom-right-radius': 'calc(.45rem - 1px)'
        }}>
        <CardHeader>
          <a
            aria-expanded={collapses.includes(2)}
            data-toggle="collapse"
            href="#pablo"
            onClick={e => {
              e.preventDefault();
              changeCollapse(2);
            }}
          >
            <Navbar className="bg-epi-blue" style={{
              'height': '10px', 'margin-bottom': '5px',
              'border-top-left-radius': 'calc(.45rem - 1px)',
              'border-top-right-radius': 'calc(.45rem - 1px)'
              }} expand="lg">
              <Container>
                <div className="navbar-translate">
                  <NavbarBrand href="#pablo" onClick={e => e.preventDefault()} style={{'font-size': '22px', 'color': 'white'}}>
                    <p>{ title }</p>
                  </NavbarBrand>
                  <button
                    className="navbar-toggler"
                    id="example-navbar-danger"
                    type="button"
                    aria-expanded={collapses.includes(2)}
                    onClick={e => {
                      e.preventDefault();
                      changeCollapse(2);
                    }}
                  >
                    <i className="now-ui-icons arrows-1_minimal-down" style={{'font-size': '22px', 'color': 'white'}}></i>
                  </button>
                </div>
                <UncontrolledCollapse navbar toggler="#example-navbar-danger">
                  <Nav className="ml-auto" navbar style={{'font-size': '22px', 'color': 'white'}}>
                    <i className="now-ui-icons arrows-1_minimal-down"></i>
                  </Nav>
                </UncontrolledCollapse>
              </Container>
            </Navbar>
          </a>
        </CardHeader>

        <Collapse isOpen={collapses.includes(2)}>
          <CardBody>
            <Row>
              <Col>
                <FormGroup>
                  <label htmlFor="exampleFormControlSelect1">
                    <span className="customizerInputTitle">Application</span>
                    <Button className="questionToolTip" id="ApplicationQuestion" size="sm">
                      ?
                    </Button>{` `}
                    <UncontrolledTooltip placement="right" target="ApplicationQuestion" delay={0}>
                      This refers to the application for which you will be using your meter,
                      including Industrial, Oil & Gas, Wastewater, and Biogas.
                    </UncontrolledTooltip>
                  </label>
                  <Input
                    className="epiInputSize form-select"
                    id="exampleFormControlSelect1"
                    type="select"
                    placeholder="Yes or No"
                    onChange={ (e) => MakeChangeDropdown({
                      section: 'application_information',
                      type: 'application_options',
                      values: [
                        'compressed_air',
                        'hvac',
                        'landfill_gas',
                        'natural_gas',
                        'waste_water',
                        'flue_gas',
                        'flare',
                        'other'
                      ],
                      price_effect: false,
                      input_name: 'application',
                      option: e
                    }, e)}
                  >
                    <option value="" selected disabled>Select Application</option>
                    <option selected={checkState(state.application_information.application_options, 'compressed_air')} value="compressed_air">Compressed Air Flow Measurement</option>
                    <option selected={checkState(state.application_information.application_options, 'hvac')} value="hvac">HVAC & Air Vent</option>
                    <option selected={checkState(state.application_information.application_options, 'landfill_gas')} value="landfill_gas">Landfill Gas & Municipal Flow</option>
                    <option selected={checkState(state.application_information.application_options, 'natural_gas')} value="natural_gas">Natural Gas Flow Measurement</option>
                    <option selected={checkState(state.application_information.application_options, 'waste_water')} value="waste_water">Wastewater & Aeration Basin</option>
                    <option selected={checkState(state.application_information.application_options, 'flue_gas')} value="flue_gas">Flue Gas</option>
                    <option selected={checkState(state.application_information.application_options, 'flare')} value="flare">Flare</option>
                    <option selected={checkState(state.application_information.application_options, 'other')} value="other">Other</option>
                  </Input>
                </FormGroup>

              { selectInputOptions ? (
                <FormGroup>
                  <Input
                    className="epiInputSize"
                    id="exampleFormControlSelect1"
                    type="text"
                    placeholder="Enter Application"
                    defaultValue={
                      checkState(state.application_information.application_options_other, 'application') &&
                      state.application_information.application_options_other.valuesObj.application
                    }
                    onChange={ (e) => MakeChangeText({
                      section: 'application_information',
                      type: 'application_options_other',
                      values: ['application'],
                      price_effect: false,
                      text_input: true,
                      option: e
                    }, e)}
                  >
                  </Input>
                </FormGroup>
              ) : (
                <div></div>
              )}
              </Col>

              <Col>
                <FormGroup>
                  <label htmlFor="exampleFormControlSelect1">
                    <span className="customizerInputTitle">Gas</span>
                    <Button className="questionToolTip" id="Gas" size="sm">
                      ?
                    </Button>{` `}
                    <UncontrolledTooltip placement="right" target="Gas" delay={0}>
                      Gas refers to the specific gas(es) that you will be metering.
                      Your new meter will be precisely calibrated to measure the provided selection.
                    </UncontrolledTooltip>
                  </label>
                  <Input
                    className="epiInputSize form-select"
                    id="exampleFormControlSelect1"
                    type="select"
                    onChange={ (e) => MakeChangeDropdown({
                      section: 'application_information',
                      type: 'gas',
                      values: [
                        'Air',
                        'Argon',
                        'Chlorine',
                        'Blast_Furnace_Gas',
                        'BioGas',
                        'Landfill_Gas',
                        'Ethylene',
                        'Ethane',
                        'Propane',
                        'Liquid_Propane_Gas',
                        'Butane_Gas',
                        'Benzene',
                        'Methane',
                        'Methane_Helium_Mix',
                        'Methane_Nitrogen_Mix',
                        'Carbon_Monoxide',
                        'Carbon_Dioxide',
                        'Hydrogen',
                        'Hydrogen_Nitrogen_Mix',
                        'Helium',
                        'Mix_Gas',
                        'Flare_gas',
                        'Flue_Gas',
                        'Nitrogen',
                        'Natural_Gas',
                        'Ammonia',
                        'Oxygen',
                        'O3',
                        'SNGPL_Gas',
                        'SSGCL_Gas',
                        'MARI_Gas',
                        'other'
                      ],
                      price_effect: true,
                      input_name: 'gas',
                      option: e
                    }, e)}
                  >
                    <option value="" disabled>Select a Gas</option>
                    <option selected={checkState(state.application_information.gas, 'Air')} value="Air" selected>Air</option>
                    <option selected={checkState(state.application_information.gas, 'Argon')} value="Argon">Argon</option>
                    <option selected={checkState(state.application_information.gas, 'Chlorine')} value="Chlorine">Chlorine</option>
                    <option selected={checkState(state.application_information.gas, 'Blast_Furnace_Gas')} value="Blast_Furnace_Gas">Blast Furnace Gas</option>
                    <option selected={checkState(state.application_information.gas, 'BioGas')} value="BioGas">BioGas</option>
                    <option selected={checkState(state.application_information.gas, 'Landfill_Gas')} value="Landfill_Gas">Landfill Gas</option>
                    <option selected={checkState(state.application_information.gas, 'Ethylene')} value="Ethylene">Ethylene</option>
                    <option selected={checkState(state.application_information.gas, 'Ethane')} value="Ethane">Ethane</option>
                    <option selected={checkState(state.application_information.gas, 'Propane')} value="Propane">Propane</option>
                    <option selected={checkState(state.application_information.gas, 'Liquid_Propane_Gas')} value="Liquid_Propane_Gas">Liquid Propane Gas</option>
                    <option selected={checkState(state.application_information.gas, 'Butane_Gas')} value="Butane_Gas">Butane Gas</option>
                    <option selected={checkState(state.application_information.gas, 'Benzene')} value="Benzene">Benzene</option>
                    <option selected={checkState(state.application_information.gas, 'Methane')} value="Methane">Methane</option>
                    <option selected={checkState(state.application_information.gas, 'Methane_Helium_Mix')} value="Methane_Helium_Mix">Methane Helium Mix</option>
                    <option selected={checkState(state.application_information.gas, 'Methane_Nitrogen_Mix')} value="Methane_Nitrogen_Mix">Methane Nitrogen Mix</option>
                    <option selected={checkState(state.application_information.gas, 'Carbon_Monoxide')} value="Carbon_Monoxide">Carbon Monoxide</option>
                    <option selected={checkState(state.application_information.gas, 'Carbon_Dioxide')} value="Carbon_Dioxide">Carbon Dioxide</option>
                    <option selected={checkState(state.application_information.gas, 'Hydrogen')} value="Hydrogen">Hydrogen</option>
                    <option selected={checkState(state.application_information.gas, 'Hydrogen_Nitrogen_Mix')} value="Hydrogen_Nitrogen_Mix">Hydrogen Nitrogen Mix</option>
                    <option selected={checkState(state.application_information.gas, 'Helium')} value="Helium">Helium</option>
                    <option selected={checkState(state.application_information.gas, 'Mix_Gas')} value="Mix_Gas">Mix Gas</option>
                    <option selected={checkState(state.application_information.gas, 'Flare_gas')} value="Flare_gas">Flare Gas</option>
                    <option selected={checkState(state.application_information.gas, 'Flue_Gas')} value="Flue_Gas">Flue Gas</option>
                    <option selected={checkState(state.application_information.gas, 'Nitrogen')} value="Nitrogen">Nitrogen</option>
                    <option selected={checkState(state.application_information.gas, 'Natural_Gas')} value="Natural_Gas">Natural Gas</option>
                    <option selected={checkState(state.application_information.gas, 'Ammonia')} value="Ammonia">Ammonia</option>
                    <option selected={checkState(state.application_information.gas, 'Oxygen')} value="Oxygen">Oxygen</option>
                    <option selected={checkState(state.application_information.gas, 'O3')} value="O3">O3</option>
                    <option selected={checkState(state.application_information.gas, 'SNGPL_Gas')} value="SNGPL_Gas">SNGPL Gas</option>
                    <option selected={checkState(state.application_information.gas, 'SSGCL_Gas')} value="SSGCL_Gas">SSGCL Gas</option>
                    <option selected={checkState(state.application_information.gas, 'MARI_Gas')} value="MARI_Gas">MARI Gas</option>
                    <option selected={checkState(state.application_information.gas, 'other')} value="other">Other</option>
                  </Input>
                </FormGroup>

                { selectInputOptionsTwo ? (
                  <FormGroup>
                    <Input
                      className="epiInputSize"
                      id="exampleFormControlSelect1"
                      type="text"
                      placeholder="Enter Gas"
                      defaultValue={
                        checkState(state.application_information.gas_other, 'gas') &&
                        state.application_information.gas_other.valuesObj.gas
                      }
                      onChange={ (e) => MakeChangeText({
                        section: 'application_information',
                        type: 'gas_other',
                        values: ['gas'],
                        price_effect: false,
                        text_input: true,
                        option: e
                      }, e)}
                    >
                    </Input>
                  </FormGroup>
                ) : (
                  <div></div>
                )}
              </Col>
            </Row>

            <hr />

            <Row>
              <Col>
                <FormGroup>
                  <label htmlFor="exampleFormControlSelect1">
                    <span className="customizerInputTitle">
                      Do you expect condensing moisture in the process line?
                    </span>
                    </label>
                  <Input
                    className="epiInputSize form-select"
                    id="exampleFormControlSelect1"
                    type="select"
                    placeholder="Yes or No"
                    onChange={ (e) => MakeChangeDropdown({
                      section: 'application_information',
                      type: 'condensing_moisture',
                      values: [
                        'yes',
                        'no'
                      ],
                      price_effect: false,
                      option: e
                    }, e)}
                  >
                    <option value="" selected disabled>Select Yes or No</option>
                    <option selected={checkState(state.application_information.condensing_moisture, 'yes')} value="yes">Yes</option>
                    <option selected={checkState(state.application_information.condensing_moisture, 'no')} value="no">No</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col></Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
}

export default ApplicationInformation;
