import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, Row, Button, NavLink, NavItem, Nav } from 'reactstrap'
import './style.css';
import { Helmet } from "react-helmet";

// import TechSupportFooter from 'components/Footers/TechSupportFooter'

function Sitemap() {
    const [showTechLink, setShowTechLink] = useState(false);
    const [showTechLink2, setShowTechLink2] = useState(false);
    const [showTechLink3, setShowTechLink3] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
          <Helmet>
              <meta charSet="utf-8" />
              <title>EPI – Sitemap</title>
              <meta name="description" content="Check out the complete list of links on the EPI website, which will be updated as new content is released." />
              <link rel="canonical" href="https://epiflow.com/sitemap" />
          </Helmet>
            {/* section-1 */}
            <div className='tsn-section-1'>
                <div className='image-container'>
                    <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/support" tag={Link}>Support</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/support/servicerequest" tag={Link}>Sitemap</NavLink></span>
                    </div>
                    <div>
                        <h2 className='cover-title-h2'>EPI Sitemap</h2>
                        <p className='cover-title-p'>
                            See a list of all links on our website so that you can easily find whatever resource you need.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <div className='pt-5 pb-5'>

            <Container>
                <Row>
                <Col>
                    <span className="footerTitle" style={{ 'color': '#4A4A6B'}}>
                        Products
                        <hr />
                    </span>
                    <Nav className="flex-column footerNavEpi">
                        <NavItem>
                            <NavLink
                            className="footerLink"
                            href="/allproducts">
                            All Meters
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className="footerLink" href="/support/getquote">
                            Custom Order
                            </NavLink>
                        </NavItem>
                        <br />
                        <NavItem>
                            <NavLink className="footerLink" style={{ 'color': '#B7BDC8', 'font-size': '14px' }} onClick={e => e.preventDefault()}>
                            Meter Models
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/allproducts/haz">
                            - EPI-HAZ
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/allproducts/gen">
                            - EPI-GEN
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/allproducts/val">
                            - EPI-VAL
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/allproducts/pure">
                            - EPI-PURE
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/allproducts/xt">
                            - EPI-XT
                            </NavLink>
                        </NavItem>
                        <br />
                        <NavItem>
                            <NavLink className="footerLink" style={{ 'color': '#B7BDC8', 'font-size': '14px' }} onClick={e => e.preventDefault()}>
                            Meters by Application
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/allproducts/haz">
                            - Hazardous Locations
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/allproducts/gen">
                            - General Locations
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/allproducts/val">
                            - High Value
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to="/allproducts/pure">
                            - High Purity
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/allproducts/xt">
                            - High Temperature
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>

                <Col>
                    <span className="footerTitle" style={{ 'color': '#4A4A6B'}}>
                        Information & Resources
                        <hr />
                    </span>

                    <Nav className="flex-column footerNavEpi">
                    <NavItem onClick={() => setShowTechLink(!showTechLink)}>
                        <NavLink className="footerLink" style={{ 'color': '#0d6efd'}}>
                            Technical Information
                            <i
                                className="fa fa-plus"
                                aria-hidden="true"
                                style={{ 'font-size': '20px', 'margin-left': '5px'}}
                                onClick={() => setShowTechLink(!showTechLink)}
                            >
                            </i>
                        </NavLink>
                    </NavItem>
                    {showTechLink && (
                        <div>
                            <NavItem>
                                <NavLink tag={Link} to="/technicalinfo" style={{'font-size':'14px'}}>
                                - Technical Information
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/technicalinfo/fullguideflowmeters" style={{'font-size':'14px'}}>
                                - A Full Guide to Flow Meters
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/technicalinfo/approvalscertificates" style={{'font-size':'14px'}}>
                                - Agency Approvals & Certificates
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/technicalinfo/installation" style={{'font-size':'14px'}}>
                                - Installation
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/technicalinfo/meterconsiderations" style={{'font-size':'14px'}}>
                                - Meter Considerations
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/technicalinfo/meterconfigurations" style={{'font-size':'14px'}}>
                                - Meter Configurations
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/technicalinfo/technologycomparison" style={{'font-size':'14px'}}>
                                - Technology Comparison
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/technicalinfo/operationaltheory" style={{'font-size':'14px'}}>
                                - Operational Theory
                                </NavLink>
                            </NavItem>
                        </div>
                    )}
                    {showTechLink && <hr />}

                    <NavItem onClick={() => setShowTechLink2(!showTechLink2)}>
                        <NavLink className="footerLink" style={{ 'color': '#0d6efd'}}>
                            Industrial Applications for Meters
                            <i
                                className="fa fa-plus"
                                aria-hidden="true"
                                style={{ 'font-size': '20px', 'margin-left': '5px'}}
                                onClick={() => setShowTechLink2(!showTechLink2)}
                            >
                            </i>
                        </NavLink>
                    </NavItem>
                    {showTechLink2 && (
                        <div>
                            <NavItem>
                                <NavLink tag={Link} to="/industrialapplications/" style={{'font-size':'14px'}}>
                                - Industrial Applications for Meters
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/industrialapplications/oxygen" style={{'font-size':'14px'}}>
                                - Oxygen
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/industrialapplications/methane" style={{'font-size':'14px'}}>
                                - Methane
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/industrialapplications/flue" style={{'font-size':'14px'}}>
                                - Flue Gas
                                </NavLink>
                            </NavItem>
                        </div>
                    )}
                    {showTechLink2 && <hr />}

                    <NavItem onClick={() => setShowTechLink3(!showTechLink3)}>
                        <NavLink className="footerLink" style={{ 'color': '#0d6efd'}}>
                            White Papers
                            <i
                                className="fa fa-plus"
                                aria-hidden="true"
                                style={{ 'font-size': '20px', 'margin-left': '5px'}}
                                onClick={() => setShowTechLink3(!showTechLink3)}
                            >
                            </i>
                        </NavLink>
                    </NavItem>
                    {showTechLink3 && (
                        <div>
                            <NavItem>
                                <NavLink tag={Link} to="/whitepapers/compressedair" style={{'font-size':'14px'}}>
                                - Compressed Air Flow Measurement Meters
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/whitepapers/hvac" style={{'font-size':'14px'}}>
                                - HVAC & Air Vent Meters
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/whitepapers/landfill" style={{'font-size':'14px'}}>
                                - Landfill Gas & Municipal Flow Meters
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/whitepapers/naturalgas" style={{'font-size':'14px'}}>
                                - Natural Gas Flow Measurement Meter
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink tag={Link} to="whitepapers/wastewater" style={{'font-size':'14px'}}>
                                - Wastewater & Aeration Basin Meters
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/whitepapers/fluegas" style={{'font-size':'14px'}}>
                                - Flue Gas Applications
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/whitepapers/flaregas" style={{'font-size':'14px'}}>
                                - Flare Gas Applications
                                </NavLink>
                            </NavItem>
                        </div>
                    )}

                    <NavItem>
                        <NavLink className="footerLink" href="/technicalinfo/gasesweflow">
                        Gases We Flow
                        </NavLink>
                    </NavItem>
                    </Nav>
                </Col>

                <Col>
                    <span className="footerTitle" style={{ 'color': '#4A4A6B'}}>
                        Support
                        <hr />
                    </span>

                    <Nav className="flex-column footerNavEpi">
                      <NavItem>
                          <NavLink className="footerLink" tag={Link} to="/support/">
                          Get Support
                          </NavLink>
                      </NavItem>

                    <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/support/techsupport">
                        Technical Support
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/support/servicerequest">
                        Service Request
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/findrep">
                        Find a Representative
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/support/getquote">
                        Request a Quote
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/support/downloads">
                        Downloads
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/support/howtovideos">
                        How-To Videos
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/support/faq">
                        FAQ’s
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/support/shipping">
                        Shipping & Returns
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/support/warranty">
                        Warranty
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/support/privacyinquiry">
                        Privacy Inquiry
                        </NavLink>
                    </NavItem>
                    </Nav>
                </Col>

                <Col>
                    <span className="footerTitle" style={{ 'color': '#4A4A6B'}}>
                        Company
                        <hr />
                    </span>

                    <Nav className="flex-column footerNavEpi">
                      <NavItem>
                          <NavLink className="footerLink" tag={Link} to="/sitemap">
                          Sitemap
                          </NavLink>
                      </NavItem>
                    <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/">
                        Home
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/aboutus">
                        About Us
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/contactus">
                        Contact Us
                        </NavLink>
                    </NavItem>

                    <span className="footerTitle" style={{ 'color': '#4A4A6B'}}>
                        Customer Portal
                        <hr />
                    </span>

                    <Nav className="flex-column footerNavEpi">
                        <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/portal">
                            Account
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/login">
                        Log In
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink className="footerLink" tag={Link} to="/signup">
                        Sign Up
                        </NavLink>
                        </NavItem>
                    </Nav>
                    </Nav>
                </Col>
                </Row>
            </Container>
            </div>
            {/* section-2 */}

            {/* section-3 */}
            <div className='ap-section-3 d-flex justify-content-center pt-5 pb-5'>
                <div>
                    <h3 className='commontitleOne text-center'>Can't find the meter or information you <br /> are looking for?</h3>
                    <p className='commonParaOne text-center'>We're here to help!</p>
                    <Row className='text-center'>
                        <Col md="6" sm="12" className='d-flex justify-content-md-end justify-content-sm-center'>
                            <NavLink to="/support" tag={Link}>
                                <Button className="button1 loginbtn" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                                    View Support
                                </Button>
                            </NavLink>
                        </Col>
                        <Col md="6" sm="12" className='d-flex justify-content-md-start justify-content-sm-center'>
                            <NavLink to="/contactus" tag={Link}>
                                <Button className="button2 loginbtn" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                                    Contact Us
                                </Button>
                            </NavLink>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* section-3 */}
        </div>
    )
}

export default Sitemap
