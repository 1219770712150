import React from "react";

// product views
import HazProductView from "components/ProductViews/Haz/HazInsertion/HazProductView";
import HazRProductView from "components/ProductViews/Haz/HazInsertion/HazRProductView";
import HazFatProductView from "components/ProductViews/Haz/HazInsertion/HazFatProductView";
import HazFatRProductView from "components/ProductViews/Haz/HazInsertion/HazFatRProductView";

function HazInsertion({product, productType, tab}) {
  switch (product) {
    case 'haz':
      return (
        <>
          <HazProductView productType={productType} tab={tab} />
        </>
      );
      break;

      case 'haz-r':
        return (
          <>
            <HazRProductView productType={productType} tab={tab} />
          </>
        );
        break;

      case 'haz-fat':
        return (
          <>
            <HazFatProductView productType={productType} tab={tab} />
          </>
        );
        break;

      case 'haz-fat-r':
        return (
          <>
            <HazFatRProductView productType={productType} tab={tab} />
          </>
        );
        break;
  
    default:
      return (
        <>
            <HazProductView productType={productType} tab={tab} />
        </>
      );
      break;
  }
}

export default HazInsertion;
