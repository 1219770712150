import React, { useState } from 'react';
import { Col, Container, Row, Collapse, Card, CardBody } from 'reactstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPhoneNumberIntl, parsePhoneNumber } from 'react-phone-number-input';
import RepData from "./RepData";
import './style.css';

function FRsearch(){
    const [searchQ, setSearchQ] = useState('');
    const [viewRep, setViewRep] = useState(false);
    const approvedReps = RepData();

    console.log("Show me distributor data 1.0 =>", approvedReps);
    return (
        <div>
            <div className='bg-white'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo mb-4 text-center'>Find Your Nearest Representative</h4>
                    <p className='commonParaThree text-center'>
                        Enter your address below or use your current location to find your nearest EPI Representative.
                    </p>
                    <div className='p-5'></div>
                    <h4 className='commontitleOne mb-4 text-center'>Find By Location</h4>
                    <Row>
                        <Col>
                            <div className='form-group'>
                                <label for="">ENTER COUNTRY, STATE or CITY </label>
                                <div className='position-relative'>
                                    <input
                                        type="text"
                                        className="form-control FPfromControl"
                                        id=""
                                        placeholder="Enter Your Country, State or City"
                                        // onChange={(event) => this.search(event.target.value)}
                                        onChange={(e) => setSearchQ(e.target.value)}
                                    />
                                    <div className='FRsearchMain'>
                                        <FontAwesomeIcon icon={faSearch} className='FRsearchIcon' />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section-3 */}
            <div className='background-colorOne FPSection3'>
                <Row className='g-3'>
                    <Col className='scroll'>
                        {
                            approvedReps.map((value, index) => {
                                const value_ = `${value.address}, ${value.city}, ${value.region}, ${value.country}`;
                                const returnCountry = value_.toLowerCase().includes(searchQ.toLowerCase());
                                const count = [0];

                                if(!returnCountry){
                                    count[0] = 1;
                                    return
                                    var value = {
                                        companyname: 'Eldridge Products Inc',
                                        region: 'Marina',
                                        firstname: '',
                                        lastname: '',
                                        country: 'USA',
                                        address: '465 Reservation Rd, Marina, CA 93933',
                                        city: 'Marina',
                                        state: 'California',
                                        zip: '37343',
                                        phone: '1-831-648-7777',
                                        mobile: '',
                                        tollfree: '1-831-648-7777',
                                        fax: '1-831-648-7777',
                                        email: 'sales@epiflow.com',
                                        website: 'https://epiflow.com/',
                                        logolink: ''
                                    }
                                }
                                console.log('Show me noneVal 1.0 =>', count[0]);

                                return(
                                    <div className='FPSection3-container mt-4'>
                                        <div className='p-3 rounded shadow bg-white FPSection3-Card'>
                                            <div className="row mb-4">
                                                <div className="col-md-6">
                                                    <h5>{value.companyname}</h5>
                                                    {/* <p className='FPSection3P'>REGION: SOUTHERN CA</p> */}
                                                    <p className='FPSection3P' style={{ 'text-transform': 'uppercase' }}>REGION: { value.region }</p>
                                                </div>
                                                <div className="col-md-6 d-flex justify-content-end">
                                                    {viewRep.index === index? (
                                                        <button
                                                            className='button2 m-0'
                                                            onClick={
                                                                () => setViewRep({
                                                                    index: 'index',
                                                                    status: true
                                                                })
                                                            }
                                                        >
                                                            Contact Representative
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className='button2 m-0'
                                                            onClick={
                                                                () => setViewRep({
                                                                    index: index,
                                                                    status: true
                                                                })
                                                            }
                                                        >
                                                            Contact Representative
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            <p className='commonParaThree color-blue font-weight-bold'>
                                                <span className='seprator pe-3'>{formatPhoneNumberIntl(value.phoneNumber)}</span>
                                                <a href="mailto:sales@epiflow.com" className='ms-3'>{value.email}</a>
                                            </p>
                                            <Collapse isOpen={viewRep.index === index && viewRep.status}>
                                                <CardBody style={{ 'padding': '0px'}}>
                                                    <hr />
                                                    <h4 className='commontitlefive'>Contact Representative</h4>
                                                    <p className='commonParaThree'>Use the form below to send this representative a message.</p>
                                                    <Row>
                                                        <Col lg="4" md="6" sm="12">
                                                            <div className="form-group">
                                                                <label className="font-weight-bold" for="">FIRST NAME <span className='text-danger'>*</span></label>
                                                                <input type="text" className="form-control" id="" placeholder="First Name" />
                                                            </div>
                                                        </Col>
                                                        <Col lg="4" md="6" sm="12">
                                                            <div className="form-group">
                                                                <label className="font-weight-bold" for="">LAST NAME <span className='text-danger'>*</span></label>
                                                                <input type="text" className="form-control" id="" placeholder="Last Name" />
                                                            </div>
                                                        </Col>
                                                        <Col lg="4" md="6" sm="12">
                                                            <div className="form-group">
                                                                <label className="font-weight-bold" for="">EMAIL <span className='text-danger'>*</span></label>
                                                                <input type="email" className="form-control" id="" placeholder="Email" />
                                                            </div>
                                                        </Col>
                                                        <Col lg="4" md="6" sm="12">
                                                            <label For="" className="form-label si-phone-text font-weight-bold">PHONE <span className='text-danger'>*</span></label>
                                                            <input type="email" className="form-control" id="" placeholder="Email" />
                                                        </Col>
                                                        <Col lg="4" md="6" sm="12">
                                                            <div className="form-group">
                                                                <label className="font-weight-bold" for="">COMPANY</label>
                                                                <input type="text" className="form-control" id="" placeholder="Company" />
                                                            </div>
                                                        </Col>
                                                        <Col lg="4" md="6" sm="12">
                                                            <div className="form-group">
                                                                <label className="font-weight-bold" for="">ZIP CODE </label>
                                                                <input type="text" className="form-control" id="" placeholder="Zip Code" />
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="form-group">
                                                                <label className="font-weight-bold" for="exampleFormControlTextarea1">ENTER DETAILS HERE</label>
                                                                <textarea className="form-control bt-text-area" id="exampleFormControlTextarea1" rows="4" placeholder='Select Communication Protocol'></textarea>
                                                            </div>
                                                        </Col>
                                                        <button className='button1 m-0'>
                                                            Submit
                                                        </button>
                                                    </Row>
                                                </CardBody>
                                            </Collapse>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Col>
                    <Col>
                        <div className='FPIframeDiv'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26372021.139118582!2d-113.73046575972587!3d36.209340135454404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sbd!4v1644442125339!5m2!1sen!2sbd" title='map' allowfullscreen="" loading="lazy"></iframe>
                        </div>
                    </Col>
                </Row>
            </div>

            {/* section-3 */}

        </div>
    );
}

export default FRsearch;
