import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink, Button } from 'reactstrap';
import { Helmet } from "react-helmet";

function PrivacyInquiry() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>EPI – Privacy Inquiry </title>
        <meta name="description" content="Click on the dedicated sections inside this link to access EPI’s legal documents and disclaimers. " />
        <link rel="canonical" href="https://epiflow.com/support/privacyinquiry" />
    </Helmet>
      {/* section-1 */}
      <div className='commonbanner PrivacyInc-section-1'>
        <div className='image-container'>
          <div className='about-title container pt-2 pb-2'>
              <span className='me-2'><NavLink className='breadcrumbsLink' to="/support" tag={Link}>Support</NavLink></span>
              <FontAwesomeIcon icon={faChevronRight} className='icon' />
              <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/support/privacyinquiry" tag={Link}>Privacy Inquiry</NavLink></span>
          </div>
          <div>
            <h2 className='cover-title-h2'>Privacy Inquiry</h2>
            <p className='cover-title-p'>Use the links below to access EPI legal documents and disclaimers.</p>
          </div>
        </div>
      </div>
      {/* section-1 */}
      {/* section-2 */}
      <div className="background-colorOne">
        <Container className='py-5'>
          <h4 className='commontitleTwo text-center'>EPI Legal Documents</h4>
          <Row className='mt-5 g-3'>
           <Col>
              <div className='text-center px-4 pt-4 bg-white shadow rounded'>
                <h4 className='commonParaOne'>Terms & Conditions</h4>
                <p className='commonParaThree'>
                  The EPI Terms and Conditions covers
                  liability provisions and other legal
                  topics that keep both EPI and our
                  customers safe.
                  <br />
                </p>
                <a href='/support/privacyinquiry/terms'>
                  <button className='button2 w-100 px-0'>View Terms & Conditions</button>
                </a>
              </div>
            </Col>
            <Col>
              <div className='text-center px-4 pt-4 bg-white shadow rounded'>
                <h4 className='commonParaOne'>Repair & Recalibration Service Work Disclaimer</h4>
                <p className='commonParaThree'>
                  The R&R Disclaimer specifies terms
                  for meter repairs and all servicing
                  that happens at our factory.
                </p>
                <a href="/support/privacyinquiry/repairrecalibration">
                  <button className='button2 w-100 px-0'>View R&R Disclaimer</button>
                </a>
              </div>
            </Col>
            {/* <Col>
              <div className='text-center px-4 pt-4 bg-white shadow rounded'>
                <h4 className='commonParaOne'>Privacy Policy</h4>
                <p className='commonParaThree'>View EPI’s Privacy Policy, which
                  details terms for website usage,
                  adverting methods, and customer
                  data protections.
                </p>
                <a href="/support/privacyinquiry/privacypolicy">
                  <button className='button2 w-100 px-0'>View Privacy Policy</button>
                </a>
              </div>
            </Col> */}
            {/* <Col lg="3" md="6">
              <div className='text-center px-4 pt-4 bg-white shadow rounded'>
                <h4 className='commonParaOne'>Conditions of Sale</h4>
                <p className='commonParaThree'>
                  The CondiKons of Sale covers the
                  purchase agreement that is made
                  between our customers and EPI
                  during every purchase.
                </p>
                <button className='button2 w-100 px-0'>View Conditions of Sales</button>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
      {/* section-2 */}
    </>
  );
}

export default PrivacyInquiry;
