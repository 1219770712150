import React, {useState} from "react";
import axios from 'axios';
import { Button, Modal } from "reactstrap";
import { useSWRConfig } from 'swr'
import 'assets/css/EditAccount.css';

function AllUsersModal({modalLarge, setModalLarge, userData}){
  const { mutate } = useSWRConfig()
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading2, setLoading2] = useState(false);
  const [error2, setError2] = useState(false);
  const [success2, setSuccess2] = useState(false);

  const update_distributor = async (position) => {
    setLoading(true);
    let update_data = {
      user_id: userData._id,
      email: userData.email,
      firstname: userData.firstname,
      lastname: userData.lastname,
      companyName: userData.companyName,
      phoneNumber: userData.phoneNumber,
      department: userData.department,
      address: userData.address,
      city: userData.city,
      zip: userData.zip,
      country: userData.country,
      state: userData.state
    }

    if(position === 'ban'){
      update_data.account_type = 'banned';
    }
    
    if(position === 'unban' || 'regular'){
      update_data.account_type = 'regular';
    }

    if(position === 'admin'){
      update_data.account_type = 'admin';
    }

    try{
      await axios.put(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/users/update/admin`,
        update_data,
        { withCredentials: true }
      );
      mutate('/api/users/all')
      setLoading(false);
      setSuccess(true);
    } catch (e) {
      setSuccess(false);
      setLoading(false);
      setError(true);
    }
  }

  const ban_user = async (position) => {
    setLoading2(true);
    let update_data = {
      user_id: userData._id,
      email: userData.email,
      firstname: userData.firstname,
      lastname: userData.lastname,
      companyName: userData.companyName,
      phoneNumber: userData.phoneNumber,
      department: userData.department,
      address: userData.address,
      city: userData.city,
      zip: userData.zip,
      country: userData.country,
      state: userData.state
    }

    if(position === 'ban'){
      update_data.account_type = 'banned';
    }
    
    if(position === 'unban'){
      update_data.account_type = 'regular';
    }

    try{
      await axios.put(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/users/update/admin`,
        update_data,
        { withCredentials: true }
      );
      mutate('/api/users/all')
      setLoading2(false);
      setSuccess2(true);
    } catch (e) {
      setSuccess2(false);
      setLoading2(false);
      setError2(true);
    }
  }

  return (
    <>
      <Modal
        isOpen={modalLarge}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setModalLarge(false)}
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => setModalLarge(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="text-center">
          <h4>
            ACCOUNT TYPE: 
            {userData.account_type === 'admin' && <> ADMIN</>}
            {userData.account_type === 'regularPlusPricing' && <> REGULAR WITH PRICING</>}
            {userData.account_type === 'regular' && <> REGULAR</>}
            {userData.account_type === 'requesting' && <> REQUESTING VIEW PRICING</>}
            {userData.account_type === 'banned' && <> ACCOUNT BANNED</>}
          </h4>
        </div>
        <div className="modal-body">
          <div className='bdr body p-3'>
            <div>
              <div className='row'>
                <div className='col-md-6'>
                  <div class="form-group">
                    <label for="firstname">FIRST NAME <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      name="fname"
                      id="firstname"
                      autoComplete='given-name'
                      placeholder="First Name"
                      value={userData.firstname}
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div class="form-group">
                    <label for="lastname">LAST NAME <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="lastname"
                      name="lname"
                      autoComplete='family-name'
                      placeholder="Last Name"
                      value={userData.lastname}
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="jobtitle">JOB TITLE</label>
                    <input
                      type="text"
                      id="jobtitle"
                      name="jobtitle"
                      class="form-control"
                      placeholder="Job Title"
                      value={userData.jobTitle}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="company">COMPANY <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      class="form-control"
                      placeholder="Company Name"
                      value={userData.companyName}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="department">DEPARTMENT</label>
                    <input
                      type="text"
                      id="department"
                      name="department"
                      class="form-control"
                      placeholder="Department"
                      value={userData.department}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="address">ADDRESS <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      class="form-control"
                      placeholder="Address"
                      value={userData.address}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="city">CITY <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      class="form-control"
                      placeholder="City"
                      value={userData.city}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="zip">ZIP <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      id="zip"
                      name="zip"
                      class="form-control"
                      placeholder="Zip"
                      value={userData.zip}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="company">COUNTRY <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      id="country"
                      name="country"
                      class="form-control"
                      placeholder="Country"
                      value={userData.country}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="state">STATE <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      class="form-control"
                      placeholder="State"
                      value={userData.state}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <label For="phone" class="form-label">PHONE <span className='text-danger'>*</span></label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    class="form-control"
                    placeholder="Phone number"
                    value={userData.phoneNumber}
                  />
                </div>
              </div>
            </div>
          </div> 
        </div>

        <hr style={{ 'border': '5px solid' }} />
        <div className="text-center">
          <h3 style={{ 'margin': '0px'}}>
            <u>Account actions</u>
          </h3>
        </div>
        <br />
        
        {error &&
          <div className="text-center text-danger">
            <p>Error occurred. Try again</p>
          </div>
        }

        {success &&
          <div className="text-center">
            <p>Account updated</p>
          </div>
        }
        <div className="modal-footer">
          <Button
            color="secondary"
            type="button"
            onClick={() => update_distributor('regular')}
          >
            {loading? 
              <>Updating...</>:
              <>Make account regular</>
            }
          </Button>
          <Button
            color="danger"
            type="button"
            onClick={() => update_distributor('admin')}
          >
            {loading? 
              <>Updating...</>:
              <>Make account admin</>
            }
          </Button>
        </div>

        <hr style={{ 'border': '5px solid' }} />
        <div className="text-center">
          <h3 style={{ 'margin': '0px'}}>
            <u>Account Disciplinary actions</u>
          </h3>
        </div>
        <br />
        
        {error2 &&
          <div className="text-center text-danger">
            <p>Error occurred. Try again</p>
          </div>
        }

        {success2 &&
          <div className="text-center">
            <p>Account updated</p>
          </div>
        }
        <div className="modal-footer">
          <Button
            color="secondary"
            type="button"
            onClick={() => ban_user('unban')}
          >
            {loading2? 
              <>Updating...</>:
              <>Unbad account</>
            }
          </Button>

          <Button
            color="danger"
            type="button"
            onClick={() => ban_user('ban')}
          >
            {loading2? 
              <>Updating...</>:
              <>Ban account</>
            }
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default AllUsersModal;