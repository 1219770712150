import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row, Button, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";

import TechnicalInfoFooter from 'components/Footers/TechnicalInfoFooter';

function TechnicalInformation() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI - Technical Information</title>
            <meta name="description" content="If you would like to know more about EPI’s flow meters, switches, and software, this is the section you’re searching for." />
            <link rel="canonical" href="https://epiflow.com/technicalinfo" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner TISection-1'>
                <div className='image-container'>
                    <div className='about-title container pt-2 pb-2'>
                        <span className='me-2'><NavLink className='breadcrumbsLink' to="/" tag={Link}>Home</NavLink></span>
                        <FontAwesomeIcon icon={faChevronRight} className='icon' />
                        <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                    </div>
                    <div>
                        <h2 className='cover-title-h2'>Technical Information</h2>
                        <p className='cover-title-p'>
                            Everything you need to know about EPI flow meters and more!
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='bg-white TISection2'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo mb-4 text-center'>Categories</h4>
                    <Row className='g-4'>
                        <Col lg="4" md="6">
                            <div className='p-3 shadow rounded text-center'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fa-full-guide-to-thermal-mass-flow-meters.png?alt=media&token=ff08c3d4-2792-4c71-8bc9-791f067fd8be" className='w-100' alt="Flow Meter mounted horizontally" />
                                <h4 className='commontitleOne mt-3 text-dark'>
                                    A Full Guide to Thermal Mass Flow Meters
                                </h4>
                                <p className='commonParaFour'>
                                    Get the latest on everything you should know about these flow meters and what makes the technology
                                    so important to so many different industries and applications.
                                </p>
                                <NavLink to="/technicalinfo/fullguideflowmeters" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Learn More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="4" md="6">
                            <div className='p-3 shadow rounded text-center'>
                                <img src='https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fagency-approvals-and-certificates.png?alt=media&token=244c66c0-32c1-47d8-81a7-ae456087cd7b' className='w-100' alt="Agency Certified Badge" />
                                <h4 className='commontitleOne mt-3 text-dark'>
                                    Agency Approvals & Certificates
                                </h4>
                                <p className='commonParaFour'>
                                    Learn about the approvals and certifications that our EPI flow meters have passed, proving reliable
                                    in both hazardous and ordinary locations.
                                </p>
                                <NavLink to="/technicalinfo/approvalscertificates" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Learn More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="4" md="6">
                            <div className='p-3 shadow rounded text-center'>
                                <img src='https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fthermal-mass-flow-meter-installation.png?alt=media&token=a1ddd10b-def5-4c2a-92eb-058c3f331c7e' className='w-100' alt="Insertion Flow Meter installed" />
                                <h4 className='commontitleOne mt-3 text-dark'>
                                    Installation
                                </h4>
                                <p className='commonParaFour'>
                                    View our guide on thermal mass flow meter installation for inline and insertion styles, with included
                                    tips to help you complete the task worry-free.
                                </p>
                                <NavLink to="/technicalinfo/installation" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Learn More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="4" md="6">
                            <div className='p-3 shadow rounded text-center'>
                                <img src='https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fflow-meter-considerations.png?alt=media&token=7bc4c6bc-1149-4ca9-bea0-ebe019590944' className='w-100' alt="Check list of things to keep in mind" />
                                <h4 className='commontitleOne mt-3 text-dark'>
                                    Meter Considerations
                                </h4>
                                <p className='commonParaFour'>
                                    In order to ensure that your new meter will be a perfect fit for your application, there are certain parameters
                                    to keep in mind. Get the right meter for the job the first time!
                                </p>
                                <NavLink to="/technicalinfo/meterconsiderations" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Learn More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="4" md="6">
                            <div className='p-3 shadow rounded text-center'>
                                <img src='https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fflow-meter-configurations.png?alt=media&token=3b41f335-30ea-49b3-9f68-475bc8eb062a' className='w-100' alt="Multiple meter configurations on a table" />
                                <h4 className='commontitleOne mt-3 text-dark'>
                                    Meter Configurations
                                </h4>
                                <p className='commonParaFour'>
                                    Learn about the different mounting styles available for our flow meters, as well as the differences between the integral
                                    and remote electronics and what they are used for.
                                </p>
                                <NavLink to="/technicalinfo/meterconfigurations" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Learn More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="4" md="6">
                            <div className='p-3 shadow rounded text-center'>
                                <img src='https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fmeter-technology-comparison.png?alt=media&token=946f5d24-a75e-4bd6-a315-981fd0c3bddd' className='w-100' alt="Different types of flow meters" />
                                <h4 className='commontitleOne mt-3 text-dark'>
                                    Technology Comparison
                                </h4>
                                <p className='commonParaFour'>
                                     View a list of many different types of flow meters, how they operate, and what applications they are ideal for.
                                </p>
                                <NavLink to="/technicalinfo/technologycomparison" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Learn More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="4" md="6">
                            <div className='p-3 shadow rounded text-center'>
                                <img src='https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fflow-meter-operational-theory.png?alt=media&token=2f93fb4f-cd43-489d-a8e7-c8ed4394848e' className='w-100' alt="Close-up of meter sensor" />
                                <h4 className='commontitleOne mt-3 text-dark'>
                                    Operational Theory
                                </h4>
                                <p className='commonParaFour'>
                                    See an in-depth breakdown of the science behind thermal mass flow meters and how they accurately and reliably provide
                                    flow and temperature measurements.
                                </p>
                                <NavLink to="/technicalinfo/operationaltheory" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Learn More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="4" md="6">
                            <div className='p-3 shadow rounded text-center'>
                                <img src='https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Findustrial-applications-for-flow-meters.png?alt=media&token=f8354a7a-aa76-47a6-bdb7-95b9714b9ec5' className='w-100' alt="Large industrial plant that uses flow meters" />
                                <h4 className='commontitleOne mt-3 text-dark'>
                                    Industrial Applications for Meters
                                </h4>
                                <p className='commonParaFour'>
                                    Thermal mass flow meters are used in many different industries in an almost unlimited variety of ways. These are some of the most common.
                                </p>
                                <NavLink to="/technicalinfo/industrialapplications" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Learn More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="4" md="6">
                            <div className='p-3 shadow rounded text-center'>
                                <img src='https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fmeter-white-papers.png?alt=media&token=b51fbc00-1637-4c57-8a2d-e10d23c576a1' className='w-100' alt="Factory with flue stacks" />
                                <h4 className='commontitleOne mt-3 text-dark'>
                                    White Papers
                                </h4>
                                <p className='commonParaFour'>
                                    Get more detailed information about flow meters and how they operate in different industries, and how EPI flow meters are used every day to solve real world problems.
                                </p>
                                <NavLink to="/technicalinfo/whitepapers" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Learn More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="4" md="6">
                            <div className='p-3 shadow rounded text-center'>
                                <img src='https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fgases-we-flow.png?alt=media&token=f57e0d3d-b851-473a-8c30-b3adefe72c21' className='w-100' alt="Gas canisters with many different gases" />
                                <h4 className='commontitleOne mt-3 text-dark'>
                                    Gases We Flow
                                </h4>
                                <p className='commonParaFour'>
                                    EPI flow meters can accurately measure the flow of many different gasses, including custom gas mixtures. Your new flow meter will be calibrated and tested with your specific gas mixture before shipping it out.
                                </p>
                                <NavLink to="/technicalinfo/gasesweflow" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        Learn More
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section-2 */}
            {/* section-3 */}
                <TechnicalInfoFooter />
            {/* section-3 */}
        </>
    )
}

export default TechnicalInformation
