import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import img from '../../assets/img/images/OTImg1.png';
import { Helmet } from "react-helmet";

import TechnicalInfoFooter from 'components/Footers/TechnicalInfoFooter';

function OperationalTheory() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Flow Meter Operational Theory </title>
            <meta name="description" content="Learn about how EPI flow meters accurately measure thermal mass flow, and the technologies that allow them to work in most applications. " />
            <link rel="canonical" href="https://epiflow.com/technicalinfo/operationaltheory" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner OTSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/operationaltheory" tag={Link}>Operational Theory</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Operational Theory</h2>
                        <p className='cover-title-p'>
                            Learn about how EPI meters accurately measure thermal mass flow, and the technologies that allow them to work in most applications.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo text-center mb-4'>Thermal Mass Flow Operational Theory</h4>
                    <Row>
                        <Col lg="10" md="6">
                            <div>
                                <p className='commonParaThree'>
                                    EPI™ flow meters are immersion type differential mass flow meters utilizing constant temperature thermal dispersion sensor technology. The rate of heat absorbed from the sensor by the flowing gas molecules contacting the sensor is proportional to the gas mass velocity. Mass flow rate is this mass velocity passing through a fixed area. By math, mass velocity x area = mass flow. Sensor construction consists of two matched, reference-grade platinum Resistance Temperature Detectors (RTDs) sheathed in rugged thin wall 316 stainless steel thermo wells. The only materials exposed to the process gas stream are 316 SS (or optional Hastelloy C276).
                                    <br /><br />
                                    The sensors’ RTDs are temperature corrective and operate at a constant power differential. The flow sensor is heated, while the second sensor acts as a temperature reference, taking on the temperature of the flowing process gas. The flow meter’s digital electronics maintain the balance the two the flow sensing elements. Within a wide range, the sensor has pressure corrective properties since the molecular density will increase or decrease proportionally with pressure and so the sensor is insensitive to typical process pressure changes. As a result, no external temperature or pressure corrections are required to achieve accurate flow measurements.
                                    <br /><br />
                                    As heat is dispersed from the flow sensor to the moving gas stream during the NIST-traceable calibration process, we closely measure both the gas flow and the amount of power required to maintain the correct temperature differential between the heated and unheated sensors. The power required to maintain this balance — the flow signal — is a non-linear function of flow rate. This flow signal is linearized by the unique coefficients stored in the flow meter's microprocessor, and the flow meter provides a true mass flow output.
                                </p>
                            </div>
                        </Col>
                        <Col lg="2" md="6">
                            <div className='text-center'>
                                <img src={img} className="OTimage" alt="Diagram showing how flow meters operate" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* section-2 */}
            {/* section-3 */}
                <TechnicalInfoFooter />
            {/* section-3 */}
        </>
    )
}

export default OperationalTheory
