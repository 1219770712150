import React, { useState, useEffect } from 'react';
import useSwr from 'swr';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import{
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button
} from "reactstrap";

import fetcher from 'utils/fetcher';
import 'assets/css/EditAccount.css';
import ShipToAddressModal from './ShipToAddressModal';

function ShipToAddresses({ userData }) {
  const [modal, setModal] = useState(false);
  const [shippingAddress, setShippingAddress] = useState([]);
  const { data, mutate } = useSwr("/api/addresses/shippingAddress", fetcher);

  useEffect(() => {
    if(data){
      setShippingAddress(data)
    }
  }, [data]);

  const deleteAddress = async (data) => {
    console.log('Show me shipping addresses 3.0 =>', data);

    try{
      await axios.put(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/addresses/shippingAddress`,
        data,
        { withCredentials: true }
      )
      mutate('/api/addresses/shippingAddress');
    } catch (e) {
      console.log('Show me update user error 1.0 =>', e);
    }
  }

  console.log('Show me shipping addresses 1.0 =>', shippingAddress);

  return (
    <>
      <div className='common-main'>
        <div className='quit-details-main'>
          <div className='row quit-details-main-row'>
              <div className='col-md-4'>
                  <h3 className='p-3 mt-2 quite-details-text'>Ship-to Addresses</h3>
              </div>
              <div className='col-md-8 d-flex'>
                <div className='row qoute-2nd-rw d-flex align-items-center justify-content-end'>
                  <ul className='text-end m-0'>
                    <li><a onClick={() => setModal(true)} className='me-3'><strong><FontAwesomeIcon icon={faAddressBook}></FontAwesomeIcon> Add a new address</strong></a></li>
                  </ul>
                </div>
              </div>
          </div>
        </div>
        <div className='bdr body p-3'>
          {shippingAddress && shippingAddress.map((value) => {
            return(
              <Card style={{ width: "20rem", margin: '10px' }}>
                <CardBody>
                  <CardTitle tag="h4">{value.company}</CardTitle>
                  <CardText>
                    {value.address}<br />
                    {value.city},<br />
                    {value.state},<br />
                    {value.zip},<br />
                    {value.country}
                  </CardText>
                  <Button
                    className='inputButton'
                    href="#pablo"
                    onClick={() => deleteAddress(value)}
                  >
                    Delete
                  </Button>
                </CardBody>
              </Card>
            );
          })}
        </div>
      </div>
      <ShipToAddressModal
        modal={modal}
        setModal={setModal}
      />
    </>
  );
}

export default ShipToAddresses
