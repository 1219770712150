import React, { useState, useEffect } from "react";

// core components
import ProductHeader from "components/Headers/ProductHeader";
import ProductViewNavbar from "components/Navbars/ProductViewNavbar";
import ProductView from "components/ProductViews/Components/ProductView";
import ProductDescription from "components/ProductViews/Components/ProductDescription";
import PuregenLMCustomizer from "components/Customizer/Views/Pure/PureInline/PuregenLMCustomizer";
import TechSpecs from "views/TechSpecs/TechSpecs";
import CADFiles from "views/CADFiles/CADFiles";
import { Helmet } from "react-helmet";


function PuregenLMProductView({productType, tab}){
  const [page, setPage] = useState('discover');

  useEffect(() => {
    if(tab){
      if(tab === 'disc'){
        setPage('discover');
      }
  
      if(tab === 'specs'){
        setPage('specs');
      }
  
      if(tab === 'cad'){
        setPage('cad');
      }  
    }
  }, [tab]);
  
  const productImage = "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FPURE%2FInline%2FLM%2FSeries-800-HPX-Inline-Hero.png?alt=media&token=70e01097-aae1-495b-b0f5-5b073f772813";

  const navbarData = {
    product_title: 'SERIES 800 HPX',
    product_title_old: 'SERIES 800',
    product_type: 'INLINE THERMAL MASS FLOW METER'
  }

  const productInfo = {
    model: 'SERIES 800HPX',
    series: navbarData.product_title
  }

  const images = [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FPURE%2FInline%2FLM%2FProductImages%2FSeries-800-HPX-Inline-Flow-Meter.jpg?alt=media&token=92a0d1c4-096f-4b08-bb5f-3008cf2cc49c",
      altText: "Series 800 HPX Inline High-Purity Inline Flow Meter",
      productImageLink: '/productimages/hpx'
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FPURE%2FInline%2FLM%2FProductImages%2FSeries-800-HPX-Inline-Enclosure-Close-Up.jpg?alt=media&token=d014088c-99c0-49c8-bb77-f7f20cf10cfc",
      altText: "Series 800 HPX Inline Enclosure",
      productImageLink: '/productimages/hpx_2'
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FPURE%2FInline%2FLM%2FProductImages%2FSeries-800-HPX-Inline-with-Butt-Ends.jpg?alt=media&token=9714e4f3-5888-4acf-8fd4-9786d50cf619",
      altText: "Series 800 HPX Inline with Butt Ends",
      productImageLink: '/productimages/hpx_3'
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FPURE%2FInline%2FLM%2FProductImages%2FSeries-800-HPX-Inline-with-VCR-Ends.jpg?alt=media&token=fac26b4e-aa7d-4e98-8f8a-3ebeb120e286",
      altText: "Series 800 HPX Inline with VCR Ends",
      productImageLink: '/productimages/hpx_4'
    }
  ];

  const productViewData = {
    title: 'Series 800 HPX Inline High-Purity Inline Flow Meters',
    description_small: `
    Part of EPI Flow’s PURE series of clean hygienic flow meters, the EPI-PUREGEN-LM Series 800 HPX is an inline high purity thermal mass flow meter that uses an Explosion-Proof Rated Enclosure, for applications where extremely accurate and highly repeatable measurement and control is needed.
    <br /><br />
    The 800 HPX is a thermal mass flowmeter with a unique feature set specifically designed for a variety of Ultra High Purity and High Purity applications including Semiconductor & Displays, Pharmaceutical, Biotechnology, Food & Beverage, and other processes that require a clean hygienic flow meter.
    <br /><br />
    To deliver utmost accuracy, longevity, and easy maintenance, the EPI Series 800 HPX features a thermal mass flow sensor that is rugged and cleanable and utilizes two RTD (Resistance Temperature Detector) sensing elements. The sensors are also especially refined for easy removal and cleaning and are constructed of reference-grade platinum, ceramic, glass, and stainless steel. In the end, the VCR-style sensors (very easy to clean), are slipped into a stainless-steel cover for abrasion and corrosion resistance.
    <br /><br />
    If you require Ultra High Purity and High Purity gas thermal mass flow meters with inline installation type for hazardous locations, the 800 HPX is your easy choice. On the other hand, if you need Ultra High Purity & High Purity inline thermal flow meter for Ordinary locations with a non-hazardous rating check our <a href="/products/pure/inline/pure-rb">Series 800 HPN high purity thermal mass flow meter</a>.
    `,
    description: `
    800 HPX products offer a feature set designed for a variety of Ultra High Purity and High Purity applications including Semiconductor & Displays, Pharmaceutical, Biotechnology, Food & Beverage, and other processes that require a clean hygienic flow meter. The product includes a rugged, cleanable, thermal mass flow sensor. These units consist of a sensor assembly which utilizes two RTD (Resistance Temperature Detector) sensing elements. The sensors are a VCR style for easy removal and cleaning and are constructed of reference grade platinum, ceramic, glass, and stainless steel. Two platinum resistance sensors are built up upon the ceramic substrate and then given a thin glass coating. The assembly is then slipped into a stainless-steel sheath for corrosion and abrasion resistance. The sensor assembly is large, rugged, and relatively insensitive to dirt buildup. During operation, the temperature sensor constantly measures the ambient temperature of the gas and maintains a reference resistance on one side of a bridge circuit. The second sensor is forced through self-heating to a constant temperature above that of the gas stream and is controlled by the temperature sensor and bridge circuitry. Our bridge maintains the overheat temperature and counterbalances the temperature effects through our proprietary temperature compensation techniques.
    <br /><br />
    800 Series flow meters are approved for use in hazardous locations (see specifications).
    <br /><br />
    Inline style thermal mass flow meters include a flow section that is usually specified to match the user’s flow conduit and is then plumbed directly into the process line. This design has the sensing elements mounted directly in the flow section for exposure to the process gas. Our inline style thermal mass flow meters are available in sizes from 1/4″ pipe through 6″ pipe. The internal wetted surfaces may be electro-polished to either a 10 Ra or 25 Ra surface finish. Inlet flow conditioning plates are optionally available on flow sections from ¾” to 6”. Mounting is via butt weld ends. Optional end configurations include – Tri-Clamp style ends, or male VCR style ends for ½”, ¾” or 1” tubes.
    <br /><br />
    Integral style thermal mass flow meters have all the electrical components and connections located within one enclosure. This enclosure is rated for non-hazardous environments. The enclosure is mounted directly to the inline flow section assembly at the point of measurement. The enclosure includes all the electrical connections as well as the linearizing electronics and the display/keypad assembly.
    <br /><br />
    Thermal mass flow meters use the principle of convective heat transfer to directly measure mass flow. EPI’s proprietary thermal mass flow sensors use two ratiometrically-matched, reference-grade platinum Resistance Temperature Detectors (RTDs). The platinum sensing element wire is encapsulated in a 316 Stainless Steel sheath or, if specified, a Hastelloy C sheath. Our microcontroller operated smart sensor technology preferentially heats one RTD; the other RTD acts as the temperature reference. The process gas flow dissipates heat from the first RTD, causing an increase in the power required to maintain a balance between the RTDs. This increase is directly related to the gas molecular rate of flow. Our sensors are temperature compensated for a wide process gas temperature range and insensitive to pressure changes, so the output signal is a true mass flow rate signal.

    `,
    meter_type: 'Inline',
    enclosure: 'Integrated Electronics',
    warranty: '1-4',
    certifications: 'CE'
  }

  const techSpecsData = {
    features:{
      titleOne: 'PURE Series HPX Enclosure',
      highlightOneImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FProductFeatures%2Ffeatures-hpx.png?alt=media&token=9735938e-6e4d-47da-b9f5-363722b2e20f',
      highlightOneInfo: `The HPX flow meter enclosure is a diecast aluminum, NEMA Type 4X watertight enclosure.
      <br /><br />
      <ul>
        <li>Material of Body & Cover: Aluminium Pressure Die-Casting</li>
        <li>Finish: Safety Blue Spray Epoxy</li>
        <li>Coating: Chromating and Chemically Resistant Paint (outside only)</li>
        <li>Protection Degree: Max. possible IP68</li>
        <li>Glass Thickness: 15mm</li>
      </ul>
      `,
      titleTwo: 'Superior Flow Measurement and Calibration',
      highlightTwoImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ffeatures-image-2.png?alt=media&token=86df9cb6-22d4-4fbc-bc7f-f6b4e267b8ec',
      highlightTwoPdf: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FProductFeatures%2FFlow%20Calibration%20Check.png?alt=media&token=d98121d8-44ae-465d-b603-aa56f7c9bdd6',
      highlightTwoInfo: `EPI flow meters provide accurate, consistent, and rapid real-time flow measurement with a sensor that
      is both temperature and pressure corrective. Each flow meter is calibrated using standards traceable to
      the National Institute of Standards and Technology (NIST).
      <br /><br />

      -Accuracy: +/- (1% of reading + 0.5% of full scale + GTC) <br />
      -Repeatability: +/- 0.2% of full scale <br />
      -Sensor response time: 1 second to 63% of final value <br />
      -Gas temperature coefficient (GTC): 0.02% full scale/°C <br />
      -Gas pressure effect: negligible over +/-50% of factory calibration pressure
      `
    },
    our_process: {
      benefits:[
        {
          title: 'Broad Velocity Range',
          info: `Up to 60,000 SFPM (80,000 SFPM with Attenuation Rod or Particle Shield)`
        },
        {
          title: 'Low flow sensitivity',
          info: `100:1 Turn down ratio; 10 SFPM (0.05 NMPS) minimum reading.`
        },
        {
          title: 'Wide temperature range',
          info: `Sensor suitable for process gas temperatures up to 392°F (200°C).`
        },
        {
          title: 'Multiple analog outputs',
          info: `4-20mA and 0-5VDC outputs for both flow and temperature`
        },
        {
          title: 'Various communication protocols supported',
          info: `RS485 Modbus RTU (standard), HART, BACnet, and Profibus DP`
        },
        {
          title: 'NIST traceable calibration facility',
          info: `Over 30 years of flow calibration expertise`
        },
        {
          title: 'Ease of installation',
          info: `Various configurations to meet your installation requirements`
        },
        {
          title: 'Live Zero',
          info: `Live Zero (EPIVal) to validate your flow meter calibration in both process line and ambient air
          conditions`
        }
      ]
    },
    specifications: {
      specs: [
        {
          title: 'Approvals',
          info: `Currently no product agency approvals.`
        },
        {
          title: 'Enclosure Certifications by manufacturer',
          info: `CSA – CL. I. GR. A,B,C,D; CL. II. GR. E,F,G; CL. III.;TYPE 4X,
          US – CL. I. GR. A,B,C,D; CL. II. GR. E,F,G; CL. III.;TYPE 4X,
          IECEx – Ex d IIC Gb, Ex t IIIC Db,
          ATEX – II 2G Ex IIC Gb, II 2Dd Ex t 111C Db`
        },
        {
          title: 'Linear signal output',
          info: `0–5 VDC & 4–20 mA`
        },
        {
          title: 'Event Relay Rating',
          info: `One with Frequency or two, 1 Amp @ 30Vdc (33W)`
        },
        {
          title: 'Communication protocols',
          info: `RS232 & (RS485 ASCII or Modbus RTU or BACNet)`
        },
        {
          title: 'Optional communication protocols',
          info: `HART or Profibus DP (24Vdc power only)`
        },
        {
          title: 'Display & Keypad',
          info: `2-line 16-character LCD with 4 tactile buttons`
        },
        {
          title: 'Accuracy, including linearity (Ref.: 21°C)',
          info: `±(1% of Reading + 0.5% of Full Scale + GTC)`
        },
        {
          title: 'Repeatability',
          info: `±0.2% of Full Scale`
        },
        {
          title: 'Sensor response time',
          info: `1 second to 63% of final value`
        },
        {
          title: 'Turn down ratio',
          info: `100:1 @ 10 SFPM (.051 NMPS) Minimum Reading`
        },
        {
          title: 'Electronics PCB temperature range',
          info: `-40° to 158°F (-40° to +70°C)`
        },
        {
          title: 'Gas temperature range*',
          info: `-40°C to 66°C (-40° to 150°F), extended ranges available`
        },
        {
          title: 'Maximum flow body rating',
          info: `500 PSI Std.`
        },
        {
          title: 'Power supply',
          info: `24 Vdc; optional 115VAC or 230VAC 50/60Hz`
        },
        {
          title: 'Transmitter power requirements',
          info: `5 watts maximum`
        },
        {
          title: 'Wetted materials',
          info: `316 Stainless Steel`
        },
        {
          title: 'Internal Flow Body Surface Finish',
          info: `Internal finish – Typical <=25Ra Max.; Optional <=10Ra Max`
        },
        {
          title: 'Standard temperature & pressure (STP)',
          info: `70°F & 29.92″ Hg (Air .075 lb./cubic foot)`
        },
        {
          title: 'NIST traceable calibration',
          info: `Standard`
        },
        {
          title: 'NOTE',
          info: `Specifications subject to change without notice.`
        }
      ]
    },
    communication_config: {
      type: 'pure',
      comm_config: [
        {
          output: '4-20MA FLOW (SELF OR ISOLATEDLOOP POWERED)',
          standard: true,
          frequency: true,
          bacnet: true,
          bacnet_frequency: true,
          hart: true,
          profibus: true
        },
        {
          output: '0-5 OR 0-10 VDC FLOW',
          standard: true,
          frequency: true,
          bacnet: true,
          bacnet_frequency: true,
          hart: false,
          profibus: true
        },
        {
          output: '4-20MA TEMPERATURE',
          standard: true,
          frequency: true,
          bacnet: true,
          bacnet_frequency: true,
          hart: true,
          profibus: true
        },
        {
          output: '0-5 OR 0-10 VDC TEMPERATURE',
          standard: true,
          frequency: true,
          bacnet: true,
          bacnet_frequency: true,
          hart: true,
          profibus: true
        },
        {
          output: 'FREQUENCY 0-1KHZ',
          standard: false,
          frequency: true,
          bacnet: false,
          bacnet_frequency: true,
          hart: false,
          profibus: false
        },
        {
          output: 'MODE SWITCH (RANGES)',
          standard: false,
          frequency: true,
          bacnet: false,
          bacnet_frequency: true,
          hart: false,
          profibus: false
        },
        {
          output: '1 MECHANICAL RELAY',
          standard: true,
          frequency: false,
          bacnet: true,
          bacnet_frequency: false,
          hart: true,
          profibus: true
        },
        {
          output: '2 MECHANICAL RELAY	',
          standard: true,
          frequency: true,
          bacnet: true,
          bacnet_frequency: true,
          hart: true,
          profibus: true
        },

        {
          output: 'RS 485/MODBUS (R/W)',
          standard: true,
          frequency: true,
          bacnet: true,
          bacnet_frequency: true,
          hart: true,
          profibus: true
        },
        {
          output: 'RS 232 (R/W)',
          standard: true,
          frequency: true,
          bacnet: false,
          bacnet_frequency: false,
          hart: false,
          profibus: false
        },
        {
          output: 'HART (READ ONLY)',
          standard: false,
          frequency: false,
          bacnet: false,
          bacnet_frequency: false,
          hart: true,
          profibus: false
        },
        {
          output: 'PROFIBUS (READ ONLY)',
          standard: false,
          frequency: false,
          bacnet: false,
          bacnet_frequency: false,
          hart: false,
          profibus: false
        },
        {
          output: 'rs 485/BACNET (READ ONLY)',
          standard: false,
          frequency: false,
          bacnet: true,
          bacnet_frequency: true,
          hart: false,
          profibus: false
        },
      ]
    },
    geometry_sizing: {
      cadImg: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FPURE-1.jpg?alt=media&token=4080017f-edb1-4689-9854-5c2c79d0c789',
      cadImg2: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FPURE-2.jpg?alt=media&token=fe1d9b46-1149-4013-91c2-31b13c33b9cc',
      sizing: [
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        }
      ]
    },
    tech_support:{
      faq: [
        {
          title: 'Wiring',
          info: `The wiring of your EPI flow meter will be based on the configuration selected when your order was placed. EPI flow meters can be
          configured for various AC or DC input power options. Both analog and digital output signals are also available depending on your model and
          options.`
        },
        {
          title: 'Orientation',
          info: `EPI flow meters can be mounted in a number of different orientations based on your requirements with some limited adjustability in the
          field.`
        },
        {
          title: 'Installation',
          info: `EPI flow meters do require minumum up and downstream straight run to ensure the flow profile can develop properly, providing you with
          the highest accuracy readings.`
        },
        {
          title: 'Communication/ Outputs',
          info: `Your EPI flow meter will come standard with RS485 Modbus and RS232 digital communication. We also offer optional BACnet, Profibus DP and
          HART communications protocols. EPI flow meters provide a 4-20mA and 0-5 VDC analog output signal for flow and
          temperature (based on specific models) as well as up to 2 relays that can be independently configured based on your requirements.
          `
        }
      ],
      maintenance_info: {
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
      }
    },
    installation: {
      video: '',
      image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FWiring%2FHPX.png?alt=media&token=3962adaa-2242-456f-b2cb-ecd9733beac4'
    },
    documents_downloads: {
      datasheets: [
        {
          title: 'Downloads',
          files: [
            {
              file_title: 'Product Brochure',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%20800HPX.pdf?alt=media&token=54438bdc-97fe-4902-acc0-513597a0983c',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Wiring Diagram',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FHPX.png?alt=media&token=da2df853-ac52-46a2-af80-92651a629792',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Meter Installation Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'EPITerm Software Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000003_RevA%20EPITerm%20Interface%20User%20Manual.pdf?alt=media&token=0e082343-3f69-476a-aaa0-fd344ece1885',
              version: '1.0.0',
              release_data: '2022'
            }
          ],
        }
      ],
    }
  }

  const cadData = {
    files: [
      {
        productName: '',
        modelLink: '',
        pdfLink: '',
        downloadLink: '',
        description: ``
      }
    ]
  }

  const ViewPages = () => {
    if(page === 'discover'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <ProductView productViewData={productViewData} images={images} />
          </div>
          <ProductDescription productViewData={productViewData} />
          <PuregenLMCustomizer
            productType={productType}
            productInfo={productInfo}
          />
        </>
      );
    }

    if(page === 'specs'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <TechSpecs data={techSpecsData} />
          </div>
        </>
      );
    }

    if(page === 'cad'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <CADFiles data={cadData} />
          </div>
        </>
      );
    }

    if(page === 'support'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <h1>Support</h1>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <ProductHeader productImage={productImage} />
      <div
        className="section section-about-us"
        style={{
          'background': 'whitesmoke',
          'padding-bottom': '50px',
          'border-bottom': 'whitesmoke'
        }}
      >
        <ProductViewNavbar
          navbarData={navbarData}
          page={page}
          setPage={setPage}
        />
      </div>
      <ViewPages />
    </>
  );
}

export default PuregenLMProductView;
