import React from "react";

// product views
import PuregenLMProductView from "components/ProductViews/Pure/PureInline/PuregenLMProductView";
import PuregenLMRProductView from "components/ProductViews/Pure/PureInline/PuregenLMRProductView";
import PuregenRBProductView from "components/ProductViews/Pure/PureInline/PuregenRBProductView";
import PuregenRBRProductView from "components/ProductViews/Pure/PureInline/PuregenRBRProductView";

function PureInline({product, productType, tab}) {
  switch (product) {
    case 'pure-lm':
      return (
        <>
          <PuregenLMProductView productType={productType} tab={tab} />
        </>
      );
      break;

      case 'pure-lmr':
        return (
          <>
            <PuregenLMRProductView productType={productType} tab={tab} />
          </>
        );
        break;

      case 'pure-rb':
        return (
          <>
            <PuregenRBProductView productType={productType} tab={tab} />
          </>
        );
        break;

      case 'pure-rbr':
        return (
          <>
            <PuregenRBRProductView productType={productType} tab={tab} />
          </>
        );
        break;
  
    default:
      return (
        <>
          <PuregenLMProductView productType={productType} tab={tab} />
        </>
      );
      break;
  }
}

export default PureInline;
