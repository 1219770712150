import React,{ useState, useEffect } from 'react';
import useSwr from 'swr';
import { useHistory } from 'react-router';
// import '../assets/css/Quotes.css';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import DistributorModal from './ViewDistributor';
import fetcher from 'utils/fetcher';
import DistrubutorsTable from './DistributorsTable';
import PricingTable from './PricingTable';
import CadTable from './CadTable';
import ViewPricesModal from './ViewPricesModal';

function Dashboard() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();
    const [modalLarge, setModalLarge] = useState(false);
    const [modalLarge2, setModalLarge2] = useState(false);
    const [requestView, setRequestView] = useState('distributors');
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const [quoteData, setQuoteData] = useState([]);
    const [pricingRequestData, setPricingRequestData] = useState([]);
    const [cadRequestData, setCadRequestData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [modalData2, setModalData2] = useState([]);
    const page = { dashboard: true }

    var { data } = useSwr(`/api/me`, fetcher);
    var { data: Distributor } = useSwr(`/api/distributor/all`, fetcher);
    var { data: pricingRequests } = useSwr(`/api/users/request`, fetcher);
    var { data: cadRequests } = useSwr(`/api/cad/all`, fetcher);


    useEffect(() => {
        if(data){
            setUserData(data);
            if(data.account_type != 'admin'){
                history.push('/portal/quotes');
            }
        }

        if(Distributor){
            setQuoteData(Distributor)
        }

        if(pricingRequests){
            setPricingRequestData(pricingRequests)
        }

        if(cadRequests){
            setCadRequestData(cadRequests)
        }
    }, [data, Distributor, pricingRequests, cadRequests]);

    // console.log('Pricing data 1.0 =>', pricingRequests)
    // console.log('Cad data 1.0 =>', cadRequestData)

    const headerData = {
        firstname: userData.firstname,
        page: 'DISTRIBUTORS REQUESTS'
    }

    console.log('Dist data 1.0 =>', quoteData);
    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page} /></div>) : undefined}
                    <div className='common-main'>
                        <div className='shadow'>
                            <h2 className='p-3'>Pending Approvals Requests</h2>
                            <div className='quotes body p-3'>
                                <div className='row filters'>
                                <div className='col-9'>
                                    {requestView === 'distributors'?
                                        <p>View all distributors requests</p>
                                        :
                                        <p>View all view pricing requests</p>
                                    }
                                </div>
                                <div className='col-3'>
                                    <select
                                        class="form-select form-control form-control-lg"
                                        onChange={(e) => setRequestView(e.target.value)}
                                    >
                                        <option value="distributors">Distributors</option>
                                        <option value="pricings">Pricings</option>
                                        <option value="cad">CAD Files</option>
                                    </select>
                                </div>
                                </div>
                                <hr />
                                { requestView === 'distributors' &&
                                    <DistrubutorsTable
                                        users={quoteData}
                                        setModalData={setModalData}
                                        modalLarge={modalLarge}
                                        setModalLarge={setModalLarge}
                                    />
                                }

                                { requestView === 'pricings' &&
                                    <PricingTable
                                        users={pricingRequestData}
                                        setModalData={setModalData2}
                                        modalLarge={modalLarge2}
                                        setModalLarge={setModalLarge2}
                                    />
                                }

                                { requestView === 'cad' &&
                                    <CadTable
                                        users={cadRequestData}
                                        setModalData={setModalData2}
                                        modalLarge={modalLarge2}
                                        setModalLarge={setModalLarge2}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DistributorModal
                modalLarge={modalLarge}
                setModalLarge={setModalLarge}
                distributorData={modalData}
            />

            <ViewPricesModal
                modalLarge={modalLarge2}
                setModalLarge={setModalLarge2}
                userData={modalData2}
            />
        </div>
    )
}

export default Dashboard
