import React,{ useState, useEffect } from 'react';
import useSwr from 'swr';
import { useParams } from "react-router";
import '../assets/css/Quotes.css';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import fetcher from 'utils/fetcher';

import HazCustomizer from "components/Customizer/Views/Haz/HazInline/HazCustomizer";
import HazRCustomizer from "components/Customizer/Views/Haz/HazInline/HazRCustomizer";
import HazFatCustomizer from "components/Customizer/Views/Haz/HazInline/HazFatCustomizer";
import HazFatRCustomizer from "components/Customizer/Views/Haz/HazInline/HazFatRCustomizer";

import GenCustomizer from "components/Customizer/Views/Gen/GenCustomizer";
import GenRCustomizer from "components/Customizer/Views/Gen/GenRCustomizer";
import GenFatCustomizer from "components/Customizer/Views/Gen/GenFatCustomizer";
import GenFatRCustomizer from "components/Customizer/Views/Gen/GenFatRCustomizer";

import ValgenLMCustomizer from 'components/Customizer/Views/Val/ValInline/ValgenLMCustomizer';
import ValgenRBCustomizer from 'components/Customizer/Views/Val/ValInline/ValgenRBCustomizer';

import PuregenLMCustomizer from 'components/Customizer/Views/Pure/PureInline/PuregenLMCustomizer';
import PuregenRBCustomizer from 'components/Customizer/Views/Pure/PureInline/PuregenRBCustomizer';

import _200CCustomizer from 'components/Customizer/Views/Xt/XtInsertion/200CCustomizer';
import _525CrCustomizer from 'components/Customizer/Views/Xt/XtInsertion/525CrCustomizer';

function EditCartQuote() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const [quoteData, setQuoteData] = useState([]);
    var { quoteId } = useParams();
    const page = { cart: true }

    var { data: user_data } = useSwr(`/api/me`, fetcher);
    var { data: Quotes } = useSwr(`/api/quotes/single/${quoteId}`, fetcher);
    
    useEffect(() => {
      if(user_data){
        setUserData(user_data);
      }

      if(Quotes){
        setQuoteData(Quotes)
      }
    }, [user_data, Quotes]);

    // console.log('Show me view quote data 1.0.1 =>', quoteId, quoteData);

    const headerData = {
        firstname: userData.firstname,
        page: 'QUOTES',
        subpage: 'EDIT QUOTE'
    }

    const navbarData = {
        product_title: 'SERIES 8600-8700MP HAZ',
        product_title_old: 'SERIES 8600-8700MP',
        product_type: 'INLINE THERMAL MASS FLOW METER'
    }

    // console.log('Show the model 1.0 =>', quoteData);

    const Customizer = () => {
        switch (quoteData.model) {
            case '8600-8700MP':
            case '8800MP':
                return(
                    <HazCustomizer 
                        quoteData={ quoteData }
                        quoteId={ quoteId }
                        pageType="viewQuote"
                        // productInfo={navbarData}
                    />
                );
                break;

            case '8000-8100MP':
            case '8200MP':
                return(
                    <HazRCustomizer 
                        quoteData={ quoteData }
                        quoteId={ quoteId }
                        pageType="viewQuote"
                    />
                );
                break;

            case '9700MP':
            case '9800MP':
                return(
                    <HazFatCustomizer 
                        quoteData={ quoteData }
                        quoteId={ quoteId }
                        pageType="viewQuote"
                    />
                );
                break;

            case '9100MP':
            case '9200MP':
                return(
                    <HazFatRCustomizer 
                        quoteData={ quoteData }
                        quoteId={ quoteId }
                        pageType="viewQuote"
                    />
                );
                break;

            // GEN
            case '8600-8700MPNH':
            case '8600-8700MPNH':
                return(
                    <GenCustomizer 
                        quoteData={ quoteData }
                        quoteId={ quoteId }
                        pageType="viewQuote"
                    />
                );
                break;

            case '8000-8100MPNH':
            case '8200MPNH':
                return(
                    <GenRCustomizer
                        quoteData={ quoteData }
                        quoteId={ quoteId }
                        pageType="viewQuote"
                    />
                );
                break;

            case '9700MPNH':
            case '9800MPNH':
                return(
                    <GenFatCustomizer
                        quoteData={ quoteData }
                        quoteId={ quoteId }
                        pageType="viewQuote"
                    />
                );
                break;

            case '9100MPNH':
            case '9200MPNH':
                return(
                    <GenFatRCustomizer
                        quoteData={ quoteData }
                        quoteId={ quoteId }
                        pageType="viewQuote"
                    />
                );
                break;

            // VAL
            case 'SERIES 500':
            case 'SERIES 540':
                return(
                    <ValgenLMCustomizer 
                        quoteData={ quoteData }
                        quoteId={ quoteId }
                        pageType="viewQuote"
                    />
                );
                break;

            case 'SERIES 400':
            case 'SERIES 440':
                return(
                    <ValgenRBCustomizer
                        quoteData={ quoteData }
                        quoteId={ quoteId }
                        pageType="viewQuote"
                    />
                );
                break;

            // PURE
            case 'SERIES 800HPX':
                return(
                    <PuregenLMCustomizer 
                        quoteData={ quoteData }
                        quoteId={ quoteId }
                        pageType="viewQuote"
                    />
                );
                break;

            case 'SERIES 800HPN':
                return(
                    <PuregenRBCustomizer
                        quoteData={ quoteData }
                        quoteId={ quoteId }
                        pageType="viewQuote"
                    />
                );
                break;

            // XT
            case '8800XT 200C':
                return(
                    <_200CCustomizer 
                        quoteData={ quoteData }
                        quoteId={ quoteId }
                        pageType="viewQuote"
                    />
                );
                break;

            case '8800XT525':
                return(
                    <_525CrCustomizer
                        quoteData={ quoteData }
                        quoteId={ quoteId }
                        pageType="viewQuote"
                    />
                );
                break;
        default:
            return false;
            break
        }
    }

    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page} /></div>) : undefined}
                    <div className='common-main1'>
                        <Customizer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditCartQuote
