import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Card, CardBody, CardText, CardTitle, Col, Container, Row, NavLink } from 'reactstrap';
import TechSupportFooter from 'components/Footers/TechSupportFooter'
import { Helmet } from "react-helmet";

function TechSupportNew() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
          <Helmet>
              <meta charSet="utf-8" />
              <title>EPI – Technical Support</title>
              <meta name="description" content="Get help with any issues you’re having with your flow meter, from installation to software and manuals, and anything in between!" />
              <link rel="canonical" href="https://epiflow.com/support/techsupport" />
          </Helmet>
            {/* section-1 */}
            <div className='tsn-section-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/support" tag={Link}>Support</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/support/techsupport" tag={Link}>Technical Support</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Technical Support</h2>
                        <p className='cover-title-p'>
                            Get help with any issues you're having with your flow meter, from installation to software and manuals, and anything in between.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-3 */}
                <TechSupportFooter />
            {/* section-3 */}

            {/* section-2 */}
            <div className='pt-5 pb-5'>
                <Container>
                    <h3 className='commontitleOne pt-5 mb-4 text-center'>Help when you need it most.</h3>
                    <Row>
                        <Col>
                            <Card className='card-shadow-none'>
                                <CardBody className='text-center p-4'>
                                    <CardTitle className='commontitlefive'>
                                        Help with Technical Issues
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2'>
                                        Get immediate technical assistance from a factory-trained professional.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='card-shadow-none'>
                                <CardBody className='text-center p-4'>
                                    <CardTitle className='commontitlefive'>
                                        Help with Installation
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2'>
                                        Receive remote advice from an installation expert at EPI.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='card-shadow-none'>
                                <CardBody className='text-center p-4'>
                                    <CardTitle className='commontitlefive'>
                                        Request Software & Manuals
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2'>
                                        Let us know what you're searching for, and we'll give you a copy of the things you've requested within 24 hours (weekdays).
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='card-shadow-none'>
                                <CardBody className='text-center p-4'>
                                    <CardTitle className='commontitlefive'>
                                        Report an Issue
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2'>
                                        Please let us know if you have any problems using our website or connected services, such as the meter customizer, emails, or payment details.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section-2 */}

        </div>
    )
}

export default TechSupportNew
