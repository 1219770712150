import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';

// reactstrap components
import {
  UncontrolledCollapse,
  Button,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardHeader,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Collapse,
  UncontrolledTooltip
} from "reactstrap";

function ProbeSelection({ handleChange, state, checkState }){
  const [selectInputOptions, setSelectInputOptions] = useState(1);
  const [widthSelected, setWidthSelected] = useState(0);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  // collapse states and functions
  const [collapses, setCollapses] = React.useState([0]);
  const changeCollapse = collapse => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter(prop => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  const MakeChangeText = (data) => {
    data.option_value = data.option.target.value;

    if(data.input_name === 'width'){
      setWidthSelected(1);
      if(data.option_value === '1/2'){
        setSelectInputOptions(1)
      } else if(data.option_value === '3/4'){
        setSelectInputOptions(2)
      } else if(data.option_value === '1'){
        setSelectInputOptions(2)
      }
    }
    handleChange(data);
  }

  const MakeChangeText_ = (data) => {
    data.option_value = data.option.target.value;
    handleChange(data);
  }

  const MakeChangeCheckbox = (data) => {
    if(!data.option.target.checked){
      data.values = ['none', data.values[0]]
      handleChange(data);
    } else {
      handleChange(data);
    }
  }

  const checkUnits = (unit, item) => {
    if(checkState(state.probe_section.probe_length, item) === unit){
      return true;
    }
    return false;
  }

  const checkUnits_ = (unit, item) => {
    if(checkState(state.probe_section.probe_width, item) === unit){
      return true;
    }
    return false;
  }

  const getMeterModelValue = (value) => {
    if(value === '1/2'){ return '40' }
    if(value === '3/4'){ return '60' }
    if(value === '1'){ return '80' }
  }

  const getMeterModelValue2 = (value) => {
    if(value === '6'){ return '06' }
    if(value === '12'){ return '12' }
    if(value === '18'){ return '18' }
    if(value === '24'){ return '24' }
    if(value === '30'){ return '30' }
    if(value === '36'){ return '36' }
    if(value === '42'){ return '42' }
    if(value === '48'){ return '48' }
    if(value === '54'){ return '54' }
    if(value === '60'){ return '60' }
  }

  var title = 'Probe Selection';
  if (isMobile) {
    if(title.length > 18) title = title.substring(0,18) + '...';
  }

  return (
    <>
      <Card style={{
        'border-top-left-radius': 'calc(.45rem - 1px)',
        'border-top-right-radius': 'calc(.45rem - 1px)',
        'border-bottom-left-radius': 'calc(.45rem - 1px)',
        'border-bottom-right-radius': 'calc(.45rem - 1px)'
        }}>
        <CardHeader>
          <a
            aria-expanded={collapses.includes(2)}
            data-toggle="collapse"
            href="#pablo"
            onClick={e => {
              e.preventDefault();
              changeCollapse(2);
            }}
          >
            <Navbar className="bg-epi-blue" style={{
              'height': '10px', 'margin-bottom': '5px',
              'border-top-left-radius': 'calc(.45rem - 1px)',
              'border-top-right-radius': 'calc(.45rem - 1px)'
              }} expand="lg">
              <Container>
                <div className="navbar-translate">
                  <NavbarBrand href="#pablo" onClick={e => e.preventDefault()} style={{'font-size': '22px', 'color': 'white'}}>
                    <p>{ title }</p>
                  </NavbarBrand>
                  <button
                    className="navbar-toggler"
                    id="example-navbar-danger"
                    type="button"
                    aria-expanded={collapses.includes(2)}
                    onClick={e => {
                      e.preventDefault();
                      changeCollapse(2);
                    }}
                  >
                    <i className="now-ui-icons arrows-1_minimal-down" style={{'font-size': '22px', 'color': 'white'}}></i>
                  </button>
                </div>
                <UncontrolledCollapse navbar toggler="#example-navbar-danger">
                  <Nav className="ml-auto" navbar style={{'font-size': '22px', 'color': 'white'}}>
                    <i className="now-ui-icons arrows-1_minimal-down"></i>
                  </Nav>
                </UncontrolledCollapse>
              </Container>
            </Navbar>
          </a>
        </CardHeader>

        <Collapse isOpen={collapses.includes(2)}>
          <CardBody>
            <Row>
              <Col>
              <FormGroup check inline>
                <Label check>
                  <span className="customizerInputTitleSmallX1">Ball Valve Retractor</span>
                  <Input
                    defaultChecked={
                      checkState(
                        state.probe_section.ball_val_retractor,
                        'bvr'
                      )
                    }
                    onClick={ (e) => MakeChangeCheckbox({
                      section: 'probe_section',
                      type: 'ball_val_retractor',
                      values: ['bvr', 'none'],
                      price_effect: false,
                      option: e
                    }, e)}
                    id="ball_val_retractor"
                    name="ball_val_retractor"
                    type="checkbox"
                  ></Input>
                  <span className="form-check-sign"></span>
                  <Button className="questionToolTip" id="HydrostaticCertificate" size="sm">
                    ?
                  </Button>{` `}
                  <UncontrolledTooltip placement="top" target="HydrostaticCertificate" delay={0}>
                    Hydrostatic testing is a type of pressure test that works by completely filling the component with water,
                    removing the air contained within the unit, and pressurizing the system beyond the designed pressure limit the of the unit.
                    The pressure is then held for a specific amount of time to visually inspect the system for leaks.
                    If selected, your meter will be tested before shipment and sent with the certificate of passing.
                  </UncontrolledTooltip>
                </Label>
              </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label htmlFor="exampleFormControlSelect1">
                    <span className="customizerInputTitle">Width</span>
                    <Button className="questionToolTip" id="ApplicationQuestion" size="sm">
                      ?
                    </Button>{` `}
                    <UncontrolledTooltip placement="right" target="ApplicationQuestion" delay={0}>
                      This refers to the application for which you will be using your meter,
                      including Industrial, Oil & Gas, Wastewater, and Biogas.
                    </UncontrolledTooltip>
                  </label>
                  <Input
                    className="epiInputSize form-select"
                    id="exampleFormControlSelect1"
                    type="select"
                    placeholder="Yes or No"
                    onChange={ (e) => MakeChangeText({
                      section: 'probe_section',
                      type: 'probe_width',
                      values: ['width'],
                      price_effect: true,
                      input_name: 'width',
                      text_input: true,
                      meter_model_effect: true,
                      meter_model_type: 'pipe_length',
                      meter_model_value: getMeterModelValue(e.target.value),
                      option: e
                    }, e)}
                  >
                    <option value="" selected disabled>Select Width</option>
                    <option selected={checkUnits_('1/2', 'width')} value="1/2">1/2"</option>
                    <option selected={checkUnits_('3/4', 'width')} value="3/4">3/4"</option>
                    <option selected={checkUnits_('1', 'width')} value="1">1"</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col>
                { selectInputOptions == 1 && (
                  <FormGroup>
                    <label htmlFor="exampleFormControlSelect1">
                      <span className="customizerInputTitle">Length</span>
                      <Button className="questionToolTip" id="Gas" size="sm">
                        ?
                      </Button>{` `}
                      <UncontrolledTooltip placement="right" target="Gas" delay={0}>
                        Gas refers to the specific gas(es) that you will be metering.
                        Your new meter will be precisely calibrated to measure the provided selection.
                        If you select Natural or Mixed Gas, please input and verify the chemicals and percentages,
                        totaling 100%, that make up the composition you will be flowing.
                      </UncontrolledTooltip>
                    </label>
                    <Input
                      // disabled={ widthSelected? ('') : ('disabled') }
                      disabled={!checkState(state.probe_section.probe_width, 'width')}
                      className="epiInputSize form-select"
                      id="exampleFormControlSelect1"
                      type="select"
                      onChange={ (e) => MakeChangeText_({
                        section: 'probe_section',
                        type: 'probe_length',
                        values: ['length'],
                        price_effect: false,
                        text_input: true,
                        meter_model_effect: true,
                        meter_model_type: 'probe_length',
                        meter_model_value: getMeterModelValue2(e.target.value),
                        option: e
                      }, e)}
                    >
                      <option value="" selected disabled>Select Length</option>
                      <option
                        disabled={checkState(state.probe_section.ball_val_retractor, 'bvr')}
                        selected={checkUnits('6')}
                        value="6"
                      >
                        6"
                      </option>
                      <option selected={checkUnits('12', 'length')} disabled={checkState(state.probe_section.ball_val_retractor, 'bvr')} value="12">12"</option>
                      <option selected={checkUnits('18', 'length') || checkUnits_('1/2', 'width')} value="18">18"</option>
                      <option selected={checkUnits('24', 'length')} value="24">24"</option>
                      <option selected={checkUnits('30', 'length')} value="30">30"</option>
                      <option selected={checkUnits('36', 'length')} value="36">36"</option>
                    </Input>
                  </FormGroup>
                )}

                { selectInputOptions == 2 && (
                  <FormGroup>
                    <label htmlFor="exampleFormControlSelect1">
                      <span className="customizerInputTitle">Length</span>
                      <Button className="questionToolTip" id="Gas" size="sm">
                        ?
                      </Button>{` `}
                      <UncontrolledTooltip placement="right" target="Gas" delay={0}>
                        Gas refers to the specific gas(es) that you will be metering.
                        Your new meter will be precisely calibrated to measure the provided selection.
                        If you select Natural or Mixed Gas, please input and verify the chemicals and percentages,
                        totaling 100%, that make up the composition you will be flowing.
                      </UncontrolledTooltip>
                    </label>
                    <Input
                      className="epiInputSize form-select"
                      id="exampleFormControlSelect1"
                      type="select"
                      onChange={ (e) => MakeChangeText({
                        section: 'probe_section',
                        type: 'probe_length',
                        values: ['length'],
                        price_effect: false,
                        text_input: true,
                        meter_model_effect: true,
                        meter_model_type: 'probe_length',
                        meter_model_value: getMeterModelValue2(e.target.value),
                        option: e
                      }, e)}
                    >
                      <option value="" selected disabled>Select Length</option>
                      <option
                        disabled={checkState(state.probe_section.ball_val_retractor, 'bvr') && checkUnits_('1/2', 'width')}
                        selected={checkUnits('6')}
                        value="6"
                      >
                        6"
                      </option>
                      <option selected={checkUnits('12', 'length')} disabled={checkState(state.probe_section.ball_val_retractor, 'bvr') && checkUnits_('1/2', 'width')} value="12">12"</option>
                      <option selected={checkUnits('18', 'length')} disabled={checkState(state.probe_section.ball_val_retractor, 'bvr') && checkUnits_('1/2', 'width')} value="18">18"</option>
                      <option selected={checkUnits('24', 'length')} value="24">24"</option>
                      <option selected={checkUnits('30', 'length')} value="30">30"</option>
                      <option selected={checkUnits('36', 'length')} value="36">36"</option>
                      <option selected={checkUnits('42', 'length')} value="42">42"</option>
                      <option selected={checkUnits('48', 'length')} value="48">48"</option>
                      <option selected={checkUnits('54', 'length')} value="54">54"</option>
                      <option selected={checkUnits('60', 'length')} value="60">60"</option>
                    </Input>
                  </FormGroup>
                )}
              </Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
}

export default ProbeSelection;
