/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { 
  Container,
  Row,
  Col,
  Button,
  NavLink
} from "reactstrap";

function TechnicalInfoFooter() {
  return(
    <>
      <div className='background-colorOne py-5'>
        <Container className='text-center'>
          <h3 className='commontitleOne pt-5 mb-4'>Can’t find the meter or information you <br />
              are looking for?</h3>
          <p className='commonParaOne'>We’re here to help!</p>
          <Row className='text-center'>
              <Col md="6" sm="12" className='d-flex justify-content-md-end justify-content-sm-center'>
                  <NavLink to="/support" tag={Link}>
                      <Button className="button1 loginbtn" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                          View Support
                      </Button>
                  </NavLink>
              </Col>
              <Col md="6" sm="12" className='d-flex justify-content-md-start justify-content-sm-center'>
                  <NavLink to="/contactus" tag={Link}>
                      <Button className="button2 loginbtn" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                          Contact Us
                      </Button>
                  </NavLink>
              </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TechnicalInfoFooter;
