import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row, Button, NavLink } from 'reactstrap';
import img1 from '../../assets/img/images/InstallationImg1.png';
import img2 from '../../assets/img/images/InstallationImg2.png';
import img3 from '../../assets/img/images/InstallationImg3.png';
import { Helmet } from "react-helmet";


import TechnicalInfoFooter from 'components/Footers/TechnicalInfoFooter';


function Installation() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Flow Meter Installation Details</title>
            <meta name="description" content="Check out the inline and insertion flow meter installation & mounting guidelines recommended by the EPI team! " />
            <link rel="canonical" href="https://epiflow.com/technicalinfo/installation" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner InstallationSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/installation" tag={Link}>Installation</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Installation</h2>
                        <p className='cover-title-p'>
                            See the flow meter installation & mounting guidelines recommended by the EPI team.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo text-center mb-4'>Flow Meter Installation Details</h4>
                    <p className='commonParaThree'>
                        Acceptable limits for the process gas temperature and the environmental temperature to which the transmitter electronics may be subjected are listed in the product-specific
                        pages of this site and the appropriate Manual.
                        <br /><br />

                        We recommend installing the flow meter at a location where the gas is dry or above the dew point temperature. Installations which allow large droplets of water to condense out
                        and to come in contact with the sensing element must be avoided. Applications with large quantities of gas-borne particulates should also be avoided as the sensor may become
                        dirty which could affect the heat loss to the flowing gas and therefore have a negative impact on the overall accuracy of the flow readings. Gas purge options are available,
                        if necessary. Consult the factory for options.
                        <br /><br />

                        Optimum installation requires sufficient straight run to allow a uniform, non-swirling, fully-developed flow profile within the flow conduit. General guideline for minimum straight
                        run requirements are included in the Manual for each product. Depending upon the specific location details, more or less straight run may be required to produce a satisfactory flow profile.
                        It is best to avoid installations which are immediately downstream of bends, abrupt cross-sectional area increases or decreases, fans, louvers, or other equipment installed in the line.
                        These situations can cause non-uniform flow profiles and swirl which can result in signal errors. Problematic flow profiles require flow conditioning to improve meter performance.
                        Consult the factory for additional information.
                    </p>
                </Container>
            </div>

            {/* section-2 */}

            {/* section-3 */}
            <div className='background-colorOne'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo text-center mb-4'>Installation Guides</h4>
                    <Row className='gy-4'>
                        <Col md="6">
                            <div className='InstallationS3Card shadow rounded p-3 text-center ms-auto'>
                                <div>
                                    <img src={img1} className="mt-3" alt="Inline Flow Meter Icon" />
                                </div>
                                <h5 className='commontitlethree mt-4 mb-0'>Inline Flow Meters</h5>
                                <p className='commonParaThree font-weight-bold'>INSTALLATION GUIDE</p>
                                <NavLink to="/technicalinfo/installation/inline" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        View Guide
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='InstallationS3Card shadow rounded p-3 text-center'>
                                <div>
                                    <img src={img2} className="mt-3" alt="Insertion Flow Meter Icon" />
                                </div>
                                <h5 className='commontitlethree mt-4 mb-0'>Insertion Flow Meters</h5>
                                <p className='commonParaThree font-weight-bold'>INSTALLATION GUIDE</p>
                                <NavLink to="/technicalinfo/installation/insertion" tag={Link}>
                                    <Button className="button2 w-100" style={{ 'font-size': '1.0em'}}>
                                        View Guide
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* section-3 */}

            {/* section-4 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo text-center mb-4'>Master-Touch™ Flow Meter Installation & Mounting Guidelines</h4>
                    <p className='commonParaThree'>
                        The Master-Touch™ thermal dispersion sensor must be exposed to the flowing gas in the process pipe at a location that provides a uniform and consistent flow profile across the
                        pipe diameter. Each flow meter is calibrated under tightly controlled conditions in our NIST-traceable facility. Our inline style flowmeters are calibrated with the sensors in
                        a fixed position within the provided flow section. Our insertion flowmeters are calibrated near the ISO Point-of-Average-Flow (.243r) positioning in the process line* with a
                        fully-developed flow profile. However, anomalies in the actual flow profile or installations in non-circular ducts may require adjustments to the flow meter’s output signals
                        or readings to achieve the best accuracy. Although changes to the process gas composition, gas temperature, line pressure, etc. can affect the overall accuracy of the flow readings,
                        these effects are often minimal when compared to their effect on other flow measurement technologies.
                    </p>
                    <div className='text-center py-5'>
                        <img src={img3} className='installation-img' alt="Graphic showing the Point of Average Flow for gases in a pipe" />
                    </div>
                    <p className='commonParaThree'>
                        The Master-Touch™ firmware supports two methods of in situ flow signal adjustments for superior accuracy:
                        <br /> <br />
                        <ul>
                            <li>The C-Factor (Menu 811) adjusts the linearized microprocessor output by a uniform percentage of the readings. This simple adjustment should be applied before any futher
                                adjustments.
                            </li>
                            <li>
                                Point-Wise adjustments (Menu 750 PW-Curve Fit) provide greater flexibility by allowing adjustments in increments of 5% of the Full Scale, i.e., 10–15% or 65–70%. It is unlikely
                                that more than two or three adjustments would be needed to achieve the desired results, however we offer the option to apply those adjustments wherever along the Full Scale
                                that you are able to capture the comparative values.
                            </li>
                        </ul>
                        Please consult the factory for additional information on flow meter installation.
                    </p>
                </Container>
            </div>

            {/* section-4 */}
            {/* section-5 */}
                <TechnicalInfoFooter />
            {/* section-5 */}
        </>
    )
}

export default Installation
