import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";


import TechnicalInfoFooter from 'components/Footers/TechnicalInfoFooter';

function Oxygen() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Oxygen Gas Measurement</title>
            <meta name="description" content="See how EPI’s thermal flow meters are used to accurately measure flue gas in many different industrial process. Check out our article!" />
            <link rel="canonical" href="https://epiflow.com/industrialapplications/oxygen" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner FullGuide-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/industrialapplications" tag={Link}>Industrial Applications</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/oxygen" tag={Link}>Oxygen Gas</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Oxygen Gas Measurement</h2>
                        <p className='cover-title-p'>
                            Learn about how thermal mass flow meters are used to measure oxygen gas.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <div className='DownloadPDfbtn-div'>
                        <div className='position-relative d-inline'>
                            <audio src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fapplications%2FOxygen.mp3?alt=media&token=645adbf8-8bc2-4b14-9746-ed25890dcfa5" controls />
                        </div>
                    </div>
                    <h4 className='commontitleTwo text-center mb-2'>
                        Oxygen Gas Measurement with Thermal Mass Flow Meters
                    </h4>
                    {/* <p className='commonParaThree text-center mb-4'>
                        Thermal mass flow technology comparison has some explana,on below for helping to understand correct terms while doing this analysis.
                    </p> */}
                    <p className='commonParaThree'>
                        Uncompressed air and oxygen gas are used in a wide variety of commercial and industrial applications,
                        covering everything from steel production to dehydrating fruit to renewing municipal water supplies.
                        Uncompressed atmospheric air is chosen for many applications for obvious reasons – it’s plentiful and
                        effectively free. Pure oxygen is more costly, but its chemical properties make it an important input in many
                        industrial processes where minimizing fuel waste and catalyzing chemical reactions and biological
                        processes are the goal. In all cases, the key to ensuring efficient production and minimized costs is the
                        careful measurement of the input gas – a task that can be made more accurate, more repeatable, and
                        more cost-effective with the right technology.
                    </p>
                    <br /><br />
                    <h4 className='commontitlefive mb-2'>
                        Industrial Combustion
                    </h4>
                    <p className='commonParaThree'>
                        The importance of combustion to industry can’t be overstated, with furnaces, boilers, and heaters
                        accounting for roughly <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2F29676.pdf?alt=media&token=6c80a176-eb62-45c1-a8a1-78cbef7e0112">
                        two-thirds of the total energy used by American manufacturers.</a> Anywhere
                        combustion has to happen, oxygen is a must. In some cases, standard atmospheric air is good enough,
                        but in many industrial combustion applications where high heat is a must, pure oxygen is injected in place
                        of or in addition to air. In the steel industry, for instance, oxygen gas is a key input used to make furnaces
                        run more efficiently, decarburize steel, and more. Oxygen can also be used in glass melting furnaces to
                        reach the same temperatures while consuming less fuel and <a href="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Foxygen_enriched_combustion_process_htgts3.pdf?alt=media&token=dfb8b8b6-9154-41a7-a931-31517e2ac3a9">
                        lowering nitrogen oxide emissions by up to 90%</a>.&nbsp; In all industrial combustion applications, obtaining the right fuel-to-air mixture is crucial to achieving consistent, correct temperatures while minimizing fuel waste, making it extremely important that manufacturers be able to measure their air and oxygen flow accurately.
                    </p>
                    <br /><br />

                    <h4 className='commontitlefive mb-2'>
                        Wastewater Aeration
                    </h4>
                    <p className='commonParaThree'>
                        Wastewater aeration is the process of adding air to water in order to encourage the growth of bacteria,
                        which then eat and break down contaminants, leaving much cleaner water behind. It’s used extensively
                        both in the treatment of industrial wastewater, and the treatment of the municipal water we drink and use
                        in our homes every day. While algae can be used to provide dissolved oxygen to the system in some
                        cases, in most wastewater aeration systems, air or oxygen is injected into the aeration basins either at
                        the surface or through subsurface bubblers. Much like in industrial combustion, air can be used as a cost-
                        effective input, but pure oxygen gas can also be injected to improve the efficiency of bacteria growth and
                        their consumption of unwanted pollutants. In either case, getting oxygen levels in the system just right is
                        key to ensuring happy bacteria and optimal waste reduction.
                    </p>
                    <br /><br />

                    <h4 className='commontitlefive mb-2'>
                        HVAC
                    </h4>
                    <p className='commonParaThree'>
                        Heating, ventilation, and air conditioning systems are of crucial parts of effectively all commercial
                        environments, as well as our own homes. They’re especially important in industrial settings where
                        potentially harmful substances can get into the air, ranging from contaminants in labs to sawdust in
                        workshops and beyond. But, while maintaining consistent airflow in a house is one thing, maintaining
                        proper ventilation and the flow of clean air, temperature-correct air throughout an office building or industrial
                        facility is an entirely different problem. As a result, large HVAC systems can be extremely complex in
                        design. They can also be extremely wasteful and costly if not operating correctly. Unfortunately, because
                        of the size of the ducting and the length and winding nature of most HVAC systems, measuring the flow
                        of air throughout an entire building to ensure that blowers, fans, louvers, and control systems are all
                        working correctly can be extremely challenging.
                    </p>
                    <br /><br />

                    <h4 className='commontitlefive mb-2'>
                        Fermenters and Bioreactors
                    </h4>
                    <p className='commonParaThree'>
                        Fermenters and bioreactors are systems used in laboratories to produce organic materials like cells,
                        bacteria, yeast, enzymes, and much more. Bioreactors are used in a variety of applications, ranging from
                        pharmaceuticals to food additives to biofuels to water treatment processes and beyond. Air is needed for
                        culture growth, and pure oxygen is often chosen to speed up the process and increase density. The gas
                        must be dissolved into the medium the culture is being grown in, and the higher the cell density and the
                        faster growing the culture, the more oxygen required. Like most oxygen applications, levels must be
                        carefully measured and monitored to ensure optimal absorption without unnecessary waste.
                    </p>
                    <br /><br />

                    <h4 className='commontitleTwo text-center mb-2'>
                        Measuring Oxygen Flow with a Thermal Mass Flow Meter
                    </h4>
                    <p className='commonParaThree'>
                        With oxygen gas and air so crucial to so many industrial processes, it’s important that users get their
                        distribution right. Improper feed rates in combustion, aeration, and bioreactors result in less efficient
                        processes with poor output and higher costs – a double loss. Improper airflow in HVAC applications
                        doesn’t just lead to higher costs, it can also jeopardize the health of workers – something far more
                        important than money.<br /><br />
                        In all of these cases, users need a way to measure air and oxygen gas flow that is extremely accurate,
                        highly repeatable, and cost-effective – three traits perfectly describing thermal mass flow meters.
                    </p>

                    <h4 className='commontitlefive mb-2'>
                        Thermal Mass Flow Meters Ensure Accurate Oxygen and Air Distribution.
                    </h4>
                    <p className='commonParaThree'>
                        Thermal mass flow meters are one of the most accurate ways of measuring air and oxygen flow,
                        generally offering error rates of less than 1% across a meter’s entire operating range. Thermal mass flow
                        meters are also designed specifically to handle a wide range of flow rates, allowing them to maintain their
                        pinpoint accuracy even in applications with lower flows, like HVAC ducting. Their simple design also
                        ensures downtime is kept to an absolute minimum, and accurate measurements can be taken with a
                        degree of consistency and repeatability unmatched by other metering systems.
                    </p>

                    <h4 className='commontitlefive mb-2'>
                        Thermal Mass Flow Meters Provide Uninterrupted Flow
                    </h4>
                    <p className='commonParaThree'>
                        Ensuring air and oxygen gas flow can be properly measured is crucial, but if the metering equipment
                        interrupts the flow in any meaningful way, the entire process becomes counterproductive. The probes in
                        thermal mass flow meters are extremely low-profile, creating no meaningful resistance and allowing flow
                        to pass by them effectively undisturbed. That makes them perfect for applications like combustion and
                        aeration, where it’s critical to success that flow into the system be as consistent and pinpoint-accurate as
                        possible.
                    </p>

                    <h4 className='commontitlefive mb-2'>
                        Thermal Mass Flow Meters are Extremely Cost-Effective
                    </h4>
                    <p className='commonParaThree'>
                        Thermal mass flow meters offer a number of cost benefits based on their ease of installation and their
                        extremely low maintenance requirements. Whether a plant is looking for a permanently fixed inline system
                        or a more temporary insertion style meter, the simple design of thermal mass flow meters allows them to
                        be quickly and easily installed using flanges or threading for inline meters and simple ports for insertion
                        meters. And, because thermal mass flow meters contain no moving parts, they’re exceptionally reliable
                        and require almost no maintenance once installed and operational.
                    </p>
                    <br /><br />

                    <h4 className='commontitleTwo text-center mb-2'>
                        Let EPI Thermal Mass Flow Meters Help You More Accurately Measure Your Air and Oxygen Gas Flows
                    </h4>
                    <p className='commonParaThree'>
                        At Eldridge Products Inc., we’ve been helping our clients accurately measure gas flows for over three
                        decades. Our full line of inline and insertion thermal mass flow meters and easy-to-use control software
                        have been used by thousands of clients around the globe to improve their processes and save money
                        through better measurement and control of their gasses.
                        <br /><br />
                        If you’re looking for a way to accurately, consistently, and cost-effectively measure your air or oxygen gas
                        flow, we’d love to show you why an EPI Thermal Mass Flow Meter is the ideal tool for the job. <a href="/contactus">Reach out to a member of the EPI team</a> today to find out more!
                    </p>
                </Container>
            </div>
        </>
    )
}

export default Oxygen
