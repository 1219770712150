import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useSwr from 'swr';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import fetcher from 'utils/fetcher';
import img1 from '../../assets/img/images/meter-img.png'
import { Modal } from "reactstrap";

import RegisterForm from './RegisterForm';
import ProductView from './ProductView';

function RegisterNewProduct() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const { REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT: apiUrl } = process.env;
    const page = { RegisterNewProduct: true }
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [viewProductData, setViewProductData] = useState(false);
    const [modalLive, setModalLive] = useState(false);
    const [modelViewProduct, setModalViewProduct] = useState(false);

    const { data } = useSwr(`/api/me`, fetcher);
    useEffect( async() => {
        if(data){
            setUserData(data);
        }

        try {
            const { data: meters } = await axios.get(
                `${apiUrl}/api/meters/all`,
                { withCredentials: true }
            )
            setProductsData(meters)
        } catch (e) {
            console.log(e.message);
        }
    }, [data]);

    const headerData = {
        firstname: userData.firstname,
        page: 'REGISTER PRODUCTS'
    }

    const viewProduct = (data) => {
        setViewProductData(data);
        setModalViewProduct(true);
    }
    
    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page} />
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page} /></div>) : undefined}
                    <div className='common-main'>
                        <div className='position-relative'>
                            <h2 className='p-3'>Products</h2>
                            <div className='registered-new' onClick={() => setModalLive(true)}>
                                <FontAwesomeIcon icon={faPlus} className='fa-plus'></FontAwesomeIcon>
                                <span>Registered New</span>
                            </div>
                        </div>
                        <div className='quotes body p-3'>
                            <p>View a list of any and all EPI products that you have added under your account. You can also add more products as you
                                receive them.
                            </p>
                            {productsData.length === 0 && (<hr />)}
                            <div>
                                { productsData.map((value) => {
                                    return(
                                        <>
                                            <hr />
                                            <div className='row' onClick={ () => viewProduct(value) }>
                                                <div className='col-md-6 col-sm-12'>
                                                    <div className='row'>
                                                        <div className='col-3 d-flex align-items-center justify-content-center'>
                                                            <img src={img1} className='pt-img' alt="" />
                                                        </div>
                                                        <div className='col-9 d-flex align-items-center'>
                                                            <div>
                                                                <p className='pt-title'>Series 9800 MPNH</p>
                                                                <p className='pt-body'>SN: 1234-5678-9012-3456</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 col-sm-12 d-flex align-items-center'>
                                                    <p className='pt-right-text'>Install Location: { value.install_adress_one }</p>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal toggle={() => setModalLive(false)} isOpen={modalLive}>
                <div className="modal-header">
                    <h2 className="modal-title" id="exampleModalLiveLabel">
                        Register a New Meter
                    </h2>
                    <button
                        aria-label="Close"
                        className="close"
                        type="button"
                        onClick={() => setModalLive(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <RegisterForm />
            </Modal>

            <Modal toggle={() => setModalViewProduct(false)} isOpen={modelViewProduct}>
                <div className="modal-header">
                    <h2 className="modal-title" id="exampleModalLiveLabel">
                        Registered Meter
                    </h2>
                    <button
                        aria-label="Close"
                        className="close"
                        type="button"
                        onClick={() => setModalViewProduct(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <ProductView data={viewProductData} />
            </Modal>
        </div>
    )
}

export default RegisterNewProduct
