import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import img from '../../assets/img/images/MeterConfigurationsImg1.png';
import { Helmet } from "react-helmet";

import TechnicalInfoFooter from 'components/Footers/TechnicalInfoFooter';

function MeterConfigurations() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Flow Meter Configurations</title>
            <meta name="description" content="Learn about the different types of flow meter enclosures and mounting styles in EPI’s in-depth article written for you! " />
            <link rel="canonical" href="https://epiflow.com/technicalinfo/meterconfigurations" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner MeterConfSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/meterconfigurations" tag={Link}>Meter Configurations</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Meter Configurations</h2>
                        <p className='cover-title-p'>
                            Learn about the different types of flow meter enclosures and mounting styles below.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo text-center mb-2'>
                        Configurations for Inline, Insertion, Remote, & Integral Style Meters
                    </h4>
                    <p className='commonParaThree text-center pb-5'>
                        When selecting a flow meter from Eldridge Products, there are a number of physical configurations available to you:
                    </p>
                    <div className='MConfCard-main mt-2'>
                        <Row>
                            <Col md="2">
                                <div>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2FMeterConfigurations%2Finline.png?alt=media&token=1bfaf91c-86ea-4249-a3c7-e85ddb187687" alt="Inline Style Mounting Configuration" />
                                </div>
                            </Col>
                            <Col md="10">
                                <h4 className='commontitlefive mb-2'>Inline Style Mounting</h4>
                                <p className='commonParaThree'>
                                    Thermal mass flow meters for inline style mounting include a flow section that is usually specified to match the user’s flow conduit and is then plumbed directly into the process line. This design has the sensing elements mounted directly in the flow section for exposure to the process gas. Our inline style thermal mass flow meters are available in sizes from 1/4″ pipe through 4″ pipe or tube, and are provided with a variety of options such as MNPT ends, tube end fittings, butt weld ends, flanged end configurations, etc. as required. Pipe sizes in excess of 4″ typically require insertion style thermal mass flow meters although EPI™ has manufactured inline style flow meters for pipes as large as 8″.
                                </p>
                            {/*     <button className='button1'>View All Inline Meters</button>
                                <button className='button2 px-5 ms-3'>More Info</button> */}
                            </Col>
                        </Row>
                    </div>
                    <div className='MConfCard-main mt-2'>
                        <Row>
                            <Col md="2">
                                <div>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2FMeterConfigurations%2Finsertion.png?alt=media&token=46fa6971-1f50-403f-a90d-bda84f01a77b" alt="Insertion Style Mounting Configuration" />
                                </div>
                            </Col>
                            <Col md="10">
                                <h4 className='commontitlefive mb-2'>Insertion Style Mounting</h4>
                                <p className='commonParaThree'>
                                Thermal mass flow meters for insertion style mounting include a sensor & probe assembly that is inserted into the process gas flow conduit to allow the process gas to flow across the flow sensing elements. Our insertion style flow meters are available with 1/2″, 3/4″, or 1″ OD probes. Tube fittings and ball valve retractor assemblies, with or without a mounting flange, are also available from the factory as options. The tube length must be specified upon ordering. Standard lengths range from a minimum of 6″ to a maximum of 36″. For other probe diameters and lengths, please consult the factory.
                                </p>
                            {/*     <button className='button1'>View All Insertion Meters</button>
                                <button className='button2 px-5 ms-3'>More Info</button> */}
                            </Col>
                        </Row>
                    </div>
                    <div className='MConfCard-main mt-2'>
                        <Row>
                            <Col md="2">
                                <div>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2FMeterConfigurations%2Fintegral.png?alt=media&token=d81ae842-f268-483b-97d3-59640891d21b" alt="Integral Style Electronics" />
                                </div>
                            </Col>
                            <Col md="10">
                                <h4 className='commontitlefive mb-2'>Integral Style Electronics</h4>
                                <p className='commonParaThree'>
                                  Thermal mass flow meters with integral electronics have all of the electrical components and connections located within one enclosure. This enclosure may be rated for either hazardous environments MP Series or for ordinary, non-hazardous environments MPNH Series, as necessary. The enclosure is mounted directly to the inline flow section or to the insertion probe assembly at the point of measurement. The enclosure includes the all the electrical connections as well as the linearizing electronics and the display/keypad assembly.
                                </p>
                            {/*     <button className='button1'>View All Integral Style Meters</button>
                                <button className='button2 px-5 ms-3'>More Info</button> */}
                            </Col>
                        </Row>
                    </div>
                    <div className='MConfCard-main mt-2'>
                        <Row>
                            <Col md="2">
                                <div>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2FMeterConfigurations%2Fremote.png?alt=media&token=fe83dc79-cd2e-455b-9c94-9d347f490105" alt="Remote Style Electronics" />
                                </div>
                            </Col>
                            <Col md="10">
                                <h4 className='commontitlefive mb-2'>Remote Style Electronics</h4>
                                <p className='commonParaThree'>
                                    Thermal mass flow meters with remote electronics utilize two enclosures. The flow transmitter enclosure is mounted at the point of measurement — on the flow section or on the probe assembly. This enclosure may be rated for either hazardous environments or for ordinary, non-hazardous environments, as necessary. In our standard configuration, the second (remote) enclosure is usually placed in a readily accessible location rated for non-hazardous conditions. Our optional ACEX (AC power) and DCEX (DC power) enclosures carry the same area classification as the corresponding flow transmitter enclosure. The remote enclosure includes the all the electrical connections as well as the linearizing electronics and the display/keypad assembly. Depending upon the application requirements, a two-, four- or six-wire, twisted-pair cable is required to carry the input power and flow signal between the two enclosures.
                                </p>
                            {/*     <button className='button1'>View All Remote Style Meters</button>
                                <button className='button2 px-5 ms-3'>More Info</button> */}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            {/* section-2 */}
            {/* section-3 */}
                <TechnicalInfoFooter />
            {/* section-3 */}
        </>
    )
}

export default MeterConfigurations
