import React from "react";

import { Input } from "reactstrap";

function CommunicationsOptions({ MakeChangeDropdown, state, checkState }){
  
  const Options = () => {
    if(checkState(state.input_power_communication.input_power, 'vdc_12_18')){
      return(
        <>
          <option selected={checkState(state.input_power_communication.communications_options, 'standard')} value="standard">Standard (MODBUS RTU)</option>
          <option selected={checkState(state.input_power_communication.communications_options, 'bacnet')} value="bacnet">BACnet</option>
        </>
      )
    } else {
      return(
        <>
          <option selected={checkState(state.input_power_communication.communications_options, 'standard')} value="standard">Standard (MODBUS RTU)</option>
          <option selected={checkState(state.input_power_communication.communications_options, 'hart')} value="hart">HART</option>
          <option selected={checkState(state.input_power_communication.communications_options, 'bacnet')} value="bacnet">BACnet</option>
          <option selected={checkState(state.input_power_communication.communications_options, 'profibus')} value="profibus">Profibus</option>
        </>
      )
    }
    
  }

  return(
    <>
      <Input
        className="epiInputSize"
        id="exampleFormControlSelect1"
        type="select"
        onChange={ (e) => MakeChangeDropdown({
          section: 'input_power_communication',
          type: 'communications_options',
          values: [
            'standard',
            'hart',
            'bacnet',
            'profibus'
          ],
          price_effect: false,
          change_effect: 'communications',
          option: e,
        }, e)}
      >
        <Options />
      </Input>
    </>
  )
}

export default CommunicationsOptions;