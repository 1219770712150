import React from 'react'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Row, Col } from 'reactstrap';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function DdAccordation({data}) {
    const [expanded, setExpanded] = React.useState('panel0');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <Row>
            { data.documents_downloads.datasheets.map((value, index) => {
                return(
                    <Col>
                        <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <Typography>{value.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <table className='ddacordation-table'>
                                        <thead>
                                            <tr>
                                                <th width="60%">TITLE</th>
                                                <th width="20%">VERSION</th>
                                                <th width="20%">RELEASE DATE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {value.files.map((value) => {
                                                return(
                                                    <tr>
                                                        <td><a target="_blank" href={ value.file_link }>{ value.file_title }</a></td>
                                                        <td>{ value.version }</td>
                                                        <td>{ value.release_data }</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>

                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Col>
                )
            })}
        </Row>
    )
}

export default DdAccordation
