import React, { useEffect, useState } from 'react';
import useSwr from 'swr';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import { Card, CardBody, CardText, CardTitle, Col, Container, Row, Button, NavLink } from 'reactstrap'
import { Helmet } from "react-helmet";
import fetcher from 'utils/fetcher';
import TechSupportFooter from 'components/Footers/TechSupportFooter'


function ServiceRequestNew() {
    const [auth, setAuth] = useState(false);
    const { data } = useSwr(`/api/me`, fetcher);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if(data){
            setAuth(true);
        }
    }, [data]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>EPI – Service Requests </title>
                <meta name="description" content="Get started servicing or repairing your flow meter, find the components and hardware you require, or get help directly from our service manager." />
                <link rel="canonical" href="https://epiflow.com/support/servicerequest" />
            </Helmet>
            {/* section-1 */}
            <div className='commonbanner SRNSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/support" tag={Link}>Support</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/support/servicerequest" tag={Link}>Service Requests</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Service Requests</h2>
                        <p className='cover-title-p'>
                            Get started servicing or repairing your flow meter, find the components and hardware you require, or get help directly from our service team.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2   <HipporelloEmbed formId="3692b3933f034e8699511591be42d01c"/> */}

            <TechSupportFooter />

            {/* section-2 */}
            <div className='pt-5 pb-5'>
                <Container>
                    <h3 className='commontitleOne pt-5 mb-4 text-center'>Your meter's best friend.</h3>
                    <Row>
                        <Col>
                            <Card className='card-shadow-none'>
                                <CardBody className='text-center p-4'>
                                    <CardTitle className='commontitlefive'>
                                        RMA Request
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2'>
                                        Within 24 hours, you will get an RMA form along with shipping instructions (weekdays). Following a complete evaluation of your flow meter, you will be informed of any required repair work.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='card-shadow-none'>
                                <CardBody className='text-center p-4'>
                                    <CardTitle className='commontitlefive'>
                                        Calibration Service
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2'>
                                        Let the flow experts who know your meter best calibrate and ensure your process gas measurement accuracy.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='card-shadow-none'>
                                <CardBody className='text-center p-4'>
                                    <CardTitle className='commontitlefive'>
                                        Hardware & Parts
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2'>
                                        Find all the components and hardware you'll need to get your meter up and running again.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='card-shadow-none'>
                                <CardBody className='text-center p-4'>
                                    <CardTitle className='commontitlefive'>
                                        Service Manager's Questions
                                    </CardTitle>
                                    <CardText className='commonParaThree mt-2'>
                                        Get immediate answers from the EPI service manager to any questions you may have.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ServiceRequestNew
