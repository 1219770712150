import React, { useState, useEffect } from "react";

// core components
import ProductHeader from "components/Headers/ProductHeader";
import ProductViewNavbar from "components/Navbars/ProductViewNavbar";
import ProductView from "components/ProductViews/Components/ProductView";
import ProductDescription from "components/ProductViews/Components/ProductDescription";
import _200CCustomizer from "components/Customizer/Views/Xt/XtInsertion/200CCustomizer";
import MultigenCustomizer from "components/Customizer/Views/Multigen/MultigenInsertion/MultigenCustomizer";
import TechSpecs from "views/TechSpecs/TechSpecs";
import CADFiles from "views/CADFiles/CADFiles";

function MultigenProductView({productType, tab}){
  const [page, setPage] = useState('discover');

  useEffect(() => {
    if(tab){
      if(tab === 'disc'){
        setPage('discover');
      }
  
      if(tab === 'specs'){
        setPage('specs');
      }
  
      if(tab === 'cad'){
        setPage('cad');
      }  
    }
  }, [tab]);
  
  const productImage = "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FMulti%2FInsertion%2FMulti%2FSeries-9000-MP-Multipoint-Hero.png?alt=media&token=95b0cecb-66a6-48c8-a2a0-b2ab8f93566e";

  const navbarData = {
    product_title: 'SERIES 9000MP MultiGEN',
    product_title_old: 'SERIES 9000MP',
    product_type: 'INSERTION THERMAL MASS FLOW METER'
  }

  const productInfo = {
    model: '9000MP',
    series: navbarData.product_title
  }

  const images = [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FMulti%2FInsertion%2FMulti%2FProductImages%2FSeries-9000-MP-Multipoint.jpg?alt=media&token=85938d8b-4537-4427-8fa9-b95933b31bf7",
      altText: "Series 9000MP Multipoint Insertion Flow Meter",
      productImageLink: '/productimages/multi_insertion'
    }
  ];

  const productViewData = {
    title: 'Master-Touch™ Series 9000MP Multipoint Insertion Flow Meters',
    description_small: `
    If you require advanced multipoint measurements of thermal mass flow, the Master-Touch Series 9000MP Multipoint thermal mass flow meters are the essential choice for you.
    <br /><br />
    Able to effectively function in any large air intake ducts, air exhausts, or flue stacks, these dynamic and advanced thermal mass flow meters deliver unparalleled service. Perfect for large industry settings and currently in use at many pulp and paper mills and power source companies across the world, the Series 9000MP is the pinnacle of large-scale thermal mass meters.
    <br /><br />
    A unique feature of the Series 9000MP systems is that they can be provided with an optional Air Purge System, which provides a simple way to clean the sensors. This is completely dynamic, giving you control over duration and frequency with EPI’s state-of-the-art Master-Touch Software.
    <br /><br />
    <ul>
      <li>Perfect for large scale industry operations</li>
      <li>Multiple probe system for reliability</li>
      <li>Replaceable and removable sensors</li>
      <li>Constant and Trustworthy monitoring</li>
    </ul>
    `,
    description: `
    Master-Touch™ products include a rugged, cleanable, thermal mass flow sensor. These units consist of a sensor assembly which utilizes two RTD (Resistance Temperature Detector) sensing elements. The sensors are constructed of reference grade platinum, ceramic, glass, and stainless steel. Two platinum resistance sensors are built up upon the ceramic substrate and then given a thin glass coating. The assembly is then slipped into a stainless-steel sheath for corrosion and abrasion resistance. The sensor assembly is large, rugged, and relatively insensitive to dirt buildup. During operation, the temperature sensor constantly measures the ambient temperature of the gas and maintains a reference resistance on one side of a bridge circuit. The second sensor is forced through self-heating to a constant temperature above that of the gas stream and is controlled by the temperature sensor and bridge circuitry. Our bridge maintains the overheat temperature and counterbalances the temperature effects through our proprietary temperature compensation techniques.
    <br /><br />
    9000MP Series Multipoint have not been submitted for agency approval testing.
    <br /><br />
    Multipoint Systems are designed to measure gas flows where two or more sensing points are required due to large cross-sectional areas, such as large air intake ducts or air exhaust and flue stacks.
    <br /><br />
    Our Series 9000MP Multipoint Systems are installed throughout the world, providing our customers with years of steady, reliable service. Coupled with our new Air Purge System, the Series 9000MP Multipoint Systems are now well-suited to an even greater variety of industrial applications. The probe design supports applications as diverse as ambient air flows in HVAC ducts, exhaust gas in large diameter stacks, boiler NOx efficiency systems and municipal waste incinerators. Our customers include environmental/HVAC system integrators, pulp & paper mills, power & energy companies, etc. across the US, Europe and Asia. The configuration and installation options support facility retrofits as well as the most modern new construction requirements.
    <br /><br />
    <h5>Configuration</h5>
    The Series 9000MP Multipoint System includes one or more Series 9000MP Probes and a Series 9600MP System Control Panel. The 9000MP flow transmitter probe assembly is the heart of the system. The probe assembly typically has two or more flow sensors mounted in a 1½” OD probe shaft. The 9000MP probe’s sensors are removable for field replacement if one is damaged. Each sensor is matched to its own bridge board and microprocessor to function, in effect, as a complete flow meter. The linearized output signals from the multiple sensor assemblies in each probe are averaged to create one overall probe output signal.
    <br /><br />
    The probe’s averaged output signal is transmitted to a Series 9600MP System Control Panel (SCP). If more than one probe is installed, the output signals from all the probes are transmitted to the SCP for averaging to create one system output. The System Control Panel (SCP) is housed in a 12″ x 10″ NEMA 4X fiberglass enclosure. The SCP provides the power for the probe assemblies and receives the averaged outputs over a four-wire cable to each probe. It also includes its own microprocessor assembly for system-level control and flow display. The SCP provides two analog output signals (0–5 VDC and 4–20 mA), as well as RS232 & RS485 Modbus RTU communications. The 4–20 mA grand average output can drive up to 1200 ohms.
    <br /><br />
    <h5>Optional Air Purge System</h5>
    The optional Air Purge System (APS) provides a means of cleaning the sensors in applications where particulates cause problems. The frequency and duration of the purge cycle can be controlled externally or by using the Master-Touch™ software. The system can also be set to maintain the flow rate at its level when the purge was activated. This important option preserves the integrity of the flow rate and elapsed total data which might otherwise be compromised by the flow of the purge gas.
    <br /><br />
    Thermal mass flow meters use the principle of convective heat transfer to directly measure mass flow. EPI’s proprietary thermal mass flow sensors use two ratiometrically-matched, reference-grade platinum Resistance Temperature Detectors (RTDs). The platinum sensing element wire is encapsulated in a 316 Stainless Steel sheath or, if specified, a Hastelloy C sheath. Our microcontroller operated smart sensor technology preferentially heats one RTD; the other RTD acts as the temperature reference. The process gas flow dissipates heat from the first RTD, causing an increase in the power required to maintain a balance between the RTDs. This increase is directly related to the gas molecular rate of flow. Our sensors are temperature compensated for a wide process gas temperature range and insensitive to pressure changes, so the output signal is a true mass flow rate signal.
    <br /><br />

    `,
    meter_type: 'Insertion',
    enclosure: 'Integrated Electronics',
    warranty: '1-4',
    certifications: 'CE'
  }

  const techSpecsData = {
    features:{
      titleOne: 'Series 9000 Multi-Point Enclosures',
      highlightOneImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FProductFeatures%2Ffeatures-multi.png?alt=media&token=c38b7f8c-f8a1-4323-b4d6-a358b8423fed',
      highlightOneInfo: `EPI’s MULTI series sensor enclosure is highly durable and can be used in wet, corrosive and high temperature environments.
      <br /><br />
      <ul>
        <li>Stainless steel continuous hinge and stainless steel corner screws</li>
        <li>Maintenance free and corrosion resistant</li>
        <li>Gaskets are made from continuously poured polyurethane foam</li>
        <br />
        The remote enclosure is also durable and watertight.
          <br /><br />
          <ul>
            <li>Flat cover with bonded window</li>
            <li>Stainless steel continuous hinge and stainless steel corner screws</li>
          </ul>
      </ul>
      `,
      titleTwo: 'Superior Flow Measurement and Calibration',
      highlightTwoImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ffeatures-image-2.png?alt=media&token=86df9cb6-22d4-4fbc-bc7f-f6b4e267b8ec',
      highlightTwoPdf: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2FCalibration%20check%20(1).pdf?alt=media&token=9bed13f4-7edb-42b2-b660-27d90cadb7ca',
      highlightTwoInfo: `EPI flow meters provide accurate, consistent, and rapid real-time flow measurement with a sensor that
      is both temperature and pressure corrective. Each flow meter is calibrated using standards traceable to
      the National Institute of Standards and Technology (NIST).
      <br /><br />

      -Accuracy: +/- (1% of reading + 0.5% of full scale + GTC) <br />
      -Repeatability: +/- 0.2% of full scale <br />
      -Sensor response time: 1 second to 63% of final value <br />
      -Gas temperature coefficient (GTC): 0.02% full scale/°C <br />
      -Gas pressure effect: negligible over +/-50% of factory calibration pressure
      `
    },
    our_process: {
      benefits:[
        {
          title: 'Broad Velocity Range',
          info: `Up to 60,000 SFPM (80,000 SFPM with Attenuation Rod or Particle Shield)`
        },
        {
          title: 'Low flow sensitivity',
          info: `100:1 Turn down ratio; 10 SFPM (0.05 NMPS) minimum reading.`
        },
        {
          title: 'Wide temperature range',
          info: `Sensor suitable for process gas temperatures up to 392°F (200°C).`
        },
        {
          title: 'Multiple analog outputs',
          info: `4-20mA and 0-5VDC outputs for both flow and temperature`
        },
        {
          title: 'Various communication protocols supported',
          info: `RS485 Modbus RTU (standard), HART, BACnet, and Profibus DP`
        },
        {
          title: 'NIST traceable calibration facility',
          info: `Over 30 years of flow calibration expertise`
        },
        {
          title: 'Ease of installation',
          info: `Various configurations to meet your installation requirements`
        },
        {
          title: 'Live Zero',
          info: `Live Zero (EPIVal) to validate your flow meter calibration in both process line and ambient air
          conditions`
        }
      ]
    },
    specifications: {
      specs: [
        {
          title: 'Approvals',
          info: `No Agency Certifications`
        },
        {
          title: 'Linear signal output',
          info: `0–5 VDC & 4–20 mA (Flow and Temperature)`
        },
        {
          title: 'Event Relay Rating',
          info: `One with Frequency or two, 1 Amp @ 30Vdc (33W)`
        },
        {
          title: 'Signal Interface',
          info: `RS232 & RS485 Modbus RTU`
        },
        {
          title: 'LCD',
          info: `(flow rate, flow total, gas temperature)`
        },
        {
          title: 'Accuracy, including linearity (Ref.: 21°C)',
          info: `±(1% of Reading + 0.5% of Full Scale + GTC)`
        },
        {
          title: 'Repeatability',
          info: `±0.2% of Full Scale`
        },
        {
          title: 'Sensor response time',
          info: `1 second`
        },
        {
          title: 'Turn down ratio',
          info: `100:1 @ 10 SFPM (.051 NMPS) Minimum Reading`
        },
        {
          title: 'Electronics internal temperature range',
          info: `0° to 50°C (32° to 122°F) Standard, -40° to 60°C (-40° to 140°F) Optional temperature range`
        },
        {
          title: 'Gas temperature range*',
          info: `-40°–392°F (-40°–200°C), consult factory for extended ranges`
        },
        {
          title: 'Gas temperature coefficient (GTC)',
          info: `0.02% Full Scale/°C`
        },
        {
          title: 'Pressure rating maximum.',
          info: `25 PSI Std.`
        },
        {
          title: 'Input power requirement',
          info: `24VDC @ 250mA, 115 VAC 50/60 Hz optional, 230 VAC 50/60 Hz optional`
        },
        {
          title: '9600MP Control Panel power requirements',
          info: `5 watts maximum`
        },
        {
          title: '9600MP Control Panel enclosure',
          info: `NEMA 4X fiberglass, 11.5″ x 13″ x 6″`
        },
        {
          title: '9000MP Probe Assembly enclosure',
          info: `NEMA 4X fiberglass, 9.13″ x 11.13″ x 5.5″ flanged`
        },
        {
          title: 'Wetted materials',
          info: `316 Stainless Steel (Hastelloy optional)`
        },
        {
          title: 'Standard temperature & pressure (STP)',
          info: `70°F & 29.92″ Hg (Air .075 lb./cubic foot)`
        },
        {
          title: 'NIST traceable calibration',
          info: `Standard`
        },
        {
          title: 'NOTE',
          info: `Specifications subject to change without notice.`
        }
      ]
    },
    communication_config: {
      type: 'multigen',
      comm_config: [
        {
          output: '4-20MA FLOW (SELF OR ISOLATEDLOOP POWERED)',
          standard: true,
          hart: true,
          bacnet: true,
          profibus: true
        },
        {
          output: '0-5 OR 0-10 VDC FLOW',
          standard: true,
          hart: false,
          bacnet: true,
          profibus: true
        },
        {
          output: '4-20MA TEMPERATURE',
          standard: true,
          hart: true,
          bacnet: true,
          profibus: true
        },
        {
          output: '0-5 OR 0-10 VDC TEMPERATURE',
          standard: true,
          hart: true,
          bacnet: true,
          profibus: true
        },
        {
          output: 'FREQUENCY 0-1KHZ',
          standard: false,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: 'MODE SWITCH (RANGES)',
          standard: true,
          hart: true,
          bacnet: true,
          profibus: true
        },
        {
          output: '1 MECHANICAL RELAY',
          standard: true,
          hart: true,
          bacnet: true,
          profibus: true
        },
        {
          output: '2 MECHANICAL RELAY	',
          standard: true,
          hart: true,
          bacnet: true,
          profibus: true
        },

        {
          output: 'RS 485/MODBUS (R/W)',
          standard: true,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: 'RS 232/MODBUS (R/W)',
          standard: true,
          hart: true,
          bacnet: true,
          profibus: true
        },
        {
          output: 'HART (READ ONLY)',
          standard: false,
          hart: true,
          bacnet: false,
          profibus: false
        },
        {
          output: 'PROFIBUS (READ ONLY)',
          standard: false,
          hart: false,
          bacnet: false,
          profibus: true
        },
        {
          output: 'BACNET (READ ONLY)',
          standard: false,
          hart: false,
          bacnet: true,
          profibus: false
        },
      ]
    },
    geometry_sizing: {
      cadImg: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FMULTI.png?alt=media&token=f20448c9-1e8d-496d-8851-d3b93dbc0a58',
      cadImg2: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FMULTI-2.png?alt=media&token=a4be6def-43a6-48bd-9f09-0aaa7544e951',
      cadImg3: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FMULTI-3.png?alt=media&token=5ec335ae-f183-408a-8b9d-6ee0fa8fc236',
      sizing: [
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        }
      ]
    },
    tech_support:{
      faq: [
        {
          title: 'Wiring',
          info: `The wiring of your EPI flow meter will be based on the configuration selected when your order was placed. EPI flow meters can be
          configured for various AC or DC input power options. Both analog and digital output signals are also available depending on your model and
          options.`
        },
        {
          title: 'Orientation',
          info: `EPI flow meters can be mounted in a number of different orientations based on your requirements with some limited adjustability in the
          field.`
        },
        {
          title: 'Installation',
          info: `EPI flow meters do require minumum up and downstream straight run to ensure the flow profile can develop properly, providing you with
          the highest accuracy readings.`
        },
        {
          title: 'Communication/ Outputs',
          info: `Your EPI flow meter will come standard with RS485 Modbus and RS232 digital communication. We also offer optional BACnet, Profibus DP and
          HART communications protocols. EPI flow meters provide a 4-20mA and 0-5 VDC analog output signal for flow and
          temperature (based on specific models) as well as up to 2 relays that can be independently configured based on your requirements.
          `
        }
      ],
      maintenance_info: {
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
      }
    },
    installation: {
      video: '',
      image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FcomingSoon.png?alt=media&token=468c705b-6551-485a-80aa-dff248b53e86'
    },
    documents_downloads: {
      datasheets: [
        {
          title: 'Downloads',
          files: [
            {
              file_title: 'Product Brochure',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSeries%209000MP%20Multipoint.pdf?alt=media&token=68ecc494-4db0-49e6-a179-fdde37804771',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Meter Installation Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'EPITerm Software Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000003_RevA%20EPITerm%20Interface%20User%20Manual.pdf?alt=media&token=0e082343-3f69-476a-aaa0-fd344ece1885',
              version: '1.0.0',
              release_data: '2022'
            }
          ],
        }
      ],
    }
  }

  const cadData = {
    files: [
      {
        productName: '',
        modelLink: '',
        pdfLink: '',
        downloadLink: '',
        description: ``
      }
    ]
  }

  const ViewPages = () => {
    if(page === 'discover'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <ProductView productViewData={productViewData} images={images} />
          </div>
          <ProductDescription productViewData={productViewData} />
          <MultigenCustomizer
            productType={productType}
            productInfo={productInfo}
          />
        </>
      );
    }

    if(page === 'specs'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <TechSpecs data={techSpecsData} />
          </div>
        </>
      );
    }

    if(page === 'cad'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <CADFiles data={cadData} />
          </div>
        </>
      );
    }

    if(page === 'support'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <h1>Support</h1>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <ProductHeader productImage={productImage} />
      <div
        className="section section-about-us"
        style={{
          'background': 'whitesmoke',
          'padding-bottom': '50px',
          'border-bottom': 'whitesmoke'
        }}
      >
        <ProductViewNavbar
          navbarData={navbarData}
          page={page}
          setPage={setPage}
        />
      </div>
      <ViewPages />
    </>
  );
}

export default MultigenProductView;
