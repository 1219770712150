import React, { useState, useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import img1 from '../../assets/img/images/hvacImg1.png';
import img2 from '../../assets/img/images/hvacImg2.png';
import { Helmet } from "react-helmet";

function HVAC() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [color, setColor] = useState('#095CF6');
    const handleBtn = () => {
        setColor('#fff')
    }
    const handleBtnOut = () => {
        setColor('#095CF6')
    }
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – HVAC & Air Vent Meters</title>
            <meta name="description" content="Thermal Mass Flow HVAC meters are used in a wide variety of applications (factories, office, buildings, hotels, etc.): check out our in-depth article!" />
            <link rel="canonical" href="https://epiflow.com/whitepapers/hvac" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner HVACSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/whitepapers" tag={Link}>White Papers</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/whitepapers/hvac" tag={Link}>HVAC</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>HVAC & Air Vent Meters</h2>
                        <p className='cover-title-p'>Everything you need to know about EPI.</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <Container className='py-5'>
                <div className='DownloadPDfbtn-div'>
                    <div className='position-relative d-inline'>
                        <button
                            className='button2'
                            onMouseOver={handleBtn}
                            onMouseOut={handleBtnOut}
                        >
                            Download as PDF
                        </button>
                        <FontAwesomeIcon
                            className='DownloadPDfbtn-icon'
                            style={{ color: color }}
                        />
                    </div>
                </div>
                <h4 className='commontitleTwo text-center mb-4'>
                    Thermal Mass Flow HVAC Meters
                </h4>
                <p className='commonParaThree'>
                    Thermal Mass Flow HVAC meters are used in a wide variety of applications. <br /><br />
                    Virtually every commercial structure — factories, office buildings, hotels, etc. — has an air distribution system for heating, ventilation and air-conditioning (HVAC).
                    A well-designed, efficient HVAC system is critical to providing a comfortable and healthy environment for employees, visitors and guests. There are two broad categories
                    of HVAC systems: constant air volume flow systems (CAV) and variable air volume flow systems (VAV). Within these two categories are a variety of methods for generating and
                    balancing the air flow with an integrated system of fans, vents and controllers. As the size and complexity of these systems increase, so does the cost of proper operation
                    and maintenance. Taken together, these systems use roughly 40% of the electricity consumed in commercial buildings. Efficient system operations, with positive performance
                    benefits and a healthier environment, should be an important goal for any new installation, system upgrade or on-going maintenance program.
                    <br /><br />

                    Whether a building incorporates a constant volume flow system, a variable volume flow system, or a combination of both, an obvious and important consideration when designing
                    the HVAC vent air flow system is the flow of the air itself. Assuring that clean air for heating and cooling reaches everywhere it needs to be requires careful planning to
                    have an efficient and cost-effective network of ductwork, fans/blowers, control and dampening louvers, etc. for the distribution and control of the air flows throughout a
                    building. Unfortunately, the physical requirements for accurately measuring the air flow are rarely given the same degree of consideration before construction — even though
                    measurement accuracy is critical to every form of HVAC system.
                    <br /><br />

                    Air flow measurement in large ductwork is necessarily based on a very small sample that is used to represent an accurate measurement of the total air flow. However, good
                    accuracy is difficult to achieve with only a single point of measurement because the straight run of ductwork required to assure a well-developed, uniform flow profile isn’t
                    often available. Bends, piping transitions and control louvers all distort the air flow profile much like the flow of a river moving around bends, blockages and changes in elevation.
                    In most cases these distortions require a significant amount of straight run to resolve. Industry recommendations of 20, 30 or even 50 diameters of straight run are not uncommon
                    depending on the complexity of the ductwork upstream of the point of measurement. In the end, it often falls on the instrumentation itself to solve the problems of accurate measurement.
                    <br /><br />

                    Over the years this measurement challenge has most often been met by using flow meters that measure a differential pressure within their flow sensing assembly, such as averaging pitot
                    tubes or pitot tube arrays, and which then derive the flow velocity based on Bernoulli’s equation. These flow meters require a pressure gage or sensor to be connected across their
                    pressure ports for flow sensing. And, if mass flow rate outputs are required, differential pressure flow meters require additional instrumentation and readings to infer mass flow.
                    Manual or computer calculations incorporating physical process measurements such as absolute pressure, differential pressure, temperature and viscosity readings must be applied to the
                    output signal to obtain the correct Standard or Normal flow rate.
                    <br /><br />
                </p>
                <h4 className='commontitleTwo text-center my-4'>
                    EPI’s patented Flow Averaging Tubes™ (FAT™s)
                </h4>
                <Row>
                    <Col lg="8" md="6">
                        <p className='commonParaThree'>
                            Eldridge Products’ patented Flow Averaging Tube™ thermal vent air flow meters provide real-time mass flow rate measurement and elapsed flow totalization without the need for
                            additional instrumentation that can increase the cost and complexity of achieving good measurement results. By combining the advantages of an averaging pitot tube’s multiple
                            sampling ports to give a stable flow signal with the direct mass flow readings of thermal dispersion technology, the FAT™s provide accurate air flow measurements with an
                            unrivaled simplicity. In many installations, the upstream straight run can be as short as three diameters. Where the ductwork configuration creates very serious flow profile
                            irregularities, two or more FAT™ tubes may be used for reliable, repeatable air flow measurement. These may be positioned in an “X” pattern across a round duct or in parallel
                            across the diameter of a rectangular duct.
                        </p>
                    </Col>
                    <Col lg="4" md="6">
                        <div>
                            <img src={img1} className='w-100' alt="Installation graphic for EPI flow averaging tube flow meters" />
                        </div>
                    </Col>
                </Row>
                <h4 className='commontitleTwo text-center my-4'>
                    Single Point Arrays
                </h4>
                <Row>
                    <Col lg="8" md="6">
                        <p className='commonParaThree'>
                            If an installation is not optimal for the Flow Averaging Tube™ thermal flow meters, either due to hot (392°F) or particulate-laden air flow, single-point flow meters may be
                            used to achieve an averaged flow signal from multiple inputs. The sensor of each vent air flow meter is located around the circumference of a round duct at the correct depth
                            near the ISO Point of Average Flow, or in an equal area grid pattern for rectangular ducts.
                            <br /> <br />

                            With either the FAT™s or the single-point array, the output signals from the individual instruments can be averaged by the data acquisition system, or by adding an Eldridge
                            Model 9601MP System Control Panel (SCP) in the flow measurement scheme. The SCP, with its internal Modbus RTU network facilitating the system communications, provides a broad
                            set of features for access to the individual sensing points as well as system level flow rate, total and alarms.
                        </p>
                    </Col>
                    <Col lg="4" md="6">
                        <div>
                            <img src={img2} className='w-100' alt="Single point arrays graphic" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default HVAC;
