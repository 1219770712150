import React from "react";

// reactstrap components
import {
  Row,
  Col
} from "reactstrap";

function ProductDescription({productViewData}){
  return(
    <>
      <div className="section section-about-us">
        <Col className="ml-auto mr-auto" md="10">
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <h3 className="title text-center">Product Description</h3>
              <p dangerouslySetInnerHTML={ {__html: productViewData.description} } />
              <div id="meterOrientation_" style={{ 'padding': '30px'}}></div>
            </Col>
          </Row>
          <div className="separator separator-primary"></div>
        </Col>
      </div>
    </>
  );
}

export default ProductDescription;