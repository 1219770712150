import TotalCard from "components/Customizer/TotalCard";
import UpdateCard from "components/Customizer/UpdateCard";
import CustomizerPriceFooter from "components/Footers/CustomizerPriceFooter";

export const handleChangeMain = (props, data, setData) => {
  const { type, values, section, price_effect } = props;
  const price_effect_warranty = false;

  if(props.price_effect_warranty) {
    makeChange(
      props = {
        type: type,
        value: values[0],
        section: section,
        values: values,
        price_effect,
        price_effect_warranty: props.price_effect_warranty,
        meter_model_effect: props.meter_model_effect,
        meter_model_type: props.meter_model_type,
        meter_model_value: props.meter_model_value
      },
      data,
      setData
    );
    return
  }

  if(props.option_value && props.text_input){
    const option_value = props.option_value;
    const text_input = props.text_input;
    makeChangeText(
      props = {
        type: type,
        value: option_value,
        section: section,
        values: values,
        price_effect,
        text_input,
        price_effect_warranty: price_effect_warranty,
        meter_model_effect: props.meter_model_effect,
        meter_model_type: props.meter_model_type,
        meter_model_value: props.meter_model_value
      },
      data,
      setData
    );
    return
  }

  if(props.option_value) {
    const option_value = props.option_value;
    if(props.meter_model_effect){
      makeChange(
        props = {
          type: type,
          value: option_value,
          section: section,
          values: values,
          price_effect,
          price_effect_warranty: price_effect_warranty,
          meter_model_effect: props.meter_model_effect,
          meter_model_type: props.meter_model_type,
          meter_model_value: props.meter_model_value
        },
        data,
        setData
      );
      return
    }

    makeChange(
      props = {
        type: type,
        value: option_value,
        section: section,
        values: values,
        price_effect,
        price_effect_warranty: price_effect_warranty,
        meter_model_effect: false,
        meter_model_type: false,
        meter_model_value: false,
        meter_model_effect: props.meter_model_effect,
        meter_model_type: props.meter_model_type,
        meter_model_value: props.meter_model_value
      },
      data,
      setData
    );
    return
  }

  makeChange(
    props = {
      type: type,
      value: values[0],
      section: section,
      values: values,
      price_effect,
      price_effect_warranty: price_effect_warranty,
      meter_model_effect: props.meter_model_effect,
      meter_model_type: props.meter_model_type,
      meter_model_value: props.meter_model_value
    },
    data,
    setData
  );
}

export const makeChange = (props, data, setData) => {
  const { type, value, values, section, price_effect, price_effect_warranty } = props;
  const valuesObj = values.reduce((acc,curr)=> (acc[curr]=false,acc),{});

  if(!data[section][type][value]){
    valuesObj[value] = true;

    setData(prevState => ({
      ...prevState,
        [section]:{
          ...prevState[section],
            [type]: {
              ...prevState[type],
              valuesObj
            }
        }
    }));

    if(price_effect){
      const pricesObj = data[section][type + '_prices'];
      const valuePrice = pricesObj[value];
      console.log('Show me the data change value price 2.0 =>', pricesObj, valuePrice)
      setData(prevState => ({
        ...prevState,
          ['total']:{
            ...prevState['total'],
            [type]: valuePrice
          }
      }));
    }

    if(price_effect_warranty){
      const pricesObj = data[section][type + '_prices'];
      const valuePrice = pricesObj[value];
      console.log('Show me the data change value price 2.0.1 =>', pricesObj, valuePrice)
      setData(prevState => ({
        ...prevState,
          ['warranty']:{
            ...prevState['warranty'],
            [type]: valuePrice
          }
      }));
    }

    if(props.meter_model_effect){
      const { meter_model_type, meter_model_value } = props;
      console.log('Show me data meter effect 1.0 =>', meter_model_type, meter_model_value);
      setData(prevState => ({
        ...prevState,
        ['model_number']:{
          ...prevState['model_number'],
          [meter_model_type]: meter_model_value
        }
      }));
      setData(prevState => ({
        ...prevState,
        ['model_number_state']:{
          [meter_model_type]: meter_model_value
        }
      }));
    }
    console.log('Show me the data change 2.0 =>', data, value, props)
  }
}

export const makeChangeText = (props, data, setData) => {
  const { type, value, values, section, price_effect } = props;
  const valuesObj = values.reduce((acc,curr)=> (acc[curr]=false,acc),{});

  if(props.text_input){
    valuesObj[values[0]] = value;
  }

  setData(prevState => ({
    ...prevState,
      [section]:{
        ...prevState[section],
          [type]: {
            ...prevState[type],
            valuesObj
          }
      }
  }));

  if(price_effect){
    const pricesObj = data[section][type + '_prices'];
    const valuePrice = pricesObj[value];
    setData(prevState => ({
      ...prevState,
        ['total']:{
          ...prevState['total'],
          [type]: valuePrice
        }
    }));
  }

  if(props.meter_model_effect){
    const { meter_model_type, meter_model_value } = props;
    setData(prevState => ({
      ...prevState,
      ['model_number']:{
        ...prevState['model_number'],
        [meter_model_type]: meter_model_value
      }
    }));
    setData(prevState => ({
      ...prevState,
      ['model_number_state']:{
        [meter_model_type]: meter_model_value
      }
    }));
  }
}

export const getKeyByValueMain = (object, value) => {
  if(object.valuesObj){
    const object_ = object.valuesObj;
    return Object.keys(object_).find(key => object_[key] === value);
  }
  return Object.keys(object).find(key => object[key] === value);
}

export const checkStateMain = (checkData, position) => {
  if(checkData?.valuesObj){
    if(checkData.valuesObj && checkData.valuesObj[position]){
      return checkData.valuesObj[position]
    }
  }

  if(checkData && checkData[position]){
    return checkData[position]
  }
}

export const sum = (obj) => {
  var sum = 0;
  for( var el in obj ) {
    if( obj.hasOwnProperty( el ) ) {
      sum += parseFloat( obj[el] );
    }
  }
  return sum;
}

export const warrantyPercentageMain = (data) => {
  if(data.meter_type.type === 'insertion'){
    var remote_cable_price = 10 * 5;
    if(data.meter_housing.remote_cable.valuesObj){
      var remote_cable_price = data.meter_housing.remote_cable.valuesObj.feet * 5;
    }  
    var total = sum(data.total) + remote_cable_price;
  }

  var total = sum(data.total);
  const percentage = (total / 100) * data.warranty.warranty;
  return(parseFloat(total + percentage));
}

export const ShowFooterMain = (props, isMobile) => {
  if (isMobile) {
    return (<CustomizerPriceFooter state={props} />)
  } else {
    return(<> </>)
  }
}

export const ShowTotalCardMain = (total, isMobile, isViewQuote, data, quoteId, quoteData, productInfo) => {
  if (isMobile) {
    return (<> </>)
  } else {
    if(isViewQuote){
      return(
        <UpdateCard
        total={total.value}
        state={ data }
        quoteId={ quoteId }
        series={quoteData.series}
        model={quoteData.model}
      />
      );
    }
    return(
      <TotalCard
        total={total.value}
        data={data}
        series={productInfo.series}
        model={productInfo.model}
      />
    );
  }
}