import React, { useState, useEffect } from "react";

// core components
import ProductHeader from "components/Headers/ProductHeader";
import ProductViewNavbar from "components/Navbars/ProductViewNavbar";
import ProductView from "components/ProductViews/Components/ProductView";
import ProductDescription from "components/ProductViews/Components/ProductDescription";
import HazFatCustomizer from "components/Customizer/Views/Haz/HazInline/HazFatCustomizer";
import TechSpecs from "views/TechSpecs/TechSpecs";
import CADFiles from "views/CADFiles/CADFiles";
import { Helmet } from "react-helmet";

function HazFatProductView({productType, tab}){
  const [page, setPage] = useState('discover');
  
  useEffect(() => {
    if(tab){
      if(tab === 'disc'){
        setPage('discover');
      }
  
      if(tab === 'specs'){
        setPage('specs');
      }
  
      if(tab === 'cad'){
        setPage('cad');
      }  
    }
  }, [tab]);
  
  const productImage = "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInsertion%2FHAZ%20FAT%2FSeries-9800-MP-Insertion-FAT-Hero.png?alt=media&token=258c5a5c-487d-46c3-b3f6-c08d08060557";

  const navbarData = {
    product_title: 'SERIES 9800MP HAZ FAT',
    product_title_old: 'SERIES 9800MP',
    product_type: 'INSERTION THERMAL MASS FLOW METER'
  }

  const productInfo = {
    model: '9800MP',
    series: navbarData.product_title
  }

  const images = [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInsertion%2FHAZ%20FAT%2FProductImages%2FSeries-9800-MP-Insertion-FAT.jpg?alt=media&token=0695bfdf-e337-4c75-8301-b688c8be3ea0",
      altText: "Series 9800MP HAZ FAT Insertion Flow Meter",
      productImageLink: '/productimages/haz_fat_insertion'
    }
  ];

  const productViewData = {
    title: 'Master-Touch™ Series 9800MP HAZ FAT Insertion Flow Meters',
    description_small: `

    `,
    description: `
    Master-Touch™ products include a rugged, cleanable, thermal mass flow sensor. These units consist of a sensor assembly which utilizes two RTD (Resistance Temperature Detector) sensing elements. The sensors are constructed of reference grade platinum, ceramic, glass, and stainless steel. Two platinum resistance sensors are built up upon the ceramic substrate and then given a thin glass coating. The assembly is then slipped into a stainless-steel sheath for corrosion and abrasion resistance. The sensor assembly is large, rugged, and relatively insensitive to dirt buildup. During operation, the temperature sensor constantly measures the ambient temperature of the gas and maintains a reference resistance on one side of a bridge circuit. The second sensor is forced through self-heating to a constant temperature above that of the gas stream and is controlled by the temperature sensor and bridge circuitry. Our bridge maintains the overheat temperature and counterbalances the temperature effects through our proprietary temperature compensation techniques.
    <br /><br />
    9800 Series flow meters are approved for use in hazardous locations (see specifications).
    <br /><br />
    Insertion style thermal mass flow meters include a sensor & probe assembly that is inserted into the process gas flow conduit to allow the process gas to flow across the flow inlet tube. Our insertion style flow meters are available with 1/2″, 3/4″, or 1″ OD probes. Tube fittings and ball valve retractor assemblies, with or without a mounting flange, are also available from the factory as options. The tube length is determined by the size of the process pipe. Large ducts or stacks may require multiple averaging tubes to achieve the very best accuracy. For problematic or unique installations, please consult the factory.
    <br /><br />
    Integral style thermal mass flow meters have all the electrical components and connections located within one enclosure. This enclosure is rated for hazardous environments. The enclosure is mounted directly to the inline flow section or to the insertion probe assembly at the point of measurement. The enclosure includes all the electrical connections as well as the linearizing electronics and the display/keypad assembly.
    <br /><br />
    Our patented Flow Averaging Tubes™ (FAT™) use the principle of convective heat transfer to directly measure mass flow and are well suited to most applications with limited available straight run. In many installations, the up-stream straight run can be reduced to three diameters. The probe has several large diameter inlet ports along the length of the upstream impact surface. The pressure at each inlet port is averaged inside the tube to create the axial flow through the tube and across our flow sensor. The gas returns to the main flow stream through the ports located near the sensing elements. Anomalies in the actual flow profile or installations in non-circular ducts may still some require minor adjustment to achieve the best accuracy.
    `,
    meter_type: 'Insertion',
    enclosure: 'Integrated Electronics',
    warranty: '1-4',
    certifications: 'CE, CSA/CUS, IECEx, KOSHA, ATEX'
  }

  const techSpecsData = {
    features:{
      titleOne: 'Agency Certified Assembly',
      highlightOneImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FProductFeatures%2Ffeatures-haz.png?alt=media&token=300d936c-d90a-42fe-ae6c-0751ee842cc0',
      highlightOneInfo: `EPI’s HAZ series full assembly is an approved instrument for use in hazardous locations with T2, T3, and
      T4 approvals available in domestic and international markets. The diecast aluminum, NEMA Type 4X
      watertight enclosure is both flame and explosion proof and features a corrosion resistant finish.
      <br />
      <strong>Domestic: CSA/CUS</strong> <br />
      &nbsp; -Class 1 Group B, C, D <br />
      &nbsp; -Class 2 Group E, F, G <br />
      &nbsp; -Class 3 <br />
      &nbsp; -NEMA Type 4X <br />

      <strong>International: T2, T3, and T4</strong> <br />
      &nbsp; -ATEX <br />
      &nbsp; -IECEx <br />
      &nbsp; -KOSHA
      `,
      titleTwo: 'Superior Flow Measurement and Calibration',
      highlightTwoImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ffeatures-image-2.png?alt=media&token=86df9cb6-22d4-4fbc-bc7f-f6b4e267b8ec',
      highlightTwoPdf: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FProductFeatures%2FFlow%20Calibration%20Check.png?alt=media&token=d98121d8-44ae-465d-b603-aa56f7c9bdd6',
      highlightTwoInfo: `EPI flow meters provide accurate, consistent, and rapid real-time flow measurement with a sensor that
      is both temperature and pressure corrective. Each flow meter is calibrated using standards traceable to
      the National Institute of Standards and Technology (NIST).
      <br /><br />

      -Accuracy: +/- (1% of reading + 0.5% of full scale + GTC) <br />
      -Repeatability: +/- 0.2% of full scale <br />
      -Sensor response time: 1 second to 63% of final value <br />
      -Gas temperature coefficient (GTC): 0.02% full scale/°C <br />
      -Gas pressure effect: negligible over +/-50% of factory calibration pressure
      `
    },
    our_process: {
      benefits:[
        {
          title: 'Broad Velocity Range',
          info: `Up to 200,000 SFPM (Contact factory for higher velocities)`
        },
        {
          title: 'Low flow sensitivity',
          info: `100:1 Turndown ratio; 15 SFPM (0.076 NMPS) minimum reading.`
        },
        {
          title: 'Wide temperature range',
          info: `HAZ Sensor suitable for process gas temperatures up to 392°F (200°C).`
        },
        {
          title: 'Multiple analog outputs',
          info: `4-20mA and 0-5VDC outputs for both flow and temperature`
        },
        {
          title: 'Various communication protocols supported',
          info: `RS485 Modbus RTU (standard), HART, BACnet, and Profibus DP`
        },
        {
          title: 'NIST traceable calibration facility',
          info: `Over 30 years of flow calibration expertise`
        },
        {
          title: 'Ease of installation',
          info: `Various configurations to meet your installation requirements`
        },
        {
          title: 'Live Zero',
          info: `Live Zero (EPIVal) to validate your flow meter calibration in both process line and ambient air
          conditions`
        }
      ]
    },
    specifications: {
      specs: [
        {
          title: 'Approvals',
          info: `CSA/CUS, ATEX, IECEx, KOSHA`
        },
        {
          title: 'Linear signal output',
          info: `0–5 VDC & 4–20 mA (Flow and Temperature)`
        },
        {
          title: 'Event Relay Rating',
          info: `One with Frequency or two, 1 Amp @ 30Vdc (33W)`
        },
        {
          title: 'Signal Interface',
          info: `RS232 & RS485 Modbus RTU embedded, Optional HART or Profibus DP`
        },
        {
          title: 'LCD',
          info: `(flow rate, flow total, gas temperature)`
        },
        {
          title: 'Accuracy, including linearity (Ref.: 21°C)',
          info: `±(1% of Reading + 0.5% of Full Scale + GTC)`
        },
        {
          title: 'Repeatability',
          info: `±0.2% of Full Scale`
        },
        {
          title: 'Sensor response time',
          info: `1 second to 63% of final value`
        },
        {
          title: 'Turn down ratio',
          info: `100:1 @ 10 SFPM (.051 NMPS) Minimum Reading`
        },
        {
          title: 'Electronics PCB temperature range',
          info: `-40° to 158°F (-40° to +70°C)`
        },
        {
          title: 'Environmental temperature range',
          info: `-40° to 140°F (-40° to +60°C)`
        },
        {
          title: 'Gas temperature range*',
          info: `-40°–392°F (-40°–200°C)`
        },
        {
          title: 'Gas temperature coefficient (GTC)',
          info: `0.02% Full Scale/°C`
        },
        {
          title: 'Pressure rating maximum.',
          info: `500 PSI Std.`
        },
        {
          title: 'Input power requirement',
          info: `24VDC @ 250mA, 115 VAC 50/60 Hz optional, 230 VAC 50/60 Hz optional`
        },
        {
          title: 'Flow Transmitter power requirements',
          info: `5 watts maximum`
        },
        {
          title: 'RAM Back-up.',
          info: `Lithium Battery`
        },
        {
          title: 'Wetted materials',
          info: `316 Stainless Steel (Hastelloy optional)`
        },
        {
          title: 'Standard temperature & pressure (STP)',
          info: `70°F & 29.92″ Hg (Air .075 lb./cubic foot)`
        },
        {
          title: 'NIST traceable calibration',
          info: `Standard`
        },
        {
          title: 'NOTE',
          info: `Specifications subject to change without notice.`
        }
      ]
    },
    communication_config: {
      type: 'haz',
      comm_config: [
        {
          output: '4-20MA FLOW (SELF OR ISOLATEDLOOP POWERED)',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: true,
          bacnet: true,
          profibus: true
        },
        {
          output: '0-5 OR 0-10 VDC FLOW',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: false,
          bacnet: true,
          profibus: false
        },
        {
          output: '4-20MA TEMPERATURE',
          standard: true,
          temp_out: true,
          no_temp_out: false,
          hart: true,
          bacnet: true,
          profibus: true
        },
        {
          output: '0-5 OR 0-10 VDC TEMPERATURE',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: false,
          bacnet: true,
          profibus: true
        },
        {
          output: 'FREQUENCY 0-1KHZ',
          standard: false,
          temp_out: false,
          no_temp_out: false,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: 'MODE SWITCH (RANGES)',
          standard: false,
          temp_out: false,
          no_temp_out: true,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: '1 MECHANICAL RELAY',
          standard: false,
          temp_out: false,
          no_temp_out: false,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: '2 MECHANICAL RELAY	',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: true,
          bacnet: true,
          profibus: true
        },

        {
          output: 'RS 485/MODBUS (R/W)',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: 'RS 232/MODBUS (R/W)',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: true,
          bacnet: true,
          profibus: true
        },
        {
          output: 'HART (READ ONLY)',
          standard: false,
          temp_out: false,
          no_temp_out: false,
          hart: true,
          bacnet: false,
          profibus: false
        },
        {
          output: 'PROFIBUS (READ ONLY)',
          standard: false,
          temp_out: false,
          no_temp_out: false,
          hart: false,
          bacnet: false,
          profibus: true
        },
        {
          output: 'BACNET (READ ONLY)',
          standard: false,
          temp_out: false,
          no_temp_out: false,
          hart: false,
          bacnet: true,
          profibus: false
        },
      ]
    },
    geometry_sizing: {
      cadImg: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FHAZ-FAT-Insertion.jpg?alt=media&token=c24ea931-4543-4144-8d83-c7fdb7fb824e',
      sizing: [
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        }
      ]
    },
    tech_support:{
      faq: [
        {
          title: 'Wiring',
          info: `The wiring of your EPI flow meter will be based on the configuration selected when your order was placed. EPI flow meters can be
          configured for various AC or DC input power options. Both analog and digital output signals are also available depending on your model and
          options.`
        },
        {
          title: 'Orientation',
          info: `EPI flow meters can be mounted in a number of different orientations based on your requirements with some limited adjustability in the
          field.`
        },
        {
          title: 'Installation',
          info: `EPI flow meters do require minumum up and downstream straight run to ensure the flow profile can develop properly, providing you with
          the highest accuracy readings.`
        },
        {
          title: 'Communication/ Outputs',
          info: `Your EPI flow meter will come standard with RS485 Modbus and RS232 digital communication. We also offer optional BACnet, Profibus DP and
          HART communications protocols. EPI flow meters provide a 4-20mA and 0-5 VDC analog output signal for flow and
          temperature (based on specific models) as well as up to 2 relays that can be independently configured based on your requirements.
          `
        }
      ],
      maintenance_info: {
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
      }
    },
    installation: {
      video: '',
      image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FWiring%2FMP.png?alt=media&token=ed7dc743-9aef-4cd5-b929-87d84cb0d935'
    },
    documents_downloads: {
      datasheets: [
        {
          title: 'Downloads',
          files: [
            {
              file_title: 'Product Brochure',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%209800MP%20FAT.pdf?alt=media&token=f1e1a7e8-6a1d-4dab-93dd-9ef4f2b70590',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Wiring Diagram',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FHAZ%20MP%20Wiring.pdf?alt=media&token=ea382e90-6bc1-426d-b8c4-706e0936bf3f',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Product Manual',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000004_RevA_MP%20Series%20Manual.pdf?alt=media&token=e9863e76-f0ee-4917-a5ab-f4a134b4ca99',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Meter Installation Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'EPITerm Software Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000003_RevA%20EPITerm%20Interface%20User%20Manual.pdf?alt=media&token=0e082343-3f69-476a-aaa0-fd344ece1885',
              version: '1.0.0',
              release_data: '2022'
            }
          ],
        }
      ],
    }
  }

  const cadData = {
    files: [
      {
        productName: '',
        modelLink: '',
        pdfLink: '',
        downloadLink: '',
        description: ``
      }
    ]
  }

  const ViewPages = () => {
    if(page === 'discover'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <ProductView productViewData={productViewData} images={images} />
          </div>
          <ProductDescription productViewData={productViewData} />
          <HazFatCustomizer
            productType={productType}
            productInfo={productInfo}
          />
        </>
      );
    }

    if(page === 'specs'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <TechSpecs data={techSpecsData} />
          </div>
        </>
      );
    }

    if(page === 'cad'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <CADFiles data={cadData} />
          </div>
        </>
      );
    }

    if(page === 'support'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <h1>Support</h1>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <ProductHeader productImage={productImage} />
      <div
        className="section section-about-us"
        style={{
          'background': 'whitesmoke',
          'padding-bottom': '50px',
          'border-bottom': 'whitesmoke'
        }}
      >
        <ProductViewNavbar
          navbarData={navbarData}
          page={page}
          setPage={setPage}
        />
      </div>
      <ViewPages />
    </>
  );
}

export default HazFatProductView;
