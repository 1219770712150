import React,{ useState, useEffect } from 'react';
import useSwr from 'swr';
import '../../assets/css/Quotes.css';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import fetcher from 'utils/fetcher';
import AllQuotesTable from './AllQuotesTable';

function AllQuotes() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const [quoteData, setQuoteData] = useState([]);
    const page = { allConfigs: true }

    var { data } = useSwr(`/api/me`, fetcher);
    var { data: Quotes } = useSwr(`/api/quotes/all/all`, fetcher);

    useEffect(() => {
        if(data){
            setUserData(data);
        }

        if(Quotes){
            setQuoteData(Quotes)
        }
    }, [data, Quotes]);

    const headerData = {
        firstname: userData.firstname,
        page: 'ALL QUOTES'
    }

    console.log('Show me config 1.0 =>', quoteData)

    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page} /></div>) : undefined}
                    <div className='common-main'>
                        <div className='shadow'>
                            <h2 className='p-3'>All Meter Configuration</h2>
                            <div className='quotes body p-3'>
                                <p>View a list of all saved quotes. Select a quote to edit, delete, or export as a PDF.</p>
                                <AllQuotesTable configs={quoteData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllQuotes
