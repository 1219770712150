import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import img1 from '../../assets/img/images/InlineImg1.png'
import img2 from '../../assets/img/images/InlineImg2.png'
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import TechnicalInfoFooter from 'components/Footers/TechnicalInfoFooter';
import { Helmet } from "react-helmet";



function InstallationInline() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Inline Meter Installation Guide</title>
            <meta name="description" content="Have a look at EPI’s installation guide for our state-of-the-art inline style thermal mass flow meters and discover all their applications." />
            <link rel="canonical" href="https://epiflow.com/technicalinfo/installation/inline" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner InlineSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/installation" tag={Link}>Installation</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/installation/inline" tag={Link}>Inline</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Inline Meter Installation</h2>
                        <p className='cover-title-p'>
                            View the installation guide for our inline style thermal mass flow meters.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            {/* <div className='bg-white'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo mb-4 text-center'>Inline Flow Meter Installation Video</h4>
                    <div className='text-center'>
                        <iframe className='shadow rounded' width="700" height="399" src="https://www.youtube.com/embed/f9LFJOno96c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </Container>
            </div> */}

            {/* section-2 */}
            {/* section-3 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <Row>
                        <Col md="8">
                            <h4 className='commontitleOne mb-3'>Inline Meter Installation Guide</h4>
                            <p className='commonParaThree'>
                                The inline style flow meter assembly includes the flow sensing element, temperature sensing element, bridge
                                amplifier/signal output board, microprocessor circuit board, transmitter enclosure, and flow section. Depending
                                on the flow section size and/or other requirements, the flow section may include flow conditioning plates.
                                <br /><br />

                                The flow section is typically specified to match the user’s flow conduit and is plumbed directly in the flow line.
                                This design has the sensing elements mounted directly in the flow section for exposure to the process gas.
                                Inline mounting styles are available through EPI™ for line sizes 1/4″ pipe and larger. Consult our factory for flow
                                section end mounting options.
                                <br /><br />

                                Inline flow meters are calibrated with the flow sensing element mounted in place within the flow section. The sensor
                                should not be removed as the accuracy of the flow signal will be affected. Should it become necessary to remove the
                                sensing element for any reason, the element must be replaced in the same alignment as it was originally positioned.
                                <br /><br />

                                Consult the factory before disassembling.
                            </p>
                        </Col>
                        <Col md="4">
                            <img src={img2} className="w-100" alt="Upstream and Downstream Recommendations for Pipe Diameters" />
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* section-3 */}

            {/* section-4 */}
            <TechnicalInfoFooter />
            {/* section-4 */}
        </>
    )
}

export default InstallationInline
