import React, { useState, useEffect } from "react";
import axios from "axios";
import useSwr from 'swr';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form"
import { Form, Button } from 'reactstrap'
import { nanoid } from 'nanoid'


import fetcher from 'utils/fetcher';

import 'assets/css/Login.css'
import logo from 'assets/img/logo1.png'

function ForgotPassword() {
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [allReturn, setAllReturn] = useState(false);
  const { data } = useSwr(`/api/me`, fetcher);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    {data && history.push("/portal")}
  }, [data]);

  const onSubmit = async (data, e) => {
    let forget_data = {
      email: data.email,
      new_password: nanoid()
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/auth/forgotpassword/new`,
        forget_data,
        { withCredentials: true }
      );
      setAllReturn(true);
    } catch (e) {
      setAllReturn(true);
    }

    e.target.reset();
  };

  return (
    <>
      <div className='login-container'>
        {!allReturn &&
          <div className='login-main shadow'>
            <div className='text-center mb-4'>
              <img src={logo} alt="EPI Logo" />
            </div>

            <h5>Forgot Password</h5>
            <p className='pleaselog-text mb-4'>
              Please insert your email in the input below and we will send an email with the link to reset your password.
            </p>

            <Form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="text"
                className="form-control mb-3"
                name="email"
                placeholder="Email Address"
                {...register("email", { required: true })}
              />
              {errors.email && <span>Email</span>}
              <Button type="submit" className='inputButton'>
                Reset Password
              </Button>
            </Form>
            <a href="#" onClick={() => history.push('/login')}>
              <p className="account-text">Don’t have an account? Log In</p>
            </a>
          </div>
        }

        {allReturn &&
          <div className='login-main shadow'>
            <div className='text-center mb-4'>
              <img src={logo} alt="EPI Logo" />
            </div>
            <h5>Forgot Password</h5>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <p className='pleaselog-text mb-4' style={{ 'color': '#16C172' }}>A link to reset your password has been sent to your email.</p>
            </Form>
          </div>
        }
      </div>
    </>
  );
}

export default ForgotPassword;
