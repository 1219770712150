import React,{ useState, useEffect } from 'react';
import useSwr from 'swr';
import { faArrowRight, faEdit, faUser, faTrash, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import fetcher from 'utils/fetcher';
// import './DeliveryTime.css'

import { UncontrolledTooltip } from "reactstrap";

function Blank2() {
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const page = { deliveryTime: true }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    var { data } = useSwr(`${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/me`, fetcher);
    useEffect(() => {
        if(data){
            setUserData(data);
        }
    }, [data]);

    const headerData = {
        firstname: userData.firstname,
        page: 'USER INFORMATION'
    }

    return (
        <div className='deliverytime'>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar> </Sidebar></div>) : undefined}
                    <span class="backBtn">
                      <a href="" class="">Back to All Users</a>
                      <FontAwesomeIcon icon={faAngleRight} className='quoute-2d-element-icon'></FontAwesomeIcon>
                      <a href="" class="">Jay Allen</a>
                    </span>
                    <div className='common-main'>
                      <div className='shadow'>
                        <div className='quit-details-main'>
                            <div className='row quit-details-main-row'>
                              <div className='col-7'>
                                  <h2 className='pt-3 pb-3 mt-2 quite-details-text'>Jay's Configurations</h2>
                              </div>
                              <div className='col-5'>
                                  <div className='row text-right quote-nav-options'>
                                      <ul className='m-0'>
                                        <li className='quoteMenuButton'>
                                          <a>
                                              <FontAwesomeIcon icon={faUser} className='quoute-2d-element-icon'></FontAwesomeIcon>
                                              Edit / Manage User
                                          </a>
                                        </li>
                                      </ul>
                                  </div>
                              </div>
                            </div>
                          </div>
                        <div className='bdr body p-3'>
                          <form>
                            <div class="form-group row">
                              <div class="col-sm-3">
                                <label for="name" class="">First Name</label>
                                <input type="text" readonly class="form-control-plaintext" id="fName" value="Jay" />
                              </div>
                              <div class="col-sm-3">
                                <label for="name" class="">Last Name</label>
                                <input type="text" readonly class="form-control-plaintext" id="lName" value="Allen" />
                              </div>
                              <div class="col-sm-6">
                                <label for="email" class="">Email</label>
                                <input type="text" readonly class="form-control-plaintext" id="email" value="jay@jayallen.com" />
                              </div>
                            </div>
                          </form>
                          <hr className='qHr' />
                          <div class="row filters">
                            <h4>User Configurations</h4>
                            <div class="col-12">
                              <p>Configurations Table Here</p>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
}

export default Blank2
