import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useSwr from 'swr';
import 'assets/css/BecomeDistributor.css';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import ImageSelectorDistributor from './ImageSelectorDistributor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Form } from 'reactstrap'
import { nanoid } from 'nanoid'
import fetcher from 'utils/fetcher';

import Success from './Success';

function BecomeDistributor() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [image, setImage] = useState('');
    const [userData, setUserData] = useState([]);
    const [distributorData, setDistributorData] = useState([]);
    const [formData, setFormData] = useState([]);
    const [phoneCountry, setPhoneCountry] = useState('US');
    const [faxCountry, setFaxCountry] = useState('US');
    const [phoneValue, setPhoneValue] = useState();
    const [faxValue, setFaxValue] = useState();
    const [successfullSubmit, setSuccessfullSubmit] = useState(false);
    const page = { BecomeDistributor: true }

    const { data } = useSwr(`/api/me`, fetcher);
    const { data: distributorData_ } = useSwr(`/api/distributor`, fetcher);
    useEffect(() => {
      if(data){
        setUserData(data);
      }
      if(distributorData_){
        setDistributorData(distributorData_);
        setImage(distributorData_.image);
      }
    }, [data, distributorData_]);

    console.log('Show me user data 1.0.1 =>', data);
    console.log('Show me distributorData data 1.0.1 =>', distributorData);

    const headerData = {
      firstname: userData.firstname,
      page: 'BECOME A DISTRIBUTOR'
    }

    const updateDefaultValue = (formFeild) => {
      const feild = formFeild.feild;
      const textValue = formFeild.value.target.value;

      setFormData(prevState => ({
        ...prevState,
        [feild]: textValue
      }))
      // console.log('Show me update user info form update 1.3 =>', formData);
    }

    console.log("Show me distributorData data 2.0.1", distributorData);

    const onSubmit = async (type) => {
      var companyName = formData.companyName;
      var contact = formData.companyContact;
      var description = formData.companyDescription;
      var website = formData.website;
      var phoneNumber = phoneValue;
      var fax = faxValue;
      var shippingAddress = formData.shippingAddress;
      var shippingAddress2 = formData.shippingAddress2;
      var shippingAddress3 = formData.shippingAddress3;

      if(!companyName){var companyName = distributorData.companyName}
      if(!contact){var contact = distributorData.contact}
      if(!description){var description = distributorData.description}
      if(!website){var website = distributorData.website}
      if(!phoneNumber){var phoneNumber = distributorData.phoneNumber}
      if(!fax){var fax = distributorData.fax}
      if(!shippingAddress){var shippingAddress = distributorData.shippingAddress}
      if(!shippingAddress2){var shippingAddress2 = distributorData.shippingAddress2}
      if(!shippingAddress3){var shippingAddress3 = distributorData.shippingAddress3}

      const update_data = {
        activation_id: distributorData.activation_id,
        companyName: companyName,
        contact: contact,
        description: description,
        email: userData.email,
        website: website,
        phoneNumber: phoneNumber,
        fax: fax,
        image: distributorData.image,
        shippingAddress: shippingAddress,
        shippingAddress2: shippingAddress2,
        shippingAddress3: shippingAddress3
      }

      console.log('Show me rep info 1.0 =>', update_data);

      if(type === 'apply'){
        update_data['activation_id'] = nanoid();
        update_data['activation'] = '0';
        try{
          await axios.post(
            `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/distributor`,
            update_data,
            { withCredentials: true }
          );
          setSuccessfullSubmit(true);
          console.log('Show me distributor data 1.0 =>', update_data);
        } catch (e) {
            console.log('Show me update user error 1.0 =>', e);
        }
      }

      if(type === 'update'){
        try{
          await axios.put(
            `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/distributor`,
            update_data,
            { withCredentials: true }
          );
          setSuccessfullSubmit(true);
          console.log('Show me distributor data 1.0 =>', update_data);
        } catch (e) {
          console.log('Show me update user error 1.0 =>', e);
        }
      }

      window.scrollTo(0, 0);
    }

    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page}/></div>) : undefined}
                    {!successfullSubmit &&
                        <div className='common-main'>
                            <h2 className='p-3'>Become a Distributor</h2>
                            {!successfullSubmit && distributorData.activation &&
                                <div className='bdr body p-3'>
                                <p>Use the form below to apply for a distributor account. Your information will be reviewed and if approved, you will receive an email from EPI support confirming your access.</p>
                                <hr />
                                <div>
                                    <h4>Company Details</h4>
                                    <ImageSelectorDistributor
                                        header={"UPLOAD COMPANY LOGO"}
                                        image={image}
                                        setImage={setImage}
                                        userData={distributorData}
                                    />
                                    <Form>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label for="companyname">COMPANY NAME <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="companyname"
                                                        name="companyname"
                                                        placeholder="Company Name"
                                                        defaultValue={ distributorData.companyName }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'companyName',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label for="contact">COMPANY CONTACT</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="contact"
                                                        name="contact"
                                                        placeholder="Company Contact"
                                                        defaultValue={ distributorData.contact }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'companyContact',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="description">COMPANY DESCRIPTION <span className='text-danger'>*</span></label>
                                            <textarea
                                                class="form-control"
                                                id="description"
                                                name="description"
                                                rows="3"
                                                placeholder='Enter a brief description of your company and/or the services your company offers here.'
                                                defaultValue={ distributorData.description }
                                                onChange={(e) => updateDefaultValue({
                                                    feild: 'companyDescription',
                                                    value: e
                                                }, e)}
                                            ></textarea>
                                        </div>
                                        <br />
                                        <div className=''>
                                            <h4>Contact Information</h4>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label for="email" class="form-label">COMPANY EMAIL <span className='text-danger'>*</span></label>
                                                <input
                                                    disabled
                                                    type="email"
                                                    class="form-control"
                                                    id="email"
                                                    name="email"
                                                    placeholder="email@domain.com"
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    defaultValue={ userData.email }
                                                    onChange={(e) => updateDefaultValue({
                                                        feild: 'email',
                                                        value: e
                                                    }, e)}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label for="website" class="form-label">COMPANY WEBSITE <span className='text-danger'>*</span></label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Company Website"
                                                    id="website"
                                                    name="url"
                                                    defaultValue={ distributorData.website }
                                                    onChange={(e) => updateDefaultValue({
                                                        feild: 'website',
                                                        value: e
                                                    }, e)}
                                                />
                                            </div>
                                        </div>
                                        {/* phone feild start */}

                                        <div className='row mt-3 mb-3'>
                                            <div className='col-md-6'>
                                                <label For="phone" class="form-label">PHONE NUMBER <span className='text-danger'>*</span></label>
                                                <PhoneInput
                                                    className='phoneinpputfeild form-control'
                                                    id="phone"
                                                    name="tel-country-code"
                                                    placeholder="Company Phone Number"
                                                    defaultValue={formatPhoneNumberIntl(distributorData.phoneNumber)}
                                                    defaultCountry={phoneCountry}
                                                    onChange={setPhoneValue}
                                                />
                                            </div>
                                            <div className='col-md-6'>
                                                <label For="fax" class="form-label">FAX NUMBER</label>
                                                <PhoneInput
                                                    className='phoneinpputfeild form-control'
                                                    id="fax"
                                                    name="tel-country-code"
                                                    placeholder="Company Fax Number"
                                                    defaultValue={formatPhoneNumberIntl(distributorData.faxNumber)}
                                                    defaultCountry={faxCountry}
                                                    onChange={setFaxValue}
                                                />
                                            </div>
                                        </div>
                                        {/* phone feild end */}

                                        {/* COMPANY ADDRESS  */}
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label for="addr1" class="form-label">COMPANY ADDRESS <span className='text-danger'>*</span></label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="addr1"
                                                    name="address-line1"
                                                    autoComplete='address-line1'
                                                    placeholder='Company Address Line 1'
                                                    defaultValue={ distributorData.shippingAddress }
                                                    onChange={(e) => updateDefaultValue({
                                                        feild: 'shippingAddress',
                                                        value: e
                                                    }, e)}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label for="addr2" class="form-label" style={{ visibility: 'hidden' }}>a</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="addr2"
                                                    name="address-line2"
                                                    autoComplete='address-line2'
                                                    placeholder='Company Address Line 2'
                                                    defaultValue={ distributorData.shippingAddress2 }
                                                    onChange={(e) => updateDefaultValue({
                                                        feild: 'shippingAddress2',
                                                        value: e
                                                    }, e)}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label for="addr3" class="form-label" style={{ visibility: 'hidden' }}>a</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="addr3"
                                                    name="address-line3"
                                                    autoComplete='address-line3'
                                                    placeholder='Company Address Line 3'
                                                    defaultValue={ distributorData.shippingAddress3 }
                                                    onChange={(e) => updateDefaultValue({
                                                        feild: 'shippingAddress3',
                                                        value: e
                                                    }, e)}
                                                />
                                            </div>
                                        </div>
                                        {/* COMPANY ADDRESS  */}
                                    </Form>
                                    {!distributorData.activation &&
                                        <input
                                            type="submit"
                                            className='upload-button'
                                            value="Apply to Become A Distributor"
                                            onClick={() => onSubmit('apply')}
                                        />
                                    }

                                    {distributorData.activation === '2' &&
                                        <input
                                            type="submit"
                                            className='upload-button'
                                            value="Reapply to Become A Distributor"
                                            onClick={() => onSubmit('apply')}
                                        />
                                    }

                                    {/* {distributorData.activation === '0' &&
                                        <input
                                            type="submit"
                                            className='upload-button'
                                            value="Distributor Application Pending Approval ..."
                                            disabled
                                        />
                                    } */}

                                    {distributorData.activation === '1' &&
                                        <input
                                            type="submit"
                                            className='upload-button'
                                            value="Update Distributor Info"
                                            onClick={() => onSubmit('update')}
                                        />
                                    }
                                </div>
                                </div>
                            }

                            {!distributorData.activation &&
                                <div className='bdr body p-3'>
                                <p>Use the form below to apply for a distributor account. Your information will be reviewed and if approved, you will receive an email from EPI support confirming your access.</p>
                                <hr />
                                <div>
                                    <h4>Company Details</h4>
                                    {/* <ImageSelectorDistributor
                                        header={"UPLOAD COMPANY LOGO"}
                                        image={image}
                                        setImage={setImage}
                                        userData={distributorData}
                                    /> */}
                                    <Form>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label for="companyname">COMPANY NAME <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="companyname"
                                                        name="companyname"
                                                        placeholder="Company Name"
                                                        defaultValue={ distributorData.companyName }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'companyName',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label for="contact">COMPANY CONTACT</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="contact"
                                                        name="contact"
                                                        placeholder="Company Contact"
                                                        defaultValue={ distributorData.contact }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'companyContact',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="description">COMPANY DESCRIPTION <span className='text-danger'>*</span></label>
                                            <textarea
                                                class="form-control"
                                                id="description"
                                                name="description"
                                                rows="3"
                                                placeholder='Enter a brief description of your company and/or the services your company offers here.'
                                                defaultValue={ distributorData.description }
                                                onChange={(e) => updateDefaultValue({
                                                    feild: 'companyDescription',
                                                    value: e
                                                }, e)}
                                            ></textarea>
                                        </div>
                                        <br />
                                        <div className=''>
                                            <h4>Contact Information</h4>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label for="email" class="form-label">COMPANY EMAIL <span className='text-danger'>*</span></label>
                                                <input
                                                    disabled
                                                    type="email"
                                                    class="form-control"
                                                    id="email"
                                                    name="email"
                                                    placeholder="email@domain.com"
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    defaultValue={ userData.email }
                                                    onChange={(e) => updateDefaultValue({
                                                        feild: 'email',
                                                        value: e
                                                    }, e)}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label for="website" class="form-label">COMPANY WEBSITE <span className='text-danger'>*</span></label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Company Website"
                                                    id="website"
                                                    name="url"
                                                    defaultValue={ distributorData.website }
                                                    onChange={(e) => updateDefaultValue({
                                                        feild: 'website',
                                                        value: e
                                                    }, e)}
                                                />
                                            </div>
                                        </div>
                                        {/* phone feild start */}

                                        <div className='row mt-3 mb-3'>
                                            <div className='col-md-6'>
                                                <label For="phone" class="form-label">PHONE NUMBER <span className='text-danger'>*</span></label>
                                                <PhoneInput
                                                    className='phoneinpputfeild form-control'
                                                    id="phone"
                                                    name="tel-country-code"
                                                    placeholder="Company Phone Number"
                                                    defaultValue={formatPhoneNumberIntl(distributorData.phoneNumber)}
                                                    defaultCountry={phoneCountry}
                                                    onChange={setPhoneValue}
                                                />
                                            </div>
                                            <div className='col-md-6'>
                                                <label For="fax" class="form-label">FAX NUMBER</label>
                                                <PhoneInput
                                                    className='phoneinpputfeild form-control'
                                                    id="fax"
                                                    name="tel-country-code"
                                                    placeholder="Company Fax Number"
                                                    defaultValue={formatPhoneNumberIntl(distributorData.faxNumber)}
                                                    defaultCountry={faxCountry}
                                                    onChange={setFaxValue}
                                                />
                                            </div>
                                        </div>
                                        {/* phone feild end */}

                                        {/* COMPANY ADDRESS  */}
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label for="addr1" class="form-label">COMPANY ADDRESS <span className='text-danger'>*</span></label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="addr1"
                                                    name="address-line1"
                                                    autoComplete='address-line1'
                                                    placeholder='Company Address Line 1'
                                                    defaultValue={ distributorData.shippingAddress }
                                                    onChange={(e) => updateDefaultValue({
                                                        feild: 'shippingAddress',
                                                        value: e
                                                    }, e)}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label for="addr2" class="form-label" style={{ visibility: 'hidden' }}>a</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="addr2"
                                                    name="address-line2"
                                                    autoComplete='address-line2'
                                                    placeholder='Company Address Line 2'
                                                    defaultValue={ distributorData.shippingAddress2 }
                                                    onChange={(e) => updateDefaultValue({
                                                        feild: 'shippingAddress2',
                                                        value: e
                                                    }, e)}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label for="addr3" class="form-label" style={{ visibility: 'hidden' }}>a</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="addr3"
                                                    name="address-line3"
                                                    autoComplete='address-line3'
                                                    placeholder='Company Address Line 3'
                                                    defaultValue={ distributorData.shippingAddress3 }
                                                    onChange={(e) => updateDefaultValue({
                                                        feild: 'shippingAddress3',
                                                        value: e
                                                    }, e)}
                                                />
                                            </div>
                                        </div>
                                        {/* COMPANY ADDRESS  */}
                                    </Form>
                                    <br />
                                    {!distributorData.activation &&
                                        <input
                                            type="submit"
                                            className='upload-button'
                                            value="Apply to Become A Distributor"
                                            onClick={() => onSubmit('apply')}
                                        />
                                    }

                                    {distributorData.activation === '2' &&
                                        <input
                                            type="submit"
                                            className='upload-button'
                                            value="Reapply to Become A Distributor"
                                            onClick={() => onSubmit('apply')}
                                        />
                                    }

                                    {/* {distributorData.activation === '0' &&
                                        <input
                                            type="submit"
                                            className='upload-button'
                                            value="Distributor Application Pending Approval ..."
                                            disabled
                                        />
                                    } */}

                                    {distributorData.activation === '1' &&
                                        <input
                                            type="submit"
                                            className='upload-button'
                                            value="Update Distributor Info"
                                            onClick={() => onSubmit('update')}
                                        />
                                    }
                                </div>
                                </div>
                            }
                        </div>
                    }
                    <div className='common-main'>
                        {successfullSubmit && <Success />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BecomeDistributor
