import React from "react";
import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem } from "reactstrap";
import './MobileSideNavbar.css';

function ProductViewSidebar({ setPage }){
  const closeSidebar = () => {
    document.getElementById("productSidebar").style.width = "0";
  }

  return(
    <div id="productSidebar" class="sidenav">
      <ListGroup flush>
        <ListGroupItem
          onClick={() => {
            setPage('discover');
            closeSidebar();
          }}
        >
          DISCOVER
          <hr />
        </ListGroupItem>
        <ListGroupItem
          onClick={() => {
            setPage('specs');
            closeSidebar();
          }}
        >
          TECH SPECS
          <hr />
        </ListGroupItem>
        <ListGroupItem
          onClick={() => {
            setPage('cad');
            closeSidebar();
          }}
        >
          CAD FILES
          <hr />
        </ListGroupItem>
        <ListGroupItem
          tag={Link}
          to="/support"
        >
          SUPPORT
          <hr />
        </ListGroupItem>
      </ListGroup>
    </div>
  )
}

export default ProductViewSidebar;