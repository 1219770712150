import React from "react";

// product views
import GenProductView from "components/ProductViews/Gen/GenInline/GenProductView";
import HazProductView from "components/ProductViews/Haz/HazInline/HazProductView";

import GenRProductView from "components/ProductViews/Gen/GenInline/GenRProductView";
import GenFatProductView from "components/ProductViews/Gen/GenInline/GenFatProductView";
import GenFatRProductView from "components/ProductViews/Gen/GenInline/GenFatRProductView";

function GenInline({product, productType, tab}) {
  switch (product) {
    case 'gen':
      return (
        <>
          <GenProductView productType={'gen'} tab={tab} />
        </>
      );
      break;

      case 'gen-r':
        return (
          <>
            <GenRProductView productType={productType} tab={tab} />
          </>
        );
        break;

      case 'gen-fat':
        return (
          <>
            <GenFatProductView productType={productType} tab={tab} />
          </>
        );
        break;

      case 'gen-fat-r':
        return (
          <>
            <GenFatRProductView productType={productType} tab={tab} />
          </>
        );
        break;
  
    default:
      return (
        <>
            <GenProductView productType={productType} tab={tab} />
        </>
      );
      break;
  }
}

export default GenInline;
