import React, { useState, useEffect } from "react";

// core components
import ProductHeader from "components/Headers/ProductHeader";
import ProductViewNavbar from "components/Navbars/ProductViewNavbar";
import ProductView from "components/ProductViews/Components/ProductView";
import ProductDescription from "components/ProductViews/Components/ProductDescription";
import ValgenRBCustomizer from "components/Customizer/Views/Val/ValInline/ValgenRBCustomizer";
import TechSpecs from "views/TechSpecs/TechSpecs";
import CADFiles from "views/CADFiles/CADFiles";
import { Helmet } from "react-helmet";

function ValgenRBProductView({productType, tab}){
  const [page, setPage] = useState('discover');

  useEffect(() => {
    if(tab){
      if(tab === 'disc'){
        setPage('discover');
      }
  
      if(tab === 'specs'){
        setPage('specs');
      }
  
      if(tab === 'cad'){
        setPage('cad');
      }  
    }
  }, [tab]);
  
  const productImage = "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInsertion%2FRB%2FValuMass-440-Insertion-Hero.png?alt=media&token=13ada86f-45fe-4342-94c7-755804ec2939";

  const navbarData = {
    product_title: 'SERIES 440 VAL',
    product_title_old: 'SERIES 440',
    product_type: 'INSERTION THERMAL MASS FLOW METER'
  }

  const productInfo = {
    model: 'SERIES 440',
    series: navbarData.product_title
  }

  const images = [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInsertion%2FRB%2FProductImages%2FValuMass-440-Insertion.jpg?alt=media&token=b113ba21-49af-4fd6-afab-3b9a19547144",
      altText: "ValuMass™ Series 440 VAL Insertion Flow Meter",
      productImageLink: '/productimages/val440'
    }
  ];

  const productViewData = {
    title: 'ValuMass™ Series 440 VAL Insertion Flow Meters',
    description_small: `
    ValuMass™ Series 440 is an economical, easy-to-install solution for many flow applications with the high value associated with all of EPI’s flow measurement instrumentation. This insertion thermal mass flowmeter is expertly crafted with a standard NEMA 4X Rated (IP66) Enclosure, for applications that do not require the hazardous rating i.e the enclosure is certified for Ordinary Locations.
    <br /><br />
    The ValuMass™ Series 440 is optimized for extremely accurate and repeatable mass flow metering using the two RTD (Resistance Temperature Detector) sensing elements. Designed with proprietary temperature compensation techniques, the sensors are accurate and have high resistance due to the use of superior materials such as reference-grade platinum, ceramic, glass, and stainless steel. For added protection, abrasion, and corrosion resistance, we also protect our sensors with a stainless-steel sheath.
    <br /><br />
    If you are looking for a more affordable thermal mass flow meter with insertion type, the ValuMass™ Series 440 is your go-to option. On the other hand, if you need the same model with an inline meter type, please check our <a href="/products/val/inline/val-rb">ValuMass™ Series 400</a>.
    `,
    description: `
    ValuMass™ products include a rugged, cleanable, thermal mass flow sensor. These units consist of a sensor assembly which utilizes two RTD (Resistance Temperature Detector) sensing elements. The sensors are constructed of reference grade platinum, ceramic, glass, and stainless steel. Two platinum resistance sensors are built up upon the ceramic substrate and then given a thin glass coating. The assembly is then slipped into a stainless-steel sheath for corrosion and abrasion resistance. The sensor assembly is large, rugged, and relatively insensitive to dirt buildup. During operation, the temperature sensor constantly measures the ambient temperature of the gas and maintains a reference resistance on one side of a bridge circuit. The second sensor is forced through self-heating to a constant temperature above that of the gas stream and is controlled by the temperature sensor and bridge circuitry. Our bridge maintains the overheat temperature and counterbalances the temperature effects through our proprietary temperature compensation techniques.
    <br /><br />
    440 Series flow meters are approved for use in non-hazardous locations (see specifications).
    <br /><br />
    Insertion style thermal mass flow meters include a sensor & probe assembly that is inserted into the process gas flow conduit to allow the process gas to flow across the flow inlet tube. Our insertion style flow meters are available with 1/2″ OD probe in 6”, 12” or 18” lengths. Tube fittings and ball valve retractor assemblies, with or without a mounting flange, are also available from the factory as options.
    <br /><br />
    Integral style thermal mass flow meters have all the electrical components and connections located within one enclosure. This enclosure is rated for hazardous environments. The enclosure is mounted directly to the inline flow section or to the insertion probe assembly at the point of measurement. The enclosure includes all the electrical connections as well as the linearizing electronics and the display assembly.
    `,
    meter_type: 'Insertion',
    enclosure: 'Integrated Electronics',
    warranty: '1-4',
    certifications: 'CE'
  }

  const techSpecsData = {
    features:{
      titleOne: 'VAL Series 440 Enclosure',
      highlightOneImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FProductFeatures%2Ffeatures-val-400.png?alt=media&token=2cd9bddf-d25f-4bd8-bfe5-f5c66941ef39',
      highlightOneInfo: `Each VAL Series 440 aluminum enclosure includes a powder-coat-painted die-cast base and lid with a seal.
      <br /><br />
      <ul>
        <li>Ratings: IP66, NEMA 1,2,4,4X</li>
      </ul>
      `,
      titleTwo: 'Superior Flow Measurement and Calibration',
      highlightTwoImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ffeatures-image-2.png?alt=media&token=86df9cb6-22d4-4fbc-bc7f-f6b4e267b8ec',
      highlightTwoPdf: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FProductFeatures%2FFlow%20Calibration%20Check.png?alt=media&token=d98121d8-44ae-465d-b603-aa56f7c9bdd6',
      highlightTwoInfo: `EPI flow meters provide accurate, consistent, and rapid real-time flow measurement with a sensor that
      is both temperature and pressure corrective. Each flow meter is calibrated using standards traceable to
      the National Institute of Standards and Technology (NIST).
      <br /><br />

      -Accuracy: +/- (1% of reading + 0.5% of full scale + GTC) <br />
      -Repeatability: +/- 0.2% of full scale <br />
      -Sensor response time: 1 second to 63% of final value <br />
      -Gas temperature coefficient (GTC): 0.02% full scale/°C <br />
      -Gas pressure effect: negligible over +/-50% of factory calibration pressure
      `
    },
    our_process: {
      benefits:[
        {
          title: 'Broad Velocity Range',
          info: `Up to 30,000 SFPM`
        },
        {
          title: 'Low flow sensitivity',
          info: `100:1 Turn down ratio; 10 SFPM (0.05 NMPS) minimum reading.`
        },
        {
          title: 'Wide temperature range',
          info: `VAL Sensor suitable for process gas temperatures up to 257°F (125°C).`
        },
        {
          title: 'Multiple analog outputs',
          info: `4-20mA and 0-5VDC outputs for both flow and temperature`
        },
        {
          title: 'Various communication protocols supported',
          info: `RS485 Modbus RTU (standard) & BACnet`
        },
        {
          title: 'NIST traceable calibration facility',
          info: `Over 30 years of flow calibration expertise`
        },
        {
          title: 'Ease of installation',
          info: `Various configurations to meet your installation requirements`
        },
        {
          title: 'Live Zero',
          info: `Live Zero (EPIVal) to validate your flow meter calibration in both process line and ambient air
          conditions`
        }
      ]
    },
    specifications: {
      specs: [
        {
          title: 'Accuracy, including linearity (Ref.: 21°C)',
          info: `±(1% of Reading + 0.5% of Full Scale + GTC)`
        },
        {
          title: 'Repeatability',
          info: `±0.2% of Full Scale`
        },
        {
          title: 'Linear Signal Output',
          info: `Software Controlled 0 – 5 or 0 – 10VDC (Flow and Temperature),
          4 – 20 mA (Flow and Temperature),
          RS485 Modbus RTU (Flow Rate/Total, Temperature),
          Optional 0 – 1 kHz (proportional to flow for total/counter)`
        },
        {
          title: 'Sensor response time',
          info: `1 second to 63% of final value`
        },
        {
          title: 'Turn down ratio',
          info: `100:1 @ 10 SFPM (.051 NMPS) Minimum Reading`
        },
        {
          title: 'Electronics PCB temperature range',
          info: `-40° to 158°F (-40° to +70°C)`
        },
        {
          title: 'Environmental temperature range',
          info: `-40° to 140°F (-40° to +60°C)`
        },
        {
          title: 'Gas temperature range',
          info: `-40°–392°F (-40°–200°C)`
        },
        {
          title: 'Gas pressure effect',
          info: `Negligible over ± 20% of absolute calibration pressure`
        },
        {
          title: 'Insertion Flowmeters',
          info: `500 PSIG (Stainless Steel ferrule),
          25 PSIG (Teflon™ ferrule), 250 PSIG with Locking collar & safety cable.`
        },
        {
          title: 'Input power requirement',
          info: `24VDC @ 250mA, 115 VAC 50/60 Hz optional, 230 VAC 50/60 Hz 50mA optional`
        },
        {
          title: 'Transmitter Power Requirements',
          info: `5 Watts or less`
        },
        {
          title: 'Relay Output Rating',
          info: `1 Amp @ 30VDC (33W) (2 Relays, 1 if Freq. Out needed)`
        },
        {
          title: 'Communications',
          info: `RS-232C, RS485 Modbus RTU`
        },
        {
          title: 'Display',
          info: `Optional 2-lines, 16 characters per line, backlit LCD (No Keypad)`
        },
        {
          title: 'Wetted Materials',
          info: `316 & 316L Stainless Steel`
        },
        {
          title: 'Standard Temperature & Pressure (STP)',
          info: `70°F & 29.92″ Hg (Air = 0.075 Lbs. / FT3)`
        },
        {
          title: 'NIST Traceable Calibration',
          info: `Standard`
        },
        {
          title: 'Enclosure Rating',
          info: `NEMA 4X (IP66)`
        },
        {
          title: 'NOTE',
          info: `Specifications subject to change without notice`
        }
      ]
    },
    communication_config: {
      type: 'val400',
      comm_config: [
        {
          output: '1 MECHANICAL RELAY',
          standard: true,
          frequency: false,
          bacnet: true,
          bacnet_frequency: false
        },
        {
          output: '2 MECHANICAL RELAY	',
          standard: true,
          frequency: true,
          bacnet: true,
          bacnet_frequency: true
        },
        {
          output: 'RS 485/MODBUS (R/W)',
          standard: true,
          frequency: true,
          bacnet: false,
          bacnet_frequency: false
        },
        {
          output: 'RS 232 (R/W)',
          standard: true,
          frequency: true,
          bacnet: true,
          bacnet_frequency: true
        },
        {
          output: 'HART (READ ONLY)',
          standard: false,
          frequency: false,
          bacnet: false,
          bacnet_frequency: false
        },
        {
          output: 'PROFIBUS (READ ONLY)',
          standard: false,
          frequency: false,
          bacnet: false,
          bacnet_frequency: false
        },
        {
          output: 'RS 485/BACnet (READ ONLY)',
          standard: false,
          frequency: false,
          bacnet: true,
          bacnet_frequency: true
        }
      ]
    },
    geometry_sizing: {
      cadImg: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FVAL-440-Insertion.jpg?alt=media&token=12b5ce11-3160-44f7-9244-188855fb8f75',
      cadImg2: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FVAL-440-Insertion-2.jpg?alt=media&token=5015494c-075f-46d7-a071-6a842ee7fe75',
      cadImg3: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FVAL-440-Insertion-3.jpg?alt=media&token=e04893af-f604-4b4b-9ece-370eb6ec197b',
      cadImg4: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FVAL-440-Insertion-4.jpg?alt=media&token=a4907e1c-ca29-43a8-9467-41940be7eb3b',
      sizing: [
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        }
      ]
    },
    tech_support:{
      faq: [
        {
          title: 'Wiring',
          info: `The wiring of your EPI flow meter will be based on the configuration selected when your order was placed. EPI flow meters can be
          configured for various AC or DC input power options. Both analog and digital output signals are also available depending on your model and
          options.`
        },
        {
          title: 'Orientation',
          info: `EPI flow meters can be mounted in a number of different orientations based on your requirements with some limited adjustability in the
          field.`
        },
        {
          title: 'Installation',
          info: `EPI flow meters do require minumum up and downstream straight run to ensure the flow profile can develop properly, providing you with
          the highest accuracy readings.`
        },
        {
          title: 'Communication/ Outputs',
          info: `Your EPI flow meter will come standard with RS485 Modbus and RS232 digital communication. We also offer optional BACnet, Profibus DP and
          HART communications protocols. EPI flow meters provide a 4-20mA and 0-5 VDC analog output signal for flow and
          temperature (based on specific models) as well as up to 2 relays that can be independently configured based on your requirements.
          `
        }
      ],
      maintenance_info: {
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
      }
    },
    installation: {
      video: '',
      image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FWiring%2F400.png?alt=media&token=72a0b457-5975-483f-8766-b81b6428ce16'
    },
    documents_downloads: {
      datasheets: [
        {
          title: 'Downloads',
          files: [
            {
              file_title: 'Product Brochure',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%20400%20VALUMASS.pdf?alt=media&token=f0bd4ff9-f0dc-4a37-922b-5f0a59442fd5',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Wiring Diagram',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FVAL%20Series%20400-440%20Wiring.pdf?alt=media&token=37036fc8-339b-4b05-bdb7-f69f26505102',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Product Manual',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000002_RevA%20Valumass%20Manual.pdf?alt=media&token=03025f6a-9bf0-44ac-8c69-2d0cb37a06b4',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Meter Installation Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'EPITerm Software Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000003_RevA%20EPITerm%20Interface%20User%20Manual.pdf?alt=media&token=0e082343-3f69-476a-aaa0-fd344ece1885',
              version: '1.0.0',
              release_data: '2022'
            }
          ],
        }
      ],
    }
  }

  const cadData = {
    files: [
      {
        productName: '8716',
        modelLink: 'https://proengineerportal.autodesk360.com/shares/public/SH9285eQTcf875d3c539cc144c88493ca402?mode=embed',
        pdfLink: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/files%2F8716MP.pdf?alt=media&token=9ad25d55-b7e2-47f3-95f7-535318cab484',
        downloadLink: 'https://proengineerportal.autodesk360.com/g/shares/SH9285eQTcf875d3c539cc144c88493ca402',
        description: `All content on this site is the intellectual property of Eldridge Products,
        Inc. You have the right to copy and share all materials herein in any medium or format.
        You may not use materials for commercial purposes and you may not modify or redistribute modified materials.
        We reserve the right to receive credit for all uses of the content.`
      },
      {
        productName: '8720',
        modelLink: 'https://proengineerportal.autodesk360.com/shares/public/SH9285eQTcf875d3c539cc144c88493ca402?mode=embed',
        pdfLink: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/files%2F8716MP.pdf?alt=media&token=9ad25d55-b7e2-47f3-95f7-535318cab484',
        downloadLink: 'https://proengineerportal.autodesk360.com/g/shares/SH9285eQTcf875d3c539cc144c88493ca402',
        description: `All content on this site is the intellectual property of Eldridge Products,
        Inc. You have the right to copy and share all materials herein in any medium or format.
        You may not use materials for commercial purposes and you may not modify or redistribute modified materials.
        We reserve the right to receive credit for all uses of the content.`
      },
      {
        productName: '8724',
        modelLink: 'https://proengineerportal.autodesk360.com/shares/public/SH9285eQTcf875d3c539cc144c88493ca402?mode=embed',
        pdfLink: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/files%2F8716MP.pdf?alt=media&token=9ad25d55-b7e2-47f3-95f7-535318cab484',
        downloadLink: 'https://proengineerportal.autodesk360.com/g/shares/SH9285eQTcf875d3c539cc144c88493ca402',
        description: `All content on this site is the intellectual property of Eldridge Products,
        Inc. You have the right to copy and share all materials herein in any medium or format.
        You may not use materials for commercial purposes and you may not modify or redistribute modified materials.
        We reserve the right to receive credit for all uses of the content.`
      },
    ]
  }

  const ViewPages = () => {
    if(page === 'discover'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <ProductView productViewData={productViewData} images={images} />
          </div>
          <ProductDescription productViewData={productViewData} />
          <ValgenRBCustomizer
            productType={productType}
            productInfo={productInfo}
          />
        </>
      );
    }

    if(page === 'specs'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <TechSpecs data={techSpecsData} />
          </div>
        </>
      );
    }

    if(page === 'cad'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <CADFiles data={cadData} />
          </div>
        </>
      );
    }

    if(page === 'support'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <h1>Support</h1>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <ProductHeader productImage={productImage} />
      <div
        className="section section-about-us"
        style={{
          'background': 'whitesmoke',
          'padding-bottom': '50px',
          'border-bottom': 'whitesmoke'
        }}
      >
        <ProductViewNavbar
          navbarData={navbarData}
          page={page}
          setPage={setPage}
        />
      </div>
      <ViewPages />
    </>
  );
}

export default ValgenRBProductView;
