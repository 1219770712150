import React, { useState, useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import img1 from '../../assets/img/images/waterWasteImg1.png';
import { Helmet } from "react-helmet";

function Wastewater() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [color, setColor] = useState('#095CF6');
    const handleBtn = () => {
        setColor('#fff')
    }
    const handleBtnOut = () => {
        setColor('#095CF6')
    }
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Wastewater & Aeration Basin Meters</title>
            <meta name="description" content="Thermal Mass Flow Wastewater & Aeration Basin Meters are critical tools for municipal responsibility for many applications. Dive into EPI’s article!" />
            <link rel="canonical" href="https://epiflow.com/whitepapers/wastewater" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner WWaterSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/whitepapers" tag={Link}>White Papers</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/whitepapers/wastewater" tag={Link}>Wastewater</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Wastewater & Aeration Basin Meters</h2>
                        <p className='cover-title-p'>Everything you need to know about EPI.</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <Container className='py-5'>
                <div className='DownloadPDfbtn-div'>
                    <div className='position-relative d-inline'>
                        <button
                            className='button2'
                            onMouseOver={handleBtn}
                            onMouseOut={handleBtnOut}
                        >
                            Download as PDF
                        </button>
                        <FontAwesomeIcon
                            className='DownloadPDfbtn-icon'
                            style={{ color: color }}
                        />
                    </div>
                </div>
                <h4 className='commontitleTwo text-center mb-4'>
                    Thermal Mass Flow Wastewater & Aeration Basin Meters
                </h4>
                <p className='commonParaThree'>
                    Thermal mass flow wastewater & aeration basin meters area critical tool for municipal responsibility for these applications. <br /><br />
                    The treatment of water and wastewater is a critical element of municipal responsibility. Increased public and private awareness of water quality, availability, and cost is
                    a driving force behind the demands for better efficiency and economy in these processes. Whether local needs call for new facilities or improvements to existing facilities,
                    Master-Touch™ flow meters can help you meet these demands and at the same time eliminate the undesirable system pressure drops and high maintenance costs associated with the
                    older technology of differential flow meters and rotary load meters.
                </p>
                <h4 className='commontitleTwo text-center mb-4'>
                    Aeration Basins
                </h4>
                <p className='commonParaThree'>
                    A common use of thermal flow meters at WWTP facilities is to measure the air (or oxygen) flow required for the secondary treatment of the activated sludge process when air and
                    “seed” sludge are added to the wastewater to facilitate decomposition. To stimulate the growth of aerobic bacteria and other organisms that are present in the sewage, air is
                    pumped at a pre-determined rate into large aeration tanks where the wastewater and sludge are mixed. The rate of the air flow must be carefully monitored and adjusted, as necessary,
                    throughout the tanks and throughout the overall process for optimal efficiency. Adding either too much or too little air can have a very noticeable negative impact on this important
                    step of the treatment process, so a well-balanced and properly distributed air/oxygen supply in an aeration system is a critical element in any effective wastewater treatment plant.
                    Providing accurate measurement of the air flow is often the primary application for thermal flow meters at treatment plants.
                    <br /><br />
                    After additional steps, such as settling and re-circulating, the sludge is subjected to anaerobic treatment where the sludge is placed in digesters (oxygen-free tanks) and heated for
                    a number of days to stimulate the growth of anaerobic bacteria. This digestion process is required to convert as much of the sludge as possible into water and a mixture of carbon dioxide
                    and methane gas called digester gas or biogas — and this presents another excellent and increasingly critical opportunity to incorporate thermal flow meters into the plant operations.
                </p>
                <Row className='g-3'>
                    <Col md="6">
                        <div>
                            <img src={img1} className='w-100' alt="Graphic of an aeration basin" />
                        </div>
                    </Col>
                    <Col md="6">
                        <div>
                            <p className='commonParaThree'>
                                Potential points of measurement in a WWTP air system are: <br />
                                A Blower inlet air;<br />
                                B Total air usage;<br />
                                C Distribution pipes; and<br />
                                D Aeration basins.
                            </p>
                        </div>
                    </Col>
                </Row>
                <h4 className='commontitleTwo text-center my-4'>
                    Digester Gas / Energy Production
                </h4>
                <p className='commonParaThree'>
                    Water and wastewater treatment processes, such aeration and pumping, are energy-intensive. (Energy costs are commonly the second leading expense of WWTP facilities behind only facility staffing.)
                    However, the digester gas from the anaerobic process typically contains 60–80% methane gas. Rather than allowing the gas to escape into the atmosphere — with its own set of environmental problems
                    and restrictions! — the methane can be captured for use as an energy source to drive turbines that produce electricity or to directly drive other plant equipment. The gas can also be used in
                    boilers to provide heat for the facility’s buildings. All of this helps reduce the need for purchasing natural gas from another source.
                    <br /><br />
                    A typical digester system will contain the captured gas in a storage tank that acts as a buffer to balance fluctuations in the production of gas in the digesters. Production is usually greater in
                    summer than in winter which is often the opposite of the facilities pattern of usage. As supplies dwindle, natural gas must supplement the captured digester gas. And it is here that the accurate
                    measurement of both digester gas and natural gas will have a critical impact in the cost-effective operation of the treatment facility. Closely monitoring the use of both gases as it is
                    distributed through the treatment facility provides the information needed for efficient operation and for the reporting of the cost savings derived from the capture and use of the digester
                    gas itself.
                </p>

                <h4 className='commontitleTwo text-center my-4'>
                    Thermal Technology
                </h4>
                <p className='commonParaThree'>
                    Constant temperature thermal mass flow meters, such as those produced by EPI, operate on the principle of thermal dispersion or heat loss from a heated Resistance Temperature Detector (RTD) to
                    the flowing gas. Two active RTD sensors are operated in a balanced state. One acts as a temperature sensor reference; the other is the active heated sensor. Heat loss to the flowing fluid tends
                    to unbalance the heated flow sensor and it is forced back into balance by the electronics. With this method of operating the constant temperature sensor, only the skin temperature is affected by
                    the fluid flow heat loss. This allows the sensor core temperature to be maintained and produces a very fast response to fluid velocity and temperature changes. Additionally, because the power
                    is applied as needed, the technology has a wide operating range of flow and temperature. The heated sensor maintains an index of overheat above the environmental temperature sensed by the unheated
                    element. The effects of variations in density are virtually eliminated by molecular heat transfer and sensor temperature corrections.
                </p>

                <h4 className='commontitleTwo text-center mb-4'>
                    Specifying the Requirements
                </h4>
                <p className='commonParaThree'>
                    A number of factors must be considered when selecting and specifying any instrumentation and this is true for thermal mass flow meters to be used in WWTP systems. To specify the best configuration, you must determine:
                    <br /><br />
                    <b>What are the flow measurement conditions, such as the minimum and maximum flow rates to be measured, the process pipe size, and the gas temperature and line pressure?</b><br />
                    All flow meters have minimum and maximum flow limits for any given pipe size, and temperature and pressure ranges for the physical construction. Assuring that the flow meter meets these basic requirements is the first
                    step in specifying the proper mass flow meter. These parameters will determine the calibration scale and the expected accuracy, as well as help to identify potential issues with the overall installation.
                    <br /><br />

                    <b>Where will the flow meter be installed and what is the piping configuration upstream and downstream of that location?</b><br />
                    The flow readings will be their most accurate where the gas flow profile in the pipe is uniform and consistent so that the sensor output at the point of measurement is truly representative of the overall flow through
                    the pipe. All instrument manufacturers have recommended straight run requirements for the installation of their meters. These recommendations are offered to help end-users determine suitable locations for the flow meters,
                    but it is important to recognize that these are only guidelines and not guarantees of optimal positioning.
                    <br /><br />

                    <b>Is there moisture present at the point of measurement?</b><br />
                    By its nature, most digester gas is considered to be “wet”. Simply stated, thermal mass flow meters will not read accurately* if water droplets come into contact with the sensor RTDs. Although there are successful strategies
                    for minimizing the potential for problems, installing the flow meter at a location where the gas is dry is strongly advised.<br />

                    * The heat loss to a liquid such as water droplets is so much greater than the heat loss to a dry gas that the meter’s flow signal will typically rise to a higher value producing an error that will remain until the heated RTD
                    is dry again. Although Eldridge Products’ flow meters will be affected by these droplets, they will not cause damage to the flow sensor. Non-condensing water vapor in the gas is acceptable.
                    <br /><br />
                    Master-Touch™ thermal flow meters comply with the requirements of 40 CRF 98.34 (c)(1). They are calibrated in our own NIST-traceable facility using CH4/CO2 gas mixtures for the best accuracy. Our flow meters provide
                    real-time mass flow measurement and totalization for continuous air and digester gas flow monitoring. In addition, Master-Touch™ flow meters can store as many as four independent flow ranges for multiple gas mixtures
                    and have two fully-programmable relays for a variety of notification functions and/or pulsed totalization of flow. A variety of sizes and configuration are available to meet virtually any installation requirement.
                </p>
            </Container>
        </>
    );
}

export default Wastewater;
