import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import img1 from '../../assets/img/images/InlineImg1.png'
import img2 from '../../assets/img/images/InsertionImg1.png'
import TechnicalInfoFooter from 'components/Footers/TechnicalInfoFooter';
import { Helmet } from "react-helmet";


function InstallationInsertion() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Insertion Meter Installation Guide</title>
            <meta name="description" content="Have a look at EPI’s installation guide for our state-of-the-art insertion style thermal flow meters and discover all their applications. " />
            <link rel="canonical" href="https://epiflow.com/technicalinfo/installation/insertion" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner InsertionSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/installation" tag={Link}>Installation</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/installation/insertion" tag={Link}>Insertion</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Insertion Meter Installation</h2>
                        <p className='cover-title-p'>
                            View the installation guide for our insertion style thermal mass flow meters.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            {/* <div className='bg-white'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo mb-4 text-center'>Insertion Flow Meter Installation Video</h4>
                    <div className='text-center'>
                        <iframe className='shadow rounded' width="700" height="399" src="https://www.youtube.com/embed/f9LFJOno96c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </Container>
            </div> */}

            {/* section-2 */}

            {/* section-3 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <h4 className='commontitleOne mb-3'>Insertion Meter Installation Guide</h4>
                    <p className='commonParaThree'>
                        The insertion style flow meters include the flow sensing element, temperature sensing element, bridge amplifier/signal output board, microprocessor circuit board,
                        transmitter enclosure, and the probe assembly which supports the sensing elements. This design requires the probe assembly to be inserted into the process gas flow conduit.
                        <br /><br />

                        Insertion styles are available with 1/2″, 3/4″ or 1″ OD probes. Insertion style flow meters may be installed with properly sized bored-through tube fittings to mount them
                        in place. Tube fittings, with or without mounting flange, are available from the factory as an option. Installing the tube fitting consists of preparing the flow conduit
                        to accept the fitting by first drilling a clearance hole for the transmitter probe assembly, welding it in place, or threading it into the proper size half coupling
                        which has been welded to the flow conduit. The tube length will be determined by EPI™ based upon the installation specifications.
                        <br /><br />

                        Optional ball valve assemblies are available through EPI™ which allow the removal of the insertion style averaging tubes for service, calibration, cleaning, etc.
                        The valve provides a means to seal off leaks of the process gas at the point of insertion after the probe assembly has been removed. Installation requires fitting the
                        flow section to which the insertion probe assembly will be inserted with a threaded half coupling of the proper size to accommodate the ball valve retractor. In some
                        instances, this requires direct threading together (or with a reducing bushing) of the retractor assembly. In other cases, it requires welding the half coupling in place
                        and drilling a clearance hole through for the probe assembly. If the flow section is under pressure, a hot tap drill rig (not available through EPI) may be required.
                        <br /><br />

                        The maximum pressure for insertion style flow meters is stated in the Manual for each product. To reduce the possibility of personal injury when servicing the flow meter,
                        each size is rated such that the maximum force applied to the transmitter is approximately 25 pounds. Caution should be exercised if considering applying higher pressures,
                        and AT HIGHER PRESSURES, A HOLDING DEVICE MAY BE REQUIRED TO PREVENT THE TRANSMITTER FROM BEING PROJECTED OUT OF THE PROCESS LINE WHEN REMOVING OR REPLACING THE
                        TRANSMITTER ASSEMBLY.
                    </p>
                    <img src={img2} className="w-100 mt-2" alt="Recommended Pressure Ratings for Insertion Meters" />
                    <h4 className='commontitleOne mb-3'>Flow Meter Installation: Probe Insertion Depth Guidelines</h4>
                    <p className='commonParaThree'>
                        EPI calibrates insertion flowmeters for positioning near the ISO Point-of-Average-Flow (0.243r). See the installation manual for calculation of the probe insertion depth
                        (pipe OD to probe end) includes the point-of-average-flow, the x-factor, and the wall thickness of each nominal pipe size, as well as a further adjustment on smaller line
                        sizes to be sure that the slot is fully inserted through the pipe wall. Any potential blockage effect created by inserting the probe assembly into the pipe to the depth
                        listed has been accommodated by EPI™ as part of the calibration for the pipe size specified for the initial factory calibration. Therefore, the factory C-Factor value is
                        now always “1”. <strong>Product-specific insertion depths are included in the Manual for that product.</strong>
                        <br /><br />
                        Consult the factory for any additional C-Factor adjustments required if the flowmeter will be used in a pipe size other than that specified for the initial factory calibration.
                    </p>
                </Container>
            </div>
            {/* section-3 */}
            {/* section-4 */}
            <TechnicalInfoFooter />
            {/* section-4 */}

        </>
    )
}

export default InstallationInsertion
