import React, { useState, useEffect } from "react";
import useSwr from 'swr';
import axios from 'axios';

// reactstrap components
import { Form, Button, Modal } from "reactstrap";
import fetcher from 'utils/fetcher';


function ShipToAddressModal({ modal, setModal}){
  const [formData, setFormData] = useState([]);
  const [emptyField, setEmptyField] = useState(false);
  const { data, mutate } = useSwr("/api/addresses/shippingAddress", fetcher);

  useEffect(() => {
    setFormData({
      company: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    })
  }, []);

  const updateDefaultValue = (formFeild) => {
    const feild = formFeild.feild;
    const textValue = formFeild.value.target.value;

    setFormData(prevState => ({
      ...prevState,
      [feild]: textValue
    }))
  }

  const onSubmit = async () => {
    const submit_data = {
      company: formData.company,
      address: formData.address,
      city: formData.city,
      state: formData.state,
      zip: formData.zip,
      country: formData.country,
      address_type: 'shipping'
    }

    const checkData = Object.values(submit_data);
    console.log('Show me submit data 1.0 =>', checkData);

    for (var i = 0; i < checkData.length; i++){
      if(checkData[i] === ''){
        setEmptyField(true);
        return
      }
      setEmptyField(false);
    }

    console.log('Show me submit data 2.0 =>', submit_data);

    try{
      await axios.post(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/addresses/shippingAddress`,
        submit_data,
        { withCredentials: true }
      )
      setModal(false);
      mutate();
    } catch (e) {
      console.log('Show me update user error 1.0 =>', e);
    }
  }

  return (
    <>
      <Modal
        isOpen={modal}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setModal(false)}
      >
        <div className="modal-header">
          <h4 className="modal-title" id="myLargeModalLabel">
            Add a new shipping address
          </h4>
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => setModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
              <div className='row'>
                  <div className='col-md-4'>
                    <div class="form-group">
                      <label for="address">Company <span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        id="company"
                        name="company"
                        class="form-control"
                        placeholder="Company"
                        onChange={(e) => updateDefaultValue({
                          feild: 'company',
                          value: e
                        }, e)}
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div class="form-group">
                      <label for="address">Address <span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        class="form-control"
                        placeholder="Address"
                        onChange={(e) => updateDefaultValue({
                          feild: 'address',
                          value: e
                        }, e)}
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div class="form-group">
                      <label for="city">City <span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        class="form-control"
                        placeholder="City"
                        onChange={(e) => updateDefaultValue({
                          feild: 'city',
                          value: e
                        }, e)}
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div class="form-group">
                      <label for="state">State <span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        id="state"
                        name="state"
                        class="form-control"
                        placeholder="State"
                        onChange={(e) => updateDefaultValue({
                          feild: 'state',
                          value: e
                        }, e)}
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div class="form-group">
                      <label for="zip">ZIP <span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        id="zip"
                        name="zip"
                        class="form-control"
                        placeholder="Zip"
                        onChange={(e) => updateDefaultValue({
                          feild: 'zip',
                          value: e
                        }, e)}
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div class="form-group">
                      <label for="company">Country <span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        id="country"
                        name="country"
                        class="form-control"
                        placeholder="Country"
                        onChange={(e) => updateDefaultValue({
                          feild: 'country',
                          value: e
                        }, e)}
                      />
                    </div>
                  </div>
              </div>
          </Form>
          { emptyField &&
            <div className="text-center">
              <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>All fields above must be filled</p>
            </div>
          }
          <Button className='inputButton' onClick={(e) => onSubmit()}> Add Address </Button>
        </div>
      </Modal>
    </>
  );
}

export default ShipToAddressModal;
