import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import 'assets/css/style.css'
import SidebarDownloads from './SidebarDownloads/SidebarDownloads';
import DownloadModal from './DownloadModal';
import meterimg from '../../assets/img/images/meter-img.png'
import { Col, Row, NavLink, Button } from 'reactstrap';
import { Helmet } from "react-helmet";


function Downloads() {
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [downloadModal, setDownloadModal] = useState(false);
    const [meterOptions, setMeterOptions] = useState('inline')
    const [secondOptions, setSecondOptions] = useState(false);
    const [thirdOptions, setThirdOptions] = useState(false);
    const [downloadData, setDownloadData] = useState({});

    const clearFilter = () => {
        history.push('/support/downloads');
    }

    const meters = [
        {
            name: 'SERIES 8600-8700 MP',
            nameType: 'HAZ',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8600-8700-MP-Inline.png?alt=media&token=f6f34c3c-0aaf-45e6-a84c-54efcf46e38c',
            link: '/products/haz/inline/haz',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000004_RevA_MP%20Series%20Manual.pdf?alt=media&token=e9863e76-f0ee-4917-a5ab-f4a134b4ca99',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%208600MP-8700MP.pdf?alt=media&token=2e2e0e38-75aa-46aa-ac0b-f0b931b5defb',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FHAZ%20MP%20Wiring.pdf?alt=media&token=ea382e90-6bc1-426d-b8c4-706e0936bf3f',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'inline',
            options_2: 'integral'
        },
        {
            name: 'SERIES 8800 MP',
            nameType: 'HAZ',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8800-MP-Insertion.png?alt=media&token=dc99f036-ef27-430a-941d-13c38dc1a713',
            link: '/products/haz/insertion/haz',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000004_RevA_MP%20Series%20Manual.pdf?alt=media&token=e9863e76-f0ee-4917-a5ab-f4a134b4ca99',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%208800MP.pdf?alt=media&token=9d5c8074-6ef1-4a5c-a50f-bc6b994e80ae',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FHAZ%20MP%20Wiring.pdf?alt=media&token=ea382e90-6bc1-426d-b8c4-706e0936bf3f',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'insertion',
            options_2: 'integral'
        },
        {
            name: 'SERIES 8000–8100 MP',
            nameType: 'HAZ R',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8000-8100-MP-Inline-Remote-1.png?alt=media&token=757f08e1-7fe2-4a41-8dea-2319b4ea5b72',
            link: '/products/haz/inline/haz-r',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000004_RevA_MP%20Series%20Manual.pdf?alt=media&token=e9863e76-f0ee-4917-a5ab-f4a134b4ca99',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%208000MP%20%26%208100MP.pdf?alt=media&token=90121b77-9d8f-42ab-bd2f-cbdfa70d722b',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FHAZ%20MP%20Wiring.pdf?alt=media&token=ea382e90-6bc1-426d-b8c4-706e0936bf3f',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'inline',
            options_2: 'remote'
        },
        {
            name: 'SERIES 8200 MP',
            nameType: 'HAZ R',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8200-MP-Insertion-Remote.png?alt=media&token=b6b61fff-905e-4c74-8133-933247384502',
            link: '/products/haz/insertion/haz-r',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000004_RevA_MP%20Series%20Manual.pdf?alt=media&token=e9863e76-f0ee-4917-a5ab-f4a134b4ca99',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%208200MP.pdf?alt=media&token=f288f247-9053-4db6-9244-3628e00ab0fe',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FHAZ%20MP%20Wiring.pdf?alt=media&token=ea382e90-6bc1-426d-b8c4-706e0936bf3f',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'insertion',
            options_2: 'remote'
        },
        {
            name: 'SERIES 9700 MP',
            nameType: 'HAZ FAT',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9700-MP-Inline-FAT.png?alt=media&token=03785a4c-4da7-48eb-9f86-80389bf69cca',
            link: '/products/haz/inline/haz-fat',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000004_RevA_MP%20Series%20Manual.pdf?alt=media&token=e9863e76-f0ee-4917-a5ab-f4a134b4ca99',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%209700MP%20FAT.pdf?alt=media&token=194387eb-fb09-4973-a399-0c932a52ab4e',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FHAZ%20MP%20Wiring.pdf?alt=media&token=ea382e90-6bc1-426d-b8c4-706e0936bf3f',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'inline',
            options_2: 'integral'
        },
        {
            name: 'SERIES 9800 MP',
            nameType: 'HAZ FAT',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9800-MP-Insertion-FAT.png?alt=media&token=27c195bf-2c91-41a0-89a1-24860f57eb9a',
            link: '/products/haz/insertion/haz-fat',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000004_RevA_MP%20Series%20Manual.pdf?alt=media&token=e9863e76-f0ee-4917-a5ab-f4a134b4ca99',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%209800MP%20FAT.pdf?alt=media&token=f1e1a7e8-6a1d-4dab-93dd-9ef4f2b70590',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FHAZ%20MP%20Wiring.pdf?alt=media&token=ea382e90-6bc1-426d-b8c4-706e0936bf3f',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'insertion',
            options_2: 'integral'
        },
        {
            name: 'SERIES 9100 MP',
            nameType: 'HAZ FAT R',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9100-MP-Inline-Remote-FAT.png?alt=media&token=9a42a5d7-75d8-4d61-8dae-23ebc8e26146',
            link: '/products/haz/inline/haz-fat-r',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000004_RevA_MP%20Series%20Manual.pdf?alt=media&token=e9863e76-f0ee-4917-a5ab-f4a134b4ca99',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSeries%209100MP%20FAT.pdf?alt=media&token=ab98f83a-52e1-46d4-a0f7-d13595e0346a',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FHAZ%20MP%20Wiring.pdf?alt=media&token=ea382e90-6bc1-426d-b8c4-706e0936bf3f',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'inline',
            options_2: 'remote'
        },
        {
            name: 'SERIES 9200 MP',
            nameType: 'HAZ FAT R',
            type: 'hazardous',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9200-MP-Insertion-Remote-FAT.png?alt=media&token=452e41f0-8ec5-4f84-8af0-1b806efbe873',
            link: '/products/haz/insetion/haz-fat-r',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000004_RevA_MP%20Series%20Manual.pdf?alt=media&token=e9863e76-f0ee-4917-a5ab-f4a134b4ca99',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%209200MP%20FAT.pdf?alt=media&token=755cf309-d98b-495a-9b4e-a2e008cf9c20',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FHAZ%20MP%20Wiring.pdf?alt=media&token=ea382e90-6bc1-426d-b8c4-706e0936bf3f',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'insertion',
            options_2: 'remote'
        },
        {
            name: 'SERIES 8600-8700 MPNH',
            nameType: 'GEN',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8600-8700-MPNH-Inline.png?alt=media&token=f26fdb4a-d1c0-40c0-a9cf-5a55125d4678',
            link: '/products/gen/inline/gen',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000005_RevA_MPNH%20Manual.pdf?alt=media&token=f7c3ffa8-0136-4222-8257-d07cdea925f2',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%208600MPNH-8700MPNH.pdf?alt=media&token=7fd309d8-0414-4b2a-9bdd-dfed11a97766',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FGEN%20MPNH%20Wiring.pdf?alt=media&token=f8d47a88-e137-4075-98bf-68bd9458ff62',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'inline',
            options_2: 'integral'
        },
        {
            name: 'SERIES 8800 MPNH',
            nameType: 'GEN',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8800-MPNH-Insertion.png?alt=media&token=06fdd9df-bd8b-4e29-9370-2b31c34a4f9b',
            link: '/products/gen/insertion/gen',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000005_RevA_MPNH%20Manual.pdf?alt=media&token=f7c3ffa8-0136-4222-8257-d07cdea925f2',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%208800MPNH.pdf?alt=media&token=9331cd61-3f15-4faf-ab89-553c8713b4d7',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FGEN%20MPNH%20Wiring.pdf?alt=media&token=f8d47a88-e137-4075-98bf-68bd9458ff62',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'insertion',
            options_2: 'integral'
        },
        {
            name: 'SERIES 8000-8100 MPNH',
            nameType: 'GEN R',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8000-8100-MPNH-Inline-Remote-1.png?alt=media&token=12391992-8ca5-4676-91d2-93f79aafd333',
            link: '/products/gen/inline/gen-r',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000005_RevA_MPNH%20Manual.pdf?alt=media&token=f7c3ffa8-0136-4222-8257-d07cdea925f2',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%208000-8100%20MPNH.pdf?alt=media&token=a23a7910-6ac8-4e1e-8a00-c14798d90cc9',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FGEN%20MPNH%20Wiring.pdf?alt=media&token=f8d47a88-e137-4075-98bf-68bd9458ff62',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'inline',
            options_2: 'remote'
        },
        {
            name: 'SERIES 8200 MPNH',
            nameType: 'GEN R',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8200-MPNH-Insertion-Remote.png?alt=media&token=fff4e0f8-e9eb-4d31-9273-9f57cb3468ea',
            link: '/products/gen/insertion/gen-r',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000005_RevA_MPNH%20Manual.pdf?alt=media&token=f7c3ffa8-0136-4222-8257-d07cdea925f2',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%208200MPNH.pdf?alt=media&token=bfa74419-d9bc-41e3-8568-6d2cbdb2a24c',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FGEN%20MPNH%20Wiring.pdf?alt=media&token=f8d47a88-e137-4075-98bf-68bd9458ff62',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'insertion',
            options_2: 'remote'
        },
        {
            name: 'SERIES 9700 MPNH',
            nameType: 'GEN FAT',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9700-MPNH-Inline-FAT.png?alt=media&token=3b6c9bf1-e340-4c85-939a-58940b96c68e',
            link: '/products/gen/inline/gen-fat',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000005_RevA_MPNH%20Manual.pdf?alt=media&token=f7c3ffa8-0136-4222-8257-d07cdea925f2',
                brouchure: '',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FGEN%20MPNH%20Wiring.pdf?alt=media&token=f8d47a88-e137-4075-98bf-68bd9458ff62',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'inline',
            options_2: 'integral'
        },
        {
            name: 'SERIES 9800 MPNH',
            nameType: 'GEN FAT',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9800-MPNH-Insertion-FAT.png?alt=media&token=24d206c1-28f0-43d7-8ab6-4b02cad839a7',
            link: '/products/gen/insertion/gen-fat',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000005_RevA_MPNH%20Manual.pdf?alt=media&token=f7c3ffa8-0136-4222-8257-d07cdea925f2',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%209800MPNH%20FAT.pdf?alt=media&token=fbb1ee4e-8f8e-4326-a30a-dfb35b9f5004',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FGEN%20MPNH%20Wiring.pdf?alt=media&token=f8d47a88-e137-4075-98bf-68bd9458ff62',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'insertion',
            options_2: 'integral'
        },
        {
            name: 'SERIES 9100 MPNH',
            nameType: 'GEN FAT R',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9100-MPNH-Inline-Remote-FAT.png?alt=media&token=f34f7592-8ebb-4aa7-97e9-c0886833f844',
            link: '/products/gen/inline/gen-fat-r',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000005_RevA_MPNH%20Manual.pdf?alt=media&token=f7c3ffa8-0136-4222-8257-d07cdea925f2',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%209100MPNH%20FAT.pdf?alt=media&token=45bcbfc5-3c8b-4a3c-91c8-e908867e2235',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FGEN%20MPNH%20Wiring.pdf?alt=media&token=f8d47a88-e137-4075-98bf-68bd9458ff62',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'inline',
            options_2: 'remote'
        },
        {
            name: 'SERIES 9200 MPNH',
            nameType: 'GEN FAT R',
            type: 'general',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9200-MPNH-Insertion-Remote-FAT.png?alt=media&token=dea45a00-2238-4bc9-85ac-f2cf5613c2a6',
            link: '/products/gen/insertion/gen-fat-r',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000005_RevA_MPNH%20Manual.pdf?alt=media&token=f7c3ffa8-0136-4222-8257-d07cdea925f2',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSeries_9200MPNH.pdf?alt=media&token=fad20f23-db11-4c17-bb0b-f196309a9c5a',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FGEN%20MPNH%20Wiring.pdf?alt=media&token=b0e8b387-ee95-4b1d-afca-53cb2ce26dc9',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'insertion',
            options_2: 'remote'
        },
        {
            name: 'SERIES VM 500',
            nameType: 'VALGEN-LM',
            type: 'val',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FValuMass-500-Inline.png?alt=media&token=e3b95f33-ff88-473d-bbc8-ec5bd6d951e0',
            link: '/products/val/inline/val-lm',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000002_RevA%20Valumass%20Manual.pdf?alt=media&token=03025f6a-9bf0-44ac-8c69-2d0cb37a06b4',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%20500%20VALUMASS.pdf?alt=media&token=677df829-f29c-474e-8a27-6e129dc0ae66',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FVAL%20Series%20500-540%20Wiring.pdf?alt=media&token=176d2c0e-0d4b-4688-b873-eb758e40dc27',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'inline',
            options_2: 'integral'
        },
        // {
        //     name: 'SERIES VM 540',
        //     nameType: 'VALGEN-LMR',
        //     type: 'val',
        //     image: '',
        //     link: '/products/val/inline/val-lmr',
        //     options: 'insertion',
        //     options_2: 'remote'
        // },
        {
            name: 'SERIES VM 400',
            nameType: 'VALGEN-RB',
            type: 'val',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FValuMass-400-Inline.png?alt=media&token=8871295a-3050-4255-b638-62f22e4f3469',
            link: '/products/val/inline/val-rm',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000002_RevA%20Valumass%20Manual.pdf?alt=media&token=03025f6a-9bf0-44ac-8c69-2d0cb37a06b4',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%20400%20VALUMASS.pdf?alt=media&token=f0bd4ff9-f0dc-4a37-922b-5f0a59442fd5',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FVAL%20Series%20400-440%20Wiring.pdf?alt=media&token=37036fc8-339b-4b05-bdb7-f69f26505102',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'inline',
            options_2: 'integral'
        },
        // {
        //     name: 'SERIES VM 440',
        //     nameType: 'VALGEN-RBR',
        //     type: 'val',
        //     image: '',
        //     link: '/products/val/inline/val-rbr',
        //     options: 'insertion',
        //     options_2: 'remote'
        // },
        {
            name: 'SERIES VM 540',
            nameType: 'VALGEN-LM',
            type: 'val',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FValuMass-540-Insertion.png?alt=media&token=d79139d4-2e3c-4891-8c98-9361f6d666a8',
            link: '/products/val/insertion/val-lm',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000002_RevA%20Valumass%20Manual.pdf?alt=media&token=03025f6a-9bf0-44ac-8c69-2d0cb37a06b4',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%20500%20VALUMASS.pdf?alt=media&token=677df829-f29c-474e-8a27-6e129dc0ae66',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FVAL%20Series%20500-540%20Wiring.pdf?alt=media&token=176d2c0e-0d4b-4688-b873-eb758e40dc27',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'insertion',
            options_2: 'integral'
        },
        {
            name: 'SERIES VM 440',
            nameType: 'VALGEN-RB',
            type: 'val',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FValuMass-440-Insertion.png?alt=media&token=1e742f5c-3f2e-4497-96f4-2154c833a5f4',
            link: '/products/val/insertion/val-lm',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000002_RevA%20Valumass%20Manual.pdf?alt=media&token=03025f6a-9bf0-44ac-8c69-2d0cb37a06b4',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%20400%20VALUMASS.pdf?alt=media&token=f0bd4ff9-f0dc-4a37-922b-5f0a59442fd5',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FVAL%20Series%20400-440%20Wiring.pdf?alt=media&token=37036fc8-339b-4b05-bdb7-f69f26505102',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'insertion',
            options_2: 'integral'
        },
        // {
        //     name: 'SERIES VM 540',
        //     nameType: 'VALGEN-LMR',
        //     type: 'val',
        //     image: '',
        //     link: '/products/val/insertion/val-lmr',
        //     options: 'insertion',
        //     options_2: 'remote'
        // },
        // {
        //     name: 'SERIES VM 440',
        //     nameType: 'VALGEN-RBR',
        //     type: 'val',
        //     image: '',
        //     link: '/products/val/insertion/val-rbr',
        //     options: 'insertion',
        //     options_2: 'remote'
        // },
        {
            name: 'SERIES 800 HPX',
            nameType: 'PUREGEN-LM',
            type: 'pure',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-800-HPX-Inline.png?alt=media&token=7dbd1080-5f36-494c-a8bf-be25828886b9',
            link: '/products/pure/inline/pure-lm',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000002_RevA%20Valumass%20Manual.pdf?alt=media&token=03025f6a-9bf0-44ac-8c69-2d0cb37a06b4',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%20800HPX.pdf?alt=media&token=54438bdc-97fe-4902-acc0-513597a0983c',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FHPX.png?alt=media&token=da2df853-ac52-46a2-af80-92651a629792',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'inline',
            options_2: 'integral'
        },
        // {
        //     name: 'SERIES 800 HPX',
        //     nameType: 'PUREGEN-LMR',
        //     type: 'pure',
        //     image: '',
        //     link: '/products/pure/inline/pure-lmr',
        //     options: 'inline',
        //     options_2: 'remote'
        // },
        {
            name: 'SERIES 800 HPN',
            nameType: 'PUREGEN-RB',
            type: 'pure',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-800-HPN-Inline.png?alt=media&token=b9000367-1efc-483d-a94a-33924d9f6749',
            link: '/products/pure/inline/pure-rb',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000002_RevA%20Valumass%20Manual.pdf?alt=media&token=03025f6a-9bf0-44ac-8c69-2d0cb37a06b4',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%20800HPN.pdf?alt=media&token=d790b5e3-41e4-4850-8b90-99581a1168ad',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FHPN.png?alt=media&token=e5ce61d3-275a-4ffe-bea6-785d4cc7950a',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'inline',
            options_2: 'integral'
        },
        // {
        //     name: 'SERIES 800 HPM',
        //     nameType: 'PUREGEN-RBR',
        //     type: 'pure',
        //     image: '',
        //     link: '/products/pure/inline/pure-rbr',
        //     options: 'inline',
        //     options_2: 'remote'
        // },
        {
            name: 'SERIES 8200 MPHT',
            nameType: '525CR',
            type: 'xt',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8200-MPHT-Insertion-Remote.png?alt=media&token=f4c16e41-9f8b-4975-8b5a-d36c9b4b4465',
            link: '/products/xt/insertion/525cr',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000004_RevA_MP%20Series%20Manual.pdf?alt=media&token=e9863e76-f0ee-4917-a5ab-f4a134b4ca99',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%208200MPHT.pdf?alt=media&token=74d262c9-e0be-42e0-a17a-00bf477b290a',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FXT.png?alt=media&token=12abd3ec-2253-4c1e-943f-a8741abb735b',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'insertion',
            options_2: 'remote'
        },
        // {
        //     name: 'SERIES 8200 MPHT',
        //     nameType: '300CR',
        //     type: 'xt',
        //     image: '',
        //     link: '/products/xt/inline/300cr',
        //     options: 'inline',
        //     options_2: ''
        // },
        // {
        //     name: 'SERIES 8200 MPHT',
        //     nameType: '300CR',
        //     type: 'xt',
        //     image: '',
        //     link: '/products/xt/insertion/525cr',
        //     options: 'insertion',
        //     options_2: 'remote'
        // },
        {
            name: 'SERIES 8800 MPHT',
            nameType: '200C',
            type: 'xt',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8800-MPNH-Insertion.png?alt=media&token=06fdd9df-bd8b-4e29-9370-2b31c34a4f9b',
            link: '/products/xt/insertion/200c',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000004_RevA_MP%20Series%20Manual.pdf?alt=media&token=e9863e76-f0ee-4917-a5ab-f4a134b4ca99',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%208800MPHT.pdf?alt=media&token=6adb59cb-d08e-4521-9470-8a1892e5cbe0',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FXT.png?alt=media&token=12abd3ec-2253-4c1e-943f-a8741abb735b',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'insertion',
            options_2: 'integral'
        },
        // {
        //     name: 'SERIES 8200 MPHT',
        //     nameType: '200CR',
        //     type: 'xt',
        //     image: '',
        //     link: '/products/xt/insertion/200cr',
        //     options: 'insertion',
        //     options_2: 'remote'
        // },
        // {
        //     name: 'SERIES 9000 MP',
        //     nameType: 'MULTIGEN',
        //     type: 'multigen',
        //     image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-9000-MP-Multipoint.png?alt=media&token=3433ecd1-956c-4028-aaaf-b0dc7dacaca4',
        //     link: '/products/multigen/insertion/200cr',
        //     downloads:{
        //         manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000005_RevA_MPNH%20Manual.pdf?alt=media&token=f7c3ffa8-0136-4222-8257-d07cdea925f2',
        //         brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%208600MP-8700MP.pdf?alt=media&token=2e2e0e38-75aa-46aa-ac0b-f0b931b5defb',
        //         wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FHAZ%20MP%20Wiring.pdf?alt=media&token=a144c094-3347-4a8b-8988-c9793c240f82',
        //         installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
        //         cad: ''
        //     },
        //     options: 'insertion',
        //     options_2: 'remote'
        // },
        {
            name: 'SERIES 7000-7100',
            nameType: 'SWITCHGEN',
            type: 'switch',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-7000-Inline.png?alt=media&token=49801d08-51d1-4d02-ab1a-ae5d61b4ea54',
            link: '/products/switch/inline/gen',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F7000.pdf?alt=media&token=b04c8ea7-c96c-47f7-b43b-3c7212d56d64',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%207000-7200.pdf?alt=media&token=d1e527e3-5728-461e-a15b-16cafd9e4889',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FSwitch%20Wiring%20Diagram.pdf?alt=media&token=707e8e5c-d486-4f7e-9f73-36bd444616b5',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'inline',
            options_2: 'integral'
        },
        {
            name: 'SERIES 7800',
            nameType: 'SWITCHGEN',
            type: 'switch',
            image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-7200-Insertion.png?alt=media&token=dfcc21f8-cdb2-4cc1-af72-1ebba9577f09',
            link: '/products/switch/insertion/gen',
            downloads:{
                manual: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F7000.pdf?alt=media&token=b04c8ea7-c96c-47f7-b43b-3c7212d56d64',
                brouchure: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%207000-7200.pdf?alt=media&token=d1e527e3-5728-461e-a15b-16cafd9e4889',
                wiring: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FSwitch%20Wiring%20Diagram.pdf?alt=media&token=707e8e5c-d486-4f7e-9f73-36bd444616b5',
                installation: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
                cad: ''
            },
            options: 'insertion',
            options_2: 'integral'
        }
    ];

    const showDownload = (data) => {
        setDownloadModal(true)
        setDownloadData(data)
    }

    return (
      <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>EPI – Download EPI’s Meter Information</title>
                <meta name="description" content="Find all EPI’s downloadable files for our thermal flow meters: from software to wiring diagrams. " />
                <link rel="canonical" href="https://epiflow.com/support/downloads" />
            </Helmet>

            {/* section-1 */}
            <div className='dl-section-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/support" tag={Link}>Support</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/support/downloads" tag={Link}>Downloads</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Downloads</h2>
                        <p className='cover-title-p'>Here you will find all of the downloads for our meters, from software to wiring diagrams.</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='ap-section-2'>
                <div className="body-main ap-body-main">
                    <div className='bm-child-1'>
                        {/* <Sidebar></Sidebar> */}
                        <SidebarDownloads
                            meterOptions={meterOptions}
                            setMeterOptions={setMeterOptions}
                            secondOptions={secondOptions}
                            setSecondOptions={setSecondOptions}
                            setThirdOptions={setThirdOptions}
                        />
                    </div>
                    <div className='bm-child-2 ap-bm-child-2'>
                        <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                            <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                        </div>
                        {isOpen ? (<div className='mobile-sidebar'><SidebarDownloads /></div>) : undefined}

                        <div className='common-main'>
                            <div className='row ap-custom-row'>
                              <div className='selectedFilters'>
                                <div className="left">
                                  <span>Selected Filters</span>
                                  <h4>
                                      {meterOptions}
                                      { secondOptions && ' - ' }
                                      { secondOptions == ''? (' - All') : (secondOptions)}
                                  </h4>
                                </div>
                                <div className="right">
                                  <button className="btn btn-secondary" onClick={() => clearFilter()}>Clear Filters</button>
                                </div>
                              </div>
                                { meters.map( value => {
                                    if (value.options !== meterOptions){
                                        return null;
                                    }

                                    // console.log('Show me all products 1.0 =>', value);

                                    if (secondOptions && value.options_2 !== secondOptions){
                                        return null;
                                    }

                                    if (thirdOptions && thirdOptions && value.type !== thirdOptions){
                                        return null;
                                    }

                                    value.downloads['name'] = value.name

                                    return(
                                        <div className='col-sm-4 col-md-3 text-center'>
                                            <img src={value.image? (value.image) : (meterimg)} alt="" />
                                            <p className='meter-name'>
                                                {value.name}<br />
                                                <span style={{ 'font-size': '14px', 'color': '#4B5976' }}>{value.nameType}</span>
                                            </p>
                                            <Button
                                                className="button2 loginbtn"
                                                style={{ 'font-size': '1.0em', 'margin': '0px' }}
                                                onClick={() => showDownload(value.downloads)}
                                            >
                                                View Downloads
                                            </Button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <DownloadModal
                        setDownloadModal={setDownloadModal}
                        downloadModal={downloadModal}
                        downloadData={downloadData}
                    />
                </div>
            </div>
            {/* section-2 */}

            {/* section-3 */}
            <div className='ap-section-3 d-flex justify-content-center pt-5 pb-5'>
                <div>
                    <h3 className='commontitleOne text-center'>Can't find the meter or information you <br /> are looking for?</h3>
                    <p className='commonParaOne text-center'>We're here to help!</p>
                    <Row className='text-center'>
                        <Col md="6" sm="12" className='d-flex justify-content-md-end justify-content-sm-center'>
                            <NavLink to="/support" tag={Link}>
                                <Button className="button1 loginbtn" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                                    View Support
                                </Button>
                            </NavLink>
                        </Col>
                        <Col md="6" sm="12" className='d-flex justify-content-md-start justify-content-sm-center'>
                            <NavLink to="/contactus" tag={Link}>
                                <Button className="button2 loginbtn" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                                    Contact Us
                                </Button>
                            </NavLink>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* section-3 */}
        </>
    )
}

export default Downloads
