import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import useSwr from 'swr';
import './Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFileInvoiceDollar,
    faAddressCard,
    faHome,
    faFax,
    faCogs,
    faLifeRing,
    faShieldAlt,
    faMoneyCheck,
    faLock,
    faTruckLoading,
    faHourglassEnd,
    faTruck,
    faBox,
    faAddressBook,
    faUser,
    faUsers,
    faEye,
    faTools,
    faHistory,
    faCartArrowDown
} from '@fortawesome/free-solid-svg-icons';
import{ NavLink } from "reactstrap";
import fetcher from 'utils/fetcher';

function Sidebar({ page } ) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [userData, setUserData] = useState([]);
    var { data: user_data } = useSwr(`/api/me`, fetcher);

    useEffect(() => {
      if(user_data){
        setUserData(user_data);
      }
    }, [user_data]);

    return (
        <div className='sidebar-main'>
            {userData.account_type && userData.account_type === 'admin' &&
                <>
                    <div className='mt-5 mb-4'>
                        <h5>Dashboard</h5>
                        <NavLink tag={Link} from="*" to="/portal/quotes/all/real" style={{ 'padding': '0px'}}>
                            <table style={page.allQuotesReal? {'color': '#0561FF'} : {'': ''}}>
                                <tr>
                                    <td className='first-td'><FontAwesomeIcon icon={faFileInvoiceDollar} className='icon'></FontAwesomeIcon></td>
                                    <td><span>ALL QUOTES</span></td>
                                </tr>
                            </table>
                        </NavLink>

                        {/* <NavLink tag={Link} from="*" to="/portal/quotes/all" style={{ 'padding': '0px'}}>
                            <table style={page.allquotesa? {'color': '#0561FF'} : {'': ''}}>
                                <tr>
                                    <td className='first-td'><FontAwesomeIcon icon={faFileInvoiceDollar} className='icon'></FontAwesomeIcon></td>
                                    <td><span>ADMIN QUOTES</span></td>
                                </tr>
                            </table>
                        </NavLink> */}
                        <hr />

                        <NavLink tag={Link} from="*" to="/portal/quotes/all" style={{ 'padding': '0px'}}>
                            <table style={page.allConfigs? {'color': '#0561FF'} : {'': ''}}>
                                <tr>
                                    <td className='first-td'><FontAwesomeIcon icon={faTools} className='icon'></FontAwesomeIcon></td>
                                    <td><span>ALL CONFIGURATIONS</span></td>
                                </tr>
                            </table>
                        </NavLink>
                        <hr />

                        <NavLink tag={Link} from="*" to="/portal/users" style={{ 'padding': '0px'}}>
                            <table style={page.allUsers? {'color': '#0561FF'} : {'': ''}}>
                                <tr>
                                    <td className='first-td'><FontAwesomeIcon icon={faUsers} className='icon'></FontAwesomeIcon></td>
                                    <td><span>ALL REGISTERED USERS</span></td>
                                </tr>
                            </table>
                        </NavLink>
                        <hr />

                        <NavLink tag={Link} from="*" to="/portal/dashboard" style={{ 'padding': '0px'}}>
                            <table style={page.dashboard? {'color': '#0561FF'} : {'': ''}}>
                                <tr>
                                    <td className='first-td'><FontAwesomeIcon icon={faEye} className='icon'></FontAwesomeIcon></td>
                                    <td><span>REQUESTS</span></td>
                                </tr>
                            </table>
                        </NavLink>

                        {/* <NavLink tag={Link} from="*" to="/portal/viewprices" style={{ 'padding': '0px'}}>
                            <table style={page.viewPrices? {'color': '#0561FF'} : {'': ''}}>
                                <tr>
                                    <td className='first-td'><FontAwesomeIcon icon={faEye} className='icon'></FontAwesomeIcon></td>
                                    <td><span>PRICING REQUESTS</span></td>
                                </tr>
                            </table>
                        </NavLink> */}
                        <hr />

                        {/* <NavLink tag={Link} from="*" to="/portal/distributors" style={{ 'padding': '0px'}}>
                            <table style={page.distributors? {'color': '#0561FF'} : {'': ''}}>
                                <tr>
                                    <td className='first-td'><FontAwesomeIcon icon={faUsers} className='icon'></FontAwesomeIcon></td>
                                    <td><span>APPROVED DISTRIBUTORS</span></td>
                                </tr>
                            </table>
                        </NavLink>

                        <NavLink tag={Link} from="*" to="/portal/viewprices/approved" style={{ 'padding': '0px'}}>
                            <table style={page.approvedPricings? {'color': '#0561FF'} : {'': ''}}>
                                <tr>
                                    <td className='first-td'><FontAwesomeIcon icon={faEye} className='icon'></FontAwesomeIcon></td>
                                    <td><span>APPROVED PRICING</span></td>
                                </tr>
                            </table>
                        </NavLink> */}
                    </div>
                </>
            }

            <div className={userData.account_type && userData.account_type === 'admin' ? ('mb-4'): ('mt-5 mb-4')}>
                <h5>My Meters</h5>
                  <NavLink tag={Link} from="*" to="/portal/cart" style={{ 'padding': '0px'}}>
                      <table style={page.cart? {'color': '#0561FF'} : {'': ''}}>
                          <tr>
                              <td className='first-td'><FontAwesomeIcon icon={faCartArrowDown} className='icon'></FontAwesomeIcon></td>
                              <td><span>CART</span></td>
                          </tr>
                      </table>
                  </NavLink>
                  {/* <hr /> */}
                  {/* <NavLink tag={Link} from="*" to="/portal/quotes/real/s" style={{ 'padding': '0px'}}>
                      <table style={page.allQuotesRealSingle? {'color': '#0561FF'} : {'': ''}}>
                          <tr>
                              <td className='first-td'><FontAwesomeIcon icon={faFileInvoiceDollar} className='icon'></FontAwesomeIcon></td>
                              <td><span>SAVED QUOTES</span></td>
                          </tr>
                      </table>
                  </NavLink> */}
                  <hr />
                  <NavLink tag={Link} from="*" to="/portal/quotes" style={{ 'padding': '0px'}}>
                      <table style={page.quotes? {'color': '#0561FF'} : {'': ''}}>
                          <tr>
                              <td className='first-td'><FontAwesomeIcon icon={faTools} className='icon'></FontAwesomeIcon></td>
                              <td><span>SAVED CONFIGURATIONS</span></td>
                          </tr>
                      </table>
                  </NavLink>
                {/* <NavLink tag={Link} from="*" to="/portal/registerProduct" style={{ 'padding': '0px'}}>
                    <table style={page.RegisterNewProduct? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faTag} className='icon'></FontAwesomeIcon></td>
                            <td>REGISTERED PRODUCTS</td>
                        </tr>
                    </table>
                </NavLink> */}
            </div>
            <div className='mb-4'>
                <h5>Account</h5>
                <NavLink tag={Link} from="*" to="/portal/editAccount" style={{ 'padding': '0px'}}>
                    <table style={page.EditAccount? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faAddressCard} className='icon'></FontAwesomeIcon></td>
                            <td><span>EDIT ACCOUNT</span></td>
                        </tr>
                    </table>
                </NavLink>
                <NavLink tag={Link} from="*" to="/portal/myAddresses" style={{ 'padding': '0px'}}>
                    <table style={page.myAddresses? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faAddressBook} className='icon'></FontAwesomeIcon></td>
                            <td><span>MY ADDRESSES</span></td>
                        </tr>
                    </table>
                </NavLink>
                <NavLink tag={Link} from="*" to="/portal/becomeAdistributor" style={{ 'padding': '0px'}}>
                    <table style={page.BecomeDistributor? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faHome} className='icon'></FontAwesomeIcon></td>
                            <td><span>BECOME A DISTRIBUTOR</span></td>
                        </tr>
                    </table>
                </NavLink>
                <NavLink tag={Link} from="*" to="/portal/RequestPricingAccess" style={{ 'padding': '0px'}}>
                    <table style={page.RequestPricingAccess? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faEye} className='icon'></FontAwesomeIcon></td>
                            <td><span>REQUEST PRICING ACCESS</span></td>
                        </tr>
                    </table>
                </NavLink>
            </div>
            <div className='mb-4'>
                <h5>Support</h5>
                <NavLink tag={Link} from="*" to="/portal/contactUs" style={{ 'padding': '0px'}}>
                    <table style={page.contactUs? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faFax} className='icon'></FontAwesomeIcon></td>
                            <td><span>CONTACT US</span></td>
                        </tr>
                    </table>
                </NavLink>
                <NavLink tag={Link} from="*" to="/portal/serviceRequests" style={{ 'padding': '0px'}}>
                   <table style={page.ServiceRequest? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faCogs} className='icon'></FontAwesomeIcon></td>
                            <td><span>SERVICE REQUESTS</span></td>
                        </tr>
                    </table>
                </NavLink>
                <NavLink tag={Link} from="*" to="/portal/techSupport" style={{ 'padding': '0px'}}>
                    <table style={page.techSupport? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faLifeRing} className='icon'></FontAwesomeIcon></td>
                            <td><span>TECH SUPPORT</span></td>
                        </tr>
                    </table>
                </NavLink>
                <NavLink tag={Link} from="*" to="/portal/RMA" style={{ 'padding': '0px'}}>
                    <table style={page.RMA? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faBox} className='icon'></FontAwesomeIcon></td>
                            <td><span>RMA REQUEST</span></td>
                        </tr>
                    </table>
                </NavLink>
            </div>
            <div className='mb-4'>
                <h5>Policies</h5>
                <NavLink tag={Link} from="*" to="/portal/warranty" style={{ 'padding': '0px'}}>
                    <table style={page.warranty? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faShieldAlt} className='icon'></FontAwesomeIcon></td>
                            <td><span>WARRANTY</span></td>
                        </tr>
                    </table>
                </NavLink>
                <NavLink tag={Link} from="*" to="/portal/paymentOptions" style={{ 'padding': '0px'}}>
                    <table style={page.PaymentOptions? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faMoneyCheck} className='icon'></FontAwesomeIcon></td>
                            <td><span>PAYMENT OPTIONS</span></td>
                        </tr>
                    </table>
                </NavLink>
                <NavLink tag={Link} from="*" to="/portal/privacyPolicy" style={{ 'padding': '0px'}}>
                    <table style={page.privacypolicy? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faLock} className='icon'></FontAwesomeIcon></td>
                            <td><span>PRIVACY POLICY</span></td>
                        </tr>
                    </table>
                </NavLink>
                <NavLink tag={Link} from="*" to="/portal/deliveryTime" style={{ 'padding': '0px'}}>
                    <table style={page.deliveryTime? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faTruckLoading} className='icon'></FontAwesomeIcon></td>
                            <td><span>SHIPPING INFO</span></td>
                        </tr>
                    </table>
                </NavLink>
                <NavLink tag={Link} from="*" to="/portal/returnPolicy" style={{ 'padding': '0px'}}>
                    <table style={page.returnPolicy? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faTruck} className='icon'></FontAwesomeIcon></td>
                            <td><span>RETURN POLICY</span></td>
                        </tr>
                    </table>
                </NavLink>
                <NavLink tag={Link} from="*" to="/portal/responseTimes" style={{ 'padding': '0px'}}>
                    <table style={page.responseTimes? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faHourglassEnd} className='icon'></FontAwesomeIcon></td>
                            <td><span>RESPONSE TIMES</span></td>
                        </tr>
                    </table>
                </NavLink>
                {/* <NavLink tag={Link} from="*" to="/portal/packingMaterials" style={{ 'padding': '0px'}}>
                    <table style={page.packingMaterials? {'color': '#0561FF'} : {'': ''}}>
                        <tr>
                            <td className='first-td'><FontAwesomeIcon icon={faCube} className='icon'></FontAwesomeIcon></td>
                            <td><span>PACKING MATERIALS</span></td>
                        </tr>
                    </table>
                </NavLink> */}
            </div>
        </div>
    );
};

export default Sidebar;
