/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import useSwr from 'swr';
import fetcher from 'utils/fetcher';

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  NavLink
} from "reactstrap";

function TechSupportFooter() {
  const [auth, setAuth] = useState(false);
  const { data } = useSwr(`/api/me`, fetcher);
  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(data){
      setAuth(true);
    }
  }, [data]);

  return(
    <>
      <div className='background-colorOne pt-5 pb-5'>
        <Container className='text-center'>
          <h3 className='commontitleOne pt-5 mb-4'>Open a Technical Support Ticket</h3>
          {!auth && <p className='commonParaThree'>You must be logged in to open a service request; please log in or sign up for an EPI account.</p>}
          {!auth &&
            <Row className='text-center'>
              <Col md="6" sm="12" className='d-flex justify-content-md-end justify-content-sm-center'>
                  <div className='pt-4 pb-5'>
                  <NavLink tag={Link} to="/login">
                      <Button className="button1 loginbtn" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                      Log In
                      </Button>
                  </NavLink>
                  </div>
              </Col>
              <Col md="6" sm="12" className='d-flex justify-content-md-start justify-content-sm-center'>
                  <div className='pt-4 pb-5'>
                  <NavLink tag={Link} to="/signup">
                      <Button className="button2 loginbtn" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                      Sign Up
                      </Button>
                  </NavLink>
                  </div>
              </Col>
            </Row>
          }

          {auth && <p className='commonParaThree'>Use the buttons below to select which request you'd like to create.</p>}
          {auth &&
            <Row className='text-center'>
              <Col md="6" sm="12" className='d-flex justify-content-md-end justify-content-sm-center'>
                <div className='pt-4 pb-5'>
                  <NavLink tag={Link} to="/login">
                    <Button className="button1 loginbtn" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                      Open Service Request
                    </Button>
                  </NavLink>
                </div>
              </Col>
              <Col md="6" sm="12" className='d-flex justify-content-md-start justify-content-sm-center'>
                <div className='pt-4 pb-5'>
                  <NavLink tag={Link} to="/signup">
                    <Button className="button2 loginbtn" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                      Open RMA Request
                    </Button>
                  </NavLink>
                </div>
              </Col>
            </Row>
          }
        </Container>
      </div>
    </>
  );
}

export default TechSupportFooter;
