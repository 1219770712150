import React, { useState, useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";

function NaturalGas() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [color, setColor] = useState('#095CF6');
    const handleBtn = () => {
        setColor('#fff')
    }
    const handleBtnOut = () => {
        setColor('#095CF6')
    }
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Natural Gas Flow Meters </title>
            <meta name="description" content="Thermal Mass Flow Gas Meters are utilized extensively in industrial and commercial applications, as well as widespread home use. Let’s have a look!" />
            <link rel="canonical" href="https://epiflow.com/whitepapers/naturalgas" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner NGSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/whitepapers" tag={Link}>White Papers</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/whitepapers/naturalgas" tag={Link}>Natural Gas</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Natural Gas Flow Measurement Meter</h2>
                        <p className='cover-title-p'>Everything you need to know about EPI.</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <Container className='py-5'>
                <div className='DownloadPDfbtn-div'>
                    <div className='position-relative d-inline'>
                        <button
                            className='button2'
                            onMouseOver={handleBtn}
                            onMouseOut={handleBtnOut}
                        >
                            Download as PDF
                        </button>
                        <FontAwesomeIcon
                            className='DownloadPDfbtn-icon'
                            style={{ color: color }}
                        />
                    </div>
                </div>
                <h4 className='commontitleTwo text-center mb-4'>
                    Thermal Mass Flow Natural Gas Metering
                </h4>
                <p className='commonParaThree'>
                    Thermal mass flow natural gas metering is a key tool <br /> <br />
                    Natural gas is a fossil fuel composed of a mixture of hydrocarbon gases. The primary components are methane, ethane and propane. It is the cleanest
                    burning fossil fuel, producing byproducts of mostly carbon dioxide and water vapor. Natural gas provides approximately 25% of the United States’ total
                    energy consumption. The United States annually produces about 24% of the world’s natural gas supply. Natural gas is used extensively in industrial
                    and commercial applications, as well as widespread home use. Natural Gas flow meters from Eldridge Products can be very useful in the measurement of
                    natrual gas flow.
                </p><br />
                <h4 className='commontitleTwo text-center mb-4'>
                    Composition & Properties
                </h4>
                <p className='commonParaThree'>
                    As stated above, natural gas is made up of many hydrocarbons. Although natural gas is primarily a mixture of methane, ethane and propane, the remaining
                    gases and their percentage of the overall composition can vary greatly. For example, the trace levels of H2S in the mixture sometimes lead to the
                    natural gas being labeled as “sweet” or “sour”. However, the typical gas composition and the common range of variation are:
                </p><br />
            </Container>

            {/* table start here */}

            <div className='background-colorOne'>
                <Container className='py-5'>
                    <div style={{ overflowX: 'scroll' }}>
                        <table class="table ts-s6-table natrualgas-table">
                            <thead>
                                <tr>
                                    <th width="50%" scope="col">Gas Component</th>
                                    <th width="25%" scope="col">Typical %</th>
                                    <th width="25%" scope="col">Range %</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Methane</th>
                                    <th>94.9</th>
                                    <th>87.0 &#8211; 96.0</th>
                                </tr>
                                <tr>
                                    <th scope="row">Propane</th>
                                    <th>2.5</th>
                                    <th>1.8 &#8211; 5.1</th>
                                </tr>
                                <tr>
                                    <th scope="row">Other Gases</th>
                                    <th>0.2</th>
                                    <th>0.1 &#8211; 1.5</th>
                                </tr>
                                <tr>
                                    <th scope="row">Water Vapor</th>
                                    <th>2.4</th>
                                    <th>2.0 &#8211; 4.0</th>
                                </tr>
                                <tr>
                                    <th scope="row">Methane</th>
                                    <th>&#60; 80 mg/m3</th>
                                    <th>16 &#8211; 32 mg/m3</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Container>
            </div>
            {/* table end here */}
            <Container className='py-5'>
                <p className='commonParaThree mt-3'>
                    Sulphur odorant: 5 mg/m3 typical <br />
                    Specific Gravity Range: 0.57 &#8211; 0.70
                </p>
                <h4 className='commontitleTwo text-center mb-4'>
                    Distribution
                </h4>
                <p className='commonParaThree'>
                    Natural gas gets to the consumer in three steps: <br /> <br />
                    Production companies explore, drill and extract the gas from the ground, through over 280,000 natural gas wells. The gas flows from higher pressure to lower pressure to get to the well head.
                    <br />

                    Transmission companies, 125 in all, send the gas under high pressure through 1.2 million miles of U.S. pipelines ranging in size from 20 inches to 42 inches. Compressor stations are located
                    on the average about every 55 miles across the pipeline. Pipeline pressure can be as low as 200 Pounds per Square Inch Gage pressure (PSIG) to as high as 1,500 PSIG.
                    <br />

                    Over 1,200 distribution companies (utilities) supply the gas to the consumers’ gas meter in all 50 states. A Sulphur odorant is added to the natural gas for safety purposes. Distribution lines,
                    or mains, can vary in size from 2 inches to 24 inches. The closer the natural gas gets to the consumer, the smaller the pipe and the lower the pressure. The delivered natural gas pressure to
                    the consumer can vary from as low as 0.25 PSIG to as high as 100 PSIG.
                </p>

                <h4 className='commontitleTwo text-center mb-4'>
                    Measurement and Heat Value
                </h4>
                <p className='commonParaThree'>
                    Natural gas is often measured in British Thermal Units (BTUs). One BTU is equal to the amount of heat required to raise the temperature of one pound of water one degree Fahrenheit at atmospheric
                    pressure. One cubic foot of natural gas has an average heating capacity of approximately 1000 BTUs. The actual heating capacity varies across the country, and from time to time at any given location,
                    due to the previously noted variations in the gas mixture. It is typical to see variations of +/- 25, 50, or more BTU for a range of 950 to 1050 BTU’s. The utility company’s meter at the building
                    exterior measures the consumed volume in hundreds of cubic feet (CCF); however the consumer’s bill normally measures the consumption in therms, where one therm is equal to 100,000 BTUs.
                </p>

                <h4 className='commontitleTwo text-center mb-4'>
                    Gas Billing
                </h4>
                <p className='commonParaThree'>
                    Customers are billed by taking the gas meter reading in cubic feet and converting this value to therms and applying a thermal factor that is the product of the heat value of the gas times the gas density.
                    <br /><br />

                    The formula for therms is:<br />
                    therms = cubic feet x 1000 BTU’s per cubic foot / 100,000<br /><br />
                    Assume that the heat value is 1025 BTU’s per cubic foot at 2.5 PSIG:<br />
                    1025/1000 = 1.025 multiplier due to gas heat (energy) value;<br />
                    (2.5 + 14.7) / 14.7 = 1.170 multiplier due to gas density.<br />
                    The billing (or thermal) correction factor formula is:<br />
                    1.025 x 1.170 or 1.199.<br />
                    Result is:<br />
                    $/therm x therms consumed x 1.199 = billing for gas used.<br /><br />
                    The energy value of the gas in BTU’s is normally reliable, provided the utility company measures this value throughout the year and integrates it over time. However, the gas density due to pressure makes up
                    the largest portion of the billing or thermal correction, and this is the most likely area for billing mistakes from the local gas utility. Consider the following example:
                    <br />

                    The consumer is supplied natural gas at 0.25 PSIG but is billed at a pressure of 2.5 PSIG. How much is he being overcharged?
                    2.5 + 14.7 / (0.25 + 14.7) = 1.151 or 15.1% overcharge on his utility bill.
                </p>
                <h4 className='commontitleTwo text-center mb-4'>
                    Could this really happen?
                </h4>
                <p className='commonParaThree'>
                    Not only can it happen, but it happens daily throughout the country! Natural gas consumers who have purchased flow meters to audit the gas utility have, on a number of occasions, found discrepancies between
                    the billing totals and flow meter totals. Upon further investigation, they have found the wrong gas density being used in the correction for the therms energy value and therefore the subsequent calculations
                    for their utility charges. Depending on the total monthly usage, these discrepancies can be significant, resulting in artificially and inaccurately high overall operating costs.
                    <br /><br />

                    Submetering the natural gas usage at strategically selected points in a facility shows how much gas is actually used at each metered location or work area and provides an overall total for the facility.
                    This total is compared to the utility’s billing total as a means of verifying or disputing the charges. The key element in efficient submetering is a flow meter that measures the natural gas flow accurately
                    without duplicating the utility company’s need for complicated and potentially erroneous corrections based on actual gas temperature and pressure. Thermal natural gas mass flow meters, such as EPI’s Master-Touch™,
                    are ideally suited to this purpose. Contact Eldridge products today to learn more about natural gas flow measurement.
                </p>
            </Container>
        </>
    );
}

export default NaturalGas;
