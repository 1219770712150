import React,{ useState, useEffect } from 'react';
import useSwr from 'swr';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import fetcher from 'utils/fetcher';
import './PrivacyPolicy.css'

function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const page = { privacypolicy: true }

    var { data } = useSwr(`http://localhost:1337/api/me`, fetcher);
    useEffect(() => {
        if(data){
            setUserData(data);
        }
    }, [data]);

    const headerData = {
        firstname: userData.firstname,
        page: 'PRIVACY POLICY'
    }
    return (
        <div className='privacypolicy'>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar> </Sidebar></div>) : undefined}
                    <div className='common-main'>
                        <h2 className='p-3'>Privacy Policy</h2>
                        <div className='bdr body p-3'>
                            <h4>Our Terms & Conditions</h4>
                            <p>These terms and conditions govern the sale of Products ("Products") and the provision of services ("Services") by Eldridge Products, Inc. (EPI) and its divisions, subsidiaries, and affiliates ("Seller") as well as by third party vendors and/or service providers of Seller. These terms and conditions ("Agreement") take precedence over Buyer's supplemental or conflicting terms and conditions to which notice of objection is hereby given. Buyer's acceptance of the Products and/or Services from Seller shall be deemed to constitute acceptance of the terms and conditions contained herein.
                            <br /><br />
                            THESE TERMS AND CONDITIONS MAY ONLY BE WAIVED OR MODIFIED IN A WRITTEN AGREEMENT SIGNED BY AN AUTHORIZED OFFICER OF SELLER.
                            </p>
                            <hr />
                            <h4>1. Orders</h4>
                            <p>
                              All orders for Seller's products or services placed by Buyer are subject to confirmation and acceptance by Seller at its factory in Monterey County, California. Orders may not be cancelled or rescheduled without Seller's written consent. All purchase orders must include requested delivery dates, quantities, and complete description of Products being purchased.
                            </p>
                            <h4>Data Security</h4>
                            <p>
                              (a) Seller's written price quotations are subject to change upon written notice to the Buyer and are void after 30 days unless otherwise specified in the price quotation. All of Seller's published price lists are subject to change without notice. The prices shown on Seller's published price lists, including its website, are not offers to sell and are subject to confirmation by Seller's written price quotation and Sales Order Acknowledgement. Pricing for undelivered products or services may be increased if there are increased costs as a result of Buyer's delay or other Buyer change requests, a change in market conditions resulting in increased costs to Seller or any other cost increases beyond the Seller's control.
                              <br /><br />
                                (b) All prices quoted are exclusive of transportation and insurance costs, duties, and all taxes   including, but not limited to, federal, state, provincial and local sales, excise, value added, goods and services taxes and any other taxes. Buyer agrees to directly pay these taxes when due. In the state of California, if Buyer claims a resale tax exemption, Buyer must provide Seller with a California tax exemption resale certificate Buyer agrees to indemnify and hold Seller harmless for any liability for tax in connection with the sale, as well as the collection or withholding thereof, including penalties and interest thereon. When applicable, charges for shipping & handling and taxes shall appear as separate items on Seller's invoice.
                            </p>
                            <h4>3. Payment</h4>
                            <p>
                              (a) Payment must be made in U.S. Dollars. Full payment is due promptly prior to shipment without credit approval. A deposit on the order in an amount to be determined by Seller may be required, based on the order value, account history and other criteria.
                              <br /><br />
                                (b) Seller reserves the right to establish and/or change credit and payment terms extended to Buyer at Sellers sole discretion, when, in Seller's sole opinion, Buyer's financial condition or previous payment record warrants such action. Further, on delinquent accounts, Seller shall not be obligated to continue performance under any agreement with Buyer.
                              <br /><br />
                                (c) For orders with credit approval, seller's standard terms of payment are net thirty (30) days
                                from the date of emailed invoice or Sellers input of invoice into any automated payment system, or when placed within the U.S. Mail when email or automated payment systems are not utilized, unless otherwise specified in writing by Seller. In addition, Buyer will be deemed to have accepted an invoice upon the fifteenth (15th) day from the date of invoice and thereafter no further objections to the invoice will be permitted or accepted. Seller must specify in writing any inconsistencies or errors in the invoice within this (15) day period from the date of invoice, and no further objections will be permitted or accepted; and Buyer must certify by email to finance@epiflow.com within fifteen (15) days from the date of invoice that payment has been submitted for payment processing. Invoices requiring alteration by Seller shall not cause the original payment due date to be extended.
                              <br /><br />
                                (d) Seller may invoice each shipment separately and each shipment shall be considered a separate and individual contract. Buyer agrees to pay such invoice pursuant to its terms without the benefit of setoff or deduction.
                              <br /><br />
                                (e) All late payments shall be charged interest computed on a daily basis from the due date until paid in full. A late charge of one and one-half percent (1-1/2%) per month or the maximum rate permitted by applicable law, whichever is less, will be imposed on all past due accounts. Buyer shall be liable for costs of collection, including reasonable attorneys' fees and court costs, in any action to collect past due amounts.
                              <br /><br />
                                (f) If Seller believes in good faith that Buyer's ability to make payments may be impaired or if Buyer shall fail to pay any invoice when due, Seller may suspend delivery of any order or any remaining balance thereof until such payment is made or cancel any order or any remaining balance thereof.
                              <br /><br />
                              (g) Any payment received from Buyer may be applied by Seller against any obligation owing from Buyer to Seller, regardless of any statement appearing on or referring to such payment, without discharging Buyer's liability for any additional amounts owing from Buyer to Seller, and the acceptance by Seller of such payment shall not constitute a waiver of Seller's right to pursue the collection of any remaining balance.
                            </p>
                            <h4>4. Delivery, Shipment Costs and Insurance</h4>
                            <p>
                              All deliveries will be made FCA Seller's facility in Monterey County, California (FCA - Free Carrier as defined in Incoterms 2000). Shipping, packaging and insurance charges from Seller's facility to Buyer's facility shall be:
                              <br /><br />
                                (1) paid by Buyer to Seller and charges shall be prepaid and added to the invoice as a line item,
                                and added to the purchase price of the Product or (2) Seller shall have the option to ship freight
                                collect for shipping, packaging and insurance charges or (3) Seller may require the Buyer to
                                directly arrange with carrier for package pickup at Seller's facility with costs against Buyers
                                account. In the absence of specific instructions by Buyer, Seller will select the preferred carrier
                                and shipping method. The carrier insurance value shall be equal to the invoice value of the package contents. Subject to Seller's right of stoppage in transit, delivery of the Products to the carrier shall constitute delivery to Buyer and risk of loss shall thereupon pass to Buyer. Buyer
                                acknowledges that delivery dates provided by Seller are estimates only and Seller shall not be
                                liable for delays in delivery.
                            </p>
                            <h4>5. Title and Security Interest</h4>
                            <p>
                              Seller retains title and a purchase money security interest in the Products delivered to Buyer, and in their accessories, replacements, accessions, proceeds and Products, including accounts receivable (collectively, the "Collateral") until paid in full to secure payment of all amounts due under this Agreement. Buyer's failure to pay all amounts hereunder in full when and as due shall constitute a default hereof and shall give Seller all rights of a secured party. Buyer agrees, from time to time, to perform any act and execute and deliver any document (including, without limitation, financing statements) reasonably requested by Seller to transfer, create, perfect, preserve, protect and enforce this security interest, including the identification of and production of the goods subject to the security interest. Any subsequent purchaser takes property subject to this security interest.
                            </p>
                            <h4>6. Acceptance</h4>
                            <p>
                              Shipments will be deemed to have been accepted by Buyer upon delivery of the said shipments to Buyer unless rejected for cause, upon receipt. Buyer shall perform whatever inspection or tests Buyer deems necessary as promptly as possible but in no event later than five (5) days after delivery, after which time Buyer will be deemed to have irrevocably accepted the Products. Any discrepancy in shipment quantity must be reported within five (5) working days of receipt of the Products.
                            </p>
                            <h4>7. Limited Warranty and Limitation of Liability</h4>
                            <p>
                              (a) Seller warrants its products to be free from defects in materials and workmanship for one year from the date of factory shipment. If there is a defect, the purchaser must notify Seller of the defect within the warranty period. Upon Seller's receipt and confirmation of the defective Product, Seller at its sole option, will either repair the defective Product, or provide a Seller's replacement, or refund the purchase price of the defective Product. Repaired or replaced Products shall be warranted for the remainder of the warranty period. For any of Seller's installed Products that Buyer believes to be defective, Buyer shall immediately discontinue product use.
                              <br /><br />
                                EPI MAKES NO OTHER WARRANTY, EXPRESS OR IMPLIED, AS TO THE PRODUCTS. EPI MAKES NO WARRANTY AND DISCLAIMS ANY WARRANTY THAT THE GOODS SOLD TO ANY PURCHASER ARE FIT FOR ANY PARTICULAR PURPOSE. FURTHERMORE, EPI DISCLAIMS ANY WARRANTY OF MERCHANTABILITY WITH RESPECT TO ANY PRODUCTS SOLD TO ANY PURCHASERS. BUYER SHALL NOT IN ANY EVENT BE ENTITLED TO, AND SELLER SHALL NOT BE LIABLE FOR, INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY NATURE INCLUDING, WITHOUT LIMITATION, BUSINESS INTERRUPTION COSTS, REMOVAL AND/OR REINSTALLATION COSTS, REPROCUREMENT COSTS, LOSS OF PROFIT OR REVENUE, LOSS OF DATA, PROMOTIONAL OR MANUFACTURING EXPENSES, OVERHEAD, INJURY TO REPUTATION OR LOSS OF CUSTOMERS, EVEN IF SELLER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. BUYER'S RECOVERY FROM SELLER FOR ANY CLAIM SHALL NOT EXCEED BUYER'S PURCHASE PRICE FOR THE PRODUCT GIVING RISE TO SUCH CLAIM IRRESPECTIVE OF THE NATURE OF THE CLAIM, WHETHER IN CONTRACT, TORT, WARRANTY, OR OTHERWISE. SELLER SHALL NOT BE LIABLE FOR AND BUYER SHALL INDEMNIFY, DEFEND AND HOLD SELLER HARMLESS FROM ANY CLAIMS BASED ON SELLER'S COMPLIANCE WITH BUYER'S DESIGNS, SPECIFICATIONS OR INSTRUCTIONS, OR MODIFICATION OF ANY PRODUCTS BY PARTIES OTHER THAN SELLER, OR USE IN COMBINATION WITH OTHER PRODUCTS. BUYER FURTHER ACKNOWLEDGES THAT SELLER CANNOT BE HELD LIABLE FOR PUNITIVE OR EXEMPLARY DAMAGES AS WELL.
                                <br /><br />
                                  (b) When applicable, Seller will transfer to Buyer any transferable warranties that a third-party vendor/service provider provides to Seller. SELLER SHALL HAVE NO LIABILITY TOWARDS BUYER THIRD-PARTY- MANUFACTURER PRODUCT WARRANTIES. SELLER ASSUMES NO LIABILITY FOR THE QUALITY OF THE WORK PERFORMED OR SERVICES RENDERED BY THIRD-PARTY VENDORS/SERVICE PROVIDERS. IT IS BUYER'S OBLIGATION TO REQUEST COPIES OF ANY APPLICABLE MANUFACTURER WARRANTIES AND BUYER SHALL BE DEEMED TO HAVE ACCEPTED SUCH WARRANTIES UPON ACCEPTANCE OF THE PRODUCTS AND/OR SERVICES.
                                <br /><br />
                                (c)Warranty period on any out of warranty Products that are repaired or serviced by Seller shall be warranted for a period of 90 days from factory shipment.
                                <br /><br />
                                  (d) No warranty shall apply to any Product that has been subject to misuse, improper testing, disassembly, mishandling, corrosive processes, erosive processes, excessive temperatures, or which has been operated contrary to current written instructions relating to installation, maintenance or operation, or contrary to industry standards.
                                <br /><br />
                                  (e) Seller disclaims, and shall have no liability for any trademark, trade dress, trade secret, copyright, design or patent infringement, or any other intellectual property right, which may occur, as a result of the sale of Products to Buyer and there shall be no remedy or recourse against Seller to the extent the infringement arises from or is otherwise based upon: i) the Seller's compliance with the particular requirements of Buyer that differ from the Seller's standard specifications for the Product; (ii) modifications or alterations of the product other than by the Seller; or (iii) a combination of the Product with other items not furnished or manufactured by the Seller.
                            </p>
                            <h4>8. Third-Party Intellectual Property</h4>
                            <p>
                              If any Product includes any third-party software or other third-party intellectual property, such software or other intellectual property is provided by Seller to Buyer subject to the copyright and user license, if any, for such Products, the terms and conditions of which are set forth in the license agreement accompanying such software or other intellectual property. Nothing herein shall be construed to grant any rights or license to use any software or other intellectual property in any manner or for any purpose not expressly permitted by such license agreement. Buyer acknowledges and understands that Seller is not the manufacturer of any third-party software or other third-party intellectual property to be supplied to Buyer and is not liable to Buyer or any third-party for any copyright, design or patent issue, right or claim that may arise in relation to the use of the third-party software or other third-party intellectual property.
                            </p>
                            <h4>9. Export Control</h4>
                            <p>
                              Buyer certifies that it will be the recipient of Products to be delivered by Seller. Buyer acknowledges that the Products are subject to the export and/or import control laws and regulations of various countries including the Export Administration Laws and Regulations of the United States of America (USA). Buyer agrees to comply strictly with all U.S. export laws and assume sole responsibility for obtaining licenses to export or re-export as may be required and acknowledges that it shall not directly or indirectly export any Products to any country to which such export or transmission is restricted or prohibited. Products sold by Seller cannot be transferred, sold or re-exported to any party on the Restricted Entity List or Restricted Person List of the U.S. Department of Commerce, Bureau of Industry and Security (BIS) or any Restricted party designated by the U.S. Treasury Department's Office of Foreign Assets Control, or any party debarred or sanctioned for proliferation or terrorism reasons by the U.S. State Department.

                            </p>
                            <h4>10. Use of Products</h4>
                            <p>
                              PRODUCTS SOLD BY SELLER ARE NOT DESIGNED, INTENDED OR AUTHORIZED FOR USE IN LIFE SUPPORT, LIFE SUSTAINING, NUCLEAR, AIRCRAFT OR OTHER APPLICATIONS IN WHICH THE FAILURE OF SUCH PRODUCTS COULD REASONABLY BE EXPECTED TO RESULT IN PERSONAL INJURY, LOSS OF LIFE OR CATASTROPHIC PROPERTY DAMAGE. IF BUYER USES OR SELLS THE PRODUCTS FOR USE IN ANY SUCH APPLICATIONS: (1) BUYER ACKNOWLEDGES THAT SUCH USE OR SALE IS AT BUYER'S SOLE RISK; (2) BUYER AGREES THAT SELLER AND THE MANUFACTURER OF THE PRODUCTS ARE NOT LIABLE, IN WHOLE OR IN PART, FOR ANY CLAIM OR DAMAGE ARISING FROM SUCH USE; AND (3) BUYER AGREES TO INDEMNIFY, DEFEND AND HOLD SELLER AND THE MANUFACTURER OF THE PRODUCTS HARMLESS FROM AND AGAINST ANY AND ALL CLAIMS, DAMAGES, LOSSES, COSTS, EXPENSES AND LIABILITIES ARISING OUT OF OR IN CONNECTION WITH SUCH USE OR SALE.
                            </p>
                            <h4>11. Technical Assistance or Advice</h4>
                            <p>
                              Any technical assistance or advice offered by Seller in regard to the use of any Product or provided in connection with Buyer's purchases is given free of charge and only as an accommodation to Buyer. Seller shall have no obligation to provide any technical assistance or advice to Buyer and if any such assistance or advice is provided, such fact will not obligate Seller to provide any further or additional assistance or advice. Seller shall not be held liable for the content or Buyer's use of such technical assistance or advice, nor shall any statement made by any of Seller's Representatives or Distributors in connection with the Products or Services constitute a representation or warranty, express or implied.
                            </p>
                            <h4>12. Limitation Period</h4>
                            <p>
                              Subject to any of the limitations expressed in the applicable manufacturer's warranty, no action by Buyer may be brought at any time for any reason against Seller more than twelve (12) months after the sale and factory shipment of the product.
                            </p>
                            <h4>13. Dispute Resolution</h4>
                            <p>
                              Seller and Buyer agree that any and all disputes, claims, or controversies arising out of or related to the validity, interpretation or performance of this Agreement for all Products-delivered inside or outside of the USA, and all Services performed inside or outside of the USA, shall be governed by, and construed in accordance with, the law of the State of California, without giving effect to conflict of laws principles. Both parties agree that any action, arising out of or relating to the terms and provisions of this Agreement, or to any claimed breach, shall be commenced in the Monterey County Superior Court in and for the State of California, and both parties expressly acknowledge that personal jurisdiction and venue shall lie exclusively therein. Buyer and Seller further agree that the provisions of the United Nations Convention on Contracts for the International Sale of Goods 1980 (as amended, replaced or codified from time to time) shall not apply to the sale of Seller's products.
                            </p>
                            <h4>14. Force Majeure</h4>
                            <p>
                              Seller shall not be liable for its inability to secure sufficient quantities of any Product or failure to deliver due to causes beyond Seller's reasonable control including, but not limited to, Acts of God, natural or artificial disaster, riot, war, strikes, delay by carrier, product or material shortages, acts or omissions of other parties, acts or omissions of civil or military authority, Government priorities, changes in law, fire, floods, epidemics, quarantine restrictions, acts of terrorism, delays in transportation or inability to obtain labor, materials or Products through Seller's regular sources, any and all of which shall be considered as an event of force majeure excusing Seller from performance and barring remedies for non-performance. In an event of force majeure condition, the Seller's time for performance shall be extended for a period equal to the time lost as a consequence of the force majeure condition without subjecting Seller to any liability or penalty. Seller may, at its option, cancel the remaining performance, without any liability or penalty, by giving notice of such cancellation to Buyer.
                            </p>
                            <h4>15. Non-Waiver</h4>
                            <p>
                              No course of dealing or failure of either party to strictly enforce any term, right or condition of this Agreement shall be construed as a waiver of that term, right or condition nor shall Seller's acceptance of a purchase order be deemed as an acceptance of any terms and conditions therein inconsistent with these terms and conditions, unless expressly agreed by the Seller in writing and signed by the President of Seller.
                            </p>
                            <h4>16. Savings Term</h4>
                            <p>
                              If any provision of these terms and conditions is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these terms and conditions, which shall remain in full force and effect.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
