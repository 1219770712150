import React from "react";
import axios from 'axios';
import profileicon from '../../assets/img/images/profile-icon.png';
import { Button, Modal } from "reactstrap";
import 'assets/css/EditAccount.css';

function ViewPricesModal({modalLarge, setModalLarge, userData, mutate}){

  const update_distributor = async (position) => {
    let update_data = {
      user_id: userData._id,
      email: userData.email,
      firstname: userData.firstname,
      lastname: userData.lastname,
      companyName: userData.companyName,
      phoneNumber: userData.phoneNumber,
      department: userData.department,
      address: userData.address,
      city: userData.city,
      zip: userData.zip,
      country: userData.country,
      state: userData.state
    }

    if(position === 'approved'){
      update_data.account_type = 'regularPlusPricing';
    } else {
      update_data.account_type = 'regular';
    }

    try{
      await axios.put(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/users/update`,
        update_data,
        { withCredentials: true }
      );
      mutate();
      console.log('Show me update user return 1.0 =>', update_data);
    } catch (e) {
      console.log('Show me update user error 1.0 =>', e);
    }
  }

  return (
    <>
      <Modal
        isOpen={modalLarge}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setModalLarge(false)}
      >
        <div className="modal-header">
          <h4 className="modal-title" id="myLargeModalLabel">
            { userData.firstname } Details
          </h4>
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => setModalLarge(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className='bdr body p-3'>
            <div>
              <div className='row'>
                <div className='col-md-6'>
                  <div class="form-group">
                    <label for="firstname">FIRST NAME <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      name="fname"
                      id="firstname"
                      autoComplete='given-name'
                      placeholder="First Name"
                      value={userData.firstname}
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div class="form-group">
                    <label for="lastname">LAST NAME <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="lastname"
                      name="lname"
                      autoComplete='family-name'
                      placeholder="Last Name"
                      value={userData.lastname}
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="jobtitle">JOB TITLE</label>
                    <input
                      type="text"
                      id="jobtitle"
                      name="jobtitle"
                      class="form-control"
                      placeholder="Job Title"
                      value={userData.jobTitle}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="company">COMPANY <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      class="form-control"
                      placeholder="Company Name"
                      value={userData.companyName}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="department">DEPARTMENT</label>
                    <input
                      type="text"
                      id="department"
                      name="department"
                      class="form-control"
                      placeholder="Department"
                      value={userData.department}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="address">ADDRESS <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      class="form-control"
                      placeholder="Address"
                      value={userData.address}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="city">CITY <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      class="form-control"
                      placeholder="City"
                      value={userData.city}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="zip">ZIP <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      id="zip"
                      name="zip"
                      class="form-control"
                      placeholder="Zip"
                      value={userData.zip}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="company">COUNTRY <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      id="country"
                      name="country"
                      class="form-control"
                      placeholder="Country"
                      value={userData.country}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div class="form-group">
                    <label for="state">STATE <span className='text-danger'>*</span></label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      class="form-control"
                      placeholder="State"
                      value={userData.state}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <label For="phone" class="form-label">PHONE <span className='text-danger'>*</span></label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    class="form-control"
                    placeholder="Phone number"
                    value={userData.phoneNumber}
                  />
                </div>
              </div>
            </div>
          </div> 
        </div>
        <hr />
        <div className="modal-footer">
          <Button
            color="secondary"
            type="button"
            onClick={() => update_distributor('denied')}
          >
            Deny
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => update_distributor('approved')}
          >
            Approve
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default ViewPricesModal;