import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";

import TechnicalInfoFooter from 'components/Footers/TechnicalInfoFooter';

function MeterConsiderations() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Flow Meter Considerations</title>
            <meta name="description" content="When you’re looking for the perfect flow meter for your application, there are some terms that you should know about. Read about them in our article!" />
            <link rel="canonical" href="https://epiflow.com/technicalinfo/meterconsiderations" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner MeterConSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/meterconsiderations" tag={Link}>Meter Considerations</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Meter Considerations</h2>
                        <p className='cover-title-p'>
                            When you are looking for the perfect meter for your application, there are some terms and concepts that you should know about. Read about them below.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo text-center mb-2'>
                        Flow Meter Considerations for Flow Conditions
                    </h4>
                    <p className='commonParaThree text-center mb-4'>
                        Flow meter considerations must be  understood for your flow conditions. You must be sure to include certain parameters for the flow meter to be properly matched
                        to your application. Please refer to the information below when requesting a quote, or you can submit our Application Data Sheet by email or fax. The category
                        descriptions below will help you specify the proper flow meter:
                    </p>
                    <br />
                    <h4 className='commontitlefour mb-2'>Gas Composition</h4>
                    <p className='commonParaThree mb-4'>
                        Flow Meter Considerations for gas are simple if you are flowing air or a pure gas such as hydrogen, nitrogen, etc. Gas mixtures should be provided
                        with each gas listed as a percent of the total, with the sum equal to 100%. Whenever possible, we calibrate your thermal mass flow meter with the actual gas. When
                        this is not possible, we use a gas or gas mixture with closely equivalent heat transfer characteristics.
                    </p>
                    <h4 className='commontitlefour mb-2'>Full Scale Flow Rate</h4>
                    <p className='commonParaThree mb-4'>
                        Although you may not know your exact maximum flow rate (Full Scale), you must provide an estimate for the calibration. If the Full Scale is not
                        known, it is best to over-estimate a flow. This information can be in units of mass/volume flow (SCFM, Lbs/Hr, NCMH, etc.) or velocity (SFPM, MPS, etc.).
                    </p>
                    <h4 className='commontitlefour mb-2'>Line Size</h4>
                    <p className='commonParaThree mb-4'>
                        Whether you are interested in an inline style or an insertion style thermal mass flow meter, we need to know your process line size. This may be expressed
                        as the nominal pipe size and schedule (ANSI, DN, etc.) of round pipes or the outside dimensions and wall thickness (or gauge) of rectangular ducts, for example.
                    </p>
                    <h4 className='commontitlefour mb-2'>Process Gas Temperature and Line Pressure</h4>
                    <p className='commonParaThree mb-4'>
                        We calibrate your thermal mass flow meter under conditions as close to your process environment as possible, so it is important
                        to know both the design and operational ranges of the gas temperature and the line pressure.
                    </p>
                    <h4 className='commontitlefour mb-2'>Electronics Temperature</h4>
                    <p className='commonParaThree mb-4'>
                        This refers to the ambient temperature of the environment surrounding the flow meter’s electronics. We can provide options for a wide range of conditions.
                    </p>
                    <h4 className='commontitlefour mb-2'>Input Power Requirements</h4>
                    <p className='commonParaThree mb-4'>
                        You must specify whether the thermal mass flow meter will be powered by 24 VDC, 115 VAC, 230 VAC. Consult factory for use with alternate power sources
                        such as 12 VDC or solar panels.
                    </p>
                    <h4 className='commontitlefour mb-2'>Configuration</h4>
                    <p className='commonParaThree mb-4'>
                        EPI™ manufactures both Inline and Insertion style flow meters. We typically supply inline style flow meters for line sizes from 1/4″ to 4″ with a number of installation options such as MNPT ends, ANSI or DIN flanges, etc. Insertion style flow meters can be used in line sizes of 2″ or greater and can be mounted with ball valve retractor assemblies, compression fittings, pipe nipples, etc. EPI™ also manufactures flow meters with either Integral or Remote Style electronics. The integral configuration is generally less expensive, but the remote style allows easy access to the electronics even when the actual process line installation is in an otherwise inconvenient location.
                    </p>
                    <h4 className='commontitlefour mb-2'>Product Approvals</h4>
                    <p className='commonParaThree mb-4'>
                        EPI™ flow meters have been tested and approved by a number of certifying agencies. If your application requires an agency approval, please select from the list below:
                    </p>
                    <div className='ms-4'>
                        <p className='commonParaThree mb-1'><b>Master-Touch™ MP Series Flow Transmitter</b></p>
                        <p className='commonParaThree mb-3'>For use in hazardous area locations by CSA/CUS (default except for EU), ATEX (default for EU), IECEx and KOSHA (customer must specify which approval is required)</p>
                        <p className='commonParaThree mb-1'><b>Master-Touch™ MP Series Remote Enclosure</b></p>
                        <p className='commonParaThree mb-3'>For use in Ordinary (Non-Hazardous) area locations: Type 4X, IP66. For use in hazardous area locations (options): ACEX or DCEX.</p>
                        <p className='commonParaThree mb-1'><b>Master-Touch™ MPNH Series</b></p>
                        <p className='commonParaThree mb-3'>For use in Ordinary (Non-Hazardous) area locations: Type 4X, IP66.</p>
                        <p className='commonParaThree mb-1'><b>ValuMass™ Series 400 and 500</b></p>
                        <p className='commonParaThree mb-3'>CE Mark</p>
                    </div>
                </Container>
            </div>

            {/* section-2 */}
            {/* section-3 */}
                <TechnicalInfoFooter />
            {/* section-3 */}
        </>
    )
}

export default MeterConsiderations
