import React, { useState, useEffect } from 'react';
import useSwr from 'swr';
import '../assets/css/PaymentOptions.css';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import creditcardicon from '../assets/img/images/creditcard-icon.png'
import wireicon from '../assets/img/images/wire-icon.png';
import cashiericon from '../assets/img/images/cashier-icon.png';
import purchaseicon from '../assets/img/images/purchase-icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import fetcher from 'utils/fetcher';

function PaymentOptions() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const page = { PaymentOptions: true }

    const { data } = useSwr(`${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/me`, fetcher);
    useEffect(() => {
        if(data){
            setUserData(data);
        }
    }, [data]);

    // console.log('Show me user data 1.0.1 =>', data);

    const headerData = {
        firstname: userData.firstname,
        page: 'PAYMENT OPTIONS'
    }

    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page}/></div>) : undefined}
                    <div className='common-main'>
                        <div className="shadow">
                            <h2 className='p-3'>Accepted Payment Options</h2>
                            <div className='apayment body p-3'>
                                <p>If pre-existing terms are not established, payment options will be shared upon order confirmation. Any late payments are subject to a 3% fee.</p>
                                <hr />
                                <div className='card-main mt-4'>
                                  {/* card-4 */}
                                  <div className='mcard'>
                                      <div>
                                          <img src={cashiericon} alt="" className='w-100' />
                                      </div>
                                      <div>
                                          <h4>Checks and Cashier's Checks</h4>
                                          <p>
                                              EPI accepts both standard checks and cashier’s checks, the latter being preferred. Because they have to be mailed to us, and then take some time to verify once they arrive, paying by check may delay your order a little bit. Please address your check/cashier’s check to <b>Eldridge Products, Inc.</b> and denote the same invoice or order number as the memo.
                                          </p>
                                      </div>
                                  </div>
                                  {/* card-4 */}
                                    {/* card-2 *
                                    <div className='mcard'>
                                        <div>
                                            <img src={creditcardicon} alt="" className='w-100' />
                                        </div>
                                        <div>
                                            <h4>Debit Card</h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod tempor
                                                incididunt ut labore et dolore magna aliqua. Ut
                                                enim ad minim veniam, quis nostrud
                                                exercitaAon ullamco laboris nisi ut aliquip ex ea
                                                commodo consequat.
                                            </p>
                                        </div>
                                    </div>
                                     card-2 */}
                                    {/* card-3 */}
                                    <div className='mcard'>
                                        <div>
                                            <img src={wireicon} alt="" className='w-100' />
                                        </div>
                                        <div>
                                            <h4>Wire Transfer / ACH</h4>
                                            <p>
                                                We accept wire transfers and ACH, plus the processing fee (usually around $20, please check with your financial institution). These are very similar, in that they both involve electronic transfers of funds directly from one bank to another - without a middleman like PayPal. These tend to be favored by bigger businesses, but can be used by individuals as well. Payment routing and bank information will be provided upon order approval.
                                            </p>
                                        </div>
                                    </div>
                                    {/* card-3 */}
                                    {/* card-1 */}
                                    <div className='mcard'>
                                        <div>
                                            <img src={creditcardicon} alt="" className='w-100' />
                                        </div>
                                        <div>
                                            <h4>Credit/Debit Cards</h4>
                                            <p>
                                              We accept Visa, MasterCard, Discover, and American Express cards through our secure online payment system. A 2% convenience fee will be assessed for all card-based purchases.
                                            </p>
                                        </div>
                                    </div>
                                    {/* card-1 */}
                                    {/* card-5 */}
                                    <div className='mcard'>
                                        <div>
                                            <img src={purchaseicon} alt="" className='w-100' />
                                        </div>
                                        <div>
                                            <h4>Purchase Orders</h4>
                                            <p>
                                                We offer NET terms to select, pre-approved customers on a case-by-case basis.
                                            </p>
                                        </div>
                                    </div>
                                    {/* card-5 */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentOptions
