import React from 'react'
import email from '../../../assets/img/contact/Email.png';
import telephone from '../../../assets/img/contact/Phone.png';
import map from '../../../assets/img/contact/Location.png';

function ContactSection() {
    return (
            <div className='row'>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <div class="card">
                        <img src={email} class="card-img-top w-100" alt="..." />
                        <div class="card-body">
                            <h5 class="card-title section-header">Email Us</h5>
                            <table class="contactTable" style={{ 'text-align': 'left'}}>
                                <tr style={{ 'text-align': 'left'}}>
                                    <td><p class="card-text">General Information</p></td>
                                    <td>:</td>
                                    <td><a href="mailto:info@epiflow.com">info@epiflow.com</a></td>
                                </tr>
                                <tr>
                                    <td><p class="card-text">Orders / Request Quote</p></td>
                                    <td>:</td>
                                    <td><a href="mailto:sales@epiflow.com">sales@epiflow.com</a></td>
                                </tr>
                                {/* <tr>
                                    <td><p class="card-text">Representative Support</p></td>
                                    <td>:</td>
                                    <td><a href="mailto:reps@epiflow.com">reps@epiflow.com</a></td>
                                </tr> */}
                                <tr>
                                    <td><p class="card-text">Service / RMA / Calibration</p></td>
                                    <td>:</td>
                                    <td><a href="mailto:service@epiflow.com">service@epiflow.com</a></td>
                                </tr>
                                <tr>
                                    <td><p class="card-text">Technical Support</p></td>
                                    <td>:</td>
                                    <td><a href="mailto:episupport@epiflow.com">episupport@epiflow.com</a></td>
                                </tr>
                                <tr>
                                    <td><p class="card-text">Media Request</p></td>
                                    <td>:</td>
                                    <td><a href="mailto:media@epiflow.com">media@epiflow.com</a></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <div class="card">
                        <img src={telephone} class="card-img-top" alt="..." />
                        <div class="card-body call-us-main">
                            <h5 class="card-title section-header">Call Us</h5>
                            <h5 class="card-title section-header phone"><a href="tel:+18316487777">831-648-7777</a></h5>
                            <h5 className='toll-free'>TOLL FREE</h5>
                            <table className='m-auto mt-3 mb-3'>
                                <tr>
                                    <td><p class="card-text">Sales</p></td>
                                    <td>:</td>
                                    <td><a href="tel:+18316487777">831-648-7777</a></td>
                                </tr>
                                <tr>
                                    <td><p class="card-text">Fax</p></td>
                                    <td>:</td>
                                    <td><a href="tel:+18316487780">831-648-7780</a></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12 m-auto'>
                    <div class="card">
                        <img src={map} class="card-img-top" alt="..." />
                        <div class="card-body call-us-main">
                            <h5 class="card-title section-header">Visit Us</h5>
                            <table className='m-auto mt-3 mb-3'>
                                <tr>
                                    <td className='align-top'><p class="card-text">Address</p></td>
                                    <td className='align-top'>:</td>
                                    <td className='pb-2'>
                                        <a target="blank" href="https://goo.gl/maps/GaFzbbRA6Cyra3in6" className='d-block'>465 Reservation Road
                                        <br />
                                        <span className='d-block text-start'>Marina, CA 93933</span>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-top'><p class="card-text">Hours</p></td>
                                    <td className='align-top'>:</td>
                                    <td><p className='card-text c-time'>Monday - Friday, <br /> 7:00am-5:00pm</p></td>
                                </tr>
                            </table>
                            <small>*Please call ahead to schedule an appointment to visit.</small>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default ContactSection
