export default {
  haz_inline: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInline%2FHAZ%2FProductImages%2FSeries-8600-8700-MP-Inline.jpg?alt=media&token=f995b655-8dba-4ed0-b9b6-51414017c7ee',
    productLink: '/products/haz/inline/haz'
  },
  haz_inline_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInline%2FHAZ%2FProductImages%2FSeries-8600-8700-MP-Inline-Enclosure-Closeup.jpg?alt=media&token=43b62067-c5af-4495-a5f5-e84a443919e1',
    productLink: '/products/haz/inline/haz'
  },
  haz_inline_3: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInline%2FHAZ%2FProductImages%2FSeries-8600-8700-MP-Inline-Flange-Closeup.jpg?alt=media&token=324620c7-c52b-4475-85a8-97aeba4811cc',
    productLink: '/products/haz/inline/haz'
  },
  haz_inline_4: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInline%2FHAZ%2FProductImages%2FSeries-8600-8700-MP-Inline-Flange-Large.jpg?alt=media&token=93cb2a5f-24e6-465a-88ec-00b542ef054b',
    productLink: '/products/haz/inline/haz'
  },
  haz_r_inline: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInline%2FHAZ%20R%2FProductImages%2FSeries-8000-8100-MP-Inline-Remote-Flow-Meter.jpg?alt=media&token=2e61f4d5-78c4-4487-8eae-bdd98fa7ee6f',
    productLink: '/products/haz/inline/haz-r'
  },
  haz_r_inline_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInline%2FHAZ%20R%2FProductImages%2FSeries-8000-8100-MP-Inline-Remote-Enclosure-Closeup.jpg?alt=media&token=4c744bed-93c5-4ef3-beff-a5278a51e25b',
    productLink: '/products/haz/inline/haz-r'
  },
  haz_r_inline_3: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInline%2FHAZ%20R%2FProductImages%2FSeries-8000-8100-MP-Inline-Remote-Control-Panel.jpg?alt=media&token=56eb54c2-f491-49f9-92d7-0778f3f576fd',
    productLink: '/products/haz/inline/haz-r'
  },
  haz_r_inline_4: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInline%2FHAZ%20R%2FProductImages%2FSeries-8000-8100-MP-Inline-Remote-BODU-Closeup.jpg?alt=media&token=1ac7dca9-c442-448f-b688-f730c3cfc2d8',
    productLink: '/products/haz/inline/haz-r'
  },
  haz_fat_inline: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInline%2FHAZ%20FAT%2FProductImages%2FSeries-9700-MP-Inline-FAT.jpg?alt=media&token=b5a01bec-adaf-4a65-86ee-bb5c91137a7a',
    productLink: '/products/haz/inline/haz-fat'
  },
  haz_fat_inline_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInline%2FHAZ%20FAT%2FProductImages%2FSeries-9700-MP-Inline-FAT-Enclosure-Closeup.jpg?alt=media&token=49484e8a-4611-4410-9712-5ac505eb24a7',
    productLink: '/products/haz/inline/haz-fat'
  },
  haz_fat_inline_3: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInline%2FHAZ%20FAT%2FProductImages%2FSeries-9700-MP-Inline-FAT-Probe.jpg?alt=media&token=93ee6f93-c462-4e0c-8200-91862a5f5074',
    productLink: '/products/haz/inline/haz-fat'
  },
  haz_fat_inline_4: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInline%2FHAZ%2FProductImages%2FSeries-8600-8700-MP-Inline-Flange-Large.jpg?alt=media&token=93cb2a5f-24e6-465a-88ec-00b542ef054b',
    productLink: '/products/haz/inline/haz-fat'
  },
  haz_fat_r_inline: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInline%2FHAZ%20FAT%20R%2FProductImages%2FSeries-9100-MP-Inline-Remote-FAT.jpg?alt=media&token=be3e56f0-7a26-468d-9443-dbdf3b09563f',
    productLink: '/products/haz/inline/haz-fat-r'
  },
  haz_fat_r_inline_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInline%2FHAZ%20FAT%20R%2FProductImages%2FSeries-9100-MP-Inline-Remote-FAT-Flange-Closeup.jpg?alt=media&token=f3bd8e53-703e-47aa-8e3e-3f59edbba949',
    productLink: '/products/haz/inline/haz-fat-r'
  },

  haz_insertion: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInsertion%2FHAZ%2FProductImages%2FSeries-8800-MP-Insertion.jpg?alt=media&token=d82fe55a-e6fe-4bf1-b505-5afd9678c281',
    productLink: '/products/haz/insertion/haz'
  },
  haz_insertion_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInsertion%2FHAZ%2FProductImages%2FSeries-8800-MP-Insertion-Enclosure.jpg?alt=media&token=f1c37bec-e394-4cd0-ba1d-6a60db063bfd',
    productLink: '/products/haz/insertion/haz'
  },
  haz_r_insertion: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInsertion%2FHAZ%20R%2FProductImages%2FSeries-8200-MP-Insertion-Remote.jpg?alt=media&token=1324ab6b-4b27-4d48-b2e1-323cf4ef9a49',
    productLink: '/products/haz/insertion/haz-r'
  },
  haz_r_insertion_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInsertion%2FHAZ%20R%2FProductImages%2FSeries-8200-MP-Insertion-Remote-Enclosure-Closeup.jpg?alt=media&token=5a2c4d2a-1c25-4910-b645-c8a8f23cf6f7',
    productLink: '/products/haz/insertion/haz-r'
  },
  haz_r_insertion_3: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInsertion%2FHAZ%20R%2FProductImages%2FSeries-8200-MP-Insertion-Remote-Probe-Closeup.jpg?alt=media&token=0ff263e8-cdb4-47cc-b0bf-6c51ebc1cbac',
    productLink: '/products/haz/insertion/haz-r'
  },
  haz_r_insertion_4: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInsertion%2FHAZ%20R%2FProductImages%2FSeries-8200-MP-Insertion-Remote-Sensor-Closeup.jpg?alt=media&token=16b37860-9b5f-4b61-a6de-d378d31a7f44',
    productLink: '/products/haz/insertion/haz-r'
  },
  haz_r_insertion_5: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInsertion%2FHAZ%20R%2FProductImages%2FSeries-8200-MP-Insertion-Remote-Electronics.jpg?alt=media&token=440be08b-8bb3-4a4c-84f5-0f124f7349ee',
    productLink: '/products/haz/insertion/haz-r'
  },
  haz_fat_insertion: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInsertion%2FHAZ%20FAT%2FProductImages%2FSeries-9800-MP-Insertion-FAT.jpg?alt=media&token=0695bfdf-e337-4c75-8301-b688c8be3ea0',
    productLink: '/products/haz/insertion/haz-fat'
  },
  haz_fat_r_insertion: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FHAZ%2FInsertion%2FHAZ%20FAT%20R%2FProductImages%2FSeries-9200-MP-Insertion-Remote-FAT.jpg?alt=media&token=fb9bc096-458c-45ee-aac2-bfa9e7039931',
    productLink: '/products/haz/insertion/haz-fat-r'
  },


  gen_inline: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInline%2FGEN%2FProductImages%2FSeries-8600-8700-MPNH-Inline.jpg?alt=media&token=73e0d91f-ee09-412b-b0d0-e7052dd02026',
    productLink: '/products/gen/inline/gen'
  },
  gen_inline_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInline%2FGEN%2FProductImages%2FSeries-8600-8700-MPNH-Inline-Enclosure.jpg?alt=media&token=d2b96502-87b8-4391-ad32-dff7cd4fb2ee',
    productLink: '/products/gen/inline/gen'
  },
  gen_inline_3: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInline%2FGEN%2FProductImages%2FSeries-8600-8700-MPNH-Inline-Body-Closeup.jpg?alt=media&token=40e5fb2c-f877-479a-9197-383606bcb35d',
    productLink: '/products/gen/inline/gen'
  },
  gen_r_inline: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInline%2FGEN%20R%2FProductImages%2FSeries-8000-8100-MPNH-Inline-Remote-Flow-Meter.jpg?alt=media&token=5843c2e4-ec6c-4c99-b2c6-008e62a12c3e',
    productLink: '/products/gen/inline/gen-r'
  },
  gen_r_inline_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInline%2FGEN%20R%2FProductImages%2FSeries-8000-8100-MPNH-Inline-Remote-Panel-Closeup.jpg?alt=media&token=39a3596d-dd97-4dca-99f5-e3c1d064e279',
    productLink: '/products/gen/inline/gen-r'
  },
  gen_r_inline_3: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInline%2FGEN%20R%2FProductImages%2FSeries-8000-8100-MPNH-Inline-Remote-Flow-Body.jpg?alt=media&token=2b749491-e787-4b38-9190-0d05695f751c',
    productLink: '/products/gen/inline/gen-r'
  },
  gen_r_inline_4: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInline%2FGEN%20R%2FProductImages%2FSeries-8000-8100-MPNH-Inline-Remote-Blank-Enclosure-Closeup.jpg?alt=media&token=8167fdd5-a7c5-4b51-b7b1-f9db5c4f5ea8',
    productLink: '/products/gen/inline/gen-r'
  },
  gen_fat_inline: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInline%2FGEN%20FAT%2FProductImages%2FSeries-9700-MPNH-Inline-FAT.jpg?alt=media&token=ba5b9942-6302-4bf8-89e0-e06cfe35d92e',
    productLink: '/products/gen/inline/gen-fat'
  },
  gen_fat_r_inline: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInline%2FGEN%20FAT%20R%2FProductImages%2FSeries-9100-MPNH-Inline-Remote-FAT.jpg?alt=media&token=2d2d79c8-17f9-4bc1-8bfa-f376ad14a62b',
    productLink: '/products/gen/inline/gen-fat-r'
  },

  gen_insertion: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInsertion%2FGEN%2FProductImages%2FSeries-8800-MPNH-Insertion.jpg?alt=media&token=f785e93b-0de2-4848-b140-4b752195a756',
    productLink: '/products/gen/insertion/gen'
  },
  gen_r_insertion: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInsertion%2FGEN%20R%2FProductImages%2FSeries-8200-MPNH-Insertion-Remote.jpg?alt=media&token=6b343264-df4a-4e3d-899f-37ccacc537c1',
    productLink: '/products/gen/insertion/gen-r'
  },
  gen_r_insertion_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInsertion%2FGEN%20R%2FProductImages%2FSeries-8200-MPNH-Insertion-Remote-Enclosure.jpg?alt=media&token=b17db10e-2028-4bf1-968f-537efa9c1ca0',
    productLink: '/products/gen/insertion/gen-r'
  },
  gen_r_insertion_3: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInsertion%2FGEN%20R%2FProductImages%2FSeries-8200-MPNH-Insertion-Remote-Sensor-Closeup.jpg?alt=media&token=48f5a1ba-dfd8-4954-bf6e-69605e82a50c',
    productLink: '/products/gen/insertion/gen-r'
  },
  gen_r_insertion_4: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInsertion%2FGEN%20R%2FProductImages%2FSeries-8200-MPNH-Insertion-Remote-Closeup.jpg?alt=media&token=19f8abf4-148f-4e03-aacb-cc875a5565bc',
    productLink: '/products/gen/insertion/gen-r'
  },
  gen_fat_insertion: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInsertion%2FGEN%20FAT%2FProductImages%2FSeries-9800-MPNH-Insertion-FAT.jpg?alt=media&token=72b90dbe-fadc-47f1-85d5-5d0adfa7a35d',
    productLink: '/products/gen/insertion/gen-fat'
  },
  gen_fat_r_insertion: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInsertion%2FGEN%20FAT%20R%2FProductImages%2FSeries-9200-MPNH-Insertion-Remote-FAT.jpg?alt=media&token=010e3621-66f5-4227-bdd6-02baa5ae7188',
    productLink: '/products/gen/insertion/gen-fat-r'
  },


  multi_insertion: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FMulti%2FInsertion%2FMulti%2FProductImages%2FSeries-9000-MP-Multipoint.jpg?alt=media&token=85938d8b-4537-4427-8fa9-b95933b31bf7',
    productLink: '/products/multigen/insertion/multigen'
  },


  multi_insertion: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FMulti%2FInsertion%2FMulti%2FProductImages%2FSeries-9000-MP-Multipoint.jpg?alt=media&token=85938d8b-4537-4427-8fa9-b95933b31bf7',
    productLink: '/products/multigen/insertion/multigen'
  },


  hpx: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FPURE%2FInline%2FLM%2FProductImages%2FSeries-800-HPX-Inline-Flow-Meter.jpg?alt=media&token=92a0d1c4-096f-4b08-bb5f-3008cf2cc49c',
    productLink: '/products/pure/inline/pure-lm'
  },
  hpx_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FPURE%2FInline%2FLM%2FProductImages%2FSeries-800-HPX-Inline-Enclosure-Close-Up.jpg?alt=media&token=d014088c-99c0-49c8-bb77-f7f20cf10cfc',
    productLink: '/products/pure/inline/pure-lm'
  },
  hpx_3: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FPURE%2FInline%2FLM%2FProductImages%2FSeries-800-HPX-Inline-with-Butt-Ends.jpg?alt=media&token=9714e4f3-5888-4acf-8fd4-9786d50cf619',
    productLink: '/products/pure/inline/pure-lm'
  },
  hpx_4: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FPURE%2FInline%2FLM%2FProductImages%2FSeries-800-HPX-Inline-with-VCR-Ends.jpg?alt=media&token=fac26b4e-aa7d-4e98-8f8a-3ebeb120e286',
    productLink: '/products/pure/inline/pure-lm'
  },


  hpn: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FPURE%2FInline%2FRB%2FProductImages%2FSeries-800-HPN-Inline-Flow-Meter.jpg?alt=media&token=0bc32b21-6a5a-4211-950c-98852992aff5',
    productLink: '/products/pure/inline/pure-rb'
  },
  hpn_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FPURE%2FInline%2FRB%2FProductImages%2FSeries-800-HPN-Inline-Enclosure-Closeup.jpg?alt=media&token=c42f8699-35de-4ca1-b97b-58eecb08f5d3',
    productLink: '/products/pure/inline/pure-rb'
  },
  hpn_3: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FPURE%2FInline%2FRB%2FProductImages%2FSeries-800-HPN-Inline-Probe-Close-Up.jpg?alt=media&token=9c20e9a9-74fb-4897-b95e-af1d85f89028',
    productLink: '/products/pure/inline/pure-rb'
  },


  switch_inline: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FSwitch%2FInline%2FSwitch%2FProductImages%2FSeries-7000-Inline-Flow-Meter.jpg?alt=media&token=e8dc849a-c5a0-498f-a2d8-1baf43f48d28',
    productLink: '/products/switch/inline/gen'
  },
  switch_inline_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FSwitch%2FInline%2FSwitch%2FProductImages%2FSeries-7000-Inline-Probe.jpg?alt=media&token=2061b47e-94b9-4bb5-9cbe-3635c71dc917',
    productLink: '/products/switch/inline/gen'
  },

  switch_insertion: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FSwitch%2FInsertion%2FSwitch%2FProductImages%2FSeries-7200-Insertion-Flow-Meter.jpg?alt=media&token=93a2ad28-d172-4978-b48e-fe28d575a7a5',
    productLink: '/products/switch/insertion/gen'
  },
  switch_insertion_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FSwitch%2FInsertion%2FSwitch%2FProductImages%2FSeries-7200-Insertion-Enclosure-Closeup.jpg?alt=media&token=06ac7247-0660-42fb-983e-3a99e18388af',
    productLink: '/products/switch/insertion/gen'
  },
  switch_insertion_3: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FSwitch%2FInsertion%2FSwitch%2FProductImages%2FSeries-7200-Insertion-Sensor-Closeup.jpg?alt=media&token=181c1272-ff28-4d58-9259-f2b16ca9dc31',
    productLink: '/products/switch/insertion/gen'
  },


  xt200c: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FXT%2FInsertion%2F200C%2FProductImages%2FSeries-8800-MPHT-Insertion.jpg?alt=media&token=4db65a90-5583-4b13-bac6-144194c6a83e',
    productLink: '/products/xt/insertion/200c'
  },

  xt525cr: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FXT%2FInsertion%2F525CR%2FProductImages%2FSeries-8200-MPHT-Insertion-Remote.jpg?alt=media&token=3245ee69-f5b5-4c01-914a-3111df7cf24e',
    productLink: '/products/xt/insertion/525cr'
  },


  val500: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInline%2FLM%2FProductImages%2FValuMass-500-Inline.jpg?alt=media&token=c8d6d47d-63df-4590-bea7-012207b5eb22',
    productLink: '/products/val/inline/val-lm'
  },
  val500_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInline%2FLM%2FProductImages%2FValuMass-500-Inline-Enclosure-Closeup.jpg?alt=media&token=ea9d6214-8683-4fed-b7df-f9864c4a0ee5',
    productLink: '/products/val/inline/val-lm'
  },
  val500_3: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInline%2FLM%2FProductImages%2FValuMass-500-Inline-Body-Closeup.jpg?alt=media&token=934b5469-ca3c-4201-98ac-f71751717f1e',
    productLink: '/products/val/inline/val-lm'
  },

  val540: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInsertion%2FLM%2FProductImages%2FValuMass-540-Insertion.jpg?alt=media&token=8e48af64-4022-4838-84de-2d1ad5cd29c2',
    productLink: '/products/val/insertion/val-lm'
  },
  val540_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInsertion%2FLM%2FProductImages%2FValuMass-540-Insertion-Enclosure-Closeup.jpg?alt=media&token=8d992ebd-1439-43ce-bf3e-67edc6220526',
    productLink: '/products/val/insertion/val-lm'
  },
  val540_3: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInsertion%2FLM%2FProductImages%2FValuMass-540-Insertion-in-Production-Plant.jpg?alt=media&token=542f65e6-9ea0-462c-88cf-863451ad3504',
    productLink: '/products/val/insertion/val-lm'
  },


  val400: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInline%2FRB%2FProductImages%2FValuMass-400-Inline.jpg?alt=media&token=c5800f5a-26aa-49e5-8cec-27b6ade017a8',
    productLink: '/products/val/inline/val-rb'
  },
  val400_2: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInline%2FRB%2FProductImages%2FValuMass-400-Inline-Side.jpg?alt=media&token=07e6ec9a-b2ae-438e-a841-47a35db52468',
    productLink: '/products/val/inline/val-rb'
  },

  val440: {
    image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FVAL%2FInsertion%2FRB%2FProductImages%2FValuMass-440-Insertion.jpg?alt=media&token=b113ba21-49af-4fd6-afab-3b9a19547144',
    productLink: '/products/val/insertion/val-rb'
  }

}
