import React from "react";

// reactstrap components
import { Row } from "reactstrap";

import HartBottom from "./HartBottom";

function Hart(){
  return (
    <>
      <Row>
      </Row>
      <hr />
      <HartBottom />
    </>
  );
}

export default Hart;
