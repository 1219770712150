import React,{ useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import HipporelloEmbed from "@hipporello/embed"
import useSwr from 'swr';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import fetcher from 'utils/fetcher';

function TechSupport() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const page = { techSupport: true }

    var { data } = useSwr(`${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/me`, fetcher);
    useEffect(() => {
        if(data){
            setUserData(data);
        }
    }, [data]);

    const headerData = {
        firstname: userData.firstname,
        page: 'TECH SUPPORT'
    }

    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar> </Sidebar></div>) : undefined}
                    <div className='common-main'>
                        <h2 className='p-3'>Tech Support</h2>
                        <div className='bdr body p-3'>
                            <p>If you are looking for technical support, please fill out the form below. If you are specifically looking to submit an RMA request, click the link below.
                              <NavLink to="/portal/RMA" tag={Link}>Request RMA</NavLink>
                            </p>
                           <hr />
                           <HipporelloEmbed formId="3f576b7d8d294b3d8591e483d42d8c67"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TechSupport
