import React,{ useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useSwr from 'swr';
import { useHistory } from 'react-router';
import '../assets/css/Quotes.css';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import fetcher from 'utils/fetcher';
import { Row, Col, Button, NavLink} from "reactstrap";
import ConfigurationsTable from "./ConfigurationsTable";

function Quotes() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const [quoteData, setQuoteData] = useState([]);
    const page = { quotes: true }

    var { data } = useSwr(`/api/me`, fetcher);
    var { data: Quotes } = useSwr(`/api/quotes/all`, fetcher);

    useEffect(() => {
        if(data){
            setUserData(data);
        }

        if(Quotes){
            setQuoteData(Quotes)
        }
    }, [data, Quotes]);

    const headerData = {
        firstname: userData.firstname,
        page: 'SAVED CONFIGURATIONS'
    }

    const quoteClicked = (_id) => {
        history.push(`/portal/quotes/${_id}#`);
    }

    console.log('Show me config data 1.0 =>', quoteData);

    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page} /></div>) : undefined}
                    <div className='common-main'>
                        <div className='shadow'>
                            <h2 className='p-3'>Saved Meter Configurations</h2>
                            <div className='quotes body p-3'>
                                <p>View a list of all your saved meter configurations. Select one to view, edit or delete.</p>
                                <hr />
                                { quoteData.length <= 0 &&
                                    <div className="noQuotes text-center">
                                    <p>No configurations have been saved yet. Start by customizing a product in the Meter Customizer!</p>
                                    <br />
                                        <NavLink tag={Link} to="/allproducts">
                                            <Button className="button1" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                                                Start Customizing
                                            </Button>
                                        </NavLink>
                                    </div>
                                }
                                { !quoteData.length <= 0 && <ConfigurationsTable configurations={quoteData} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Quotes
