import React, {useState} from 'react'
import axios from 'axios';
import storage from 'utils/firebase';
import profileicon from '../../assets/img/images/profile-icon.png';
import { nanoid } from "nanoid";

function ImageSelectorDistributor({ image, setImage ,header, userData}) {
    const [uploading, setUploading] = useState(false)
    const imageNanoid = nanoid();
    
    const upload = async (imageData) => {
        setUploading(true)
        const imageName  = `${imageNanoid}${imageData.name}`;
        if(imageData == null){
            return;
        } {
            setImage(imageData)
            const update_data = {
                companyName: userData.companyName,
                contact: userData.contact,
                description: userData.description,
                email: userData.email,
                website: userData.website,
                phoneNumber: userData.phoneNumber,
                fax: userData.fax,
                image: imageName,
                shippingAddress: userData.shippingAddress,
                shippingAddress2: userData.shippingAddress2,
                shippingAddress3: userData.shippingAddress3
            }
        
            try{
                const ref = storage.ref(imageName);
                const uploadTask = ref.put(imageData);
                
                uploadTask.on("state_changed", console.log, console.error, () => {
                    ref.getDownloadURL().then((url) => {
                        update_data.image = url;
                        console.log('Show me image update user info 1.1 =>', update_data);
                        axios.put(
                            `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/distributor`,
                            update_data,
                            { withCredentials: true }
                        )
                    });
                });
            } catch (e) {
                console.log('Show me update user info 1.1 =>', e);
            }
        }
    }

    return (
        <div className='row mt-3 mb-3'>
            <div className='col-md-2'>
                { uploading ?
                    <img src={image ? URL.createObjectURL(image) : profileicon} alt="" className='profileiconimg' /> :
                    <img src={image ? image : profileicon} alt="" className='profileiconimg' />
                }
            </div>
            <div className='col-md-10 d-flex align-items-center'>
                <div>
                    <h5>{header}</h5>
                    <div className='mt-2 upload-btn-wrapper '>
                        <label htmlFor='image-input' className="upload-button">Upload Image</label>
                        <input
                            onChange={(event) => upload(event.target.files[0])}
                            id='image-input'
                            name='image-input'
                            type='file'
                            accept='image/png, image/jpeg'
                            className='d-none'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageSelectorDistributor
