import React,{ useState, useEffect } from 'react';
import useSwr from 'swr';
import { useHistory } from 'react-router';
// import '../assets/css/Quotes.css';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ApprovedPricingsUserModal from './ViewApprovedPricings';
import fetcher from 'utils/fetcher';
import { Row, Col} from "reactstrap";

function ApprovedPricings() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();
    const [modalLarge, setModalLarge] = React.useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const [quoteData, setQuoteData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const page = { approvedPricings: true }

    var { data } = useSwr(`/api/me`, fetcher);
    var { data: Distributor } = useSwr(`/api/users/pricing/all`, fetcher);
    console.log('Show me pricing data 1.0 =>', quoteData);

    useEffect(() => {
        if(data){
            setUserData(data);
            if(data.account_type != 'admin'){
                history.push('/portal/quotes');
            }
        }

        if(Distributor){
            setQuoteData(Distributor)
        }
    }, [data, Distributor]);

    const headerData = {
        firstname: userData.firstname,
        page: 'APPROVED PRICINGS'
    }

    const quoteClicked = (_id) => {
        history.push(`/portal/quotes/${_id}#`);
    }
    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page} /></div>) : undefined}
                    <div className='common-main'>
                        <div className='shadow'>
                            <h2 className='p-3'>Approved Pricings</h2>
                            <div className='quotes body p-3'>
                                <p>View a list of all your approved distributors.</p>
                                <hr />
                                <div>
                                    {quoteData && quoteData.map((value) => {
                                        const date = new Date(value.createdAt);
                                        const day = date.getDate();
                                        const month = ("0" + (date.getMonth() + 1)).slice(-2)
                                        const year = date.getFullYear();

                                        return(
                                            <>
                                                <Row type="button" onClick={ () => {
                                                    setModalData(value);
                                                    setModalLarge(!modalLarge);
                                                }}>
                                                    <Col xs="10" className='quotesLink'>{value.companyName}, ({ value.email } { value.model })</Col>
                                                    <Col className="quotesButtons"> { `${month}/${day}/${year}` } </Col>
                                                </Row>
                                                <hr />
                                            </>
                                        )
                                    })}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ApprovedPricingsUserModal
                modalLarge={modalLarge}
                setModalLarge={setModalLarge}
                distributorData={modalData}
            />
        </div>
    )
}

export default ApprovedPricings
