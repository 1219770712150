import React, { useState, useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import img1 from '../../assets/img/images/LandFillImg1.png';
import { Helmet } from "react-helmet";

function Landfill() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [color, setColor] = useState('#095CF6');
    const handleBtn = () => {
        setColor('#fff')
    }
    const handleBtnOut = () => {
        setColor('#095CF6')
    }
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Landfill & Municipal Meters</title>
            <meta name="description" content="Thermal Mass Flow Landfill Meters are critical to monitoring gas process efficiencies: let’s discover how they work! " />
            <link rel="canonical" href="https://epiflow.com/whitepapers/landfill" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner LandFillSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/whitepapers" tag={Link}>White Papers</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/whitepapers/landfill" tag={Link}>Landfill</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Landfill Gas & Municipal Flow Meters</h2>
                        <p className='cover-title-p'>Everything you need to know about EPI.</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <Container className='py-5'>
                <div className='DownloadPDfbtn-div'>
                    <div className='position-relative d-inline'>
                        <button
                            className='button2'
                            onMouseOver={handleBtn}
                            onMouseOut={handleBtnOut}
                        >
                            Download as PDF
                        </button>
                        <FontAwesomeIcon
                            className='DownloadPDfbtn-icon'
                            style={{ color: color }}
                        />
                    </div>
                </div>
                <h4 className='commontitleTwo text-center mb-4'>
                    Thermal Mass Landfill & Municipal Meters
                </h4>
                <p className='commonParaThree'>
                    Thermal mass flow landfill meters are critical to monitoring gas process efficiencies. <br /> <br />
                    Landfill gas (LFG) is produced by the decomposition of organic material in landfills. It is a mixture of gases that are required to be monitored and reported.
                    Additionally, methane emissions from landfills represent an opportunity to capture and reuse a significant source of energy. Accurately measuring these flows is
                    critical to meeting process efficiencies, cost reduction goals and reporting requirements.
                </p>
                <h4 className='commontitleTwo text-center mb-4'>
                    Municipal Solid Waste
                </h4>
                <p className='commonParaThree'>
                    The EPA issued Rule 40 CFR Part 98 in October 2009 that requires greenhouse gas emissions from large sources and suppliers in the United States to be accurately reported
                    in order to collect accurate emissions data to help in making important policy decisions. According to the EPA, by 2012 municipal solid waste (MSW) landfills followed only
                    industry and agriculture as significant sources of human-related methane emissions in the United States, accounting for almost 20% of these emissions.<br />

                    When municipal solid waste is first deposited in a landfill, it undergoes an aerobic (with oxygen) decomposition stage and very little methane is generated. However, this
                    usually changes in less than 1 year of operation. Anaerobic conditions (without oxygen) develop and methane-producing bacteria begin to decompose the waste, generating
                    increasing amounts of methane. Methane (60–70%) and carbon dioxide (30–40%) comprise the majority of most landfill gas with additional trace amounts of nitrogen, oxygen
                    and other gases. Although methane doesn’t last as long in the atmosphere as CO2 does, it is a dangerous greenhouse gas with a global yearly warming potential that is 25
                    times greater than that of CO2.
                </p>
                <h4 className='commontitleTwo text-center mb-4'>
                    Energy Production
                </h4>
                <p className='commonParaThree'>
                    Instead of allowing the LFG to escape into the environment, it can be collected and used as an important and convenient energy source. By using the collected LFG, municipalities
                    are eliminating odors and reducing other hazards associated with the LFG emissions. At the same time, they are preventing methane from entering the atmosphere, limiting its
                    contribution to local smog and global climate change.
                    <br /><br />
                    As LFG is extracted from landfills, typically using a series of wells and a blower/flare (or vacuum) system, it is directed to a central point where it is processed and treated
                    for its particular intended use. Then the gas is flared, used to power turbines that generate electricity, replace fossil fuels in industrial and manufacturing operations, or
                    potentially upgraded to higher quality gas that may be used directly or further processed into an alternative vehicle fuel. The accurate measurement of the LFG flow is a critical
                    aspect of any of these efforts.
                </p>
                <Row className='g-3'>
                    <Col md="6">
                        <div>
                            <img src={img1} alt="Graphic of landfill layout" />
                        </div>
                    </Col>
                    <Col md="6">
                        <div>
                            <p className='commonParaThree'>
                                Potential points of measurement between the landfill and the cogeneration plant are:<br />
                                A Individual collection lines;<br />
                                B Common header lines;<br />
                                C Process lines through sumps, knock-out pots, and blowers/compressors;<br />
                                D Oxidizer/flare stacks;<br />
                                E The supplemental natural gas supply.
                            </p>
                        </div>
                    </Col>
                </Row>
                <p className='commonParaThree mt-4'>
                    Constant temperature thermal mass flow meters, such as those produced by EPI, operate on the principle of thermal dispersion or heat loss from a heated Resistance Temperature Detector
                    (RTD) to the flowing gas. Two active RTD sensors are operated in a balanced state. One acts as a temperature sensor reference; the other is the active heated sensor. Heat loss to the
                    flowing fluid tends to unbalance the heated flow sensor and it is forced back into balance by the electronics. With this method of operating the constant temperature sensor, only the
                    skin temperature is affected by the fluid flow heat loss. This allows the sensor core temperature to be maintained and produces a very fast response to fluid velocity and temperature changes.
                    Additionally, because the power is applied as needed, the technology has a wide operating range of flow and temperature. The heated sensor maintains an index of overheat above the environmental
                    temperature sensed by the unheated element. The effects of variations in density are virtually eliminated by molecular heat transfer and sensor temperature corrections.
                </p>
                <h4 className='commontitleTwo text-center mb-4'>
                    Specifying the Requirements
                </h4>
                <p className='commonParaThree'>
                    A number of factors must be considered when selecting and specifying any instrumentation and this is true for thermal mass flow meters to be used in LFG systems. To specify the best configuration, you must determine:
                    <br /><br />
                    <b>What are the flow measurement conditions, such as the minimum and maximum flow rates to be measured, the process pipe size, and the gas temperature and line pressure?</b><br />
                    All flow meters have minimum and maximum flow limits for any given pipe size, and temperature and pressure ranges for the physical construction. Assuring that the flow meter meets these basic
                    requirements is the first step in specifying the proper mass flow meter. These parameters will determine the calibration scale and the expected accuracy, as well as help to identify potential
                    issues with the overall installation.
                    <br /><br />
                    <b>Where will the flow meter be installed and what is the piping configuration upstream and downstream of that location?</b><br />
                    The flow readings will be their most accurate where the gas flow profile in the pipe is uniform and consistent so that the sensor output at the point of measurement is truly representative
                    of the overall flow through the pipe. All instrument manufacturers have recommended straight run requirements for the installation of their meters. These recommendations are offered to help
                    end-users determine suitable locations for the flow meters, but it is important to recognize that these are only guidelines and not guarantees of optimal positioning.
                    <br /><br />
                    <b>Is there moisture present at the point of measurement?</b><br />
                    By its nature, most landfill gas is considered to be “wet”. Simply stated, thermal mass flow meters will not read accurately* if water droplets come into contact with the sensor
                    RTDs. Although there are successful strategies for minimizing the potential for problems, installing the flow meter at a location where the gas is dry is strongly advised.<br />

                    * The heat loss to a liquid such as water droplets is so much greater than the heat loss to a dry gas that the meter’s flow signal will typically rise to a higher value producing an error
                    that will remain until the heated RTD is dry again. Although Eldridge Products’ flow meters will be affected by these droplets, they will not cause damage to the flow sensor. Non-condensing
                    water vapor in the gas is acceptable.<br /><br />
                    Master-Touch™ thermal flow meters comply with the requirements of 40 CRF 98.34 (c)(1). They are calibrated in our own NIST-traceable facility using CH4/CO2 gas mixtures for the best accuracy.
                    Our flow meters provide real-time mass flow measurement and totalization for continuous LFG flow monitoring. In addition, Master-Touch™ flow meters can store as many as four independent flow ranges
                    for multiple gas mixtures and have two fully-programmable relays for a variety of notification functions and/or pulsed totalization of flow. A variety of sizes and configuration are available to
                    meet virtually any installation requirement.
                </p>
            </Container>
        </>
    );
}

export default Landfill;
