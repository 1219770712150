import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import HipporelloEmbed from "@hipporello/embed"
import PhoneInput from 'react-phone-number-input'
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";


function GetQuote() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [value, setValue] = useState();
    return (
        <div>
          <Helmet>
              <meta charSet="utf-8" />
              <title>EPI – Get A Quote</title>
              <meta name="description" content="Fill out the form in this link to have an EPI representative contact you with a custom quote for the meter suited to your individual needs." />
              <link rel="canonical" href="https://epiflow.com/support/getquote" />
          </Helmet>
            {/* section-1 */}
            <div className='si-section-1 gq-section-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/support" tag={Link}>Support</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/support/getquote" tag={Link}>Get a Quote</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>GET A QUOTE</h2>
                        <p className='cover-title-p'>Fill out the form below to have an EPI representative contact you with a custom quote for the meter suited to your individual needs.</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <div className='pb-5 bt-s2 gq-s2'>
                <Container>
                    <Row>
                        <h3 className='commontitleOne pt-5 mb-4 text-center'>Get your quote below.</h3>
                        <HipporelloEmbed formId="06659001bb5f485cbc90a67e91ce2704"/>
                    </Row>
                </Container>
            </div>
            {/* section-2 */}
        </div>
    )
}

export default GetQuote
