import React from "react";

// reactstrap components
import {
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledTooltip
} from "reactstrap";

function Pressure({ MakeChangeText, state, checkState }){
  const getValue = (object, value) => {
    if(object){
      return object.valuesObj[value]
    }
  }

  const checkUnits = (unit) => {
    if(checkState(state.process_parameters.pressure_units, 'units') === unit){
      return true;
    }
    return false;
  }

  return (
    <>
      <Row>
        <Col className="processParametterCols">
        <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitleProcessParameters">MINIMUM GAS PRESSURE</span>
              <Button className="questionToolTip" id="MINIMUMGASPRESSURE" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="MINIMUMGASPRESSURE" delay={0}>
                Input the minimum gas pressure you expect in the process line.
                This will serve as a reference point when your meter is calibrated to your gas type.
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlSelect1"
              type="text"
              placeholder="Minimum Pressure"
              defaultValue={
                getValue(state.process_parameters.pressure_minimum, 'minimum') &&
                getValue(state.process_parameters.pressure_minimum, 'minimum')
              }
              onChange={ (e) => MakeChangeText({
                section: 'process_parameters',
                type: 'pressure_minimum',
                values: ['minimum'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            >
            </Input>
          </FormGroup>
        </Col>

        <Col className="processParametterCols">
        <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitleProcessParameters">MAXIMUM GAS PRESSURE</span>
              <Button className="questionToolTip" id="MAXIMUMGASPRESSURE" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="MAXIMUMGASPRESSURE" delay={0}>
                Input the maximum gas pressure you expect in the process line.
                This will serve as a reference point when your meter is calibrated to your gas type.
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlSelect1"
              type="text"
              placeholder="Maximum Pressure"
              defaultValue={
                checkState(state.process_parameters.pressure_maximum, 'maximum') &&
                checkState(state.process_parameters.pressure_maximum, 'maximum')
              }
              onChange={ (e) => MakeChangeText({
                section: 'process_parameters',
                type: 'pressure_maximum',
                values: ['maximum'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            >
            </Input>
          </FormGroup>
        </Col>

        <Col className="processParametterCols">
          <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitleProcessParameters">NORMAL GAS PRESSURE</span>
              <Button className="questionToolTip" id="OPTIMALGASPRESSURE" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="OPTIMALGASPRESSURE" delay={0}>
                Input your optimal gas pressure.
                This will serve as a reference point when your meter is calibrated to your gas type.
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlSelect1"
              type="text"
              placeholder="Optimal Pressure"
              defaultValue={
                checkState(state.process_parameters.pressure_optimal, 'optimal') &&
                checkState(state.process_parameters.pressure_optimal, 'optimal')
              }
              onChange={ (e) => MakeChangeText({
                section: 'process_parameters',
                type: 'pressure_optimal',
                values: ['optimal'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            >
            </Input>
          </FormGroup>
        </Col>

        <Col className="processParametterCols">
          <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitleProcessParameters">Units</span>
              <Button className="questionToolTip" id="UnitsPRESSURE" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="UnitsPRESSURE" delay={0}>
                PRESSURE Units
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize form-select"
              id="exampleFormControlSelect1"
              type="select"
              onChange={ (e) => MakeChangeText({
                section: 'process_parameters',
                type: 'pressure_units',
                values: ['units'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            >
              <option value="" selected disabled hidden>Units</option>
              <option selected={checkUnits('PSIG')} value="PSIG">PSIG</option>
              <option selected={checkUnits('PSIA')} value="PSIA">PSIA</option>
              <option selected={checkUnits('BARG')} value="BARG">BARG</option>
              <option selected={checkUnits('BARA')} value="BARA">BARA</option>
              <option selected={checkUnits('IN H20')} value="IN H20">IN H20A</option>
              <option selected={checkUnits('KG/CM2')} value="KG/CM2">KG/CM2A</option>
              <option selected={checkUnits('MM Hg')} value="MM Hg">MM Hg</option>
              <option selected={checkUnits('PA')} value="PA">PA</option>
              <option selected={checkUnits('KPA')} value="KPA">KPA</option>
              <option selected={checkUnits('MPA')} value="MPA">MPA</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default Pressure;
