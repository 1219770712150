import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";

import TechSupportFooter from 'components/Footers/TechSupportFooter'

function WarrantyNew() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Warranty Policy </title>
            <meta name="description" content="Let’s discover EPI’s limited warranty and limitation of liability in this in-depth and dedicated section of our website! " />
            <link rel="canonical" href="https://epiflow.com/support/warranty" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner TComSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/support" tag={Link}>Support</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/support/warranty" tag={Link}>Warranty</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Warranty</h2>
                        <p className='cover-title-p'>
                            Get information about different types of flow meters and their uses, as well as some pros and cons.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo text-center mb-2'>
                        Limited Warranty and Limitation of Liability
                    </h4>
                    <p className='commonParaThree mb-4'>
                        (a) Seller warrants its products to be free from defects in materials and workmanship for one
                        year from the date of factory shipment. If there is a defect, the purchaser must notify Seller of
                        the defect within the warranty period. Upon Seller’s receipt and confirmation of the defective
                        Product, Seller at its sole option, will either repair the defective Product, or provide a Seller’s
                        replacement, or refund the purchase price of the defective Product. Repaired or replaced
                        Products shall be warranted for the remainder of the warranty period. For any of Seller’s
                        installed Products that Buyer believes to be defective, Buyer shall immediately discontinue
                        product use.
                    </p>
                    <p className='commonParaThree mb-4'>
                        EPI MAKES NO OTHER WARRANTY, EXPRESS OR IMPLIED, AS TO THE PRODUCTS. EPI
                        MAKES NO WARRANTY AND DISCLAIMS ANY WARRANTY THAT THE GOODS SOLD TO
                        ANY PURCHASER ARE FIT FOR ANY PARTICULAR PURPOSE. FURTHERMORE, EPI
                        DISCLAIMS ANY WARRANTY OF MERCHANTABILITY WITH RESPECT TO ANY
                        PRODUCTS SOLD TO ANY PURCHASERS. BUYER SHALL NOT IN ANY EVENT BE
                        ENTITLED TO, AND SELLER SHALL NOT BE LIABLE FOR, INDIRECT, SPECIAL,
                        INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY NATURE INCLUDING, WITHOUT
                        LIMITATION, BUSINESS INTERRUPTION COSTS, REMOVAL AND/OR REINSTALLATION
                        COSTS, REPROCUREMENT COSTS, LOSS OF PROFIT OR REVENUE, LOSS OF DATA,
                        PROMOTIONAL OR MANUFACTURING EXPENSES, OVERHEAD, INJURY TO
                        REPUTATION OR LOSS OF CUSTOMERS, EVEN IF SELLER HAS BEEN ADVISED OF THE
                        POSSIBILITY OF SUCH DAMAGES. BUYER'S RECOVERY FROM SELLER FOR ANY CLAIM
                        SHALL NOT EXCEED BUYER'S PURCHASE PRICE FOR THE PRODUCT GIVING RISE TO
                        SUCH CLAIM IRRESPECTIVE OF THE NATURE OF THE CLAIM, WHETHER IN CONTRACT,
                        TORT, WARRANTY, OR OTHERWISE. SELLER SHALL NOT BE LIABLE FOR AND BUYER
                        SHALL INDEMNIFY, DEFEND AND HOLD SELLER HARMLESS FROM ANY CLAIMS BASED
                        ON SELLER'S COMPLIANCE WITH BUYER'S DESIGNS, SPECIFICATIONS OR
                        INSTRUCTIONS, OR MODIFICATION OF ANY PRODUCTS BY PARTIES OTHER THAN
                        SELLER, OR USE IN COMBINATION WITH OTHER PRODUCTS. BUYER FURTHER
                        ACKNOWLEDGES THAT SELLER CANNOT BE HELD LIABLE FOR PUNITIVE OR
                        EXEMPLARY DAMAGES AS WELL.
                    </p>
                    <p className='commonParaThree mb-4'>
                        (b) When applicable, Seller will transfer to Buyer any transferable warranties that a third-party vendor/service provider provides to Seller. SELLER SHALL HAVE NO LIABILITY TOWARDS
                        BUYER THIRD-PARTY - MANUFACTURER PRODUCT WARRANTIES. SELLER ASSUMES
                        NO LIABILITY FOR THE QUALITY OF THE WORK PERFORMED OR SERVICES
                        RENDERED BY THIRD-PARTY VENDORS/SERVICE PROVIDERS. IT IS BUYER'S
                        OBLIGATION TO REQUEST COPIES OF ANY APPLICABLE MANUFACTURER
                        WARRANTIES AND BUYER SHALL BE DEEMED TO HAVE ACCEPTED SUCH
                        WARRANTIES UPON ACCEPTANCE OF THE PRODUCTS AND/OR SERVICES.
                    </p>
                    <p className='commonParaThree mb-4'>
                        (c) Warranty period on any out of warranty Products that are repaired or serviced by Seller shall
                        be warranted for a period of 90 days from factory shipment.
                    </p>
                    <p className='commonParaThree mb-4'>
                        (d) No warranty shall apply to any Product that has been subject to misuse, improper testing,
                        disassembly, mishandling, corrosive processes, erosive processes, excessive temperatures, or
                        which has been operated contrary to current written instructions relating to installation,
                        maintenance or operation, or contrary to industry standards.
                    </p>
                    <p className='commonParaThree mb-4'>
                        (e) Seller disclaims, and shall have no liability for any trademark, trade dress, trade secret,
                        copyright, design or patent infringement, or any other intellectual property right, which may
                        occur, as a result of the sale of Products to Buyer and there shall be no remedy or recourse
                        against Seller to the extent the infringement arises from or is otherwise based upon:
                        i) the Seller's compliance with the particular requirements of Buyer that differ from the
                        Seller's standard specifications for the Product;
                        (ii) modifications or alterations of the product other than by the
                        Seller; or ;
                        (iii) a combination of the Product with other items not furnished or manufactured by the
                        Seller.
                    </p>
                </Container>
            </div>
            {/* section-2 */}

            {/* section-4 */}
                <TechSupportFooter />
            {/* section-4 */}
        </>
    )
}

export default WarrantyNew
