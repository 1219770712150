import React,{ useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useSwr from 'swr';
import { useParams, useHistory } from 'react-router';
import 'assets/css/Quotes.css';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import { faArrowRight, faCreditCard, faDownload, faTrashAlt, faEdit, faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fetcher from 'utils/fetcher';
import { Row, Col, Button, NavLink, Collapse, CardBody} from "reactstrap";
import meterimg from '../../assets/img/images/meter-img.png'
import ShoppingCart from 'components/Customizer/ShoppingCart';
import MappingData_ from "../ViewQuote/ModelNumMappingData/Inline";
import SavedCartModal from './SavedCartModal';

function SavedCart() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const [cartData, setCartData] = useState([]);
    const [quoteData, setQuoteData] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    const [testDelete, setTestDelete]= useState(false);
    const [mappingData, setMappingData] = useState(false);
    const [viewRep, setViewRep] = useState(false);
    const [modal, setModal] = useState(false);
    const [quoteId_, setQuoteId_] = useState(false);
    var { quoteId } = useParams();
    const page = { allQuotesReal: true }

    var { data } = useSwr(`/api/me`, fetcher);
    var { data: Cart } = useSwr(`/api/realquotes/single/${quoteId}`, fetcher);

    console.log('Show me single saved quote 1.0 =>', Cart);

    useEffect(() => {
      setIsLoading(true);
      if(data){
        setUserData(data);
      }
    }, [data]);

    useEffect(() => {
      if(MappingData_){
        setMappingData(MappingData_[0]);
      }
    }, [MappingData_]);

    useEffect(() => {
      if(Cart){
        const cart_items = Object.values(Cart.cart_items)
        setCartData(cart_items);
        setQuoteId_(Cart.quoteId);
        console.log('Show me quote 1.0 =>', Cart);
      }
    }, [Cart]);

    const toggle = () => setModal(!modal);

    const headerData = {
        firstname: userData.firstname,
        page: 'single quote'
    }

    const quoteClicked = (_id) => {
        history.push(`/portal/quotes/${_id}#`);
    }

    const deleteMeter = (meter, index) => {
      meter['cart_location'] = index;
      console.log('Show me cart delete 1.0 =>', meter);
      // ShoppingCart({
      //   'task': 'remove',
      //   'data': meter
      // });
    }

    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page} /></div>) : undefined}
                    <div className='common-main'>
                      <Row>
                          <Col md="6" sm="12">
                            <h3 className='commontitleOne mt-3 vq-ctone quoteId'>Quote ID#: {quoteId_}</h3>
                          </Col>
                          <Col md="6" sm="12">
                            <p class="text-right pt-3"><b>Created By: <a href="">Value</a></b></p>
                          </Col>
                      </Row>
                      <Row>
                        <div className='shadow'>
                          <div className='quit-details-main'>
                              <div className='row quit-details-main-row'>
                                  <div className='col-md-4'>
                                      <h3 className='p-3 mt-2 quite-details-text'>Saved Quote</h3>
                                  </div>
                                  <div className='col-md-8'>
                                      {/* new */}
                                      <div className='row text-right quote-nav-options'>
                                          <ul className='m-0'>
                                            <li className='quoteMenuButton'>
                                              <a>
                                                <FontAwesomeIcon icon={faCreditCard} className='quoute-2d-element-icon'></FontAwesomeIcon>
                                                Purchase Meters
                                              </a>
                                            </li>
                                              {/* <li><a href="/"><FontAwesomeIcon icon={faCreditCard} className='quoute-2d-element-icon'></FontAwesomeIcon>Buy Now</a></li> */}
                                              {/* <li className='quoteMenuButton'>
                                                  <a>
                                                      <FontAwesomeIcon icon={faEdit} className='quoute-2d-element-icon'></FontAwesomeIcon>
                                                      Edit
                                                  </a>
                                              </li>
                                              <li className='quoteMenuButton'>
                                                  <a>
                                                      <FontAwesomeIcon icon={faDownload} className='quoute-2d-element-icon'></FontAwesomeIcon>
                                                      Download/Print PDF
                                                  </a>
                                              </li>
                                              <li className='quoteMenuButton'><a className='me-3'><FontAwesomeIcon icon={faTrashAlt} className='quoute-2d-element-icon'></FontAwesomeIcon>Delete</a></li> */}
                                          </ul>
                                      </div>
                                      {/* new */}
                                  </div>
                              </div>
                          </div>
                          <div className='quotes body p-3 cartPage'>
                            <p>View a list of meter configurations that have been saved to your quote.</p>
                            <hr />
                            <div class="row">
                              <div class="col-12 cItems">
                                <div class="table-responsive">
                                    <table class="table qTable">
                                        <thead class="text-muted">
                                            <tr class="small text-uppercase">
                                              <th scope="col" width="40">ID #</th>
                                              <th scope="col meterName">Items</th>
                                              <th scope="col" width="120">Quantity</th>
                                              <th scope="col" width="120">Price</th>
                                              <th scope="col" width="200">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          { cartData.map((value, index) => {
                                            if(value.state){
                                              console.log('Show me mapping data 1.0 =>', mappingData.model_number.pipe_length[value.state.model_number.pipe_length].desc)
                                              var ca = value.state.model_number.certifications + value.state.model_number.approvals;
                                              if(value.state.model_number.certifications === 'NA'){
                                                var ca = value.state.model_number.certifications
                                              }
                                            }

                                            return(
                                              <tr>
                                                <td><b>{index + 1}</b></td>
                                                <td class="cMeterInfo">
                                                  <div class="row">
                                                    <div class="col-2">
                                                      <img
                                                        src={
                                                          value.state.meter_type?.type === 'inline'?
                                                          value.state.meter_images?.inline:
                                                          value.state.meter_images?.insertion
                                                        }
                                                        class="img-sm meterImg"
                                                      />
                                                    </div>
                                                    <div class="col-10">
                                                      <p class="qLabel">Meter Series:</p>
                                                      <p class="title text-dark">
                                                        {value.series}
                                                      </p>
                                                      <br />
                                                      <p class="qLabel">Model Number:</p>
                                                      <p class="text-muted small">
                                                        <b>8636MP- INT- SSS- 133- DC24- MW050- S4036MT- 1RC- AIR- CT2- MB</b>
                                                      </p>
                                                      <p class="qLabel">Calibrated For:</p>
                                                      <p class="text-muted small">
                                                        <b>Parameters - Temp - etc</b>
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div class="row cSpecs">
                                                    <div class="col-12">
                                                      {viewRep.index === index? (
                                                          <a
                                                              class="btn btn-text"
                                                              onClick={
                                                                  () => setViewRep({
                                                                      index: 'index',
                                                                      status: true
                                                                  })
                                                              }
                                                          >
                                                              <i class="fa fa-plus"></i>&nbsp;&nbsp;Show More Meter Specs
                                                          </a>
                                                      ) : (
                                                          <a
                                                              class="btn btn-text"
                                                              onClick={
                                                                  () => setViewRep({
                                                                      index: index,
                                                                      status: true
                                                                  })
                                                              }
                                                          >
                                                              <i class="fa fa-plus"></i>&nbsp;&nbsp;Show More Meter Specs
                                                          </a>
                                                      )}
                                                      <hr class="qHr" />
                                                      <Collapse isOpen={viewRep.index === index && viewRep.status}>
                                                        <table class="table qTable cTable">
                                                          <thead>
                                                            <tr>
                                                              <th scope="col">Category</th>
                                                              <th scope="col">Code</th>
                                                              <th scope="col">Description</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr>
                                                              <td>Meter Model</td>
                                                              <td className="qUppercase">{value.state.model_number.electronics}{value.state.model_number.pipe_length}{value.state.model_number.series}</td>
                                                              <td>
                                                                {`
                                                                  ${mappingData.model_number.electronics[value.state.model_number.electronics]?.desc}.
                                                                  ${mappingData.model_number.pipe_length[value.state.model_number.pipe_length]?.desc}.
                                                                `}
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>Transmitter</td>
                                                              <td className="qUppercase">{value.state.model_number.transmitter}</td>
                                                              <td>{mappingData.transmitter[value.state.model_number.transmitter]?.desc}</td>
                                                            </tr>
                                                            <tr>
                                                              <td>Sensor</td>
                                                              <td className="qUppercase">{value.state.model_number.sensor_material}</td>
                                                              <td>{mappingData.sensor_material[value.state.model_number.sensor_material]?.desc}</td>
                                                            </tr>
                                                            <tr>
                                                              <td>Display</td>
                                                              <td className="qUppercase">{value.state.model_number.display}</td>
                                                              <td>{mappingData.display[value.state.model_number.display]?.desc}</td>
                                                            </tr>
                                                            <tr>
                                                              <td>Input Power</td>
                                                              <td className="qUppercase">{value.state.model_number.input_power}</td>
                                                              <td>{mappingData.input_power[value.state.model_number.input_power]?.desc}</td>
                                                            </tr>
                                                            <tr>
                                                              <td>Temperature Range</td>
                                                              <td className="qUppercase">{value.state.model_number.gas_temp}</td>
                                                              <td>{mappingData.gas_temp[value.state.model_number.gas_temp]?.desc}</td>
                                                            </tr>
                                                            <tr>
                                                              <td>Configuration</td>
                                                              <td className="qUppercase">
                                                                {value.state.model_number.pipe_standard}
                                                                {value.state.model_number.pipe_length}
                                                                {value.state.model_number.mounting_requirements}
                                                              </td>
                                                              <td>
                                                                {`
                                                                  ${mappingData.pipe_standard[value.state.model_number.pipe_standard]?.desc}
                                                                  ${mappingData.pipe_standard.pipe_length[value.state.model_number.pipe_length]?.desc}
                                                                  ${mappingData.pipe_standard[value.state.model_number.mounting_requirements]?.desc}
                                                                `}
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>Range</td>
                                                              <td className="qUppercase">{value.state.model_number.range}</td>
                                                              <td>{mappingData.ranges[value.state.model_number.range]?.desc}</td>
                                                            </tr>
                                                            <tr>
                                                              <td>Gas Type</td>
                                                              <td className="qUppercase">{value.state.model_number.gas}</td>
                                                              <td>{mappingData.gas[value.state.model_number.gas.toUpperCase()]?.desc}</td>
                                                            </tr>
                                                            <tr>
                                                              <td>Certifications/Approvals</td>
                                                              <td className="qUppercase">{ca}</td>
                                                              <td>{mappingData.certifications_approvals[ca]?.desc}</td>
                                                            </tr>
                                                            <tr>
                                                              <td>Communication Protocols</td>
                                                              <td className="qUppercase">{value.state.model_number.communications_options_2}</td>
                                                              <td>{mappingData.communications_options_2[value.state.model_number.communications_options_2]?.desc}</td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <a href="" class="btn btn-text">
                                                        <i class="fa fa-plus"></i>&nbsp;&nbsp;Show Less Meter Specs
                                                        </a>
                                                      </Collapse>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div class="form-group">
                                                    <select class="epiInputSize form-select form-control">
                                                      <option>1</option>
                                                      <option>2</option>
                                                      <option>3</option>
                                                      <option>4</option>
                                                      <option>5+</option>
                                                    </select>
                                                  </div>
                                                </td>
                                                <td>
                                                    <div class="price-wrap">
                                                      <p class="price">${value.total}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                  <div class="cLinks">
                                                    <a href="" class="btn btn-text mt-0">
                                                      <i class="fa fa-edit"></i>&nbsp;&nbsp;Edit
                                                    </a>
                                                    <br />
                                                    <a href="" class="btn btn-text mt-0">
                                                      <i class="fa fa-trash"></i>&nbsp;&nbsp;Delete
                                                    </a>
                                                    <br />
                                                    <a href="" class="btn btn-text mt-0">
                                                      <i class="fa fa-eye"></i>&nbsp;&nbsp;View Saved Config
                                                    </a>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                    </table>
                                </div>
                              </div>
                              <div class="col-12 cCheckout text-right">
                                <p class="qPrice"><span class="qPricingInner">Subtotal:</span> $1,000.00</p>
                              </div>
                              <div class="col-12 cCheckout clearfix">
                                <a onClick={toggle} class="btn btn-gradient float-right">
                                  Submit for Review
                                </a>
                                <a href="#" class="btn btn-outline float-right">
                                  Continue Shopping
                                </a>
                              </div>
                            </div>
                            <hr />
                            <div class="row">
                              <div class="qTerms">
                                <br />
                                <p class="qLabel">Pricing notes:</p>
                                <p class="qNote"><b>- Total above does not include items listed as optional or alternative.<br />
                                - Pricing shown here does not include any applicable taxes which must be added at time of order placement.</b>
                                </p>
                                <br />
                                <div class="qTermsSection">
                                  <div class="row p-0">
                                    <div class="col-3 p-0">
                                      <p class="qLabel">Delivery time:</p>
                                    </div>
                                    <div class="col-9 p-0">
                                      <p class="qNote">(Delivery times are subject to change according to stock availability at the time of order.)
                                      </p>
                                    </div>
                                  </div>
                                  <div class="row p-0">
                                    <div class="col-3 p-0">
                                      <p class="qLabel">Delivery terms:</p>
                                    </div>
                                    <div class="col-9 p-0">
                                      <p class="qNote">Delivery to EPI dock for buyer pickup and transport using courier as approved by EPI.
                                      </p>
                                    </div>
                                  </div>
                                  <div class="row p-0">
                                    <div class="col-3 p-0">
                                      <p class="qLabel">Delivery location:</p>
                                    </div>
                                    <div class="col-9 p-0">
                                      <p class="qNote">Shipping Address/Location
                                      </p>
                                    </div>
                                  </div>
                                  <div class="row p-0">
                                    <div class="col-3 p-0">
                                      <p class="qLabel">Payment terms:</p>
                                    </div>
                                    <div class="col-9 p-0">
                                      <p class="qNote">Exworks Marina Factory
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </Row>
                    <SavedCartModal
                      modal={modal}
                      toggle={toggle}
                      cartData={cartData}
                    />
                  </div>
                </div>
            </div>
          </div>
    )
}

export default SavedCart;
