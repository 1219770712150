import React from "react";

// product views
import _300CrProductView from "components/ProductViews/Xt/XtInline/300CrProductView";

function XtInline({product, productType}) {
  switch (product) {
      case '300cr':
        return (
          <>
            <_300CrProductView productType={productType} />
          </>
        );
        break;
  
    default:
      return (
        <>
            <_300CrProductView />
        </>
      );
      break;
  }
}

export default XtInline;
