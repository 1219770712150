import React,{ useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useSwr from 'swr';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import fetcher from 'utils/fetcher';
import './ReturnPolicy.css'

import {
    Button,
    NavLink
} from "reactstrap";

function ReturnPolicy() {
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const page = { returnPolicy: true }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    var { data } = useSwr(`http://localhost:1337/api/me`, fetcher);
    useEffect(() => {
        if(data){
            setUserData(data);
        }
    }, [data]);

    const headerData = {
        firstname: userData.firstname,
        page: 'RETURN POLICY'
    }

    return (
        <div className='deliverytime'>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar> </Sidebar></div>) : undefined}
                    <div className='common-main'>
                        <h2 className='p-3'>Return Policy</h2>
                        <div className='bdr body p-3'>
                            <h4>The EPI Return Policy</h4>
                            <p>
                                Seller warrants its products to be free from defects in materials and
                                workmanship for one year from the date of factory shipment. If there is a defect, the purchaser
                                must notify Seller of the defect within the warranty period.
                                Upon Seller’s receipt and confirmation of the defective Product, Seller at its sole option, will
                                either repair the defective Product, or provide a Seller’s replacement, or refund the purchase
                                price of the defective Product.<br />
                                Repaired or replaced Products shall be warranted for the remainder of the warranty period. For
                                any of Seller’s installed Products that Buyer believes to be defective, Buyer shall immediately
                                discontinue product use.
                            </p>
                            <p>
                                For more information on our Warranty policy and submitting a claim visit our warranty page.
                                <br /><br />
                                <NavLink tag={Link} to="/portal/RMA">
                                    <Button className="button1" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                                        Request Return / RMA
                                    </Button>
                                </NavLink>
                            </p>

                            <h4>To be eligible for a return, the following criteria must be met:</h4>
                            <ul>
                                <li>All returns must be related to an EPI order.</li>
                                <li>
                                    All returns must be submitted through our RMA process within 30 days of original
                                    purchase. Once approved, shipping instructions and an RMA number will be provided.
                                </li>
                                <li>Any shipping costs from the original order are non-refundable</li>
                                <li>
                                    Any return shipping costs must be covered by the returner.
                                </li>
                                <li>
                                    All meters must be returned with full assembly, and fully decontaminated. A cleaning fee will be assessed if applicable.
                                </li>
                            </ul>

                            <p>
                                Once your return has been received, please allow 5 business days for evaluation results. This
                                evaluation includes analysis of functionality, hardware, firmware, and visual inspection. If the
                                evaluation reveals additional issues not covered under warranty, you will then be informed of
                                additional costs through your repair order.<br />

                                Any approved warranty repair orders and/or exchanges will be provided with a prepaid shipping
                                label for the return of your eligible EPI product.
                            </p>

                            <h4>We require the following when shipping your return:</h4>
                            <ul>
                                <li>All serial numbers must match. This includes sensor, electronics, probe/ ﬂow body.</li>
                                <li>
                                    Please include RMA number on the shipping labels and any other documents associated with the unit.
                                </li>
                                <li>
                                    Please make sure the sensor is protected. We recommend using a piece of PVC over
                                    the sensor/probe and securing it to prevent any damage during transportation.
                                </li>
                                <li>
                                    Inline ﬂow meters should not be disassembled for shipping, please leave the sensor
                                    securely ﬁxed in place inside the ﬂow body.
                                </li>
                                <li>
                                    Please ensure the meter is packaged with foam or bubble wrap to avoid meter damage.
                                </li>
                            </ul>
                            <p>
                                When shipping your returned meter, please include original order information or the return RMA
                                number inside the box. We recommend selecting a service that allows for package tracking and
                                keeping the tracking number for your records.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReturnPolicy
