import React from "react";

// product views
import _300CrProductView from "components/ProductViews/Xt/XtInsertion/300CrProductView";
import _525CrProductView from "components/ProductViews/Xt/XtInsertion/525CrProductView";
import _200CProductView from "components/ProductViews/Xt/XtInsertion/200CProductView";
import _200CrProductView from "components/ProductViews/Xt/XtInsertion/200CrProductView";

function XtInsertion({product, productType, tab}) {
  switch (product) {
      case '525cr':
        return (
          <>
            <_525CrProductView productType={productType} tab={tab} />
          </>
        );
        break;

      case '300cr':
        return (
          <>
            <_300CrProductView productType={productType} tab={tab} />
          </>
        );
        break;

      case '200c':
        return (
          <>
            <_200CProductView productType={productType} tab={tab} />
          </>
        );
        break;

      case '200cr':
        return (
          <>
            <_200CrProductView productType={productType} tab={tab} />
          </>
        );
        break;
  
    default:
      return (
        <>
            <_200CProductView productType='inline' tab={tab} />
        </>
      );
      break;
  }
}

export default XtInsertion;
