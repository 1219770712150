import React, { useState, useEffect } from 'react'
import './ContactUsNew.css'
import HipporelloEmbed from "@hipporello/embed"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faLifeRing } from '@fortawesome/free-solid-svg-icons'
// import ContactSection from 'components/Home/ContactSection/ContactSection'
import ContactSection from 'views/Home/ContactSection/ContactSection'
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";


function ContactUsNew() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [value, setValue] = useState();
    return (
      <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>EPI – Contact Us</title>
          <meta name="description" content="Use the information in this link to get in touch with us in whatever way works best for you: we will get back as soon as possible!" />
          <link rel="canonical" href="https://epiflow.com/contactus" />
      </Helmet>
        <div className='contact-us-new'>
            {/* section-1 */}
            <div className='commonbanner section-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/" tag={Link}>Home</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/contactus" tag={Link}>Contact Us</NavLink></span>
                      <span className='me-2 newLinkOverlay'>
                        <NavLink className='contactLink' to="/support" tag={Link}>
                          <FontAwesomeIcon icon={faLifeRing} className='icon' />&nbsp;
                          Get Support
                        </NavLink>
                      </span>
                  </div>
                    <div className='text-center'>
                        <h2 className='cover-title-h2'>Contact Us</h2>
                        <p className='cover-title-p'>Use the below information to get in touch with us in whatever way works best for you. We will get back to you as soon as we can!</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <div className='consection-2 container'>
                <h1>Get in touch with EPI.</h1>
                <ContactSection />
            </div>
            {/* section-2 */}
            {/* section-3 */}
            <div className='consection-3'>
                <div className='container'>
                    <h1>Send Us a Message</h1>
                    <br />
                    <HipporelloEmbed formId="e0521245b32f4ebba50afdb7ea64f5d0"/>
                    <br />
                </div>
                <iframe src="https://maps.google.com/maps?q=465%20Reservation%20Rd,%20Marina,%20CA%2093933&t=k&z=15&ie=UTF8&iwloc=&output=embed" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" title='ss' className='rounded'></iframe>
            </div>
            {/* section-3 */}
        </div>
        </>
    )
}

export default ContactUsNew
