import React from "react";
import axios from 'axios';
import profileicon from '../../assets/img/images/profile-icon.png';
import { Button, Modal } from "reactstrap";

function DistributorModal({modalLarge, setModalLarge, distributorData}){

  const update_distributor = (position) => {
    const update_data = {
      companyName: distributorData.companyName,
      contact: distributorData.contact,
      description: distributorData.description,
      email: distributorData.email,
      website: distributorData.website,
      phoneNumber: distributorData.phoneNumber,
      fax: distributorData.fax,
      image: distributorData.image,
      shippingAddress: distributorData.shippingAddress,
      shippingAddress2: distributorData.shippingAddress2,
      shippingAddress3: distributorData.shippingAddress3,
      activation_id: distributorData.activation_id,
      activation: position
    }

    try{
      axios.put(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/distributor`,
        update_data,
        { withCredentials: true }
      );
      setModalLarge(false);
    } catch (e) {
      console.log('Show me update user info 1.1 =>', e);
    }
  }
  return (
    <>
      <Modal
        isOpen={modalLarge}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setModalLarge(false)}
      >
        <div className="modal-header">
          <h4 className="modal-title" id="myLargeModalLabel">
            Distributor Details
          </h4>
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => setModalLarge(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className='row mt-3 mb-3'>
            <div className='col-md-2'>
              {
                  <img src={distributorData.image ? distributorData.image : profileicon} alt="" className='profileiconimg' />
              }
            </div>
          </div>
          <div className='row'>
              <div className='col-md-6'>
                  <div class="form-group">
                      <label for="">COMPANY NAME <span className='text-danger'>*</span></label>
                      <input
                          disabled
                          type="text"
                          class="form-control"
                          id=""
                          placeholder="Company Name"
                          defaultValue={ distributorData.companyName }
                      />
                  </div>
              </div>
              <div className='col-md-6'>
                  <div class="form-group">
                      <label for="">COMPANY CONTACT</label>
                      <input
                          disabled
                          type="text"
                          class="form-control"
                          id=""
                          placeholder="Company Contact"
                          defaultValue={ distributorData.contact }
                      />
                  </div>
              </div>
          </div>
          <div class="form-group">
              <label for="exampleFormControlTextarea1">COMPANY DESCRIPTION <span className='text-danger'>*</span></label>
              <textarea
                  disabled
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder='Enter a brief description of your company and/or the services your company offers here.'
                  defaultValue={ distributorData.description }
              ></textarea>
          </div>

          <div className='text-center'>
              <h4>Contact Information</h4>
          </div>

          <div className="row">
              <div className="col-md-6">
                  <label for="exampleInputEmail1" class="form-label">COMPANY EMAIL <span className='text-danger'>*</span></label>
                  <input
                      disabled
                      type="email"
                      class="form-control"
                      placeholder="email@domain.com"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      defaultValue={ distributorData.email }
                  />
              </div>
              <div className="col-md-6">
                  <label for="" class="form-label">COMPANY WEBSITE <span className='text-danger'>*</span></label>
                  <input
                      disabled
                      type="text"
                      class="form-control"
                      placeholder="Company Website"
                      id=""
                      defaultValue={ distributorData.website }
                  />
              </div>
          </div>

          <div className='row mt-3 mb-3'>
              <div className='col-md-6'>
                  <label For="" class="form-label">PHONE NUMBER <span className='text-danger'>*</span></label>
                  <input
                      disabled
                      type="text"
                      class="form-control"
                      id=""
                      placeholder="Company Phone Number"
                      defaultValue={ distributorData.phoneNumber }
                  />
              </div>
              <div className='col-md-6'>
                  <label For="" class="form-label">FAX NUMBER</label>
                  <input
                      disabled
                      type="text"
                      class="form-control"
                      id=""
                      placeholder="Company Fax Number"
                      defaultValue={ distributorData.faxNumber }
                  />
              </div>
          </div>

          <div className="row">
              <div className="col-md-4">
                  <label for="" class="form-label">COMPANY ADDRESS <span className='text-danger'>*</span></label>
                  <input
                      disabled
                      type="text"
                      class="form-control"
                      id=""
                      placeholder='Company Address Line 1'
                      defaultValue={ distributorData.shippingAddress }
                  />
              </div>
              <div className="col-md-4">
                  <label for="" class="form-label" style={{ visibility: 'hidden' }}>a</label>
                  <input
                      disabled
                      type="text"
                      class="form-control"
                      id=""
                      placeholder='Company Address Line 2'
                      defaultValue={ distributorData.shippingAddress2 }
                  />
              </div>
              <div className="col-md-4">
                  <label for="" class="form-label" style={{ visibility: 'hidden' }}>a</label>
                  <input
                      disabled
                      type="text"
                      class="form-control"
                      id=""
                      placeholder='Company Address Line 3'
                      defaultValue={ distributorData.shippingAddress3 }
                  />
              </div>
          </div>
        </div>
        <hr />
        <div className="modal-footer">
          <Button
            color="secondary"
            type="button"
            onClick={() => update_distributor('2')}
          >
            Remove
          </Button>
          <Button
            disabled
            color="primary"
            type="button"
            onClick={() => update_distributor('1')}
          >
            Approve
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default DistributorModal;