import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useSwr, { useSWRConfig } from 'swr';
import { Form, Button } from 'reactstrap'
import 'assets/css/EditAccount.css';
import fetcher from 'utils/fetcher';


function PasswordReset() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [formData, setFormData] = useState([]);
    const [emptyField, setEmptyField] = useState(false);
    const [userData, setUserData] = useState([]);
    const [resetSuccess, setResetSuccess] = useState(false);

    const { data, mutate } = useSwr("/api/me", fetcher);

    useEffect(() => {
      if(data){
        setUserData(data);  
      }

      setFormData({
        old_password: '',
        new_password: '',
      });
    }, []);

    const updateDefaultValue = (formFeild) => {
      const feild = formFeild.feild;
      const textValue = formFeild.value.target.value;

      setFormData(prevState => ({
        ...prevState,
        [feild]: textValue
      }))
    }

    const checkDataFunc = (data, setFuction) => {
      const user_data = data;
      user_data['profileImage'] = 'empty';

      if(!data.jobTitle || data.jobTitle === ""){
        user_data['jobTitle'] = 'empty';
      }
      if(!data.department || data.department === ""){
        user_data['department'] = 'empty';
      }

      const checkData = Object.values(user_data);
      for (var i = 0; i < checkData.length; i++){
        if(checkData[i] === undefined){
          setFuction(true);
          return
        }
        setFuction(false);
      }
    }

    const onSubmit = async (type) => {
      let update_data = {
        old_password: formData.old_password,
        new_password: formData.new_password
      }

      // checkDataFunc( update_data, setEmptyField );
      // if(emptyField){ return }

      console.log('Show me update user info 1.2 =>', update_data);

      try{
        await axios.put(
          `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/auth/forgotpassword/dash`,
          update_data,
          { withCredentials: true }
        )
        setResetSuccess(true);
      } catch (e) {
        console.log('Show me update user error 1.0 =>', e);
      }
    }

    console.log('Show me update user info 1.2 =>', formData);

    return (
        <div>
          <div className='common-main'>
              <h2 className='p-3'>Password Reset</h2>
              <div className='bdr body p-3'>
                  <div>
                      <Form>
                          <div className='row'>
                              <div className="row">
                                <div className='col-md-6'>
                                  <div class="form-group">
                                    <label for="oldpassword">Email <span className='text-danger'>*</span></label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="email"
                                      id="email"
                                      autoComplete='email'
                                      placeholder="Email"
                                      value={userData.email}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-6'>
                                  <div class="form-group">
                                      <label for="oldpassword">OLD PASSWORD <span className='text-danger'>*</span></label>
                                      <input
                                          type="text"
                                          class="form-control"
                                          name="oldpassword"
                                          id="oldpassword"
                                          autoComplete='given-name'
                                          placeholder="Old password"
                                          onChange={(e) => updateDefaultValue({
                                            feild: 'old_password',
                                            value: e
                                          }, e)}
                                      />
                                  </div>
                              </div>
                              <div className='col-md-6'>
                                  <div class="form-group">
                                      <label for="newpassword">NEW PASSWORD <span className='text-danger'>*</span></label>
                                      <input
                                          type="text"
                                          class="form-control"
                                          id="newpassword"
                                          name="newpassword"
                                          autoComplete='family-name'
                                          placeholder="New password"
                                          onChange={(e) => updateDefaultValue({
                                            feild: 'new_password',
                                            value: e
                                          }, e)}
                                      />
                                  </div>
                              </div>
                          </div>
                      </Form>
                      <div className='text-center'>
                        {resetSuccess && 
                          <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>
                            Your password was successfully reset.
                          </p>
                        }
                      </div>
                      <Button className='inputButton' onClick={(e) => onSubmit()}> Reset Password </Button>
                  </div>
              </div>
          </div>
        </div>
    )
}

export default PasswordReset
