import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import useSwr from 'swr';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useForm } from "react-hook-form"
import { Form, Button } from 'reactstrap'


import fetcher from 'utils/fetcher';

import 'assets/css/Login.css'
import logo from 'assets/img/logo1.png'

function NoPasswordLoginWithToken() {
  const history = useHistory();
  var { token } = useParams();
  const [dirty, setDirty] = useState(false);
  const [allReturn, setAllReturn] = useState(false);
  const { data } = useSwr(`/api/me`, fetcher);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const auth = useCallback( async() => {
    {data && history.push("/portal")}

    if(!dirty){
      let forget_data = {
        loginToken: token
      }
  
      try {
        await axios.post(
          `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/sessions/nopasswordlogin`,
          forget_data,
          { withCredentials: true }
        );
        setDirty(true);
  
        // console.log('Show me login token 1.0 =>', loginToken);
        history.go("/portal");
      } catch (e) {
        setDirty(true);
        setAllReturn(true);
      }
    }
  }, [token, dirty, data]);

  auth();

  return (
    <>
      <div className='login-container'>
        {!allReturn &&
          <div className='login-main shadow'>
            <div className='text-center mb-4'>
              <img src={logo} alt="EPI Logo" />
            </div>
            <h5>Loading...</h5>
          </div>
        }

        {allReturn &&
          <div className='login-main shadow'>
            <div className='text-center mb-4'>
              <img src={logo} alt="EPI Logo" />
            </div>
            <h5>Log In Without Password</h5>
            <div>
              <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>This link has expired please trying again with the link below.</p>
            </div>
            <a href="#" onClick={() => history.push('/login')}>
              <p className="account-text">Already have an account? Log In</p>
            </a>
          </div>
        }
      </div>
    </>
  );
}

export default NoPasswordLoginWithToken;
