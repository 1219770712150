import React, { useRef, useEffect, useState } from 'react'
import useSwr from 'swr';
import { Modal, Button } from 'reactstrap'
import { useReactToPrint } from 'react-to-print';
import fetcher from 'utils/fetcher';
import GetRealValues from './GetRealValues';
import MappingData_ from "./ModelNumMappingData/Inline";
import ProcessParameters from "./ProcessParameters";

import './viewQuote2.css';
import './ViewPDF.css';
import logo from "assets/img/logo1.png";

function ViewPDFModal ({quoteData, viewInvoice, setViewInvoice, state, getKeyByValue}){
  const [mappingData, setMappingData] = useState(false);
  const [userData, setUserData] = useState([]);
  const [invoiceAddress, setInvoiceAddress] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);


  const { data } = useSwr("/api/me", fetcher);
  const { data:invoiceAddressData } = useSwr("/api/addresses/invoicesAddress", fetcher);
  const { data:shippingAddressData } = useSwr("/api/addresses/shippingAddress", fetcher);

  useEffect(() => {
    if(data){
      setUserData(data);
    }

    if(invoiceAddressData){
      setInvoiceAddress(invoiceAddressData[0]);
    }

    if(shippingAddressData){
      setShippingAddress(shippingAddressData[0]);
    }
  }, [data, invoiceAddressData, shippingAddressData]);

  useEffect(() => {
    if(MappingData_){
      setMappingData(MappingData_[0]);
    }
  }, [MappingData_]);

  if(state){
    var ca = state.model_number.certifications + state.model_number.approvals;
    if(state.model_number.certifications === 'NA'){
      var ca = state.model_number.certifications
    }
  }

  document.title=`epiflow-quote-${quoteData.quoteId}`;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // const {
  //   flow_direction,
  //   meter_housing,
  //   pipe_information,
  //   application_information,
  //   input_power,
  //   communication_options,
  //   mounting_requirements,
  //   flow_body_sensor,
  //   flow_body_shield
  // } = GetRealValues(state, getKeyByValue);
  // console.log('Show me pdf user data 1.0 =>', userData, invoiceAddress, shippingAddress);

  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  const date = new Date(quoteData.createdAt);
  const day = date.getDate();
  const month = ("0" + (date.getMonth() + 1)).slice(-2)
  const year = date.getFullYear();

  const futureDate = date.addDays(30);
  const dayF = futureDate.getDate();
  const monthF = ("0" + (futureDate.getMonth() + 1)).slice(-2)
  const yearF = futureDate.getFullYear();

  const total = quoteData + 400;

  const checkState = (checkData, position) => {
    if(checkData.valuesObj){
      if(checkData.valuesObj && checkData.valuesObj[position]){
        return checkData.valuesObj[position]
      }
    }

    if(checkData && checkData[position]){
      return checkData[position]
    }
  }

  const getValue = (object, value) => {
    if(object){
      return object.valuesObj[value]
    }
  }

  const ShowInvoice = () => {
    return(
      <div class="container-fluid" ref={componentRef}>
        <div id="content">
          <div class="row qHeader">
            <div class="col-9">
              <p class="qQuoteInfo">
                <b class="qHeading">Quote #{quoteData.quoteId}</b>
                <br />
                Date: { `${month}/${day}/${year}` }
                <br />
                Valid Through: { `${monthF}/${dayF}/${yearF}` }
                <br />
                <div id="pageCounter">3 Pages </div>
                </p>
            </div>
            <div class="col-3">
              <img class="qLogo" src="/logo-primary.png" alt="EPI Logo" />
            </div>
          </div>
          <div class="row qLetter">
            <div class="col-9">
              <p class="qCustomerInfo">
                {userData.firstname} {userData.lastname} <br />
                {userData.address && `
                  ${userData.address},
                  ${userData.city},
                  ${userData.state},
                  ${userData.zip}
                `} <br />
                {userData.email} <br />
                {userData.phoneNumber}
              </p>
            </div>
            <div class="col-3">
              <p class="qCompanyInfo">
                <b>Eldridge Products, Inc.</b> <br />
                465 Reservation Rd. <br />
                Marina, CA 93933 <br />
                831-648-7777 <br />
                sales@epiflow.com
              </p>
              <br />
              <p class="qLabel">Created by:</p>
              <p class="qCompanyInfo">
                EPI Sales Name<br />
                831-648-7777 <br />
                sales@epiflow.com
              </p>
            </div>
            <br />
          </div>
          <div class="pagebreak"> </div>
          <div class="qMain">
            <div class="row">
              <div class="col-8">
                <div class="qMeterInfo qPaddingTop qPaddingBottom">
                  <p class="qLabel">Meter Series:</p>
                  <p class="qLarge">{quoteData.series }</p>
                  <p class="qLabel">Model Number:</p>
                  <p class="qMedium" style={{'text-transform': 'uppercase'}}>
                    {/* 8640MP-DCEX-SSS-133-DC24-MW100-4018NA-1RC-FLARE-AT2-MB/HT */}
                    {`
                      ${state.model_number.electronics}${state.model_number.pipe_length}${state.model_number.series}-
                      ${state.model_number.transmitter}-
                      ${state.model_number.sensor_material}${state.model_number.sensor_shield}-
                      ${state.model_number.display}-
                      ${state.model_number.input_power}-
                      ${state.model_number.gas_temp}-
                      ${state.model_number.pipe_standard}${state.model_number.pipe_length}${state.model_number.mounting_requirements}-
                      ${state.model_number.range}-
                      ${state.model_number.gas}-
                      ${ca}-
                      ${state.model_number.communications_options_2}
                    `}
                  </p>
                  <br />
                  <div class="qCustomerNotes">
                    <p class="qLabel">Notes:</p>
                    <p class="qNote">Customer notes here.</p>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <img class="img qMeterImg" src={state.meter_images[state.meter_type.type]} alt="" />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <table class="table qTable">
                  <thead>
                    <tr>
                      <th style={{'background': '#b7bdc8'}} scope="col">Category</th>
                      <th scope="col">Code</th>
                      <th scope="col">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Meter Model</td>
                      <td className="qUppercase">{state.model_number.electronics}{state.model_number.pipe_length}{state.model_number.series}</td>
                      <td>
                        {`
                          ${mappingData.model_number.electronics[state.model_number.electronics]?.desc}.
                          ${mappingData.model_number.pipe_length[state.model_number.pipe_length]?.desc}.
                        `}
                      </td>
                    </tr>
                    <tr>
                      <td>Transmitter</td>
                      <td className="qUppercase">{state.model_number.transmitter}</td>
                      <td>{mappingData.transmitter[state.model_number.transmitter]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Sensor</td>
                      <td className="qUppercase">{state.model_number.sensor_material}</td>
                      <td>{mappingData.sensor_material[state.model_number.sensor_material]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Display</td>
                      <td className="qUppercase">{state.model_number.display}</td>
                      <td>{mappingData.display[state.model_number.display]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Input Power</td>
                      <td className="qUppercase">{state.model_number.input_power}</td>
                      <td>{mappingData.input_power[state.model_number.input_power]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Temperature Range</td>
                      <td className="qUppercase">{state.model_number.gas_temp}</td>
                      <td>{mappingData.gas_temp[state.model_number.gas_temp]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Configuration</td>
                      <td className="qUppercase">
                        {state.model_number.pipe_standard}
                        {state.model_number.pipe_length}
                        {state.model_number.mounting_requirements}
                      </td>
                      <td>
                        {`
                          ${mappingData.pipe_standard[state.model_number.pipe_standard]?.desc}
                          ${mappingData.pipe_standard.pipe_length[state.model_number.pipe_length]?.desc}
                          ${mappingData.pipe_standard[state.model_number.mounting_requirements]?.desc}
                        `}
                      </td>
                    </tr>
                    <tr>
                      <td>Range</td>
                      <td className="qUppercase">{state.model_number.range}</td>
                      <td>{mappingData.ranges[state.model_number.range]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Gas Type</td>
                      <td className="qUppercase">{state.model_number.gas}</td>
                      <td>{mappingData.gas[state.model_number.gas.toUpperCase()]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Certifications/Approvals</td>
                      <td className="qUppercase">{ca}</td>
                      <td>{mappingData.certifications_approvals[ca]?.desc}</td>
                    </tr>
                    <tr>
                      <td>Communication Protocols</td>
                      <td className="qUppercase">{state.model_number.communications_options_2}</td>
                      <td>{mappingData.communications_options_2[state.model_number.communications_options_2]?.desc}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <ProcessParameters
              state={state}
              getValue={getValue}
              checkState={checkState}
            />
            <div class="row qPricingSection">
              <div class="col-12">
                {userData.account_type === "admin" &&
                  <div class="qPricing">
                    {/* <p class="qTaxes"><span class="qTaxesInner">Taxes:</span> $200</p>
                    <p class="qTaxes"><span class="qTaxesInner">Shipping:</span> $200</p>
                    <p class="qPriceInner"><span class="qPricingInner">Taxes not included</span></p>*/}
                    <p class="qPrice"><span>Subtotal:</span> ${quoteData.total}</p>
                  </div>
                }

                {userData.account_type === "regularPlusPricing" &&
                  <div class="qPricing">
                    {/* <p class="qTaxes"><span class="qTaxesInner">Taxes:</span> $200</p>
                    <p class="qTaxes"><span class="qTaxesInner">Shipping:</span> $200</p>
                    <p class="qPriceInner"><span class="qPricingInner">Taxes not included</span></p>*/}
                    <p class="qPrice"><span class="qPricingInner">Subtotal:</span> ${quoteData.total}</p>
                  </div>
                }
                <div class="qTerms">
                  <p class="qLabel"><b>Pricing notes:</b></p>
                  <p class="qNote"><b>- Total above does not include items listed as optional or alternative.<br />
                  - Pricing shown here does not include any applicable taxes which must be added at time of order placement.</b>
                  </p>
                  <br />
                  <div class="qTermsSection">
                    <div class="row p-0">
                      <div class="col-3 p-0">
                        <p class="qLabel">Delivery time:</p>
                      </div>
                      <div class="col-9 p-0">
                        <p class="qNote">(Delivery times are subject to change according to stock availability at the time of order.)
                        </p>
                      </div>
                    </div>
                    <div class="row p-0">
                      <div class="col-3 p-0">
                        <p class="qLabel">Delivery terms:</p>
                      </div>
                      <div class="col-9 p-0">
                        <p class="qNote">Delivery to EPI dock for buyer pickup and transport using courier as approved by EPI.
                        </p>
                      </div>
                    </div>
                    <div class="row p-0">
                      <div class="col-3 p-0">
                        <p class="qLabel">Delivery location:</p>
                      </div>
                      <div class="col-9 p-0">
                        <p class="qNote">Shipping Address/Location
                        </p>
                      </div>
                    </div>
                    <div class="row p-0">
                      <div class="col-3 p-0">
                        <p class="qLabel">Payment terms:</p>
                      </div>
                      <div class="col-9 p-0">
                        <p class="qNote">Exworks Marina Factory
                        </p>
                      </div>
                    </div>
                    <div class="row p-0">
                      <div class="col-3 p-0">
                        <p class="qLabel">Quote valid until:</p>
                      </div>
                      <div class="col-9 p-0">
                        <p class="qNote">09/11/2022 (Good for 30 Days)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pagebreak"> </div>
            <div id="pageCounter">3 Pages </div>

            <br />
            <div class="qAdditional">
              <p class="qLabel">Note:</p>
              <p class="qNote">Please take note that we have a minimum order value of $350.00 for orders routed through the HBK sales offices. You can order online in our HBMshop without any minimum order value.
              <br />
              You can find this quotation in your MyHBM area and can easily order it from there. If you do not have an HBMshop account yet, register today!
              </p>
              <p class="qLabel">Services:</p>
              <p class="qNote">HBK offers a wide range of support services including initial system installation, on-site training, annual equipment calibration and consulting. These services are not included in this quote unless clearly stated as a specific line item. Please request an additional service quotation at sales@usa.hbm.com.
              </p>
              <p class="qLabel">Technical modifications:</p>
              <p class="qNote">All information about the properties of our products is only of a descriptive character. It is not to be understood as express warranty or quality guarantees - unless expressly stated otherwise.
              </p>
              <p class="qLabel">Export:</p>
              <p class="qNote">Regardless of any disclosure made by Buyer to HBK of the ultimate destination or application of HBK products, Buyer shall not export either directly or indirectly any HBK product or any system incorporating any HBK product either in contravention of statute or regulation or without first obtaining all required licenses and permits from the United States Government and any other relevant agencies or authorities.
              </p>
              <p class="qLabel">Warranty:</p>
              <p class="qNote">Hottinger Bruel & Kjaer Inc. warrants that all equipment shall be free from defects in material and workmanship under normal use for a period of one year from date of shipment. Please see the complete Standard Terms and Conditions of Sale for Hottinger Bruel & Kjaer Inc. for details.
              </p>
              <p class="qLabel">Cancellation:</p>
              <p class="qNote">Orders for products that have been custom manufactured or produced on customer orders cannot be cancelled free of charge. Any cancellation of an order will result in the purchasing and production costs already incurred being invoiced, up to 100% of the order value for these components.
              </p>
              <p class="qLabel">Terms and Conditions:</p>
              <p class="qNote">All business is conducted in strict accordance with the Standard Terms and Conditions of Sale of Hottinger Bruel & Kjaer Inc. You can download the current Standard Terms and Conditions of Sale from our website.
              </p>
            </div>
              {/* <div class="row qPaddingBottom qInfos">
                <div class="col-md-4 addressCol">
                  <div class="customBorder">
                    <p class="qLabel">Invoice To:</p>
                    <hr class="qHr" />
                    <p class="qAddress">
                      {userData.firstname} {userData.lastname} <br />
                    <hr />
                      {userData.phoneNumber}
                      <hr />
                      {userData.email}
                      {userData.address && <hr />}
                      {userData.address && `
                        ${userData.address},
                        ${userData.city},
                        ${userData.state},
                        ${userData.zip}
                      `}
                    </p>
                  </div>
                </div>
                <div class="col-md-4 addressCol">
                  <div class="customBorder">
                    <p class="qLabel">Bill To:</p>
                    <hr class="qHr" />
                    {invoiceAddress &&
                      <p class="qAddress">
                        {invoiceAddress.company} <hr />
                        {`
                          ${invoiceAddress.address},
                          ${invoiceAddress.city},
                          ${invoiceAddress.state},
                          ${invoiceAddress.zip}
                        `}
                      </p>
                    }
                    {!invoiceAddress && <p class="qAddress">No Invoice Address</p>}
                  </div>
                </div>
                <div class="col-md-4 addressCol">
                  <div class="customBorder">
                    <p class="qLabel">Ship To:</p>
                    <hr class="qHr" />
                    {shippingAddress &&
                      <p class="qAddress">
                        {shippingAddress.company} <hr />
                        {`
                          ${shippingAddress.address},
                          ${shippingAddress.city},
                          ${shippingAddress.state},
                          ${shippingAddress.zip}
                        `}
                      </p>
                    }
                    {!shippingAddress && <p class="qAddress">No Shipping Address</p>}
                  </div>
                </div>
              </div> */}
            <div class="row qCompany p-0">
              <div class="col-12 text-center">
                <p class="qBrand">Eldridge Products, Inc.</p>
                <p class="qBrandAddress">465 Reservation Rd. Marina, CA 93933</p>
                <p class="qContact"><a href="tel:1-831-648-7777">831-648-7777</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="mailto:sales@epiflow.com">sales@epiflow.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return(
    <>
      <Modal
          isOpen={viewInvoice}
          className="modal-lg"
          modalClassName="bd-example-modal-lg"
          toggle={() => setViewInvoice(false)}
      >
          <div className="modal-body">
            <div className='text-center' style={{ 'margin': '10px auto', 'max-width': '300px'}}>
              <Button
                className="button1 loginbtn"
                style={{ 'font-size': '1.0em', 'margin': '0px' }}
                onClick={handlePrint}
              >
                Download/Print PDF
              </Button>
            </div>
            <ShowInvoice />
          </div>
      </Modal>
    </>
  );
}

export default ViewPDFModal;
