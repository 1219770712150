import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Col, Row, ListGroup, ListGroupItem } from 'reactstrap'

function CADFiles({data}) {
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // const goToDownloads = () => {
    //     his
    // }

    return (
        <div>
            <div className="container">
                <h1 className='commontitleOne text-center pt-5 pb-3'>CAD Files</h1>
                <div className='mt-5 mb-5'>
                    {data.files.map((value, index) => {
                        if(!value.productName){
                            return(
                                <>
                                    <div className='text-center' style={{ 'padding': '0'}}>
                                        Use the link below to request CAD file for this meter.
                                        <div className='section-3 text-center' style={{ 'background': 'whitesmoke'}}>
                                            <a href="#" onClick={() => history.push('/support/downloads')} className='btn btn-primary'>View All Downloads</a>
                                        </div>
                                    </div>
                                </>
                            );
                        }
                        
                        return(
                            <>
                                <Row>
                                    {index != 0 && <hr />}
                                    <Col style={{ 'padding': '0px' }}>
                                        <div className="cadEmHeader" style={{ 'padding': '10px', 'font-size': '14px', 'font-weight': '600' }}>
                                            Series {value.productName}
                                        </div>
                                        <iframe
                                            style={{ 'padding': '0px'}}
                                            src={value.modelLink}
                                            width="740"
                                            height="366"
                                            allowfullscreen="true"
                                            webkitallowfullscreen="true"
                                            mozallowfullscreen="true"
                                            frameborder="0"
                                        ></iframe>
                                        <br /><br />
                                        <span>
                                            {value.description}
                                        </span>
                                    </Col>
                                    <Col style={{
                                        'border': 'solid #eee',
                                        'border-width': '0.5px',
                                        'padding': '0px',
                                        'max-height': '407px',
                                        'border-left': 'none'
                                    }}>
                                        <div className="cadEmHeader1" style={{ 'padding': '12px', 'font-size': '20px', 'font-weight': '900' }}>
                                            RELATED FILES (2)
                                            <hr />
                                        </div>
                                        <ListGroup flush style={{ 'padding': '12px' }}>
                                            <ListGroupItem
                                                style={{ 'background-color': '#eeed', 'font-weight': '600', 'padding': '20px'}}
                                                action
                                                target="_blank"
                                                href={value.pdfLink}
                                                tag="a"
                                            >
                                                <tr>
                                                    <th width="90%">Series {value.productName} Drawings PDF </th>
                                                    <th width="10%"></th>
                                                    <th width="10%"></th>
                                                    <th width="10%">
                                                        <i className="now-ui-icons arrows-1_minimal-right" style={{'font-weight': '600', 'font-size': '17px'}}></i>
                                                    </th>
                                                </tr>
                                            </ListGroupItem>

                                            <ListGroupItem
                                                style={{ 'background-color': '#eeed', 'font-weight': '600', 'padding': '20px'}}
                                                action
                                                target="_blank"
                                                href={value.downloadLink}
                                                tag="a"
                                            >
                                                <tr>
                                                    <th width="90%">Series {value.productName} Model Download </th>
                                                    <th width="10%"></th>
                                                    <th width="10%"></th>
                                                    <th width="10%">
                                                        <i className="now-ui-icons arrows-1_minimal-right" style={{'font-weight': '600', 'font-size': '17px'}}></i>
                                                    </th>
                                                </tr>
                                            </ListGroupItem>
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </>
                        );
                    })}
                </div>
                <div className='p-4'></div>
            </div>
            {/* section 3 */}
        </div>
    )
}

export default CADFiles
