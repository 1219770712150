import React from "react";
import ReactGA from 'react-ga';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  withRouter
} from 'react-router';

import { RedirectRoutes } from "./RedirectRoutes";

// Auth pages
import Home from "views/Home/Home";
import AboutUs from "views/AboutUs/AboutUs";
import Login from "views/Login/Login";
import SignUp from "views/SignUp/SignUp";
import ContactUsNew from "views/ContactUsNew/ContactUsNew";
// import RegisterNewProduct from "views/RegisterNewProduct";
import RegisterNewProduct from "views/RegisterNewProduct/RegisterNewProduct";
import Quotes from "views/Quotes";
import Dashboard from "views/Dashboard/Dashboard";
import Distributors from "../views/Distributors/Distributors";
import ViewPrices from "views/ViewPrices/ViewPrices";
import ApprovedPricings from "../views/ApprovedPricings/ApprovedPricings";
// import ViewQuote from "views/ViewQuote";
// import EditAccount from "views/EditAccount";
import EditAccount from "views/EditAccount/EditAccount";
import MyAddresses from "views/MyAddresses/MyAddresses";
import BecomeDistributor from "views/BecomeDistributor/BecomeDistributor";
import RequestPricingAccess from "../views/RequestPricingAccess/RequestPricingAccess";
import ContactUs from "views/ContactUs";
import PaymentOptions from "views/PaymentOptions";
import ServiceRequest from "views/ServiceRequest/ServiceRequest";
import TechSupport from "views/TechSupport/TechSupport";
import RMA from "views/RMA/RMA";
import Warranty from "views/Warranty/Warranty";
import PrivacyPolicy from "views/PrivacyPolicy/PrivacyPolicy";
import DeliveryTime from "views/DeliveryTime/DeliveryTime";
import ReturnPolicy from "views/ReturnPolicy/ReturnPolicy";
import ResponseTimes from "views/ResponseTimes/ResponseTimes";
import PackingMaterials from "views/PackingMaterials/PackingMaterials";
import ViewQuote from "views/ViewQuote/ViewQuote";
import EditQuote from "views/EditQuote";
import EditCartQuote from "views/EditCartQuote";
import AllUsers from "views/AllUsers/AllUsers";

// Public pages
import HowToVideos from "views/HowToVideos/HowToVideos";
import Downloads from "views/Downloads/Downloads";
import Faq from "views/Faq/Faq";
import MeterModels from "views/MeterModels/MeterModels";
import EpiVal from "views/EpiVal/EpiVal";
import Support from "views/Support/Support";
import Shipping from "views/Shipping/Shipping";
import ProductRegistration from "views/ProductRegistration/ProductRegistration";
import WarrantyNew from "views/WarrantyNew/WarrantytNew";
import TechSupportNew from "views/TechSupportNew/TechSupportNew";
import RequestSoftware from "views/RequestSoftware/RequestSoftware";
import BugTracking from "views/BugTracking/BugTracking";
import SupportInquiry from "views/SupportInquiry/SupportInquiry";
import GetQuote from "views/GetQuote/GetQuote";
import ViewMeterRouter from "views/Meters/ViewMeterRouter";
import FindRep from "views/FindRep/FindRep";
import TechnicalInformation from "views/TechnicalInformation/TechnicalInformation";
import ServiceRequestNew from "views/ServiceRequestNew/ServiceRequestNew";
import PrivacyInquiry from "views/Legal/PrivacyInquiry";
import LegalPrivacyPolicy from "views/Legal/LegalPrivacyPolicy";
import RepairRecalibration from "views/Legal/RepairRecalibration";
import AgencyApprovalsCertificates from "views/AgencyApprovalsCertificates/AgencyApprovalsCertificates";
import Installation from "views/Installation/Installation";
import InstallationInline from "views/Installation/InstallationInline";
import InstallationInsertion from "views/Installation/InstallationInsertion";
import MeterConsiderations from "views/MeterConsiderations/MeterConsiderations";
import MeterConfigurations from "views/MeterConfigurations/MeterConfigurations";
import TechnologyComparison from "views/TechnologyComparison/TechnologyComparison";
import OperationalTheory from "views/OperationalTheory/OperationalTheory";
import IndustrialApplications from "views/IndustrialApplications/IndustrialApplications";
import WhitePapers from "views/WhitePapers/WhitePapers";
import GasesWeFlow from "views/GasesWeFlow/GasesWeFlow";
import FullGuideFlowMeters from "views/FullGuideFlowMeters/FullGuideFlowMeters";
import CompressedAir from "views/WhitePapers/CompressedAir";
import HVAC from "views/WhitePapers/HVAC";
import Landfill from "views/WhitePapers/Landfill";
import NaturalGas from "views/WhitePapers/NaturalGas";
import Wastewater from "views/WhitePapers/Wastewater";
import FlueGas from "views/WhitePapers/FlueGas";
import FlareGas from "views/WhitePapers/FlareGas";
import Oxygen from "views/IndustrialApplications/Oxygen";
import Methane from "views/IndustrialApplications/Methane";
import FlueGasApplication from "views/IndustrialApplications/FlueGas";
import AllProducts from "views/AllProducts/AllProducts";
import Sitemap from "views/SiteMap/Sitemap";
import ProductImages from "views/ProductImages/ProductImages";
import PageNotFound from "views/404/PageNotFound";
import ForgotPassword from "views/ForgotPassword/ForgotPassword";
import NoPasswordLoginWithToken from "views/NoPasswordLogin/NoPasswordLoginWithToken";
import SetNewPassword from "views/SetNewPassword/SetNewPassword";
import Cart from "views/Cart/Cart";
import AllQuotes from "views/AllQuotes/AllQuotes";
import AllRealQuotes from "views/AllRealQuotes/AllRealQuotes";
import SavedCart from "views/SavedCart/SavedCart";
import QuotesReal from "views/QuotesReal/QuotesReal";
import ViewQuoteSimple from "views/ViewQuote/ViewQuoteSimple";
import SingleUserConfigs from "views/SingleUserConfigs/SingleUserConfigs"

import UnSubscribe from "views/Subscription/UnSubscribe";
import ReSubscribe from "views/Subscription/ReSubscribe";
import Blank from "views/Blank/Blank";
import Blank2 from "views/Blank2/Blank2";

ReactGA.initialize('UA-227967716-1');

function Routes() {
  const isAuthenticated_ = JSON.parse(sessionStorage.getItem('ciuli'));
  // const isAuthenticated_ = true;

  const routerAuth = {
    isAuthenticated: true,
    authenticate() {
      if (!isAuthenticated_) {
        routerAuth.isAuthenticated = false;
      };
    }
  };
  routerAuth.authenticate();

  const pageViewsTracker = (path) => {
    ReactGA.pageview(path);
    console.log('Show me tracker 1.0 =>', path);
  }

  const routes = [
    {
      path: "/",
      exact: true,
      tracking: true,
      component: () => Home()
    },
    {
      path: "/aboutus",
      exact: true,
      tracking: true,
      component: () => AboutUs()
    },
    {
      path: "/products/:meter?/:type?/:product?/:tab?",
      exact: true,
      tracking: true,
      tracker_type: 'products',
      component: () => ViewMeterRouter()
    },
    {
      path: "/signup",
      exact: true,
      tracking: true,
      component: () => SignUp()
    },
    {
      path: "/login",
      exact: true,
      tracking: true,
      component: () => Login()
    },
    {
      path: "/login/:storedId?",
      exact: true,
      tracking: true,
      component: () => Login()
    },
    {
      path: "/activation/:activationId?",
      exact: true,
      tracking: true,
      component: () => Login()
    },
    {
      path: "/forgotpassword",
      exact: true,
      tracking: true,
      component: () => ForgotPassword()
    },
    {
      path: "/forgotpassword/:code",
      exact: true,
      tracking: true,
      component: () => SetNewPassword()
    },
    {
      path: "/nopasswordlogin/:token",
      exact: true,
      tracking: true,
      component: () => NoPasswordLoginWithToken()
    },
    {
      path: "/contactus",
      exact: true,
      tracking: true,
      component: () => ContactUsNew()
    },
    {
      path: "/metermodels",
      exact: true,
      tracking: true,
      component: () => MeterModels()
    },
    {
      path: "/epival",
      exact: true,
      tracking: true,
      component: () => EpiVal()
    },
    {
      path: "/support",
      exact: true,
      tracking: true,
      component: () => Support()
    },
    {
      path: "/support/faq",
      exact: true,
      tracking: true,
      component: () => Faq()
    },
    {
      path: "/support/howtovideos",
      exact: true,
      tracking: true,
      component: () => HowToVideos()
    },
    {
      path: "/support/downloads",
      exact: true,
      tracking: true,
      component: () => Downloads()
    },
    {
      path: "/support/shipping",
      exact: true,
      tracking: true,
      component: () => Shipping()
    },
    {
      path: "/support/productregistration",
      exact: true,
      tracking: true,
      component: () => ProductRegistration()
    },
    {
      path: "/support/warranty",
      exact: true,
      tracking: true,
      component: () => WarrantyNew()
    },
    {
      path: "/support/techsupport",
      exact: true,
      tracking: true,
      component: () => TechSupportNew()
    },
    {
      path: "/support/requestsoftware",
      exact: true,
      tracking: true,
      component: () => RequestSoftware()
    },
    {
      path: "/support/bugtraking",
      exact: true,
      tracking: true,
      component: () => BugTracking()
    },
    {
      path: "/support/supportinquiry",
      exact: true,
      tracking: true,
      component: () => SupportInquiry()
    },
    {
      path: "/support/getquote",
      exact: true,
      tracking: true,
      component: () => GetQuote()
    },
    {
      path: "/support/servicerequest",
      exact: true,
      tracking: true,
      component: () => ServiceRequestNew()
    },
    {
      path: "/support/privacyinquiry",
      exact: true,
      tracking: true,
      component: () => PrivacyInquiry()
    },
    {
      path: "/support/privacyinquiry/terms",
      exact: true,
      tracking: true,
      component: () => LegalPrivacyPolicy()
    },
    {
      path: "/support/privacyinquiry/repairrecalibration",
      exact: true,
      tracking: true,
      component: () => RepairRecalibration()
    },
    {
      path: "/findrep",
      exact: true,
      tracking: true,
      component: () => FindRep()
    },
    {
      path: "/technicalinfo",
      exact: true,
      tracking: true,
      component: () => TechnicalInformation()
    },
    {
      path: "/technicalinfo/approvalscertificates",
      exact: true,
      tracking: true,
      component: () => AgencyApprovalsCertificates()
    },
    {
      path: "/technicalinfo/installation",
      exact: true,
      tracking: true,
      component: () => Installation()
    },
    {
      path: "/technicalinfo/installation/inline",
      exact: true,
      tracking: true,
      component: () => InstallationInline()
    },
    {
      path: "/technicalinfo/installation/insertion",
      exact: true,
      tracking: true,
      component: () => InstallationInsertion()
    },
    {
      path: "/technicalinfo/meterconsiderations",
      exact: true,
      tracking: true,
      component: () => MeterConsiderations()
    },
    {
      path: "/technicalinfo/meterconfigurations",
      exact: true,
      tracking: true,
      component: () => MeterConfigurations()
    },
    {
      path: "/technicalinfo/technologycomparison",
      exact: true,
      tracking: true,
      component: () => TechnologyComparison()
    },
    {
      path: "/technicalinfo/operationaltheory",
      exact: true,
      tracking: true,
      component: () => OperationalTheory()
    },
    {
      path: "/technicalinfo/industrialapplications",
      exact: true,
      tracking: true,
      component: () => IndustrialApplications()
    },
    {
      path: "/technicalinfo/whitepapers",
      exact: true,
      tracking: true,
      component: () => WhitePapers()
    },
    {
      path: "/technicalinfo/gasesweflow",
      exact: true,
      tracking: true,
      component: () => GasesWeFlow()
    },
    {
      path: "/technicalinfo/fullguideflowmeters",
      exact: true,
      tracking: true,
      component: () => FullGuideFlowMeters()
    },
    {
      path: "/whitepapers/compressedair",
      exact: true,
      tracking: true,
      component: () => CompressedAir()
    },
    {
      path: "/whitepapers/compressedair",
      exact: true,
      tracking: true,
      component: () => CompressedAir()
    },
    {
      path: "/whitepapers/hvac",
      exact: true,
      tracking: true,
      component: () => HVAC()
    },
    {
      path: "/whitepapers/landfill",
      exact: true,
      tracking: true,
      component: () => Landfill()
    },
    {
      path: "/whitepapers/naturalgas",
      exact: true,
      tracking: true,
      component: () => NaturalGas()
    },
    {
      path: "/whitepapers/wastewater",
      exact: true,
      tracking: true,
      component: () => Wastewater()
    },
    {
      path: "/whitepapers/fluegas",
      exact: true,
      tracking: true,
      component: () => FlueGas()
    },
    {
      path: "/whitepapers/flaregas",
      exact: true,
      tracking: true,
      component: () => FlareGas()
    },
    {
      path: "/industrialapplications/oxygen",
      exact: true,
      tracking: true,
      component: () => Oxygen()
    },
    {
      path: "/industrialapplications/methane",
      exact: true,
      tracking: true,
      component: () => Methane()
    },
    {
      path: "/industrialapplications/flue",
      exact: true,
      tracking: true,
      component: () => FlueGasApplication()
    },
    {
      path: "/sitemap",
      exact: true,
      tracking: true,
      component: () => Sitemap()
    },
    {
      path: "/allproducts/:productType?",
      exact: true,
      tracking: true,
      component: () => AllProducts()
    },
    {
      path: "/productimages/:imageLink?",
      exact: true,
      tracking: true,
      component: () => ProductImages()
    },
    {
      path: "/portal",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return Dashboard();
      }
    },
    {
      path: "/portal/dashboard",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return Dashboard();
      }
    },
    {
      path: "/portal/viewprices",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return ViewPrices();
      }
    },
    {
      path: "/portal/viewprices/approved",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return ApprovedPricings();
      }
    },
    {
      path: "/portal/distributors",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return Distributors();
      }
    },
    {
      path: "/portal/quotes/all",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return AllQuotes();
      }
    },
    {
      path: "/portal/quotes",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return Quotes();
      }
    },
    {
      path: "/portal/quotes/:quoteId",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return ViewQuote();
      }
    },
    {
      path: "/portal/quotes/v/:quoteId",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return ViewQuoteSimple();
      }
    },
    {
      path: "/portal/quotes/edit/:quoteId",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return EditQuote();
      }
    },
    {
      path: "/portal/quotes/edit/c/:quoteId",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return EditCartQuote();
      }
    },
    {
      path: "/portal/quotes/all/real",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return AllRealQuotes();
      }
    },
    {
      path: "/portal/quotes/real/s",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return QuotesReal();
      }
    },
    {
      path: "/portal/quotes/real/:quoteId",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return SavedCart();
      }
    },
    {
      path: "/portal/quotes/edit/real/:quoteId",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return EditQuote();
      }
    },
    {
      path: "/portal/users",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return AllUsers();
      }
    },
    {
      path: "/portal/registerProduct",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return RegisterNewProduct();
      }
    },
    {
      path: "/portal/editAccount",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return EditAccount();
      }
    },
    {
      path: "/portal/myAddresses",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return MyAddresses();
      }
    },
    {
      path: "/portal/becomeAdistributor",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/login"} />;
        };
        return BecomeDistributor();
      }
    },
    {
      path: "/portal/RequestPricingAccess",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/login"} />;
        };
        return RequestPricingAccess();
      }
    },
    {
      path: "/portal/contactUs",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return ContactUs();
      }
    },
    {
      path: "/portal/serviceRequests",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return ServiceRequest();
      }
    },
    {
      path: "/portal/techSupport",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return TechSupport();
      }
    },
    {
      path: "/portal/RMA",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return RMA();
      }
    },
    {
      path: "/portal/warranty",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return Warranty();
      }
    },
    {
      path: "/portal/paymentOptions",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return PaymentOptions();
      }
    },
    {
      path: "/portal/returnPolicy",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return ReturnPolicy();
      }
    },
    {
      path: "/portal/privacyPolicy",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return PrivacyPolicy();
      }
    },
    {
      path: "/portal/deliveryTime",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return DeliveryTime();
      }
    },
    {
      path: "/portal/responseTimes",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return ResponseTimes();
      }
    },
    {
      path: "/portal/packingMaterials",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return PackingMaterials();
      }
    },
    {
      path: "/portal/cart",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return Cart();
      }
    },
    {
      path: "/portal/sconfigs/:userID",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return SingleUserConfigs();
      }
    },
    {
      path: "/portal/blank",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return Blank();
      }
    },
    {
      path: "/portal/blank2",
      exact: true,
      tracking: true,
      component: () => {
        if(!routerAuth.isAuthenticated) {
          return <Redirect to={"/"} />;
        };
        return Blank2();
      }
    },
    {
      path: "/unsubscribe/:id",
      exact: true,
      tracking: true,
      component: () => {
        return UnSubscribe();
      }
    },
    {
      path: "/resubscribe/:id",
      exact: true,
      tracking: true,
      component: () => {
        return ReSubscribe();
      }
    }
  ];

  const allRouters = routes.concat(RedirectRoutes);
  return (
    <Switch>
      {
        allRouters.map((route, index) => {
          {route.tracking && (window.location.pathname + window.location.search === route.path) &&
            pageViewsTracker(route.path)
          }

          {route.tracker_type === 'products' && pageViewsTracker(window.location.pathname + window.location.search)}

          // Render more <Route>s with the same paths as
          // above, but different components this time.
          return(
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={<route.component />}
            />
          );
        })
      }
      <Route component={PageNotFound} />
    </Switch>
  );
}

export default withRouter(Routes);
