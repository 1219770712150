import React,{ useState, useEffect } from 'react';
import HipporelloEmbed from "@hipporello/embed"
import useSwr from 'swr';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import fetcher from 'utils/fetcher';

function RMA() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const page = { RMA: true }

    var { data } = useSwr(`http://localhost:1337/api/me`, fetcher);
    useEffect(() => {
        if(data){
            setUserData(data);
        }
    }, [data]);

    const headerData = {
        firstname: userData.firstname,
        page: 'RMA'
    }

    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar> </Sidebar></div>) : undefined}
                    <div className='common-main'>
                        <h2 className='p-3'>RMA Request</h2>
                        <div className='bdr body p-3'>
                            <p>The form below will open a Return Material Authorization (RMA) request, whether you are looking to have your meter fixed, calibrated or returned.
                            </p>
                           <hr />
                           <HipporelloEmbed formId="47420945b0ff4b81a96f1a12148fb606"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RMA
