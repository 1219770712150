import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from "react-router";
import axios from "axios";
import useSwr from 'swr';

import { faArrowRight, faCreditCard, faDownload, faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row, Input, FormGroup, Modal } from 'reactstrap'

import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import fetcher from 'utils/fetcher';
import info from '../../assets/img/images/info-icon.png'
import msimg from '../../assets/img/images/meter-series-img.png'
import righticon from '../../assets/img/images/right-icon-img.png'
import logo from "assets/img/logo1.png";

import vertical_top_to_bottom_left from 'assets/img/meter_customizer/meter_orientation/vertical_top_to_bottom_left.PNG';
import vertical_top_to_bottom_right from 'assets/img/meter_customizer/meter_orientation/vertical_top_to_bottom_right.PNG';
import vertical_bottom_to_top_left from 'assets/img/meter_customizer/meter_orientation/vertical_bottom_to_top_left.PNG';
import vertical_bottom_to_top_right from 'assets/img/meter_customizer/meter_orientation/vertical_bottom_to_top_right.PNG';

import horizontal_left_to_right_above from 'assets/img/meter_customizer/meter_orientation/horizontal_left_to_right_above.PNG';
import horizontal_left_to_right_below from 'assets/img/meter_customizer/meter_orientation/horizontal_left_to_right_below.PNG';
import horizontal_right_to_left_above from 'assets/img/meter_customizer/meter_orientation/horizontal_right_to_left_above.PNG';
import horizontal_right_to_left_below from 'assets/img/meter_customizer/meter_orientation/horizontal_right_to_left_below.PNG';
import DeleteModal from './DeleteModal';
import GetRealValues from './GetRealValues';
import ProcessParameters from './ProcessParameters';
import ViewPDFModal from './ViewPDFModal';
import ViewQuoteTempSimple from './ViewQuoteTempSimple';
import "./viewQuote.css";

function ViewQuoteSimple() {
    const [isOpen, setIsOpen] = useState(false);
    const [modalLive, setModalLive] = useState(false);
    const [userData, setUserData] = useState([]);
    const [quoteCreatorData, setQuoteCreatorData] = useState([]);
    const [quoteData, setQuoteData] = useState([]);
    const [state, setState] = useState(false);
    const [enteredQuoteId, setEnteredQuoteId] = useState(false);
    const [deleteQuoteSet, setDeleteQuoteSet] = useState(false);
    const [orientationImg, setOrientationImg] = useState();
    const [viewInvoice, setViewInvoice] = useState(false);

    const history = useHistory();
    var { quoteId } = useParams();
    const page = { quotes: true }

    var { data: user_data } = useSwr(`/api/me`, fetcher);
    var { data: Quotes } = useSwr(`/api/quotes/single/${quoteId}`, fetcher);

    useEffect(() => {
      if(user_data){
        setUserData(user_data);
      }

      if(Quotes){
        setQuoteData(Quotes)
        setState(Quotes.state)
      }

      if(enteredQuoteId == quoteData.quoteId){
       setDeleteQuoteSet(true);
      } else {
        setDeleteQuoteSet(false)
      }
    }, [user_data, Quotes, enteredQuoteId]);

    var { data: quote_creator_data } = useSwr(`/api/users/single/${quoteData.user}`, fetcher);
    useEffect(() => {
        if(quoteData && quote_creator_data){
            setQuoteCreatorData(quote_creator_data);
        }
    }, [quoteData, quote_creator_data]);

    useEffect(() => {
        if(state){
            const orientation = getKeyByValue(state.meter_orientation.pipe_orientation, true);

            if(checkState(state.meter_orientation.pipe_orientation, 'vertical')){
              const flowDirectionVertical = getKeyByValue(state.meter_orientation.flow_direction_vertical, true);
              const enclosureVertical = getKeyByValue(state.meter_orientation.enclosure_mounting_preference_vertical, true);

              switch (orientation +'_'+ flowDirectionVertical +'_'+ enclosureVertical) {
                case 'vertical_top_to_bottom_left_side':
                  setOrientationImg(vertical_top_to_bottom_left);
                  break;

                case 'vertical_top_to_bottom_right_side':
                  setOrientationImg(vertical_top_to_bottom_right);
                  break;

                case 'vertical_bottom_to_top_right_side':
                  setOrientationImg(vertical_bottom_to_top_right);
                  break;

                case 'vertical_bottom_to_top_left_side':
                  setOrientationImg(vertical_bottom_to_top_left);
                  break;

                default:
                  break;
              }
            }

            if(checkState(state.meter_orientation.pipe_orientation, 'horizion')){
              const flowDirectionHori = getKeyByValue(state.meter_orientation.flow_direction_horizontal, true);
              const enclosureHori = getKeyByValue(state.meter_orientation.enclosure_mounting_preference_horizontal, true);

              const image = orientation +'_'+ flowDirectionHori +'_'+ enclosureHori;
              console.log('Show me meter ori state 1.0.2 =>', image);

              switch (orientation +'_'+ flowDirectionHori +'_'+ enclosureHori) {
                case 'horizion_left_to_right_above_pipe':
                  setOrientationImg(horizontal_left_to_right_above);
                  break;

                case 'horizion_left_to_right_below_pipe':
                  setOrientationImg(horizontal_left_to_right_below);
                  break;

                case 'horizion_right_to_left_above_pipe':
                  setOrientationImg(horizontal_right_to_left_above);
                  break;

                case 'horizion_right_to_left_below_pipe':
                  setOrientationImg(horizontal_right_to_left_below);
                  break;

                default:
                  break;
              }
            }
        }
    }, [state]);

    const getKeyByValue = (object, value) => {
        console.log('Show me get value data 1.0 =>', object)
        if(object.valuesObj){
            const object_ = object.valuesObj;
            return Object.keys(object_).find(key => object_[key] === value);
        }
        return Object.keys(object).find(key => object[key] === value);
    }

    const checkState = (checkData, position) => {
        if(checkData.valuesObj){
            if(checkData.valuesObj && checkData.valuesObj[position]){
                return checkData.valuesObj[position]
            }
        }

        if(checkData && checkData[position]){
            return checkData[position]
        }
    }

    const getValue = (object, value) => {
        if(object){
            return object.valuesObj[value]
        }
    }

    const checkUnits = (unit) => {
        if(checkState(state.process_parameters.flow_rate_units, 'units') === unit){
            return true;
        }
        return false;
    }

    console.log('Show me view info 1.0 =>', state)

    const headerData = {
        firstname: userData.firstname,
        page: 'SAVED CONFIGURATIONS',
        subpage: 'CONFIGURATION'
    }

    const deleteQuote = async() => {
        const meterCustomizedData = {
            quoteId: quoteId
        }

        try {
            await axios.put(
                `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/quotes/delete/${quoteId}`,
                meterCustomizedData,
                { withCredentials: true }
            )
            history.push('/portal/quotes')
        } catch (e) {
            console.log('Show me db insert error', e.message);
        }
    }

    const quoteClicked = () => {
        history.push(`/portal/quotes/edit/${quoteId}`);
    }

    const {
        flow_direction,
        meter_housing,
        pipe_information,
        application_information,
        input_power,
        communication_options,
        mounting_requirements,
        flow_body_sensor,
        flow_body_shield
    } = GetRealValues(state, getKeyByValue);

    const comments = () => {
        if(state){
            if(state.questions_or_comments.input.info){
                return getValue(state.questions_or_comments.input, 'info')
            }
        }
        return null;
    }

    return (
        <div>
            <ViewPDFModal
                quoteData={quoteData}
                viewInvoice={viewInvoice}
                setViewInvoice={setViewInvoice}
                getKeyByValue={getKeyByValue}
                state={state}
                userData={userData}
            />
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar> </Sidebar></div>) : undefined}
                    <div className='common-main'>
                        <Row>
                            <Col md="12" sm="12">
                                <h3 className='commontitleOne mt-3 vq-ctone quoteId'>Configuration ID#: { quoteData.quoteId } ({quoteData.series})</h3>
                            </Col>
                            {/* <Col md="6" sm="12" className='d-flex align-items-end justify-content-md-end justify-content-sm-start'>
                                <div>
                                    <img src={info} className='vq-info-icon' alt="" />
                                    <a href="/" className='vq-cttow'>Quote Details</a>
                                </div>
                            </Col>*/}
                        </Row>
                        <div className="shadow">
                            <div className='quit-details-main'>
                                <div className='row quit-details-main-row'>
                                    <div className='col-md-6'>
                                        <h3 className='p-3 mt-2 quite-details-text'>Configuration Details</h3>
                                    </div>
                                    <div className='col-md-6'>
                                        {/* new */}
                                        <div className='row text-right quote-nav-options'>
                                            <ul className='m-0'>
                                                {/* <li><a href="/"><FontAwesomeIcon icon={faCreditCard} className='quoute-2d-element-icon'></FontAwesomeIcon>Buy Now</a></li> */}
                                                <li className='quoteMenuButton'>
                                                    <a onClick={(e) => quoteClicked()}>
                                                        <FontAwesomeIcon icon={faEdit} className='quoute-2d-element-icon'></FontAwesomeIcon>
                                                        Edit
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* new */}
                                    </div>
                                </div>
                            </div>

                            <div className='apayment body p-3 vq-body'>
                                <ViewQuoteTempSimple
                                    state={state}
                                    quoteData={quoteData}
                                    quoteCreatorData={quoteCreatorData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DeleteModal
                quoteData={quoteData}
                deleteQuote={deleteQuote}
                deleteQuoteSet={deleteQuoteSet}
                setEnteredQuoteId={setEnteredQuoteId}
                setModalLive={setModalLive}
                modalLive={modalLive}
            />
        </div>
    )
}

export default ViewQuoteSimple
