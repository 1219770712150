import React, { useEffect } from 'react';
import { Form, FormGroup, Button } from 'reactstrap'
import '../../assets/css/RegisterNewProduct.css';

function ProductView({data}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <div className='newproduct body p-3'>
          <p className='text-center'>Below meter is registered</p>
          <hr />
          <Form>
            <FormGroup>
              <label for="">METER SERIAL NUMBER <span className='text-danger'>*</span></label>
              <input
                disabled
                type="text" 
                className="form-control"
                name="address_one"
                value={data.serial_number}
                placeholder="Install Address Line 1"
              />
            </FormGroup>
            <FormGroup>
              <label for="">INSTALL LOCATION <span className='text-danger'>*</span></label>
              <input
                disabled
                type="text" 
                className="form-control"
                name="address_one"
                value={data.install_adress_one}
                placeholder="Install Address Line 1"
              />

            <input
                disabled
                type="text" 
                className="form-control new-input-field"
                name="address_one"
                value={data.install_adress_two}
                placeholder="Install Address Line 2"
              />

              <input
                disabled
                type="text" 
                className="form-control new-input-field"
                name="address_one"
                value={data.install_adress_three}
                placeholder="Install Address Line 3 (optional)"
              />
            </FormGroup>
            <div>
              <label for="" class="form-label">AGREE TO TERMS <span className='text-danger'>*</span></label>
              <div className='check-main d-flex ml-4'>
                <input class="form-check-input" type="checkbox" disabled checked id="flexCheckChecked" />
                <label class="form-check-label" for="flexCheckChecked">
                  I have read and understand the terms laid out in the Product Registration Disclaimer.
                </label>
              </div>
            </div>
          </Form>
      </div>
    </div>
  )
}

export default ProductView
