import React, { useEffect } from "react";

// product views
import AxgenProductView from "components/ProductViews/Axgen/AxgenInline/AxgenProductView";

function AxgenInline({product}) {
  switch (product) {
    case 'axgen':
      return (
        <>
          <AxgenProductView />
        </>
      );
      break;
  
    default:
      return (
        <>
            <AxgenProductView />
        </>
      );
      break;
  }
}

export default AxgenInline;
