import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useForm } from "react-hook-form"
import { Col, Container, Row, Form, Button, Input } from 'reactstrap'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function RequestSoftware() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { register, handleSubmit } = useForm();
    const [manual, setManual] = useState(false);
    const [formError, setFormError] = useState(false);
    const { REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT: api_url } = process.env;

    const onSubmit = async (data, e) => {
      if(!manual){
        setFormError(true);
        return null;
      }

      let request_form_data = {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        company: data.company,
        meter_serial_number: data.serialNumber,
        requested_manual: manual
      }

      console.log('Show me request form data 1.0 =>', request_form_data, api_url);

      try {
        await axios.post(
          `${api_url}/api/forms/requestedsoftware`,
          request_form_data,
          { withCredentials: false }
        )
      } catch (e) {
        console.log('Show me request form data error 1.0 =>', e.message);
      }
      e.target.reset();
    };

    return (
        <>
            {/* section-1 */}
            <div className='rsm-section-1'>
                <div className='image-container'>
                    <div className='about-title container pt-2 pb-2'>
                        <span className='me-2'>SUPPORT</span>
                        <FontAwesomeIcon icon={faChevronRight} className='icon' />
                        <span className='ml-2'>REQUEST SOFTWARE & MANUALS</span>
                    </div>
                    <div>
                        <h2 className='cover-title-h2'>Request Software & Manuals</h2>
                        <p className='cover-title-p'>All the items needed for the installation and connectivity with your EPI flow meter</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <div className='pb-5'>
                <Container>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <h3 className='commontitleOne pt-5 mb-4 text-center'>Submit Your Request Below</h3>
                        <h4 className='rsm-form-title'>Contact Information</h4>
                        <Row>
                            <Col lg="3" md="6" sm="12">
                                <div class="form-group">
                                    <label for="">FIRST NAME <span className='text-danger'>*</span></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id=""
                                        placeholder="First Name"
                                        {...register("firstname", { required: true })}
                                    />
                                </div>
                            </Col>
                            <Col lg="3" md="6" sm="12">
                                <div class="form-group">
                                    <label for="">LAST NAME <span className='text-danger'>*</span></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id=""
                                        placeholder="Last Name"
                                        {...register("lastname", { required: true })}
                                    />
                                </div>
                            </Col>
                            <Col lg="3" md="6" sm="12">
                                <div class="form-group">
                                    <label for="">EMAIL <span className='text-danger'>*</span></label>
                                    <input
                                        type="email"
                                        class="form-control"
                                        id=""
                                        placeholder="Email"
                                        {...register("email", { required: true })}
                                    />
                                </div>
                            </Col>
                            <Col lg="3" md="6" sm="12">
                                <div class="form-group">
                                    <label for="">COMPANY <span className='text-danger'>*</span></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id=""
                                        placeholder="Company"
                                        {...register("company", { required: true })}
                                    />
                                </div>
                            </Col>

                        </Row>
                        <h4 className='rsm-form-title'>Contact Information</h4>
                        <Row>
                            <Col md="6" sm="12">
                                <div class="form-group">
                                    <label for="">FLOW METER SERIAL NUMBER <span className='text-danger'>*</span></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id=""
                                        placeholder="####-#### (8 Digits)"
                                        {...register("serialNumber", { required: true })}
                                    />
                                </div>
                            </Col>
                            <Col md="6" sm="12">
                                <div class="form-group">
                                    <label for="">SOFTWARE OR MANUAL REQUESTED <span className='text-danger'>*</span></label>
                                    <Input
                                        className="epiInputSize form-select"
                                        id="exampleFormControlSelect1"
                                        type="select"
                                        onChange={ (e) => setManual(e.target.value) }
                                    >
                                        <option selected>SOFTWARE OR MANUAL REQUESTED</option>
                                        <option value="EPICom">EPICom</option>
                                        <option value="EPITerm">EPITerm</option>
                                        <option value="InstallaKon & Wiring Manual">InstallaKon & Wiring Manual</option>
                                        <option value="Master-Touch Menu Manual">Master-Touch Menu Manual</option>
                                    </Input>
                                </div>
                                { formError && <span className='text-danger'>Please select a software or a manual.</span> }
                            </Col>
                        </Row>
                        <Button type="submit" className='button1'>
                            Get Help
                        </Button>
                    </Form>
                </Container>
            </div>
            {/* section-2 */}
        </>
    )
}

export default RequestSoftware
