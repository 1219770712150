import React , { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';

// reactstrap components
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet";

// core components
import MeterCustomizerNavbar from "components/Navbars/MeterCustomizerNavbar";
import MeterOrientation from "components/Customizer/Haz/HazInline/Haz/MeterOrientation";
import MeterHousing from "components/Customizer/Haz/HazInline/Haz/MeterHousing";
import InputPower from "components/Customizer/Haz/HazInline/Haz/InputPower";
import ProbeSelection from "components/Customizer/Haz/HazInline/Haz/ProbeSelection";
import PipeInformation from "components/Customizer/Haz/HazInline/Haz/PipeInformation";
import ApplicationInformation from "components/Customizer/Haz/HazInline/Haz/ApplicationInformation";
import QuestionsOrComments from "components/Customizer/Haz/HazInline/Haz/QuestionsOrComments";
import MountingRequirements from "components/Customizer/Haz/HazInline/Haz/MountingRequirements";
import FlowBody from "components/Customizer/Haz/HazInline/Haz/FlowBody";
import Enhancements from "components/Customizer/Haz/HazInline/Haz/Enhancements";
import ProcessParameters from "components/Customizer/Haz/HazInline/Haz/ProcessParameters";
import Approvals from "components/Customizer/Haz/HazInline/Haz/Approvals";

import {
  handleChangeMain,
  ShowFooterMain,
  ShowTotalCardMain,
  getKeyByValueMain,
  warrantyPercentageMain,
  checkStateMain
} from "components/Customizer/Views/utils/meterCustomizer";

function HazCustomizer({
  pageType,
  quoteData,
  quoteId,
  productType,
  productInfo={
    product_title: null,
    product_title_old: null
  }
}){
  // const [load, setLoad] = useState(true);
  // useEffect((load) => {
  //   if(load){
  //     window.scrollTo(0, 0);
  //     setLoad(false)
  //   }
  // }, [load]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState(
    {
      step: 1,
      total: {
        pipe_size: 3020,
        gas: 242,
        input_power: 0,
        rating: 100
      },
      model_number: {
        series: 'MP',
        electronics: '86',
        electronics_insertion: '88',
        pipe_length: '36',
        transmitter: 'INT',
        sensor_material: 'sss',
        sensor_shield: '',
        display: '133',
        input_power: 'DC24',
        gas_temp: 'MW050',
        pipe_standard: 'S40',
        probe_width: 'S40',
        probe_length: '06',
        mounting_requirements: 'MT',
        '_comment': 'mounting_requirements fields are in pipe_standard',
        communications_options: '',
        range: '1RC',
        gas: 'Air',
        certifications: 'C',
        approvals: 'T2',
        communications_options_2: 'MB'
      },
      model_number_state: {
        series: false,
        electronics: false,
        electronics_insertion: false,
        pipe_length: false,
        transmitter: false,
        sensor_material: false,
        sensor_shield: false,
        display: false,
        input_power: false,
        gas_temp: false,
        pipe_standard: false,
        probe_width: false,
        probe_length: false,
        mounting_requirements: false,
        '_comment': 'mounting_requirements fields are in pipe_standard',
        communications_options: false,
        range: false,
        gas: false,
        certifications: false,
        approvals: false,
        communications_options_2: false
      },
      meter_type:{
        type: productType
      },
      meter_images: {
        inline: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8600-8700-MP-Inline.png?alt=media&token=f6f34c3c-0aaf-45e6-a84c-54efcf46e38c',
        insertion: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/allProductsImgs%2FSeries-8800-MP-Insertion.png?alt=media&token=dc99f036-ef27-430a-941d-13c38dc1a713'
      },
      warranty: {
        warranty: 0
      },
      approvals_certifications: {
        certifications:{
          csa: true,
          atex: false,
          iecex: false,
          kosha: false,
          none: false
        },
        rating:{
          t2: true,
          t3: false,
          t4: false,
          none: false
        },
        rating_prices:{
          t2: 100,
          t3: 400,
          t4: 800,
          none: 0
        }
      },
      meter_orientation: {
        pipe_orientation: {
          horizion: false,
          vertical: true,
        },
        flow_direction_vertical: {
          top_to_bottom: true,
          bottom_to_top: false,
        },
        flow_direction_horizontal: {
          left_to_right: true,
          right_to_left: false
        },
        enclosure_mounting_preference_vertical: {
          left_side: true,
          right_side: false,
        },
        enclosure_mounting_preference_horizontal: {
          above_pipe: true,
          below_pipe: false
        }
      },
      meter_housing: {
        display:{
          display: true,
          no_display: false
        },
        display_prices:{
          display: 138,
          no_display: 0
        },
        remote_cable: {
          feet: false
        }
      },
      pipe_infornation: {
        type: {
          pipe: true,
          duct: false,
          tube: false
        },
        pipe_standard: {
          ansi: true,
          jis: false,
          din: false
        },
        pipe_size: {
          0.25: true,
          0.375: false,
          0.5: false,
          0.75: false,
          1: false,
          1.25: false,
          1.5: false,
          2: false,
          2.5: false,
          3: false,
          4: false,
          'other': false
        },
        pipe_size_prices: {
          0.25: 3020,
          0.375: 3031,
          0.5: 3042,
          0.75: 3053,
          1: 3069,
          1.25: 3086,
          1.5: 3108,
          2: 3130,
          2.5: 3168,
          3: 4373,
          4: 4818,
          'other': 0
        },
        pipe_size_other: {
          size: false
        },
        pipe_length_other: {
          length: false
        },
        pipe_inner_diameter_other: {
          diameter: false
        },
        schedule:{
          40: true
        },
        inner_diameter:{
          idk: true
        },
        duct_shape:{
          rectangular: true,
          round: false
        },
        duct_rectangular:{
          height: false,
          width: false,
          dimention: false,
          wall: false,
          probe: false
        },
        duct_round:{
          outer: false,
          inner: false,
          units: false,
          wall: false
        },
        tube:{
          outer: false,
          inner: false,
          units: false,
          wall: false
        }
      },
      pipe_infornation_insertion: {
        pipe_height: {
          height: 0
        },
        pipe_width: {
          width: 0
        },
        pipe_units: {
          units: false
        },
        pipe_wall: {
          wall: false
        },
        pipe_size: {
          size: false
        },
        duct_rectangular_height: {
          height: false
        },
        duct_rectangular_width: {
          width: false
        },
        duct_rectangular_units: {
          units: false
        },
        duct_rectangular_wall: {
          wall: false
        },
        duct_rectangular_direction: {
          direction: false
        },
        duct_round_height: {
          height: false
        },
        duct_round_width: {
          width: false
        },
        duct_round_units: {
          units: false
        },
        duct_round_wall: {
          wall: false
        },
        tube_height: {
          height: false
        },
        tube_width: {
          width: false
        },
        tube_units: {
          units: false
        },
        tube_wall: {
          wall: false
        },
        fitting_height: {
          height: 0
        }
      },
      application_information: {
        application:{
          application_type: false
        },
        gas:{
          Air: true,
          Argon: false,
          Chlorine: false,
          Blast_Furnace_Gas: false,
          BioGas: false,
          Landfill_Gas: false,
          Ethylene: false,
          Ethane: false,
          Propane: false,
          Liquid_Propane_Gas: false,
          Butane_Gas: false,
          Benzene: false,
          Methane: false,
          Methane_Helium_Mix: false,
          Methane_Nitrogen_Mix: false,
          Carbon_Monoxide: false,
          Carbon_Dioxide: false,
          Hydrogen: false,
          Hydrogen_Nitrogen_Mix: false,
          Helium: false,
          Mix_Gas: false,
          Flare_gas: false,
          Flue_Gas: false,
          Nitrogen: false,
          Natural_Gas: false,
          Ammonia: false,
          Oxygen: false,
          O3: false,
          SNGPL_Gas: false,
          SSGCL_Gas: false,
          MARI_Gas: false,
          other: false
        },
        gas_prices:{
          Air: 242,
          Argon: 440,
          Chlorine: 440,
          Blast_Furnace_Gas: 693,
          BioGas: 693,
          Landfill_Gas: 693,
          Ethylene: 858,
          Ethane: 858,
          Propane: 473,
          Liquid_Propane_Gas: 474.10,
          Butane_Gas: 474.10,
          Benzene: 660,
          Methane: 275,
          Methane_Helium_Mix: 1177,
          Methane_Nitrogen_Mix: 682,
          Carbon_Monoxide: 341,
          Carbon_Dioxide: 341,
          Hydrogen: 495,
          Hydrogen_Nitrogen_Mix: 715,
          Helium: 825,
          Mix_Gas: 693,
          Flare_gas: 693,
          Flue_Gas: 242,
          Nitrogen: 242,
          Natural_Gas: 275,
          Ammonia: 275,
          Oxygen: 242,
          O3: 242,
          SNGPL_Gas: 275,
          SSGCL_Gas: 275,
          MARI_Gas: 275,
          other: 693
        },
        gas_other: {
          gas: false
        },
        condensing_moisture: {
          yes: false,
          no: true
        },
        application_options: {
          compressed_air: true,
          hvac: false,
          landfill_gas: false,
          natural_gas: false,
          waste_water: false,
          flue_gas: false,
          flare: false,
          other: false
        },
        application_options_other: {
          application: false
        }
      },
      questions_or_comments: {
        input: {
          info: false
        }
      },
      flow_body:{
        sensors:{
          cer: false,
          sss: true,
          has: false
        },
        sensors_prices:{
          cer: 0,
          sss: 0,
          has: 1320
        },
        sensors_shield:{
          standard: true,
          rod: false,
          sheild: false
        },
        sensors_shield_prices:{
          standard: 0,
          rod: 275,
          sheild: 275
        }
      },
      enhancements:{
        certificates_origin:{
          orgin: false,
          none: true
        },
        certificates_origin_prices:{
          orgin: 125,
          none: 0
        },
        certificates_oxygen:{
          oxygen: false,
          none: true,
        },
        certificates_oxygen_prices:{
          oxygen: 395,
          none: 0,
        },
        certificates_leak:{
          leak: false,
          none: true
        },
        certificates_leak_prices:{
          leak: 125,
          none: 0
        },
        certificates_welding:{
          welding: false,
          none: true
        },
        certificates_welding_prices:{
          welding: 750,
          none: 0
        },
        calibration_ctp:{
          ctp: false,
          none: true
        },
        calibration_ctp_prices:{
          ctp: 225,
          none: 0
        },
        calibration_left_curve:{
          left_curve: false,
          none: true
        },
        calibration_left_curve_prices:{
          left_curve: 225,
          none: 0
        },
        test_pmi:{
          pmi: false,
          none: true
        },
        test_pmi_prices:{
          pmi: 250,
          none: 0
        },
        test_liquid:{
          liquid: false,
          none: true,
        },
        test_liquid_prices:{
          liquid: 250,
          none: 0,
        },
        test_welds:{
          welds: false,
          none: true
        },
        test_welds_prices:{
          welds: 250,
          none: 0
        },
        test_wetted:{
          wetted: false,
          none: true
        },
        warranty:{
          1:false,
          2: false,
          3: false,
          none: true
        },
        warranty_prices:{
          none: 0,
          1: 10,
          2: 20,
          3: 30
        },
        stainless:{
          stainless: false,
          none: true
        },
        stainless_prices:{
          stainless: 45,
          none: 0
        },
        electropolish:{
          none: true,
          sensor: false,
          section: false,
          section_sensor: false
        },
        electropolish_prices:{
          none: 0,
          sensor: 260,
          section: 260,
          section_sensor: 460
        }
      },
      process_parameters: {
        flow_rate_mininum:{
          minimum: false
        },
        flow_rate_maximum:{
          maximum: false
        },
        flow_rate_optimal:{
          optimal: false
        },
        flow_rate_units:{
          units: false
        },
        gas_mininum:{
          minimum: false
        },
        gas_maximum:{
          maximum: false
        },
        gas_optimal:{
          optimal: false
        },
        gas_units:{
          units: 'C'
        },
        ambient_mininum:{
          minimum: false
        },
        ambient_maximum:{
          maximum: false
        },
        ambient_optimal:{
          optimal: false
        },
        ambient_units:{
          units: false
        },
        pressure_mininum:{
          minimum: false
        },
        pressure_maximum:{
          maximum: false
        },
        pressure_optimal:{
          optimal: false
        },
        pressure_units:{
          units: false
        },
        reference_conditions: {
          temperature_70: true,
          temperature_0: false,
          other: false
        },
        other_temperature: {
          temperature_21: false,
          temperature_20: false,
          temperature_0_Hg: false,
          temperature_20kPa: false,
          temperature_0ׄ_1_Bar: false,
          temperature_60_ATM:false,
          temperature_60_Hg:false
        }
      },
      input_power_communication: {
        input_power: {
          vdc_12_18: false,
          vdc_18_24: true,
          vac_105_120: false,
          vac_210_240: false
        },
        input_power_prices: {
          vdc_12_18: 0,
          vdc_18_24: 0,
          vac_105_120: 204,
          vac_210_240: 204
        },
        communications_options: {
          standard: true,
          multi_range_temp_out: false,
          multi_range_no_temp: false,
          hart: false,
          bacnet: false,
          profibus: false
        },
        standard_ma_flow:{
          self: true,
          isolated: false
        },
        standard_vdc_flow:{
          5: true,
          10: false
        },
        standard_vdc_temp:{
          5: true,
          10: false
        },
        multi_ma_flow:{
          self: true,
          isolated: false
        },
        multi_vdc_flow:{
          5: true,
          10: false
        },
        multi_vdc_temp:{
          5: true,
          10: false
        },
        hart_ma_flow:{
          self: true,
          isolated: false
        },
        hart_vdc_flow:{
          5: true,
          10: false
        },
        hart_vdc_temp:{
          5: true,
          10: false
        },
        bacnet_ma_flow:{
          self: true,
          isolated: false
        },
        bacnet_vdc_flow:{
          5: true,
          10: false
        },
        bacnet_vdc_temp:{
          5: true,
          10: false
        },
        profibus_ma_flow:{
          self: true,
          isolated: false
        },
        profibus_vdc_flow:{
          5: true,
          10: false
        },
        profibus_vdc_temp:{
          5: true,
          10: false
        }
      },
      mounting_requirements:{
        mounting_types:{
          mnpt: true,
          butt: false,
          flange: false
        },
        flange_type: {
          150: true,
          300: false,
          'other': false
        },
        flange_type_other: {
          size: false
        },
        flange_size: {
          size: false
        }
      },
      probe_section: {
        ball_val_retractor:{
          bvr: false,
          none: true,
        },
        probe_width: {
          width: false
        },
        probe_width_prices: {
          '1/2': 100,
          '3/4': 200,
          1: 300
        },
        probe_length: {
          length: false
        }
      },
      none: {
        none:{
          none: false,
        }
      }
    }
  );

  const [isViewQuote, setIsViewQuote] = useState(false);
  useEffect(() => {
    if(pageType || pageType === 'viewQuote'){
      if(quoteData.state){
        setData(quoteData.state);
      }
      setIsViewQuote(true);
    }
  }, [pageType, quoteData]);

  const [pipeInfoPipeSize, setPipeInfoPipeSize] = useState(0.25);
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const handleChange = (props) => { handleChangeMain(props, data, setData) }
  const getKeyByValue = (object, value) => { return getKeyByValueMain(object, value) }
  const checkState = (checkData, position) => { return checkStateMain(checkData, position) }
  const warrantyPercentage = (total, warranty) => { return warrantyPercentageMain(total, warranty) }
  const ShowFooter = (props) => { return ShowFooterMain(props, isMobile) }
  const ShowTotalCard = (total) => { return ShowTotalCardMain(total, isMobile, isViewQuote, data, quoteId, quoteData, productInfo) }
  const total = warrantyPercentage(data);

  const { step, pipe_orientation } = data;
  const values = { step, total, pipe_orientation }

  return (
    <>
      <div className={ !isMobile? ' section section-about-us CustomizerPage' : ' section section-about-us CustomizerPageMobile'}>

        {data.meter_type.type === "inline" &&
          <Helmet>
              <meta charSet="utf-8" />
              <title>EPI – Series 8600-8700MP HAZ Inline Flow Meter</title>
              <meta name="description" content="EPI’s Series 8600-8700MP Inline Flow Meter provides you with incredible reliability, precision, and flexibility. Check out its tech specs! " />
              <link rel="canonical" href="https://epiflow.com/products/haz/inline/haz" />
          </Helmet>
        }

        {data.meter_type.type === "insertion" &&
          <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Series 8800MP HAZ Insertion Flow Meter</title>
            <meta name="description" content="EPI’s Series 8800MP HAZ Insertion Flow Meter monitors and measures gas flow with high-precision and accuracy. Check out its tech specs!" />
            <link rel="canonical" href="https://epiflow.com/products/haz/insertion/haz" />
          </Helmet>
        }


        {!isViewQuote && <MeterCustomizerNavbar meterType={ data.meter_type.type } />}

        {isViewQuote?
          (
            <div className='common-main' style={{ 'margin-bottom': '0px'}}>
              <Row>
                  <Col md="6" sm="12">
                      <h3 className='commontitleOne mt-3 vq-ctone'>
                        <FontAwesomeIcon icon={faEdit} className='quoute-2d-element-icon' style={{ 'color': '#4a4a6b' }}></FontAwesomeIcon>
                        #{ quoteData.quoteId } ({quoteData.series})
                      </h3>
                  </Col>
              </Row>
            </div>
          )
        :
          (
          <>
            <h3 className="title text-center mb-2">Meter Customizer</h3>
            <p className="commonParaThree text-center betaText">
          Our meter customizer is a new feature that allows you to tailor an EPI meter to your specific use case. Please keep in mind that it is still in beta, and we are constantly working to improve it.
          </p>
          </>)
        }

      {/* {!isViewQuote && <p className="text-center">Description text</p>} */}
        <Col className={isViewQuote? "ml-auto mr-auto" : "ml-auto mr-auto col-md-9" } style={{ 'top': '200', 'left': '200'}}>
          <Row>
            <Col className="ml-auto mr-auto col-12 col-lg-9 order-3 order-lg-1">
              <div className="anchor" id="Approvals"></div>
              <Approvals
                handleChange={ handleChange }
                state={ data }
                checkState={ checkState }
              />

              <div className="anchor" id="orientation"></div>
              <MeterOrientation
                handleChange={ handleChange }
                state={ data }
                checkState={ checkState }
                getKeyByValue={ getKeyByValue }
              />

              <div className="anchor" id="meterHousing"></div>
              <MeterHousing
                handleChange={ handleChange }
                state={ data }
                checkState={ checkState }
              />

              <div className="anchor" id="probeSelection"></div>
              {data.meter_type.type === "insertion" &&
                <ProbeSelection
                  handleChange={ handleChange }
                  state={ data }
                  checkState={ checkState }
                  getKeyByValue={ getKeyByValue }
                />
              }

              <div className="anchor" id="pipeInformation"></div>
              <PipeInformation
                handleChange={ handleChange }
                setPipeInfoPipeSize={ setPipeInfoPipeSize }
                state={ data }
                checkState={ checkState }
                getKeyByValue={ getKeyByValue }
                meterType={ data.meter_type.type }
              />

              <div className="anchor" id="applicationInformation"></div>
              <ApplicationInformation
                handleChange={ handleChange }
                state={ data }
                checkState={ checkState }
              />

              <div className="anchor" id="inputPower"></div>
              <InputPower
                handleChange={ handleChange }
                state={ data }
                checkState={ checkState }
                getKeyByValue={ getKeyByValue }
              />

              <div className="anchor" id="mountingRequirements"></div>
              <MountingRequirements
                handleChange={ handleChange }
                pipeInfoPipeSize={ pipeInfoPipeSize }
                state={ data }
                checkState={ checkState }
                meterType={ data.meter_type.type }
              />

              <div className="anchor" id="processParameters"></div>
              <ProcessParameters
                handleChange={ handleChange }
                state={ data }
                customizerData={data}
                checkState={ checkState }
              />

              <div className="anchor" id="flowBody"></div>
              <FlowBody
                handleChange={ handleChange }
                state={ data }
                checkState={ checkState }
              />

              <div className="anchor" id="enhancements"></div>
              <Enhancements
                handleChange={ handleChange }
                state={ data }
                checkState={ checkState }
              />

              <div className="anchor" id="questionsOrComments"></div>
              <QuestionsOrComments
                handleChange={ handleChange }
                state={ data }
                checkState={ checkState }
              />
            </Col>

            <Col className="ml-auto mr-auto col-12 col-lg-3 order-2">
              <ShowTotalCard value={total} />
            </Col>
          </Row>
          <div className="separator separator-primary"></div>
        </Col>
        <ShowFooter value={values} />
      </div>
    </>
  );
}

export default HazCustomizer;
