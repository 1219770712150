import React from 'react'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


function FaqAccordation() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header" >
                    <Typography>What industries make use of EPI Flow Meters?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        EPI Thermal Mass Flow Meters are utilized in light and heavy industrial plants, process heating facilities, upstream/midstream/downstream oil and gas activities, and wastewater operations. Gas flow rates are measured by EPI meters in mass (Kg/Hr, Lb/Hr) or conventional volumetric units (MSCFD, SCFM). EPI meters can measure almost any gas flow in a broad array of applications. For example, nearly every major facility now has one or more combustion operations, such as a boiler, process heater, or flare. Gas measurement in these processes can be utilized to enhance combustion efficiency, utility accounting, and regulator monitoring.
                        <br /><br />

                        Monitoring manufacturing compressed air lines for losses is another widely used application for EPI meters. Defects in compressed air systems are a regular issue. The magnitude of losses may cost businesses hundreds of thousands of dollars per year. At the time of installation, each compressor should have an EPI flow meter fitted. The plant manager may then create a baseline air use for a certain activity level. Any variation from this baseline, even if there is no matching change in workload, indicates a system leak.
                        <br /><br />

                        Emissions monitoring, process heating, vent gases, flare gases, digester gases, aeration in wastewater treatment facilities, laboratory gas metering, and biogas fluxes are some of the other EPI uses. <a href="technicalinfo/industrialapplications">Learn more</a> about how an EPI flow meter can assist you with your operation.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>Natural gas contains which gases?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        EPI utilizes the NAESB average for all Natural Gas compositions, which include methane, ethane, propane, nitrogen, and carbon dioxide.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography>What gases are measured using EPI flow meters?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        EPI meters can measure a wide variety of pure gases and gas mixes. These are some examples:
                        <ul>
                            <li>Air</li>
                            <li>Argon</li>
                            <li>Biogas</li>
                            <li>Butane</li>
                            <li>Carbon Dioxide</li>
                            <li>Compressed Air</li>
                            <li>Digester Gas</li>
                            <li>Flare Gas</li>
                            <li>Flue Gas</li>
                            <li>Helium</li>
                            <li>Methane</li>
                            <li>Mixed Gases</li>
                            <li>Natural Gas</li>
                            <li>Nitrogen</li>
                            <li>Oxygen</li>
                            <li>Propane</li>
                            <li>Pure Gases</li>
                            <li>Sweep Gas</li>
                            <li>Vent Gas</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>How can I choose which meter is best for me?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        EPI meters are classified into two types: insertion and inline designs. Insertion is suitable for most applications; however, if the pipe diameter is less than 2 inches (50.8 mm), an inline version should be used. For insertion and inline meters that do not include flow conditioning filters, 15 pipe diameters upstream and ten diameters downstream are suggested for accurate measurements. Our FAT (Flow Averaging Tube) meters and standard inline flow meters equipped with flow conditioning filters need less upstream and downstream straight run (five diameters upstream and five diameters downstream).
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>How precise are the meters?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        All calibrations are conducted using NIST-traceable flow standards to verify that all EPI meters satisfy stated performance specifications and give accurate, repeatable measurements. In addition, when it is safe to do so, our calibration department mimics your process line conditions by utilizing the same gas under similar circumstances.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>In what situations may the meter be used safely?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Every EPI flow meter is at the very least CE certified. CSA/CUS, ATEX, IEC, IECEx, and KOSHA have all authorized our HAZ Series flow meters for hazardous locations. CSA/CUS has authorized our GEN Series meters for Ordinary Locations. See our <a href="technicalinfo/approvalscertificates">Agency Approvals & Certificates</a> page for a complete list and more information on these certifications. Our other meters offer a variety of approved upgrade choices that may be found in the meter customizer.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>What materials are used to make the probes?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        The typical probe is constructed of 316 stainless steel. Hastelloy C-276 is the recommended material for severely corrosive situations, and it may be specified in the meter configurator.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>What is the highest flow rate that your sensors can correctly measure?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        The HAZ & GEN Series can accurately measure up to 60,000 SFPM with a standard sensor. With an attenuation rod or particle shield, it can go up to 80,000 SFPM. Our FAT can accurately measure up to 250,000 SFPM, and the VAL Series can read up to 30,000 SFPM.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>What is the lowest flow rate that your sensors can correctly measure?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        The minimum rate for FAT is 15 SFPM and 10 SFPM for Non-FAT.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>What sizes of pipes can EPI meters measure flow?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        The smallest EPI inline meter is one quarter inch (6.5mm) and can read as low as 0.008 SCFM. For detecting exhaust gases in big ducts, special length probes are offered.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>What are the benefits of using remote control?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        The sensor is separated from the meter electronics by a remote unit. One benefit is that the electronics enclosure may be situated in such a way that the display and settings buttons are simple to use. Furthermore, the probe junction box may be installed in an environment with an ambient temperature ranging from -40 to 212°F (-40 to 100°C). Almost all of our meters, including the HAZ, GEN, XT, and MultiGEN, have remote options.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>Can EPI meters be used to monitor liquid or steam flow?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        No, EPI meters can measure all sorts of gases, but a thermal mass flow meter cannot detect liquid or steam flows. The gas can be "wet" as long as it does not condensate. Droplets developing on the sensor element will cause it to read incorrectly and may prompt the meter to indicate an error message.<br />

                        If this is a possibility for your use, EPI recommends mounting the meter at an angle to reduce the possibility of moisture developing on the tip. Gravity causes any water to travel downwards, away from the sensor RTDs.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>Is the meter capable of detecting pulsations?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Yes, with a reaction time of 1 second (up to 63 percent of the final value), EPI meters can respond swiftly to variations in the gas flow.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>How frequently should calibration be performed?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        The EPA specifies that calibration for Thermal Mass Flow Meters must be completed to manufacturer specifications for monitoring emissions for EPA regulatory requirements.
                        <br />
                        It is advised that the EPI Validation (EPIVal) test be done on a yearly basis to assess if calibration is required.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>What is EPIVal?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        The EPI Validation (EPIVal) feature validates your factory calibration through a two-minute test. The test allows operators to check the meter's calibration and accuracy in the field, eliminating the need to ship the meter back to the factory for yearly calibrations. In addition, the test enables managers to evaluate factory or fieldset milliwatt levels to a tolerance of 2% to 5%. EPIVal testing can be done as frequently as necessary to meet local, regional, or national emissions reporting standards. Furthermore, a test log is saved in the EPIVal command submenu.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default FaqAccordation
