import React, { useEffect, useState, useLayoutEffect } from "react";
import axios from "axios";
import useSwr from 'swr';
import { useHistory } from "react-router-dom";
import StickyBox from "react-sticky-box";
import fetcher from 'utils/fetcher';
import ShoppingCart from "./ShoppingCart";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText
} from "reactstrap";

function UpdateCard({total, state, quoteId, series, model}){
  const history = useHistory();
  const [userData, setUserData] = useState([]);
  const [viewPrice, setViewPrice] = useState(false);
  const [cartItemsIds, setCartItemsIds] = useState(false);
  const [cart_location, setLocation] = useState(false);
  const [configData, setConfigData] = useState(false);
  const { data:userData_ } = useSwr("/api/me", fetcher);

  var { data: configData_ } = useSwr(`/api/quotes/single/${quoteId}`, fetcher);

  useEffect(() => {
    if(userData_){
      setUserData(userData_)
    }
  }, [userData_]);

  useEffect(() => {
    if(userData.account_type === 'admin' || userData.account_type === 'regularPlusPricing'){
      setViewPrice(true);
    }
  }, [userData]);

  useEffect(() => {
    if(configData_){
      setConfigData(configData_);
    }
  }, [configData_]);

  useLayoutEffect(() => {
    const sessionData = ShoppingCart({'task':'read'});
    setCartItemsIds(sessionData.ci);

    var location = 0;
    sessionData.ci?.forEach(location_id => {
      if(location_id === quoteId){
        setLocation(location);
        return;
      }
      location++;
    });
  }, []);
  
  console.log('Config data single 1.0 =>', configData);

  const numberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  let total_ = numberWithCommas(total);

  const updateCartData = (configuration) => {
    ShoppingCart({
      'task':'updateSingleState',
      'data': {
        cart_location: cart_location,
        state: configuration.state,
        total: configuration.total
      }
    });
  }

  const createNewQuoteAndUpdateCartData = async (configuration) => {
    const meterCustomizedData = {
      series: configuration.series,
      model: configuration.model,
      state: configuration.state,
      total: configuration.total,
      config_type: 'incart'
    }

    console.log('Show me new save 2', meterCustomizedData, configuration);

    try {
      const savedConfig = await axios.post(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/quotes`,
        meterCustomizedData,
        { withCredentials: true }
      );

      savedConfig.data.quantity = 1;
      ShoppingCart({'task':'read'});
      ShoppingCart({
        'task':'updateSingleConfig',
        'data': {
          cart_location: cart_location,
          meter_config: savedConfig.data,
          _id: savedConfig.data?._id
        }
      });

      history.push('/portal/cart')
    } catch (e) {
      console.log('Show me db insert error', e.message);
    }
  }

  const saveQuote = async () => {
    const meterCustomizedData = {
      series: series,
      model: model,
      total: total_,
      state: state,
      config_type: configData?.config_type
    }

    console.log('Show me new save 1', meterCustomizedData);

    try {
      const updatedConfig = await axios.put(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/quotes/${quoteId}`,
        meterCustomizedData,
        { withCredentials: true }
      );

      if(configData?.config_type === 'saved'){
        createNewQuoteAndUpdateCartData(updatedConfig.data);
      } else {

        updatedConfig.data.quantity = 1;
        ShoppingCart({'task':'read'});
        ShoppingCart({
          'task':'updateSingleConfig',
          'data': {
            cart_location: cart_location,
            meter_config: updatedConfig.data,
            _id: quoteId
          }
        });
      }

      history.push(configData?.config_type === 'incart'? `/portal/cart` : `/portal/quotes/${quoteId}`);
    } catch (e) {
      console.log('Show me db insert error', e.message);
    }
  }

  console.log('Show me isCart 1.0 =>', configData?.config_type, cart_location);


  return (
    <>
      <StickyBox offsetTop={60} offsetBottom={60}>
        <Card className="text-center" style={{ width: "15rem" }}>
          <CardBody>
            <CardTitle tag="h5">Total Cost</CardTitle>
            <CardTitle tag="h3" style={{ "color": "#0A60FF" }}>
              <strong>
                { viewPrice? `$${total_}` : 'Account not approved to view pricing' }
              </strong>
            </CardTitle>
            <CardText>
              The price listed is for the meter with the current configuration.
            </CardText>
            { configData?.config_type === 'saved' && typeof cart_location === 'boolean' &&
              <Button
                className="saveQuoteButtonZoom"
                color="white"
                size="lg"
                outline
                onClick={(e) => saveQuote()}
              >
                Update
              </Button>
            }

            { configData?.config_type === 'saved' && typeof cart_location === 'number' ?
              <>
                <Button
                  className="saveQuoteButtonZoom"
                  color="white"
                  size="lg"
                  outline
                  onClick={(e) => saveQuote()}
                >
                  Save Edits
                </Button>
                
                <Button
                  className="saveQuoteButtonZoom"
                  color="white"
                  size="lg"
                  outline
                  onClick={(e) => history.push(`/portal/cart`)}
                >
                  Discard Edits
                </Button>
              </> :
              <></>
            }

            { configData?.config_type === 'incart' &&
              <>
                <Button
                  className="saveQuoteButtonZoom"
                  color="white"
                  size="lg"
                  outline
                  onClick={(e) => saveQuote()}
                >
                  Save Edits
                </Button>
                
                <Button
                  className="saveQuoteButtonZoom"
                  color="white"
                  size="lg"
                  outline
                  onClick={(e) => history.push(`/portal/cart`)}
                >
                  Discard Edits
                </Button>
              </>
            }
          </CardBody>
        </Card>
      </StickyBox>
    </>
  );
}

export default UpdateCard;
