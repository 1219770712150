import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import './Timeline.css'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import img1 from '../../../assets/img/images/1990-multipoint-img.png'
import img2 from '../../../assets/img/images/1989-thermal-img.png'

function Timeline() {
    return (
        <>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    date={<FontAwesomeIcon icon={faCircle} className='right-icon' />}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>2017</span></div>}
                >

                    <h3 className="timeline-title">2017</h3>
                    <p className='timeline-body'>
                        Introduced the Epi-Val™ with LiveZero™ In-Situ or Field Validation Field-Val™
                        firmware with EPICom interface for Master-Touch™ & ValuMass™
                        Flow Meters; BACnet communication protocol interface soon.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<FontAwesomeIcon icon={faCircle} className='left-icon' />}
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>2014</span></div>}
                >
                    <h3 className="timeline-title">2014</h3>
                    <p className='timeline-body'>
                        Moved to new 15,000 square foot facility with greatly expanded calibration laboratory.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    date={<FontAwesomeIcon icon={faCircle} className='right-icon' />}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>2013</span></div>}
                >

                    <h3 className="timeline-title">2013</h3>
                    <p className='timeline-body'>
                        Installed & made operable new high flow closed-loop flow calibration system.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<FontAwesomeIcon icon={faCircle} className='left-icon' />}
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>2012</span></div>}
                >
                    <h3 className="timeline-title">2012</h3>
                    <p className='timeline-body'>
                        Engineered, purchased, & built new high flow closed-loop flow calibration system.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    date={<FontAwesomeIcon icon={faCircle} className='right-icon' />}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>2011</span></div>}
                >

                    <h3 className="timeline-title">2011</h3>
                    <p className='timeline-body'>
                        Received Hazardous Location IECEx certification for Master-Touch™ MP Series Flow Meters.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<FontAwesomeIcon icon={faCircle} className='left-icon' />}
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>2009</span></div>}
                >
                    <h3 className="timeline-title">2009</h3>
                    <p className='timeline-body'>
                        Introduced Profibus DP communication protocol interface for Master- Touch™ Flow Meters.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    date={<FontAwesomeIcon icon={faCircle} className='right-icon' />}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>2008</span></div>}
                >

                    <h3 className="timeline-title">2008</h3>
                    <p className='timeline-body'>
                        Introduced our Master-Touch™ Flow Meters with Digital Sensor Interface (DSI) sensor drive with wide temperature compensation curve fit.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<FontAwesomeIcon icon={faCircle} className='left-icon' />}
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>2007</span></div>}
                >
                    <h3 className="timeline-title">2007</h3>
                    <p className='timeline-body'>
                        Established the ValuMass™ Series 500 & 400 line of low cost Flow Meters with Digital Sensor Interface (DSI);
                        RFI/EMI certification update of Master-Touch™ & ValuMass™ Flow Meters.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    date={<FontAwesomeIcon icon={faCircle} className='right-icon' />}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>2006</span></div>}
                >

                    <h3 className="timeline-title">2006</h3>
                    <p className='timeline-body'>
                        Introduced HART communication protocol interface; E-Logger™ Flow Meter internal data logging into an E-Log™ file,
                        accessible through EPI’s proprietary EPICom™ Flow Meter communication software; Introduced RS485 Modbus RTU communication protocol
                        interface Flow Meters now include 0-5VDC, 4-20mA, RS232, Hi & Low Relays.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<FontAwesomeIcon icon={faCircle} className='left-icon' />}
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>2005</span></div>}
                >
                    <h3 className="timeline-title">2005</h3>
                    <p className='timeline-body'>
                        Received ATEX (KEMA) certification for Master-Touch™ MP Series Flow Meters;
                        Introduced RS485 Modbus RTU communication protocol interface, LightWire™ Infrared Flow Meter communication.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    date={<FontAwesomeIcon icon={faCircle} className='right-icon' />}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>2004</span></div>}
                >

                    <h3 className="timeline-title">2004</h3>
                    <p className='timeline-body'>
                        Patented the Thermal Mass Flow Meter wide temperature correction method;
                        Received CSA/CUS certifications for Master-Touch™ MP (Hazardous Location) &
                        MPNH (Ordinary Location), Series Flow Meters.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<FontAwesomeIcon icon={faCircle} className='left-icon' />}
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>2003</span></div>}
                >
                    <h3 className="timeline-title">2003</h3>
                    <p className='timeline-body'>
                        Patented the thermal Flow Averaging Tubes (FAT™);
                        Introduced first all microcontroller operated Flow Sensor design;
                        Introduced self cleaning Sensor Air Purge through Master-Touch™ Insertion Flow Meter Probes.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    date={<FontAwesomeIcon icon={faCircle} className='right-icon' />}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>2002</span></div>}
                >

                    <h3 className="timeline-title">2002</h3>
                    <p className='timeline-body'>
                        Field tests first hybrid analog/digital microcontroller operated Flow Sensor;
                        Introduced self cleaning Sensor Air Purge System on Multi-Point Stack Probes.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<FontAwesomeIcon icon={faCircle} className='left-icon' />}
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>2001</span></div>}
                >
                    <h3 className="timeline-title">2001</h3>
                    <p className='timeline-body'>
                        Invented 1st hybrid analog/digital microcontroller operated Flow Sensor.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    date={<FontAwesomeIcon icon={faCircle} className='right-icon' />}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>2000</span></div>}
                >

                    <h3 className="timeline-title">2000</h3>
                    <p className='timeline-body'>
                        Introduced the Non-Hazardous location Master-Touch™ Series 8000MPNH & 9000MPNH Flow Meter product lines.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<FontAwesomeIcon icon={faCircle} className='left-icon' />}
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>1999</span></div>}
                >
                    <h3 className="timeline-title">1999</h3>
                    <p className='timeline-body'>
                        Introduced high pressure, screw-drive probe retractor assembly; Introduced Bi-Directional Flow Meter.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    date={<FontAwesomeIcon icon={faCircle} className='right-icon' />}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>1998</span></div>}
                >

                    <h3 className="timeline-title">1998</h3>
                    <p className='timeline-body'>
                        Received China Pattern Approval (CPA) for Master-Touch™ Flow Meters; Introduced EPICommunicator™ or EPICom™
                        communication software for Master- Touch™ Flow Meters; Introduced Master-Touch™ UHP High Purity Flow Meter.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<FontAwesomeIcon icon={faCircle} className='left-icon' />}
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>1997</span></div>}
                >
                    <h3 className="timeline-title">1997</h3>
                    <p className='timeline-body'>
                        Received Hazardous Location CENELEC (LCIE) Ex certification for the Series 8000, 7000, 6000;
                        EPI flow laboratory installed two gas provers.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    date={<FontAwesomeIcon icon={faCircle} className='right-icon' />}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>1996</span></div>}
                >

                    <h3 className="timeline-title">1996</h3>
                    <p className='timeline-body'>
                        Introduced the Master-Touch™ Series of fully microprocessor-based flowmeters;
                        receives Hazardous Location CSA certification for the Series 8000, 7000, 6000;
                        Standard outputs include 0-5VDC, 4-20mA, RS232, Hi & Low Relays; Moved to 9000 square foot manufacturing facility;
                        Introduced Master-Touch™ Series 8000MP & 9000MP product line; RFI/EMI certification of Master-Touch™
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<FontAwesomeIcon icon={faCircle} className='left-icon' />}
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>1995</span></div>}
                >
                    <h3 className="timeline-title">1995</h3>
                    <p className='timeline-body'>
                        Developed 900°F (482 ᵒC) high temperature stack insertion Flow Meter;
                        RFI/EMI certification of Series 8000, 7000, 6000 Flow Meters.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    date={<FontAwesomeIcon icon={faCircle} className='right-icon' />}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>1994</span></div>}
                >

                    <h3 className="timeline-title">1994</h3>
                    <p className='timeline-body'>
                        Established Asia and Pacific Rim Distributors.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<FontAwesomeIcon icon={faCircle} className='left-icon' />}
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>1993</span></div>}
                >
                    <h3 className="timeline-title">1993</h3>
                    <p className='timeline-body'>
                        Developed 400 ᵒF (204 ᵒC) low flow axial sensor Flow Meter;
                        Introduced the Non-Hazardous location Series 8000NH Flow Meter,
                        7000NH Flow Switch , 6000NH Level Switch product lines.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    date={<FontAwesomeIcon icon={faCircle} className='right-icon' />}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>1992</span></div>}
                >

                    <h3 className="timeline-title">1992</h3>
                    <p className='timeline-body'>
                        Established Western European Distributors; Developed high velocity Particulate Shield PS1 Flow Meter;
                        Introduced Ordinary Location (NH) Flow Meter product line; EPI lab installed 1000°F Air Test Stand.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<FontAwesomeIcon icon={faCircle} className='left-icon' />}
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>1991</span></div>}
                >
                    <h3 className="timeline-title">1991</h3>
                    <p className='timeline-body'>
                        Developed Iso-kinetic stack velocity & sampling probe systems;
                        Developed dual sting metal clad sensors; Developed control valve & controller;
                        Received FM certification for Series 8000, 7000, 6000 Flow Meters.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    date={<FontAwesomeIcon icon={faCircle} className='right-icon' />}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>1990</span></div>}
                >
                    <div className='text-center'>
                        <img src={img1} alt="Series 9000 Multipoint Flow Meter" />
                    </div>

                    <h3 className="timeline-title">1990</h3>
                    <p className='timeline-body'>
                        Introduces the Series 9000 Multipoint averaging stack probes; Established an in-house flow calibration lab.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<FontAwesomeIcon icon={faCircle} className='left-icon' />}
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>1989</span></div>}
                >
                    <div className='text-center'>
                        <img src={img2} alt="Series 7000 Thermal Mass Flow Switch" style={{ height: '250px' }} />
                    </div>
                    <h3 className="timeline-title">1989</h3>
                    <p className='timeline-body'>
                        Introduced the Series 7000 Thermal Mass Flow Switch and Series 6000 Thermal Level Switch.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#EBF2FF', color: '#4E5976' }}
                    contentArrowStyle={{ borderRight: '30px solid  #EBF2FF' }}
                    date={<FontAwesomeIcon icon={faCircle} className='right-icon' />}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<div className='timeline-date'><span>1988</span></div>}
                >

                    <h3 className="timeline-title">1988</h3>
                    <p className='timeline-body'>
                        Eldridge Products, Inc. founded by Mark Eldridge and became a thermal mass flow meter manufacturer,
                        introducing the hazardous area class of Series 8000 Inline and Insertion Thermal Mass Flow Meters;
                        introduces low pressure ball valve retractor assemblies.
                    </p>
                </VerticalTimelineElement>
            </VerticalTimeline>
            {/* --------------------------------------- */}
        </>
    )
}

export default Timeline
