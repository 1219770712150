import React, {useEffect} from 'react'
import './AboutUs.css';
// import Timeline from './Timeline/Timeline';
import Timeline from './Timeline/Timeline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import circleimg from '../../assets/img/images/circle-img.png'
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";


function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (

        <div className='aboutus-main'>
          <Helmet>
              <meta charSet="utf-8" />
              <title>EPI – About Us </title>
              <meta name="description" content="Founded in 1988 with 30 years of high-level experience, EPI (Eldridge Products Inc) produces state-of-the-art thermal mass flow meters. " />
              <link rel="canonical" href="https://epiflow.com/aboutus" />
          </Helmet>
            {/* section-1 */}
            <div className='commonbanner about-section-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/" tag={Link}>Home</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/aboutus" tag={Link}>About Us</NavLink></span>
                  </div>

                    <div className='text-center'>
                        <h2 className='cover-title-h2'>About Us</h2><br />
                        <h3 className='cover-title-p'>30 years of thermal mass flow meter excellence</h3>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <div className='section-2'>
                <div className='text-center container'>
                    <h1>EPI Company Biography</h1>
                    <p>About Eldridge Products Inc.</p>
                    <div>
                        <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FAbout%2Fabout-epi-banner.png?alt=media&token=f866adfe-22bb-4dd5-8efa-b739271467e2" className='about-s2-img' alt="About EPI Banner" />
                    </div>
                    <p className='EPI-Company-Biography-p'>
                        Eldridge Products Inc. was founded in June 1988 in Marina, California, by President and CEO Mark Eldridge. Today, with over 30 years of
                        experience in the industry, EPI is the world’s premier manufacturer of thermal mass flow meters, switches, and metering software. With an ever-growing team of the industry’s best engineers, designers, and support specialists, EPI offers
                        clients the reliable and accurate products they’d expect from a global leader coupled with the personal attention and dedicated service they’d
                        expect from a world class business.
                        <br /><br />

                        At EPI, we understand the challenges our customers face every day when measuring thermal mass flows of all types. We also understand the
                        opportunities that accurate measurement represents to both the bottom line and to the environment. With those challenges and opportunities
                        always at the top of our minds, we’ve spent decades developing a full line of thermal mass flow metering products that are unparalleled in their
                        accuracy, their ease of use, and their reliability. EPI thermal mass flow meters take the headache and complexity out of repeatable, accurate flow
                        measurement and eliminate the need to worry about expensive system downtime.
                        <br /><br />

                        Equally as important to us as the quality of our products is the quality of our people and the relationships we build with our customers – customers
                        who know that, with EPI, they aren’t just buying hardware or software; they’re gaining a true partner. At the core of everything we do is the
                        understanding that our customers’ success and our own are inextricably linked, and it’s that knowledge that drives our unwavering commitment to
                        offering the best thermal mass flow meters and the best service the industry has to offer.
                    </p>
                </div>
            </div>
            {/* section-2 */}
            {/* section-3 */}
            <div className='section-3 '>
                <div className='container'>
                    <div className='text-center'>
                        <h1 className=''>Our Team</h1>
                        <p>The people who keep EPI up and running.</p>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='text-center'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FTeamPhotos%2FRyan-Eldridge.png?alt=media&token=416c9fb9-53c8-4b95-97c3-486b2d6da560" className='team-img' alt="EPI Team Photo - Ryan Eldridge" />
                            </div>
                            <h4 className='team-name'>Ryan Eldridge</h4>
                            <p className='team-title'>General Manager</p>
                        </div>
                        {/* <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='text-center'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FTeamPhotos%2FDavid-Eldridge.png?alt=media&token=78b34446-096f-41d3-a27a-da82f942c7b3" className='team-img' alt="EPI Team Photo - David Eldridge" />
                            </div>
                            <h4 className='team-name'>David Eldridge</h4>
                            <p className='team-title'>Quality Manager</p>
                        </div> */}
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='text-center'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FTeamPhotos%2FRajesh-Gir.png?alt=media&token=f76c8582-7c65-4a87-bcdc-ff619ec08e93" className='team-img' alt="EPI Team Photo - Rajesh Gir" />
                            </div>
                            <h4 className='team-name'>Rajesh Gir</h4>
                            <p className='team-title'>Production Manager</p>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='text-center'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FTeamPhotos%2FHamilton-Sosa.png?alt=media&token=1d690a9f-75ec-48b7-a91e-444ee597cca3" className='team-img' alt="EPI Team Photo - Hamilton Sosa" />
                            </div>
                            <h4 className='team-name'>Hamilton Sosa</h4>
                            <p className='team-title'>Senior Service Manager</p>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='text-center'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FTeamPhotos%2FRichard-Perez.png?alt=media&token=62f09b6f-f433-4b72-a806-831a4df89e76" className='team-img' alt="EPI Team Photo - Richard Perez" />
                            </div>
                            <h4 className='team-name'>Richard Perez</h4>
                            <p className='team-title'>Technical Support Manager</p>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='text-center'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FTeamPhotos%2FMark-Bramman.png?alt=media&token=942176c2-4533-4be1-978d-08785f034cce" className='team-img' alt="EPI Team Photo - Mark Bramman" />
                            </div>
                            <h4 className='team-name'>Mark Bramman</h4>
                            <p className='team-title'>Lead Technician</p>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='text-center'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FTeamPhotos%2FDai-Tran.png?alt=media&token=a94d65c3-0cff-4534-ab8b-b634e667ffde" className='team-img' alt="EPI Team Photo - Dai Tran" />
                            </div>
                            <h4 className='team-name'>Dai Tran</h4>
                            <p className='team-title'>Technician </p>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='text-center'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FTeamPhotos%2FChinh-Van-Bui.png?alt=media&token=e7db2edc-6708-4f43-bdc2-cb7f972e2ac5" className='team-img' alt="EPI Team Photo - Chihn Van Bui" />
                            </div>
                            <h4 className='team-name'>Chinh Van Bui</h4>
                            <p className='team-title'>Calibration Technician</p>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='text-center'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FTeamPhotos%2FJoseph-Limon.png?alt=media&token=b059ce07-fc0f-4e9b-9cc0-42c8f7e97263" className='team-img' alt="EPI Team Photo - Joseph Limon" />
                            </div>
                            <h4 className='team-name'>Joseph Limon</h4>
                            <p className='team-title'>Purchase Manager</p>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='text-center'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FTeamPhotos%2FRigo-Ardon.png?alt=media&token=48d3b8cf-3332-429e-a9d5-834a1b691f51" className='team-img' alt="EPI Team Photo - Rigo Ardon" />
                            </div>
                            <h4 className='team-name'>Rigo Ardon</h4>
                            <p className='team-title'>Finance Manager</p>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='text-center'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FTeamPhotos%2FJay-Allen.png?alt=media&token=b09d09a0-57df-48f2-bc34-5b2681587acb" className='team-img' alt="EPI Team Photo - Jay Allen" />
                            </div>
                            <h4 className='team-name'>Jay Allen</h4>
                            <p className='team-title'>Lead Designer</p>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='text-center'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FTeamPhotos%2FNickson-Ariemba.png?alt=media&token=854dd2fc-c450-4b63-91b9-3f3d8455fbf5" className='team-img' alt="EPI Team Photo - Nickson Ariemba" />
                            </div>
                            <h4 className='team-name'>Nickson Ariemba</h4>
                            <p className='team-title'>Lead Fullstack Engineer</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* section-3 */}
            {/* section-4 */}
            <div className='section-4'>
                <div className='text-center'>
                    <h1 className=''>Masters of Flow Since 1988</h1>
                    <p>A brief overview of EPI.</p>
                </div>
                <Timeline />
            </div>
            {/* section-4 */}
        </div>
    )
}

export default AboutUs
