import React, {useEffect, useState} from "react";
import { useHistory } from 'react-router';

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable
} from "@tanstack/react-table";

const defaultColumns = [
  {
    header: 'Configurations',
    footer: props => props.column.id,
    columns: [
      {
        accessorKey: 'Model',
        cell: info => info.getValue(),
        footer: props => props.column.id,
      },
      {
        accessorFn: row => row.series,
        id: 'Series',
        cell: info => info.getValue(),
        header: () => <span className="showAlways">Series</span>,
        footer: props => props.column.id,
      },
      {
        accessorFn: row => `#${row.config_id}`,
        id: 'Config Id',
        cell: info => info.getValue(),
        header: () => <span className="showAlways">Config Id</span>,
        footer: props => props.column.id,
      },
      {
        accessorFn: row => row.created_on,
        id: 'Created On',
        cell: info => info.getValue(),
        header: () => <span>Created On</span>,
        footer: props => props.column.id,
      },
      {
        accessorFn: row => row.updated_on,
        id: 'Updated On',
        cell: info => info.getValue(),
        header: () => <span>Updated On</span>,
        footer: props => props.column.id,
      },
      {
        accessorFn: row => `$${row.total}`,
        id: 'Total',
        cell: info => info.getValue(),
        header: () => <span>Total</span>,
        footer: props => props.column.id,
      },
      {
        accessorFn: row => row.action,
        id: 'Actions',
        cell: info => <a className="hyperlink" onClick={ () => {
          info.getValue().quoteClicked(
            info.getValue()._id
          )
        }}>View</a>,
        header: () => <span>Actions</span>,
        footer: props => props.column.id,
      },
    ],
  }
]

const Filter = ({column, table}) => {
  console.log('show me column 1.0 =>', column.id);
  if(column?.id === 'User Type'){
    return(
      <select
        class="form-select form-control form-control-lg"
        onChange={(e) => column.setFilterValue(e.target.value)}
      >
        <option value="">All Users</option>
        <option value="standard">Standard Users</option>
        <option value="distributor">Distributors / Reps</option>
        <option value="admin">Admins</option>
      </select>
    );
  }

  if(column?.id === 'Subscribed' ){
    return(
      <select
        class="form-select form-control form-control-lg"
        onChange={(e) => column.setFilterValue(e.target.value)}
      >
        <option value="">Both</option>
        <option value="yes">Yes</option>
        <option value="no">No</option>
      </select>
    );
  }

  if(column?.id === 'Actions' ){
    return(
      <select class=" form-control form-control-lg" disabled>
      </select>
    );
  }

  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={((column.getFilterValue())?.[0] ?? "")}
        onChange={(e) =>
          column.setFilterValue((old) => [e.target.value, old?.[1]])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={((column.getFilterValue())?.[1] ?? "")}
        onChange={(e) =>
          column.setFilterValue((old) => [old?.[0], e.target.value])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <input
      type="text"
      value={(column.getFilterValue() ?? "")}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className="form-control form-control-sm"
    />
  );
}


const AllQuotesTable = ({ configs }) => {
  const [data, setData] = useState('')
  const [columns] = useState(() => [...defaultColumns])
  const [rowSelection, setRowSelection] = useState({});
  const history = useHistory();

  const quoteClicked = (_id) => {
    history.push(`/portal/quotes/${_id}#`);
  }

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true
  });

  useEffect( async () => {
    let mounted = true;

      if (mounted) {
        const configs_ = [];
        for (let i = 0; i < configs.length; i++) {

          const date = new Date(configs[i].createdAt);
          const day = date.getDate();
          const month = ("0" + (date.getMonth() + 1)).slice(-2)
          const year = date.getFullYear();
          const date_full = `${month}/${day}/${year}`;

          const date2 = new Date(configs[i].updatedAt);
          const day2 = date2.getDate();
          const month2 = ("0" + (date2.getMonth() + 1)).slice(-2)
          const year2 = date2.getFullYear();
          const date_full2 = `${month2}/${day2}/${year2}`;

          const config = {
            Model: configs[i].model,
            series: configs[i].series,
            config_id: configs[i].quoteId,
            created_on: date_full,
            updated_on: date_full2,
            total: configs[i].total,
            action: {
              _id: configs[i]._id,
              quoteClicked: quoteClicked
            }
          }
          configs_.push(config);
        }

        if(configs_){
          setData(configs_)
        }
      }

    return () => mounted = false; // cleanup function
  }, [configs]);

  return (
    <div className="row filters">
      <div className="col-10">
        <h6>Toggle Columns</h6>
        {table.getAllLeafColumns().map(column => {
          return (
            <div key={column.id} class="form-check form-check-inline">
              <label class="form-check-label">
                <input
                  class="form-check-input"
                  {...{
                    type: 'checkbox',
                    checked: column.getIsVisible(),
                    onChange: column.getToggleVisibilityHandler(),
                  }}
                />{' '}
                &nbsp;{column.id}
                <span class="form-check-sign">
                    <span class="check"></span>
                </span>
              </label>
            </div>
          )
        })}
        <p></p>
      </div>

      <div class="col-2">
        <label class="form-check-label"></label>
        <p className="text-right p-2">
          Page: <b>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </b>
        </p>
      </div>

      <div className="h-4" />
      <table class="table table-hover">
        <thead class="thead-light">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr scope="col" key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan} className="tHeadText">
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanFilter() ? (
                          <div className="showAlways">
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        <nav>
          <ul className="pagination justify-content-center">
            <li className={`page-item ${!table.getCanPreviousPage() && 'disabled'}`}>
              <a
                className="page-link"
                onClick={() => table.setPageIndex(0)}
              >
                {"First"}
              </a>
            </li>
            <li className={`page-item ${!table.getCanPreviousPage() && 'disabled'}`}>
              <a
                className="page-link"
                onClick={() => table.previousPage()}
              >
                {"Previous"}
              </a>
            </li>
            <li className={`page-item ${!table.getCanNextPage() && 'disabled'}`}>
              <a
                className="page-link"
                onClick={() => table.nextPage()}
            >
              {"Next"}
              </a>
            </li>
            <li class="page-item" className={`page-item ${!table.getCanNextPage() && 'disabled'}`}>
              <a
                className="page-link"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            >
              {"Last"}
              </a>
            </li>
          </ul>
        </nav>
        <div className="paginationHelper justify-content-center row">
          <div className="col-2">
            <p className="">
              <label>Go To:</label>
              <input
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
                className="form-control"
              />
            </p>
          </div>
          <div className="col-3">
            <div className="form-group">
              <p className="">
                <label>Display Per Page:</label>
                <select
                  className="form-select form-control form-control-lg"
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
                </select>
              </p>
            </div>
          </div>
          <div className="col-12">
            <p className="text-center">
              Page: <b>
                {table.getState().pagination.pageIndex + 1} of{" "}
                {table.getPageCount()}
              </b>
            </p>
          </div>
        </div>
      </div>

    </div>
  )
}

export default AllQuotesTable
