import React from "react";

// reactstrap components
import {
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  UncontrolledTooltip
} from "reactstrap";

function DuctRectangular({ handleChange, state, checkState }){
  Array.prototype.removeWithValue = function() {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
  };

  const MakeChangeText = (data) => {
    data.option_value = data.option.target.value;
    handleChange(data);
  }

  return (
    <>
      <Row>
        <Col>
          <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitle">Height</span>
              <Button className="questionToolTip" id="DuctHeight" size="sm">
                  ?
              </Button>{` `}
              <UncontrolledTooltip placement="top" target="DuctHeight" delay={0}>
                Pipe Size Information needed
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlInput1"
              placeholder="Duct Height"
              type="text"
              defaultValue={
                checkState(state.pipe_infornation_insertion.duct_rectangular_height, 'height') && 
                state.pipe_infornation_insertion.duct_rectangular_height.valuesObj.height
              }
              onChange={ (e) => MakeChangeText({
                section: 'pipe_infornation_insertion',
                type: 'duct_rectangular_height',
                values: ['height'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            ></Input>
          </FormGroup>
        </Col>

        <Col>
          <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitle">Width</span>
              <Button className="questionToolTip" id="DuctWidth" size="sm">
                  ?
              </Button>{` `}
              <UncontrolledTooltip placement="top" target="DuctWidth" delay={0}>
                Duct Width Information needed
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlInput1"
              placeholder="Duct Width"
              type="text"
              defaultValue={
                checkState(state.pipe_infornation_insertion.duct_rectangular_width, 'width') && 
                state.pipe_infornation_insertion.duct_rectangular_width.valuesObj.width
              }
              onChange={ (e) => MakeChangeText({
                section: 'pipe_infornation_insertion',
                type: 'duct_rectangular_width',
                values: ['width'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            ></Input>
          </FormGroup>
        </Col>

        <Col>
          <FormGroup>
            <label htmlFor="exampleFormControlSelect1" style={{ 'margin-bottom': '14px', 'margin-top': '10px'}}>
              <span className="customizerInputTitle">Dimention</span>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlInput1"
              placeholder="Duct Dimention"
              type="text"
              defaultValue={
                checkState(state.pipe_infornation_insertion.duct_rectangular_units, 'units') && 
                state.pipe_infornation_insertion.duct_rectangular_units.valuesObj.units
              }
              onChange={ (e) => MakeChangeText({
                section: 'pipe_infornation_insertion',
                type: 'duct_rectangular_units',
                values: ['units'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            ></Input>
          </FormGroup>
        </Col>
      </Row>

      <Row style={{ 'margin-top': '20px'}}>
        <Col>
          <FormGroup>
            <label htmlFor="exampleFormControlInput1">
              <span className="customizerInputTitle">Duct Wall Thickness</span>
              <Button className="questionToolTip" id="DuctWallThickness" size="sm">
                  ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="DuctWallThickness" delay={0}>
                Duct Wall Thickness Information needed
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlInput1"
              placeholder="Duct Wall Thickness"
              type="text"
              defaultValue={
                checkState(state.pipe_infornation_insertion.duct_rectangular_wall, 'wall') && 
                state.pipe_infornation_insertion.duct_rectangular_wall.valuesObj.wall
              }
              onChange={ (e) => MakeChangeText({
                section: 'pipe_infornation_insertion',
                type: 'duct_rectangular_wall',
                values: ['wall'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            ></Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitle">Probe Direction</span>
              <Button className="questionToolTip" id="ProbeDirection" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="ProbeDirection" delay={0}>
                Probe Direction Information needed
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlInput1"
              placeholder="Probe Direction"
              type="text"
              defaultValue={
                checkState(state.pipe_infornation_insertion.duct_rectangular_direction, 'direction') && 
                state.pipe_infornation_insertion.duct_rectangular_direction.valuesObj.direction
              }
              onChange={ (e) => MakeChangeText({
                section: 'pipe_infornation_insertion',
                type: 'duct_rectangular_direction',
                values: ['direction'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            ></Input>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default DuctRectangular;
