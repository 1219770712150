import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyASnD2NkdDLUqxwnlCYLbhNg5CwRaXq-CQ",
  authDomain: "epiprofileimgs.firebaseapp.com",
  projectId: "epiprofileimgs",
  storageBucket: "epiprofileimgs.appspot.com",
  messagingSenderId: "249469189762",
  appId: "1:249469189762:web:d0359bf4edfd99813d3e9b",
  measurementId: "G-55LCMXXQ6C"
};

firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();
export default storage;
