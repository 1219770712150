import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useSwr, { useSWRConfig } from 'swr';
import { Form, Button } from 'reactstrap'
import 'assets/css/EditAccount.css';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import PhoneInput from 'react-phone-number-input';
import { formatPhoneNumberIntl, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import fetcher from 'utils/fetcher';

function RequestPricingAccess() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [phoneValue, setPhoneValue] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [image, setImage] = useState('');
    const [userData, setUserData] = useState([]);
    const [formData, setFormData] = useState([]);
    const [emptyField, setEmptyField] = useState(false);
    const [emptyField2, setEmptyField2] = useState(false);
    const [phoneCountry, setPhoneCountry] = useState('US');
    const page = { RequestPricingAccess: true }

    const { cache } = useSWRConfig()
    const { data, mutate } = useSwr("/api/me", fetcher);

    console.log('Show me update user info form update 0.1 =>', data, cache);

    useEffect(() => {
        if(data){
            setUserData(data);
            setImage(data.profileImage);
            setPhoneValue(data.phoneNumber);
            setFormData({
                email: data.email,
                firstname: data.firstname,
                lastname: data.lastname,
                companyName: data.companyName,
                phoneNumber: data.phoneNumber,
                profileImage: data.profileImage,
                jobTitle: data.jobTitle,
                department: data.department,
                address: data.address,
                city: data.city,
                zip: data.zip,
                country: data.country,
                state: data.state,
                account_type: data.account_type,
                subscriptions: data.subscriptions
            });
            if(data.phoneNumber){
                const country = parsePhoneNumber(data.phoneNumber);
                if(country){
                    setPhoneCountry(country.country)
                }
            }
        }
    }, [data]);

    const checkDataFunc = (data, setFuction) => {
        const user_data = data;
        user_data['profileImage'] = 'empty';

        if(!data.jobTitle || data.jobTitle === ""){
            user_data['jobTitle'] = 'empty';
        }
        if(!data.department || data.department === ""){
            user_data['department'] = 'empty';
        }

        const checkData = Object.values(user_data);
        for (var i = 0; i < checkData.length; i++){
          if(checkData[i] === undefined){
            setFuction(true);
            return
          }
          setFuction(false);
        }
    }

    useEffect(() => {
        checkDataFunc( formData, setEmptyField );
    }, [formData]);

    const updateDefaultValue = (formFeild) => {
        const feild = formFeild.feild;
        const textValue = formFeild.value.target.value;

        setFormData(prevState => ({
            ...prevState,
            [feild]: textValue
        }))
    }

    const onSubmit = async () => {
        let update_data = {
            email: formData.email,
            firstname: formData.firstname,
            lastname: formData.lastname,
            companyName: formData.companyName,
            phoneNumber: phoneValue,
            department: formData.department,
            address: formData.address,
            city: formData.city,
            zip: formData.zip,
            country: formData.country,
            state: formData.state,
            subscriptions: formData.subscriptions,
            typeOfRequest: 'requesting'
        }

        console.log('Show me update user info 1.1 =>', update_data)
        checkDataFunc( update_data, setEmptyField2 );

        if(emptyField2){ return }

        update_data.account_type = 'requesting'
        console.log('Show me update user info 1.2 =>', update_data);

        try{
            mutate('/api/me', update_data, false)
            await axios.put(
                `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/me`,
                update_data,
                { withCredentials: true }
            )

            const options = { optimisticData: update_data, rollbackOnError: true }
            mutate(update_data, options);
        } catch (e) {
            // console.log('Show me update user error 1.0 =>', e);
        }
    }

    const headerData = {
        firstname: userData.firstname,
        page: 'REQUEST PRICING ACCESS'
    }

    // console.log('Show me update user info 1.2 =>', formData);

    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page}/></div>) : undefined}
                    <div className='common-main'>
                        <h2 className='p-3'>Request to See Prices</h2>
                        {formData && formData.account_type === "regular" &&
                            <div className='bdr body p-3 text-center'>
                                {emptyField && 
                                    <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545', 'padding-top': '15px' }}>
                                        In order to view pricing the form bellow must be fill out and submited. A EPI representative will review your request, and if approved your account will be granted pricing visibility.
                                    </p>
                                }
                                {/* <div>
                                    <Button
                                        disabled={emptyField && 'disabled'}
                                        className='inputButton'
                                        onClick={(e) => onSubmit('viewPricing')}
                                    >
                                        Request Pricing Access
                                    </Button>
                                </div> */}
                            </div>
                        }

                        {formData && formData.account_type === "admin" &&
                            <div className='bdr body p-3 text-center'>
                                <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>As an admin, you are able to view all pricing.</p>
                            </div>
                        }

                        {formData && formData.account_type === "requesting" &&
                            <div className='bdr body p-3 text-center'>
                                <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>Your request has been sent for review.</p>
                            </div>
                        }

                        {formData && formData.account_type === "regularPlusPricing" &&
                            <div className='bdr body p-3 text-center'>
                                <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>Your account has been approved to view prices. Pricing should now show in the Meter Customizer.</p>
                            </div>
                        }
                        
                        {formData.account_type ==='regular' &&
                            <div className='bdr body p-3'>
                                <div>
                                    <Form>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label for="firstname">FIRST NAME <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        name="fname"
                                                        id="firstname"
                                                        autoComplete='given-name'
                                                        placeholder="First Name"
                                                        defaultValue={ userData.firstname }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'firstname',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label for="lastname">LAST NAME <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="lastname"
                                                        name="lname"
                                                        autoComplete='family-name'
                                                        placeholder="Last Name"
                                                        defaultValue={ userData.lastname }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'lastname',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="jobtitle">JOB TITLE</label>
                                                    <input
                                                        type="text"
                                                        id="jobtitle"
                                                        name="jobtitle"
                                                        class="form-control"
                                                        placeholder="Job Title"
                                                        defaultValue={ userData.jobTitle }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'jobTitle',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="company">COMPANY <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="company"
                                                        name="company"
                                                        class="form-control"
                                                        placeholder="Company Name"
                                                        defaultValue={ userData.companyName }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'companyName',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="department">DEPARTMENT</label>
                                                    <input
                                                        type="text"
                                                        id="department"
                                                        name="department"
                                                        class="form-control"
                                                        placeholder="Department"
                                                        defaultValue={ userData.department }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'department',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="address">ADDRESS <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="address"
                                                        name="address"
                                                        class="form-control"
                                                        placeholder="Address"
                                                        defaultValue={ userData.address }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'address',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="city">CITY <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="city"
                                                        name="city"
                                                        class="form-control"
                                                        placeholder="City"
                                                        defaultValue={ userData.city }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'city',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="zip">ZIP <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="zip"
                                                        name="zip"
                                                        class="form-control"
                                                        placeholder="Zip"
                                                        defaultValue={ userData.zip }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'zip',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="company">COUNTRY <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="country"
                                                        name="country"
                                                        class="form-control"
                                                        placeholder="Country"
                                                        defaultValue={ userData.country }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'country',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="state">STATE <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="state"
                                                        name="state"
                                                        class="form-control"
                                                        placeholder="State"
                                                        defaultValue={ userData.state }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'state',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label For="phone" class="form-label">PHONE <span className='text-danger'>*</span></label>
                                                <PhoneInput
                                                    className='phoneinpputfeild'
                                                    defaultValue={formatPhoneNumberIntl(userData.phoneNumber)}
                                                    id="phone"
                                                    name="phone"
                                                    autoComplete='phone'
                                                    placeholder={userData.phoneNumber}
                                                    defaultCountry={phoneCountry}
                                                    onChange={setPhoneValue}
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                    <Button
                                        className='inputButton'
                                        onClick={(e) => onSubmit()}
                                    >
                                        Request Pricing Access
                                    </Button>

                                    {emptyField2 && 
                                        <div className='text-center'>
                                            <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545', 'padding-top': '15px' }}>
                                                All fields must be field.
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>

                    {/* {emptyField &&
                        <div className='common-main'>
                            <h2 className='p-3'>Account Information</h2>
                            <div className='bdr body p-3'>
                                <div>
                                    {!emptyField &&
                                        <>
                                            <ImageSelector
                                                header={"UPLOAD PROFILE IMAGE"}
                                                image={image}
                                                setImage={setImage}
                                                userData={userData}
                                            />
                                            <hr />
                                        </>
                                    }
                                    <Form>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label for="firstname">FIRST NAME <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        name="fname"
                                                        id="firstname"
                                                        autoComplete='given-name'
                                                        placeholder="First Name"
                                                        defaultValue={ userData.firstname }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'firstname',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label for="lastname">LAST NAME <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="lastname"
                                                        name="lname"
                                                        autoComplete='family-name'
                                                        placeholder="Last Name"
                                                        defaultValue={ userData.lastname }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'lastname',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="jobtitle">JOB TITLE</label>
                                                    <input
                                                        type="text"
                                                        id="jobtitle"
                                                        name="jobtitle"
                                                        class="form-control"
                                                        placeholder="Job Title"
                                                        defaultValue={ userData.jobTitle }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'jobTitle',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="company">COMPANY <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="company"
                                                        name="company"
                                                        class="form-control"
                                                        placeholder="Company Name"
                                                        defaultValue={ userData.companyName }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'companyName',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="department">DEPARTMENT</label>
                                                    <input
                                                        type="text"
                                                        id="department"
                                                        name="department"
                                                        class="form-control"
                                                        placeholder="Department"
                                                        defaultValue={ userData.department }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'department',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="address">ADDRESS <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="address"
                                                        name="address"
                                                        class="form-control"
                                                        placeholder="Address"
                                                        defaultValue={ userData.address }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'address',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="city">CITY <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="city"
                                                        name="city"
                                                        class="form-control"
                                                        placeholder="City"
                                                        defaultValue={ userData.city }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'city',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="zip">ZIP <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="zip"
                                                        name="zip"
                                                        class="form-control"
                                                        placeholder="Zip"
                                                        defaultValue={ userData.zip }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'zip',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="company">COUNTRY <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="country"
                                                        name="country"
                                                        class="form-control"
                                                        placeholder="Country"
                                                        defaultValue={ userData.country }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'country',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="state">STATE <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="state"
                                                        name="state"
                                                        class="form-control"
                                                        placeholder="State"
                                                        defaultValue={ userData.state }
                                                        onChange={(e) => updateDefaultValue({
                                                            feild: 'state',
                                                            value: e
                                                        }, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label For="phone" class="form-label">PHONE <span className='text-danger'>*</span></label>
                                                <PhoneInput
                                                    className='phoneinpputfeild'
                                                    defaultValue={formatPhoneNumberIntl(userData.phoneNumber)}
                                                    id="phone"
                                                    name="phone"
                                                    autoComplete='phone'
                                                    placeholder={userData.phoneNumber}
                                                    defaultCountry={phoneCountry}
                                                    onChange={setPhoneValue}
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                    <Button className='inputButton' onClick={(e) => onSubmit()}> Update Profile Information </Button>
                                </div>
                            </div>
                        </div>
                    } */}
                </div>
            </div>
        </div>
    )
}

export default RequestPricingAccess
