import React from "react";

// reactstrap components
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip
} from "reactstrap";

import MultiNoTempBottom from "./MultiNoTempBottom";

function MultiRangeNoTemp({ handleChange, state, checkState }){
  return (
    <>
      <p className="text-center inputPowerCommunicationTextbox customizerInputTitleSmallX1">
        Multi-Range allows for up to 4 custom ranges, specified before purchasing. An EPI representative will reach out
        to you to confirm these details after your quote has become finalized.
      </p>
      <Row>
        <Col className="inputPowerCommunicationCols">
          <span>
            <span className="customizerInputTitleSmallX1">4-20MA FLOW OUTPUT</span>
            <Button className="questionToolTip" id="MAFLOWOUTPUT21" size="sm">
                ?
            </Button>{` `}
            <UncontrolledTooltip placement="top" target="MAFLOWOUTPUT21" delay={0}>
              A 4-20mA current loop is a means to pass a “value” like flow using an established electrical current (the loop) 
              as the carrier of this information. Generally, the 4mA represents the minimum value (like 0 for flow); and the 20mA 
              represents the maximum value, or full scale, of the value you are sending.  
              EPI flow meters use this output to represent the flow rate in the process line. 
              We offer Self Powered or Isolated-Loop Powered 4-20mA flow signal; please specify your desired configuration. 
              If you choose the Isolated-Loop Powered option, this does not provide meter power, 
              and will require separate input power for the meter electronics.
            </UncontrolledTooltip>
          </span>
          <FormGroup check className="form-check-radio">
            <Label check>
              <span className="customizerInputTitleSmallX1">Self Powered</span>
              <Input
                defaultChecked={
                  checkState(
                    state.input_power_communication.multi_ma_flow,
                    'self'
                  )
                }
                onClick={ (e) => handleChange({
                  section: 'input_power_communication',
                  type: 'multi_ma_flow',
                  values: ['self', 'isolated'],
                  price_effect: false
                },e)
              }
                id="multi_ma_flow"
                name="multi_ma_flow"
                type="radio"
              ></Input>
              <span className="form-check-sign"></span>
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">
            <Label check>
              <span className="customizerInputTitleSmallX1">Isolated-loop Powered</span>
              <Input
                defaultChecked={
                  checkState(
                    state.input_power_communication.multi_ma_flow,
                    'isolated'
                  )
                }
                onClick={ (e) => handleChange({
                  section: 'input_power_communication',
                  type: 'multi_ma_flow',
                  values: ['isolated', 'self'],
                  price_effect: false
                },e)
              }
                id="multi_ma_flow"
                name="multi_ma_flow"
                type="radio"
              ></Input>
              <span className="form-check-sign"></span>
            </Label>
          </FormGroup>
        </Col>

        <Col className="inputPowerCommunicationCols">
          <span>
            <span className="customizerInputTitleSmallX1">VDC FLOW OUTPUT</span>
            <Button className="questionToolTip" id="VDCFLOWOUTPUT21" size="sm">
                ?
            </Button>{` `}
            <UncontrolledTooltip placement="top" target="VDCFLOWOUTPUT21" delay={0}>
              VDC FLOW OUTPUT Information needed
            </UncontrolledTooltip>
          </span>
          <FormGroup check className="form-check-radio">
            <Label check>
              <span className="customizerInputTitleSmallX1">0-5</span>
              <Input
                defaultChecked={
                  checkState(
                    state.input_power_communication.multi_vdc_flow,
                    '5'
                  )
                }
                onClick={ (e) => handleChange({
                  section: 'input_power_communication',
                  type: 'multi_vdc_flow',
                  values: ['5', '10'],
                  price_effect: false
                },e)
              }
                id="multi_vdc_flow"
                name="multi_vdc_flow"
                type="radio"
              ></Input>
              <span className="form-check-sign"></span>
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">
            <Label check>
              <span className="customizerInputTitleSmallX1">0-10</span>
              <Input
                defaultChecked={
                  checkState(
                    state.input_power_communication.multi_vdc_flow,
                    '10'
                  )
                }
                onClick={ (e) => handleChange({
                  section: 'input_power_communication',
                  type: 'multi_vdc_flow',
                  values: ['10', '5'],
                  price_effect: false
                },e)
              }
                id="multi_vdc_flow"
                name="multi_vdc_flow"
                type="radio"
              ></Input>
              <span className="form-check-sign"></span>
            </Label>
          </FormGroup>
        </Col>

        <Col className="inputPowerCommunicationCols">

        </Col>

        <Col className="inputPowerCommunicationCols">

        </Col>
      </Row>
      <hr />
      <MultiNoTempBottom />
    </>
  );
}

export default MultiRangeNoTemp;
