export default function GetRealValues (state, getKeyByValue){
  const meterHousing = () => {
    const _display = getKeyByValue(state.meter_housing, true)
    if(_display === 'display'){ return('Display With Keypad') }
    if(_display === 'no_display'){ return('No display') }
  }

  const flowBodySensor = () => {
    const _sensor = getKeyByValue(state.flow_body.sensors, true);
    if(_sensor === 'sss'){ return('Stainless Steel 316L') }
    if(_sensor === 'has'){ return('Hastelloy C-276') }
  }

  const flowBodyShield = () => {
    const sensors_shield = getKeyByValue(state.flow_body.sensors_shield, true);
    if(sensors_shield === 'standard'){ return('Standard Sensor - No Sheild') }
    if(sensors_shield === 'rod'){ return('Standard Sensor + Attenuation Rod') }
    if(sensors_shield === 'shield'){ return('Standard Sensor + Particulate Shield') }
  }

  const flowDirection = () => {
    if(getKeyByValue(state.meter_orientation.pipe_orientation, true) === 'vertical'){
      const flowDirVert = getKeyByValue(state.meter_orientation.flow_direction_vertical, true);
      if(flowDirVert === 'left_to_right'){ return('Left to Right') }
      if(flowDirVert === 'right_to_left'){ return('Right to Left') }
    }

    if(getKeyByValue(state.meter_orientation.pipe_orientation, true) === 'horizion'){
      const flowDirHori = getKeyByValue(state.meter_orientation.flow_direction_horizontal, true);
      if(flowDirHori === 'left_to_right'){ return('LEFT TO RIGHT') }
      if(flowDirHori === 'right_to_left'){ return('RIGHT TO LEFT') }
    }
  }

  const power = () => {
    const power = getKeyByValue(state.input_power_communication.input_power, true);
    if(power === 'vac_105_120'){ return('VAC 105 to 120') }
    if(power === 'vac_210_240'){ return('VAC 210 to 240') }
    if(power === 'vdc_12_18'){ return('12 to 18 VDC') }
    if(power === 'vdc_18_24'){ return('18 to 24 VDC') }
  }

  const communications = () => {
    const comms = getKeyByValue(state.input_power_communication.communications_options, true);
    if(comms === 'bacnet'){ return('Bacnet') }
    if(comms === 'hart'){ return('Hart') }
    if(comms === 'multi_range_no_temp'){ return('Multi-Range + No Temp') }
    if(comms === 'multi_range_temp_out'){ return('Multi-Range + Temp Out') }
    if(comms === 'profibus'){ return('Profibus') }
    if(comms === 'standard'){ return('Standard') }
  }

  if(state){
    const return_values = {
      flow_direction: flowDirection(),
      meter_housing: meterHousing(),
      pipe_information: getKeyByValue(state.pipe_infornation.pipe_size, true),
      application_information: getKeyByValue(state.application_information.gas, true),
      input_power: power(),
      communication_options: communications(),
      mounting_requirements: getKeyByValue(state.mounting_requirements.mounting_types, true),
      flow_body_sensor: flowBodySensor(),
      flow_body_shield: flowBodyShield()
    }
    return(return_values)
  }

  const return_values = {
    flow_direction: '',
    meter_housing: '',
    pipe_information: '',
    application_information: '',
    input_power: '',
    communication_options: '',
    mounting_requirements: '',
    flow_body_sensor: '',
    flow_body_shield: ''
  }
  return(return_values)
}