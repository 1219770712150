import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';

// reactstrap components
import {
  UncontrolledCollapse,
  Button,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardHeader,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Collapse,
  UncontrolledTooltip
} from "reactstrap";

function MountingRequirements({handleChange, pipeInfoPipeSize, state, checkState, meterType }){
  const [selectFlange, setSelectFlange] = useState(false);
  const [selectFlange2, setSelectFlange2] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  // collapse states and functions
  const [collapses, setCollapses] = React.useState([0]);
  const changeCollapse = collapse => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter(prop => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  useEffect(()=>{
    if(pipeInfoPipeSize > 3){
      setSelectFlange(false);
    }
  }, [pipeInfoPipeSize]);

  const MakeChangeDropdown = (data) => {
    data.option_value = data.option.target.value;
    const values = data.values;
    const first_value = values[0]

    if(first_value !== data.option_value){
      values.removeWithValue(data.option_value)
      values[0] = data.option_value;
      values.splice(1,0, first_value);
      values.join();
      data.values = values;
    }

    handleChange(data);
  }

  const MakeChangeText = (data) => {
    data.option_value = data.option.target.value;
    handleChange(data);
  }

  const MakeChange = (data) => {
    handleChange(data);
  }

  var title = 'Mounting Requirements';
  if (isMobile) {
    if(title.length > 18) title = title.substring(0,18) + '...';
  }

  var flangeSelected = false;
  if(pipeInfoPipeSize > 3){
    flangeSelected = true;
  }

  const getMeterModelValue = (value) => {
    if(value === '150'){ return 'FL15xx' }
    if(value === '300'){ return 'FL30xx' }
    if(value === 'other'){ return 'NA' }
  }

  return (
    <>
      <Card style={{
        'border-top-left-radius': 'calc(.45rem - 1px)',
        'border-top-right-radius': 'calc(.45rem - 1px)',
        'border-bottom-left-radius': 'calc(.45rem - 1px)',
        'border-bottom-right-radius': 'calc(.45rem - 1px)'
        }}>
        <CardHeader>
          <a
            aria-expanded={collapses.includes(2)}
            data-toggle="collapse"
            href="#pablo"
            onClick={e => {
              e.preventDefault();
              changeCollapse(2);
            }}
          >
            <Navbar className="bg-epi-blue" style={{
              'height': '10px', 'margin-bottom': '5px',
              'border-top-left-radius': 'calc(.45rem - 1px)',
              'border-top-right-radius': 'calc(.45rem - 1px)'
              }} expand="lg">
              <Container>
                <div className="navbar-translate">
                  <NavbarBrand href="#pablo" onClick={e => e.preventDefault()} style={{'font-size': '22px', 'color': 'white'}}>
                    <p>{ title }</p>
                  </NavbarBrand>
                  <button
                    className="navbar-toggler"
                    id="example-navbar-danger"
                    type="button"
                    aria-expanded={collapses.includes(2)}
                    onClick={e => {
                      e.preventDefault();
                      changeCollapse(2);
                    }}
                  >
                    <i className="now-ui-icons arrows-1_minimal-down" style={{'font-size': '22px', 'color': 'white'}}></i>
                  </button>
                </div>
                <UncontrolledCollapse navbar toggler="#example-navbar-danger">
                  <Nav className="ml-auto" navbar style={{'font-size': '22px', 'color': 'white'}}>
                    <i className="now-ui-icons arrows-1_minimal-down"></i>
                  </Nav>
                </UncontrolledCollapse>
              </Container>
            </Navbar>
          </a>
        </CardHeader>

        <Collapse isOpen={collapses.includes(2)}>
          <CardBody>
          <Row>
              <Col>
                <span className="customizerInputTitle">Mounting Type</span>
                <FormGroup check className="form-check-radio" disabled>
                  <Label check>
                    <span className="customizerInputTitleSmallX1">MNPT Ends</span>
                    <Input
                      disabled
                      onClick={ (e) => MakeChange({
                          section: 'mounting_requirements',
                          type: 'mounting_types',
                          values: ['mnpt', 'butt', 'flange'],
                          price_effect: false,
                          meter_model_effect: true,
                          meter_model_type: 'mounting_requirements',
                          meter_model_value: 'MT'
                        },
                        e,
                        setSelectFlange(false)
                      )}
                      id="mounting_types"
                      name="mounting_types"
                      type="radio"
                    ></Input>
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
                <FormGroup check className="form-check-radio" disabled>
                  <Label check>
                    <span className="customizerInputTitleSmallX1">Butt End</span>
                    <Input
                      disabled
                      onClick={ (e) => MakeChange({
                          section: 'mounting_requirements',
                          type: 'mounting_types',
                          values: ['butt', 'mnpt', 'flange'],
                          price_effect: false,
                          meter_model_effect: true,
                          meter_model_type: 'mounting_requirements',
                          meter_model_value: 'BE'
                        },
                        e,
                        setSelectFlange(false)
                      )}
                      id="mounting_types"
                      name="mounting_types"
                      type="radio"
                    ></Input>
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>

                {flangeSelected? (
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">Flange</span><br />
                      { flangeSelected &&
                        <span className="customizerInputTitleSmallX1 errorText">
                          (3” 4” selections only available with flange end connection)
                        </span>
                      }
                      <Input
                        defaultChecked={
                          checkState(
                            state.mounting_requirements.mounting_types,
                            'flange'
                          )
                        }
                        checked={flangeSelected}
                        onClick={ (e) => MakeChange({
                          section: 'mounting_requirements',
                          type: 'mounting_types',
                          values: ['flange', 'butt', 'mnpt'],
                          price_effect: false,
                          meter_model_effect: true,
                          meter_model_type: 'mounting_requirements',
                          meter_model_value: '150'
                        }, e,
                        )}
                        id="mounting_types"
                        name="mounting_types"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                ) : (
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <span className="customizerInputTitleSmallX1">Flange</span><br />
                      <Input
                        defaultChecked={
                          checkState(
                            state.mounting_requirements.mounting_types,
                            'flange'
                          )
                        }
                        onClick={ (e) => MakeChange({
                          section: 'mounting_requirements',
                          type: 'mounting_types',
                          values: ['flange', 'butt', 'mnpt'],
                          price_effect: false,
                          meter_model_effect: true,
                          meter_model_type: 'mounting_requirements',
                          meter_model_value: 'FL15xx'
                        }, e,
                        setSelectFlange2(true)
                        )}
                        id="mounting_types"
                        name="mounting_types"
                        type="radio"
                      ></Input>
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                )}
              </Col>

              {/*
              <Col>
                <FormGroup>
                  <label htmlFor="exampleFormControlSelect1">
                    Image here
                  </label>
                </FormGroup>
              </Col>
              */}
            </Row>

            {selectFlange && (<hr />)}

            <Row>
              <Col>
                {checkState(state.mounting_requirements.mounting_types, 'flange') && (
                  <FormGroup>
                    <label htmlFor="exampleFormControlSelect1">
                      <span className="customizerInputTitle">Flange Type</span>
                      <Button className="questionToolTip" id="FlangType" size="sm">
                        ?
                      </Button>{` `}
                      <UncontrolledTooltip placement="right" target="FlangType" delay={0}>
                        Consult factory for non-standard flange requirements
                      </UncontrolledTooltip>
                      </label>
                    <Input
                      defaultChecked={
                        checkState(
                          state.mounting_requirements.flange_type,
                          '300'
                        )
                      }
                      className="epiInputSize form-select"
                      id="exampleFormControlSelect1"
                      type="select"
                      onChange={ (e) => MakeChangeDropdown({
                        section: 'mounting_requirements',
                        type: 'flange_type',
                        values: [
                          '150',
                          '300'
                        ],
                        price_effect: false,
                        meter_model_effect: true,
                        meter_model_type: 'mounting_requirements',
                        meter_model_value: getMeterModelValue(e.target.value),
                        option: e
                      }, e)}
                    >
                      <option value="" disabled>Select Flange Type</option>
                      <option selected={checkState(state.mounting_requirements.flange_type, '150')} value="150">ANSI 150</option>
                      <option selected={checkState(state.mounting_requirements.flange_type, '300')} value="300">ANSI 300</option>
                      <option selected={checkState(state.mounting_requirements.flange_type_other, 'size')} value="other">Other</option>
                    </Input>
                  </FormGroup>
                )}

                {checkState(state.mounting_requirements.mounting_types, 'flange') || flangeSelected && (
                  <FormGroup>
                    <label htmlFor="exampleFormControlSelect1">
                      <span className="customizerInputTitle">Flange Type</span>
                      <Button className="questionToolTip" id="FlangType" size="sm">
                        ?
                      </Button>{` `}
                      <UncontrolledTooltip placement="right" target="FlangType" delay={0}>
                        Consult factory for non-standard flange requirements
                      </UncontrolledTooltip>
                      </label>
                    <Input
                      defaultChecked={
                        checkState(
                          state.mounting_requirements.flange_type,
                          '300'
                        )
                      }
                      className="epiInputSize form-select"
                      id="exampleFormControlSelect1"
                      type="select"
                      onChange={ (e) => MakeChangeDropdown({
                        section: 'mounting_requirements',
                        type: 'flange_type',
                        values: [
                          '150',
                          '300'
                        ],
                        price_effect: false,
                        meter_model_effect: true,
                        meter_model_type: 'mounting_requirements',
                        meter_model_value: getMeterModelValue(e.target.value),
                        option: e
                      }, e)}
                    >
                      <option value="" disabled>Select Flange Type</option>
                      <option selected={checkState(state.mounting_requirements.flange_type, '150')} value="150">ANSI 150</option>
                      <option selected={checkState(state.mounting_requirements.flange_type, '300')} value="300">ANSI 300</option>
                      <option selected={checkState(state.mounting_requirements.flange_type_other, 'size')} value="other">Other</option>
                    </Input>
                  </FormGroup>
                )}

                { checkState(state.mounting_requirements.flange_type, 'other') && checkState(state.mounting_requirements.mounting_types, 'flange') && (
                  <FormGroup>
                    <br />
                    <span>EPI Sales department will share updated pricing for your custom flange selection below.</span>
                    <Input
                      className="epiInputSize"
                      id="exampleFormControlSelect1"
                      type="text"
                      placeholder="Enter flange type"
                      defaultValue={
                        checkState(state.mounting_requirements.flange_type_other, 'size') &&
                        state.mounting_requirements.flange_type_other.valuesObj.size
                      }
                      onChange={ (e) => MakeChangeText({
                        section: 'mounting_requirements',
                        type: 'flange_type_other',
                        values: ['size'],
                        price_effect: false,
                        text_input: true,
                        price_effect: false,
                        text_input: true,
                        option: e
                      }, e)}
                    >
                    </Input>
                    <br />
                  </FormGroup>
                ) }

                { checkState(state.mounting_requirements.flange_type, 'other') && flangeSelected && (
                  <FormGroup>
                    <br />
                    <span>EPI Sales department will share updated pricing for your custom flange selection below.</span>
                    <Input
                      className="epiInputSize"
                      id="exampleFormControlSelect1"
                      type="text"
                      placeholder="Enter flange type"
                      defaultValue={
                        checkState(state.mounting_requirements.flange_type_other, 'size') &&
                        state.mounting_requirements.flange_type_other.valuesObj.size
                      }
                      onChange={ (e) => MakeChangeText({
                        section: 'mounting_requirements',
                        type: 'flange_type_other',
                        values: ['size'],
                        price_effect: false,
                        text_input: true,
                        option: e
                      }, e)}
                    >
                    </Input>
                    <br />
                  </FormGroup>
                ) }

                { selectFlange && (
                  <FormGroup>
                    <label htmlFor="exampleFormControlSelect1">
                      <span className="customizerInputTitle">Flange Size</span>
                      <Button className="questionToolTip" id="FlangSize" size="sm">
                        ?
                      </Button>{` `}
                      <UncontrolledTooltip placement="right" target="FlangSize" delay={0}>
                        Flange size is based on pipe size selection
                      </UncontrolledTooltip>
                    </label>
                  </FormGroup>
                )}
              </Col>
              <Col></Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
}

export default MountingRequirements;
