import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row, Button, NavLink } from 'reactstrap';
import Img from '../../assets/img/images/GasWeFlowImg1.png';
import { Helmet } from "react-helmet";


import TechnicalInfoFooter from 'components/Footers/TechnicalInfoFooter';

function GasesWeFlow() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Gases We Flow</title>
            <meta name="description" content="EPI offers a wide variety of thermal flow meters that cover many different gases and applications. If it’s a gas, we can meter it!" />
            <link rel="canonical" href="https://epiflow.com/technicalinfo/gasesweflow" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner GWFlowSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/gasesweflow" tag={Link}>Gases We Flow</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Gases We Flow</h2>
                        <p className='cover-title-p'>
                            We offer flow meters that cover a wide variety of gases and applications, as seen below.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <Container className='py-5'>
                <h4 className='commontitleTwo text-center mb-4'>If it’s a gas, we can meter it.</h4>
                <Row className='GWFlowSection2 text-center gy-4'>
                    <Col lg="2" md="3" sm="6" xs="6">
                        <div>
                            <h5 className='commonParaOne m-0'>O<sub>2</sub></h5>
                            <p className='commontitlefive m-0'>Air</p>
                        </div>
                    </Col>
                    <Col lg="2" md="3" sm="6" xs="6">
                        <div>
                            <h5 className='commonParaOne m-0'>Ar</h5>
                            <p className='commontitlefive m-0'>Argon</p>
                        </div>
                    </Col>
                    <Col lg="2" md="3" sm="6" xs="6">
                        <div>
                            <h5 className='commonParaOne m-0'>CO<sub>2</sub></h5>
                            <p className='commontitlefive m-0'>Carbon Dioxide</p>
                        </div>
                    </Col>
                    <Col lg="2" md="3" sm="6" xs="6">
                        <div>
                            <h5 className='commonParaOne m-0'>C<sub>2</sub>H<sub>6</sub></h5>
                            <p className='commontitlefive m-0'>Ethane</p>
                        </div>
                    </Col>
                    <Col lg="2" md="3" sm="6" xs="6">
                        <div>
                            <h5 className='commonParaOne m-0'>C<sub>2</sub>H<sub>4</sub></h5>
                            <p className='commontitlefive m-0'>Ethylene</p>
                        </div>
                    </Col>
                    <Col lg="2" md="3" sm="6" xs="6">
                        <div>
                            <h5 className='commonParaOne m-0'>He</h5>
                            <p className='commontitlefive m-0'>Helium</p>
                        </div>
                    </Col>
                    <Col lg="2" md="3" sm="6" xs="6">
                        <div>
                            <h5 className='commonParaOne m-0'>H<sub>2</sub></h5>
                            <p className='commontitlefive m-0'>Hydrogen</p>
                        </div>
                    </Col>
                    <Col lg="2" md="3" sm="6" xs="6">
                        <div>
                            <h5 className='commonParaOne m-0'>CH<sub>4</sub></h5>
                            <p className='commontitlefive m-0'>Methane</p>
                        </div>
                    </Col>
                    <Col lg="2" md="3" sm="6" xs="6">
                        <div>
                            <h5 className='commonParaOne m-0'>N<sub>2</sub></h5>
                            <p className='commontitlefive m-0'>Nitrogen</p>
                        </div>
                    </Col>
                    <Col lg="2" md="3" sm="6" xs="6">
                        <div>
                            <h5 className='commonParaOne m-0'>C<sub>3</sub>H<sub>8</sub></h5>
                            <p className='commontitlefive m-0'>Propane</p>
                        </div>
                    </Col>
                    <Col lg="4" md="3" sm="6">
                        <div>
                            <h5 className='commonParaOne m-0'>Custom</h5>
                            <p className='commontitlefive m-0'>Natural & Other Gases</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* section-2 */}
            {/* section-3 */}
            <div className="background-colorOne">
                <Container className='py-5'>
                    <Row>
                        <Col md="6">
                            <h4 className='commontitleOne mb-3'>Our Meters Cover Many Applications</h4>
                            <p className='commonParaThree'>
                                We calibrate our meters using a variety of gases in order to match our customers needs.  Any gas that is not specifically on our list can, in most cases be substituted with another gas or mixture of gases.
                                <br /><br />
                                Gases on our list include, Air, Argon, Carbon Dioxide, Ethane, Ethylene, Helium, Hydrogen, Methane, Nitrogen, Propane.  Methane is used as a substitute gas for Natural Gas.  Air is used as a substitute gas for Oxygen.  Other mixes are determined based on customer gas composition.
                            </p>
                        </Col>
                        <Col md="6">
                            <img src={Img} alt="Gas canisters with gases we flow" />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section-3 */}
            {/* section-4 */}
                <TechnicalInfoFooter />
            {/* section-4 */}
        </>
    );
}

export default GasesWeFlow;
