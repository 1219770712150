import React,{ useState, useEffect } from 'react';
import useSwr from 'swr';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import fetcher from 'utils/fetcher';

function ResponseTimes() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const page = { responseTimes: true }

    var { data } = useSwr(`http://localhost:1337/api/me`, fetcher);
    useEffect(() => {
        if(data){
            setUserData(data);
        }
    }, [data]);

    const headerData = {
        firstname: userData.firstname,
        page: 'RESPONSE TIMES'
    }

    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar> </Sidebar></div>) : undefined}
                    <div className='common-main'>
                        <h2 className='p-3'>Response Times</h2>
                        <div className='bdr body p-3'>
                            <p>We know that when you email an online company, you don't know what to expect. Will they reply? How long will it take? Being part of the online community, we know the unfortunate reality: most businesses are not responsive to your emails or phone calls.
                            <br /><br />
                            EPI is not like other online companies.
                            </p>
                            <hr />
                            <h4>We reply in less than 24 hours! (weekends and holidays excluded)</h4>
                            <p>
                              EPI is different! We have a strong commitment to fast, personal communication. When you send us a message, you can count on a timely response.
                            </p>
                            <p>
                              Why do we want to make sure you know? We have two reasons. First, we want you to feel secure that the time you spend composing a message for us will get seen. You aren't wasting your time! Second, we know that with all the spam going around, your spam filter might catch a message of ours, or maybe we don't have your correct email address. We put a lot of time into providing good, thorough information to our customers, and it is a bummer for us when our emails don't get to you! Therefore, if you don't receive our timely reply, we want you to ask us about it. Never assume we didn't reply, because we do reply. Every time!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResponseTimes
