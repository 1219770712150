import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import productImage from './Images.js';
import{ Button } from "reactstrap";
import 'assets/css/Login.css';
import './productImages.css';

function ProductImages() {
  const history = useHistory();
  const { imageLink } = useParams();
  const [product, setProduct] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if(productImage && imageLink && productImage[imageLink]){
      setProduct(productImage[imageLink])
    } else {
      history.push('/');
    }
  }, [productImage, imageLink]);

  const productLink = (link) => {
    history.push(link)
  }

  return (
    <>
      <div className='login-container'>
        <img className="productImageGallery" src={product.image} />
        <Button
          className='inputButton'
          href="#"
          onClick={() => productLink(product.productLink)}
        >
          View Product
        </Button>
      </div>
    </>
  );
}

export default ProductImages;
