import React, { useState, useEffect } from "react";
import axios from "axios";
import useSwr from 'swr';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { Form, Button } from 'reactstrap';

import fetcher from 'utils/fetcher';

import 'assets/css/Login.css';
import logo from 'assets/img/logo1.png';

function SetNewPassword() {
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loginError, setLoginError] = useState(null);
  const [good, setGood] = useState(false);
  const [passwordNotMuch, setPasswordNotMuch] = useState(false);
  var { code } = useParams();
  const { data } = useSwr(`/api/me`, fetcher);

  useEffect( async () => {
    window.scrollTo(0, 0);
    await axios.delete(
      `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/sessions`,
      { withCredentials: true }
    )
    sessionStorage.setItem('ciuli', JSON.stringify(false));
  }, [data]);

  const onSubmit = async (data, e) => {

    if(data.password != data.confirm_password){
      setPasswordNotMuch(true);
      return;
    }

    let login_data = {
      password: data.password,
      code: code
    }

    console.log('User login data 1.0 =>', login_data);

    try {
      await axios.post(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/auth/forgotpassword/newcode`,
        login_data,
        { withCredentials: true }
      )
      setGood(true);
    } catch (e) {
      setLoginError(e.message);
    }

    e.target.reset();
  };

  return (
    <>
      <div className='login-container'>
          <div className='login-main shadow'>
              <div className='text-center mb-4'>
                  <img src={logo} alt="EPI Logo" />
              </div>
              <h5>Reset Password</h5>

              {/* <p className='pleaselog-text mb-4'>
                Please insert your email in the input below and we will send an email with the link to reset your password.
              </p> */}

              <Form onSubmit={handleSubmit(onSubmit)}>
                  {!good && 
                    <>
                      <input
                        type="password"
                        className="form-control mb-3"
                        name="passowrd"
                        placeholder="New password"
                        {...register("password", { required: true })}
                      />
                      <input
                        type="password"
                        className="form-control mb-3"
                        name="confirm_password"
                        placeholder="Confirm new password"
                        {...register("confirm_password", { required: true })}
                      />
                    </>
                  }

                  {errors.email && <span>Email</span>}
                  { loginError && <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>Error. Please try again</p>  }
                  { passwordNotMuch && <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>Error. Passwords did not much.</p>  }
                  { good && <p className='pleaselog-text mb-4' style={{ 'color': '#16C172' }}>Your password has been successfully updated!</p>  }

                  {!good && 
                    <Button
                      type="submit"
                      className='inputButton'
                    >
                      Reset Password
                    </Button>
                  }
              </Form>
              <a href="#" onClick={() => history.push('/login')}>
                <p className="account-text">Click Here to Log In</p>
              </a>
          </div>
      </div>
    </>
  );
}

export default SetNewPassword;
