import React, {useEffect} from 'react';
import '../../assets/css/Quotes.css';

function Pagination({postsPerPage, totalPosts, paginate, currentPage, setPageNumbers, pageNumbersCount}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  console.log("pagi =>", paginate);

  return(
    <>
      <nav aria-label="Quotes Pagination" class="qPagination">
        <ul class="pagination justify-content-center">
          <li className={currentPage === 1? 'page-item disabled': 'page-item'}>
            <a class="page-link" onClick={() => paginate(currentPage - 1)}>Previous</a>
          </li>

          {pageNumbers.map(number => (
            <li 
              key={number} 
              className={currentPage === number? 'page-item active' : 'page-item'}
              aria-current={currentPage === number && 'page'}
            >
              <a onClick={() => paginate(number)} onLoad={setPageNumbers(number)} className='page-link'>
                {number}
              </a>
            </li>
          ))}
          <li className={pageNumbersCount === currentPage? 'page-item disabled' : 'page-item'}>
            <a class="page-link" onClick={() => paginate(currentPage + 1)}>Next</a>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Pagination;