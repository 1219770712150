import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import videoimg from '../../assets/img/images/video-img.png'
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";


function HowToVideos() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
      <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>EPI – How-To Videos</title>
          <meta name="description" content="Have a look at all our support videos that are going to help you get the most out of our thermal flow meters. " />
          <link rel="canonical" href="https://epiflow.com/support/howtovideos" />
      </Helmet>
        <div className='background-colorOne pb-5'>
            {/* section-1 */}
            <div className='htv-section-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/support" tag={Link}>Support</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/support/howtovideos" tag={Link}>How-To-Videos</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>How-To Videos</h2>
                        <p className='cover-title-p'>Here you can view all of our support videos to help you get the most out of our meters.</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <div className='container'>
                <div className='text-center'>
                    <h3 className='commontitleTwo mt-5'>Coming Soon</h3>
                    {/* <h3 className='commontitleTwo mt-5'>Meter Setup</h3>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='shadow'>
                                <img src={videoimg} alt="" />
                            </div>
                            <p className='htv-card-title'>Installation of Inline Thread Meters</p>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='shadow'>
                                <img src={videoimg} alt="" />
                            </div>
                            <p className='htv-card-title'>Installation of Inline Thread Meters</p>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='shadow'>
                                <img src={videoimg} alt="" />
                            </div>
                            <p className='htv-card-title'>Installation of Inline Thread Meters</p>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* section-2 */}

            {/* <div className='container'>
                <hr />
            </div> */}

            {/* section-3 */}
            {/* <div className='container'>
                <div className='text-center'>
                    <h3 className='commontitleTwo mt-4'>Interface & Wiring</h3>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='shadow'>
                                <img src={videoimg} alt="" />
                            </div>
                            <p className='htv-card-title'>Quick Start AC/DC</p>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='shadow'>
                                <img src={videoimg} alt="" />
                            </div>
                            <p className='htv-card-title'>Navigating the Meter Interface</p>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='shadow'>
                                <img src={videoimg} alt="" />
                            </div>
                            <p className='htv-card-title'>Wiring Flow Signal & Temp Output</p>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* section-3 */}

            {/* <div className='container'>
                <hr />
            </div> */}

            {/* section-4 */}
            {/* <div className='container'>
                <div className='text-center'>
                    <h3 className='commontitleTwo mt-4'>Software Setup & Connection</h3>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='shadow'>
                                <img src={videoimg} alt="" />
                            </div>
                            <p className='htv-card-title'>EPICOM Software Setup & Connection</p>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='shadow'>
                                <img src={videoimg} alt="" />
                            </div>
                            <p className='htv-card-title'>EPICOM Software Setup & Connection</p>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='shadow'>
                                <img src={videoimg} alt="" />
                            </div>
                            <p className='htv-card-title'>EPICOM Software Setup & Connection</p>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* section-4 */}

            {/* <div className='container'>
                <hr />
            </div> */}
            {/* section-5 */}

            {/* <div className='container'>
                <div className='text-center'>
                    <h3 className='commontitleTwo mt-4'>Packing & Unpacking</h3>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='shadow'>
                                <img src={videoimg} alt="" />
                            </div>
                            <p className='htv-card-title'>Unpacking Your Meter</p>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='shadow'>
                                <img src={videoimg} alt="" />
                            </div>
                            <p className='htv-card-title'>Packing Your Meter for Service</p>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* section-5 */}
        </div>
        </>
    )
}

export default HowToVideos
