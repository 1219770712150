import React, { useState, useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";

function FlareGas() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [color, setColor] = useState('#095CF6');
    const handleBtn = () => {
        setColor('#fff')
    }
    const handleBtnOut = () => {
        setColor('#095CF6')
    }
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Flare Gas Applications</title>
            <meta name="description" content="Many industries utilize vent and flare gas, such as wastewater treatment, landfills, refining, oil & gas. That’s where EPI’s flow meters join in! " />
            <link rel="canonical" href="https://epiflow.com/whitepapers/flaregas" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner FlareGasSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/whitepapers" tag={Link}>White Papers</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/whitepapers/flaregas" tag={Link}>Flare Gas</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Flare Gas Applications</h2>
                        <p className='cover-title-p'>Everything you need to know about EPI.</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <Container className='py-5'>
                <div className='DownloadPDfbtn-div'>
                    <div className='position-relative d-inline'>
                        <button
                            className='button2'
                            onMouseOver={handleBtn}
                            onMouseOut={handleBtnOut}
                        >
                            Download as PDF
                        </button>
                        <FontAwesomeIcon
                            className='DownloadPDfbtn-icon'
                            style={{ color: color }}
                        />
                    </div>
                </div>
                <h4 className='commontitleTwo text-center mb-4'>
                    Measuring Flare Gas Flows in Large Stacks
                </h4>
                <p className='commonParaThree'>
                    Various industries will vent and flare gas, such as wastewater treatment, landfills, refining, oil & gas, etc.. The flare is burning off waste gases to protect
                    the environment and equipment. Thermal Mass Flow Meters are a proven way for measuring that the flare system is operating as expected. We have a variety of meters
                    that can do the job. Search our product line for insertion meters. Our Master–Touch™ product line would be a great choice for either our integral or remote
                    insertion probe meters.
                </p>
            </Container>
        </>
    );
}

export default FlareGas;
