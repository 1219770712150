import React from "react";

// reactstrap components
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip
} from "reactstrap";

import ProfibusBottom from "./ProfibusBottom";

function Profibus(){
  return (
    <>
      <hr />
      <ProfibusBottom />
    </>
  );
}

export default Profibus;
