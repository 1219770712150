import React, { useState } from 'react';
import axios from "axios";
import { useHistory } from 'react-router';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ShoppingCart from 'components/Customizer/ShoppingCart';

function SaveConfigModal({modal, toggle, config, position, counter, setCounter}) {
  const history = useHistory();
  const [configName, setConfigName] = useState(false);
  console.log('Show me config name 1.0 =>', configName);

  const saveSingleConfig = async () => {
    const meterCustomizedData = {
      series: config.series,
      model: config.model,
      total: config.total,
      state: config.state,
      config_type: 'saved',
      config_name: ''
    }

    if(configName){
      meterCustomizedData.config_name = configName;
    }

    console.log('Show me savedSingleConfig 1.0 =>', meterCustomizedData);

    try {
      const meter_config = await axios.put(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/quotes/${config._id}`,
        meterCustomizedData,
        { withCredentials: true }
      );

      ShoppingCart({
        'task':'updateSingleConfig',
        'data': {
          cart_location: position,
          meter_config: meter_config.data,
          _id: meter_config.data?._id
        }
      });

      createNewQuoteAndUpdateCartData(config, position);

      setCounter(counter + 1);
      console.log('Show me savedSingleConfig 1.1 =>', meter_config, meter_config.data?._id);
    } catch (e) {
      console.log('Show me db insert error', e.message);
    }
  }

  const createNewQuoteAndUpdateCartData = async (configuration, position) => {
    const meterCustomizedData = {
      series: configuration.series,
      model: configuration.model,
      state: configuration.state,
      total: configuration.total,
      config_type: 'incart'
    }

    console.log('Show me new save 2', meterCustomizedData, configuration);

    try {
      const savedConfig = await axios.post(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/quotes`,
        meterCustomizedData,
        { withCredentials: true }
      );

      savedConfig.data.config_type = 'saved';
      savedConfig.data.quantity = 1;
      ShoppingCart({
        'task':'updateSingleConfig',
        'data': {
          cart_location: position,
          meter_config: savedConfig.data,
          _id: savedConfig.data?._id
        }
      });

      history.push('/portal/cart')
    } catch (e) {
      console.log('Show me db insert error', e.message);
    }
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}><b class="text-center">Name Configuration</b></ModalHeader>
        <ModalBody>
          <p>Please enter a name for your saved configuration; being descriptive will help you find it later if you would like to edit the configuration or add it into your cart.</p>
          <input
            type="text"
            class="epiInputSize form-control"
            onChange={(e) => setConfigName(e.target.value)}
          >
          </input>
        </ModalBody>
        <ModalFooter>
          <div class="row w-100 m-0">
            <div class="d-flex justify-content-end p-0">
              <Button className="btn btn-outline mr-2" color="secondary" onClick={toggle}>
                Cancel
              </Button>
                <Button
                  disabled={!configName}
                  className="btn btn-gradient"
                  color="primary"
                  onClick={saveSingleConfig}
                >
                  Save
                </Button>{' '}
              </div>
            </div>
          </ModalFooter>
      </Modal>
    </div>
  );
}

export default SaveConfigModal;
