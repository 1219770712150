import React, { useState, useEffect } from 'react';
import HipporelloEmbed from "@hipporello/embed"
import useSwr from 'swr';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import './ServiceRequest.css'
import '../../assets/css/style.css'
import fetcher from 'utils/fetcher';

// import RmaForms from 'views/RmaForms/RmaForms';=
import { Modal, ModalBody } from "reactstrap";
import { Col, Row } from 'reactstrap';

function ServiceRequest() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [modalLarge, setModalLarge] = React.useState(false);
    const [userData, setUserData] = useState([]);
    const page = { ServiceRequest: true }

    const { data } = useSwr(`${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/me`, fetcher);
    useEffect(() => {
        if(data){
            setUserData(data);
        }
    }, [data]);

    console.log('Show me user data 1.0.1 =>', data);

    const headerData = {
        firstname: userData.firstname,
        page: 'SERVICE REQUESTS'
    }
    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar> </Sidebar></div>) : undefined}
                    <div className='common-main'>
                        <div className='shadow'>
                            <div className='position-relative'>
                                <h2 className='p-3'>Service Requests</h2>
                            </div>

                            <div className='quotes body p-3'>
                                <p>If you are looking to open a service request, please fill out the form below.
                                </p>
                                <hr/>
                                <HipporelloEmbed formId="3692b3933f034e8699511591be42d01c"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={modalLarge}
                className="modal-lg"
                modalClassName="bd-example-modal-lg"
                toggle={() => setModalLarge(false)}
            >
                <div className="modal-header">
                    <button
                        aria-label="Close"
                        className="close"
                        type="button"
                        onClick={() => setModalLarge(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <ModalBody>
                    <h2 className='p-3 text-center'>RMA Confirmed: Signature Required</h2>
                    <div>
                        <div className='newproduct body p-3'>
                            <p>Your RMA request has been confirmed! Please indicate your agreement to the terms below and provide your e-signature to
                                confirm your RMA; failure to do so may delay the RMA. Once signed, you may ship the meter to us using the provided
                                instrucAons.
                            </p>
                            <hr />
                            <form action="">


                                <div>
                                    <label for="" className="form-label">PLEASE CONFIRM EACH OF THE FOLLOWING:</label>
                                    <div className='check-main d-flex ml-4'>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" for="flexCheckChecked">
                                            <b>Verify that the meter and all included items are decontaminated.</b>
                                        </label>
                                    </div>
                                    <div className='check-main d-flex ml-4'>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" for="flexCheckChecked">
                                            <b>Make sure that the entire meter is included (unless otherwise specified).</b>
                                        </label>
                                    </div>
                                    <div className='check-main d-flex ml-4'>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" for="flexCheckChecked">
                                            <b>All serial numbers must match, including the sensor, electronics,probe/flow body.</b>
                                        </label>
                                    </div>
                                    <div className='check-main d-flex ml-4'>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" for="flexCheckChecked">
                                            <b>Please make sure the RMA number is included on the shipping labels and any other documents associated with the unit.</b>
                                        </label>
                                    </div>
                                    <div className='check-main d-flex ml-4'>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" for="flexCheckChecked">
                                            <b>Ensure the meter is protected. We recommend using a piece of PVC over the sensor/probe and secure to prevent any
                                                damage during transportation.
                                            </b>
                                        </label>
                                    </div>
                                    <div className='check-main d-flex ml-4'>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" for="flexCheckChecked">
                                            <b>Inline flow meters should not be disassembled for shipping, please leave the sensor securely fixed in place inside the
                                                flow body.</b>
                                        </label>
                                    </div>
                                    <div className='check-main d-flex ml-4'>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" for="flexCheckChecked">
                                            <b>Ensure the meter is packaged with foam or bubble wrap to avoid meter damage.</b>
                                        </label>
                                    </div>
                                    <div className='check-main d-flex ml-4'>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" for="flexCheckChecked">
                                            <b>Ensure the meter is packaged with foam or bubble wrap to avoid meter damage.</b>
                                        </label>
                                    </div>
                                    <div className='check-main d-flex ml-4'>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" for="flexCheckChecked">
                                            <b>All meters should be shipped to: Eldridge Products, Inc. 465 Reservation Rd, Marina CA 93933 Attn: Service
                                                department [#19287384]</b>
                                        </label>
                                    </div>
                                </div>
                                <label for="" className="form-label mt-4">HAS THE METER BEEN EXPOSED TO ANY HAZARDOUS MATERIALS OR SUBSTANCES? </label> <br />
                                <div className='ms-4'>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input rf-form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                        <label className="form-check-label rf-form-check-label" for="inlineRadio1">No</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input rf-form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                        <label className="form-check-label rf-form-check-label" for="inlineRadio1">Yes</label>
                                    </div>
                                </div>
                                <div class="form-group mt-3">
                                    <label for="exampleFormControlTextarea1">PLEASE SPECIFY THE HAZARDOUS MATERIALS OR SUBSTANCES</label>
                                    <textarea class="form-control w-100" id="exampleFormControlTextarea1" rows="3" placeholder='Specify the details of the hazards.'></textarea>
                                </div>
                                <Row>
                                    <Col md="8" sm="12">
                                    <div class="form-group">
                                            <label for="">SIGN YOUR NAME BELOW </label>
                                            <input type="text" className="form-control w-100 sign-in-Name-input" id="" placeholder="Enter Full Name" />
                                        </div>
                                    </Col>
                                    <Col md="4" sm="12" className='d-flex align-items-end'>
                                    <button className='button1 rf-button'>Complete Signature</button>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </div>
                </ModalBody>
                {/* </div> */}
            </Modal>
        </div>
    )
}

export default ServiceRequest
