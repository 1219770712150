import React from 'react'
import storage from 'utils/firebase';
import { DropzoneArea } from 'material-ui-dropzone'
import { customAlphabet } from "nanoid";

const DragDropFile = ({setFiles, setUploadedFiles, uploadedFiles, setUploading}) => {
  const nanoid = customAlphabet("0123456789", 10);
  const fileNanoid = nanoid();

  // const upload = async (files) =>{
  //   const uploaded = [];
  //   for (let fs = 0; fs < files.length; fs++) {
  //     setUploading(true)
  //     try{
  //       const filename_ = files[fs].name;
  //       const filename  = `${fileNanoid}${filename_}`;
  //       const ref = storage.ref(`/files/${filename}`);
  //       const uploadTask = ref.put(files[fs]);
        
  //       uploadTask.on("state_changed", console.log, console.error, () => {
  //         ref.getDownloadURL().then((url) => {
  //           uploaded.push({
  //             filename: filename,
  //             url: url
  //           })
  //         });
  //       });
  //     } catch (e) {
  //       console.log('Show me bug tracking info 1.1 =>', e);
  //     }

  //     if(fs == files.length){
  //       setUploadedFiles(uploaded);
  //       setUploading(false);
  //     }
  //   }
  // }

  return(
      <>
          <div className='drag-drop-main text-center'>
              <div className='drag-drop-field'>
                  <a href="/" className='button2'>Click to Browse</a>
                  <p className='commonParaThree mt-3'>or <br /> drag files here</p>
              </div>
              <DropzoneArea
                  onChange={
                    (e) => setFiles({ files: e})
                  }
              />
          </div>
      </>
  );
}

export default DragDropFile;