import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';

// reactstrap components
import {
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  UncontrolledTooltip
} from "reactstrap";

function InsertionPipe({ handleChange, state, checkState }){
  const pipe_od = checkState(state.pipe_infornation_insertion.pipe_height, 'height') &&
  state.pipe_infornation_insertion.pipe_height.valuesObj.height;

  const pipe_id = checkState(state.pipe_infornation_insertion.pipe_width, 'width') &&
  state.pipe_infornation_insertion.pipe_width.valuesObj.width;

  const fitting_height = checkState(state.pipe_infornation_insertion.fitting_height, 'height') &&
  state.pipe_infornation_insertion.fitting_height.valuesObj.height;

  console.log('Show me pipe fitting 1.0 =>', fitting_height)

  if(pipe_od >= 0 || pipe_id >= 0){
    var pipe_wall = (pipe_od - pipe_id) / 2;
  } else {
    var pipe_wall = 0;
  }

  var fitting_height_ = 1.8;
  // if(fitting_height){
  //   var fitting_height_ = fitting_height;
  // }

  console.log('Show me pipe fitting 1.1 =>', fitting_height_)

  Array.prototype.removeWithValue = function() {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
  };

  const MakeChangeText = (data) => {
    data.option_value = data.option.target.value;
    handleChange(data);
  }

  const Calc2 = () => {
    if(pipe_id > 0 && pipe_od > 0){
      if(pipe_id > pipe_od){
        return(
          <>
            <Row className="text-center">
              <span className="customizerInputTitleProcessParameters" style={{'color':'red'}}>Error: pipe id can not be larger that pipe od</span>
            </Row>
          </>
        );
      }
      
      const pipe_info = (pipe_id / 2) * 0.243 + pipe_wall + 1;
      const depth = pipe_info + fitting_height_;
      return(
        <>
          <Row className="calcResultsRow">
            <Col className="processParametterCols"></Col>
            <Col>
              <span className="customizerInputTitleProcessParameters">Point of Average Flow</span>
              <p className="text-centerl calcInfo">{pipe_info.toFixed(2)}</p>
            </Col>
            <Col>
              <span className="customizerInputTitleProcessParameters">Recommended Insertion Depth From Top of Fitting</span>
              <p className="text-centerl calcResult">{depth.toFixed(2)}</p>
            </Col>
          </Row>
        </>
      );
    } else {
      return(<></>);
    }
  }
  Calc2();

  return (
    <>
      <Row>
        <Col className="processParametterCols">
          <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitle">Pipe (OD)</span>
              <Button className="questionToolTip" id="DuctRoundOuterDiameter" size="sm">
                  ?
              </Button>{` `}
              <UncontrolledTooltip placement="top" target="DuctRoundOuterDiameter" delay={0}>
                Outer Diameter Information needed
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlInput1"
              placeholder="Pipe Height"
              type="number"
              defaultValue={
                checkState(state.pipe_infornation_insertion.pipe_height, 'height') &&
                state.pipe_infornation_insertion.pipe_height.valuesObj.height
              }
              onChange={ (e) => MakeChangeText({
                section: 'pipe_infornation_insertion',
                type: 'pipe_height',
                values: ['height'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            ></Input>
          </FormGroup>
        </Col>

        <Col className="processParametterCols">
          <FormGroup>
            <label htmlFor="exampleFormControlSelect1">
              <span className="customizerInputTitle">Pipe (ID)</span>
              <Button className="questionToolTip" id="DuctRoundInnerDiameter" size="sm">
                  ?
              </Button>{` `}
              <UncontrolledTooltip placement="top" target="DuctRoundInnerDiameter" delay={0}>
                Inner Diameter Information needed
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlInput1"
              placeholder="Pipe Width"
              type="number"
              defaultValue={
                checkState(state.pipe_infornation_insertion.pipe_width, 'width') &&
                state.pipe_infornation_insertion.pipe_width.valuesObj.width
              }
              onChange={ (e) => MakeChangeText({
                section: 'pipe_infornation_insertion',
                type: 'pipe_width',
                values: ['width'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            ></Input>
          </FormGroup>
        </Col>

        <Col className="processParametterCols">
          <FormGroup>
            <label htmlFor="exampleFormControlInput1">
              <span className="customizerInputTitle">Pipe Wall Thickness</span>
              <Button className="questionToolTip" id="DuctRoundWallThickness" size="sm">
                  ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="DuctRoundWallThickness" delay={0}>
                Pipe Wall Thickness Information needed
              </UncontrolledTooltip>
            </label>
            <Input
              disabled
              style={{ 'border-radius': '6px', 'background-color': '#EBF2FF'}}
              className="epiInputSize"
              id="exampleFormControlInput1"
              placeholder="Enter Pipe Wall Thickness"
              value={pipe_wall.toFixed(2)}
              type="number"
              defaultValue={
                checkState(state.pipe_infornation_insertion.pipe_wall, 'wall') &&
                state.pipe_infornation_insertion.pipe_wall.valuesObj.wall
              }
              onChange={ (e) => MakeChangeText({
                section: 'pipe_infornation_insertion',
                type: 'pipe_wall',
                values: ['wall'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            ></Input>
          </FormGroup>
        </Col>
      </Row>

      <Row style={{ 'margin-top': '20px'}}>
        <Col className="processParametterCols">
          <FormGroup>
            <label htmlFor="exampleFormControlSelect1" style={{ 'margin-bottom': '14px', 'margin-top': '10px'}}>
              <span className="customizerInputTitle">Fitting Height</span>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlInput1"
              placeholder="Fitting Height"
              type="number"
              defaultValue={
                checkState(state.pipe_infornation_insertion.fitting_height, 'height') &&
                state.pipe_infornation_insertion.fitting_height.valuesObj.height
              }
              onChange={ (e) => MakeChangeText({
                section: 'pipe_infornation_insertion',
                type: 'fitting_height',
                values: ['height'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            ></Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <label htmlFor="exampleFormControlInput1">
              <span className="customizerInputTitle">Pipe Size and Standard</span>
              <Button className="questionToolTip" id="DuctRoundWallThickness" size="sm">
                  ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="DuctRoundWallThickness" delay={0}>
                Pipe Wall Thickness Information needed
              </UncontrolledTooltip>
            </label>
            <Input
              className="epiInputSize"
              id="exampleFormControlInput1"
              placeholder="Enter Pipe Size and Standard"
              type="text"
              defaultValue={
                checkState(state.pipe_infornation_insertion.pipe_size, 'size') &&
                state.pipe_infornation_insertion.pipe_size.valuesObj.size
              }
              onChange={ (e) => MakeChangeText({
                section: 'pipe_infornation_insertion',
                type: 'pipe_size',
                values: ['size'],
                price_effect: false,
                text_input: true,
                option: e
              }, e)}
            ></Input>
          </FormGroup>
        </Col>
      </Row>
      <Col>
        <Calc2 />
      </Col>
    </>
  );
}

export default InsertionPipe;
