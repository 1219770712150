import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";


import TechnicalInfoFooter from 'components/Footers/TechnicalInfoFooter';

function FlueGasApplication() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Flue Gas Measurement</title>
            <meta name="description" content="See how EPI’s thermal flow meters are used to accurately measure flue gas in many different industrial process. Check out our article!" />
            <link rel="canonical" href="https://epiflow.com/industrialapplications/flue" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner FullGuide-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/industrialapplications" tag={Link}>Industrial Applications</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/flue" tag={Link}>Flue Gas</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Flue Gas</h2>
                        <p className='cover-title-p'>
                            See how our flow meters are used to measure flue gas in many different industrial processes.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <div className='DownloadPDfbtn-div'>
                        <div className='position-relative d-inline'>
                            <audio src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fapplications%2FFlue%20Gas%20.mp3?alt=media&token=f9fb4353-f22f-428d-a24b-715320f6b89f" controls />
                        </div>
                    </div>
                    <h4 className='commontitleTwo text-center mb-2'>
                        Flue Gas Monitoring with Thermal Mass Flow Meters
                    </h4>
                    <p className='commonParaThree'>
                        Flue gas is produced anywhere a non-electric furnace or boiler is used to generate heat. That includes
                        the furnaces and chimneys in many of our homes, but the source of the vast majority of flue gasses – and
                        thus, the source of greatest concern – is industrial processes. The sheer volume of flue gas released into
                        the atmosphere from factories and plants means that carefully monitoring it is crucial to maintaining the
                        health of the environment and the people who live around dense industrial areas. Many companies turn to
                        thermal mass flow meters to perform that monitoring, as their accuracy, cost-effectiveness, and ease of
                        use make them ideal solutions for measuring flue gas flow and staying on the right side of environmental
                        regulations, both in the United States and around the globe.
                    </p>
                    <br /><br />

                    <h4 className='commontitlefive mb-2'>
                        What is Flue Gas?
                    </h4>
                    <p className='commonParaThree'>
                        Flue gas is the exhaust from industrial combustion that is vented to the outside air through a smokestack
                        – or flue. Flue gas is produced whenever a number of common industrial inputs and fuels are burned,
                        including natural gas, coal, oil, and wood. The average person probably isn’t familiar with the term flue
                        gas, but everyone has seen it wafting out of plants and rising up into the air or settling on a cold day.
                        Because most industrial processes use standard air as an oxygen source, flue gasses are mostly
                        composed of the nitrogen left over after combustion. However, they also include trace amounts of other
                        gasses and materials based on whatever is being burnt, some of which are harmful to humans and the
                        environment, like soot, ash, nitrogen oxide, and sulfur oxide.
                    </p>
                    <br /><br />

                    <h4 className='commontitlefive mb-2'>
                        How Does Flue Gas Impact the Environment?
                    </h4>
                    <p className='commonParaThree'>
                        Flue gasses have a significant impact on the world around us. Carbon dioxide content can contribute to
                        rising carbon levels, and the high nitrogen content can lead to “nutrient pollution,” in which excess
                        nitrogen causes everything from elevated ozone in the atmosphere to explosions in algae blooms that
                        can kill off fish and even make humans sick. However, the most harmful part of flue gas is the particulates
                        and pollutants they pump into the atmosphere and, in turn, straight into our lungs. Those pollutants –
                        major contributors to smog – can aggravate respiratory conditions and lung irritation severe enough that
                        some municipalities are forced to warn high-risk people to stay indoors when air quality gets too low.
                        <br /><br />

                        The damage flue gasses can cause means that, in the United States, they generally need to be
                        monitored under EPA rule 40 CFR Part 75. Clean air regulations in many countries also require flue
                        gasses to be treated to ensure that the quantity of pollutants released into the air is minimized as much
                        as possible.
                    </p>
                    <br /><br />

                    <h4 className='commontitlefive mb-2'>
                        How is Flue Gas Treated?
                    </h4>
                    <p className='commonParaThree'>
                        The goal of flue gas treatment is to allow combustion emissions to be vented while removing as much of
                        the most harmful components as possible. While it’s impossible to clean flue gasses completely,
                        especially considering the enormous amount of nitrogen they contain, <a target="_blank" href="https://www.britannica.com/technology/flue-gas-treatment">some pollutants can be reduced by
                        as much as 90% with the right technologies.</a>
                    </p>
                    <br /><br />

                    <h4 className='commontitlefive mb-2'>
                        Filtering
                    </h4>
                    <p className='commonParaThree'>
                        The first step in flue gas treatment is often filtering to remove the largest particles from the exhaust flow.
                        In some cases, mechanical cloth filters are used, but more often, particles are filtered out using an
                        electrostatic precipitator. Electrostatic precipitators work by imparting a negative charge on particles being
                        carried in a gas and then sending them past a set of positively charged metal plates, which pull the
                        charged particles out of the gas flow. Using this type of filtering, plants can remove the vast majority of
                        smoke, soot, and ash particles from flue gasses.
                    </p>
                    <br /><br />

                    <h4 className='commontitleTwo mb-2'>
                        Denitrification
                    </h4>
                    <p className='commonParaThree'>
                        Once flue gas has been filtered, harmful nitrogen oxide and sulfur dioxide are still present. To reduce or
                        eliminate the former, flue gas is passed through a denitrification reactor that uses a chemical reaction to
                        turn nitrogen oxide into less harmful nitrogen. The denitrification reactor is primed with a catalyst – most
                        often ammonia – which reacts with nitrogen oxide and <a target="_blank" href="https://iopscience.iop.org/article/10.1088/1755-1315/100/1/012033/pdf#:~:text=The%20technology%20of%20denitrification%20after%20combustion%20is%20also%20called%20flue%20gas%20denitrification.&text=The%20SCR%2FSNCR%20combined%20flue,the%20catalytic%20reduction%20more%20complete.">reduces it to water and pure nitrogen gas</a>, which
                        then flows on to the final stage of treatment.
                    </p>

                    <h4 className='commontitlefive mb-2'>
                        Sulfur Dioxide Scrubbing
                    </h4>
                    <p className='commonParaThree'>
                        The final step in treatment is to remove the remaining sulfur dioxide from the flue gas using a scrubber
                        system. Scrubbers work by injecting an alkaline substance into the flue gas, either in a liquid, semi-dry, or
                        powdered format. The <a target="_blank" href="https://www3.epa.gov/ttncatc1/dir1/ffdg.pdf">calcium or sodium-based substance reacts with the sulfur dioxide</a>, absorbing it and
                        producing solid calcium or sodium sulfate, which can be removed from the system prior to the gas finally
                        flowing out of the treatment system and into the atmosphere.
                    </p>

                    <h4 className='commontitlefive text-center mb-2'>
                        Measuring Flue Gas with a Thermal Mass Flow Meter
                    </h4>
                    <p className='commonParaThree'>
                        Despite the effectiveness of treatment, flue gas vented to the atmosphere still contains trace amounts of
                        sulfur dioxide and nitrogen oxide, and significant amounts of nitrogen. As a result, the EPA requires
                        plants in many industries to constantly monitor their flue gas output. Thermal mass flow meters are the
                        ideal tools for that job because they offer the accuracy, cost-effectiveness, and ease of use required to
                        ensure plants can keep close tabs on their flue gas emissions without any interruptions to production or
                        significant impacts on the bottom line.
                    </p>

                    <h4 className='commontitlefive mb-2'>
                        Thermal Mass Flow Meters are Extremely Accurate
                    </h4>
                    <p className='commonParaThree'>
                        Thermal mass flow meters offer accuracy to within 1% across their entire operational range, requiring no
                        corrections for gas temperature or pressure. Equally importantly, their high turndown ratios offer the
                        rangeability necessary to accurately measure low-flow gasses like flue gas. Insertion-style flow meters
                        can also easily be installed in an array in larger flues in order to maximize accuracy across the entire
                        cross section and ensure ongoing compliance with all environmental regulations.
                    </p>
                    <br /><br />

                    <h4 className='commontitleTwo text-center mb-2'>
                        Thermal Mass Flow Meters are Easy to Install
                    </h4>
                    <p className='commonParaThree'>
                        Industrial flues have large diameters that can make the installation of certain flow metering systems
                        difficult. Insertion-style thermal mass flow meters are ideal solutions because they can be easily mounted
                        using simple ports, allowing them to be installed effectively anywhere along a stack or duct. That’s
                        important in low flow, high volume environments like industrial flues, where an array of sensors is often
                        crucial to getting an accurate measurement.
                    </p>
                    <br /><br />

                    <h4 className='commontitleTwo mb-2'>
                        Thermal Mass Flow Meters are Cost-Effective
                    </h4>
                    <p className='commonParaThree'>
                        Thermal mass flow meters offer a number of cost benefits. First and foremost, the systems themselves
                        are extremely affordable – a major upside when installing meter arrays in multiple flues. Beyond the initial
                        cost, the simple design of thermal mass flow meters – with zero moving parts – provides extremely high
                        reliability, extremely low downtime, and easy and infrequent maintenance. Combined, those factors make
                        flue gas monitoring with thermal mass flow meters the most accurate and the most economical option.
                    </p>
                    <br /><br />

                    <h4 className='commontitleTwo text-center mb-2'>
                        Monitor Your Flue Gas Emissions More Accurately with EPI Thermal Mass Flow Meters
                    </h4>
                    <p className='commonParaThree'>
                        Eldridge Products Inc. has been a leading manufacturer of thermal mass flow meters for over thirty years.
                        The quality and accuracy of our thermal mass flow meters and the unmatched knowledge and experience
                        of our team have allowed us to solve flue gas and other gas metering problems for clients in a wide
                        variety of industries spanning six continents.
                        <br /><br />
                        We’d love to speak with you about your flue gas monitoring needs, and, no matter how complex they may
                        be, we’re certain our insertion-style thermal mass flow meters and advanced control software can offer
                        you the performance, ease of use, and reliability necessary to get the job done.
                        <br /><br />
                        <a href="/contactus">click here to reach out to a member of the EPI team today.</a>
                    </p>
                </Container>
            </div>
        </>
    )
}

export default FlueGasApplication
