const pipe_length = {
  '36': {
    desc: 'Inline, 1/4" OD x 6" Long (MNPT)'
  },
  '49': {
    desc: 'Inline, 3/8" OD x 6" Long (MNPT)'
  },
  '59': {
    desc: 'Inline, 1/2" OD x 7" Long (MNPT)'
  },
  '69': {
    desc: 'Inline, 3/4" OD x 7" Long (MNPT)'
  },
  '89': {
    desc: 'Inline, 1" OD x 8" Long (MNPT)'
  },
  '10': {
    desc: 'Inline, 1-1/4" OD x 10" Long (MNPT)'
  },
  '12': {
    desc: 'Inline, 1-1/2" OD x 14" Long (MNPT)'
  },
  '16': {
    desc: 'Inline, 2" OD x 14" Long (MNPT)'
  },
  '20': {
    desc: 'Inline, 2-1/2" OD x 14" Long (MNPT)'
  },
  '24': {
    desc: 'Inline, 3" OD x 14" Long (150# Flanged)'
  },
  '32': {
    desc: 'Inline, 4" OD x 14" Long (150# Flanged)'
  }
};

export default [
  {
    model_number: {
      electronics:{
        '80': {
          desc: 'Remote Electronics, Inline up to 1"'
        },
        '81': {
          desc: 'Remote Electronics, Inline > 1"'
        },
        '86': {
          desc: 'Integral Electronics, Inline up to 1"'
        },
        '87': {
          desc: 'Integral Electronics, Inline > 1"'
        }
      },
      pipe_length: {
        ...pipe_length
      },
      series: {
        MP: {
          desc: 'Explosion Proof Flow Transmitter Enclosure'
        },
        MPNH: {
          desc: 'General Purpose Flow Transmitter Enclosure'
        }
      }
    },
    transmitter: {
      INT: {
        desc: 'Integral Electronics (8600-8700)'
      },
      REM: {
        desc: 'Remote electronics enclosure for use in Ordinary Locations ONLY; Certified to CSA/CUS Standards: Class 2252-03 & 2252-83 for Process Control Equipment in Ordinary Locations Only; CSA Enclosures Type 4X, IP66 (8000-8100)'
      },
      ACEX: {
        desc: 'Explosion proof remote electronics enclosure with AC input power (8000-8100 MP Only)'
      },
      DCEX: {
        desc: 'Explosion proof remote electronics enclosure with DC input power (8000-8100 MP Only)'
      }
    },
    sensor_material: {
      sss: {
        desc: '316 Stainless Steel (Gas Temp. up to 400°F/204°C)'
      },
      has: {
        desc: 'C276 Hastelloy (Gas Temp. up to 400°F/204°C)'
      },
      ssi: {
        desc: '316 Stainless Steel Intermediate Temperature (Gas Temp. up to 600°F/315°C)'
      },
      vcr: {
        desc: '316 Stainless Steel 1" Thread VCR Sensor (Gas Temp. up to 400°F/204°C)'
      }
    },
    sensor_shield: {
      SSSAR: {
        desc: ''
      },
      SSSPS: {
        desc: ''
      }
    },
    display: {
      '000': {
        desc: 'No Display'
      },
      '133': {
        desc: 'Display'
      }
    },
    input_power: {
      DC24: {
        desc: '24 VDC'
      },
      AC115: {
        desc: '115 VAC'
      },
      AC230: {
        desc: '230 VAC'
      }
    },
    gas_temp: {
      MW050: {
        desc: 'Up to 50°C/122°F'
      },
      MW075: {
        desc: 'Up to 75°C/167°F'
      },
      MW100: {
        desc: 'Up to 100°C/212°F'
      },
      MW125: {
        desc: 'Up to 125°C/257°F'
      },
      MW150: {
        desc: 'Up to 150°C/302°F'
      },
      MW175: {
        desc: 'Up to 175°C/347°F'
      },
      MW200: {
        desc: 'Up to 200°C/392°F'
      },
      MW250: {
        desc: 'Up to 250°C/482°F (Only available with SSI Sensor)'
      },
      MW300: {
        desc: 'Up to 300°C/572°F (Only available with SSI Sensor)'
      }
    },
    pipe_standard: {
      '_comment': 'This is configuration',
      S40: {
        desc: 'Schedule 40 Pipe. Consult factory for non-sch40 piping.'
      },
      pipe_length: {
        ...pipe_length
      },
      XX: {
        "_comment": 'Value from model_number.pos1b'
      },
      MT: {
        desc: 'MNPT Ends'
      },
      BE: {
        desc: 'Butt Ends'
      },
      FL15XX: {
        "_comment": 'XX = Value from model_number.pos1b',
        desc: '#150 ANSI RF Flange End'
      },
      FL30XX: {
        "_comment": 'XX = Value from model_number.pos1b',
        desc: '#300 ANSI RF Flange End'
      },
      NZL: {
        desc: 'Flow nozzle for low velocity applications (For 1/4", 3/8" 1/2" OD flow sections only)'
      },
      P2: {
        desc: 'Two Flow Conditioning Screens (Flow sections > 1/2" OD only)'
      },
      NO: {
        desc: 'No Obstructions (For high velocity applications or back pressure concerns)'
      }
    },
    ranges: {
      '1RC': {
        desc: '1 Gas Range (Standard)'
      },
      '2RC': {
        desc: '2 separate full scale flow ranges (Same Gas & Pressure)'
      },
      '3RC': {
        desc: '3 separate full scale flow ranges (Same Gas & Pressure)'
      },
      '4RC': {
        desc: '4 separate full scale flow ranges (Same Gas & Pressure)'
      },
      '2RG': {
        desc: '2 separate full scale flow ranges (Different Gas or Pressure)'
      },
      '3RG': {
        desc: '3 separate full scale flow ranges (Different Gas or Pressure)'
      },
      '4RG': {
        desc: '4 separate full scale flow ranges (Different Gas or Pressure)'
      }
    },
    gas: {
      AIR: {
        desc: 'Air'
      },
      AR: {
        desc: 'Argon'
      },
      CL2: {
        desc: 'Chlorine (Argon used as closest substitute)'
      },
      BIOGAS: {
        desc: 'Biogas (CH4 & CO2 as defined by end user)'
      },
      Landfill_Gas: {
        desc: 'Need discription of Landfill_Gas'
      },
      BFG: {
        desc: 'Blast Furnace Gas (Composition as defined by end user)'
      },
      CO: {
        desc: 'Carbon Monoxide (Air used as closest substitute)'
      },
      CO2: {
        desc: 'Carbon Dioxide'
      },
      CH4: {
        desc: 'Methane'
      },
      C2H4: {
        desc: 'Ethylene'
      },
      C2H6: {
        desc: 'Ethane'
      },
      C3H8: {
        desc: 'Propane'
      },
      Liquid_Propane_Gas: {
        desc: 'Need discription of Liquid_Propane_Gas'
      },
      C4H10: {
        desc: 'Butane'
      },
      C6H6: {
        desc: 'Benzene (Propane used as closest substitute)'
      },
      FLUE: {
        desc: 'Flue Gas (Composition as defined by end user)'
      },
      HE: {
        desc: 'Helium'
      },
      H2: {
        desc: 'Hydrogen'
      },
      H2_N2: {
        desc: 'Hydrogen & Nitrogen Mix or Methane Nitrogen Mix (as defined by end user)'
      },
      CH4_HE: {
        desc: 'Methane & Helium Mix (as defined by end user)'
      },
      FLARE: {
        desc: 'Flare Gas'
      },
      MIX: {
        desc: 'Custom Gas Mix (Composition as defined by end user)'
      },
      SNGPL_Gas: {
        desc: 'Need description of SNGPL_Gas'
      },
      SSGCL_Gas: {
        desc: 'Need description of SSGCL_Gas'
      },
      MARI_Gas: {
        desc: 'Need description of MARI_Gas'
      },
      NG: {
        desc: 'Natural Gas'
      },
      N2: {
        desc: 'Nitrogen'
      },
      NH3: {
        desc: 'Ammonia (Methane used as closest substitute) '
      },
      '02': {
        desc: 'Oxygen'
      },
      '03': {
        desc: 'Ozone (Air used as closest substitute)'
      }
    },
    certifications_approvals: {
      NA: {
        desc: 'No Agency Certifications (CE Only)'
      },
      'CSA/CUS': {
        desc: 'Certified to CSA/CUS Standards: Class 2252-03 & 2252-83 for Process Control Equipment in Ordinary Locations Only (MPNH Model Only)'
      },
      comment: {
        "_comment": 'OPTIONS BELOW FOR MP MODELS ONLY (for Flow Element/Flow Transmitter; Approved for remote electronics only with remote ACEX or DCEX feature; For use in hazardous area locations; Class I Division 1, Group B, C, D; Class II Group E, F, G; Class III; Enclosure Type 4X; Class I Zone I; AEx d IIB+H2 IP66; Ex d IIB+H2 IP66; T2; Ta = 0°C to 50°C)',
      },
      CT2: {
        desc: 'CSA/CUS T2 (300°C) Approved Instrument'
      },
      CT3: {
        desc: 'CSA/CUS T3 (200°C) Approved Instrument'
      },
      CT4: {
        desc: 'CSA/CUS T4 (135°C) Approved Instrument'
      },
      IT2: {
        desc: 'IECEx T2 (300°C) Approved Instrument'
      },
      IT3: {
        desc: 'IECEx T3 (200°C) Approved Instrument'
      },
      IT4: {
        desc: 'IECEx T4 (135°C) Approved Instrument'
      },
      AT2: {
        desc: 'ATEX T2 (300°C) Approved Instrument'
      },
      AT3: {
        desc: 'ATEX T3 (200°C) Approved Instrument'
      },
      AT4: {
        desc: 'ATEX T4 (135°C) Approved Instrument'
      },
      KT2: {
        desc: 'KOSHA T2 (300°C) Approved Instrument'
      },
      KT3: {
        desc: 'KOSHA T3 (200°C) Approved Instrument'
      },
      KT4: {
        desc: 'KOSHA T4 (135°C) Approved Instrument'
      }
    },
    communications_options_2: {
      MB: {
        desc: 'RS485 Modbus RTU; Read and Write (Standard)'
      },
      MR: {
        desc: 'Multi Range'
      },
      BN: {
        desc: 'BACnet MS/TP; Read only '
      },
      HT: {
        desc: 'HART communications module; Read only'
      },
      PB: {
        desc: 'Profibus DP communications module; Read only'
      }
    }
  }
]
