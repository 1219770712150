import React from 'react'

export default function ProcessParameters({state, getValue, checkState}) {
    if(state){
        return(
          <div class="row">
            <div class="col-12">
              <br />
              <h5><b>Process Parameters</b></h5>
              <table class="table qTable">
                <thead>
                  <tr>
                    <th scope="col">Parameter</th>
                    <th scope="col">Minimum</th>
                    <th scope="col">Maximum</th>
                    <th scope="col">Units</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Flow Rate: </td>
                    <td>
                      {
                        getValue(state.process_parameters.flow_rate_minimum, 'minimum')?
                        getValue(state.process_parameters.flow_rate_minimum, 'minimum'):
                        0
                    }
                    </td>
                    <td>
                      {
                          checkState(state.process_parameters.flow_rate_maximum, 'maximum')?
                          checkState(state.process_parameters.flow_rate_maximum, 'maximum'):
                          0
                      }
                    </td>
                    <td className="qUppercase">
                      {
                          checkState(state.process_parameters.flow_rate_units, 'units')?
                          checkState(state.process_parameters.flow_rate_units, 'units'):
                          'NA'
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Gas Temperature: </td>
                    <td>
                      {
                          getValue(state.process_parameters.gas_minimum, 'minimum')?
                          getValue(state.process_parameters.gas_minimum, 'minimum'):
                          0
                      }
                    </td>
                    <td>
                      {
                          checkState(state.process_parameters.gas_maximum, 'maximum')?
                          checkState(state.process_parameters.gas_maximum, 'maximum'):
                          0
                      }
                    </td>
                    <td className="qUppercase">
                      {
                          checkState(state.process_parameters.gas_units, 'units')?
                          checkState(state.process_parameters.gas_units, 'units'):
                          'NA'
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Gas Pressure: </td>
                    <td>
                      {
                          getValue(state.process_parameters.pressure_minimum, 'minimum')?
                          getValue(state.process_parameters.pressure_minimum, 'minimum'):
                          0
                      }
                    </td>
                    <td>
                      {
                          checkState(state.process_parameters.pressure_maximum, 'maximum')?
                          checkState(state.process_parameters.pressure_maximum, 'maximum'):
                          0
                      }
                    </td>
                    <td className="qUppercase">
                      {
                          checkState(state.process_parameters.pressure_units, 'units')?
                          checkState(state.process_parameters.pressure_units, 'units'):
                          'NA'
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        );
    }
  return null;
}
