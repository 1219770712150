import React, { useState, useEffect } from 'react';
import useSwr from 'swr';
import{
  Card,
  CardBody,
  CardTitle,
  CardText
} from "reactstrap";
import 'assets/css/EditAccount.css';
import fetcher from 'utils/fetcher';

function CompanyAddress() {
  const [userData, setUserData] = useState([]);
  const { data, mutate } = useSwr("/api/me", fetcher);

  useEffect(() => {
    if(data){
      setUserData(data)
    }
  }, [data]);

  return (
    <>
      <div className='common-main'>
          <h2 className='p-3'>Company Address</h2>
          <div className='bdr body p-3'>
            {userData.companyName &&
              <Card style={{ width: "20rem", margin: '10px' }}>
                <CardBody>
                  <CardTitle tag="h4">{ userData.companyName }</CardTitle>
                  <CardText>
                    { userData.address }<br />
                    { userData.city },<br />
                    { userData.state },<br />
                    { userData.zip },<br />
                    { userData.country }
                  </CardText>
                </CardBody>
              </Card>
            }
          </div>
      </div>
    </>
  );
}

export default CompanyAddress
