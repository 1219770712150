import React,{ useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useSwr from 'swr';
import { useHistory } from 'react-router';
import '../../assets/css/Quotes.css';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import fetcher from 'utils/fetcher';
import { Row, Col, Button, NavLink} from "reactstrap";
import Pagination from './Pagination';

function QuotesReal() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const [quoteData, setQuoteData] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(15);
    const [pageNumbers, setPageNumbers] = useState(1);
    const page = { allQuotesRealSingle: true }

    var { data } = useSwr(`/api/me`, fetcher);
    var { data: Quotes } = useSwr(`/api/realquotes/all`, fetcher);

    useEffect(() => {
        console.log('Show me realquotes 1.0 =>', Quotes);
        setIsLoading(true);
        if(data){
            setUserData(data);
        }

        if(Quotes){
            setQuoteData(Quotes)
            setIsLoading(false);
        }
    }, [data, Quotes]);

    const headerData = {
        firstname: userData.firstname,
        page: 'SAVED QUOTES'
    }

    const quoteClicked = (_id) => {
        history.push(`/portal/quotes/real/${_id}#`);
    }

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentQuotes = quoteData.slice(indexOfFirstPost, indexOfLastPost);

    console.log('Show me quote 1.0 =>', Quotes);

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page} /></div>) : undefined}
                    <div className='common-main'>
                        <div className='shadow'>
                            <h2 className='p-3'>All Submited Quotes</h2>
                            <div className='quotes body p-3'>
                                <p>View a list of all saved quotes. Select a quote to edit, delete, or export as a PDF.</p>
                                <p>Page {currentPage} of {pageNumbers}</p>
                                <hr />
                                { !isLoading && quoteData.length <= 0 &&
                                    <div className="noQuotes text-center">
                                    <p>No quotes have been saved yet. Start by customizing a product in the Meter Customizer!</p>
                                    <br />
                                        <NavLink tag={Link} to="/allproducts">
                                            <Button className="button1" style={{ 'font-size': '1.0em', 'margin': '0px' }}>
                                                Start Customizing
                                            </Button>
                                        </NavLink>
                                    </div>
                                }

                                {isLoading &&
                                    <div className='text-center'>
                                        <p>Loading...</p>
                                    </div>
                                }

                                <div>
                                    {currentQuotes && currentQuotes.map((value) => {
                                        const date = new Date(value.createdAt);
                                        const day = date.getDate();
                                        const month = ("0" + (date.getMonth() + 1)).slice(-2)
                                        const year = date.getFullYear();

                                        return(
                                            <>
                                              <Row type="button" onClick={() => quoteClicked(value._id)}>
                                                  <Col xs="4" className='quotesLink'><FontAwesomeIcon icon={faFolderOpen} style={{'color': '#f1ce6b', 'font-size': '15px'}} />
                                                      Quote #{value.quoteId}
                                                      {value.cart_type === 'save' && ' (Saved Cart)'}
                                                      {value.cart_type === 'realQuote' && value.review_status === '0' && ' (Submitted quote pending review)'}
                                                      {value.review_status === '1' && ' (Quote approved)'}
                                                      {value.review_status === '2' && ' (Quote denied)'}
                                                  </Col>
                                                  <Col xs="4" className='quotesName'>
                                                      Created By: Value
                                                  </Col>
                                                  <Col xs="4" className="quotesButtons"> { `${month}/${day}/${year}` } </Col>
                                              </Row>
                                              <hr />
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={quoteData.length}
                                paginate={paginate}
                                currentPage={currentPage}
                                setPageNumbers={setPageNumbers}
                                pageNumbersCount={pageNumbers}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuotesReal;
