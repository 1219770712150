import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Card, CardBody, CardText, Col, Container, Row, Table, NavLink } from 'reactstrap'
// import mapimg from '../../assets/img/images/shipping-map-img.png'

function Shipping() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Shipping & Return Policies</title>
            <meta name="description" content="Check out all the information about shipping options and pricing, as well as critical details about EPI’s return policy." />
          </Helmet>
            {/* section-1 */}
            <div className='ship-section-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/support" tag={Link}>Support</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/support/shipping" tag={Link}>Shipping & Returns</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Shipping & Returns</h2>
                        <p className='cover-title-p'>View information about shipping options and pricing, as well as critical details about our return policy.</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='bg-white'>
                <Container>
                    <h3 className='commontitleOne pt-5 mb-4 text-center'>Our Shipping Policies</h3>
                    <Row>
                        <Col>
                            <Card className='card-shadow-none'>
                                <CardBody className='p-4'>
                                    <CardText className='commonParaThree mt-2'>
                                        EPI shipments are always Ex Works (EPI utilizes the Incoterm Ex Works. The seller is not bound to load the products on the buyer's preferred mode of transportation when selling Ex Works. Instead, the vendor must make the product ready at a particular place, and the customer must bear the delivery expense.) from our Marina, CA plant. Your final items are packaged safely and brought to the EPI shipping dock. They will be picked up and transported by a courier whom EPI has approved. UPS, FedEx, and DHL are among the approved carriers. In addition, a freight forwarder is allowed for oversized packages (Subject to courier's requirements).
                                        <br /><br />
                                        Unless otherwise stated, all products are insured. EPI will add shipping charges to your final billing if no shipping method and account details are given. On your final billing, this will appear as Prepay and Add.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section-2 */}

            {/* section-3 */}
            <div className='background-colorOne pb-5'>
                <Container>
                  <Row>
                      <Col sm="6">
                        <h4 className='commontitlethree pt-5 mb-4'>Prepay and Add for Domestic Shipping</h4>
                        <h5>Standard Shipping Services:</h5>
                        <p className='commonParaThree'>Domestic UPS Ground (5-7 days) is the standard shipping service (contiguous United States).</p>
                        <h5>Optional Shipping Services:</h5>
                        <p className='commonParaThree'>
                        Domestic: UPS 1-day, two-day, or three-day delivery (depending on service selected)
                        </p>
                      </Col>
                      <Col sm="6">
                        <h4 className='commontitlethree pt-5 mb-4'>Prepay and Add for International Shipping</h4>
                        <h5>Standard Shipping Services:</h5>
                        <p className='commonParaThree'>International UPS Worldwide Expedited</p>
                        <h5>Optional Shipping Services:</h5>
                        <p className='commonParaThree'>
                        Worldwide Express, Worldwide Express Plus, and Worldwide Express Saver are optional shipping services.
                        </p>
                      </Col>
                  </Row>
                </Container>
            </div>
            {/* section-3 */}
            {/* section-4 */}
            <div className='pb-5' style={{'background-color': '#f8fafd'}}>
                <Container>
                    <Row>
                        <Col sm="6">
                            <h3 className='commontitlethree pt-5 mb-4'>Shipping on Holidays</h3>
                            <p className='commonParaThree'>In 2022, EPI and UPS will not ship or deliver on the days listed below. Please keep in mind that delivery estimates will begin the next business day.</p>
                            <Table responsive size="lg" className='ship-table ship-s4-table '>
                                <thead>
                                    <tr>
                                        <th>
                                            Holiday
                                        </th>
                                        <th>
                                            Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            New Year's Day
                                        </td>
                                        <td>
                                            January 1, 2022
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Easter Sunday
                                        </td>
                                        <td>
                                            April 17, 2022
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Memorial Day
                                        </td>
                                        <td>
                                            May 30, 2022
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Independence Day
                                        </td>
                                        <td>
                                            July 4, 2022
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Labor Day
                                        </td>
                                        <td>
                                            September 5, 2022
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Thanksgiving Day
                                        </td>
                                        <td>
                                            November 24, 2022
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Christmas Day
                                        </td>
                                        <td>
                                            December 25, 2022
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            New Year's Day
                                        </td>
                                        <td>
                                            January 1, 2023
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col sm="6">
                            <h3 className='commontitlethree pt-5 mb-4'>Shipping to a PO Box</h3>
                            <p className='commonParaThree'>EPI is unable to ship to PO boxes due to the size of the flow meters.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section-4 */}
            {/* section-6 */}
            <Container>
                <h3 className='commontitleOne pt-5 mb-4 text-center'>Our Return Policy</h3>
                <p className='commonParaThree'>
                For one year from the date of factory shipping, the seller promises that its products are free of faults in materials and quality. If a problem exists, the purchaser must notify the Seller within the warranty term.
                <br /><br />
                Upon receipt and confirmation of the faulty Product, the seller shall, in its sole discretion, fix the defective Product, offer a seller's replacement, or refund the defective Product's purchase price.
                <br /><br />
                Products that have been repaired or replaced will be guaranteed for the balance of the warranty term. Buyer must immediately stop using any of the seller's installed Products that Buyer deems to be faulty.
                <br /><br />
                Visit our warranty page for additional information on our warranty policy and how to file a claim.
                </p>

                <Row className='text-center'>
                    <Col md="6" sm="12" className='d-flex justify-content-md-end justify-content-sm-center'>
                        <div className='pt-4 pb-5'>
                            <a href="/support/servicerequest" className='button1'>Request a Return</a>
                        </div>
                    </Col>
                    <Col md="6" sm="12" className='d-flex justify-content-md-start justify-content-sm-center'>
                        <div className='pt-4 pb-5'>
                            <a href="/support/warranty" className='button3'>View Warranty Terms</a>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* section-6 */}
            {/* section-7 */}
            <div className='background-colorOne pt-5 pb-5'>
                <Container>
                    <Row>
                        <Col sm="6">
                            <h3 className='commontitlethree'>Product Return Requirements</h3>
                            <p className='commonParaThree'>The following requirements must be completed to be eligible for a return:
                            <ul>
                              <li>All returns must be tied to an EPI order.</li>
                              <li>All returns must be processed through our RMA system within 30 days of the purchase date. Shipping instructions and an RMA number will be sent after your application has been granted.</li>
                              <li>Any shipping charges incurred during the initial order are not refundable.</li>
                              <li>The returner must fund all return shipping expenses, and all meters must be assembled appropriately and decontaminated before being returned. If necessary, a cleaning fee will be charged.</li>
                            </ul>
                            </p>
                            <p className='commonParaThree'>
                              Please wait five business days for evaluation findings after your return has been received. Functionality, hardware, firmware, and visual examination are all part of this assessment. In addition, suppose the inspection uncovers other concerns that aren't covered by the warranty. In that case, you'll be notified of extra costs via your repair order.
                              <br />
                              A prepaid mailing label will be issued with any authorized warranty repair orders and/or exchanges for the return of your qualifying EPI product.
                            </p>
                        </Col>
                        <Col sm="6">
                          <h3 className='commontitlethree'>How to Ship Your Return</h3>
                          <p className='commonParaThree'>When sending your return, please include the following information:
                          <ul>
                            <li>All serial numbers must be the same. Sensors, electronics, and probe/flow body are all included.</li>
                            <li>Please mention the RMA number on the shipping labels and any other documentation linked with the equipment.</li>
                            <li>Please ensure that the sensor is secure. To minimize damage during transit, we recommend placing a piece of PVC over the sensor/probe and fastening it.</li>
                            <li>Inline flow meters should not be dismantled for transportation; instead, the sensor should be firmly fixed inside the flow body.</li>
                            <li>Please box the meter with foam or bubble wrap to avoid meter damage.</li>
                          </ul>
                          </p>
                          <p className='commonParaThree'>
                            Please include original order details or the return RMA number within the package when delivering your returned meter. Choose a provider that allows you to monitor your packages and save the tracking number as a reference.
                          </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section-7 */}
        </div>
    )
}

export default Shipping
