import React from "react";

// product views
import ValgenLMProductView from "components/ProductViews/Val/ValInsertion/ValgenLMProductView";
import ValgenLMRProductView from "components/ProductViews/Val/ValInsertion/ValgenLMRProductView";
import ValgenRBProductView from "components/ProductViews/Val/ValInsertion/ValgenRBProductView";
import ValgenRBRProductView from "components/ProductViews/Val/ValInsertion/ValgenRBRProductView";

function ValInsertion({product, productType}) {
  switch (product) {
    case 'val':
      return (
        <>
          <ValgenLMProductView productType={productType} />
        </>
      );
      break;

      case 'val-lmr':
        return (
          <>
            <ValgenLMRProductView productType={productType} />
          </>
        );
        break;

      case 'val-rb':
        return (
          <>
            <ValgenRBProductView productType={productType} />
          </>
        );
        break;

      case 'val-rbr':
        return (
          <>
            <ValgenRBRProductView productType={productType} />
          </>
        );
        break;
  
    default:
      return (
        <>
            <ValgenLMProductView productType={productType} />
        </>
      );
      break;
  }
}

export default ValInsertion;
