import React from 'react'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function TComAccordation() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header" >
                    <Typography>Constant Temperature Thermal Mass Flow Meters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Constant temperature thermal mass flow meters, such as those produced by EPI™, are thermal mass flow meters (heat loss). Constant temperature thermal mass flow meters require two active sensors (typically platinum RTD’s)  that are operated in a balanced state. One acts as a temperature sensor reference; the other is the active heated sensor. Heat loss produced by the flowing fluid tends to unbalance the heated flow sensor and it is forced back into balance by the electronics. With this method of operating the constant temperature sensor, only the skin temperature is affected by the fluid flow heat loss. This allows the sensor core temperature to be maintained and produces a very fast response to fluid velocity and temperature changes. Additionally, because the power is applied as needed, the system has a wide operating range of flow and temperature. The heated sensor maintains an index of overheat above the environmental temperature sensed by the unheated element. The effects of variations in density are virtually eliminated by molecular heat transfer and sensor temperature corrections. These meters typically have an effective turndown ratio of 100:1 when properly sized.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>Constant Power Thermal Mass Flow Meters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Constant power thermal mass flow meters are thermal (heat loss) mass flow meters, also produced by EPI™, and require two active elements. A constant current heated element acts a heat loss flow sensor while a second RTD operates as an environmental temperature sensor. When the fluid is at rest the heat loss is at a minimum. Heat loss increases with increasing fluid velocity. In this method of operation, the mass of the sensor must change its temperature, making it slow to respond to fluid velocity changes. In addition, this method of operation has a limited useful temperature range due to the constant current applied. The dynamic temperature range may be widened by applying more power (current) to the heater, but this can result in excessive heat applied to the heater when the fluid is at rest. The effects of variations in density are virtually eliminated by molecular heat transfer and sensor temperature corrections. These meters typically have a turndown ratio of 100:1.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography>Calorimetric (or Energy Balance) Thermal Mass Flow Meters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Calorimetric or energy balance thermal mass flow meters require one heating element and two temperature sensors. Although many design variations exist, they all have a similar operating method. Typically, the heater is attached to the middle of a flow tube with a constant heat input. Two matched RTD’s or thermocouples are attached equidistant upstream and downstream of the heater. The temperature differential at flowing conditions is sensed, producing an output signal. Because both temperature sensors see the same temperature and pressure effects, the design is inherently unaffected by density changes and the result will be a true mass flow output. Limitations of this flow meter design would commonly be a maximum flow rate of 200 liters per minute, nonindustrial packaging, and a tendency to clog in dirty fluids. These meters typically have a turndown ratio of 10:1.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>Coriolis Mass Flow Meters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Coriolis mass flow meters provide a direct mass flow measurement and are easy to size based on process requirements. The principle of operation is the Coriolis effect or conservation of angular momentum due to the Coriolis acceleration of a fluid stream. When an excitation force is applied to a tube causing it to vibrate, the fluid flowing through the tube will cause a rotation or twist to the tube because of the Coriolis acceleration acting in opposite directions on either side of the applied force. Various tube designs, excitation sources, and sensors may be incorporated in the Coriolis mass flow meter. Coriolis mass flow meters tend to be much larger in size compared to other types of flow meters and, when purchase price is of concern, Coriolis mass flow meters may not be an option due to their high price. These meters typically have a turndown ratio of 10:1.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                    <Typography>Differential Pressure Flow Meters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Differential pressure flow meters are available which provide a volumetric flow rate output. These flow meters require a pressure gage or sensor to be connected across their pressure ports for flow sensing. If mass flow rate outputs are required, differential pressure flow meters require additional readings to infer mass flow. Manual or computer calculations incorporating physical process measurements such as absolute pressure, differential pressure, temperature and viscosity readings must be applied to the output signal to obtain the actual flow rate. Orifice or orifice plate, nozzle or flow nozzle, flow venturi or venturi tube, pitot tube or pitot static tube, elbow or flow elbow, loop or flow loop flow meters are all differential pressure flow meters. Variations on these are the sonic nozzle and the sonic venturi. Differential pressure flow meters typically have a turndown ratio of 10:1.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                    <Typography>Magnetic Flow Meters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Magnetic flow meters follow Faraday’s law of Electromagnetic induction. In a magnetic flow meter, an electromotive force (flux) is generated perpendicular to the conductive fluid as it passes through a magnetic field in a nonmagnetic conduit. An electromagnet is excited by either a pulsed DC current or sinusoidal AC current. Through ion exchange an electromotive force (emf) is produced across an electrode pair, thereby providing the magnetic flow meter with an emf output signal proportional to the fluid velocity. Magnetic flow meters are used in conductive liquid applications exclusively. Normally, when installed they provide an unobstructed flow. Typically, the minimum conductivity of the fluid is in the order of 0.1 microseisms/cm so magnetic flow meters won’t work for most gases and petroleum products. These meters typically have a turndown ratio of 10:1.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                    <Typography>Positive Displacement Flow Meters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Positive displacement flow meters may incorporate oval gears, helical gear, pistons, lobed impeller, sliding vanes, or nutating disks. This type of flow meter entraps a known quantity of fluid per pulse and by totaling up the pulses over time the fluid flow rate is known. If mass flow rate outputs are required, then the positive displacement flow meter requires other readings to infer mass flow. Manual or computer calculations incorporating physical process measurements such as absolute pressure, differential pressure, temperature and viscosity readings must be applied to the output signal to obtain the actual flow rate.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                    <Typography>Target Flow Meters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Target flow meters may be used where rough accuracy is required or where the fluid is extremely dirty. A disk or body is immersed into the fluid stream perpendicular to the flow. The differential pressure forces acting on the target are sensed by either a strain gage or force balance method. The magnitude of the strain gage signal or energy required to maintain balance is proportional to the fluid flow. Target flow meters may be used in applications where the flowing fluid has sufficient momentum to cause the required pressure differential. The target flow meter is located where turbulence, pulsation, or vibrations are minimized. If mass flow rate outputs are required, then the target flow meter requires other readings to infer mass flow. Manual or computer calculations incorporating physical process measurements such as absolute pressure, differential pressure, temperature and viscosity readings must be applied to the output signal to obtain the actual flow rate. These meters typically have a turndown ratio of 10:1.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
                    <Typography>Turbine Flow Meters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Turbine flow meters consist of inlet flow conditioners, rotor, rotor supports, rotor bearings, housing, and signal pickoff coil. A turbine rotor has multiple blades, and the velocity of rotation sensed by the pickoff coil is proportional to flow. Turbine flow meters are sensitive to density and viscosity fluctuations. If mass flow rate outputs are required, then the turbine flow meter requires other readings to infer mass flow. Manual or computer calculations incorporating physical process measurements such as absolute pressure, differential pressure, temperature and viscosity readings must be applied to the output signal to obtain the actual flow rate. Clean fluids are required to prevent contamination of the bearings unless sealed bearings are used. Turbine meters typically have a turndown ratio of 10:1, but with special care it is possible to achieve 20:1. Other rotational meters are the propeller, paddle wheel, impeller, rotor and rotating cup flow meters.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
                    <Typography>Ultrasonic Flow Meters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Ultrasonic flow meters may have sensing elements clamped external to the pipe or immersed in the fluid. Two basic sensing methods are generally used for air flow measurement, the time of flight (transit time) or Doppler method. Time of flight ultrasonic flow meters measure the difference in time travel between pulses sent with and against the fluid flow. Two sensors are required, one upstream and the other downstream. The signal is sent both directions to get the time differential. Doppler ultrasonic flow meters apply the Doppler effect to measure flow rate. This method requires particles or bubbles in the fluid stream. If the signal sent is equal to the signal received, then the fluid is not flowing. A frequency shift will occur with flowing fluid, and the shift will get proportionately greater with increasing fluid velocity.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                <AccordionSummary aria-controls="panel11d-content" id="panel11d-header">
                    <Typography>Variable Area Flow Meter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Variable area flow meter or Rotameters have a tapered bore and float. Floats may have a number of design variations from a simple ball to complex designs. The tube bore designs may also be varied. The operating principle is a dynamic balance condition. When the upward forces of the flowing fluids equal the weight of the float, the float will be buoyant at a level within the tapered bore. The annular area around the float increases with increasing fluid flow. These meters typically have a turndown ratio of 10:1.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                <AccordionSummary aria-controls="panel12d-content" id="panel12d-header">
                    <Typography>Vortex Shedder Area Flow Meters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Vortex shedder flow meters apply the von Karman vortex shedding phenomenon. As the fluid flows over a bluff body, vortices are alternately formed downstream on either side of the bluff body. The frequency of the vortices are proportional to the fluid velocity. Various sensing methods for airflow measurement can be applied to measure the frequency of the vortices. If mass flow rate outputs are required, then the vortex shedder flow meter requires other readings to infer mass flow. Manual or computer calculations incorporating physical process measurements such as absolute pressure, differential pressure, temperature and viscosity readings must be applied to the output signal to obtain the actual flow rate.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default TComAccordation
