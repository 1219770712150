import React,{ useState, useEffect } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import useSwr from 'swr';
import { useHistory } from 'react-router';
import 'assets/css/Quotes.css';
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import { faArrowRight, faCreditCard, faDownload, faTrashAlt, faEdit, faFolderOpen, faSave, faPlus, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fetcher from 'utils/fetcher';
import { Row, Col, Button, NavLink, Collapse, CardBody} from "reactstrap";
import meterimg from '../../assets/img/images/meter-img.png'
import ShoppingCart from 'components/Customizer/ShoppingCart';
import MappingData_ from "../ViewQuote/ModelNumMappingData/Inline";
import CartModal from "./CartModal";
import SaveConfigModal from "./SaveConfigModal";
import {
  checkStateMain as checkState
} from "components/Customizer/Views/utils/meterCustomizer";


function Cart() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const [cartData, setCartData] = useState([]);
    const [quoteData, setQuoteData] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    const [testDelete, setTestDelete]= useState(false);
    const [mappingData, setMappingData] = useState(false);
    const [viewRep, setViewRep] = useState(false);
    const [modal, setModal] = useState(false);
    const [counter, setCounter] = useState(false);
    const [subTotal, setSubTotal] = useState(0);
    const [saveConfigModal, setSaveConfigModal] = useState(false);
    const [currentConfig, setCurrentConfig] = useState(false);
    const [currentPosition, setCurrentPosition] = useState(false);
    const page = { cart: true }

    var { data } = useSwr(`/api/me`, fetcher);

    useEffect(() => {
      setIsLoading(true);
      if(data){
        setUserData(data);
      }
    }, [data]);

    useEffect(() => {
      if(MappingData_){
        setMappingData(MappingData_[0]);
      }
    }, [MappingData_]);

    useEffect(() => {
      setCartData(ShoppingCart({'task':'read'}));
      console.log('show me new change 1.0 =>', )
    }, [counter]);

    const toggle = () => setModal(!modal);
    const saveConfigToggle = () => setSaveConfigModal(!saveConfigModal);
    const saveConfigToggle_ = (value, index) => {
      setSaveConfigModal(!saveConfigModal)
      setCurrentConfig(value);
      setCurrentPosition(index);
    }

    const headerData = {
        firstname: userData.firstname,
        page: 'CART'
    }

    const quoteClicked = (_id) => {
        history.push(`/portal/quotes/v/${_id}#`);
    }

    const quoteClickedEdit = (_id) => {
      history.push(`/portal/quotes/edit/c/${_id}#`);
    }

    const submitQuote = async (cart_type) => {
      const cart  = {...cartData.cart_items}
      cartData['total'] = '1000';
      cartData['cart_items'] = cart;
      cartData['review_status'] = 0;
      cartData['cart_type'] = cart_type;
      delete cartData.total_price;
      delete cartData.ot;

      console.log('Show me qoute submit data 1.0 =>', cart_type);
      console.log('Show me qoute submit data 1.1 =>', cartData);

      try {
        await axios.post(
          `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/realquotes`,
          cartData,
          { withCredentials: true }
        );
        ShoppingCart({task: 'delete'});
        history.push(`/portal/quotes/real/s`);
      } catch(e) {
        console.log('Show me db insert error cart', e.message);
      }
    }

    const deleteMeter = (index) => {
      ShoppingCart({
        'task': 'remove',
        'data': {cart_location: index}
      });
      setCounter(counter + 1);
    }

    const updateCart = ({value, position}) => {
      if(value < 1){
        return;
      }

      ShoppingCart({
        'task':'updateSingle',
        'data': {
          cart_location: position,
          quantity: value
        }
      });
      setCounter(counter + 1);
      console.log('Show me cart 2.0 =>', ShoppingCart({'task':'read'}));
    }

    const removeCommas = (value) => {
      var a=value;
      a=a.replace(/\,/g,''); // 1125, but a string, so convert it to number
      a=parseInt(a,10);
      return a
    }

    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const subTotalArray = [];

    useEffect(() => {
      if(subTotalArray.length){
        setSubTotal(subTotalArray.reduce((a, b) => a + b));
      }
    }, [subTotalArray, counter]);

    const getValue = (object, value) => {
      if(object){
        if(object.valuesObj[value]){
          return object.valuesObj[value]
        }
        return 0;
      }
    }

    console.log('Show me cart 1.0 =>', cartData);

    return (
        <div>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar page={page} /></div>) : undefined}
                    <div className='common-main'>
                          <div className='shadow'>
                            <div className='quit-details-main mt-5'>
                                <div className='row quit-details-main-row'>
                                    <div className='col-md-4'>
                                        <h2 className='p-3 mt-2 quite-details-text'>Cart</h2>
                                    </div>
                                    <div className='col-md-8'>
                                        {/* new */}
                                        <div className='row text-right quote-nav-options'>
                                            <ul className='m-0'>
                                              <li className='quoteMenuButton' style={{display: 'none'}}>
                                                <a onClick={() => submitQuote('save')}>
                                                  <FontAwesomeIcon icon={faSave} className='quoute-2d-element-icon'></FontAwesomeIcon>
                                                  Save Cart for Later
                                                </a>
                                              </li>
                                              <li className='quoteMenuButton'>
                                                <a className='btnNav' href="/allproducts">
                                                  <FontAwesomeIcon icon={faPlus} className='quoute-2d-element-icon'></FontAwesomeIcon>
                                                  Add Meter
                                                </a>
                                              </li>
                                              <li className='quoteMenuButton'>
                                                <a className='btnNav' onClick={toggle}>
                                                  <FontAwesomeIcon icon={faPaperPlane} className='quoute-2d-element-icon'></FontAwesomeIcon>
                                                  Submit for Review
                                                </a>
                                              </li>
                                                {/* <li><a href="/"><FontAwesomeIcon icon={faCreditCard} className='quoute-2d-element-icon'></FontAwesomeIcon>Buy Now</a></li> */}
                                                {/* <li className='quoteMenuButton'>
                                                    <a>
                                                        <FontAwesomeIcon icon={faEdit} className='quoute-2d-element-icon'></FontAwesomeIcon>
                                                        Edit
                                                    </a>
                                                </li>
                                                <li className='quoteMenuButton'>
                                                    <a>
                                                        <FontAwesomeIcon icon={faDownload} className='quoute-2d-element-icon'></FontAwesomeIcon>
                                                        Download/Print PDF
                                                    </a>
                                                </li>
                                                <li className='quoteMenuButton'><a className='me-3'><FontAwesomeIcon icon={faTrashAlt} className='quoute-2d-element-icon'></FontAwesomeIcon>Delete</a></li> */}
                                            </ul>
                                        </div>
                                        {/* new */}
                                    </div>
                                </div>
                            </div>
                            <div className='quotes body p-3 cartPage'>
                              <p>Here you will see a list of all meter configurations that have been saved to the cart. In order to purchase the meters in your cart, you must first submit them to be reviewed by the EPI team.</p>
                              <hr />
                              <div class="row">
                                <div class="col-12 cItems">
                                  <div class="table-responsive">
                                      <table class="table qTable">
                                          <thead class="text-muted">
                                              <tr class="small text-uppercase">
                                                <th scope="col" width="50">ID #</th>
                                                <th scope="col meterName">Items</th>
                                                <th scope="col" width="120">Quantity</th>
                                                <th scope="col" width="120">
                                                  {userData.account_type === "admin" && "Price"}
                                                  {userData.account_type === "regularPlusPricing" && "Price"}
                                                </th>
                                                <th scope="col" width="200">Actions</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                            { cartData?.cart_items?.map((value, index) => {
                                              subTotalArray.push(removeCommas(value.total) * value.quantity)
                                              if(value.state){
                                                console.log('Show me mapping data 1.0 =>', mappingData.model_number.pipe_length[value.state.model_number.pipe_length].desc)
                                                var ca = value.state.model_number.certifications + value.state.model_number.approvals;
                                                if(value.state.model_number.certifications === 'NA'){
                                                  var ca = value.state.model_number.certifications
                                                }
                                              }

                                              var electronics = value.state.model_number.electronics;
                                              var pipe_probe_length = value.state.model_number.pipe_length;
                                              if(value.state.meter_type.type === 'insertion'){
                                                var electronics = value.state.model_number.electronics_insertion;
                                                var pipe_probe_length = value.state.model_number.probe_length;
                                              }

                                              return(
                                                <tr>
                                                  <td><b>{index + 1}</b></td>
                                                  <td class="cMeterInfo">
                                                    <div class="row">
                                                      <div class="col-2">
                                                        <img
                                                          src={
                                                            value.state.meter_type?.type === 'inline'?
                                                            value.state.meter_images?.inline:
                                                            value.state.meter_images?.insertion
                                                          }
                                                          class="img-sm meterImg"
                                                        />
                                                      </div>
                                                      <div class="col-10">
                                                        <p class="qLabel">Meter Series:</p>
                                                        <p class="title text-dark">
                                                          {value.series}
                                                        </p>
                                                        <br />
                                                        <p class="qLabel">Model Number:</p>
                                                        <p class="text-muted small" style={{'text-transform': 'uppercase'}}>
                                                          <b>
                                                            {electronics}
                                                            {value.state.model_number.pipe_length}{value.state.model_number.series} -
                                                            {value.state.model_number.transmitter} -
                                                            {value.state.model_number.sensor_material} -
                                                            {value.state.model_number.display} -
                                                            {value.state.model_number.input_power} -
                                                            {value.state.model_number.gas_temp} -
                                                            {value.state.model_number.pipe_standard}{pipe_probe_length}{value.state.model_number.mounting_requirements} -
                                                            {value.state.model_number.range}-
                                                            {value.state.model_number.gas} -
                                                            {ca} -
                                                            {value.state.model_number.communications_options_2}
                                                          </b>
                                                        </p>
                                                        <p class="qLabel">Calibration:</p>
                                                        <p class="text-muted small">
                                                          <b>
                                                            {value.state.model_number.gas}&nbsp;
                                                            calibration for&nbsp;
                                                            {getValue(value.state.process_parameters.flow_rate_minimum, 'minimum')? getValue(value.state.process_parameters.flow_rate_minimum, 'minimum') : 0}-
                                                            {checkState(value.state.process_parameters.flow_rate_maximum, 'maximum')? checkState(value.state.process_parameters.flow_rate_maximum, 'maximum') : 0}&nbsp;
                                                            {checkState(value.state.process_parameters.flow_rate_units, 'units')? checkState(value.state.process_parameters.flow_rate_units, 'units') : 'SCFS'}&nbsp;
                                                            in a
                                                            1/4'' pipe &nbsp;
                                                            {/* |{checkState(value.state.pipe_infornation.pipe_size, 0.25)}| */}
                                                            @&nbsp;
                                                            {getValue(value.state.process_parameters.ambient_minimum, 'minimum')? getValue(value.state.process_parameters.ambient_minimum, 'minimum') : 0}-
                                                            {checkState(value.state.process_parameters.ambient_maximum, 'maximum')? getValue(value.state.process_parameters.ambient_minimum, 'minimum') : 0}&nbsp;
                                                            {checkState(value.state.process_parameters.ambient_units, 'units')? checkState(value.state.process_parameters.ambient_units, 'units') : '°C'}&nbsp;
                                                            and&nbsp;
                                                            {getValue(value.state.process_parameters.pressure_minimum, 'minimum')? getValue(value.state.process_parameters.pressure_minimum, 'minimum') : 0}-
                                                            {checkState(value.state.process_parameters.pressure_maximum, 'maximum')? getValue(value.state.process_parameters.pressure_maximum, 'maximum') : 0}&nbsp;
                                                            {checkState(value.state.process_parameters.pressure_units, 'units')? checkState(value.state.process_parameters.pressure_units, 'units') : 'PSIG'}&nbsp;
                                                          </b>
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div class="row cSpecs">
                                                      <div class="col-12">
                                                        {viewRep.index === index? (
                                                            <a
                                                                class="btn btn-text"
                                                                onClick={
                                                                    () => setViewRep({
                                                                        index: 'index',
                                                                        status: true
                                                                    })
                                                                }
                                                            >
                                                                <i class="fa fa-plus"></i>&nbsp;&nbsp;Show More Meter Specs
                                                            </a>
                                                        ) : (
                                                            <a
                                                                class="btn btn-text"
                                                                onClick={
                                                                    () => setViewRep({
                                                                        index: index,
                                                                        status: true
                                                                    })
                                                                }
                                                            >
                                                                <i class="fa fa-plus"></i>&nbsp;&nbsp;Show More Meter Specs
                                                            </a>
                                                        )}
                                                        <hr class="qHr" />
                                                        <Collapse isOpen={viewRep.index === index && viewRep.status}>
                                                          <table class="table qTable cTable">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">Category</th>
                                                                <th scope="col">Code</th>
                                                                <th scope="col">Description</th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              <tr>
                                                                <td>Meter Model</td>
                                                                <td className="qUppercase">{value.state.model_number.electronics}{value.state.model_number.pipe_length}{value.state.model_number.series}</td>
                                                                <td>
                                                                  {`
                                                                    ${mappingData.model_number.electronics[value.state.model_number.electronics]?.desc}.
                                                                    ${mappingData.model_number.pipe_length[value.state.model_number.pipe_length]?.desc}.
                                                                  `}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td>Transmitter</td>
                                                                <td className="qUppercase">{value.state.model_number.transmitter}</td>
                                                                <td>{mappingData.transmitter[value.state.model_number.transmitter]?.desc}</td>
                                                              </tr>
                                                              <tr>
                                                                <td>Sensor</td>
                                                                <td className="qUppercase">{value.state.model_number.sensor_material}</td>
                                                                <td>{mappingData.sensor_material[value.state.model_number.sensor_material]?.desc}</td>
                                                              </tr>
                                                              <tr>
                                                                <td>Display</td>
                                                                <td className="qUppercase">{value.state.model_number.display}</td>
                                                                <td>{mappingData.display[value.state.model_number.display]?.desc}</td>
                                                              </tr>
                                                              <tr>
                                                                <td>Input Power</td>
                                                                <td className="qUppercase">{value.state.model_number.input_power}</td>
                                                                <td>{mappingData.input_power[value.state.model_number.input_power]?.desc}</td>
                                                              </tr>
                                                              <tr>
                                                                <td>Temperature Range</td>
                                                                <td className="qUppercase">{value.state.model_number.gas_temp}</td>
                                                                <td>{mappingData.gas_temp[value.state.model_number.gas_temp]?.desc}</td>
                                                              </tr>
                                                              <tr>
                                                                <td>Configuration</td>
                                                                <td className="qUppercase">
                                                                  {value.state.model_number.pipe_standard}
                                                                  {value.state.model_number.pipe_length}
                                                                  {value.state.model_number.mounting_requirements}
                                                                </td>
                                                                <td>
                                                                  {`
                                                                    ${mappingData.pipe_standard[value.state.model_number.pipe_standard]?.desc}
                                                                    ${mappingData.pipe_standard.pipe_length[value.state.model_number.pipe_length]?.desc}
                                                                    ${mappingData.pipe_standard[value.state.model_number.mounting_requirements]?.desc}
                                                                  `}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td>Range</td>
                                                                <td className="qUppercase">{value.state.model_number.range}</td>
                                                                <td>{mappingData.ranges[value.state.model_number.range]?.desc}</td>
                                                              </tr>
                                                              <tr>
                                                                <td>Gas Type</td>
                                                                <td className="qUppercase">{value.state.model_number.gas}</td>
                                                                <td>{mappingData.gas[value.state.model_number.gas.toUpperCase()]?.desc}</td>
                                                              </tr>
                                                              <tr>
                                                                <td>Certifications/Approvals</td>
                                                                <td className="qUppercase">{ca}</td>
                                                                <td>{mappingData.certifications_approvals[ca]?.desc}</td>
                                                              </tr>
                                                              <tr>
                                                                <td>Communication Protocols</td>
                                                                <td className="qUppercase">{value.state.model_number.communications_options_2}</td>
                                                                <td>{mappingData.communications_options_2[value.state.model_number.communications_options_2]?.desc}</td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                          <a href="" class="btn btn-text">
                                                          <i class="fa fa-plus"></i>&nbsp;&nbsp;Show Less Meter Specs
                                                          </a>
                                                        </Collapse>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input
                                                        type="number"
                                                        min="1"
                                                        max="100"
                                                        class="epiInputSize form-select form-control"
                                                        defaultValue={value.quantity}
                                                        onChange={(e) => {updateCart({
                                                          value: e.target.value,
                                                          position: index
                                                        })}}
                                                      >
                                                      </input>
                                                    </div>
                                                  </td>
                                                  <td>
                                                      <div class="price-wrap">
                                                        {userData.account_type === "admin"?
                                                          <p class="price">${numberWithCommas(removeCommas(value.total) * value.quantity)}</p>:
                                                          <p class="price"></p>
                                                        }

                                                        {userData.account_type === "regularPlusPricing"?
                                                          <p class="price">${numberWithCommas(removeCommas(value.total) * value.quantity)}</p>:
                                                          <p class="price"></p>
                                                        }
                                                      </div>
                                                  </td>
                                                  <td>
                                                    <div class="cLinks">
                                                      <a href="" class="btn btn-text mt-0" onClick={() => quoteClickedEdit(value._id)}>
                                                        <i class="fa fa-edit"></i>&nbsp;&nbsp;Edit
                                                      </a>
                                                      <br />
                                                      <a href="" class="btn btn-text mt-0" onClick={() => deleteMeter(index)}>
                                                        <i class="fa fa-trash"></i>&nbsp;&nbsp;Delete
                                                      </a>
                                                      <br />
                                                      {value.config_type === 'incart' &&
                                                        // <a class="btn btn-text mt-0" onClick={() => saveSingleConfig(value, index)}>
                                                        //   <i class="fa fa-save"></i>&nbsp;&nbsp;Save Configuration
                                                        // </a>
                                                        // <a class="btn btn-text mt-0" onClick={saveConfigToggle}>
                                                        //   <i class="fa fa-save"></i>&nbsp;&nbsp;Save Configuration
                                                        // </a>
                                                        <a class="btn btn-text mt-0" onClick={() => saveConfigToggle_(value, index)}>
                                                          <i class="fa fa-save"></i>&nbsp;&nbsp;Save Configuration
                                                        </a>
                                                      }

                                                      {value.config_type === 'saved' &&
                                                        <a class="btn btn-text mt-0" onClick={() => quoteClicked(value._id)}>
                                                          <i class="fa fa-eye"></i>&nbsp;&nbsp;View Configuration
                                                        </a>
                                                      }
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                      </table>
                                  </div>
                                </div>
                                <div class="col-12 cCheckout text-right">
                                  {userData.account_type === "admin"?
                                    <p class="qPrice"><span class="qPricingInner">Subtotal:</span> ${numberWithCommas(subTotal)}</p>:
                                    <p class="qPrice"><span class="qPricingInner"></span></p>
                                  }

                                  {userData.account_type === "regularPlusPricing"?
                                    <p class="qPrice"><span class="qPricingInner">Subtotal:</span> ${numberWithCommas(subTotal)}</p>:
                                    <p class="qPrice"><span class="qPricingInner"></span></p>
                                  }
                                </div>
                                <div class="col-12 cCheckout clearfix">
                                  <a
                                    onClick={toggle}
                                    class={
                                      cartData.cart_items?.length === 0?
                                      'btn btn-gradient float-right disabled':
                                      'btn btn-gradient float-right'
                                    }
                                  >
                                    Submit for Review
                                  </a>
                                  <a href="/allproducts" class="btn btn-outline float-right">
                                    Continue Shopping
                                  </a>
                                </div>
                              </div>
                              <hr />
                              <div class="row">
                                <div class="qTerms">
                                  <br />
                                  <p class="qLabel">Pricing notes:</p>
                                  <p class="qNote"><b>- Total above does not include items listed as optional or alternative.<br />
                                  - Pricing shown here does not include any applicable taxes which must be added at time of order placement.</b>
                                  </p>
                                  <br />
                                  <div class="qTermsSection">
                                    <div class="row p-0">
                                      <div class="col-3 p-0">
                                        <p class="qLabel">Delivery time:</p>
                                      </div>
                                      <div class="col-9 p-0">
                                        <p class="qNote">Delivery times are subject to change according to stock availability at the time of order.
                                        </p>
                                      </div>
                                    </div>
                                    <div class="row p-0">
                                      <div class="col-3 p-0">
                                        <p class="qLabel">Delivery terms:</p>
                                      </div>
                                      <div class="col-9 p-0">
                                        <p class="qNote">Delivery to EPI dock for buyer pickup and transport using courier as approved by EPI.
                                        </p>
                                      </div>
                                    </div>
                                    <div class="row p-0">
                                      <div class="col-3 p-0">
                                        <p class="qLabel">Delivery location:</p>
                                      </div>
                                      <div class="col-9 p-0">
                                        <p class="qNote">Shipping Address/Location
                                        </p>
                                      </div>
                                    </div>
                                    <div class="row p-0">
                                      <div class="col-3 p-0">
                                        <p class="qLabel">Payment terms:</p>
                                      </div>
                                      <div class="col-9 p-0">
                                        <p class="qNote">Exworks Marina Factory
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    <CartModal
                      modal={modal}
                      toggle={toggle}
                      cartData={cartData}
                      submitQuote={submitQuote}
                    />
                    <SaveConfigModal 
                      modal={saveConfigModal}
                      toggle={saveConfigToggle}
                      config={currentConfig}
                      position={currentPosition}
                      counter={counter}
                      setCounter={setCounter}
                    />
                  </div>
                </div>
            </div>
          </div>
    )
}

export default Cart
