import React,{ useState, useEffect } from 'react';
import useSwr from 'swr';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderDiv from 'components/Headers/HeaderDiv/HeaderDiv';
import Sidebar from 'components/Navbars/Sidebar/Sidebar';
import fetcher from 'utils/fetcher';
import './Warranty.css'

function Warranty() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const page = { warranty: true }

    var { data } = useSwr(`${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/me`, fetcher);
    useEffect(() => {
        if(data){
            setUserData(data);
        }
    }, [data]);

    const headerData = {
        firstname: userData.firstname,
        page: 'WARRANTY'
    }
    return (
        <div className='warranty'>
            <HeaderDiv data={headerData} />
            <div className="body-main">
                <div className='bm-child-1'>
                    <Sidebar page={page}/>
                </div>
                <div className='bm-child-2'>
                    <div className='toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                    {isOpen ? (<div className='mobile-sidebar'><Sidebar> </Sidebar></div>) : undefined}
                    <div className='common-main'>
                        <h2 className='p-3'>Warranty</h2>
                        <div className='bdr body p-3'>
                            <p>We have included our warranty terms here for your reference. This information is also available on our support page.
                            </p>
                            <hr />
                            <h4>Limited Warranty and Limitation of Liability</h4>
                            <p>
                              (a) Seller warrants its products to be free from defects in materials and workmanship for one year from the date of factory shipment. If there is a defect, the purchaser must notify Seller of the defect within the warranty period. Upon Seller’s receipt and confirmation of the defective Product, Seller at its sole option, will either repair the defective Product, or provide a Seller’s replacement, or refund the purchase price of the defective Product. Repaired or replaced Products shall be warranted for the remainder of the warranty period. For any of Seller’s installed Products that Buyer believes to be defective, Buyer shall immediately discontinue product use.
                            </p><br />
                            <p>
                              EPI MAKES NO OTHER WARRANTY, EXPRESS OR IMPLIED, AS TO THE PRODUCTS. EPI MAKES NO WARRANTY AND DISCLAIMS ANY WARRANTY THAT THE GOODS SOLD TO ANY PURCHASER ARE FIT FOR ANY PARTICULAR PURPOSE. FURTHERMORE, EPI DISCLAIMS ANY WARRANTY OF MERCHANTABILITY WITH RESPECT TO ANY PRODUCTS SOLD TO ANY PURCHASERS. BUYER SHALL NOT IN ANY EVENT BE ENTITLED TO, AND SELLER SHALL NOT BE LIABLE FOR, INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY NATURE INCLUDING, WITHOUT LIMITATION, BUSINESS INTERRUPTION COSTS, REMOVAL AND/OR REINSTALLATION COSTS, REPROCUREMENT COSTS, LOSS OF PROFIT OR REVENUE, LOSS OF DATA, PROMOTIONAL OR MANUFACTURING EXPENSES, OVERHEAD, INJURY TO REPUTATION OR LOSS OF CUSTOMERS, EVEN IF SELLER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. BUYER'S RECOVERY FROM SELLER FOR ANY CLAIM SHALL NOT EXCEED BUYER'S PURCHASE PRICE FOR THE PRODUCT GIVING RISE TO SUCH CLAIM IRRESPECTIVE OF THE NATURE OF THE CLAIM, WHETHER IN CONTRACT, TORT, WARRANTY, OR OTHERWISE. SELLER SHALL NOT BE LIABLE FOR AND BUYER SHALL INDEMNIFY, DEFEND AND HOLD SELLER HARMLESS FROM ANY CLAIMS BASED ON SELLER'S COMPLIANCE WITH BUYER'S DESIGNS, SPECIFICATIONS OR INSTRUCTIONS, OR MODIFICATION OF ANY PRODUCTS BY PARTIES OTHER THAN SELLER, OR USE IN COMBINATION WITH OTHER PRODUCTS. BUYER FURTHER ACKNOWLEDGES THAT SELLER CANNOT BE HELD LIABLE FOR PUNITIVE OR EXEMPLARY DAMAGES AS WELL.
                            </p><br />
                            <p>
                              (b) When applicable, Seller will transfer to Buyer any transferable warranties that a third-party vendor/service provider provides to Seller. SELLER SHALL HAVE NO LIABILITY TOWARDS BUYER THIRD-PARTY- MANUFACTURER PRODUCT WARRANTIES. SELLER ASSUMES NO LIABILITY FOR THE QUALITY OF THE WORK PERFORMED OR SERVICES RENDERED BY THIRD-PARTY VENDORS/SERVICE PROVIDERS. IT IS BUYER'S OBLIGATION TO REQUEST COPIES OF ANY APPLICABLE MANUFACTURER WARRANTIES AND BUYER SHALL BE DEEMED TO HAVE ACCEPTED SUCH WARRANTIES UPON ACCEPTANCE OF THE PRODUCTS AND/OR SERVICES.
                            </p><br />
                            <p>
                                (c) Warranty period on any out of warranty Products that are repaired or serviced by Seller shall be warranted for a period of 90 days from factory shipment.
                            </p><br />
                            <p>
                              (d) No warranty shall apply to any Product that has been subject to misuse, improper testing, disassembly, mishandling, corrosive processes, erosive processes, excessive temperatures, or which has been operated contrary to current written instructions relating to installation, maintenance or operation, or contrary to industry standards.

                            </p><br />
                            <p>
                              (e) Seller disclaims, and shall have no liability for any trademark, trade dress, trade secret, copyright, design or patent infringement, or any other intellectual property right, which may occur, as a result of the sale of Products to Buyer and there shall be no remedy or recourse against Seller to the extent the infringement arises from or is otherwise based upon: <br /><br />
                                (i) the Seller's compliance with the particular requirements of Buyer that differ from the Seller's standard specifications for the Product;<br />
                                (ii) modifications or alterations of the product other than by the Seller; or ;<br />
                                (iii) a combination of the Product with other items not furnished or manufactured by the Seller.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Warranty
