import React from "react";

// product views
import RagenProductView from "components/ProductViews/Ragen/RagenInsertion/RagenProductView";

function RagenInsertion({product, productType}) {
  switch (product) {
    case 'ragen':
      return (
        <>
          <RagenProductView productType={productType} />
        </>
      );
      break;
  
    default:
      return (
        <>
            <RagenProductView productType={productType} />
        </>
      );
      break;
  }
}

export default RagenInsertion;
