import React from "react";

// product views
import GenProductView from "components/ProductViews/Gen/GenInsertion/GenProductView";
import GenRProductView from "components/ProductViews/Gen/GenInsertion/GenRProductView";
import GenFatProductView from "components/ProductViews/Gen/GenInsertion/GenFatProductView";
import GenFatRProductView from "components/ProductViews/Gen/GenInsertion/GenFatRProductView";

function GenInsertion({product, productType, tab}) {
  switch (product) {
    case 'gen':
      return (
        <>
          <GenProductView productType={productType} tab={tab} />
        </>
      );
      break;

      case 'gen-r':
        return (
          <>
            <GenRProductView productType={productType} tab={tab} />
          </>
        );
        break;

      case 'gen-fat':
        return (
          <>
            <GenFatProductView productType={productType} tab={tab} />
          </>
        );
        break;

      case 'gen-fat-r':
        return (
          <>
            <GenFatRProductView productType={productType} tab={tab} />
          </>
        );
        break;
  
    default:
      return (
        <>
            <GenProductView productType={productType} tab={tab} />
        </>
      );
      break;
  }
}

export default GenInsertion;
