import React, { useState, useEffect } from "react";

// core components
import ProductHeader from "components/Headers/ProductHeader";
import ProductViewNavbar from "components/Navbars/ProductViewNavbar";
import ProductView from "components/ProductViews/Components/ProductView";
import ProductDescription from "components/ProductViews/Components/ProductDescription";
import GenRCustomizer from "components/Customizer/Views/Gen/GenRCustomizer";

import TechSpecs from "views/TechSpecs/TechSpecs";
import CADFiles from "views/CADFiles/CADFiles";
import { Helmet } from "react-helmet";

function GenRProductView({productType, tab}){
  const [page, setPage] = useState('discover');

  useEffect(() => {
    if(tab){
      if(tab === 'disc'){
        setPage('discover');
      }
  
      if(tab === 'specs'){
        setPage('specs');
      }
  
      if(tab === 'cad'){
        setPage('cad');
      }  
    }
  }, [tab]);

  const productImage = "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInsertion%2FGEN%20R%2FSeries-8200-MPNH-Insertion-Remote-Hero.png?alt=media&token=25d23e23-59c9-4384-a370-f3dba54df1b8";

  const navbarData = {
    product_title: 'SERIES 8200MPNH GEN R',
    product_title_old: 'SERIES 8200MPNH',
    product_type: 'INSERTION THERMAL MASS FLOW METER'
  }

  const productInfo = {
    model: '8200MPNH',
    series: navbarData.product_title
  }

  const images = [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInsertion%2FGEN%20R%2FProductImages%2FSeries-8200-MPNH-Insertion-Remote.jpg?alt=media&token=6b343264-df4a-4e3d-899f-37ccacc537c1",
      altText: "Series 8200MPNH GEN Remote Insertion Flow Meter",
      productImageLink: '/productimages/gen_r_insertion'
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInsertion%2FGEN%20R%2FProductImages%2FSeries-8200-MPNH-Insertion-Remote-Enclosure.jpg?alt=media&token=b17db10e-2028-4bf1-968f-537efa9c1ca0",
      altText: "Series 8200MPNH GEN Remote Insertion Enclosure",
      productImageLink: '/productimages/gen_r_insertion_2'
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInsertion%2FGEN%20R%2FProductImages%2FSeries-8200-MPNH-Insertion-Remote-Sensor-Closeup.jpg?alt=media&token=48f5a1ba-dfd8-4954-bf6e-69605e82a50c",
      altText: "Series 8200MPNH GEN Remote Insertion Sensor",
      productImageLink: '/productimages/gen_r_insertion_3'
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FGEN%2FInsertion%2FGEN%20R%2FProductImages%2FSeries-8200-MPNH-Insertion-Remote-Closeup.jpg?alt=media&token=19f8abf4-148f-4e03-aacb-cc875a5565bc",
      altText: "Series 8200MPNH GEN Remote Closeup",
      productImageLink: '/productimages/gen_r_insertion_4'
    }
  ];

  const productViewData = {
    title: 'Master-Touch™ Series 8200MPNH GEN Remote Insertion Flow Meters',
    description_small: `
    Consistently reliable thermal mass flow meters, the Series 8200MPNH provides you with accessible, safe, and dependable thermal mass monitoring.
    <br /><br />
    Delivered in an Insertion Style Thermal Mass Flow meter configuration, this series includes a sensor and probe assembly that can be inserted directly into the gas flow for precise interpretations.
    <br /><br />
    Also available in a safe and handy remote style thermal mass flow system that features a unique two enclosure set up, providing you with the ability to monitor the thermal mass away from the probe location, the Series 8200MPNH can provide you with the accuracy, safety, and dependability that you need no matter what your requirements may be.
    <br /><br />
    <ul>
      <li>Contains EPI’s leading thermal mass flow mechanisms</li>
      <li>Insertion Style and remote style thermal mass flow meter systems</li>
      <li>Available in 1/2”, 3/4” or 1” OD Probes</li>
      <li>Custom options available via contacting the factory.</li>
    </ul>
    `,
    description: `
    Master-Touch™ products include a rugged, cleanable, thermal mass flow sensor. These units consist of a sensor assembly which utilizes two RTD (Resistance Temperature Detector) sensing elements. The sensors are constructed of reference grade platinum, ceramic, glass, and stainless steel. Two platinum resistance sensors are built up upon the ceramic substrate and then given a thin glass coating. The assembly is then slipped into a stainless-steel sheath for corrosion and abrasion resistance. The sensor assembly is large, rugged, and relatively insensitive to dirt buildup. During operation, the temperature sensor constantly measures the ambient temperature of the gas and maintains a reference resistance on one side of a bridge circuit. The second sensor is forced through self-heating to a constant temperature above that of the gas stream and is controlled by the temperature sensor and bridge circuitry. Our bridge maintains the overheat temperature and counterbalances the temperature effects through our proprietary temperature compensation techniques.
    <br /><br />
    8200 Series flow meters are approved for use in non-hazardous locations (see specifications).
    <br /><br />
    Insertion style thermal mass flow meters include a sensor & probe assembly that is inserted into the process gas flow conduit to allow the process gas to flow across the flow sensing elements. Our insertion style flow meters are available with 1/2″, 3/4″, or 1″ OD probes. Tube fittings and ball valve retractor assemblies, with or without a mounting flange, are also available from the factory as options. The tube length must be specified upon ordering. For other probe diameters and lengths, please consult the factory.
    <br /><br />
    Remote style thermal mass flow meters utilize two enclosures. One enclosure is mounted at the point of measurement on the flow section. This enclosure is rated for non-hazardous environments. The second (remote) enclosure is usually placed in a readily accessible location rated for non-hazardous conditions. The remote enclosure includes all the electrical connections as well as the linearizing electronics and the display/keypad assembly. A 5-conductor shielded cable is required to carry the flow signal between the two enclosures.
    <br /><br />
    Thermal mass flow meters use the principle of convective heat transfer to directly measure mass flow. EPI’s proprietary thermal mass flow sensors use two ratiometrically-matched, reference-grade platinum Resistance Temperature Detectors (RTDs). The platinum sensing element wire is encapsulated in a 316 Stainless Steel sheath or, if specified, a Hastelloy C sheath. Our microcontroller operated smart sensor technology preferentially heats one RTD; the other RTD acts as the temperature reference. The process gas flow dissipates heat from the first RTD, causing an increase in the power required to maintain a balance between the RTDs. This increase is directly related to the gas molecular rate of flow. Our sensors are temperature compensated for a wide process gas temperature range and insensitive to pressure changes, so the output signal is a true mass flow rate signal.
    `,
    meter_type: 'Insertion',
    enclosure: 'Remote Electronics',
    warranty: '1-4',
    certifications: 'CE, CSA/CUS: Class 2252-03 & 2252-83, CSA Enclosures Type 4X, IP66;'
  }

  const techSpecsData = {
    features:{
      titleOne: 'GEN Flow Meter Enclosure',
      highlightOneImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FProductFeatures%2Ffeatures-gen.png?alt=media&token=193c5489-9621-452c-80f7-cda2b55ea49b',
      highlightOneInfo: `EPI’s GEN thermal mass flow meter enclosure is rated for use in ordinary locations, can be used in wet and dusty environments, and is corrosion resistant.
      <br /><br />
      <ul>
        <li>Transparent cover with four non-metallic corner screws</li>
        <li>Maintenance free and corrosion resistant</li>
        <li>Knockouts in the base facilitate conduit entry</li>
        <li>Continuous gaskets made of polyurethane foam guarantee optimal integrity</li>
      </ul>
      `,
      titleTwo: 'Superior Flow Measurement and Calibration',
      highlightTwoImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ffeatures-image-2.png?alt=media&token=86df9cb6-22d4-4fbc-bc7f-f6b4e267b8ec',
      highlightTwoPdf: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FProductFeatures%2FFlow%20Calibration%20Check.png?alt=media&token=d98121d8-44ae-465d-b603-aa56f7c9bdd6',
      highlightTwoInfo: `EPI flow meters provide accurate, consistent, and rapid real-time flow measurement with a sensor that
      is both temperature and pressure corrective. Each flow meter is calibrated using standards traceable to
      the National Institute of Standards and Technology (NIST).
      <br /><br />

      -Accuracy: +/- (1% of reading + 0.5% of full scale + GTC) <br />
      -Repeatability: +/- 0.2% of full scale <br />
      -Sensor response time: 1 second to 63% of final value <br />
      -Gas temperature coefficient (GTC): 0.02% full scale/°C <br />
      -Gas pressure effect: negligible over +/-50% of factory calibration pressure
      `
    },
    our_process: {
      benefits:[
        {
          title: 'Broad Velocity Range',
          info: `Up to 60,000 SFPM (80,000 SFPM with Attenuation Rod or Particle Shield)`
        },
        {
          title: 'Low flow sensitivity',
          info: `100:1 Turn down ratio; 10 SFPM (0.05 NMPS) minimum reading.`
        },
        {
          title: 'Wide temperature range',
          info: `GEN Sensor suitable for process gas temperatures up to 392°F (200°C).`
        },
        {
          title: 'Multiple analog outputs',
          info: `4-20mA and 0-5VDC outputs for both flow and temperature`
        },
        {
          title: 'Various communication protocols supported',
          info: `RS485 Modbus RTU (standard), HART, BACnet, and Profibus DP`
        },
        {
          title: 'NIST traceable calibration facility',
          info: `Over 30 years of flow calibration expertise`
        },
        {
          title: 'Ease of installation',
          info: `Various configurations to meet your installation requirements`
        },
        {
          title: 'Live Zero',
          info: `Live Zero (EPIVal) to validate your flow meter calibration in both process line and ambient air
          conditions`
        }
      ]
    },
    specifications: {
      specs: [
        {
          title: 'Approvals',
          info: `Certified to CSA/CUS Standards: Class 2252-03 & 2252-83 for Process Control Equipment in Ordinary Locations Only; CSA Enclosures Type 4X, IP66`
        },
        {
          title: 'Linear signal output',
          info: `0–5 VDC & 4–20 mA (Flow and Temperature)`
        },
        {
          title: 'Event Relay Rating',
          info: `One with Frequency or two, 1 Amp @ 30Vdc (33W)`
        },
        {
          title: 'Signal Interface',
          info: `RS232 & RS485 Modbus RTU embedded, Optional HART or Profibus DP`
        },
        {
          title: 'LCD',
          info: `(flow rate, flow total, gas temperature)`
        },
        {
          title: 'Accuracy',
          info: `including linearity (Ref.: 21°C)
          ±(1% of Reading + 0.5% of Full Scale + GTC)`
        },
        {
          title: 'Repeatability',
          info: `±0.2% of Full Scale`
        },
        {
          title: 'Sensor response time',
          info: `1 second to 63% of final value`
        },
        {
          title: 'Turn down ratio',
          info: `100:1 @ 10 SFPM (.051 NMPS) Minimum Reading`
        },
        {
          title: 'Electronics PCB temperature range',
          info: `-40° to 158°F (-40° to +70°C)`
        },
        {
          title: 'Environmental temperature range',
          info: `-40° to 140°F (-40° to +60°C)`
        },
        {
          title: 'Gas temperature range',
          info: `-40°–392°F (-40°–200°C)`
        },
        {
          title: 'Gas temperature coefficient (GTC)',
          info: `0.02% Full Scale/°C`
        },
        {
          title: 'Pressure rating maximum.',
          info: `500 PSI Std.`
        },
        {
          title: 'Input power requirement',
          info: `24VDC @ 250mA, 115 VAC 50/60 Hz optional, 230 VAC 50/60 Hz optional`
        },
        {
          title: 'Flow Transmitter power requirements',
          info: `5 watts maximum`
        },
        {
          title: 'RAM Back-up.',
          info: `Lithium Battery`
        },
        {
          title: 'Wetted materials',
          info: `316 Stainless Steel (Hastelloy optional)`
        },
        {
          title: 'Standard temperature & pressure (STP)',
          info: `70°F & 29.92″ Hg (Air .075 lb./cubic foot)`
        },
        {
          title: 'NIST traceable calibration',
          info: `Standard`
        },
        {
          title: 'NOTE',
          info: `Specifications subject to change without notice.`
        }
      ]
    },
    communication_config: {
      type: 'gen',
      comm_config: [
        {
          output: '4-20MA FLOW (SELF OR ISOLATEDLOOP POWERED)',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: false,
          bacnet: true,
          profibus: false
        },
        {
          output: '0-5 OR 0-10 VDC FLOW',
          standard: false,
          temp_out: true,
          no_temp_out: false,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: '4-20MA TEMPERATURE',
          standard: false,
          temp_out: true,
          no_temp_out: false,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: '0-5 OR 0-10 VDC TEMPERATURE',
          standard: false,
          temp_out: true,
          no_temp_out: false,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: 'FREQUENCY 0-1KHZ',
          standard: false,
          temp_out: false,
          no_temp_out: false,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: 'MODE SWITCH (RANGES)',
          standard: false,
          temp_out: false,
          no_temp_out: false,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: '1 MECHANICAL RELAY',
          standard: false,
          temp_out: true,
          no_temp_out: false,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: '2 MECHANICAL RELAY	',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: true,
          bacnet: true,
          profibus: true
        },

        {
          output: 'RS 485/MODBUS (R/W)',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: false,
          bacnet: false,
          profibus: false
        },
        {
          output: 'RS 232/MODBUS (R/W)',
          standard: true,
          temp_out: true,
          no_temp_out: true,
          hart: true,
          bacnet: true,
          profibus: true
        },
        {
          output: 'HART (READ ONLY)',
          standard: false,
          temp_out: false,
          no_temp_out: false,
          hart: true,
          bacnet: false,
          profibus: false
        },
        {
          output: 'PROFIBUS (READ ONLY)',
          standard: false,
          temp_out: false,
          no_temp_out: false,
          hart: false,
          bacnet: false,
          profibus: true
        },
        {
          output: 'BACNET (READ ONLY)',
          standard: false,
          temp_out: false,
          no_temp_out: false,
          hart: false,
          bacnet: true,
          profibus: false
        },
      ]
    },
    geometry_sizing: {
      cadImg: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FGEN-R-Insertion.jpg?alt=media&token=04070c12-c951-45a7-92d3-3ab12ccf361e',
      sizing: [
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        }
      ]
    },
    tech_support:{
      faq: [
        {
          title: 'Wiring',
          info: `The wiring of your EPI flow meter will be based on the configuration selected when your order was placed. EPI flow meters can be
          configured for various AC or DC input power options. Both analog and digital output signals are also available depending on your model and
          options`
        },
        {
          title: 'Orientation',
          info: `EPI flow meters can be mounted in a number of different orientations based on your requirements with some limited adjustability in the
          field.`
        },
        {
          title: 'Installation',
          info: `EPI flow meters do require minumum up and downstream straight run to ensure the flow profile can develop properly providing you with
          the highest accuracy readings.`
        },
        {
          title: 'Communication/ Outputs',
          info: `Your EPI flow meter will come standard with RS485 Modbus and RS232 digital communication. We also offer BACnet, Profiebus DP and
          HART communications protocols available optionally. EPI flow meters provide a 4-20mA and 0-5Vdc analog output signal for flow and
          temperature (based on specific models) as well as up to 2 relays thyat can be independently configured based on your requirements
          `
        }
      ],
      maintenance_info: {
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
      }
    },
    installation: {
      video: '',
      image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FWiring%2FMPNH.png?alt=media&token=eaa0ea04-9872-4e05-a5e7-8f6ab94c71c2'
    },
    documents_downloads: {
      datasheets: [
        {
          title: 'Downloads',
          files: [
            {
              file_title: 'Product Brochure',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%208200MPNH.pdf?alt=media&token=bfa74419-d9bc-41e3-8568-6d2cbdb2a24c',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Wiring Diagram',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FGEN%20MPNH%20Wiring.pdf?alt=media&token=f8d47a88-e137-4075-98bf-68bd9458ff62',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Product Manual',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000005_RevA_MPNH%20Manual.pdf?alt=media&token=0dc749bb-f29a-4e91-935e-c14f06950ead',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Meter Installation Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'EPITerm Software Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000003_RevA%20EPITerm%20Interface%20User%20Manual.pdf?alt=media&token=0e082343-3f69-476a-aaa0-fd344ece1885',
              version: '1.0.0',
              release_data: '2022'
            }
          ],
        }
      ],
    }
  }

  const cadData = {
    files: [
      {
        productName: '',
        modelLink: '',
        pdfLink: '',
        downloadLink: '',
        description: ``
      }
    ]
  }

  const ViewPages = () => {
    if(page === 'discover'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <ProductView productViewData={productViewData} images={images} />
          </div>
          <ProductDescription productViewData={productViewData} />
          <GenRCustomizer
            productType={productType}
            productInfo={productInfo}
          />
        </>
      );
    }

    if(page === 'specs'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <TechSpecs data={techSpecsData} />
          </div>
        </>
      );
    }

    if(page === 'cad'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <CADFiles data={cadData} />
          </div>
        </>
      );
    }

    if(page === 'support'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <h1>Support</h1>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <ProductHeader productImage={productImage} />
      <div
        className="section section-about-us"
        style={{
          'background': 'whitesmoke',
          'padding-bottom': '50px',
          'border-bottom': 'whitesmoke'
        }}
      >
        <ProductViewNavbar
          navbarData={navbarData}
          page={page}
          setPage={setPage}
        />
      </div>
      <ViewPages />
    </>
  );
}

export default GenRProductView;
