import React, { useState, useEffect } from "react";
import axios from "axios";
import useSwr from 'swr';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form"
import { Form, Button } from 'reactstrap'
import fetcher from 'utils/fetcher';
import { nanoid } from 'nanoid'
import { Helmet } from "react-helmet";


import PhoneInput from 'react-phone-number-input'
import './SignUp.css'
import logo from '../../assets/img/logo1.png'

function SignUp() {
    const [value, setValue] = useState();
    const history = useHistory();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [passwordLength, setPasswordLength] = useState(false);
    const [passwordCompare, setPasswordCompare] = useState(false);
    const [signUpComplete, setSignUpComplete] = useState(false);
    const [signupError, setSignupError] = useState(null);
    const [userExists, setUserExits] = useState(false);
    const [subscribe, setSubscribe] = useState(false);
    const { data } = useSwr(`/api/me`, fetcher);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        {data && history.push("/portal")}
    }, [data]);

    const onSubmit = async (data, e) => {
        if(data.password.length < 6){
            setPasswordLength(true);
            return;
        }

        if(data.password != data.confirmpassword){
            setPasswordCompare(true);
            return;
        }

        let register_data = {
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          password: data.password,
          passwordConfirmation: data.password,
          phoneNumber: value,
          activation_id: nanoid(),
          activation: '0',
          account_type: 'regular',
          subscriptions: subscribe? 1 : 0,
          schema_version: 2
        }

        let subscription_data = {
          email: data.email,
          subscriptions: subscribe? 1 : 0
        }

        const checkSubscription = await axios.post(`${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/subscription/check`, {email: data.email});
        if(checkSubscription.data.status === 409){
            try{
                await axios.post(`${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/subscription`, subscription_data);
            } catch(e) {
                //
            }
        }

        // console.log('Show me sign up data 1.0 =>', register_data, NEXT_PUBLIC_SERVER_ENDPOINT);
        const checkUser = await axios.post(`${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/user/check`, register_data);
        // console.log('show me check user 1.0 =>', checkUser.data);
        if(checkUser.data.status != 200){
            try {
                await axios.post(`${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/users`, register_data)
                setSignUpComplete(true);
            } catch (e) {
                setSignupError(e.message);
            }
            e.target.reset();
        } else {
            setUserExits(true);
        }
    };

    useEffect(() => {
        console.log("Show me subscribe 1.0 => ", subscribe)
    }, [subscribe]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>EPI – Sign Up Form</title>
                <meta name="description" content="Sign Up on EPI’s website by filling the registration form inside this link in just a few user-friendly steps! " />
                <link rel="canonical" href="https://epiflow.com/signup" />
            </Helmet>
            { !signUpComplete &&
                <div className='signup-container'>
                    <div className='signup-main shadow'>
                        <div className='text-center mb-4'>
                            <img src={logo} alt="" />
                        </div>
                        <h5>Welcome</h5>
                        <p className='pleaselog-text mb-4'>Sign up for an EPI account below.</p>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="First Name"
                                            name="firstname"
                                            {...register("firstname", { required: true })}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Last Name"
                                            name="lastname"
                                            {...register("lastname", { required: true })}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            className="form-control mb-3"
                                            name="email"
                                            placeholder="Email Address"
                                            {...register("email", { required: true })}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <PhoneInput
                                        className='phoneinpputfeild'
                                        placeholder="Phone Number"
                                        defaultCountry="US"
                                        value={value}
                                        onChange={setValue} />
                                </div>
                                <div className='col-md-6'>
                                    <div class="form-group">
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            placeholder="Password"
                                            {...register("password", { required: true })}
                                        />
                                    </div>
                                    {passwordLength && <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>Password must be 6 or more characters.</p>}
                                </div>
                                <div className='col-md-6'>
                                    <div class="form-group">
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="confirmpassword"
                                            placeholder="Confirm Password"
                                            {...register("confirmpassword", { required: true })}
                                        />
                                    </div>
                                    {passwordCompare && <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>Password & Confirm Password do not match.</p>}
                                    <br />
                                </div>
                                <div className='col-md-12'>
                                  <div class="form-check">
                                    <label class="form-check-label">
                                      <input class="form-check-input" type="checkbox" onClick={() => setSubscribe(!subscribe)} />
                                      <span class="form-check-sign"></span>
                                      Yes, I would like to receive emails about product announcements and company updates from EPI. Our commitment to our customers is no spam, an easy unsubscribe process, and your privacy protected.
                                    </label>
                                    <br />
                                  </div>
                                </div>
                            </div>
                            <Button type="submit" className='inputButton'>Create an Account</Button>
                            { signupError && <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>Account not registered. Please try again.</p> }
                            { userExists && <p className='pleaselog-text mb-4' style={{ 'color': '#dc3545' }}>Account under this email already exists.</p> }
                        </Form>
                        <a href="#" onClick={() => history.push('/login')}>
                            <p className="account-text">Already have an account? Log In</p>
                        </a>
                    </div>
                </div>
            }

            {signUpComplete &&
                <div className='signup-container'>
                    <div className='signup-main shadow' style={{ 'min-width': '280.94px'}}>
                        <div className='text-center mb-4'>
                            <img src={logo} alt="" />
                        </div>
                        <h5>Congratulations!</h5>
                        <p className='pleaselog-text mb-4'>Your account has been created. Please check your email for a activation link.</p>
                        <p className='pleaselog-text mb-4'>
                            <a href="#" onClick={() => history.push('/login')}>
                                <p className="account-text">Log In</p>
                            </a>
                        </p>
                    </div>
                </div>
            }
        </>
    )
}

export default SignUp
