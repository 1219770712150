import React from "react";

// reactstrap components
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip
} from "reactstrap";


function MultiNoTempBottom({ handleChange }){
  return (
    <>
      <Row>
        <Col md="5">
          <FormGroup check inline>
            <Label check>
              <span className="customizerInputTitleSmallX1 my-auto">MODE SWITCH (RANGES)</span>
              <Input
                disabled
                defaultChecked
                id="certificates_origin"
                name="certificates_origin"
                type="checkbox"
              ></Input>
              <span className="form-check-sign"></span>
              <Button className="questionToolTip my-auto" id="MODESWITCH" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="MODESWITCH" delay={0}>
                Allows for external switching between stored ranges.
              </UncontrolledTooltip>
            </Label>
          </FormGroup>

          <FormGroup check inline>
            <Label check>
              <span className="customizerInputTitleSmallX1 my-auto">DUAL MECHANICAL RELAYS</span>
              <Input
                disabled
                defaultChecked
                id="certificates_origin"
                name="certificates_origin"
                type="checkbox"
              ></Input>
              <span className="form-check-sign"></span>
              <Button className="questionToolTip my-auto" id="DUALMECHANICALRELAYS" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="DUALMECHANICALRELAYS" delay={0}>
                This configuration includes dual 2 Amp programmable dry-contact mechanical relays.  
                Common uses include low alarm, high alarm, totalized output by pulse, etc.
              </UncontrolledTooltip>
            </Label>
          </FormGroup>

          <FormGroup check inline>
            <Label check>
              <span className="customizerInputTitleSmallX1 my-auto">RS 485/MODBUS (R/W)</span>
              <Input
                disabled
                defaultChecked
                id="certificates_origin"
                name="certificates_origin"
                type="checkbox"
              ></Input>
              <span className="form-check-sign"></span>
              <Button className="questionToolTip my-auto" id="RSMODBUS" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="RSMODBUS" delay={0}>
                RS 485/MODBUS (R/W)
              </UncontrolledTooltip>
            </Label>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup check inline>
            <Label check>
              <span className="customizerInputTitleSmallX1 my-auto">RS 232 (R/W)</span>
              <Input
                disabled
                defaultChecked
                id="certificates_origin"
                name="certificates_origin"
                type="checkbox"
              ></Input>
              <span className="form-check-sign"></span>
              <Button className="questionToolTip my-auto" id="RSRW" size="sm">
                ?
              </Button>{` `}
              <UncontrolledTooltip placement="right" target="RSRW" delay={0}>
                RS 232 (R/W)
              </UncontrolledTooltip>
            </Label>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default MultiNoTempBottom;
