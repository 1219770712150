import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DragDropFile from 'components/DragDropFile/DragDropFile'
import React, { useState, useEffect } from 'react'
import PhoneInput from 'react-phone-number-input'
import { Col, Container, Row } from 'reactstrap'
import { Helmet } from "react-helmet";

function SupportInquiry() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [value, setValue] = useState();
    return (
        <div>
            {/* section-1 */}
            <div className='si-section-1'>
                <div className='image-container'>
                    <div className='about-title container pt-2 pb-2'>
                        <span className='me-2'>SUPPORT</span>
                        <FontAwesomeIcon icon={faChevronRight} className='icon' />
                        <span className='ml-2'>SUPPORT INQUIRY</span>
                    </div>
                    <div>
                        <h2 className='cover-title-h2'>SUPPORT INQUIRY</h2>
                        <p className='cover-title-p'>We are here to support you!</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <div className='pb-5 bt-s2'>
                <Container>
                    <form>
                        <h3 className='commontitleOne pt-5 mb-4 text-center'>How can we help?</h3>
                        <h4 className='rsm-form-title'>Contact Information</h4>
                        <Row>
                            <Col lg="4" md="6" sm="12">
                                <div class="form-group">
                                    <label for="">FIRST NAME <span className='text-danger'>*</span></label>
                                    <input type="text" class="form-control" id="" placeholder="First Name" />
                                </div>
                            </Col>
                            <Col lg="4" md="6" sm="12">
                                <div class="form-group">
                                    <label for="">LAST NAME <span className='text-danger'>*</span></label>
                                    <input type="text" class="form-control" id="" placeholder="Last Name" />
                                </div>
                            </Col>
                            <Col lg="4" md="6" sm="12">
                                <div class="form-group">
                                    <label for="">EMAIL <span className='text-danger'>*</span></label>
                                    <input type="email" class="form-control" id="" placeholder="Email" />
                                </div>
                            </Col>
                            <Col lg="4" md="6" sm="12">
                                <label For="" class="form-label si-phone-text">PHONE <span className='text-danger'>*</span></label>
                                <PhoneInput
                                    className='phoneinpputfeild si-phoneinpputfeild'
                                    placeholder="+1 530-830-8403"
                                    defaultCountry="US"
                                    value={value}
                                    onChange={setValue} />
                            </Col>
                            <Col lg="4" md="6" sm="12">
                                <div class="form-group">
                                    <label for="">COMPANY <span className='text-danger'>*</span></label>
                                    <input type="text" class="form-control" id="" placeholder="Company" />
                                </div>
                            </Col>

                        </Row>
                        <h4 className='rsm-form-title'>Inquiry Information</h4>
                        <Row className='mt-3'>
                            <Col md="4" sm="12">
                                <div class="form-group">
                                    <label for="">FLOW METER SERIAL NUMBER <span className='text-danger'>*</span></label>
                                    <input type="text" class="form-control" id="" placeholder="####-#### (8 Digits)" />
                                </div>
                            </Col>
                            <Col md="4" sm="12">
                                <div class="form-group">
                                    <label for="">FLOW METER TYPE</label>
                                    <select className="form-select rsm-select" aria-label="Default select example">
                                        <option selected>Select Meter Type</option>
                                        <option value="1">One</option>
                                        <option value="2">two</option>
                                    </select>
                                </div>
                            </Col>
                            <Col md="4" sm="12">
                                <div class="form-group">
                                    <label for="">FLOW METER STYLE</label>
                                    <select className="form-select rsm-select" aria-label="Default select example">
                                        <option selected>Select Meter Style</option>
                                        <option value="1">One</option>
                                        <option value="2">two</option>
                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col md="6" sm="12">
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1">COMPANY DESCRIPTION <span className='text-danger'>*</span></label>
                                    <textarea class="form-control bt-text-area" id="exampleFormControlTextarea1" rows="9" placeholder='Select Communication Protocol'></textarea>
                                </div>
                            </Col>
                            <Col md="6" sm="12">
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1">SCREENSHOTS, VIDEOS & ATTACHMENTS</label>
                                    <div>
                                        <DragDropFile />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" sm="12">
                                <div class="form-group">
                                    <label for="">APPLICATION OR PROCESS CONDITIONS <span className='text-danger'>*</span></label>
                                    <input type="text" class="form-control" id="" placeholder="Enter CondiKons Here" />
                                </div>
                            </Col>
                            <Col md="6" sm="12">
                                <div class="form-group">
                                    <label for="">HARDWARE, INTERFACE, PROTOCOL OR ADDITIONAL INFORMATION <span className='text-danger'>*</span></label>
                                    <input type="text" class="form-control" id="" placeholder="Enter AddiKonal InformaKon" />
                                </div>
                            </Col>
                        </Row>
                        <div className='pt-5 pb-5'>
                            <a href="/" className='button1'>Request Support</a>
                        </div>
                    </form>
                </Container>
            </div>
            {/* section-2 */}
        </div>
    )
}

export default SupportInquiry
