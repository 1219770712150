import React, { useState, useEffect } from 'react';
import { faChevronRight, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import { Helmet } from "react-helmet";

function FlueGas() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [color, setColor] = useState('#095CF6');
    const handleBtn = () => {
        setColor('#fff')
    }
    const handleBtnOut = () => {
        setColor('#095CF6')
    }
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Flue Gas Applications</title>
            <meta name="description" content="Measuring Flue and Exhausted Gas flows in large stacks needs high-quality thermal flow meters and software. Read now EPI’s article! " />
            <link rel="canonical" href="https://epiflow.com/whitepapers/fluegas" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner FlueGasSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/whitepapers" tag={Link}>White Papers</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/whitepapers/fluegas" tag={Link}>Flue Gas</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Flue Gas Applications</h2>
                        <p className='cover-title-p'>Everything you need to know about EPI.</p>
                    </div>
                </div>
            </div>
            {/* section-1 */}
            {/* section-2 */}
            <Container className='py-5'>
                <div className='DownloadPDfbtn-div'>
                    <div className='position-relative d-inline'>
                        <button
                            className='button2'
                            onMouseOver={handleBtn}
                            onMouseOut={handleBtnOut}
                        >
                            Download as PDF
                        </button>
                        <FontAwesomeIcon
                            className='DownloadPDfbtn-icon'
                            style={{ color: color }}
                        />
                    </div>
                </div>
                <h4 className='commontitleTwo text-center mb-4'>
                    Measuring Flue and Exhaust Gas Flows in Large Stacks
                </h4>
                <p className='commonParaThree'>
                    Generally speaking, Flue or Stack gas is the exhaust gas resulting from any source of combustion. Typical commercial sources of these gases are ovens, furnaces, boilers
                    or steam generators and power plants. The need to accurately monitor, measure and report on the exhaust from commercial combustion systems is increasingly required by
                    environmental regulations and the resulting company policies. The need to do this reliably, and at the same time economically, can be a challenge unless the
                    correct instrumentation is used.
                    <br /><br />
                    The composition of flue or stack gas depends on the type of fuel that is being burned, but nitrogen (N2) derived from the combustion air is typically at least two-thirds
                    of gas mixture, with carbon dioxide (CO2), water vapor (H2O) and excess oxygen (O2) making up the balance. The exhaust gas from even well-designed combustion systems will
                    also contain a small percentage of a number of pollutants such as particulate matter (soot), carbon monoxide (CO), nitrogen oxides (NOx), and sulfur oxides (SO2).
                    Typical ranges of these components for a gas-fired system are 74% N2, 7% CO2, 15% H2O, 4% O2, 200-300 ppm CO and 60-70 ppm NOx. For a coal-fired system they are 77% N2,
                    13% CO2, 6% H2O, 4% O2, 50 ppm CO, 420 ppm NOx and 420 ppm SO2.
                    <br /><br />

                    The gases inside the stacks are much hotter — and therefore less dense — than the air outside of the stack. This difference in pressure is the driving force that “pulls”
                    the required combustion air into the combustion zone and then moves the flue gas up and out of the stack. This movement of combustion air and flue gas is commonly referred
                    to as the “natural draft” or “stack effect” though other terms are also used. Taller stacks produce more draft and the stacks for industrial applications can be quite
                    large to facilitate both the intake of air for combustion as well as the dispersal of the flue gases over a wide area. The dispersal of the flue gas is necessary to reduce
                    the overall concentrations of pollutants to acceptable levels in the surrounding atmosphere.
                    <br /><br />

                    Continuous emission monitoring systems (CEMS) have been used for quite some time as a means to provide information for industrial combustion controls. To accomplish this,
                    the systems monitored the flue gas for O2, CO and CO2. This basic function has been expanded in recent years to incorporate compliance with governmental regulations for air
                    emission standards. In addition to the “traditional” gases, a CEMS that is used for environmental reporting now monitors emissions of SO2, NOx, mercury (Hg) and hydrogen
                    chloride (HCL) as well as airborne particulate matter and volatile organic compounds (VOCs). The most common methods of sampling, analyzing and reporting used by the CEMS
                    are dilution-extractive systems, extractive systems and in situ systems, with dilution-extractive systems being the most common.

                    <br /><br />
                    In conjunction with the gas sampling, the overall gas flow rate must also be measured and accurately reported to get a complete understanding of the combustion process
                    and the resulting stack emissions. However, measuring the flow rate in large stacks presents its own set of challenges. Uneven, irregular flow profiles across the large
                    stack diameters are more common than not, and must be dealt with adequately to achieve the necessary accuracy in the overall flow measurement. The large diameters of these
                    stacks make most common methods of flow measurement impractical, ineffective or prohibitively expensive. What is required to meet this challenge is an effective
                    measurement technology in an instrumentation package with low operating costs and a variety of installation options.
                </p>

                <h4 className='commontitleTwo text-center mb-4'>
                    Thermal Technology
                </h4>
                <p className='commonParaThree'>
                    Constant temperature thermal mass flow meters, such as those produced by EPI, operate on the principle of thermal dispersion or heat loss from a heated Resistance
                    Temperature Detector (RTD) to the flowing gas. Two active RTD sensors are operated in a balanced state. One acts as a temperature sensor reference; the other is the active
                    heated sensor. Heat loss to the flowing fluid tends to unbalance the heated flow sensor and it is forced back into balance by the electronics. The effects of variations in
                    density are virtually eliminated by molecular heat transfer and sensor temperature correction, eliminating any need for additional instrumentation to provide true mass
                    flow measurement.
                </p>

                <h4 className='commontitleTwo text-center mb-4'>
                    Multipoint Systems
                </h4>
                <p className='commonParaThree'>
                    Multipoint Systems are designed to measure gas flows where two or more sensing points are required due to large cross-sectional areas such as exhaust and flue stacks.
                    Our Series 9000MP Multipoint Systems are installed throughout the world, providing our customers with years of steady, reliable service. Coupled with our Air Purge System
                    (APS), the Series 9000MP Multipoint Systems are now well-suited to an even greater variety of industrial applications.
                    <br /><br />

                    The Series 9000MP Multipoint System includes one or more Series 9000MP Probes and a Series 9600MP System Control Panel (SCP). The probe assembly typically has two or more
                    flow sensors mounted in a 1½” OD probe shaft. The 9000MP probe’s sensors are removable for field replacement if one is damaged. Each sensor is matched to its own digital
                    microcontroller. Communications between the probe assemblies and the SCP are transmitted via an integrated Modbus RTU network. The SCP includes its own microcontroller
                    for system-level control and flow display. The SCP provides 0–5 VDC and 4–20mA analog output signals. The 4–20 mA grand average output can drive up to 1200 ohms.
                    RS232 & RS485 Modbus RTU communications are also supported for unprecedented access to the overall system, — including each individual sensor — for programmable Fail Safe
                    operation and multiple options for flow signal recovery from a sensor failure.
                    <br /><br />

                    The optional Air Purge System (APS) supports the cleaning of the sensors in applications where particulates cause problems. The frequency and duration of the purge cycle
                    can be controlled externally or by using the Master-Touch™ software. The system can also be set to maintain the flow rate at its current level when the purge was activated.
                    This important option preserves the integrity of the flow rate and elapsed total data which might otherwise be compromised by the flow of the purge gas.
                    <br /><br />

                    Whether used in conjunction with a CEMS installation or as a standalone answer for accurate stack or exhaust gas measurement, the Eldridge Products, Inc. Series 9000MP
                    Multipoint Systems are known for accurate, economical and reliable performance over many years of active service life. With addition of the integrated Modbus communications
                    and the optional Air Purge system, the Series 9000MP gains even greater power and flexibility.
                </p>
            </Container>
        </>
    );
}

export default FlueGas;
