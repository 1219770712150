import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import useSwr, { useSWRConfig } from 'swr';
import axios from "axios";
import StickyBox from "react-sticky-box";
import { customAlphabet } from "nanoid";
import fetcher from 'utils/fetcher';
import ShoppingCart from "./ShoppingCart";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText
} from "reactstrap";

import  "./TotalCard.css";

function TotalCard({total, data, series, model}){
  const history = useHistory();
  const isAuthenticated_ = JSON.parse(sessionStorage.getItem('ciuli'));
  const [userData, setUserData] = useState([]);
  const [viewPrice, setViewPrice] = useState(false);

  const { data:userData_ } = useSwr("/api/me", fetcher);
  const nanoid = customAlphabet("0123456789", 10);
  const state = data;

  const numberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  useEffect(() => {
    if(userData_){
      setUserData(userData_)
    }
  }, [userData_]);

  useEffect(() => {
    if(userData?.account_type === 'admin' || userData?.account_type === 'regularPlusPricing'){
      setViewPrice(true);
    }
  }, [userData]);


  let total_ = numberWithCommas(total);

  const saveInCart = async () => {
    const meterCustomizedData = {
      series: series,
      model: model,
      total: total_,
      state: data,
      config_type: 'incart'
    }

    console.log('Show me save quote data 1.0.2 =>', meterCustomizedData);

    if(isAuthenticated_){
      sessionStorage.setItem('_customized_meter', JSON.stringify(meterCustomizedData));
      try {
        const savedConfig = await axios.post(
          `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/quotes`,
          meterCustomizedData,
          { withCredentials: true }
        );
        sessionStorage.removeItem('_customized_meter')

        // ShoppingCart({'task': 'delete'});
        ShoppingCart({'task':'read'});
        savedConfig.data.quantity = 1;
        ShoppingCart({'task':'update', 'data': savedConfig.data});

        history.push('/portal/cart')
      } catch (e) {
        console.log('Show me db insert error', e.message);
      }
    } else {
      const storeId = nanoid();
      meterCustomizedData.storedId = storeId;
      sessionStorage.setItem('_customized_meter', JSON.stringify(meterCustomizedData));
      history.push(`/login/${storeId}`);
    }
  }

  const saveConfiguration = async () => {
    const meterCustomizedData = {
      series: series,
      model: model,
      total: total_,
      state: data,
      config_type: 'saved'
    }

    console.log('Show me save quote data 1.0.2 =>', meterCustomizedData);

    if(isAuthenticated_){
      sessionStorage.setItem('_customized_meter', JSON.stringify(meterCustomizedData));
      try {
        const savedConfig = await axios.post(
          `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/quotes`,
          meterCustomizedData,
          { withCredentials: true }
        );
        sessionStorage.removeItem('_customized_meter')

        // ShoppingCart({'task': 'delete'});
        ShoppingCart({'task':'read'});
        savedConfig.data.quantity = 1;
        ShoppingCart({'task':'update', 'data': savedConfig.data});

        history.push('/portal/quotes')
      } catch (e) {
        console.log('Show me db insert error', e.message);
      }
    } else {
      const storeId = nanoid();
      meterCustomizedData.storedId = storeId;
      sessionStorage.setItem('_customized_meter', JSON.stringify(meterCustomizedData));
      history.push(`/login/${storeId}`);
    }
  }

  const ShowTotal = () => {
    // if(!isAuthenticated_){
    //   return(
    //     <>
    //       <CardTitle className="costTitle" tag="h5">Total Cost</CardTitle>
    //       <CardTitle className="costLogin" tag="h3"><p>Please log in to request pricing access.</p></CardTitle>
    //     </>
    //   );
    // }

    return(
      <>
        <CardTitle className="costTitle" tag="h5">Total Cost</CardTitle>
        {total_ === "0"?
          (<CardTitle className="costLogin" tag="h3">
            <p>TBD</p>
          </CardTitle>):
          (
            <CardTitle className="costPrice" tag="h3">
              <p>
                { viewPrice? `$${total_}` : 'Account not approved to view pricing' }
              </p>
            </CardTitle>
          )
        }

        {total_ === "0"?
          (
            <CardText>
              Price to be determined
            </CardText>
          ):(
            <>
            { viewPrice? (
              <CardText>
                The price listed is for the meter with the current configuration.
              </CardText>
            ) : (
              <CardText>
                <a href="#" onClick={() => history.push('/portal/editAccount')}>Request to view pricing</a>
              </CardText>
            ) }
            </>
          )
        }
      </>
    );
  }

  if(viewPrice){
    var ca = state.model_number.certifications + state.model_number.approvals;
    if(state.model_number.certifications === 'NA'){
      var ca = state.model_number.certifications
    }
  }

  var electronics = state.model_number.electronics;
  var pipe_probe_length = state.model_number.pipe_length;
  if(state.meter_type.type === 'insertion'){
    var electronics = state.model_number.electronics_insertion;
    var pipe_probe_length = state.model_number.probe_length;
  }

  const checkModelState = (model) => {
    // console.log('Show me changing 1.0 =>', state.model_number_state[model]);
    if(state.model_number_state[model]){
      return 'liveBox';
    }
  }

  // const pipeStandard = state.model_number.pipe_standard;
  // console.log('Show me prob length 1.0 =>', state.probe_section.probe_width?.width);
  
  return (
    <>
      <StickyBox className="priceBox" offsetTop={60} offsetBottom={60}>
        <Card className="text-center">
          <CardBody>
            <ShowTotal />
            <Button
              className="saveQuoteButtonZoom"
              color="white"
              size="lg"
              outline
              onClick={ (e) => saveInCart()}
            >
              Add to Cart
            </Button>
            <br />
            <Button
              className="saveQuoteButtonZoom"
              color="white"
              size="lg"
              outline
              onClick={ (e) => saveConfiguration()}
            >
              Save Configuration
            </Button>
            {/*
            <br />
            <br />
            <a href="/portal/quotes">Save Configuration for Later</a> */}
          </CardBody>
        </Card>
        <br />
        {viewPrice &&
          <Card className="text-center">
            <CardBody className="modelImage">
              {state.meter_type.type === 'inline'?
                <img src={state.meter_images.inline} /> :
                <img src={state.meter_images.insertion} />
              }
              <br />
              <span className="costModelTitle">Model Number:</span>
              <hr />
              {state.meter_type?.type === 'inline' &&
                <span className="costModelNum" style={{'text-transform': 'uppercase'}}>
                  <span className="">{electronics}</span>
                  <span className={checkModelState('electronics')}>{state.model_number.pipe_length}</span><span className={checkModelState('series')}>{state.model_number.series}</span>-
                  <span className={checkModelState('transmitter')}>{state.model_number.transmitter}</span>-
                  <span className={checkModelState('sensor_material')}>{state.model_number.sensor_material}</span> <span className={checkModelState('sensor_shield')}>{state.model_number.sensor_shield}</span>-
                  <span className={checkModelState('display')}>{state.model_number.display}</span>-
                  <span className={checkModelState('input_power')}>{state.model_number.input_power}</span>-
                  <span className={checkModelState('gas_temp')}>{state.model_number.gas_temp}</span>-
                  <span className={checkModelState('pipe_standard')}>{state.model_number.pipe_standard}</span><span className={checkModelState('electronics')}>{pipe_probe_length}</span><span className={checkModelState('mounting_requirements')}>{state.model_number.mounting_requirements}</span>-
                  <span className={checkModelState('range')}>{state.model_number.range}</span>-
                  <span className={checkModelState('gas')}>{state.model_number.gas}</span>-
                  <span className={checkModelState('certifications') || checkModelState('approvals')}>{ca}</span>-
                  <span className={checkModelState('communications_options_2')}>{state.model_number.communications_options_2}</span>
                  <br />
                </span>
              }

              {state.meter_type?.type === 'gen' &&
                <span className="costModelNum" style={{'text-transform': 'uppercase'}}>
                  <span className="">{electronics}</span>
                  <span className={checkModelState('electronics')}>{state.model_number.pipe_length}</span><span className={checkModelState('series')}>{state.model_number.series}</span>-
                  <span className={checkModelState('transmitter')}>{state.model_number.transmitter}</span>-
                  <span className={checkModelState('sensor_material')}>{state.model_number.sensor_material}</span> <span className={checkModelState('sensor_shield')}>{state.model_number.sensor_shield}</span>-
                  <span className={checkModelState('display')}>{state.model_number.display}</span>-
                  <span className={checkModelState('input_power')}>{state.model_number.input_power}</span>-
                  <span className={checkModelState('gas_temp')}>{state.model_number.gas_temp}</span>-
                  <span className={checkModelState('pipe_standard')}>{state.model_number.pipe_standard}</span><span className={checkModelState('electronics')}>{pipe_probe_length}</span><span className={checkModelState('mounting_requirements')}>{state.model_number.mounting_requirements}</span>-
                  <span className={checkModelState('range')}>{state.model_number.range}</span>-
                  <span className={checkModelState('gas')}>{state.model_number.gas}</span>-
                  <span className={checkModelState('certifications') || checkModelState('approvals')}>{ca}</span>-
                  <span className={checkModelState('communications_options_2')}>{state.model_number.communications_options_2}</span>
                  <br />
                </span>
              }

              {state.meter_type?.type === 'insertion' &&
                <span className="costModelNum" style={{'text-transform': 'uppercase'}}>
                  <span className="">{electronics}</span>
                  <span className={checkModelState('electronics')}>{state.probe_section.probe_width?.width === false? '00' : state.model_number.pipe_length}</span><span className={checkModelState('series')}>{state.model_number.series}</span>-
                  <span className={checkModelState('transmitter')}>{state.model_number.transmitter}</span>-
                  <span className={checkModelState('sensor_material')}>{state.model_number.sensor_material}</span> <span className={checkModelState('sensor_shield')}>{state.model_number.sensor_shield}</span>-
                  <span className={checkModelState('display')}>{state.model_number.display}</span>-
                  <span className={checkModelState('input_power')}>{state.model_number.input_power}</span>-
                  <span className={checkModelState('gas_temp')}>{state.model_number.gas_temp}</span>-
                  <span className={checkModelState('pipe_standard')}>{state.model_number.pipe_length}</span><span className={checkModelState('electronics')}>{pipe_probe_length}</span>-
                  <span className={checkModelState('range')}>{state.model_number.range}</span>-
                  <span className={checkModelState('gas')}>{state.model_number.gas}</span>-
                  <span className={checkModelState('certifications') || checkModelState('approvals')}>{ca}</span>-
                  <span className={checkModelState('communications_options_2')}>{state.model_number.communications_options_2}</span>
                  <br />
                </span>
              }
            </CardBody>
          </Card>
        }
      </StickyBox>
    </>
  );
}

export default TotalCard;
