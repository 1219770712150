import React, { useState, useEffect } from "react";

// core components
import ProductHeader from "components/Headers/ProductHeader";
import ProductViewNavbar from "components/Navbars/ProductViewNavbar";
import ProductView from "components/ProductViews/Components/ProductView";
import ProductDescription from "components/ProductViews/Components/ProductDescription";
import SwitchCustomizer from "components/Customizer/Views/Switch/SwitchInline/SwitchCustomizer";
import TechSpecs from "views/TechSpecs/TechSpecs";
import CADFiles from "views/CADFiles/CADFiles";
import { Helmet } from "react-helmet";

function SwitchProductView({productType, tab}){
  const [page, setPage] = useState('discover');

  useEffect(() => {
    if(tab){
      if(tab === 'disc'){
        setPage('discover');
      }
  
      if(tab === 'specs'){
        setPage('specs');
      }
  
      if(tab === 'cad'){
        setPage('cad');
      }  
    }
  }, [tab]);

  const productImage = "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FSwitch%2FInline%2FSwitch%2FSeries-7000-Inline-Hero.png?alt=media&token=7b95137e-7fe3-4256-8058-3052b5236ab9";

  const navbarData = {
    product_title: 'SERIES 7000 SWITCH',
    product_title_old: 'SERIES 7000',
    product_type: 'INLINE THERMAL MASS FLOW SWITCH'
  }

  const productInfo = {
    model: '7000 SWITCH',
    series: navbarData.product_title
  }

  const images = [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FSwitch%2FInline%2FSwitch%2FProductImages%2FSeries-7000-Inline-Flow-Meter.jpg?alt=media&token=e8dc849a-c5a0-498f-a2d8-1baf43f48d28",
      altText: "Series 7000 Inline Flow Switch",
      productImageLink: '/productimages/switch_inline'
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FSwitch%2FInline%2FSwitch%2FProductImages%2FSeries-7000-Inline-Probe.jpg?alt=media&token=2061b47e-94b9-4bb5-9cbe-3635c71dc917",
      altText: "Series 7000 Inline Flow Switch Probe",
      productImageLink: '/productimages/switch_inline_2'
    }
  ];

  const productViewData = {
    title: 'Series 7000 Inline Flow Switches',
    description_small: `
    Part of the SWITCH product family that are different than thermal mass flow meters, the Series 7000 is a thermal mass flow switch that detects the presence of the flow, at a predetermined set point, sends alerts, and performs designated actions. Designed with inline installation type, these thermal mass flow switches are available in sizes from 1/4″ pipe through 4″ pipe and are provided with threaded male NPT ends as the standard mounting style. While the Series 7000 is designed with an explosion-proof enclosure for Hazardous locations, the model has still not been submitted for agency approval testing.
    <br /><br />
    To provide you with accurate measurements and detection, the Series 7000 uses our proprietary temperature compensation techniques with a sensor assembly that utilizes two RTD (Resistance Temperature Detector) sensing elements. Our high-stability sensors are made of reference-grade platinum, ceramic, glass, and stainless steel, while the final assembly is slipped into a stainless-steel sheath for corrosion and abrasion resistance.
    <br /><br />
    If you need a thermal mass flow switch with an inline installation type, the Series 7000 is one of your best choices. Alternatively, if you need insertion thermal mass flow switches, then check the <a href="/products/switch/insertion/gen">Series 7200</a>.
    `,
    description: `
    7000 series products include a rugged, cleanable, thermal mass flow sensor. These units consist of a sensor assembly which utilizes two RTD (Resistance Temperature Detector) sensing elements. The sensors are constructed of reference grade platinum, ceramic, glass, and stainless steel. Two platinum resistance sensors are built up upon the ceramic substrate and then given a thin glass coating. The assembly is then slipped into a stainless-steel sheath for corrosion and abrasion resistance. The sensor assembly is large, rugged, and relatively insensitive to dirt buildup. During operation, the temperature sensor constantly measures the ambient temperature of the gas and maintains a reference resistance on one side of a bridge circuit. The second sensor is forced through self-heating to a constant temperature above that of the gas stream and is controlled by the temperature sensor and bridge circuitry. Our bridge maintains the overheat temperature and counterbalances the temperature effects through our proprietary temperature compensation techniques.
    <br /><br />
    7000 Series flow switches have not been submitted for agency approval testing.
    <br /><br />
    The Inline style thermal mass flow switch assembly includes the flow sensing element, temperature sensing element, bridge amplifier/signal output board, transmitter enclo­sure, and flow section. The flow section is typically specified to match the user’s flow conduit and is plumbed directly in the flow line. This design has the sensing elements mounted directly in the flow section for exposure to the process flow.
    <br /><br />
    Inline flow switches are available in sizes from 1/4″ pipe through 4″ pipe and are provided with threaded male NPT ends as the standard mounting style. Optional end mounting styles may be specified, such as tube ends, tube end fittings, butt weld ends, flanged end configurations, etc. as required. Pipe sizes more than 4″ require insertion style thermal mass flow switches.
    <br /><br />
    All of EPI’s flow switches have two single-pole, double-throw relays for Hi/Hi, Lo/Lo and Hi/Lo capability. The contact closure is 5 amps @ 24 VDC and 115 VAC. Two LEDs are provided for verification that the Hi or Lo alarm has been triggered. The standard configuration has 316 Stainless Steel wetted parts (Hastelloy & Monel are also available) for a rugged, cleanable instrument designed for use in applications where corrosion, abrasion, high pressure, or rough handling may be expected.
    <br /><br />
    Thermal mass flow switches use the principle of convective heat transfer to directly measure mass flow. EPI’s proprietary thermal mass flow sensors use two ratiometrically-matched, reference-grade platinum Resistance Temperature Detectors (RTDs). The platinum sensing element wire is encapsulated in a 316 Stainless Steel sheath or, if specified, a Hastelloy C sheath. Our microcontroller operated smart sensor technology preferentially heats one RTD; the other RTD acts as the temperature reference. The process gas flow dissipates heat from the first RTD, causing an increase in the power required to maintain a balance between the RTDs. This increase is directly related to the gas molecular rate of flow. Our sensors are temperature compensated for a wide process gas temperature range and insensitive to pressure changes, so the output signal is a true mass flow rate signal.
    `,
    meter_type: 'Inline',
    enclosure: 'Integrated Electronics',
    warranty: '1-4',
    certifications: 'CE'
  }

  const techSpecsData = {
    features:{
      titleOne: 'Series 7000 Flow Switch Enclosure',
      highlightOneImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FProductFeatures%2Ffeatures-switch.png?alt=media&token=15c393e1-43c9-408d-b600-31af0e6b19f7',
      highlightOneInfo: `EPI’s flow switch enclosure is rated for use in hazardous locations with T2, T3, and T4 approvals available in domestic and international markets. The diecast aluminum, NEMA Type 4X watertight enclosure is both flame and explosion proof and features a corrosion resistant finish.
      <br /><br />
      <ul>
        <li>Approvals: UL/CUL Class I, Division 1, Group B; NEMA Types 4X/7/9. ATEX Approval Optional - Consult Factory</li>
        <li>Finish: Safety Blue Polyester Powdercoat.</li>
      </ul>
      `,
      titleTwo: 'Superior Flow Measurement and Calibration',
      highlightTwoImage: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ffeatures-image-2.png?alt=media&token=86df9cb6-22d4-4fbc-bc7f-f6b4e267b8ec',
      highlightTwoPdf: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FProductFeatures%2FFlow%20Calibration%20Check.png?alt=media&token=d98121d8-44ae-465d-b603-aa56f7c9bdd6',
      highlightTwoInfo: `EPI flow meters provide accurate, consistent, and rapid real-time flow measurement with a sensor that
      is both temperature and pressure corrective. Each flow meter is calibrated using standards traceable to
      the National Institute of Standards and Technology (NIST).
      <br /><br />

      -Accuracy: +/- (1% of reading + 0.5% of full scale + GTC) <br />
      -Repeatability: +/- 0.2% of full scale <br />
      -Sensor response time: 1 second to 63% of final value <br />
      -Gas temperature coefficient (GTC): 0.02% full scale/°C <br />
      -Gas pressure effect: negligible over +/-50% of factory calibration pressure
      `
    },
    our_process: {
      benefits:[
        {
          title: 'Broad Velocity Range',
          info: `Up to 60,000 SFPM (80,000 SFPM with Attenuation Rod or Particle Shield)`
        },
        {
          title: 'Low flow sensitivity',
          info: `100:1 Turndown ratio; 15 SFPM (0.076 NMPS) minimum reading.`
        },
        {
          title: 'Wide temperature range',
          info: `Sensor suitable for process gas temperatures up to 392°F (200°C).`
        },
        {
          title: 'Multiple analog outputs',
          info: `4-20mA and 0-5VDC outputs for both flow and temperature`
        },
        {
          title: 'Various communication protocols supported',
          info: `RS485 Modbus RTU (standard), HART, BACnet, and Profibus DP`
        },
        {
          title: 'NIST traceable calibration facility',
          info: `Over 30 years of flow calibration expertise`
        },
        {
          title: 'Ease of installation',
          info: `Various configurations to meet your installation requirements`
        },
        {
          title: 'Live Zero',
          info: `Live Zero (EPIVal) to validate your flow meter calibration in both process line and ambient air
          conditions`
        }
      ]
    },
    specifications: {
      specs: [
        {
          title: 'Approvals',
          info: `No Agency Certifications`
        },
        {
          title: 'Signal output',
          info: `Relay contact, high & low,
          6 Amp @ 24 Volts DC or 250 Volts AC (Class “A” Insulation)`
        },
        {
          title: 'Event Relay Rating',
          info: `One with Frequency or two, 1 Amp @ 30Vdc (33W)`
        },
        {
          title: 'Trip point accuracy',
          info: `+/‑ 1.0% of Set Point`
        },
        {
          title: 'Repeatability',
          info: `+/‑ 0.5% of Range`
        },
        {
          title: 'Sensor response time (Metal)',
          info: `1 Second`
        },
        {
          title: 'Signal response time',
          info: `Set to 1 second`
        },
        {
          title: 'Turn down ratio',
          info: `100:1 minimum`
        },
        {
          title: 'Electronics temperature range',
          info: `0‑50°C (32‑122°F)`
        },
        {
          title: 'Gas temperature range',
          info: `0‑200°C (32‑392°F)`
        },
        {
          title: 'Gas temperature effect',
          info: `.01% /°C for gas`
        },
        {
          title: 'Pressure rating maximum:',
          info: `500 PSI`
        },
        {
          title: 'Power requirements',
          info: `User supplied 24 VDC, @ 250mA Max.
          120 VAC 50/60 Hz (optional)`
        },
        {
          title: 'Flow switch enclosure',
          info: `NEMA 4, Class 1, Div 1 Groups B, C and D`
        },
        {
          title: 'Wetted materials',
          info: `316SS, ceramic, glass, epoxy`
        },
        {
          title: 'NIST traceable calibration',
          info: `Optional for Gas`
        },
        {
          title: 'Calibration Standard (Gas)',
          info: `70 °F & 29.92″ Hg
          (Air = .075 lb./cubic foot)`
        },
        {
          title: 'Note',
          info: `Specifications subject to change without notice.`
        }
      ]
    },
    communication_config: {
      type: 'switch',
      comm_config: [
        {
          output: '4-20MA FLOW (SELF OR ISOLATEDLOOP POWERED)',
          standard: false
        },
        {
          output: '0-5 OR 0-10 VDC FLOW',
          standard: false
        },
        {
          output: '4-20MA TEMPERATURE',
          standard: false
        },
        {
          output: '0-5 OR 0-10 VDC TEMPERATURE',
          standard: false
        },
        {
          output: 'FREQUENCY 0-1KHZ',
          standard: false
        },
        {
          output: 'MODE SWITCH (RANGES)',
          standard: false
        },
        {
          output: '1 MECHANICAL RELAY',
          standard: true
        },
        {
          output: '2 MECHANICAL RELAY	',
          standard: true
        },

        {
          output: 'RS 485/MODBUS (R/W)',
          standard: false
        },
        {
          output: 'RS 232/MODBUS (R/W)',
          standard: false
        },
        {
          output: 'HART (READ ONLY)',
          standard: false
        },
        {
          output: 'PROFIBUS (READ ONLY)',
          standard: false
        },
        {
          output: 'BACNET (READ ONLY)',
          standard: false
        },
      ]
    },
    geometry_sizing: {
      cadImg: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FDimensions%2FSWITCH-Inline.png?alt=media&token=0cf72e5d-2390-47ae-a45f-c263506809e4',
      sizing: [
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        },
        {
          title: 'title',
          demensions: 'Demensions'
        }
      ]
    },
    tech_support:{
      faq: [
        {
          title: 'Wiring',
          info: `The wiring of your EPI flow meter will be based on the configuration selected when your order was placed. EPI flow meters can be
          configured for various AC or DC input power options. Both analog and digital output signals are also available depending on your model and
          options.`
        },
        {
          title: 'Orientation',
          info: `EPI flow meters can be mounted in a number of different orientations based on your requirements with some limited adjustability in the
          field.`
        },
        {
          title: 'Installation',
          info: `EPI flow meters do require minumum up and downstream straight run to ensure the flow profile can develop properly, providing you with
          the highest accuracy readings.`
        },
        {
          title: 'Communication/ Outputs',
          info: `Your EPI flow meter will come standard with RS485 Modbus and RS232 digital communication. We also offer optional BACnet, Profibus DP and
          HART communications protocols. EPI flow meters provide a 4-20mA and 0-5 VDC analog output signal for flow and
          temperature (based on specific models) as well as up to 2 relays that can be independently configured based on your requirements.
          `
        }
      ],
      maintenance_info: {
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis nostrud exercitaAon ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
      }
    },
    installation: {
      video: '',
      image: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FWiring%2FSwitch.png?alt=media&token=2033919f-db83-45a3-92a7-da80e6abd0bc'
    },
    documents_downloads: {
      datasheets: [
        {
          title: 'Downloads',
          files: [
            {
              file_title: 'Product Brochure',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FBrochures%2FSERIES%207000-7200.pdf?alt=media&token=d1e527e3-5728-461e-a15b-16cafd9e4889',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Wiring Diagram',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FWiring%2FSwitch%20Wiring%20Diagram.pdf?alt=media&token=707e8e5c-d486-4f7e-9f73-36bd444616b5',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Product Manual',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F7000.pdf?alt=media&token=b04c8ea7-c96c-47f7-b43b-3c7212d56d64',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'Meter Installation Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FInstallation%2F90000006_RevA_Installation%20Manual.pdf?alt=media&token=aaebfcd0-c0c9-4d56-a7f7-bfc9f61c820d',
              version: '1.0.0',
              release_data: '2022'
            },
            {
              file_title: 'EPITerm Software Guide',
              file_link: 'https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Downloads%2FManuals%2F90000003_RevA%20EPITerm%20Interface%20User%20Manual.pdf?alt=media&token=0e082343-3f69-476a-aaa0-fd344ece1885',
              version: '1.0.0',
              release_data: '2022'
            }
          ],
        }
      ],
    }
  }

  const cadData = {
    files: [
      {
        productName: '',
        modelLink: '',
        pdfLink: '',
        downloadLink: '',
        description: ``
      }
    ]
  }

  const ViewPages = () => {
    if(page === 'discover'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <ProductView productViewData={productViewData} images={images} />
          </div>
          <ProductDescription productViewData={productViewData} />
          <SwitchCustomizer
            productType={productType}
            productInfo={productInfo}
          />
        </>
      );
    }

    if(page === 'specs'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <TechSpecs data={techSpecsData} />
          </div>
        </>
      );
    }

    if(page === 'cad'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'margin-top': '-70px',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <CADFiles data={cadData} />
          </div>
        </>
      );
    }

    if(page === 'support'){
      return(
        <>
          <div
            className="section section-about-us"
            style={{
              'background': 'whitesmoke',
              'padding-bottom': '50px',
              'border-bottom': 'whitesmoke'
            }}
          >
            <h1>Support</h1>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <ProductHeader productImage={productImage} />
      <div
        className="section section-about-us"
        style={{
          'background': 'whitesmoke',
          'padding-bottom': '50px',
          'border-bottom': 'whitesmoke'
        }}
      >
        <ProductViewNavbar
          navbarData={navbarData}
          page={page}
          setPage={setPage}
        />
      </div>
      <ViewPages />
    </>
  );
}

export default SwitchProductView;
