import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row, NavLink } from 'reactstrap';
import TComAccordation from './TComAccordation';
import { Helmet } from "react-helmet";

import TechnicalInfoFooter from 'components/Footers/TechnicalInfoFooter';


function TechnologyComparison() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>EPI – Flow Meter Technology Comparison </title>
            <meta name="description" content="Mass Flow or Volumetric Flow? Get information about all the different types of flow meters and their uses, as well as some pros and cons. " />
            <link rel="canonical" href="https://epiflow.com/technicalinfo/technologycomparison" />
        </Helmet>
            {/* section-1 */}
            <div className='commonbanner TComSection-1'>
                <div className='image-container'>
                  <div className='about-title container pt-2 pb-2'>
                      <span className='me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo" tag={Link}>Technical Information</NavLink></span>
                      <FontAwesomeIcon icon={faChevronRight} className='icon' />
                      <span className='ml-2 me-2'><NavLink className='breadcrumbsLink' to="/technicalinfo/technologycomparison" tag={Link}>Technology Comparison</NavLink></span>
                  </div>
                    <div>
                        <h2 className='cover-title-h2'>Technology Comparison</h2>
                        <p className='cover-title-p'>
                            Get information about different types of flow meters and their uses, as well as some pros and cons.
                        </p>
                    </div>
                </div>
            </div>
            {/* section-1 */}

            {/* section-2 */}
            <div className='bg-white'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo text-center mb-2'>
                        Thermal Mass Flow Technology Comparison
                    </h4>
                    <p className='commonParaThree text-center mb-4'>
                        To help you better understand the flow meter technology comparison, some helpful terms have been defined below.
                    </p>
                    <br /><br />
                    <h4 className='commontitlefive mb-2'>
                        Mass Flow vs. Volumetric Flow
                    </h4>
                    <p className='commonParaThree'>
                        To avoid confusion when discussing flow or flow rate, it is important that the correct terms are used and that the concepts of flow rate, volume flow rate and mass flow rate are clearly understood. In general <b>Flow Rate</b> can be understood as the actual velocity (V) of the moving fluid, whereas <b>Volumetric Flow Rate</b> (Q) is expressed in units such as cubic feet, actual cubic feet, cubic meters, liters, cubic centimeters, etc., flowing through a cross sectional area (A) such as a pipe or duct. The formula Q = V x A represents Volumetric Flow Rate. Conversely <b>Mass Flow Rate</b> (M) shall be defined as the quantity of fluid delivered in pounds or grams per unit time. The formula M = ρ x A x V represents Mass Flow Rate. Density (ρ) has a great effect on compressible fluids such as gasses, whereas it has a minor effect on liquids.
                    </p>
                    <h4 className='commontitlefive mb-2'>
                        Airflow Measurement Methods
                    </h4>
                    <p className='commonParaThree'>
                        Most flow meters are volumetric and infer mass flow through calculations derived by taking other physical process measurements such as absolute pressure, differential pressure, temperature and viscosity readings. Because of this method of obtaining a mass flow output they are known as inferential mass flow meters as opposed to direct mass flow measurement.
                    </p>

                </Container>
            </div>

            {/* section-2 */}

            {/* section-3 */}
            <div className='background-colorOne'>
                <Container className='py-5'>
                    <h4 className='commontitleTwo text-center mb-2'>
                        Flow Meter Types
                    </h4>
                    <TComAccordation/>
                </Container>
            </div>

            {/* section-3 */}
            {/* section-4 */}
                <TechnicalInfoFooter />
            {/* section-4 */}
        </>
    )
}

export default TechnologyComparison
